import React, { useRef, useState, useEffect, useContext } from "react";
import { useQuery } from "../../../helper/useQuery";
import Navbar from "../../../components/Navbar/Navbar";
import "./Usecase.scss";
import bg from "../../../assets/images/background.png";
import default_img from "../../../assets/images/default_img.png";
import Button from "../../../components/Button/Button";
import Scroller from "../../../components/Scroller/ScrollBar";
import Hero from "./Component/ViewApp/Hero";
import AppImage, { ImageCard, MediaCard } from "./Component/ViewApp/AppImage";
import AppDescription from "./Component/ViewApp/AppDescription";
import RatingsReviews, { ReviewCard } from "./Component/ViewApp/RatingsReviews";
import Suggestions, { SuggestApp } from "./Component/ViewApp/Suggestions";
import Slider from "react-slick";
import AnimatedModal from "../../../components/Modal/AnimatedModal";
import { closeIcon } from "../../../helper/icons";
import video from "../../../assets/images/video.mp4";
import vredefort from "../../../assets/images/vredefort.mp4";
import StarRatings from "./Component/ViewApp/StarRating";
import StarRating from "./Component/ViewApp/StarRating";
import Details from "../../shared/DeviceDetail/Details";
import AboutDetails from "./Component/ViewApp/AboutDetails";
import { next } from "../../../../src/helper/icons";
import { axiosApiInstance } from "../../../helper/request";
import { useHistory } from "react-router-dom";
import Loading from "../../../components/Loading/Loading";
import { ThemeContext } from "../../../helper/context";

export default function Usecase() {
  const history = useHistory();
  let firstLoad = true;
  const queryy = useQuery();
  const themeContext = useContext(ThemeContext);
  // const app_name = queryy.get("app_name") || "";

  const [ratingsActive, setRatingsActive] = useState(false);
  const [aboutActive, setAboutActive] = useState(false);
  const [open, setOpen] = useState(false);

  const [appDetails, setAppDetails] = useState({});
  const [reviewDetails, setReviewDetails] = useState([]);
  const [ratingDetails, setRatingDetails] = useState([]);
  const [aboutCompatibility, setAboutCompatibility] = useState([]);
  const [usecaseSuggestions, setUsecaseSuggestions] = useState([]);
  const [mediavideoid, setMediaVidId] = useState("");
  const [fetchAppDetails, setFetchAppDetails] = useState(false);
  const [LoadingScreen, setLoadingScreen] = useState(false);

  const formatDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [day, month, year].join("/");
  };

  const progress = [10, 20, 1, 40, 50];
  const about_details = {
    Version:
      appDetails?.version_name === null ? "None" : appDetails?.version_name,

    Released_on:
      appDetails?.added === null ? "None" : formatDate(appDetails?.added),
  };
  const mappingLabels = {
    compatible_hw: "Hardware_architecture",
    compatible_gpu: "GPU_architecture",
    compatible_os: "OS_version",
    compatible_version: "RDX_version",
  };
  let compatibility = {};
  Object.keys(mappingLabels).map(
    (item) =>
      (compatibility[mappingLabels[item]] = aboutCompatibility[item]?.map(
        (y, j) =>
          j === aboutCompatibility[item].length - 1
            ? y.replace("_", ":  ")
            : y + " , "
      ))
  );

  //  Object.values(aboutCompatibility).map(
  //   (x, idx) => idx === (x.compatible_hw.length-1) ? x : x.compatible_hw + " , "
  // ),
  // GPU_Architecture: Object.values(aboutCompatibility).map(
  //   (x) => x.compatible_gpu + ","
  // ),
  // OS_Version: Object.values(aboutCompatibility).map(
  //   (x) => x.compatible_os + ","
  // ),
  // RDX_Version: Object.values(aboutCompatibility).map(
  //   (x) => x.compatible_version
  // ),

  // const dependent_services = {
  //   IOT: "abc",
  //   usecase: "abc",
  //   base: "abc",
  //   database: "abc",
  // };

  const permission = {
    external_device: "something",
    networks: "something",
    GPU: "something",
    file_system: "something",
  };

  // const average = demo.reduce((a, b) => a + b, 0) / demo.length;
  // console.log(average);

  const suggestedApp = {
    review1: {
      Name: "Subha",
      content: "Lorem ipsum",
      rev: 2.1,
    },
    review2: {
      Name: "urvi",
      content: "Lorem ipsum",
      rev: 2,
    },
    review3: {
      Name: "urvi",
      content: "Lorem ipsum",
      rev: 22,
    },
    review4: {
      Name: "dipesh",
      content: "Lorem ipsum",
      rev: 5,
    },
  };
  var x = document.getElementById(mediavideoid);
  const videoPause = () => {
    x?.pause();
  };
  const categorySliderRef = useRef();

  const CategorySliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    arrows: true,
    autoplay: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    nextArrow: <Arrow type="next" onclick={() => videoPause()} />,
    prevArrow: <Arrow type="prev" onclick={() => videoPause()} />,
  };
  function Arrow(props) {
    let className = props.type === "next" ? "nextArrow" : "prevArrow";
    className += " arrow " + props.className;
    return (
      <button
        onClick={() => {
          props.onClick();
          props.onclick();
        }}
        disabled={props.className?.includes("slick-disabled")}
        className={className}
      >
        {props.type === "next" ? nextIcon() : prevIcon()}
      </button>

      // <span className={className} onClick={props.onClick}>
      //   {char}
      // </span>
    );
  }
  const nextIcon = () => (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.46967 5.46967C9.76256 5.17678 10.2374 5.17678 10.5303 5.46967L16.5303 11.4697C16.8232 11.7626 16.8232 12.2374 16.5303 12.5303L10.5303 18.5303C10.2374 18.8232 9.76256 18.8232 9.46967 18.5303C9.17678 18.2374 9.17678 17.7626 9.46967 17.4697L14.9393 12L9.46967 6.53033C9.17678 6.23744 9.17678 5.76256 9.46967 5.46967Z"
        fill="#000"
      />
    </svg>
  );

  const prevIcon = () => (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5303 18.5303C14.2374 18.8232 13.7626 18.8232 13.4697 18.5303L7.46967 12.5303C7.17678 12.2374 7.17678 11.7626 7.46967 11.4697L13.4697 5.46967C13.7626 5.17678 14.2374 5.17678 14.5303 5.46967C14.8232 5.76256 14.8232 6.23744 14.5303 6.53033L9.06066 12L14.5303 17.4697C14.8232 17.7626 14.8232 18.2374 14.5303 18.5303Z"
        fill="#000"
      />
    </svg>
  );

  const trailer = [default_img];

  // useEffect(() => {
  //   let app_name = queryy.get("app_name") || "";
  //   setLoadingScreen(true);
  //   axiosApiInstance
  //     .get(`apps/?app_name=${app_name}`)
  //     .then((res) => {
  //       setAppDetails(res.data.detail[0]);
  //     })
  //     .finally(() => {
  //       setLoadingScreen(false);
  //       setTimeout(() => {
  //         firstLoad = false;
  //       }, 2000);
  //     });
  //   return () => {};
  // }, [fetchAppDetails]);

  // useEffect(() => {
  //   let app_name = queryy.get("app_name") || "";
  //   setLoadingScreen(true);

  //   axiosApiInstance
  //     .get(`apps/review?app_name=${app_name}`)
  //     .then((res) => {
  //       setReviewDetails(res.data.detail);
  //     })
  //     .finally(() => {
  //       setLoadingScreen(false);
  //       setTimeout(() => {
  //         firstLoad = false;
  //       }, 2000);
  //     });
  //   return () => {};
  // }, [fetchAppDetails]);

  // useEffect(() => {
  //   let app_name = queryy.get("app_name") || "";
  //   setLoadingScreen(true);

  //   axiosApiInstance
  //     .get(`apps/rating?app_name=${app_name}`)
  //     .then((res) => {
  //       setRatingDetails(res.data.detail);
  //     })
  //     .finally(() => {
  //       setLoadingScreen(false);
  //       setTimeout(() => {
  //         firstLoad = false;
  //       }, 2000);
  //     });
  //   return () => {};
  // }, [fetchAppDetails]);
  useEffect(() => {
    let app_name = queryy.get("app_name") || "";
    setLoadingScreen(true);

    axiosApiInstance
      .get(
        `apps/version/details/compatibility?app_name=${app_name}&version_name=latest`
      )
      .then((res) => {
        setAboutCompatibility(res.data.detail);
      })
      .finally(() => {
        setLoadingScreen(false);
        setTimeout(() => {
          firstLoad = false;
        }, 2000);
      });
    return () => {};
  }, [fetchAppDetails]);

  useEffect(() => {
    let app_name = queryy.get("app_name") || "";
    setLoadingScreen(true);

    axiosApiInstance
      .get(`apps/suggestions?app_name=${app_name}`)
      .then((res) => {
        setUsecaseSuggestions(res.data.detail);
      })
      .finally(() => {
        setLoadingScreen(false);
        setTimeout(() => {
          firstLoad = false;
        }, 2000);
      });
    return () => {};
  }, [fetchAppDetails]);

  return (
    <div className="_Usecase_wrapper_">
      {/* {usecaseSuggestions === [] && "no suggested apps found"} */}

      <Navbar sidenav>
        <div className="Usecase_body">
          <img src={bg} className="profile_bg" />
          <div className="body_content fadeIn">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h1>App Details</h1>
              <p
                className="goBack"
                onClick={() => {
                  themeContext.Role.current === "user"
                    ? history.push("/apps/serial/" + queryy.get("serial_number"))
                    : history.push("/apps");
                }}
              >
                Go Back
              </p>
            </div>
            <div className="Usecase_data">
              <Scroller
                autoHeightMax="67vh"
                verticalStyle={{
                  width: "0.3vw",
                  marginLeft: "-0.5vw",
                  backgroundColor: "#283e92",
                  // top: "10%"
                }}
              >
                <div className="usecase_main_wrapper">
                  <div className="usecase_subwrapper">
                    {trailer.map((vid) => (
                      <Hero
                        appname={appDetails?.app_name}
                        app_trailer={vid}
                        app_image={appDetails?.app_thumbnail}
                        developerName={appDetails?.developer_details?.full_name}
                        ratingsAndReview={appDetails?.ratings}
                        setState={setAppDetails}
                        fetchApp={fetchAppDetails}
                      />
                    ))}

                    <div className="partition_wrapper">
                      <div className="lhs_wrapper">
                        <div className="usecase_mid_subwrapper">
                          <div className="usecase_additionalimgs_wrapper">
                            <h4 className="additional_images">
                              Additional media
                            </h4>
                            <div className="images_wrapper"></div>
                          </div>
                          <div className="viewImages">{
                           console.log(  appDetails?.upload_media?.map((media, idx) => media.media_link))
                          }
                            {appDetails?.upload_media?.length === 1 ? (
                              appDetails?.upload_media?.map((media, idx) => {
                                return (
                                  <MediaCard
                                    mediavideoid={setMediaVidId}
                                    id={"viewimg_" + idx}
                                    key={idx}
                                    mediaType={media.media_type}
                                    viewMedia={media.media_link}
                                    idx={idx}
                                  />
                                );
                              })
                            ) : (
                              <Slider
                                {...CategorySliderSettings}
                                ref={categorySliderRef}
                              >
                                {appDetails?.upload_media?.map((media, idx) => {
                                  return (
                                    <MediaCard
                                      mediavideoid={setMediaVidId}
                                      id={"viewimg_" + idx}
                                      key={idx}
                                      mediaType={media.media_type}
                                      viewMedia={media.media_link}
                                      idx={idx}
                                    />
                                  );
                                })}
                              </Slider>
                            )}
                          </div>
                        </div>

                        <div className="usecase_lower_subwrapper">
                          <AppDescription
                            text={appDetails?.description}
                            date={appDetails?.uploaded}
                            choices={appDetails?.target_industries}
                            onclick={() => setAboutActive(true)}
                          />
                          <RatingsReviews
                            number={ratingDetails.ratings?.final_ratings}
                            onclick={() => setRatingsActive(true)}
                            bar={ratingDetails.ratings}
                            total={ratingDetails.total_ratings}
                            setState={setRatingDetails}
                            fetchApp={fetchAppDetails}
                          />
                          {Object.values(reviewDetails).map((element) => (
                            <ReviewCard
                              key={element.title}
                              reviewerName={"element.user?.full_name"}
                              content={"element.title"}
                              stars={element.ratings}
                              setState={setReviewDetails}
                              fetchApp={fetchAppDetails}
                            />
                          ))}
                        </div>
                      </div>
                      <div className="rhs_wrapper">
                        <Suggestions suggest={usecaseSuggestions.length} />
                        {Object.values(usecaseSuggestions).map(
                          (element, idx) => (
                            <SuggestApp
                              key={idx}
                              suggestName={element?.app_name}
                              suggestDeveloper={element.developer}
                              suggeststar={element.rating}
                              suggestThumb={element.app_thumbnail}
                              setState={setUsecaseSuggestions}
                              fetchApp={fetchAppDetails}
                              onclick={(e) => {
                                history.push({
                                  pathname: "/apps/usecase",
                                  search: `?app_name=${element?.app_name}`,
                                });
                                setFetchAppDetails(!fetchAppDetails);
                              }}
                              idx={idx}
                            />
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Scroller>
            </div>
          </div>
        </div>
      </Navbar>
      <AnimatedModal modalOpen={aboutActive}>
        {/* <Scroller
          autoHeightMax="60vh"
          verticalStyle={{
            width: "0.12vw",
            marginLeft: "0.2vw",
            backgroundColor: "#283e92",
          }}
        > */}
        {/* {open && (
            <div className="permission_wrapper">
              <div className="about_upper_wr  apper">
                <img
                  className="about_appImage"
                  src={appDetails.app_thumbnail}
                ></img>

                <div className="about_side_wrapper">
                  <h4>{appDetails.app_name}</h4>
                  <p>About This App</p>
                </div>
              </div>
              <div
                className="close_otp_modal"
                onClick={() => {
                  setAboutActive(false);
                }}
              >
                {closeIcon()}
              </div>
              <div
                onClick={() => {
                  setOpen(false);
                }}
              >
                <div className="permission_back_icon">
                  {next("next_icon")} App Permissions
                </div>
                <div className="permission_wrapper">
                  <p style={{ marginBottom: "3%" }}>
                    Showing permissions for all versions of this app
                  </p>
                  <p style={{ marginBottom: "3%" }}>This app has access to:</p>

                  <div className="permission_subwrapper">
                    <div className="permission_partition">
                      <div className="permission_lhs">
                        {Object.keys(permission).map((x, i) => (
                          <AboutDetails
                            heading={i % 2 == 0 && x.replace("_", " ")}
                            content={i % 2 == 0 && permission[x]}
                          />
                        ))}
                      </div>
                      <div className="permission_rhs">
                        {Object.keys(permission).map((x, i) => (
                          <AboutDetails
                            heading={i % 2 != 0 && x.replace("_", " ")}
                            content={i % 2 != 0 && permission[x]}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="close_otp_modal"
                  onClick={() => {
                    setAboutActive(false);
                  }}
                >
                  {closeIcon()}
                </div>
              </div>
            </div>
          )} */}
        {!open && (
          <div className="about_main_wrapper">
            <div className="about_upper_wrapper">
              <img
                className="about_appImage"
                src={appDetails?.app_thumbnail}
              ></img>

              <div className="about_side_wrapper">
                <h4 style={{ fontSize: "1.45vw" }}>{appDetails?.app_name}</h4>
                <p className="about_header">About this app</p>
              </div>
            </div>
            <div className="about_lower_wrapper">
              <div style={{ fontSize: "1.45vw" }}>
                {appDetails?.description}
              </div>
              <div className="details_subwrapper">
                <div className="details_partition">
                  <div className="details_lhs">
                    {Object.keys(about_details).map((x, i) => (
                      <AboutDetails
                        heading={i % 2 == 0 && x.replace("_", " ")}
                        content={i % 2 == 0 && about_details[x]}
                      />
                    ))}
                  </div>
                  <div className="details_rhs">
                    {Object.keys(about_details).map((x, i) => (
                      <AboutDetails
                        heading={i % 2 != 0 && x.replace("_", " ")}
                        content={i % 2 != 0 && about_details[x]}
                        id={i}
                        onclick={() => i === 3 && setOpen(true)}
                      />
                    ))}
                  </div>
                </div>
              </div>
              <div className="ver_compatibility_subwrapper">
                <div className="compatibility_partition">
                  <div className="compatibility_lhs">
                    {Object.keys(compatibility).map((x, i) => (
                      <AboutDetails
                        heading={i % 2 == 0 && x.replace("_", " ")}
                        content={
                          i % 2 == 0 &&
                          (compatibility[x] === undefined
                            ? "None"
                            : compatibility[x])
                        }
                        empty={x.length}
                      />
                    ))}
                  </div>
                  <div className="compatibility_rhs">
                    {Object.keys(compatibility).map((x, i) => (
                      <AboutDetails
                        heading={i % 2 != 0 && x.replace("_", " ")}
                        content={
                          i % 2 != 0 &&
                          (compatibility[x] === undefined
                            ? "None"
                            : compatibility[x])
                        }
                        empty={compatibility[x]}
                      />
                    ))}
                    {console.log(
                      Object.keys(compatibility).map(
                        (x) => compatibility[x] === undefined && "true"
                      )
                    )}
                  </div>
                </div>
              </div>
              {/* <div className="dependent_services_subwrapper">
                  <div className="dependent_services_partition">
                    <div className="dependent_services_lhs">
                      {Object.keys(dependent_services).map((x, i) => (
                        <AboutDetails
                          heading={i % 2 == 0 && x.replace("_", " ")}
                          content={i % 2 != 0 && dependent_services[x]}
                        />
                      ))}
                    </div>
                    <div className="dependent_services_rhs">
                      {Object.keys(dependent_services).map((x, i) => (
                        <AboutDetails
                          heading={i % 2 != 0 && x.replace("_", " ")}
                          content={i % 2 != 0 && dependent_services[x]}
                        />
                      ))}
                    </div>
                  </div>
                </div> */}

              <div
                className="close_otp_modal"
                onClick={() => {
                  setAboutActive(false);
                }}
              >
                {closeIcon()}
              </div>
            </div>
          </div>
        )}
        {/* </Scroller> */}
      </AnimatedModal>
      <AnimatedModal modalOpen={ratingsActive}>
        <Scroller
          autoHeightMax="32vw"
          verticalStyle={{
            width: "0.12vw",
            marginLeft: "0.2vw",
            backgroundColor: "#283e92",
          }}
        >
          <div className="ratings_main_wrapper">
            <div className="ratings_upper_wrapper">
              <img
                className="ratings_appImage"
                src={appDetails?.app_thumbnail}
              ></img>
              <div className="ratings_side_wrapper">
                <h4 style={{ fontSize: "1.45vw" }}>{appDetails?.app_name}</h4>
                <p style={{ fontSize: "1.145vw" }}>Ratings and reviews</p>
              </div>
            </div>

            {Object.values(reviewDetails).map((element) => (
              <ReviewCard
                key={element.title}
                reviewerName={"element.user?.full_name"}
                content={"element.title"}
                stars={element.ratings}
                setState={setReviewDetails}
                fetchApp={fetchAppDetails}
              />
            ))}
            <div
              className="close_otp_modal"
              onClick={() => {
                setRatingsActive(false);
              }}
            >
              {closeIcon()}
            </div>
          </div>
        </Scroller>
      </AnimatedModal>
      {LoadingScreen && <Loading type={"transparent"} text={"Loading"} />}
    </div>
  );
}
