import React, { useEffect } from "react";
import { useState } from "react";
import "./toggle.scss";
export default function Toggle({
  value = false,
  handleValue,
  id,
  disable = false,
  bolean,
}) {
  const [Value, setValue] = useState(value);

  useEffect(() => {
    if (Value) {
      document.querySelector("#" + id).style.right = "0";
      document.querySelector("#" + id).style.left = "unset";
      document.querySelector("#" + id).style.background =
        "linear-gradient(321deg,rgba(2, 84, 16, 0.8491771708683473) 5%,rgba(0, 255, 34, 1) 73%)";
    } else {
      document.querySelector("#" + id).style.left = "0";
      document.querySelector("#" + id).style.background =
        "linear-gradient(321deg, rgba(221,30,66,1) 5%, rgba(162,0,0,1) 73%)";
    }
  }, [Value]);

  return (
    <div
      className="toggle_container"
      style={{margin: bolean && "0 auto"}}
      onClick={() => {
        if (!disable) {
          setValue(!Value);
          handleValue(!Value);
        }
      }}
      role="button"
      aria-disabled={disable}
    >
      {!bolean ? (
        <div className="iconHolder" id={id}>
          {Value ? tick() : close()}
        </div>
      ) : (
        <div  className="iconHolder" id={id}></div>
      )}
    </div>
  );
}

const tick = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16.182}
    height={11.917}
    viewBox="0 0 16.182 11.917"
    className="tick fadeIn"
  >
    <path
      id="Vector"
      d="M0,6.152l3.955,3.955L14.061,0"
      transform="translate(1.061 1.061)"
      fill="none"
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    />
  </svg>
);

const close = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={12.499}
    height={12.499}
    viewBox="0 0 12.499 12.499"
    className="close fadeIn"
  >
    <g
      id="Group_15572"
      data-name="Group 15572"
      transform="translate(-1484.439 -359.439)"
    >
      <line
        id="Line_302"
        data-name="Line 302"
        x2={10.378}
        y2={10.378}
        transform="translate(1485.5 360.5)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeWidth={1.5}
      />
      <line
        id="Line_303"
        data-name="Line 303"
        x1={10.378}
        y2={10.378}
        transform="translate(1485.5 360.5)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeWidth={1.5}
      />
    </g>
  </svg>
);
