import React from "react";
import "../ViewApp/AboutDetails.scss";

export default function AboutDetails({ heading, content, id, onclick, empty }) {
  return (
    <div className="aboutdetails_main_wrapper">
      <div className="aboutdetails_content">
        {heading ? <h3 style={{fontSize: "1.45vw"}}>{heading}</h3> : ""}
        {/* <h3>{heading}</h3>   */}
        {/* {empty ? (
          <div id={"content_" + id} onClick={onclick}>
            {content}
          </div>
        ) : (
          "NA"
        )} */}
        {/* {console.log(empty)} */}
        <div className="aboutdetails_subcontent"  id = {"content_"+id} onClick={onclick}>{content}</div>
      </div>
    </div>
  );
}
