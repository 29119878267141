import React, { useState } from "react";
import { closeIcon } from "../../helper/icons";
import Button from "../Button/Button";
import InputBox from "../Inputbox/InputBox";
import AnimatedModal from "../Modal/AnimatedModal";
import "./resetpassword.scss";
export default function ResetPassword({
  ShowResetPassword,
  setConfirm,
  handlePassword,
  oldPassBolean,
}) {
  const [OldPassword, setOldPassword] = useState("");
  const [Password, setPassword] = useState("");
  const [ConfirmPassword, setConfirmPassword] = useState("");
  const [oldType, setOldType] = useState("password");
  const [oldType2, setOldType2] = useState("password");
  const [oldType3, setOldType3] = useState("password");
  const [errors, setErrors] = useState({
    isOldPassWordEmpty: false,
    isPasswordEmpty: false,
    isCPasswordEmpty: false,
  });
  //Password Validation
  const [isLowerCase, setisLowerCase] = useState(false);
  const [isUpperCase, setisUpperCase] = useState(false);
  const [isNumber, setisNumber] = useState(false);
  const [areMinEightChar, setareMinEightChar] = useState(false);
  const [areSpecialCharacters, setareSpecialCharacters] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [PassError, setPassError] = useState(false);

  const checkPassword = (password) => {
    if (password.match(/[a-z]/g)) {
      setisLowerCase(true);
    } else {
      setisLowerCase(false);
    }

    if (password.match(/[A-Z]/g)) {
      setisUpperCase(true);
    } else {
      setisUpperCase(false);
    }

    if (password.match(/[0-9]/g)) {
      setisNumber(true);
    } else {
      setisNumber(false);
    }

    if (password.length >= 8) {
      setareMinEightChar(true);
    } else {
      setareMinEightChar(false);
    }

    if (password.match(/[!@#$%&()+]/g)) {
      setareSpecialCharacters(true);
    } else {
      setareSpecialCharacters(false);
    }
    if (
      !password.match(/[a-z]/g) ||
      !password.match(/[A-Z]/g) ||
      !password.match(/[0-9]/g) ||
      password.length < 8 ||
      !password.match(/[!@#$%&()+]/g)
    ) {
      setPassError(true);
    } else {
      setPassError(false);
    }

    if (
      password.match(/[a-z]/g) &&
      password.match(/[A-Z]/g) &&
      password.match(/[0-9]/g) &&
      password.length >= 8 &&
      password.match(/[!@#$%&()+]/g)
    ) {
      setDropdown(false);
    } else {
      setDropdown(true);
    }
  };

  const handleOldPasswordType = (type) => {
    if (!type) {
      setOldType3("password");
    } else {
      setOldType3("text");
    }
  };

  const handlePasswordType = (type) => {
    if (!type) {
      setOldType("password");
    } else {
      setOldType("text");
    }
  };

  const handleConfirmPassword = (type) => {
    if (!type) {
      setOldType2("password");
    } else {
      setOldType2("text");
    }
  };
  const clearError = (name) => {
    let _errors = { ...errors };
    _errors[name] = false;
    setErrors({ ..._errors });
  };
  const postResetUserPassword = () => {
    let _errors = { ...errors };
    _errors.isUsernameEmpty = false;
    if (OldPassword === "" && oldPassBolean)
      _errors["isOldPassWordEmpty"] = true;
    if (Password === "" || PassError) _errors["isPasswordEmpty"] = true;
    if (ConfirmPassword === "") _errors["isCPasswordEmpty"] = true;
    if (Password !== ConfirmPassword) {
      _errors["isPasswordEmpty"] = true;
      _errors["isCPasswordEmpty"] = true;
    }
    if (Password && ConfirmPassword && Password === ConfirmPassword) {
      _errors["isPasswordEmpty"] = false;
      _errors["isCPasswordEmpty"] = false;
    }
    if (PassError) {
      _errors["isPasswordEmpty"] = true;
      setDropdown(true);
    }
    setErrors({ ..._errors });
    if (Object.values(_errors).includes(true) || PassError) {
      return;
    }
    if (oldPassBolean) {
      handlePassword(Password, OldPassword);
    } else handlePassword(Password);
  };

  return (
    <AnimatedModal
      modalOpen={ShowResetPassword}
      className="reset_password_modal"
    >
      <div
        className="close_otp_modal"
        onClick={() => {
          setConfirm(true);
          setOldPassword("");
          setPassword("");
          setConfirmPassword("");
        }}
      >
        {closeIcon()}
      </div>
      <h1 className="modal_head">Reset Password</h1>
      <div>
        {oldPassBolean && (
          <InputBox
            id="passwordConfirm"
            autoFocus
            password
            header="Current Password"
            type={oldType3}
            typeValue={(data) => handleOldPasswordType(data)}
            onChange={(e) => {
              const value = e.target.value;
              const regex = /^[a-zA-Z0-9!@#$%&()+]*$/;
              if (value.match(regex) || value === "") {
                setOldPassword(value);
              }
            }}
            value={OldPassword}
            error={errors["isOldPassWordEmpty"]}
            onFocus={() => clearError("isOldPassWordEmpty")}
            // onKeyDown={(e) => {
            //   if (e.keyCode === 13) {
            //     postData();
            //   }
            // }}
          />
        )}
        <div style={{ height: "1vw" }} />
        <InputBox
          id="reset_password"
          header="Enter New Password"
          onInput={(e) => checkPassword(e.target.value)}
          password
          type={oldType}
          typeValue={(data) => handlePasswordType(data)}
          onChange={(e) => {
            const value = e.target.value;
            const regex = /^[a-zA-Z0-9!@#$%&()+]*$/;
            if (value.match(regex) || value === "") {
              setPassword(value);
            }
          }}
          error={errors["isPasswordEmpty"]}
          value={Password}
          onFocus={() => clearError("isPasswordEmpty")}
          onBlur={() => {
            setDropdown(false);
          }}
        />
        {dropdown && (
          <div className="dropdown">
            <div className="dropdown-content">
              <p>Password must contain:</p>

              <p className={isUpperCase ? "valid" : "invalid"}>
                An <b>Uppercase</b> letter
              </p>
              <p className={isLowerCase ? "valid" : "invalid"}>
                A <b>Lowercase</b> letter
              </p>
              <p className={isNumber ? "valid" : "invalid"}>
                A <b>number</b>
              </p>
              <p className={areMinEightChar ? "valid" : "invalid"}>
                Minimum <b>8 characters</b>
              </p>
              <p className={areSpecialCharacters ? "valid" : "invalid"}>
                A Special Character
              </p>
            </div>
          </div>
        )}
      </div>
      <div style={{ height: "1vw" }} />
      <InputBox
        id="reset_password"
        header="Confirm Password"
        password
        disabled={!Password}
        type={oldType2}
        typeValue={(data) => handleConfirmPassword(data)}
        onChange={(e) => {
          const value = e.target.value;
          const regex = /^[a-zA-Z0-9!@#$%&()+]*$/;
          if (value.match(regex) || value === "") {
            setConfirmPassword(value);
          }
        }}
        error={errors["isCPasswordEmpty"]}
        value={ConfirmPassword}
        onFocus={() => clearError("isCPasswordEmpty")}
        onBlur={() => {
          let _errors = { ...errors };
          if (Password !== ConfirmPassword) {
            _errors["isPasswordEmpty"] = true;
            _errors["isCPasswordEmpty"] = true;
          } else {
            _errors["isPasswordEmpty"] = false;
            _errors["isCPasswordEmpty"] = false;
          }
          setErrors({ ..._errors });
        }}
        onKeyDown={(e) => {
          if (e.keyCode === 13) {
            postResetUserPassword();
          }
        }}
      />
      <div style={{ height: "0.3vw" }} />

      <div className="res_success_btn" style={{ margin: "1vw 0" }}>
        <Button
          type="gradient"
          bg
          name="Submit"
          onClick={postResetUserPassword}
        />
      </div>
    </AnimatedModal>
  );
}
