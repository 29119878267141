import React, { useState, useEffect } from "react";
import Button from "../../../components/Button/Button";
import Dropdown from "../../../components/Dropdown/Dropdown";
import AnimatedModal from "../../../components/Modal/AnimatedModal";
import { closeIcon } from "../../../helper/icons";
import { axiosApiInstance } from "../../../helper/request";
import { notify } from "../../../Routes";
import { motion } from "framer-motion";
import Counter from "../../../components/Counter/Counter";
import Scroller from "../../../components/Scroller/ScrollBar";
import { useRef } from "react";
import { ThemeContext } from "../../../helper/context";
import { useContext } from "react";
let extraData = {
  totalCount: 0,
  pricing_id: "",
};
let _count = 0;
export default function UpgradeLicence({
  RenewLicence,
  setRenewLicence,
  selectedDevice,
}) {
  const { getCart } = useContext(ThemeContext);
  const totalRef = useRef(null);
  const [isLoading, setisLoading] = useState(true);
  const [Data, setData] = useState([]);
  const [SelectedType, setSelectedType] = useState("monthly");

  const handleCounter = (idx, key, count, type) => {
    let _data = [...Data];
    if (type == "increment") {
      _data[idx][key].count += 1;
    } else {
      if (count >= 2) {
        _data[idx][key].count -= 1;
      }
    }
    setData([..._data]);
  };

  const setDefaultCount = (data = Data) => {};

  const getExistingLincence = () => {};

  const getData = () => {
    setisLoading(true);
    console.log("getData");
    axiosApiInstance
      .get("pricing/individual/device", {
        headers: {
          "manufacturer-key": selectedDevice.manu_key,
        },
      })
      .then((res) => {
        console.log(res.data);
        extraData.pricing_id = res.data.id;
        if (selectedDevice.licence) {
          // get existing licence of the device
          getExistingLincence(res.data.pricing_details);
        } else {
          let _data = [...res.data.pricing_details];
          for (let i = 0; i < _data.length; i++) {
            let _keys = Object.keys(_data[i]);
            for (let j = 0; j < _keys.length; j++) {
              if (_keys[j] != "attribute_name") {
                _data[i][_keys[j]].count = 1;
              }
            }
          }
          setData([..._data]);
        }
      })
      .catch((err) => {
        console.log(err);
        notify({});
      })
      .finally(() => {
        setisLoading(false);
      });
  };

  const postData = () => {
    let license_details = {};
    for (let i = 0; i < Data.length; i++) {
      license_details[Data[i].attribute_name] = Data[i][SelectedType].count;
    }
    let body = {
      device: {
        serial_number: selectedDevice.serial_number,
        device_name: selectedDevice.device_name,
      },
      pricing_id: extraData.pricing_id,
      quantity: 1, //may change in future
      item_type: "device", // "usecase" for usecase
      item_details: {
        license_type: "add", //add, upgrade or renew
        license_duration: SelectedType,
        license_details, // att:count
      },
    };
    console.log(body);
    setisLoading(true);
    axiosApiInstance
      .post("order/cart", body)
      .then((res) => {
        setRenewLicence(false);
        getCart();
        setTimeout(() => {
          totalRef.current.textContent = "$" + _count;
        }, 10);
        notify({
          msg: "Product added to cart",
          type: "success",
        });
      })
      .catch((err) => {
        console.log(err);
        if(err.response.status === 409){
           notify({
          msg: "Product already exists in cart",
        });
        }else{
        notify({});
        }
      })
      .finally(() => {
        setisLoading(false);
      });
  };

  useEffect(() => {
    // if licence is present then we need to call another api to fetch existing licence data and update the counter(we also need to check if the licence is expired or not, if licence is expire then counter should be 1)
    if (RenewLicence) {
      getData();
    }
  }, [RenewLicence]);

  useEffect(() => {
    if (Data.length > 0 && !isLoading) {
      let _data = [...Data];
      _count = 0;
      for (let i = 0; i < _data.length; i++) {
        let multiply =
          _data[i][SelectedType].amount * _data[i][SelectedType].count;
        _count += multiply;
      }
      _count = _count.toFixed(2);
      extraData.totalCount = _count;
      totalRef.current.textContent = "$" + _count;
      console.log(totalRef);
    }
  }, [Data, SelectedType]);

  return (
    <AnimatedModal modalOpen={RenewLicence} className="upgrade_licence_modal">
      {console.log(_count)}
      <div
        className="close_otp_modal"
        onClick={() => {
          setRenewLicence(false);
        }}
      >
        {closeIcon()}
      </div>

      <Dropdown
        label={"Pricing Type"}
        className="adjust_dd"
        id={"pricing_type"}
        optionsList={["monthly", "quarterly", "yearly", "lifetime"]}
        handleOption={(data) => {
          setSelectedType(data);
        }}
        defaultText={SelectedType}
      />
      {isLoading && (
        <div className="s_wrapper">
          {[1, 2].map((item) => (
            <SkeletonCard />
          ))}
        </div>
      )}
      {!isLoading ? (
        <React.Fragment>
          <Scroller autoHeightMax={"31vw"} autoHeightMin={"31vw"}>
            <motion.table
              cellPadding="0"
              cellSpacing="0"
              border="0"
              className="data_table"
            >
              <tbody>
                {Data?.map((item, idx) => (
                  <tr key={item.attribute_name}>
                    <td className="sr">{item.attribute_name}</td>
                    <td className="plans">
                      {Object.keys(item)?.map((item2, idx2) => {
                        if (item2 !== "attribute_name") {
                          return (
                            <div
                              className="plan-block"
                              aria-disabled={item2 !== SelectedType}
                              role="button"
                            >
                              <div className="plan_title">
                                {item2.replace(/_/g, " ")}
                              </div>
                              <div className="plan_price">
                                ${item[item2].amount}
                              </div>
                              <div className="plan_volumes">
                                <Counter
                                  required
                                  label={"Quantity"}
                                  value={item[item2].count}
                                  handleDecrement={() =>
                                    handleCounter(
                                      idx,
                                      item2,
                                      item[item2].count,
                                      "decrement"
                                    )
                                  }
                                  handleIncrement={() =>
                                    handleCounter(
                                      idx,
                                      item2,
                                      item[item2].count,
                                      "increment"
                                    )
                                  }
                                  disabled={item2 !== SelectedType}
                                  handleInput={(e) => {
                                    let { value } = e.target;
                                    if (value != 0 || value === "") {
                                      let _data = [...Data];
                                      _data[idx][item2].count = value;
                                      setData([...Data]);
                                    }
                                  }}
                                  onBlur={() => {
                                    let _data = [...Data];
                                    if (_data[idx][item2].count === "") {
                                      _data[idx][item2].count = "1";
                                      setData([...Data]);
                                    }
                                  }}
                                />
                              </div>
                            </div>
                          );
                        }
                        return null;
                      })}
                    </td>
                  </tr>
                ))}
              </tbody>
            </motion.table>
          </Scroller>
          <div className="price-block">
            Total Price: <span className="total_count" ref={totalRef}></span>
          </div>
          <Button
            name={"Add to cart"}
            onClick={postData}
            style={{
              display: "block",
              width: "9vw",
              float: "right",
              marginRight: "1vw",
              marginTop: "0.5vw",
              padding: "0.4vw 0",
            }}
          />
        </React.Fragment>
      ) : null}
    </AnimatedModal>
  );
}

const SkeletonCard = () => {
  return (
    <div className="skeleton skeleton--card">
      <div className="skeleton--content">
        <div className="skeleton--content-wrapper">
          <div className="s_loader skeleton--title"></div>
        </div>
        {/* <div className="skeleton--content-wrapper2">
          <div className="s_loader skeleton--line"></div>
          <div className="s_loader skeleton--line"></div>
          <div className="s_loader skeleton--line"></div>
        </div> */}
      </div>
    </div>
  );
};
