import React, { Component } from "react";
import { useEffect } from "react";
import Loading from "../../../../../components/Loading/Loading";
import { axiosApiInstance } from "../../../../../helper/request";
import "../VersionDetails/BasicDetails.scss";
import Content from "./Content";
import ContentCard from "./ContentCard";
import image from "../../../../../assets/images/dummy.png";


export default class BasicDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      basicdetailsData: {
        // version_name: "Mask detection 4.1.0",
        // suffix: "Beta",
        // visibility: "Public",
      },

      basicdetailsContentCard: {
        // target_industries: ["Healthcare", "Banking", "banking", "BFSI"],
        // release_notes:
        //   "Lorem ipsum dolor sit, amet consectetur adipisicing elit. In illo maxime assumenda soluta accusantium quos doloribus animi necessitatibus odit libero, voluptatem, ut alias laborum excepturi quisquam. Deleniti atque corrupti tenetur.Lorem ipsum dolor sit, amet consectetur adipisicing elit. In illo maxime assumenda soluta accusantium quos doloribus animi necessitatibus odit libero, voluptatem, ut alias laborum excepturi quisquam. Deleniti atque corrupti tenetur.",
      },
      verAppDetailsData: {
        Name: "Person Detection",
        Status: "Active",
      },
      LoadingScreen: false,
    };
  }
  app_name = window.location.search.split("=")[1].split("?")[0];
  versionName = window.location.search.split("=")[2];

  fetchBasicDetails = () => {
    let arr = [];
    let contentData = {};
    let contentCardData = {};

    this.setState({ LoadingScreen: true });

    axiosApiInstance
      .get(
        `apps/version/details/basic?app_name=${this.app_name}&version_name=${this.versionName}`
      )
      .then((res) => {
        arr.push(res.data.detail);
        arr.map((details) => {
          contentData = {
            version_name: details["version_name"],
            suffix: details["version_type"],
            visibility: details["visibility"],
          };
          contentCardData = {
            Target_industries: details["target_industries"],
            Release_note: details["changelog"],
          };
        });
        this.setState({
          basicdetailsData: contentData,
          basicdetailsContentCard: contentCardData,
        });
      })
      .finally(() => {
        this.setState({ LoadingScreen: false });
        setTimeout(() => {}, 2000);
      });
  };
  componentDidMount() {
    this.fetchBasicDetails();
  }

  demo = "basicdetails";
  render() {
    return (
      <div>
       
      <div className="basicDetails_main_wrapper">
     
     <div className="basicDetails_subwrapper">
       <p className="Basic_tag">Basic details</p>

       <div className="basicDetails_upper_wrapper">
         <Content contentData={this.state.basicdetailsData} />
       </div>
       <div className="basicDetails_lower_wrapper">
         <ContentCard
           basicdetailsContentCard={this.state.basicdetailsContentCard}
         />
       </div>
     </div>
     {this.LoadingScreen && (
       <Loading type={"transparent"} text={"Loading"} />
     )}
   </div>
      </div>

    );
  }
}
