import React, { Component, useContext } from "react";
import Navbar from "../../../components/Navbar/Navbar";
import "./VersionDetails.scss";
import bg from "../../../assets/images/background.png";
import HeroSidebar from "./Component/VersionDetails/HeroSidebar";
import BasicDetails from "./Component/VersionDetails/BasicDetails";
import { withRouter } from "react-router-dom";
import { ThemeContext } from "../../../helper/context";

export default class VersionDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: BasicDetails,
    };
    this.data = this.data.bind(this);
  }
  data(nextState) {
    this.setState(nextState);
  }
  appname = window.location.search.split("?")[1];
  // serialNumber = window.location.search.split("serial_number=")[1];
  //  themeContext = useContext(ThemeContext);

  render() {
    return (
      <div className="_VersionDetails_wrapper_">
        {}
        <Navbar sidenav>
          <div className="VersionDetails_body">
            {console.log(window.location.search.split("&version_name"), "window.location.search")}
            <img src={bg} className="profile_bg" />
            <div className="body_content fadeIn">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {console.log(this.serialNumber, "thissss")}
                <h1 style={{ fontSize: "1.667vw" }}>App Version Details</h1>
                <p
                  className="goBack"
                  // onClick={() => this.props.history.push("/apps/addedversion?" + this.appname)}
                  onClick={() => {
                       this.props.history.push(
                          "/apps/addedversion" + window.location.search.split("&version_name")[0]
                        )
                     
                  }}
                >
                  Go Back
                </p>
              </div>
              <div className="VersionDetails_data">
                <div className="VersionDetails_partition_wrapper">
                  <div className="VersionDt_sidebar">
                    <HeroSidebar
                      // selected={this.state.selected}
                      setselected={this.data}
                    />
                  </div>

                  <div className="VersionDt_rhs_wrapper">
                    {this.state.selected !== "" && <this.state.selected />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Navbar>
      </div>
    );
  }
}
