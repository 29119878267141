import React, { useContext, useState, useRef, useEffect } from "react";
import { motion } from "framer-motion";
import "./devicedetail.scss";
import "../../user/Profile/profile.scss";
import Navbar from "../../../components/Navbar/Navbar";
import bg from "../../../assets/images/background.png";
import { container, item as Items } from "../../../helper/motions";
import InputBox from "../../../components/Inputbox/InputBox";
import Button from "../../../components/Button/Button";
import Details from "./Details";
import AnimatedModal from "../../../components/Modal/AnimatedModal";
import {
  activeIcon,
  closeIcon,
  deviceIcon,
  inactiveIcon,
} from "../../../helper/icons";
import ServiceRunning from "./ServiceRunning";
import { ThemeContext } from "../../../helper/context";
import UserInfo from "./UserInfo";
import DeployedUsecase from "./DeployedUsecase";
import { useHistory } from "react-router-dom";
import Searchbar from "../../../components/Searchbar/Searchbar";
import Scroller from "../../../components/Scroller/ScrollBar";
import { useDebouncedEffect } from "../../../helper/useDebounce";
import { axiosApiInstance } from "../../../helper/request";
import { Radio, Radio2 } from "../../../components/Radio/Radio";
import { LoadingText } from "../../auth/register/AuthRegister";
import Loading from "../../../components/Loading/Loading";
import Selector from "../../../components/Selector/Selector";
import { useQuery } from "../../../helper/storage";
let msg = {
  type: "alert",
  header: "Error",
  errorText: "Something Went Wrong",
};
let _userInfo = null;
let _superInfo = null;
let _userAPI = null;
export default function DeviceDetail(props) {
  const userScroll = useRef();
  const history = useHistory();
  const query = useQuery();
  const deviceName = query.get("name");
  const themeContext = useContext(ThemeContext);
  const [activeSetting, setactiveSetting] = useState("Device Details");
  const [OperatorModal, setOperatorModal] = useState(false);
  const [OperatorLoading, setOperatorLoading] = useState(false);
  const [LoadingScreen, setLoadingScreen] = useState(false);
  const [SearchText, setSearchText] = useState("");
  const [ShowAlert, setShowAlert] = useState(false);
  const [UserList, setUserList] = useState([]);
  const [OperatorDeleteConfirm, setOperatorDeleteConfirm] = useState(false);
  const [headerData, setHeadersData] = useState();
  const params = new URLSearchParams(window.location.search);
  const [ErrorModal, setErrorModal] = useState({
    showPop: false,
    msg: "",
    type: "alert",
    header: "",
  });
  const [SelectedUser, setSelectedUser] = useState([]);
  useDebouncedEffect(
    () => (SearchText ? getUser() : undefined),
    [SearchText],
    1000
  );
  const [SettingList, setSettingList] = useState([
    "Device Details",
    "User Details",
    "Deployed Usecase",
  ]);
  const [ModalOpen, setModalOpen] = useState(false);
  const [FullName, setFullName] = useState("");
  const [Status, setStatus] = useState(true);
  const [selectedAppType, setSelectedAppType] = useState("");
  const getKeyCode = (e) => {
    switch (e.keyCode) {
      case 38:
        arrowKey(e, "up");
        break;

      case 40:
        arrowKey(e, "down");
        break;

      case 13:
        enterKey(e);
        break;
    }
  };
  const deleteOperator = (headerData) => {
    console.log(headerData, "hekllollllllll");
    axiosApiInstance
      .delete("device/operator/remove", {
        headers: headerData,
      })
      .then((res) => {
        console.log(res, "ressssssss");
        _userAPI();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const arrowKey = (e, type) => {
    let myReferenceDiv = document.querySelector("._span");
    let active_flag = document.querySelector(".active_flag");
    let active_next = active_flag?.nextElementSibling;
    let active_prev = active_flag?.previousElementSibling;
    let next = myReferenceDiv?.nextElementSibling;
    let prev = myReferenceDiv?.previousElementSibling;
    if (type === "down") {
      if (!active_flag) {
        next.classList.add("active_flag");
      } else {
        if (active_next) {
          active_flag.classList.remove("active_flag");
          active_next.classList.add("active_flag");
          var childElement =
            document.querySelector(".active_flag").offsetTop -
            myReferenceDiv.offsetHeight -
            20;
          userScroll?.current?.scrollTop(childElement);
        }
      }
    }
    if (type === "up") {
      if (active_prev && !active_prev.classList.contains("_span")) {
        active_flag.classList.remove("active_flag");
        active_prev.classList.add("active_flag");
        var childElement =
          document.querySelector(".active_flag").offsetTop -
          myReferenceDiv.offsetHeight -
          20;
        userScroll?.current?.scrollTop(childElement);
      }
    }
  };

  const enterKey = () => {
    //  let active_flag = document.querySelector(".active_flag");
    //  let dataCity = active_flag.getAttribute("data-city");
    //  let dataCountry = active_flag.getAttribute("data-country");
    //  let dataState = active_flag.getAttribute("data-state");
    //  console.log(active_flag, dataCity, dataCountry, dataState);
    //  if (dataCity) {
    //    isDataEmpty = null;
    //    setCity(dataCity);
    //    setLocationSelected((prevState) => ({
    //      ...prevState,
    //      isCitySelected: true,
    //    }));
    //    setLocationData((prevState) => ({
    //      ...prevState,
    //      citySuggestion: [],
    //    }));
    //  }
    //  if (dataState) {
    //    values.state = dataState;
    //    isExact = true;
    //    isDataEmpty = null;
    //    setState(dataState);
    //    setLocationSelected((prevState) => ({
    //      ...prevState,
    //      isStateSelected: true,
    //    }));
    //  }
    //  if (dataCountry) {
    //    isExact = true;
    //    isDataEmpty = null;
    //    values.country = dataCountry;
    //    setCountry(dataCountry);
    //    setLocationSelected((prevState) => ({
    //      ...prevState,
    //      isCountrySelected: true,
    //    }));
    //    setLocationLoading((prevState) => ({
    //      ...prevState,
    //      state: true,
    //    }));
    //  }
  };
  const handlePermission = (idx, name) => {
    console.log(name, "nameeeeeeeeee");
    // let _data = [...SelectedUser];
    // if (!_data[idx].permissions.includes(name)) {
    //   _data[idx].permissions.push(name);
    //   setSelectedUser([..._data]);
    // } else {
    //   const newArr = _data[idx].permissions.filter((e) => e !== name);
    //   _data[idx].permissions = [...newArr];
    //   console.log(newArr, "newArr");
    //   setSelectedUser([..._data]);
    // }
    //==================
    // let _data = [...SelectedUser];
    // if (name === "canView") {
    //   _data[idx].permissions = "canView";

    //   setSelectedUser([..._data]);
    // } else {
    //   _data[idx].permissions = "canEdit";
    //   setSelectedUser([..._data]);
    // }
  };
  const getUser = () => {
    console.log("getUser");
    if (!SearchText) {
      return;
    }

    setOperatorLoading(true);
    axiosApiInstance
      .get("user/username/filter?username=" + SearchText)
      .then((res) => {
        console.log(_userInfo, res.data);
        let _data = res.data.filter((val) => !_userInfo.includes(val));
        setUserList([..._data]);
        console.log(_data);
      })
      .catch((err) => {})
      .finally(() => {
        setOperatorLoading(false);
      });
  };

  const postOperatorData = () => {
    axiosApiInstance
      .post("device/operator/add", SelectedUser, {
        headers: {
          "serial-number": props.match.params.idee,
        },
      })
      .then((res) => {
        console.log(res.data);
        _userAPI();
        setOperatorModal(false);
        setSearchText("");
        setUserList([]);
        setSelectedUser([]);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.detail === "Forbidden No License found") {
          msg.errorText = "No License found";
          msg.header = "Error";
          msg.type = "alert";
          setShowAlert(true);
          setTimeout(() => {
            setShowAlert(false);
          }, 3000);
        }
      })
      .finally(() => {
        setOperatorModal(false);
      });
  };

  useEffect(() => {
    if (OperatorModal) {
      getExcludedUsername();
    }
  }, [OperatorModal]);

  const getExcludedUsername = () => {
    axiosApiInstance
      .get("device/fetch/users/username", {
        headers: {
          "serial-number":  props.match.params.idee,
        },
      })
      .then((res) => {
        _userInfo = [...res.data];
        console.log(res.data);
        getExcludedSuperadmin();
      })
      .catch((err) => {});
  };
  const getExcludedSuperadmin = () => {
    axiosApiInstance
      .get("user/username/superadmin")
      .then((res) => {
        _userInfo.push(res.data.superadmin);
      })
      .catch((err) => {});
  };
  return (
    <div className="__profile_wrapper__ __device_wrapper__ ">
      <Navbar sidenav>
        <div className="profile_body fadeIn">
          {console.log(props.match.params.idee, "props.match.params.idee")}
          <img src={bg} className="profile_bg" />
          <div className="profile_content">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h1>{activeSetting}</h1>
              <p
                className="goBack"
                onClick={() =>
                  history.push("/device" + window.location.search.split("&")[0])
                }
                // onClick={() => history.push("device?page=1&size=10")}
              >
                Go Back
              </p>
            </div>
            <div className="profile_data">
              <motion.div
                variants={container}
                exit="exit"
                initial="hidden"
                animate="visible"
                className="profile_left"
              >
                <motion.div variants={Items} className="profile_holder">
                  <div className="profile_image_container">
                    {deviceIcon()}
                    {Status ? activeIcon() : inactiveIcon()}
                  </div>
                  <h2
                    style={{
                      color:
                        themeContext.Theme === "Light"
                          ? "var(--primary)"
                          : "#f2f4f8",
                      textTransform: "capitalize",
                    }}
                  >
                    {deviceName}
                  </h2>
                </motion.div>
                {SettingList.map((item) => (
                  <motion.div
                    className={"pro_option"}
                    style={{
                      color:
                        themeContext.Theme === "Light"
                          ? "var(--primary)"
                          : "#f2f4f8",
                    }}
                    variants={Items}
                    key={item}
                    onClick={() => {
                      if (item === "Purchase License") {
                        setModalOpen(true);
                      } else {
                        setactiveSetting(item);
                      }
                    }}
                  >
                    {item}
                    {item === activeSetting && (
                      <div className="arrow-right fadeIn" />
                    )}
                  </motion.div>
                ))}
              </motion.div>
              {activeSetting === "Device Details" && (
                <Details id={props.match.params.idee} history={history} />
              )}
              {activeSetting === "User Details" && (
                <UserInfo
                SelectedUser={SelectedUser}
                  id={props.match.params.idee}
                  theme={themeContext.Theme}
                  handleModal={() => {
                    setOperatorModal(true);
                  }}
                  handleAlert={(bool, data) => {
                    // if (bool) {
                    //   msg = { ...data };
                    //   setShowAlert(bool);
                    // }
                  }}
                  getUserAPI={(data) => {
                    console.log(data);
                    _userAPI = data;
                  }}
                  handleDelete={(headers) => {
                    setOperatorDeleteConfirm(true);
                    setHeadersData(headers);
                  }}
                  handlePermission={(data, username) => {
                    console.log(data);
                    let _data = [...data];
                    data.forEach((element, index) => {
                      if (element === "Editor") {
                        // _data[index] = "canEdit";
                        _data[index] = "";
                      } else {
                        // _data[index] = "canView";
                        _data[index] = "";
                      }
                    });
                    console.log(_data);
                    axiosApiInstance
                      .patch(
                        "device/operator/permission",
                        {
                          username,
                          permissions: [..._data],
                        },
                        {
                          headers: {
                            "serial-number": props.match.params.idee,
                          },
                        }
                      )
                      .then((res) => {
                        console.log(res);
                      });
                  }}
                />
              )}
              {/* {activeSetting === "Deployed Usecase" && <DeployedUsecase />}
              {activeSetting === "Service Running" && <ServiceRunning />} */}
              {activeSetting === "Deployed Usecase" && (
                <div className="deployed_usecase_nodata">No Usecases Found</div>
              )}
            </div>
          </div>
        </div>
      </Navbar>

      <AnimatedModal
        modalOpen={OperatorModal}
        className="operator_modal_adjust"
      >
        <div
          className="close_otp_modal"
          onClick={() => {
            setOperatorModal(false);
            setUserList([]);
            setSelectedUser([]);
            setSearchText("");
          }}
        >
          {closeIcon()}
        </div>
        <p className="continue_msg" style={{ marginTop: "1vw" }}>
          Add operator
        </p>
        <Searchbar
          placeholder="Search by username"
          handleSearch={(e) => {
            setSearchText(e.target.value);
            if (e.target.value === "") {
              setUserList([]);
            }
          }}
          onKeyDown={getKeyCode}
          autoFocus
          value={SearchText}
        />
        <div style={{ marginTop: "1vw" }} />
        {OperatorLoading && <LoadingText />}
        {UserList.length > 0 && (
          <ul className="autocomplete-results">
            <Scroller autoHeightMax="15vw" ref={userScroll}>
              <span className="_span" />
              {UserList?.map((item) => (
                <li
                  key={item}
                  className="options"
                  onClick={() => {
                    setSearchText("");
                    setUserList([]);
                    _userInfo.push(item);
                    setSelectedUser((prev) => {
                      return [
                        ...prev,
                        {
                          username: item,
                          // permissions: ["canView"],
                        },
                      ];
                    });
                  }}
                >
                  {item}
                </li>
              ))}
            </Scroller>
          </ul>
        )}
        {SelectedUser?.length > 0 && (
          <div className="selected_user">
            <Scroller autoHeightMax="14vw">
              {SelectedUser.map((item, idx) => (
                <div className="selected_user_option">
                  <p className="name">{item.username}</p>
                  <div className="permissions">
                    {/* <div className="permission_options">
                      <p>Viewer</p>
                      <Radio
                        name={""}
                        id={"dev_" + idx}
                        checked={item.permissions.includes("canView")}
                        onClick={() => {
                          handlePermission(idx, "canView");
                        }}
                        className="adjust_cbb"
                      />
                    </div>
                    <div className="permission_options">
                      <p>Editer</p>
                      <Radio
                        name={""}
                        id={"devv_" + idx}
                        checked={item.permissions.includes("canEdit")}
                        onClick={() => {
                          handlePermission(idx, "canEdit");
                        }}
                        className="adjust_cbb"
                      />
                    </div> */}
                    {/* <div class="permission_options">
                      <input
                        className="adjust_cbb"
                        type="radio"
                        name="Viewer1"
                        id=""
                        value="option1"
                        // checked
                        onClick={() => {
                          handlePermission(idx, "canView");
                        }}
                      />
                      <label class="form-check-label" for="Viewer">
                        Viewer
                      </label>
                    </div>

                    <div class="permission_options">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="Viewer1"
                        id="Editer"
                        value="option2"
                        onClick={() => {
                          handlePermission(idx, "canEdit");
                        }}
                      />
                      <label class="form-check-label" for="exampleRadios2">
                        Editer
                      </label>
                    </div>
                    <svg
                      aria-hidden="true"
                      height="16"
                      viewBox="0 0 16 16"
                      version="1.1"
                      width="16"
                      data-view-component="true"
                      className="closeicon"
                      onClick={() => {
                        let _data = [...SelectedUser];
                        _data.splice(idx, 1);
                        let index = _userInfo.indexOf(item.username);
                        _userInfo.splice(index, 1);
                        setSelectedUser([..._data]);
                      }}
                    >
                      <path
                        fill-rule="evenodd"
                        d="M3.72 3.72a.75.75 0 011.06 0L8 6.94l3.22-3.22a.75.75 0 111.06 1.06L9.06 8l3.22 3.22a.75.75 0 11-1.06 1.06L8 9.06l-3.22 3.22a.75.75 0 01-1.06-1.06L6.94 8 3.72 4.78a.75.75 0 010-1.06z"
                      ></path>
                    </svg> */}
                  </div>
                </div>
              ))}
            </Scroller>
          </div>
        )}

        <div style={{ marginTop: "1vw" }} />
        <Button
          name="Add"
          bg
          type={"gradient"}
          onClick={postOperatorData}
          disabled={SelectedUser.length === 0}
        />
      </AnimatedModal>
      {/* <AnimatedModal
        modalOpen={ShowAlert}
        // modalOpen={true}
        handleClose={() => {
          setShowAlert(false);
          if (msg.errorText === "Registered as developer successfully!") {
            clearTimeout(timeout);
            setDevModal(false);
          }
        }}
        type={msg.type}
        errorHeader={msg.header}
        errorText={msg.errorText}
        alwayOpen={true}
      /> */}
      <AnimatedModal modalOpen={ModalOpen}>
        <div
          className="close_otp_modal"
          onClick={() => {
            setModalOpen(false);
          }}
        >
          {closeIcon()}
        </div>
        <h1 className="modal_head">Purchase License</h1>
        <InputBox id="licensingType" header="Licensing Type" value={FullName} />
        <InputBox
          id="allowedCameras"
          header="Allowed Cameras"
          value={FullName}
        />
        <InputBox id="aiModel" header="AI Model" value={FullName} />
        <InputBox id="useCase" header="Use Case" value={FullName} />
        <div className="res_success_btn">
          <Button type="gradient" bg name="Purchase" />

          <Button
            onClick={() => setModalOpen(false)}
            type="gradient"
            bg
            name="Go Back"
            style={{
              background: "#D2E2FF",
              color: "#013AA2",
            }}
          />
        </div>
      </AnimatedModal>
      <AnimatedModal
        modalOpen={ShowAlert}
        handleClose={() => {
          setShowAlert(false);
        }}
        type={msg.type}
        errorHeader={msg.header}
        errorText={msg.errorText}
        // timeout={5000}
        alwayOpen={true}
      />
      <AnimatedModal
        modalOpen={OperatorDeleteConfirm}
        onConfirm={() => {
          deleteOperator(headerData);
          setOperatorDeleteConfirm(false);
          // setShowOTP(false);
          // encryptStorage.removeItem("VID");
        }}
        handleClose={() => {
          setOperatorDeleteConfirm(false);
        }}
        type={"confirm"}
        errorText={"Are you sure you want to delete this version?"}
        alwayOpen={true}
      />

      {LoadingScreen && <Loading type={"transparent"} text={"Loading"} />}
    </div>
  );
}

const profileIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="31.244"
    height="24.996"
    viewBox="0 0 31.244 24.996"
  >
    <path
      id="Shape"
      d="M28.12,25h-25A3.128,3.128,0,0,1,0,21.87V7.811A3.127,3.127,0,0,1,3.123,4.687h3.75A1.392,1.392,0,0,0,8.108,3.8L9.077.889A1.388,1.388,0,0,1,10.309,0H20.934a1.388,1.388,0,0,1,1.233.889L23.136,3.8a1.39,1.39,0,0,0,1.235.889h3.75a3.127,3.127,0,0,1,3.123,3.124V21.87A3.128,3.128,0,0,1,28.12,25ZM15.621,6.249a7.811,7.811,0,1,0,7.812,7.81A7.819,7.819,0,0,0,15.621,6.249Zm11.4,1.56A1.093,1.093,0,1,0,28.12,8.9,1.095,1.095,0,0,0,27.025,7.809Zm-11.4,10.938a4.687,4.687,0,1,1,4.687-4.688A4.692,4.692,0,0,1,15.621,18.747Z"
      fill="#fff"
    />
  </svg>
);

const name = [
  "Shahbaz",
  "Sachin",
  "Janvi",
  "Shubham",
  "Dipesh",
  "Urvi",
  "Nitin",
  "Pushpak",
  "Subha",
];
