import React, { Component } from 'react'
import VersionDetails from '../../VersionDetails';
import VersionOptions from './VersionOptions';

export default class VersionSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "Shashi",
      // occupation: "Web Developer"
    };
  }

  demo= "settings"
  render() {
    return (
      <div className='versionSettings_main_wrapper'>
        <div className="versionSettings_subwrapper">
          <div className="versionSettings_partition_wrapper"></div>
        </div>
      
         
      </div>
    )
  }
}
