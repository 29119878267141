import React, { createContext, useState, useEffect } from "react";
import { notify } from "../Routes";
import { axiosApiInstance } from "./request";
import { encryptStorage } from "./storage";
const ThemeContext = createContext(null);
const { Provider } = ThemeContext;

const ThemeProvider = ({ children }) => {
  const [Theme, setTheme] = useState(localStorage.getItem("theme"));
  const [Role, setRole] = useState({
    all: null,
    current: null,
  });
  const [Notification, setNotification] = useState([]);
  const [Cart, setCart] = useState([]);
  const [CartData, setCartData] = useState("");
  const [Request, setRequest] = useState([]);

  const getRequest = async (res) => {
    let response = await axiosApiInstance.get("user/superadmin/requests");
    if (response?.status === 200) {
      setRequest([...response.data.requests]);
      res(true);
    } else {
      notify({});
      res(true);
    }
  };

  const getNotification = async (res) => {
    let response = await axiosApiInstance.get("user/actions");
    if (response?.status === 200) {
      setNotification([...response.data]);
      res && res(true);
    } else {
      notify({});
      res && res(true);
    }
  };

  const getCart = async (res) => {
    let response = await axiosApiInstance.get("order/cart");
    if (response?.status === 200) {
      setCart([...response.data.cart_items]);
      setCartData(response.data)
      res && res(true);
    } else {
      notify({});
      res && res(true);
    }
  };

  return (
    <Provider
      value={{
        Theme,
        Role,
        Cart,
        CartData,
        setRole,
        setTheme,
        Notification,
        setNotification,
        Request,
        setRequest,
        getRequest,
        getNotification,
        getCart,
      }}
    >
      {children}
    </Provider>
  );
};

export { ThemeContext, ThemeProvider };
