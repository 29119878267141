import React, { Component } from "react";
import "../VersionDetails/VersionAppDetails.scss";
import image from "../../../../../assets/images/dummy.png";
import { axiosApiInstance } from "../../../../../helper/request";

export default class VersionAppDetails extends Component {
  constructor(props) {
    super(props);
    // this.props.selected = BasicDetails
    this.state = {
      verAppDetailsData: {
      
      },
    };
  }
  app_name= window.location.search?.split("=")[1]?.split("?")[0]
  versionName = window.location.search.split("=")[2];

  fetchVersionAppDetails = () => {

    this.setState({ LoadingScreen: true });

    axiosApiInstance
      .get(
        `apps/version/details/basic?app_name=${this.app_name}&version_name=${this.versionName}`
      )
      .then((res) => {
     
        this.setState({
          verAppDetailsData: res.data.detail,
          
        });
      })
    
  };
  componentDidMount() {
    this.fetchVersionAppDetails();
  }
  render() {
    return (
      <div className="VersionAppDetails_wrapper">
        {/* {console.log(window.location.search.split("=")[1].split("?")[0])} */}
        <img className="ver_appimage" src={this.state.verAppDetailsData.app_thumbnail}></img>
        <p title={this.state.verAppDetailsData.app_name} className="ver_aapname">{this.state.verAppDetailsData.app_name}</p>
        {/* <p className="ver_aapname">{this.state.verAppDetailsData.Name}</p> */}
        <p
          style={{
            color:
              this.state.verAppDetailsData.status?.includes("uploaded") &&
              "#31FF00",
          }}
          className="ver_status"
        >
          {this.state.verAppDetailsData.status}
        </p>
      </div>
    );
  }
}
