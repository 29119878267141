import React, { useState, useRef, useEffect, useMemo, useContext } from "react";
import { motion } from "framer-motion";
import "./superprofile.scss";
import Navbar, {
  ManufacturerRequestCard,
} from "../../../../components/Navbar/Navbar";
import bg from "../../../../assets/images/background.png";
import { container, item as Items } from "../../../../helper/motions";
import InputBox from "../../../../components/Inputbox/InputBox";
import Button from "../../../../components/Button/Button";
import Scroller from "../../../../components/Scroller/ScrollBar";
import { encryptStorage } from "../../../../helper/storage";
import { ThemeContext } from "../../../../helper/context";
import DeveloperModal from "../../../../components/DeveloperModal/DeveloperModal";
import ManufacturerModal from "../../../../components/DeveloperModal/ManufacturerModal ";
import AnimatedModal from "../../../../components/Modal/AnimatedModal";
import { closeIcon, editIcon, searchIcon } from "../../../../helper/icons";
import devIcon from "../../../../assets/images/devIcon.png";
import manuIcon from "../../../../assets/images/manuIcon.png";
import {
  API_URL,
  axiosApiInstance,
  OTP_TIME,
} from "../../../../helper/request";
import Scrollbars from "react-custom-scrollbars";
import { useDebouncedEffect } from "../../../../helper/useDebounce";
import axios from "axios";
import { LoadingText } from "../../../auth/register/AuthRegister";
import ConfirmPasswordModal from "../../../../components/ConfirmPasswordModal/ConfirmPasswordModal";
import ResetPassword from "../../../../components/ResetPassword/ResetPassword";
import Loading from "../../../../components/Loading/Loading";
import OTP from "../../../../components/OTP/OTP";
import { userIcon } from "../../Users/Users";
import PhoneInputBox from "../../../../components/PhoneInputBox/PhoneInputBox";
let isExact = false;
let resetCounter = null;
let isCityEmpty = false;
let isDataEmpty = null;
let timeout = null;
let firstLoad = true;
let isProfileChanged = false;
let isEditing = false;
let _pass = null;
let values = {
  country: null,
  state: null,
  city: null,
};
let msg = {
  type: "alert",
  header: "Error",
  errorText: "Something Went Wrong",
};
let type_ = null;
let _lData__ = encryptStorage.getItem("VID");
let userData = {};
export default function SuperProfile() {
  const countryScrollRef = useRef();
  const cityScrollRef = useRef();
  const fNameRef = useRef();
  const countryRef = useRef();
  const stateRef = useRef();
  const cityRef = useRef();
  const themeContext = useContext(ThemeContext);
  const [SearchText, setSearchText] = useState("");
  const [LoadingScreen, setLoadingScreen] = useState(false);
  const [RequestData, setRequestData] = useState([]);
  const [ShowAlert, setShowAlert] = useState(false);
  const [Stop, setStop] = useState(false);
  const [Confirm, setConfirm] = useState(false);
  const [ConfirmModal, setConfirmModal] = useState(false);
  const [ShowResetPassword, setShowResetPassword] = useState(false);
  const [ShowOTP, setShowOTP] = useState(false);
  const [OTPCloseConfirm, setOTPCloseConfirm] = useState(false);
  const [ShowEmailInputModal, setShowEmailInputModal] = useState(false);
  const [Country, setCountry] = useState("");
  const [State, setState] = useState("");
  const [City, setCity] = useState("");
  const [LocationData, setLocationData] = useState({
    country: [],
    state: [],
    city: [],
    citySuggestion: [],
  });
  const [LocationLoading, setLocationLoading] = useState({
    country: false,
    state: false,
    city: false,
  });
  const [LocationSelected, setLocationSelected] = useState({
    isCountrySelected: true,
    isStateSelected: true,
    isCitySelected: true,
  });

  const [Name, setName] = useState("");
  const [Mobile, setMobile] = useState("");
  const [DefaultEmail, setDefaultEmail] = useState("");
  const [Email, setEmail] = useState("");
  const [ProfilePic, setProfilePic] = useState(null);
  const [errors, setErrors] = useState({
    isEmailUnique: false,
    isMobileUnique: false,
  });

  useDebouncedEffect(
    () => (Country ? getCountry("country") : undefined),
    [Country],
    1000
  );

  useDebouncedEffect(
    () => (State ? getCountry("state") : undefined),
    [State],
    1000
  );

  useDebouncedEffect(
    () => (Mobile ? uniqueCheck("phone") : undefined),
    [Mobile],
    1000
  );

  useDebouncedEffect(
    () => (Email ? uniqueCheck("Email") : undefined),
    [Email],
    1000
  );

  const uniqueCheck = (type = "phone") => {
    console.log("uniqueCheck");
    if (firstLoad) {
      return;
    }
    if (type === "phone") {
      if (Mobile === userData?.phoneRef) return;
    }
    let value = {
      Email,
      phone: Mobile,
    };
    let url = `user/unique-check/${type.toLowerCase()}?fieldValue=${
      value[type]
    }`;
    axios.get(API_URL + url).then((res) => {
      if (res.data.detail) {
        if (type === "Email") {
          error("newEmail");
          setErrors((prevState) => ({
            ...prevState,
            isEmailUnique: true,
          }));
        }

        if (type === "phone") {
          error("mobile_number");
          setErrors((prevState) => ({
            ...prevState,
            isMobileUnique: true,
          }));
        }
      } else {
        if (type === "Email") {
          handleFocus("newEmail");
          setErrors((prevState) => ({
            ...prevState,
            isEmailUnique: false,
          }));
        }

        if (type === "phone") {
          handleFocus("mobile_number");
          setErrors((prevState) => ({
            ...prevState,
            isMobileUnique: false,
          }));
        }
      }
    });
  };

  const getCountry = (type = "country") => {
    console.log("getCountry");
    console.log(firstLoad);
    if (firstLoad) {
      return;
    }
    let url = "";
    if (type === "country") {
      url = "admin/country/filters?country=" + Country;
    } else {
      url = "admin/country/filters?country=" + Country + "&state=" + State;
    }
    axios.get(API_URL + url).then((res) => {
      // console.log(res.data.detail);
      let _data = res.data.detail;
      let keys = null;
      if (_data.length) {
        keys = Object.keys(_data[0]);
      } else {
        if (type === "state") {
          setLocationData((prevState) => ({
            ...prevState,
            state: [],
          }));
        } else {
          setLocationData((prevState) => ({
            ...prevState,
            country: [],
          }));
        }
        setLocationLoading((prevState) => ({
          ...prevState,
          state: false,
          country: false,
          city: false,
        }));
        isDataEmpty = true;
        return;
      }

      if (keys?.includes("state")) {
        console.log("state");
        if (State === _data[0].state) {
          if (_data[0]?.cities?.length === 0) {
            isCityEmpty = true;
          } else {
            isCityEmpty = false;
          }
          setLocationData((prevState) => ({
            ...prevState,
            city: [..._data[0].cities],
          }));
          setLocationLoading((prevState) => ({
            ...prevState,
            state: false,
            city: false,
          }));
        } else {
          setLocationSelected((prevState) => ({
            ...prevState,
            isCountrySelected: true,
          }));
          setLocationData((prevState) => ({
            ...prevState,
            state: [..._data],
          }));
          setLocationLoading((prevState) => ({
            ...prevState,
            state: false,
            country: false,
            city: false,
          }));
        }
      } else {
        console.log("ELSE....");
        setLocationLoading((prevState) => ({
          ...prevState,
          country: false,
        }));
        setLocationData((prevState) => ({
          ...prevState,
          country: [..._data],
        }));
      }
    });
  };

  useEffect(() => {
    if (firstLoad) return;
    setState("");
    setCity("");
    isCityEmpty = false;
    if (Country === "") {
      setLocationData((prevState) => ({
        ...prevState,
        country: [],
        state: [],
        city: [],
        citySuggestion: [],
      }));
    } else {
      setLocationData((prevState) => ({
        ...prevState,
        state: [],
        city: [],
        citySuggestion: [],
      }));
    }

    setLocationSelected((prevState) => ({
      ...prevState,
      isStateSelected: false,
      isCitySelected: false,
      isCountrySelected: false,
    }));
    if (values.country) {
      setLocationSelected((prevState) => ({
        ...prevState,
        isCountrySelected: true,
      }));
    }
  }, [Country]);

  useEffect(() => {
    if (firstLoad) return;
    setCity("");
    if (State) {
      setLocationData((prevState) => ({
        ...prevState,
        city: [],
        citySuggestion: [],
        state: [],
      }));
    } else {
      setLocationLoading((prevState) => ({
        ...prevState,
        state: false,
      }));
      setLocationData((prevState) => ({
        ...prevState,
        city: [],
        citySuggestion: [],
      }));
    }

    setLocationSelected((prevState) => ({
      ...prevState,
      isStateSelected: false,
      isCitySelected: false,
    }));
    if (values.state) {
      setLocationSelected((prevState) => ({
        ...prevState,
        isStateSelected: true,
      }));
    }
  }, [State]);

  const getKeyCode = (e) => {
    switch (e.keyCode) {
      case 38:
        arrowKey(e, "up");
        break;

      case 40:
        arrowKey(e, "down");
        break;

      case 13:
        enterKey(e);
        // setIsFlagOpen(false);
        break;
    }
  };

  const arrowKey = (e, type) => {
    let myReferenceDiv = document.querySelector("._span");
    let active_flag = document.querySelector(".active_flag");
    let active_next = active_flag?.nextElementSibling;
    let active_prev = active_flag?.previousElementSibling;
    let next = myReferenceDiv?.nextElementSibling;
    let prev = myReferenceDiv?.previousElementSibling;
    if (type === "down") {
      if (!active_flag) {
        next.classList.add("active_flag");
      } else {
        if (active_next) {
          active_flag.classList.remove("active_flag");
          active_next.classList.add("active_flag");
          var childElement =
            document.querySelector(".active_flag").offsetTop -
            myReferenceDiv.offsetHeight -
            20;
          cityScrollRef?.current?.scrollTop(childElement);
          countryScrollRef?.current?.scrollTop(childElement);
        }
      }
    }
    if (type === "up") {
      if (active_prev && !active_prev.classList.contains("_span")) {
        active_flag.classList.remove("active_flag");
        active_prev.classList.add("active_flag");
        var childElement =
          document.querySelector(".active_flag").offsetTop -
          myReferenceDiv.offsetHeight -
          20;
        cityScrollRef?.current?.scrollTop(childElement);
        countryScrollRef?.current?.scrollTop(childElement);
      }
    }
  };

  const enterKey = () => {
    let active_flag = document.querySelector(".active_flag");
    let dataCity = active_flag.getAttribute("data-city");
    let dataCountry = active_flag.getAttribute("data-country");
    let dataState = active_flag.getAttribute("data-state");
    console.log(active_flag, dataCity, dataCountry, dataState);
    if (dataCity) {
      isDataEmpty = null;
      setCity(dataCity);
      setLocationSelected((prevState) => ({
        ...prevState,
        isCitySelected: true,
      }));
      setLocationData((prevState) => ({
        ...prevState,
        citySuggestion: [],
      }));
    }

    if (dataState) {
      values.state = dataState;
      isExact = true;
      isDataEmpty = null;
      setState(dataState);
      setLocationSelected((prevState) => ({
        ...prevState,
        isStateSelected: true,
      }));
    }

    if (dataCountry) {
      isExact = true;
      isDataEmpty = null;
      values.country = dataCountry;
      setCountry(dataCountry);
      setLocationSelected((prevState) => ({
        ...prevState,
        isCountrySelected: true,
      }));
      setLocationLoading((prevState) => ({
        ...prevState,
        state: true,
      }));
    }
  };

  const emailValidation = (_email) => {
    if (
      _email.match(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      return true;
    } else {
      return false;
    }
  };

  const clearError = (name) => {
    let _errors = { ...errors };
    _errors[name] = false;
    setErrors({ ..._errors });
  };

  const postResetUserPassword = (_password, _old_password) => {
    console.log(_password, _old_password);
    // setLoadingScreen(true);
    axios
      .post(API_URL + "user/updatePassword", {
        old_password: _old_password,
        current_password: _password,
        email: DefaultEmail,
      })
      .then((res) => {
        msg.errorText = "Password changed successfully!";
        msg.header = "Success";
        msg.type = "success";
        setShowAlert(true);
        setShowResetPassword(false);
        setTimeout(() => {
          setShowAlert(false);
        }, 3000);
      })
      .catch((err) => {
        msg.errorText = "Wrong old Password";
        msg.header = "Alert";
        msg.type = "alert";
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 3000);
      })
      .finally(() => {
        // setLoadingScreen(false);
      });
  };

  const getCity = (country, state) => {
    axios
      .get(
        API_URL + "admin/country/filters?country=" + country + "&state=" + state
      )
      .then((res) => {
        let _data = res.data.detail;
        if (_data[0]?.cities?.length === 0) {
          isCityEmpty = true;
        } else {
          isCityEmpty = false;
        }
        setLocationData((prevState) => ({
          ...prevState,
          city: [..._data[0].cities],
        }));
      });
  };

  const getProfile = () => {
    setLoadingScreen(true);
    axiosApiInstance
      .get("user/profile/data")
      .then((res) => {
        let { fullname, email, country, city, phone, state, profile_pic } =
          res.data;
        let ldata = encryptStorage.getItem("UID");
        ldata.profile_pic = profile_pic;
        encryptStorage.setItem("UID", ldata);
        document.getElementById("userFullName").innerHTML = fullname;
        // fNameRef.current.value = fullname;
        setTimeout(() => {
          // emailRef.current.value = email;
          // phoneRef.current.value = phone;
          fNameRef.current.disabled = true;
          countryRef.current.disabled = true;
          cityRef.current.disabled = true;
        }, 0);
        setMobile(phone);
        setName(fullname);
        setDefaultEmail(email);
        // phoneRef.current.disabled = true;
        setCountry(country);
        setState(state);
        setCity(city);
        setProfilePic(profile_pic);
        getCity(country, state);
        setLocationSelected({
          isCountrySelected: false,
          isStateSelected: false,
          isCitySelected: false,
        });
        console.log(res);
        setTimeout(() => {
          firstLoad = false;
        }, 2000);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoadingScreen(false);
      });
  };

  const sendOTP = () => {
    let obj = {
      username: encryptStorage.getItem("UID")?.username,
      email: Email,
    };
    setLoadingScreen(true);
    setShowEmailInputModal(false);
    axios
      .post(API_URL + "user/otp/send", obj)
      .then((res) => {
        setTimeout(() => {
          setShowOTP(true);
        }, 500);
        encryptStorage.setItem("VID", {
          counter: OTP_TIME,
          destination: Email,
          username: obj.username,
        });
      })
      .catch((err) => {
        msg.errorText = "Something went wrong!";
        msg.header = "Error";
        msg.type = "alert";
        setShowAlert(true);
      })
      .finally(() => {
        setLoadingScreen(false);
      });
  };

  const postVerifyOTP = (otp_) => {
    console.log(otp_);
    let lData = encryptStorage.getItem("VID");
    console.log(lData);
    let obj = {
      username: lData.username,
      email: lData.destination,
      otp: otp_,
    };
    setShowEmailInputModal(false);
    setLoadingScreen(true);
    axios
      .post(API_URL + "user/otp/verify", obj)
      .then((res) => {
        console.log(res);
        msg.type = "success";
        msg.header = "Success";
        msg.errorText = "OTP Verified Successfully. Email Updated!";
        setLoadingScreen(false);
        setShowAlert(true);
        setShowOTP(false);
        encryptStorage.removeItem("VID");
        setDefaultEmail(Email);
        postUserData();
        setTimeout(() => {
          setShowAlert(false);
        }, 3000);
        // var ele = document.querySelector("#otp_container");
        // ele.classList.add("slideOutLeft");
        // timeout = setTimeout(() => {
        //   setshowOTPContainer(false);
        //   setshowRegSuccessContainer(true);
        //   ele.classList.remove("slideOutLeft");
        // }, 500);
      })
      .catch((err) => {
        // setStop(false);
        // _stop = false;
        msg.errorText = "Invalid OTP. Please Try Again!";
        msg.header = "Error";
        msg.type = "alert";
        setShowAlert(true);
        setLoadingScreen(false);
        console.log(err);
      });
  };

  const postResendOTP = () => {
    let _lData = encryptStorage.getItem("VID");

    let obj = {
      username: _lData.username,
      email: _lData.destination,
    };
    setLoadingScreen(true);
    axios
      .post(API_URL + "user/otp/send", obj)
      .then((res) => {
        msg.errorText = "OTP resent to your Email ID.";
        msg.header = "Success";
        msg.type = "success";
        setShowAlert(true);
        resetCounter();
        // setCounter(time);
        // _lData.attempts = _lData.attempts - 1;
        // encryptStorage.setItem("VID", _lData);
      })
      .catch((err) => {
        msg.errorText = "Server Error";
        msg.header = "Error";
        msg.type = "alert";
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 3000);
        // clearTimeout(timeout);
        // timeout = setTimeout(() => {
        //   encryptStorage.removeItem("VID");
        //   window.location.reload();
        // }, 5000);
        console.log(err.response);
      })
      .finally(() => {
        setLoadingScreen(false);
      });
  };
  const handleFocus = (name) => {
    console.log(name);
    let ele = document.getElementById(name);
    ele.classList.remove("error_shake", "input__error");
  };
  const error = (id) =>
    document.getElementById(id).classList.add("error_shake", "input__error");
  const postUserData = () => {
    let _email = Email ? Email : DefaultEmail;
    let isError = false;
    let _mobile = Mobile.split(" ")[1];

    if (!Name) {
      error("fullName");
      isError = true;
    }
    if (!_mobile) {
      error("phone");
      isError = true;
    }
    if (!_email) {
      error("email");
      isError = true;
    }
    if (!Country) {
      error("country");
      isError = true;
    }
    if (!State) {
      error("state");
      isError = true;
    }
    if (!City) {
      error("city");
      isError = true;
    }
    if (isError) {
      return;
    }
    let formData = new FormData();
    console.log(_email);
    formData.append("full_name", Name);
    formData.append("phone", Mobile);
    formData.append("email", _email);
    formData.append("username", encryptStorage.getItem("UID")?.username);
    formData.append("country", Country);
    formData.append("state", State);
    formData.append("city", City);
    if (isProfileChanged) {
      formData.append("profile_pic", ProfilePic, ProfilePic.name);
    }
    setLoadingScreen(true);
    axios
      .post(API_URL + "user/edit/profile/data", formData, {
        headers: {
          password: _pass,
          Authorization: "Bearer " + encryptStorage.getItem("UID").refreshToken,
        },
      })
      .then((res) => {
        msg.errorText = "Updated Successfully";
        msg.header = "Success";
        msg.type = "success";
        isEditing = false;
        setEmail("");
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
          window.location.reload();
        }, 2000);
        firstLoad = true;
        isProfileChanged = false;
        // getProfile();
      })
      .catch((err) => {
        msg.errorText = "Failed to update profile detail!";
        msg.header = "Error";
        msg.type = "alert";
        setShowAlert(true);
      })
      .finally(() => {
        setLoadingScreen(false);
      });
  };

  const filteredItems = useMemo(() => {
    return themeContext.Request?.filter((item) => {
      return item.full_name?.toLowerCase().includes(SearchText.toLowerCase());
    });
  }, [themeContext.Request, SearchText]);

  const postRequest = (status, username) => {
    axiosApiInstance
      .post("user/superadmin/requests", {
        username,
        status,
      })
      .then((res) => {
        themeContext.getRequest();
        alert("Success");
      })
      .catch((err) => {
        alert("Error");
      });
  };

  useEffect(() => {
    getProfile();
    themeContext.getRequest();
    if (_lData__) {
      setShowOTP(true);
      setStop(false);
    }
    return () => (firstLoad = true);
  }, []);

  return (
    <div className="__super_profile_wrapper__">
      {console.log(Mobile)}
      <Navbar sidenav>
        <div className="profile_body fadeIn">
          <img src={bg} className="profile_bg" />
          <div className="profile_content">
            <h1>Profile</h1>
            <div className="profile_data">
              <div className="profile_data2">
                <div className="top">
                  <div className="profile_with_name">
                    <h1 className="name">
                      Welcome back, <span id="userFullName"></span>
                    </h1>
                    <div className="profile_holder_">
                      <div
                        className="image_holder"
                        draggable={false}
                        style={{ cursor: isEditing ? "pointer" : "default" }}
                      >
                        {ProfilePic ? (
                          isProfileChanged ? (
                            <img
                              src={URL.createObjectURL(ProfilePic)}
                              alt="profile"
                              draggable={false}
                            />
                          ) : (
                            <img src={ProfilePic} alt="profile" />
                          )
                        ) : (
                          userIcon()
                        )}
                        {isEditing && (
                          <React.Fragment>
                            <div id="circle" />
                            <div
                              className="click"
                              onClick={() => {
                                document.getElementById("profileInput").click();
                              }}
                            >
                              {profileIcon()}
                              <p>Click here to change profile</p>
                            </div>
                          </React.Fragment>
                        )}

                        <input
                          type={"file"}
                          id="profileInput"
                          style={{ display: "none" }}
                          accept="image/png, image/jpeg"
                          onChange={(e) => {
                            let file = e.target.files[0];
                            console.log(file);
                            if (!file) {
                              isProfileChanged = false;
                            } else {
                              isProfileChanged = true;
                              setProfilePic(file);
                            }
                          }}
                        />
                      </div>
                      <div className="username_button">
                        <p>{encryptStorage.getItem("UID")?.username}</p>
                        {!isEditing && (
                          <Button
                            name={"Change Password"}
                            type="gradient"
                            onClick={() => {
                              type_ = "password";
                              // setConfirmModal(true);
                              setShowResetPassword(true);
                            }}
                            style={{ alignSelf: "flex-end" }}
                          />
                        )}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                          }}
                        >
                          {isEditing && (
                            <Button
                              name={"Cancel"}
                              type="gradient"
                              onClick={() => {
                                isEditing = false;
                                firstLoad = true;
                                isProfileChanged = false;
                                [
                                  "fullName",
                                  "mobile_number",
                                  "email",
                                  "country",
                                  "state",
                                ].map((item) => handleFocus(item));
                                document.getElementById(
                                  "userFullName"
                                ).innerHTML = userData.fNameRef;
                                // fNameRef.current.value = userData.fNameRef;
                                // emailRef.current.value = userData.emailRef;
                                // phoneRef.current.value = userData.phoneRef;
                                fNameRef.current.disabled = true;
                                // phoneRef.current.disabled = true;
                                countryRef.current.disabled = true;
                                cityRef.current.disabled = true;
                                setDefaultEmail(userData.emailRef);
                                setMobile(userData.phoneRef);
                                setName(userData.fNameRef);
                                setCountry(userData.Country);
                                setState(userData.State);
                                setCity(userData.City);
                                setProfilePic(userData.ProfilePic);
                                setLocationSelected({
                                  isCountrySelected: false,
                                  isStateSelected: false,
                                  isCitySelected: false,
                                });
                                setLocationData({
                                  city: userData.city,
                                  country: [],
                                  state: [],
                                  citySuggestion: [],
                                });
                                setTimeout(() => {
                                  firstLoad = false;
                                }, 2000);
                              }}
                            />
                          )}

                          <Button
                            name={isEditing ? "Save" : "Edit Profile"}
                            type="gradient"
                            onClick={() => {
                              if (isEditing) {
                                postUserData();
                              } else {
                                type_ = "edit";
                                setConfirmModal(true);
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {!LoadingScreen && (
                  <div className="bottom">
                    <Scroller autoHeightMax="30vw" autoHeightMin="15vw">
                      <div className="grid_box">
                        <InputBox
                          id="email"
                          header="Email"
                          children={
                            !isEditing &&
                            editIcon(() => {
                              type_ = "email";
                              setConfirmModal(true);
                            })
                          }
                          disabled
                          // ref={emailRef}
                          value={DefaultEmail}
                          onFocus={() => handleFocus("email")}
                        />
                        <InputBox
                          id="fullName"
                          header="Full Name"
                          value={Name}
                          ref={fNameRef}
                          disabled
                          onFocus={() => handleFocus("fullName")}
                          onChange={(e) => {
                            setName(e.target.value);
                          }}
                        />
                        <PhoneInputBox
                          id="mobile_number"
                          isEdit={true}
                          onChange={(data) => {
                            console.log(data);
                            let _number =
                              data.countryCode + " " + data.inputNumber;
                            setMobile(_number);
                          }}
                          onFocus={() => handleFocus("mobile_number")}
                          value={Mobile}
                          disabled={!isEditing}
                          helperText={
                            errors["isMobileUnique"] && "Already used"
                          }
                        />
                        {/* 
                      <InputBox
                        id="phone"
                        header="Phone"
                        onChange={(e) => {
                          // const value = e.target.value;
                          // const regex = /^[a-zA-Z ]*$/;
                          // if (value.match(regex) || value === "") {
                          //   setFullName(value);
                          // }
                        }}
                        disabled
                        onFocus={() => handleFocus("phone")}
                        ref={phoneRef}
                      /> */}
                        <div id="_country_">
                          <InputBox
                            id="country"
                            header="Country"
                            onChange={(e) => {
                              const value = e.target.value;
                              const regex = /^[a-zA-Z ]*$/;
                              if (value.match(regex) || value === "") {
                                values.country = null;
                                setCountry(value);
                                setLocationLoading((prevState) => ({
                                  ...prevState,
                                  country: true,
                                }));
                              } else {
                                setLocationLoading((prevState) => ({
                                  ...prevState,
                                  country: false,
                                }));
                              }
                            }}
                            disabled
                            onKeyDown={getKeyCode}
                            error={errors["isCountryEmpty"]}
                            value={Country}
                            onFocus={() => {
                              clearError("isCountryEmpty");
                              handleFocus("country");
                            }}
                            ref={countryRef}
                          />
                          {LocationLoading.country &&
                          Country &&
                          !isDataEmpty ? (
                            <div className="suggestions">
                              <LoadingText />
                            </div>
                          ) : (
                            LocationData.country.length > 0 &&
                            !LocationSelected.isCountrySelected && (
                              <div className="suggestions">
                                <Scrollbars autoHeight autoHeightMax="15vh">
                                  <span className="_span" />
                                  {LocationData.country.map((item) => (
                                    <div
                                      key={item.country}
                                      className="suggestion_item"
                                      data-country={item.country}
                                      onClick={() => {
                                        isExact = true;
                                        isDataEmpty = null;
                                        values.country = item.country;
                                        setCountry(item.country);
                                        setLocationSelected((prevState) => ({
                                          ...prevState,
                                          isCountrySelected: true,
                                        }));
                                        setLocationLoading((prevState) => ({
                                          ...prevState,
                                          state: true,
                                        }));
                                      }}
                                    >
                                      {item.country}
                                    </div>
                                  ))}
                                </Scrollbars>
                              </div>
                            )
                          )}
                        </div>
                        <div id="_country_">
                          <InputBox
                            id="state"
                            header="State"
                            disabled={!LocationSelected.isCountrySelected}
                            onChange={(e) => {
                              const value = e.target.value;
                              const regex = /^[a-zA-Z ]*$/;
                              if (value.match(regex) || value === "") {
                                values.state = null;
                                setState(value);
                                setLocationLoading((prevState) => ({
                                  ...prevState,
                                  state: true,
                                }));
                              } else {
                                setLocationLoading((prevState) => ({
                                  ...prevState,
                                  state: false,
                                }));
                              }
                            }}
                            error={errors["isStateEmpty"]}
                            ref={stateRef}
                            value={State}
                            onFocus={() => {
                              clearError("isStateEmpty");
                              handleFocus("state");
                            }}
                          />
                          {LocationLoading.state && !isDataEmpty ? (
                            <div className="suggestions">
                              <LoadingText />
                            </div>
                          ) : (
                            LocationData.state.length > 0 &&
                            !LocationSelected.isStateSelected && (
                              <div className="suggestions">
                                <Scrollbars autoHeight autoHeightMax="15vh">
                                  {LocationData.state.map((item) => (
                                    <div
                                      key={item.state}
                                      className="suggestion_item"
                                      data-state={item.state}
                                      onClick={() => {
                                        values.state = item.state;
                                        isExact = true;
                                        isDataEmpty = null;
                                        setState(item.state);
                                        setLocationSelected((prevState) => ({
                                          ...prevState,
                                          isStateSelected: true,
                                        }));
                                      }}
                                    >
                                      {item.state}
                                    </div>
                                  ))}
                                </Scrollbars>
                              </div>
                            )
                          )}
                        </div>

                        <div id="_country_">
                          <InputBox
                            id="city"
                            header="City"
                            onKeyDown={getKeyCode}
                            disabled={
                              !LocationSelected.isStateSelected || isCityEmpty
                            }
                            onChange={(e) => {
                              const value = e.target.value;
                              const regex = /^[a-zA-Z ]*$/;
                              if (value.match(regex) || value === "") {
                                setCity(value);
                                const found = LocationData.city.filter((e) =>
                                  e.toLowerCase().includes(value.toLowerCase())
                                );
                                setLocationData((prevState) => ({
                                  ...prevState,
                                  citySuggestion: [...found],
                                }));
                              }
                            }}
                            error={errors["isCityEmpty"]}
                            ref={cityRef}
                            value={City}
                            onFocus={() => {
                              clearError("isCityEmpty");

                              handleFocus("city");
                            }}
                          />
                          {LocationLoading.city && !isDataEmpty ? (
                            <div className="suggestions">
                              <LoadingText />
                            </div>
                          ) : (
                            LocationData.citySuggestion.length > 0 && (
                              <div className="suggestions">
                                <Scrollbars
                                  autoHeight
                                  autoHeightMax="15vh"
                                  ref={cityScrollRef}
                                >
                                  <span className="_span" />
                                  {LocationData.citySuggestion.map((item) => (
                                    <div
                                      key={item}
                                      className="suggestion_item"
                                      data-city={item}
                                      onClick={() => {
                                        isDataEmpty = null;
                                        setCity(item);
                                        setLocationSelected((prevState) => ({
                                          ...prevState,
                                          isCitySelected: true,
                                        }));
                                        setLocationData((prevState) => ({
                                          ...prevState,
                                          citySuggestion: [],
                                        }));
                                      }}
                                    >
                                      {item}
                                    </div>
                                  ))}
                                </Scrollbars>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    </Scroller>
                  </div>
                )}
              </div>
              <div className="manufacturer_request">
                <h1>Manufacturer Request</h1>
                <p className="manu_count">
                  You have {themeContext.Request?.length} manufacturer request
                </p>
                <Scroller autoHeightMax="52vh">
                  {filteredItems.map((item, idx) => (
                    <ManufacturerRequestCard
                      key={item.full_name}
                      name={item.full_name}
                      company={item.company_name}
                      time={item.created}
                      onAcceptClick={() => {
                        postRequest("accepted", item.username);
                      }}
                      onRejectClick={() => {
                        postRequest("rejected", item.username);
                      }}
                      onHover
                      message={item.message}
                      id={"message_" + idx}
                    />
                  ))}
                </Scroller>
                <div className="search_container">
                  <input
                    type="text"
                    className="search_input"
                    placeholder="Search by manufacturer name"
                    autoFocus
                    value={SearchText}
                    onChange={(e) => {
                      const value = e.target.value;
                      const regex = /^[a-zA-Z ]*$/;
                      if (value.match(regex) || value === "") {
                        setSearchText(value);
                      }
                    }}
                  />
                  {searchIcon()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Navbar>

      <ConfirmPasswordModal
        setConfirmModal={setConfirmModal}
        ConfirmModal={ConfirmModal}
        setShowOTP={setShowOTP}
        handleAlert={(bool, data) => {
          if (bool) {
            msg = { ...data };
            setShowAlert(bool);
          }
        }}
        setShowEmailInputModal={setShowEmailInputModal}
        type={type_}
        handleEnableValue={(pass) => {
          userData = {
            fNameRef: Name,
            emailRef: DefaultEmail,
            phoneRef: Mobile,
            Country,
            State,
            City,
            ProfilePic,
            city: LocationData?.city,
          };
          console.log(userData);
          _pass = pass;
          isEditing = true;
          fNameRef.current.disabled = false;
          // phoneRef.current.disabled = false;
          countryRef.current.disabled = false;
          cityRef.current.disabled = false;
          setLocationSelected((prevState) => ({
            ...prevState,
            isStateSelected: true,
            isCountrySelected: true,
          }));
        }}
        handleEnableEmail={(data) => {
          userData = {
            fNameRef: Name,
            emailRef: DefaultEmail,
            phoneRef: Mobile,
            Country,
            State,
            City,
            ProfilePic,
            city: LocationData?.city,
          };
          _pass = data;
          setShowEmailInputModal(true);
        }}
        setShowResetPassword={setShowResetPassword}
      />
      <AnimatedModal
        modalOpen={ShowEmailInputModal}
        className="confirm_password_wrapper"
      >
        <div
          className="close_otp_modal"
          onClick={() => {
            setShowEmailInputModal(false);
            setEmail("");
            setErrors({
              isEmailUnique: false,
              isMobileUnique: false,
            });
          }}
        >
          {closeIcon()}
        </div>
        <p className="sub">New Email Address</p>
        <InputBox
          id="newEmail"
          header="Email"
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          value={Email}
          onKeyDown={(e) => {
            if (e.keyCode === 13) {
              sendOTP();
            }
          }}
          helperText={errors["isEmailUnique"] && "Already used"}
          autoFocus
        />
        <Button
          name="Confirm"
          disabled={!emailValidation(Email)}
          onClick={sendOTP}
        />
      </AnimatedModal>
      <ResetPassword
        oldPassBolean
        ShowResetPassword={ShowResetPassword}
        handlePassword={(data, oldData) => {
          postResetUserPassword(data, oldData);
        }}
        setConfirm={setConfirm}
      />
      <AnimatedModal modalOpen={ShowOTP} className="otp_modal_adjust">
        <OTP
          showOTP={ShowOTP}
          setShowOTP={setShowOTP}
          postVerifyOTP={(otp_) => {
            postVerifyOTP(otp_);
          }}
          setOTPCloseConfirm={setOTPCloseConfirm}
          postResendOTP={postResendOTP}
          resendFunc={(func) => {
            resetCounter = func;
            console.log(func);
          }}
          _stop={Stop}
          setStop={setStop}
        />
      </AnimatedModal>
      <AnimatedModal
        modalOpen={OTPCloseConfirm}
        onConfirm={() => {
          setDefaultEmail(userData.emailRef);
          setName(userData.fNameRef);
          setOTPCloseConfirm(false);
          setShowOTP(false);
          encryptStorage.removeItem("VID");
          //call api and then reset the URL and reset the password field on 200
          // var url = new URL(window.location.href);
          // const params = new URLSearchParams(window.location.search);
          // params.delete("token");
          // var url = window.location.pathname;
          // window.history.pushState(null, null, url);
          // setConfirm(false);
          // setPassword("");
          // setShowResetPassword(false);
        }}
        handleClose={() => {
          setOTPCloseConfirm(false);
        }}
        type={"confirm"}
        errorText={"Are you sure you want to close?"}
        alwayOpen={true}
      />
      <AnimatedModal
        modalOpen={Confirm}
        onConfirm={() => {
          setConfirm(false);
          setShowResetPassword(false);
        }}
        handleClose={() => {
          setConfirm(false);
        }}
        type={"confirm"}
        errorText={"Are you sure you want to close?"}
        alwayOpen={true}
      />
      <AnimatedModal
        modalOpen={ShowAlert}
        handleClose={() => {
          if (msg.errorText === "Updated Successfully") {
            window.location.reload();
          } else {
            setShowAlert(false);
          }
        }}
        type={msg.type}
        errorHeader={msg.header}
        errorText={msg.errorText}
        // timeout={5000}
        alwayOpen={true}
      />
      {LoadingScreen && <Loading type={"transparent"} text={"Loading"} />}
    </div>
  );
}

const profileIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18.701}
    height={17.034}
    viewBox="0 0 18.701 17.034"
    className="profileIcon"
    // style={{ display: "block", margin: "0,auto" }}
  >
    <path
      id="add_a_photo_FILL1_wght400_GRAD0_opsz48"
      d="M2.966,20.684a1.215,1.215,0,0,1-.887-.38,1.215,1.215,0,0,1-.38-.887V8.589a1.227,1.227,0,0,1,.38-.876,1.2,1.2,0,0,1,.887-.39h3.1L7.61,5.486h5.91V8.019h2.512v2.533h2.554v8.865a1.2,1.2,0,0,1-.39.887,1.227,1.227,0,0,1-.876.38ZM10.143,17.6a3.533,3.533,0,0,0,3.588-3.588,3.516,3.516,0,0,0-3.588-3.588,3.5,3.5,0,0,0-3.588,3.588A3.516,3.516,0,0,0,10.143,17.6Zm0-1.266a2.24,2.24,0,0,1-1.667-.665,2.317,2.317,0,0,1,1.667-3.979,2.268,2.268,0,0,1,1.657.654,2.41,2.41,0,0,1,0,3.324A2.249,2.249,0,0,1,10.143,16.335ZM17.3,8.568V6.753H15.483V5.486H17.3V3.65h1.266V5.486H20.4V6.753H18.565V8.568Z"
      transform="translate(-1.7 -3.65)"
      fill="rgba(255,255,255,0.75)"
    />
  </svg>
);

const requestData = [
  {
    name: "Dora Floyd",
    company: "Company - EaseMyAI Pvt Ltd",
    time: "1 hr ago",
  },
  {
    name: "Fred Graves",
    company: "Company - Pepsico",
    time: "3 hrs ago",
  },
  {
    name: "Lenora Logan",
    company: "DHL",
    time: "1 day ago",
  },
  {
    name: "Clyde Gomez",
    company: "Blue Dart",
    time: "2 day ago",
  },
  {
    name: "John Doe",
    company: "Reliance",
    time: "3 day ago",
  },
  {
    name: "Fred Graves2",
    company: "Company - Pepsico",
    time: "3 hrs ago",
  },
  {
    name: "Lenora Logan2",
    company: "DHL",
    time: "1 day ago",
  },
];
