import React from "react";

const ProgressBar = ({ bgcolor, progress, height, name, Width }) => {
  // const name =[1,2,3,4,5]
  const Parentdiv = {
    height: height,
    width: "100% ",
    backgroundColor: "whitesmoke",
    borderRadius: 40,
    margin: 10,
  };

  const Childdiv = {
    height: "100%",
    width: `${progress}%`,
    backgroundColor: bgcolor,
    borderRadius: 40,
    textAlign: "right",
  };

  const progresstext = {
    padding: 10,
    color: "black",
    fontWeight: 900,
  };

  return (
    

  <div style={{position: "relative"}}> 
    <p style={{position: "absolute", bottom: "-75%", left: "-2%"}}>{name+1}</p>
  <div style={Parentdiv}> 
        
        <div style={Childdiv}>
          {/* <span style={progresstext}>{`${progress}%`}</span> */}
        </div>
      </div>

  </div>
  
  );
};

export default ProgressBar;
