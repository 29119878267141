import React, { useEffect, useRef, useState } from "react";
import "./../../Component/ViewApp/Hero.scss";
import default_img from "../../../../../assets/images/default_img.png";
import { star } from "../../../../../helper/icons";
import Button from "../../../../../components/Button/Button";
import video from "../../../../../assets/images/video.mp4";
import { useQuery } from "../../../../../helper/storage";
import { axiosApiInstance } from "../../../../../helper/request";
import Loading from "../../../../../components/Loading/Loading";

export default function Hero({
  appname,
  app_trailer,
  app_image,
  developerName,
  ratingsAndReview,
  setState,
  fetchApp,
}) {
  let firstLoad = true;

  const [LoadingScreen, setLoadingScreen] = useState(false);
  const formatDownload = (n) => {
    if (n < 1e3) return n;
    if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
    if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
    if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
    if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
  };

  const ratingAndReviewComponent = () => (
    <>
      <div className="rr_wrapper">
        <div className="star_ratings">
          {ratingsAndReview ? ratingsAndReview.final_ratings : "0"} {star()}
        </div>
        <div className="no_of_reviews">
         <p> {ratingsAndReview ? ratingsAndReview.total_ratings : "0"}</p>
         <p>
        {ratingsAndReview?.total_ratings > "0" ? "Reviews" : "Review"}

         </p>
        </div>
      </div>
      <div className="downloads_wrapper">
        <div className="no_of_downloads">
          {ratingsAndReview ? formatDownload(ratingsAndReview.downloads) : "0"}
        </div>
        <p className="downloads_tag">{ ratingsAndReview?.downloads > 0 ? 'Downloads' : "Download"}</p>
      </div>
    </>
  );
  const queryy = useQuery();

  useEffect(() => {
    let app_name = queryy.get("app_name") || "";
    setLoadingScreen(true);
    axiosApiInstance.get(`apps/?app_name=${app_name}`).then((res) => {
      setState(res.data.detail.details[0]);
    })
    .finally(() => {
      setLoadingScreen(false);
      setTimeout(() => {
        firstLoad = false;
      }, 2000);
    });
    return () => {};
  }, [fetchApp]);

  return (
    <div className="usecase_upper_subwrapper">
      {app_trailer.includes("mp4") ? (
        <video controls className="app_trailer_style" src={app_trailer}></video>
      ) : (
        <img
          className="appimage"
          src={
            app_image
              ? app_image
              : "https://source.unsplash.com/random/200x200?sig="
          }
        ></img>
      )}
      <div className="usecase_app_details">
        <h2 title={appname} className="usecase_app_name">{appname}</h2>
        <h4 className="usecase_developer_name">
          {developerName ? developerName : "Developer Name"}
        </h4>

        {/* <Button name={"Edit Profile"}/> */}
        {app_trailer.includes("mp4") ? (
          <div className="usecase_video_count_wrapper">
            {app_trailer.includes("mp4") ? (
              <img
                className="after_trailer_image"
                src="
      https://source.unsplash.com/random/200x200?sig=
      "
              ></img>
            ) : (
              ""
            )}
            {ratingAndReviewComponent()}
          </div>
        ) : (
          <div className="usecase_count_wrapper">
            {app_trailer.includes("mp4") ? (
              <img
                className="after_trailer_image"
                src="
      https://source.unsplash.com/random/200x200?sig=
      "
              ></img>
            ) : (
              ""
            )}
            {ratingAndReviewComponent()}
          </div>
        )}

        {/* <Button name={"Edit Profile"}/> */}
      </div>
      {LoadingScreen && <Loading type={"transparent"} text={"Loading"} />}

    </div>
  );
}
