import React, { Component } from "react";
import Content from "./Content";
import "./Compatibility.scss";
import { axiosApiInstance } from "../../../../../helper/request";
import Scroller from "../../../../../components/Scroller/ScrollBar";
import Loading from "../../../../../components/Loading/Loading";

export default class Compatibility extends Component {
  constructor(props) {
    super(props);
    this.state = {
      compatibilityData: {},
      DependencyData: {},
      LoadingScreen: false,
    };
  }
  app_name = window.location.search.split("=")[1].split("?")[0];
  versionName = window.location.search.split("=")[2];
  fetchCompatibilityDetails = () => {
    let arr = [];
    let compatibileData = {};
    // this.setState({ LoadingScreen: true });
    axiosApiInstance
      .get(
        `apps/version/details/compatibility?app_name=${this.app_name}&version_name=${this.versionName}`
      )
      .then((res) => {
        // arr.push(res.data.detail);
        // arr.map((details) => {
        //   compatibileData = {
        //     Compatible_Hardware_Architecture: details["compatible_hw"],
        //     Compatible_OS_Version: details["compatible_os"],
        //     Compatible_GPU_Architecture: details["compatible_gpu"],
        //     Compatible_RDX_Version: details["compatible_version"],
        //   };
        // });
        this.setState({
          compatibilityData: res.data.detail,
        });
      })
      .finally(() => {
        // this.setState({ LoadingScreen: false });
        setTimeout(() => {}, 2000);
      });
  };

  fetchDependencyDetails = () => {
    let arr = [];
    let dependencyData = {};
    axiosApiInstance
      .get(
        `apps/version/details/dependency?app_name=${this.app_name}&version_name=${this.versionName}`
      )
      .then((res) => {
        // arr.push(res.data.detail);
        // arr.map((details) => {
        //   dependencyData = {
        //     Dependent_use_case_service: details["dependent_usecase"],
        //     Dependent_AI_service: details["dependent_ai"],
        //     Dependent_DB_service: details["dependent_database"],
        //     Dependent_base_service: details["dependent_base"],
        //     Dependent_firmware_service: details["dependent_firmware"],
        //   };
        // });
        this.setState({
          DependencyData: res.data.detail,
        });
      });
  };

  componentDidMount() {
    this.fetchCompatibilityDetails();
    this.fetchDependencyDetails();
  }

  demo = "compatibility";
  render() {
    const mappingCompatibleLabels = {
      compatible_hw: "Compatible_Hardware_Architecture",
      compatible_os: "Compatible_OS_Version",
      compatible_gpu: "Compatible_GPU_Architecture",
      compatible_version: "Compatible_RDX_Version",
    };
    let compatibility = {};
    Object.keys(mappingCompatibleLabels).map(
      (item) =>
        (compatibility[mappingCompatibleLabels[item]] =
          this.state.compatibilityData[item]?.map((y, j) =>
            j === this.state.compatibilityData[item].length - 1
              ? y.replaceAll("_", " ")
              : y + " , "
          ))
    );
    const mappingDependencyLabels = {
      dependent_usecase: "Dependent_use_case_service",
      dependent_ai: "Dependent_AI_service",
      dependent_database: "Dependent_DB_service",
      dependent_base: "Dependent_base_service",
      dependent_firmware: "Dependent_firmware_service",
    };
    let depenency = {};
    Object.keys(mappingDependencyLabels).map(
      (item) =>
        (depenency[mappingDependencyLabels[item]] =
          this.state.compatibilityData[item]?.map((y, j) =>
            j === this.state.compatibilityData[item].length - 1
              ? y.replaceAll("_", " ")
              : y + " , "
          ))
    );

    return (
      <div className="compatibility_main_wrapper">
        {console.log(this.state.compatibilityData)}

        <Scroller
          autoHeightMax="57vh"
          verticalStyle={{
            width: "0.3vw",
            marginLeft: "0vw",
            backgroundColor: "#283e92",
            overflow: "hidden"
          }}
        >
          <div className="ver_compatibility_subwrapper">
            <p className="compatibility_header">Compatibility details</p>
            <div className="compatibility_upper_wrapper">
              <Content contentData={compatibility} />
            </div>
            <p className="dependent_header">Dependent services</p>
            <div className="compatibility_lower_wrapper">
              <Content contentData={depenency} />
            </div>
          </div>
        </Scroller>
        {this.state.LoadingScreen && (
          <Loading type={"transparent"} text={"Loading"} />
        )}
      </div>
    );
  }
}
