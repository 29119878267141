import React, { useEffect, useRef, useState, useContext } from "react";
import Button from "../../../components/Button/Button";
import Navbar from "../../../components/Navbar/Navbar";
import "./home.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  CategoriesCard,
  UsecaseCard,
} from "../../../components/UsecaseCard/UsecardCard";
import Loading from "../../../components/Loading/Loading";
import homeHeader from "../../../assets/images/homeHeader.svg";
import devIcon from "../../../assets/images/devIcon.png";
import manuIcon from "../../../assets/images/manuIcon.png";
import DeveloperModal from "../../../components/DeveloperModal/DeveloperModal";
import ManufacturerModal from "../../../components/DeveloperModal/ManufacturerModal ";
import { useMemo } from "react";
import { encryptStorage, isSuspended } from "../../../helper/storage";
import { ThemeContext } from "../../../helper/context";
import AnimatedModal from "../../../components/Modal/AnimatedModal";
let msg = {
  type: "alert",
  header: "Error",
  errorText: "Something Went Wrong",
};
let timeout = null;
export default function Home() {
  const themeContext = useContext(ThemeContext);
  const categorySliderRef = useRef();
  const [DevModal, setDevModal] = useState(false);
  const [ShowAlert, setShowAlert] = useState(false);
  const [LoadingScreen, setLoadingScreen] = useState(false);
  const [ManufModal, setManufModal] = useState(false);
  const [RecentlyAddedOption, setRecentlyAddedOption] = useState([
    "All",
    "Healthcare",
    "BFSI",
    "Real Estate",
    "Textile",
    "Smart City",
  ]);
  // const [Upgrade, setUpgrade] = useState({
  //   developer: false,
  //   manufacturer: false,
  // });
  const [activeRecentAdded, setactiveRecentAdded] = useState("All");
  // const [ModalOpen, setModalOpen] = useState(false);

  function Arrow(props) {
    let className = props.type === "next" ? "nextArrow" : "prevArrow";
    className += " arrow " + props.className;
    return (
      <button
        onClick={props.onClick}
        disabled={props.className?.includes("slick-disabled")}
        className={className}
      >
        {props.type === "next" ? nextIcon() : prevIcon()}
      </button>

      // <span className={className} onClick={props.onClick}>
      //   {char}
      // </span>
    );
  }

  const CategorySliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    arrows: true,
    autoplay: false,
    slidesToShow: 6,
    slidesToScroll: 3,
    nextArrow: <Arrow type="next" />,
    prevArrow: <Arrow type="prev" />,
  };

  // const upgradeCard = useMemo(() => {
  //   let _upgrade = { ...Upgrade };
  //   let { roles } = encryptStorage.getItem("UID");
  //   let pending = themeContext.Role?.pending;
  //   console.log(themeContext.Role);
  //   // let roles = ["manufacturer", "developer"];
  //   console.log(roles);
  //   if (roles.includes("developer") && roles.includes("manufacturer")) {
  //     _upgrade.manufacturer = true;
  //     _upgrade.developer = true;
  //   } else {
  //     if (roles.includes("developer")) {
  //       _upgrade.manufacturer = false;
  //       _upgrade.developer = true;
  //     } else if (roles.includes("manufacturer")) {
  //       _upgrade.manufacturer = true;
  //       _upgrade.developer = false;
  //     } else {
  //       _upgrade.manufacturer = false;
  //       _upgrade.developer = false;
  //     }
  //   }
  //   console.log(pending);
  //   if (pending?.includes("manufacturer")) {
  //     _upgrade.manufacturer = true;
  //   }
  //   if (pending?.includes("developer")) {
  //     _upgrade.developer = true;
  //   }
  //   setUpgrade({ ..._upgrade });
  //   console.log(_upgrade);
  //   return {
  //     developer: _upgrade.developer,
  //     manufacturer: _upgrade.manufacturer,
  //   };
  // }, [themeContext.Role]);

  return (
    <div className="__home_wrapper__">
      <Navbar sidenav search>
        <div className="home_body">
          <div className="home_slider">
            <h1 className="slide_head">
              Bringing Artificial Intelligence To Device{" "}
            </h1>
            {/* <p className="slide_subhead">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut
              pulvinar sed nulla quis sollicitudin diam purus, elementum.{" "}
            </p> */}
            {/* {(!upgradeCard.developer || !upgradeCard.manufacturer) && (
              <div className="role_container">
                {!Upgrade.developer && (
                  <div
                    className="role_btn"
                    aria-disabled={isSuspended()}
                    onClick={() => !isSuspended() && setDevModal(true)}
                  >
                    <img src={devIcon} alt="devIcon" className="icon" />
                    <span>Become a Developer</span>
                  </div>
                )}
                {!Upgrade.developer && !Upgrade.manufacturer && (
                  <div style={{ width: "1.5625vw" }} />
                )}

                {!Upgrade.manufacturer && (
                  <React.Fragment>
                    <div
                      className="role_btn"
                      aria-disabled={isSuspended()}
                      onClick={() => !isSuspended() && setManufModal(true)}
                    >
                      <img src={manuIcon} alt="manuIcon" className="icon" />
                      <span>Become a Manufacturer</span>
                    </div>
                  </React.Fragment>
                )}
              </div>
            )} */}

            {mask2()}
            {mask1()}
            <img
              src={homeHeader}
              alt="home-header"
              className="homeHeader"
              draggable={false}
            />
          </div>
          {/* <h1 className="home_header">Recently Added</h1>
          <div className="recently_added_wrapper">
            <div className="recently_added_btn_container">
              {RecentlyAddedOption.map((item) => (
                <Button
                  name={item}
                  key={item}
                  type={activeRecentAdded === item ? "gradient" : ""}
                  style={{
                    color:
                      activeRecentAdded === item ? "#fff" : "var(--primary)",
                    background: activeRecentAdded !== item && "var(--sidenav)",
                  }}
                  onClick={() => setactiveRecentAdded(item)}
                />
              ))}
              <p className="view_all"> VIEW ALL</p>
            </div>
            <div className="recently_added_cards">
              {[1, 2, 3, 4, 5, 6].map((item) => (
                <UsecaseCard className="adjust_uc" />
              ))}
            </div>
          </div>
          <h1 className="home_header">Explore by categories</h1>
          <div className="categories_cards">
            <Slider {...CategorySliderSettings} ref={categorySliderRef}>
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((item) => (
                <CategoriesCard key={item} />
              ))}
            </Slider>
          </div> */}
        </div>
      </Navbar>

      <DeveloperModal
        DevModal={DevModal}
        setDevModal={setDevModal}
        setLoadingScreen={setLoadingScreen}
        setShowAlert={setShowAlert}
        handleAlert={(bool, data) => {
          if (bool) {
            msg = { ...data };
            setShowAlert(bool);
          }
        }}
      />
      <ManufacturerModal
        ManufModal={ManufModal}
        setManufModal={setManufModal}
        setLoadingScreen={setLoadingScreen}
        setShowAlert={setShowAlert}
        handleAlert={(bool, data) => {
          if (bool) {
            msg = { ...data };
            setShowAlert(bool);
          }
        }}
      />
      <AnimatedModal
        modalOpen={ShowAlert}
        // modalOpen={true}
        handleClose={() => {
          setShowAlert(false);
          if (
            msg.errorText === "Registered as developer successfully!" ||
            msg.errorText == "Registered as manufacturer successfully!"
          ) {
            clearTimeout(timeout);
            setDevModal(false);
          }
        }}
        type={msg.type}
        errorHeader={msg.header}
        errorText={msg.errorText}
        alwayOpen={true}
      />
      {LoadingScreen && <Loading type={"transparent"} text={"Loading"} />}
    </div>
  );
}

const nextIcon = () => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.46967 5.46967C9.76256 5.17678 10.2374 5.17678 10.5303 5.46967L16.5303 11.4697C16.8232 11.7626 16.8232 12.2374 16.5303 12.5303L10.5303 18.5303C10.2374 18.8232 9.76256 18.8232 9.46967 18.5303C9.17678 18.2374 9.17678 17.7626 9.46967 17.4697L14.9393 12L9.46967 6.53033C9.17678 6.23744 9.17678 5.76256 9.46967 5.46967Z"
      fill="#000"
    />
  </svg>
);

const prevIcon = () => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.5303 18.5303C14.2374 18.8232 13.7626 18.8232 13.4697 18.5303L7.46967 12.5303C7.17678 12.2374 7.17678 11.7626 7.46967 11.4697L13.4697 5.46967C13.7626 5.17678 14.2374 5.17678 14.5303 5.46967C14.8232 5.76256 14.8232 6.23744 14.5303 6.53033L9.06066 12L14.5303 17.4697C14.8232 17.7626 14.8232 18.2374 14.5303 18.5303Z"
      fill="#000"
    />
  </svg>
);

const mask1 = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={906.379}
    height={571.531}
    viewBox="0 0 906.379 571.531"
    className="mask1"
  >
    <defs>
      <linearGradient
        id="mask1-gradient"
        x1={0.844}
        y1={0.741}
        x2={0.365}
        y2={0.054}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#00048d" />
        <stop offset={0.048} stopColor="#000a97" />
        <stop offset={0.242} stopColor="#001dbc" />
        <stop offset={0.437} stopColor="#002dda" />
        <stop offset={0.629} stopColor="#0038ee" />
        <stop offset={0.818} stopColor="#003ffb" />
        <stop offset={1} stopColor="#0041ff" />
      </linearGradient>
    </defs>
    <path
      id="Path_5700"
      data-name="Path 5700"
      d="M556.555,66.261h654.132l15.452,164.821s-6.514,118.747,0,221.478c2.5,39.472-1.755,79.526,0,108.164,1.93,31.49,8.668,49.721-15.452,54.94-70.216,15.194-288.775,17.572-353.678,0-29.324-7.938-26.716,7.409,37.222-30.874,39.9-23.89,52.316-40.751,50.065-53.52-11.05-62.595-243.448-103.93-446.92-205.362C250.59,202.9,332.793,72.473,344.829,56.435Z"
      transform="translate(-322.102 -56.435)"
      fill="url(#mask1-gradient)"
    />
  </svg>
);

const mask2 = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={565.534}
    height={242.331}
    viewBox="0 0 565.534 242.331"
    className="mask2"
  >
    <defs>
      <linearGradient
        id="mask2-gradient"
        x1={0.422}
        y1={1.009}
        x2={0.828}
        y2={0.168}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#9c36e4" />
        <stop offset={0.375} stopColor="#9554c2" />
        <stop offset={1} stopColor="#903bbb" />
      </linearGradient>
    </defs>
    <path
      id="Path_5699"
      data-name="Path 5699"
      d="M702.633,340.3s89.912,30.573,39.646,59.339C671.83,439.947,424.22,432.44,424.22,522.406c343.594,10.2,523-30.985,523-30.985s57.446,12.4,38.815-76.028S967.4,326.982,967.4,326.982L886.531,281.7"
      transform="translate(-424.22 -281.7)"
      fill="url(#mask2-gradient)"
    />
  </svg>
);
