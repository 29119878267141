import axios from "axios";
import React, { useContext, useRef, useState } from "react";
import { useEffect } from "react";
import Scrollbars from "react-custom-scrollbars";
import ReCAPTCHAV2 from "react-google-recaptcha";
import { ThemeContext } from "../../helper/context";
import { closeIcon, transparentInfoIcon } from "../../helper/icons";
import { API_URL, axiosApiInstance } from "../../helper/request";
import { encryptStorage } from "../../helper/storage";
import { useDebouncedEffect } from "../../helper/useDebounce";
import { LoadingText } from "../../pages/auth/register/AuthRegister";
import {
  add,
  SocialInput,
  social_link,
} from "../../pages/auth/register_developer/AuthRegisterDeveloper";
import { notify } from "../../Routes";
import Button from "../Button/Button";
import Dropdown from "../Dropdown/Dropdown";
import { FormHeader } from "../FormCard/FormCard";
import InputBox from "../Inputbox/InputBox";
import AnimatedModal from "../Modal/AnimatedModal";
import PhoneInputBox from "../PhoneInputBox/PhoneInputBox";
import { Radio, Radio2 } from "../Radio/Radio";
import Scroller from "../Scroller/ScrollBar";
import "./devModal.scss";
const radios = ["Freelancer", "Student", "Company"];
const develop = ["Usecase", "AI Model", "IOT Firmware"];
let msg = {
  type: "alert",
  header: "Error",
  errorText: "Something Went Wrong",
};
let firstLoad = true;
export default function DeveloperModal({
  setDevModal,
  DevModal,
  setLoadingScreen = false,
  setShowAlert,
  handleAlert,
}) {
  const scrollbarRef = useRef();
  const themeContext = useContext(ThemeContext);
  const [ShowBank, setShowBank] = useState(true);
  const [IsBankAvailable, setIsBankAvailable] = useState(true);
  const [Role, setRole] = useState(null);
  const [CompanyName, setCompanyName] = useState("");
  const [SocialLinks, setSocialLinks] = useState([...social_link]);
  const [Develop, setDevelop] = useState([]);
  const [Bank, setBank] = useState({});
  const [Name, setName] = useState("");
  const [Phone, setPhone] = useState("+91 ");
  const [Country, setCountry] = useState("");
  const [LocationLoading, setLocationLoading] = useState(false);
  const [LocationData, setLocationData] = useState([]);
  const [TOS, setTOS] = useState(false);
  const [AllSMSelected, setAllSMSelected] = useState(false);
  const [LocationSelected, setLocationSelected] = useState(false);

  const [errors, setErrors] = useState({
    isDevelopEmpty: false,
    isTOSChecked: false,
    isRoleEmpty: false,
  });

  useDebouncedEffect(
    () => (Country ? getCountry() : undefined),
    [Country],
    1000
  );
  const getCountry = () => {
    if (firstLoad) return;
    axios
      .get(API_URL + "admin/country/filters?country=" + Country)
      .then((res) => {
        let _data = res.data.detail;
        let keys = null;
        keys = Object.keys(_data[0]);
        if (!keys?.includes("state")) {
          setLocationData(_data);
          setLocationSelected(false);
        } else {
          setLocationData([]);
          getBank(Country);
          setLocationSelected(true);
        }
      })
      .catch((err) => {
      })
      .finally(() => {
        setLocationLoading(false);
      });
  };

  const getKeyCode = (e) => {
    switch (e.keyCode) {
      case 38:
        arrowKey(e, "up");
        break;

      case 40:
        arrowKey(e, "down");
        break;

      case 13:
        enterKey(e);
        break;
    }
  };

  const arrowKey = (e, type) => {
    let myReferenceDiv = document.querySelector("._span");
    let active_flag = document.querySelector(".active_flag");
    let active_next = active_flag?.nextElementSibling;
    let active_prev = active_flag?.previousElementSibling;
    let next = myReferenceDiv?.nextElementSibling;
    let prev = myReferenceDiv?.previousElementSibling;
    if (type === "down") {
      if (!active_flag) {
        next.classList.add("active_flag");
      } else {
        if (active_next) {
          active_flag.classList.remove("active_flag");
          active_next.classList.add("active_flag");
          var childElement =
            document.querySelector(".active_flag").offsetTop -
            myReferenceDiv.offsetHeight -
            20;
        }
      }
    }
    if (type === "up") {
      if (active_prev && !active_prev.classList.contains("_span")) {
        active_flag.classList.remove("active_flag");
        active_prev.classList.add("active_flag");
        var childElement =
          document.querySelector(".active_flag").offsetTop -
          myReferenceDiv.offsetHeight -
          20;
      }
    }
  };

  const enterKey = () => {
    let active_flag = document.querySelector(".active_flag");
    let dataCountry = active_flag.getAttribute("data-country");
    if (dataCountry) {
      setCountry(dataCountry);
      getBank(dataCountry);
      setLocationData([]);
      setLocationSelected(true);
    }
  };

  const handleSocialClick = (idx) => {
    let _data = [...SocialLinks];
    _data[idx].isOpen = true;
    setSocialLinks([..._data]);
  };
  const handleSocialDelete = (idx) => {
    let _data = [...SocialLinks];
    _data[idx].isOpen = false;
    _data[idx].link = "";
    setSocialLinks([..._data]);
  };
  const handleChanges = (value, idx) => {
    const _bank = { ...Bank };
    _bank.fields[idx].value = value;
    setBank({ ..._bank });
  };
  const handleFocus = (name) => {
    let ele = document.getElementById(name);
    ele.classList.remove("error_shake", "input__error");
  };
  const renderElement = (item, idx) => {
    switch (item.type) {
      case "string":
      case "number":
        return (
          <InputBox
            id={item.name}
            header={item.name.replace(/_/g, " ")}
            onChange={(e) => {
              const value =
                item.type === "string"
                  ? e.target.value
                  : e.target.value.replace(/[^0-9]/g, "");
              handleChanges(value, idx);
            }}
            value={item.value}
            onFocus={() => handleFocus(item.name)}
          />
        );
      case "dropdown":
        return (
          <Dropdown
            label={item.name.replace(/_/g, " ")}
            className="adjust_dd"
            id={item.name}
            optionsList={item.options}
            handleOption={(data) => {
              handleChanges(data, idx);
              handleFocus(item.name);
              // setAccountType(data);
            }}
            defaultText={item.value}
            // error={BankError["isAccountTypeEmpty"]}
          />
        );
      default:
        return <p> NO ELEMENT FOUNG</p>;
    }
  };

  const getBank = (country) => {
    setLoadingScreen(true);
    axios
      .get(API_URL + "admin/bank/details?country=" + country)
      .then((res) => {
        // setShowBank(true);
        setIsBankAvailable(true);
        let dict = { ...res.data };
        for (let i = 0; i < dict.fields.length; i++) {
          dict.fields[i].value = "";
        }
        setCountry(country);
        setBank({ ...dict });
      })
      .catch((err) => {
        // setShowBank(false);
        setBank({});
        setIsBankAvailable(false);
      })
      .finally(() => {
        setLoadingScreen(false);
        setTimeout(() => {
          firstLoad = false;
        }, 2000);
      });
  };

  const validateBankData = () => {
    let res = false;
    for (let i = 0; i < Bank.fields.length; i++) {
      if (Bank.fields[i].required) {
        if (!Bank.fields[i].value) {
          let ele = document.getElementById(Bank.fields[i].name);
          ele.classList.add("error_shake", "input__error");
          res = true;
        }
      }
    }
    return res;
  };

  const getUserData = async () => {
    let dataa = encryptStorage.getItem("UID");
    axios
      .post(
        API_URL + "auth/refresh",
        {},
        {
          headers: {
            Authorization: "Bearer " + dataa.refreshToken,
          },
        }
      )
      .then((res) => {
        dataa.accessToken = res.data.accessToken;
        let _all = [...themeContext.Role.all];
        _all.push("developer");
        themeContext.setRole({
          ...themeContext.Role,
          all: [..._all],
        });
        dataa.roles = [..._all];
        encryptStorage.setItem("UID", dataa);
        msg.errorText = "Registered as developer successfully!";
        msg.header = "Success";
        msg.type = "success";
        handleAlert(true, msg);
        setShowAlert(true);
        setTimeout(() => {
          setDevModal(false);
        }, 3000);
      })
      .catch((err) => {
        encryptStorage.removeItem("UID");
        encryptStorage.removeItem("VID");
        encryptStorage.removeItem("VID2");
      })
      .finally(() => {
        setLoadingScreen(false);
      });
  };

  const postData = () => {
    let error = false;
    let _mobile = Phone.split(" ")[1];

    if (!Role || Develop.length == 0) {
      if (!Role) {
        document.getElementById("_role").style.border =
          "2px solid rgba(248, 78, 78, 0.7)";
        document.getElementById("_role").style.padding = "5px";
        document.getElementById("_role").style.marginBottom = "0.8vw";
      }
      if (Develop.length == 0) {
        document.getElementById("_like").style.border =
          "2px solid rgba(248, 78, 78, 0.7)";
        document.getElementById("_like").style.padding = "5px";
        document.getElementById("_like").style.marginBottom = "0.8vw";
      }
      error = true;
    }
    if (!TOS) {
      error = true;
      notify({
        msg: "Please accept our Terms of Service.",
      });
      // msg.errorText = "Please accept out Terms of Service.";
      // msg.header = "Error";
      // msg.type = "alert";
      // handleAlert(true, msg);
      // setShowAlert(true);
      // return;
    }
    // if (Object.values(_errors).includes(true)) {
    //   return;
    // }

    if (ShowBank && IsBankAvailable) {
      if (!Name) {
        document
          .getElementById("_fullName_")
          .classList.add("error_shake", "input__error");
        error = true;
      }
      if (!_mobile) {
        document
          .getElementById("_mobile_number_")
          .classList.add("error_shake", "input__error");
        error = true;
      }
      if (!Country) {
        document
          .getElementById("country")
          .classList.add("error_shake", "input__error");
        error = true;
      }
      if (validateBankData()) {
        notify({
          msg: "Please fill bank details.",
        });
        error = true;
      }
    }

    if (error) {
      notify({
        msg: "All field required",
      });
      return;
    }
    // let _uname = lData?.userData?.Username;
    let _sLink = {};
    SocialLinks.forEach((element) => {
      if (element.isOpen) {
        _sLink[element.name.toLowerCase()] = element.link;
      }
    });

    let body = {
      profession: Role,
      company_name: CompanyName,
      interested_areas: Develop,
      social_media: { ..._sLink },
    };
    if (ShowBank && IsBankAvailable) {
      let _bank = {};
      for (let i = 0; i < Bank.fields.length; i++) {
        _bank[Bank.fields[i].name] = Bank.fields[i].value;
      }
      body.contact_person_name = Name;
      body.contact_person_phone = Phone;
      body.country = Country;
      body.bank_details = { ..._bank };
    }
    setLoadingScreen(true);
    axios
      .post(API_URL + "user/signup/developerIn", body, {
        headers: {
          Authorization: "Bearer " + encryptStorage.getItem("UID").refreshToken,
        },
      })
      .then((res) => {
        getUserData();
      })
      .catch((err) => {
        msg.errorText = "Failed to save data!";
        msg.header = "Error";
        msg.type = "alert";
        handleAlert(true, msg);
        setShowAlert(true);
        setLoadingScreen(false);
      });
  };

  useEffect(() => {
    let arr = SocialLinks.map((item) => item.isOpen);
    if (arr.every((item) => item === true)) {
      setAllSMSelected(true);
    } else {
      setAllSMSelected(false);
    }
    scrollbarRef.current && scrollbarRef.current.scrollToBottom();
  }, [SocialLinks]);

  useEffect(() => {
    if (DevModal) {
      setLoadingScreen(true);
      axiosApiInstance
        .get("user/details/check")
        .then((res) => {
          if (res.data?.company_name) {
            setCompanyName(res.data?.company_name);
          }
          if (!res.data?.bank_present) {
            setShowBank(true);
            setCountry(res.data?.country);
            getBank(res.data?.country);
          } else {
            setShowBank(false);
            setCountry(res.data?.country);
            setLoadingScreen(false);
          }
        })
        .catch((err) => {
          notify({});
          setLoadingScreen(false);
        });
      // setShowBank(true);
      // getUserData();
      // getBank();
    }
  }, [DevModal]);

  // if (!DevModal) {
  //   return;
  // }
  return (
    <AnimatedModal modalOpen={DevModal} className="dev_modal_wrapper">
      <div
        className="close_otp_modal"
        onClick={() => {
          setDevModal(false);
          setCountry("");
          setBank({});
          setShowBank(false);
          setName("");
          setPhone("");
          setTOS(false);
          setSocialLinks([...social_link]);
          setDevelop([]);
          // setEmail("");
        }}
      >
        {closeIcon()}
      </div>
      <FormHeader name="Sign up to Become a" bold="Developer." />
      <p className="sub">Develop a solution for the world with us.</p>
      <Scroller autoHeightMax={"28vw"} autoHeightMin={"25vw"}>
        <div style={{ paddingRight: "1.2vw" }}>
          <div
            style={{
              borderRadius: "12px",
              display: "inline-block",
            }}
            id="_role"
          >
            <span className="radio-header _star">
              Currently, what are you doing?{" "}
            </span>
            <div
              style={{
                display: "flex",
                gap: "2.5vw",
                marginBottom: "1.2vw",
                marginTop: "0.625vw",
              }}
            >
              {radios.map((item) => (
                <Radio2
                  checked={Role === item}
                  name={item}
                  key={item}
                  onClick={() => {
                    document.getElementById("_role").style.border = "0";
                    document.getElementById("_role").style.padding = "0px";
                    setRole(item);
                  }}
                />
              ))}
            </div>
          </div>
          <InputBox
            id="companyName"
            header="Company name"
            onChange={(e) => {
              const value = e.target.value;
              const regex = /^[a-zA-Z ]*$/;
              if (value.match(regex) || value === "") {
                setCompanyName(value);
              }
            }}
            // error={errors["isDevelopEmpty"]}
            value={CompanyName}
            // onFocus={() => clearError("isDevelopEmpty")}
          />
          <div
            id="_like"
            style={{
              borderRadius: "12px",
              display: "inline-block",
            }}
            onClick={() => {
              document.getElementById("_like").style.border = "0";
              document.getElementById("_like").style.padding = "0px";
              document.getElementById("_like").style.marginBottom = "0px";
            }}
          >
            <span className="radio-header _star">
              What would you like to develop?
            </span>
            <div
              style={{
                display: "flex",
                gap: "2.5vw",
                marginBottom: "1.2vw",
                paddingLeft: "4px",
                marginTop: "0.625vw",
              }}
            >
              {develop.map((item, indx) => (
                <Radio
                  key={item}
                  name={item}
                  id={"dev_" + indx}
                  checked={Develop.includes(item)}
                  onClick={() => {
                    let _develop = [...Develop];
                    if (_develop.includes(item)) {
                      const index = _develop.indexOf(item);
                      if (index > -1) {
                        _develop.splice(index, 1);
                      }
                    } else {
                      _develop.push(item);
                    }
                    setDevelop([..._develop]);
                  }}
                  className="adjust_cbb"
                />
              ))}
            </div>
          </div>
          <div className="social_links_wrapper">
            <div className="radio-header _flex">
              <p>Social Media</p>
              {!AllSMSelected && (
                <div className="fab">
                  <div className="mainop">{add()}</div>
                  <div className="minifab" />
                  {SocialLinks.map((item, index) => {
                    if (!item.link && !item.isOpen) {
                      return (
                        <div
                          key={item.name}
                          className="minifab"
                          onClick={() => handleSocialClick(index)}
                        >
                          {item.icon}
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
              )}
            </div>
            <Scroller autoHeight autoHeightMax="10vw" ref={scrollbarRef}>
              <div style={{ padding: "0.5vw 0", paddingRight: "0.2vw" }}>
                {SocialLinks.map((item, index) => {
                  if (item.isOpen) {
                    return (
                      <SocialInput
                        key={item.name}
                        icon={item.icon}
                        onDelete={() => handleSocialDelete(index)}
                        onChange={(e) => {
                          let _sLink = [...SocialLinks];
                          _sLink[index].link = e.target.value;
                          setSocialLinks([..._sLink]);
                        }}
                        value={SocialLinks[index].link}
                      />
                    );
                  }
                  return null;
                })}
              </div>
            </Scroller>
          </div>

          {ShowBank && (
            <React.Fragment>
              <div className="country_section">
                <InputBox
                  id="country"
                  header="Country"
                  onChange={(e) => {
                    const value = e.target.value;
                    const regex = /^[a-zA-Z ]*$/;
                    if (value.match(regex) || value === "") {
                      setCountry(value);
                      setLocationLoading(true);
                    } else {
                      setLocationLoading(false);
                    }
                    setName("");
                    setPhone("+91 ");
                    setIsBankAvailable(false);
                  }}
                  onKeyDown={getKeyCode}
                  // error={errors["isCountryEmpty"]}
                  value={Country}
                  // onFocus={() => clearError("isCountryEmpty")}
                />
                {LocationLoading && Country ? (
                  <div className="suggestions">
                    <LoadingText />
                  </div>
                ) : (
                  LocationData.length > 0 && (
                    <div className="suggestions">
                      <Scrollbars autoHeight autoHeightMax="15vh">
                        <span className="_span" />
                        {LocationData.map((item) => (
                          <div
                            key={item?.country}
                            className="suggestion_item"
                            data-country={item?.country}
                            onClick={() => {
                              setCountry(item?.country);
                              setLocationData([]);
                              getBank(item?.country);
                              setLocationSelected(true);
                              firstLoad = true;
                              setTimeout(() => {
                                firstLoad = false;
                              }, 2000);
                            }}
                          >
                            {item?.country}
                          </div>
                        ))}
                      </Scrollbars>
                    </div>
                  )
                )}
              </div>
              <div style={{ marginTop: "1vw" }} />
              {IsBankAvailable && (
                <div className="contact_person">
                  <span className="fc-header">Contact Person</span>
                  <div className="bank_detail">
                    <InputBox
                      id="_fullName_"
                      header="Name"
                      onChange={(e) => {
                        const value = e.target.value;
                        const regex = /^[a-zA-Z ]*$/;
                        if (value.match(regex) || value === "") {
                          setName(value);
                        }
                      }}
                      value={Name}
                      onFocus={() => handleFocus("_fullName_")}
                    />
                    <PhoneInputBox
                      id="_mobile_number_"
                      isEdit={true}
                      onChange={(data) => {
                        // if (data.inputNumber) {
                        let _number = data?.countryCode + " " + data.inputNumber;
                        setPhone(_number);
                        // }
                      }}
                      onFocus={() => handleFocus("_mobile_number_")}
                      value={Phone}
                    />
                  </div>
                </div>
              )}

              {IsBankAvailable && (
                <span className="radio-header">
                  Bank Details
                  {transparentInfoIcon({
                    marginLeft: "1vw",
                    cursor: "pointer",
                    verticalAlign: "middle",
                  })}
                </span>
              )}

              {IsBankAvailable && (
                <div className="bank_detail">
                  {Bank?.fields?.map((item, idx) => renderElement(item, idx))}
                </div>
              )}
            </React.Fragment>
          )}
          {!IsBankAvailable && LocationSelected && (
            <div className="noBank">
              Your country's bank is currently not supported. Please Contact us
              for further query.
            </div>
          )}
        </div>
      </Scroller>
      <div className="recaptcha">
        <ReCAPTCHAV2
          data-type="image"
          sitekey={process.env.REACT_APP_SITE_KEY}
          // onChange={onChange}
          // size="compact"
        />
      </div>
      <div className="_tos">
        <Radio
          id="checkbox_002"
          checked={TOS}
          onClick={() => {
            setTOS(!TOS);
          }}
          className="adjust_cb"
        />
        <p>
          Creating an account means you’re okay with our{" "}
          <span>
            Terms of Service,
            <br /> Privacy Policy,
          </span>{" "}
          and our default <span>Notification Settings</span>.
        </p>
      </div>
      <Button
        onClick={postData}
        // onClick={() => setDevModal(false)}
        style={{
          display: "block",
          margin: "2vw auto",
          marginBottom: "0",
          width: "90%",
        }}
        type="gradient"
        bg
        name="Submit"
      />
    </AnimatedModal>
  );
}
