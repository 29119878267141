import React, { useContext, useEffect } from "react";
import Loading from "../components/Loading/Loading";

import CryptoJS from "crypto-js";
import { decrypt, encryptStorage, useQuery } from "../helper/storage";
import { ThemeContext } from "../helper/context";

export default function RedirectPage() {
  let query = useQuery();
  const themeContext = useContext(ThemeContext);

  useEffect(() => {
    let link = query.get("link");
    console.log(link);
    try {
      let decryptedData = decrypt(link);
      console.log(decryptedData);
      themeContext.setRole(decryptedData);
    } catch (error) {
      encryptStorage.clear();
      console.log("IN ERROR");
    }
  }, []);
  return (
    <React.Fragment>
      <Loading type={"transparent"} text={"Authenticating"} />
    </React.Fragment>
  );
}
