import React from "react";
import "./tos.scss";
import Scroller from "../../components/Scroller/ScrollBar";
import { Link } from "react-router-dom";
export default function Privacy() {
  return (
    <div className="__tos_wrapper__">
      <div className="tos_body">
        <h1>
          <span>
            <Link to={"/"}>
              <BackIcon />
            </Link>
          </span>
          Privacy Policy
        </h1>
        <hr style={{ marginBottom: "20px" }} />
        <Scroller autoHeightMax="75vh">
          <div className="data_block">
            <h2>Introduction</h2>
            <p>
              This Privacy Policy describes how we collect, use, and share
              information when you use our computer vision platform (the
              "Platform"). We understand that your privacy is important, and we
              are committed to protecting your personal information.
            </p>
            <h2>Information We Collect</h2>
            <p>
              We collect several types of information when you use our Platform,
              including:
            </p>
            <p>
              <span className="sub">Personal Information: </span>When you create
              an account on our Platform, we collect personal information such
              as your name, email address, and other contact information. This
              information is necessary for us to provide you with access to the
              Platform.
            </p>
            <p>
              <span className="sub">Technical Information: </span>We collect
              technical information about your use of the Platform, such as the
              type of device you are using, your IP address, and other
              information about how you interact with the Platform. This
              information helps us optimize the Platform for your specific
              device and improve its overall performance.
            </p>
            <p>
              <span className="sub">Use Information: </span>We collect
              information about how you use the Platform, including which AI
              models and use cases you download, how you interact with them, and
              if you choose to stream IP cameras for generating alerts and
              reports. This information helps us personalize your experience on
              the Platform and make recommendations for AI models and use cases
              that may be of interest to you.
            </p>
            <p>
              <span className="sub">Camera Information: </span>If you choose to
              stream IP cameras for generating alerts and reports, we may
              collect camera information such as the camera's IP address, brand,
              and model. This information is necessary to provide the camera
              streaming feature and to generate alerts and reports based on the
              camera's footage.
            </p>
            <h2>Use of Information</h2>
            <p>We use the information we collect for the following purposes:</p>
            <p>
              <span className="sub">To provide and improve the Platform:</span>
              We use the information we collect to provide the Platform and
              improve its functionality and performance. This includes
              monitoring the use of the Platform to identify and fix bugs,
              adding new features, and improving the user experience.
            </p>
            <p>
              <span className="sub">To communicate with you: </span>
              We use your contact information to communicate with you about your
              use of the Platform and to provide you with updates and
              information about the Platform. This may include sending you
              newsletters, promotional materials, and other communications
              related to the Platform.
            </p>
            <p>
              <span className="sub">To personalize your experience: </span>
              We use the information we collect to personalize your experience
              on the Platform, such as by recommending AI models and use cases
              that we think may be of interest to you based on your past usage.
            </p>
            <p>
              <span className="sub">To generate alerts and reports: </span>
              If you choose to stream IP cameras for generating alerts and
              reports, we use the camera footage to generate alerts and reports
              based on your specified criteria, such as motion detection or
              facial recognition. This information is only used for the purpose
              of generating alerts and reports and is not shared with third
              parties.
            </p>
            <h2>Sharing of Information</h2>
            <p>We may share your information with the following parties:</p>
            <p>
              <span className="sub">Developers: </span>
              We may share your personal and technical information with
              developers who upload AI models and use cases to the Platform.
              This information helps developers optimize their AI models and use
              cases for your specific device and improve their overall
              performance.
            </p>
            <p>
              <span className="sub">Service Providers: </span>
              We may share your information with service providers who help us
              operate and maintain the Platform. These service providers may
              include hosting providers, email providers, and customer support
              providers.
            </p>
            <p>
              <span className="sub">Legal Requirements: </span>
              We may disclose your information in response to a legal request,
              such as a subpoena or court order. We may also disclose your
              information if we believe it is necessary to protect our rights,
              property, or safety or the rights, property, or safety of others.
            </p>
            <h2>Security</h2>
            <p>
              We take reasonable measures to protect your personal information
              from unauthorized access, use, or disclosure. These measures may
              include encryption, firewalls, and other security technologies.
              However, no method of transmission over the internet or method of
              electronic storage is completely secure, so we cannot guarantee
              the absolute security of your personal information. We encourage
              you to take steps to protect your personal information, such as
              using strong passwords and keeping your account information
              secure.
            </p>
            <h2>Data Retention</h2>
            <p>
              We retain your personal information for as long as necessary to
              provide you with the Platform and to comply with our legal
              obligations. We may also retain your information for a longer
              period of time if necessary to resolve disputes or enforce our
              agreements.
            </p>
            <h2>Your Rights</h2>
            <p>
              You have certain rights with respect to your personal information,
              including the right to access, correct, and delete your personal
              information. You may also have the right to object to the
              processing of your personal information and to restrict the use of
              your personal information. If you would like to exercise any of
              these rights, please contact us using the information provided
              below.
            </p>
            <h2>Changes to this Policy</h2>
            <p>
              We may update this Privacy Policy from time to time to reflect
              changes in our practices or for other operational, legal, or
              regulatory reasons. If we make material changes to this Privacy
              Policy, we will provide notice to you by email or by posting a
              notice on the Platform.
            </p>
            <h2>Contact Us</h2>
            <p>
              If you have any questions or concerns about this Privacy Policy or
              our practices, please contact us at{" "}
              <a href="mailto: info@easemyai.com">info@easemyai.com</a>.
            </p>
            <h2>Conclusion</h2>
            <p>
              We take your privacy seriously and are committed to protecting
              your personal information. We believe that transparency is key to
              building trust, so we encourage you to read this Privacy Policy
              carefully and to contact us if you have any questions or concerns.
              Thank you for choosing our computer vision platform.
            </p>
          </div>
        </Scroller>
      </div>
    </div>
  );
}

export const BackIcon = (props) => (
  <svg
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 477.175 477.175"
    style={{
      enableBackground: "new 0 0 477.175 477.175",
    }}
    xmlSpace="preserve"
    className="backIcon"
    {...props}
  >
    <g>
      <path d="M145.188,238.575l215.5-215.5c5.3-5.3,5.3-13.8,0-19.1s-13.8-5.3-19.1,0l-225.1,225.1c-5.3,5.3-5.3,13.8,0,19.1l225.1,225 c2.6,2.6,6.1,4,9.5,4s6.9-1.3,9.5-4c5.3-5.3,5.3-13.8,0-19.1L145.188,238.575z" />
    </g>
  </svg>
);
