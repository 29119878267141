import React, { useContext, useEffect } from "react";
import Button from "../../../../../components/Button/Button";
import Scroller from "../../../../../components/Scroller/ScrollBar";
import { ThemeContext } from "../../../../../helper/context";
import { axiosApiInstance } from "../../../../../helper/request";
import "./CreatedLicenseCard.scss";
import { encryptStorage } from "../../../../../helper/storage";
let msg = {
  type: "success",
  header: "success",
  errorText: "successfully activated",
};
let Role = [];

export default function CreatedLicenseCard({
  licenseName,
  Attributedata,
  assignChildMfg,
  setAssignChildMfg,
  setAttributeData,
  licenseDuration,
  licenseDetails,
  setAssignType,
  setAssignLicenseName,
  item,
  mfrDetails,
  setmfrDetails,
  assignClick,
  licenseQuantity,
  selectCard,
  specialId,
  setFetchLicense,
  setShowAlert,
  serialNumber,
  getSpecialLicenses,
  msg,
  LicenseType,
  type,
  clickedAppName,
  clickedThumbnail,
}) {
  const themeContext = useContext(ThemeContext);
  console.log("license type", type);

  const postActivate = () => {
    let body = {};

    type === "app"
      ? (body = {
          item_type: "usecase",
          usecase: {
            usecase_name: clickedAppName,
            usecase_image: clickedThumbnail,
          },
          serial_number: serialNumber,
          license_details: licenseDetails,
          license_type: "special",
          license_duration: licenseDuration,
          license_name: licenseName,
        })
      : (body = {
          item_type: "device",
          serial_number: serialNumber,
          license_details: licenseDetails,
          license_type: "special",
          license_duration: licenseDuration,
          license_name: licenseName,
        });
    axiosApiInstance
      .post("device/special/licenses/activate", body)
      .then((res) => {
        msg.errorText = "Successfully Activated";
        msg.header = "Success";
        msg.type = "success";
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 3000);
      })
      .catch((err) => {
        msg.errorText = "Couldn't Activate";
        msg.header = "Error";
        msg.type = "alert";
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 3000);
      })
      .finally(() => {
        getSpecialLicenses();
        setFetchLicense(false);
      });
  };
  useEffect(() => {
    let dataa = encryptStorage.getItem("UID").roles;
    Role = dataa;
  }, []);

  return (
    <div
      className={
        themeContext.Role.current === "user"
          ? "createdlicense_main_wrapper_user createdlicense_main_wrapper "
          : "createdlicense_main_wrapper"
      }
    >
      <div className="createdlicense_subwrapper">
        {console.log(themeContext.Role.current, "themeContext.Role.current")}
        <div
          className={
            themeContext.Role.all.includes("superadmin")
              ? "createdlicense_partition_wrapper createdlicense_partition_wrapper_superadmin "
              : themeContext.Role.current === "user"
              ? "createdlicense_partition_wrapper_user createdlicense_partition_wrapper"
              : "createdlicense_partition_wrapper"
          }
        >
          <div className="createdlicense_lhs">
            <div title={licenseName} className="license_name">
              {/* <div className="license_name_overflow">{licenseName}</div> */}
              {/* <span className="tooltiptext">{licenseName}</span> */}
              {licenseName}
            </div>
            <div className="duration_wrapper">
              <div className="duration_wrapper_lhs">
                <p className="duration">Duration</p>
                <p className="durationPlan_type">
                  {licenseDuration?.replaceAll("_", " ") + " plan"}
                </p>
              </div>

              {themeContext.Role.all.includes("user") && (
                <div className="duration_wrapper_rhs">
                  <p className="duration">Quantity</p>
                  <p className="quantityPlan_type">{licenseQuantity}</p>
                </div>
              )}
            </div>
            {console.log(LicenseType, licenseName, "LicenseType")}
            <div className="createdlicense_button_wrapper">
              {themeContext.Role.current === "manufacturer" && (
                <Button
                  onClick={() => {
                    setAssignChildMfg(true);
                    setAssignType("assignMfg");
                    // setAssignLicenseName(licenseName)
                    LicenseType === undefined &&
                      setAssignLicenseName(licenseName);
                    assignClick();
                    // mfrDetails;
                  }}
                  name={"Assign"}
                />
              )}
              <div className="device_button">
                {LicenseType === "Special License" &&
                  themeContext.Role.current === "manufacturer" &&
                  !themeContext.Role.all.includes("superadmin") && (
                    <Button onClick={() => {}} name={"Assign to device"} />
                  )}
              </div>
            </div>

            {selectCard && (
              <Button
                disabled={licenseQuantity < 1 && true}
                onClick={() => {
                  postActivate();
                }}
                name={"Activate"}
              />
            )}
          </div>
          {licenseDetails && (
            <div className="createdlicense_rhs">
              <table className="license_table">
                <tr className="table_row">
                  <th style={{ textAlign: "left" }} className="table_header">
                    Name
                  </th>
                  <th style={{ textAlign: "center" }} className="table_header">
                    Quantity
                  </th>
                </tr>
              </table>
              <Scroller
                autoHeightMax="10vh"
                verticalStyle={{
                  width: "1.5vw",
                  marginLeft: "0.35vw",
                  backgroundColor: "#E9E9E9",
                }}
              >
                <table>
                  {Object.entries(licenseDetails).map((item) => (
                    <tr className="table_row">
                      <td
                        className="table_data"
                        style={{
                          textAlign: "left",
                          borderBottom: "0.5px solid rgba(0, 0, 0, 0.1)",
                        }}
                      >
                        {item[0]}
                      </td>
                      <td
                        className="table_data"
                        style={{
                          textAlign: "center",
                          borderBottom: "0.5px solid rgba(0, 0, 0, 0.1)",
                        }}
                      >
                        {item[1]}
                      </td>
                    </tr>
                  ))}
                </table>
              </Scroller>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
