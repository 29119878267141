import React, { useState, useEffect, useMemo, useRef } from "react";
import Navbar from "../../../components/Navbar/Navbar";
import "./users.scss";
import bg from "../../../assets/images/background.png";
import { motion } from "framer-motion";
import { axiosApiInstance } from "../../../helper/request";
import { useDebouncedEffect } from "../../../helper/useDebounce";
import Pagination from "../../../components/Pagination/Pagination";
import Scroller from "../../../components/Scroller/ScrollBar";
import { activeIcon, searchIcon2 } from "../../../helper/icons";
import { useHistory } from "react-router-dom";
import { getDateTime, getParams, useQuery } from "../../../helper/storage";
import Loading from "../../../components/Loading/Loading";
import MultiSelectDropdown from "../../../components/MultiSelectDropdown/MultiSelectDropdown";
import Dropdown from "../../../components/Dropdown/Dropdown";
import { notify } from "../../../Routes";
import { Radio } from "../../../components/Radio/Radio";
import useClickOutside from "../../../helper/useClickOutside";
let isFirstLoad = true;
let locationData = [];

export default function Users() {
  const queryy = useQuery();
  const primaryRef = useRef();
  const history = useHistory();
  const page = queryy.get("page") || "";
  const size = queryy.get("size") || "";
  const [SearchText, setSearchText] = useState("");
  const [Data, setData] = useState([]);
  const [IsLoading, setIsLoading] = useState(true);
  const [CurrentPage, setCurrentPage] = useState(1);
  const [TotalPage, setTotalPage] = useState([]);
  const [ShowLocation, setShowLocation] = useState(false);
  const [Location, setLocation] = useState("");
  const [LocationSearch, setLocationSearch] = useState("");
  const [SelectedFilter, setSelectedFilter] = useState({
    status: "",
    role: "",
    location: [],
  });
  const [FilterOptions, setFilterOptions] = useState({
    role: ["user", "developer", "manufacturer", "moderator"],
    status: ["active", "suspended"],
    location: [],
  });
  useClickOutside(primaryRef, () => {
    setShowLocation(false);
  });
  useDebouncedEffect(
    () => (SelectedFilter ? getUser(1) : undefined),
    [SelectedFilter],
    500
  );

  useDebouncedEffect(() => getUser(1), [SearchText], 500);

  const filteredItems = useMemo(() => {
    return locationData[Location]?.filter((item) => {
      return item?.toLowerCase().startsWith(LocationSearch.toLowerCase());
    });
  }, [LocationSearch, Location]);

  const getUser = (page) => {
    if (isFirstLoad) return;
    let query = "";
    const params = new URLSearchParams(window.location.search);
    let paramKeys = getParams();
    let _type = paramKeys.type;
    if (SearchText) {
      query += "&field=" + SearchText;
      let url = new URL(window.location.href);
      url.searchParams.set("field", SearchText);
      window.history.pushState(null, null, url);
    } else {
      const params = new URLSearchParams(window.location.search);
      params.delete("field");
      var _url = window.location.pathname + "?" + params.toString();
      window.history.pushState(null, null, _url);
    }
    if (SelectedFilter.status) {
      query +=
        "&role_status=" + SelectedFilter.role + ":" + SelectedFilter.status;
      let url = new URL(window.location.href);
      url.searchParams.set("role", SelectedFilter.role);
      url.searchParams.set("status", SelectedFilter.status);
      window.history.pushState(null, null, url);
    }
    Object.keys(SelectedFilter).forEach((element) => {
      if (SelectedFilter[element]?.length > 0) {
        let url = new URL(window.location.href);
        if (element === "location") {
          url.searchParams.set(element, SelectedFilter[element].join());
          window.history.pushState(null, null, url);
          query += "&" + Location + "=" + SelectedFilter[element].join();
        }
        // else {
        //   url.searchParams.set(element, SelectedFilter[element]);
        //   window.history.pushState(null, null, url);
        //   query += "&" + element + "=" + SelectedFilter[element];
        // }
      } else {
        const params = new URLSearchParams(window.location.search);
        params.delete(element);
        var _url = window.location.pathname + "?" + params.toString();
        window.history.pushState(null, null, _url);
      }
    });

    // let url = new URL(window.location.href);
    // url.searchParams.set("page", page);
    // window.history.pushState(null, null, url);
    let _page = page || paramKeys.page;
    let url = new URL(window.location.href);
    url.searchParams.set("page", _page);
    window.history.pushState(null, null, url);
    setCurrentPage(_page);
    // let query =
    //   "user/superadmin/users/details?field=" +
    //   SearchText +
    //   "&page=" +
    //   paramKeys.page +
    //   "&items=10";
    axiosApiInstance
      .get(
        "user/superadmin/users/details?page=" + _page + "&items=10" + query
        // "user/superadmin/users/details?full_name=" +
        // SearchText.replace(/[ ,]+/g, ",") +
        // SearchText +
        // "&page=" +
        // page +
        // "&items=10"
      )
      .then((res) => {
        let _totalPages = Math.ceil(res.data.total_count / 10);
        setData(res.data.users);
        setTotalPage(_totalPages);
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getLocation = () => {
    axiosApiInstance
      .get("user/superadmin/users/unique/filters")
      .then((res) => {
        locationData = { ...res.data };
        setFilterOptions({
          ...FilterOptions,
          location: Object.keys(locationData),
        });
      })
      .catch((err) => {
        notify({});
        // locationData = {
        //   country: ["india", "sri lanka", "australia", "indonesia"],
        //   state: ["Maharashtra", "Punjab"],
        //   city: ["Pune", "Mumbai"],
        // };
        // setFilterOptions({
        //   ...FilterOptions,
        //   location: ["country", "state", "city"],
        // });
      });
  };

  useEffect(() => {
    if (Object.keys(getParams()).length <= 2) {
      setTimeout(() => {
        isFirstLoad = false;
        getUser(page || 1);
      }, 600);
      var url = new URL(window.location.href);
      url.searchParams.set("page", page || 1);
      url.searchParams.set("size", size || 10);
      window.history.pushState(null, null, url);
    } else {
      let paramKeys = getParams();
      let field = paramKeys.field;
      delete paramKeys?.field;
      delete paramKeys?.page;
      delete paramKeys?.size;


      setTimeout(() => {
        isFirstLoad = false;
        if (paramKeys.type) {
          setLocation(paramKeys.type);
        }
        setSearchText(field?.replace(/,/g, " "));
        setSelectedFilter({
          location: paramKeys?.location?.split(","),
          role: paramKeys?.role,
          status: paramKeys?.status,
        });
      }, 600);
    }
    getLocation();
    return () => {
      isFirstLoad = true;
    };
  }, []);
  return (
    <div className="__users_wrapper__">
      <Navbar sidenav>
        <div className="users_body">
          <img src={bg} className="profile_bg" />
          <div className="body_content">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h1>Users</h1>
              <p className="goBack"></p>
            </div>
            <div className="users_data">
              <div className="search_result_wrapper">
                <div className="content">
                  <div className="filter_wrapper">
                    <div className="search_wrapper" id="search">
                      {searchIcon2()}
                      <input
                        type="text"
                        className="search_input"
                        placeholder="Search"
                        autoFocus
                        value={SearchText}
                        onChange={(e) => {
                          setSearchText(e.target.value);
                        }}
                      />
                    </div>
                    <div className="filter-options-wrapper">
                      <Dropdown
                        label={"Role"}
                        style={{ width: "7.2vw" }}
                        className="adjust_dd"
                        id={"role"}
                        optionsList={FilterOptions.role}
                        handleOption={(data) => {
                          // setCurrentPage(1);
                          if (SelectedFilter.status === "") {
                            setSelectedFilter({
                              ...SelectedFilter,
                              role: data,
                              status: "active",
                            });
                          } else {
                            setSelectedFilter({
                              ...SelectedFilter,
                              role: data,
                            });
                          }
                        }}
                        defaultText={SelectedFilter.role}
                      />
                      <Dropdown
                        label={"Status"}
                        style={{ width: "7.2vw" }}
                        className="adjust_dd"
                        id={"status"}
                        optionsList={FilterOptions.status}
                        handleOption={(data) => {
                          if (SelectedFilter.role === "") {
                            setSelectedFilter({
                              ...SelectedFilter,
                              role: "user",
                              status: data,
                            });
                          } else {
                            setSelectedFilter({
                              ...SelectedFilter,
                              status: data,
                            });
                          }
                        }}
                        defaultText={SelectedFilter.status}
                      />
                      <div className="location_search">
                        <div style={{ display: "flex" }}>
                          <Dropdown
                            label={"Location"}
                            style={{ width: "7.2vw" }}
                            className="adjust_location_dd"
                            id={"location"}
                            optionsList={FilterOptions.location}
                            handleOption={(data) => {
                              var url = new URL(window.location.href);
                              url.searchParams.set("type", data);
                              window.history.pushState(null, null, url);
                              setLocation(data);
                              setShowLocation(true);
                              setLocationSearch("");
                              if (SelectedFilter.location.length > 0) {
                                setSelectedFilter({
                                  ...SelectedFilter,
                                  location: [],
                                });
                              } else {
                                // isFirstLoad = true;
                                // setTimeout(() => {
                                //   isFirstLoad = false;
                                // }, 1500);
                              }
                            }}
                            defaultText={Location}
                          />
                          <div
                            className="selected_options"
                            style={{ cursor: Location ? null : "not-allowed" }}
                            onClick={() => {
                              if (Location) {
                                setShowLocation(true);
                              }
                            }}
                          >
                           
                            {SelectedFilter?.location?.length > 0 ? (
                              <p title={SelectedFilter?.location?.join()}>
                                {SelectedFilter?.location?.join()}
                              </p>
                            ) : (
                              <p style={{ textTransform: "none" }}>
                                Select a location
                              </p>
                            )}
                            {ShowLocation && (
                              <div
                                className="suggestion_wrapper"
                                ref={primaryRef}
                              >
                                <input
                                  type={"text"}
                                  placeholder="search"
                                  className="location_search_input"
                                  onChange={(e) => {
                                    setLocationSearch(e.target.value);
                                  }}
                                  value={LocationSearch}
                                />
                                <Scroller autoHeightMax="18vh">
                                  <div className="suggestion">
                                    {filteredItems?.map((item, idx) => (
                                      <button
                                        key={item}
                                        className="options"
                                        onClick={() => {
                                          let _selectedFilter = {
                                            ...SelectedFilter,
                                          };
                                          if (
                                            _selectedFilter.location.includes(
                                              item
                                            )
                                          ) {
                                            let _idx =
                                              _selectedFilter.location.indexOf(
                                                item
                                              );
                                            _selectedFilter.location.splice(
                                              _idx,
                                              1
                                            );
                                            setSelectedFilter({
                                              ..._selectedFilter,
                                            });
                                          } else {
                                            setSelectedFilter({
                                              ...SelectedFilter,
                                              location: [
                                                ...SelectedFilter.location,
                                                item,
                                              ],
                                            });
                                          }
                                        }}
                                        // style={{
                                        //   backgroundColor:
                                        //     SelectedFilter?.location?.includes(
                                        //       item
                                        //     )
                                        //       ? "var(--primary)"
                                        //       : null,
                                        // }}
                                      >
                                        {item}
                                        <Radio
                                          style={{ pointerEvents: "none" }}
                                          id={"checkbox_" + idx}
                                          checked={SelectedFilter?.location?.includes(
                                            item
                                          )}
                                        />
                                      </button>
                                    ))}
                                  </div>
                                </Scroller>
                              </div>
                            )}
                          </div>
                        </div>

                        {/* <div
                          style={{
                            display: "inline-block",
                            position: "relative",
                          }}
                        >
                          {["filteredItems"]?.length > 0 && (
                            <div className="suggestion_wrapper">
                              <input
                                type={"text"}
                                className="location_search_input"
                                onChange={(e) => {
                                  setLocationSearch(e.target.value);
                                }}
                                value={LocationSearch}
                              />
                              <Scroller autoHeightMax="18vh">
                                <div className="suggestion">
                                  {filteredItems.map((item, idx) => (
                                    <button
                                      key={item}
                                      className="options"
                                      onClick={() => {
                                        setSelectedFilter({
                                          ...SelectedFilter,
                                          location: [
                                            ...SelectedFilter.location,
                                            item,
                                          ],
                                        });
                                      }}
                                    >
                                      {item}
                                      <Radio
                                        style={{ pointerEvents: "none" }}
                                        id={"checkbox_" + idx}
                                        // checked={renderActive(option.name)}
                                      />
                                    </button>
                                  ))}
                                </div>
                              </Scroller>
                            </div>
                          )}
                        </div> */}
                      </div>
                    </div>
                  </div>
                  <div
                    className="result_table_container"
                    style={{ height: TotalPage <= 1 ? "51vh" : null }}
                  >
                    {(!IsLoading && Data.length) > 0 ? (
                      <Scroller
                        // autoHeightMax="45vh"
                        autoHeightMax={TotalPage == 1 ? "49vh" : "45vh"}
                      >
                        <motion.table
                          cellPadding="0"
                          cellSpacing="0"
                          border="0"
                          className="data_table"
                        >
                          <tbody>
                            {Data.map((item, idx) => (
                              <tr
                                onClick={() =>
                                  history.push(
                                    "/users/" +
                                      item.id +
                                      window.location.search
                                  )
                                }
                                key={item.username}
                              >
                                <td className="sr"># {idx + 1}</td>
                                <td>
                                  <div className="userInfo">
                                    {item.profile_pic ? (
                                      <img
                                        alt={"username" + idx}
                                        className="user_avatar_img"
                                        src={item.profile_pic}
                                      />
                                    ) : (
                                      userIcon()
                                    )}

                                    <div className="meta">
                                      <p className="user_meta">{item.full_name}</p>
                                      <p className="user_meta">{item.username}</p>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div className="adjust_info">
                                    {emailIcon()}
                                    <p style={{ display: "inline-block" }}>
                                      {" "}
                                      {item.email}
                                    </p>
                                  </div>
                                </td>
                                <td>
                                  <div className="adjust_info">
                                    {createdIcon()}
                                    <p style={{ display: "inline-block" }}>
                                      {getDateTime(item.created)}
                                    </p>
                                  </div>
                                </td>
                                <td>
                                  <div className="adjust_info">
                                    {callIcon()}
                                    <p style={{ display: "inline-block" }}>
                                      {item.phone}
                                    </p>
                                  </div>
                                </td>
                                <td>
                                  <div className="adjust_info">
                                    {item?.roles?.user === "active"
                                      ? activeIcon()
                                      : suspendedIcon()}
                                    <p
                                      style={{
                                        display: "inline-block",
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      {item.user_status}
                                    </p>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </motion.table>
                      </Scroller>
                    ) : (
                      <div className="noData">No user found</div>
                    )}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "0.7vw",
                    }}
                  >
                    <div />
                    {TotalPage > 1 && (
                      <Pagination
                        totPages={TotalPage}
                        currentPage={CurrentPage}
                        rowCount={10}
                        pageClicked={(ele, count_) => {
                          var url = new URL(window.location.href);
                          url.searchParams.set("page", ele);
                          window.history.pushState(null, null, url);
                          getUser();
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Navbar>
      {IsLoading && <Loading type={"transparent"} text={"Loading"} />}
    </div>
  );
}
export const suspendedIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={45.702}
    height={45.702}
    viewBox="16 5 14 32"
    role="img"
    className="suspendedIcon"
  >
    <defs>
      <filter
        id="circle"
        x={0}
        y={0}
        width={45.702}
        height={45.702}
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy={3} input="SourceAlpha" />
        <feGaussianBlur stdDeviation={4} result="blur" />
        <feFlood floodColor="#222" floodOpacity={0.2} />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g id="Group_15463" data-name="Group 15463" transform="translate(14 11)">
      <g id="Group_14605" data-name="Group 14605">
        <g id="Group_14594" data-name="Group 14594" transform="translate(0 0)">
          <g transform="matrix(1, 0, 0, 1, -14, -11)" filter="url(#circle)">
            <g
              id="circle-2"
              data-name="circle"
              transform="translate(14 11)"
              fill="#d10f0f"
              stroke="#fafdff"
              strokeLinejoin="round"
              strokeWidth={2}
            >
              <circle cx={8.851} cy={8.851} r={8.851} stroke="none" />
              <circle cx={8.851} cy={8.851} r={9.851} fill="none" />
            </g>
          </g>
        </g>
      </g>
    </g>
    <g
      id="Group_15464"
      data-name="Group 15464"
      transform="translate(-1465.587 -343.587)"
    >
      <line
        id="Line_302"
        data-name="Line 302"
        x2={5.875}
        y2={5.875}
        transform="translate(1485.5 360.5)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeWidth={1.5}
      />
      <line
        id="Line_303"
        data-name="Line 303"
        x1={5.875}
        y2={5.875}
        transform="translate(1485.5 360.5)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeWidth={1.5}
      />
    </g>
  </svg>
);

export const callIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={36}
    height={36}
    viewBox="0 0 36 36"
    role="img"
    className="callIcon"
  >
    <rect
      id="placeholder"
      width={36}
      height={36}
      rx={12}
      fill="var(--sidenav)"
    />
    <path
      id="call_FILL1_wght400_GRAD0_opsz48"
      d="M22.252,23.335a12.987,12.987,0,0,1-5.839-1.445A19.823,19.823,0,0,1,11.2,18.134a19.823,19.823,0,0,1-3.756-5.213A12.987,12.987,0,0,1,6,7.083,1.069,1.069,0,0,1,7.083,6h3.371a.852.852,0,0,1,.59.229,1.064,1.064,0,0,1,.325.614l.65,3.034a1.732,1.732,0,0,1-.012.614.928.928,0,0,1-.253.469L9.347,13.391a19.989,19.989,0,0,0,3.022,3.9,16.7,16.7,0,0,0,3.792,2.817l2.287-2.359A1.331,1.331,0,0,1,19,17.376a1.113,1.113,0,0,1,.626-.036l2.865.626A1.052,1.052,0,0,1,23.335,19v3.25a1.069,1.069,0,0,1-1.083,1.083Z"
      transform="translate(3 3)"
      fill="var(--primaryInvert)"
    />
  </svg>
);

export const emailIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={36}
    height={36}
    viewBox="0 0 36 36"
    role="img"
    className="emailIcon"
  >
    <rect
      id="placeholder"
      width={36}
      height={36}
      rx={12}
      fill="var(--sidenav)"
    />
    <g
      id="Group_15694"
      data-name="Group 15694"
      transform="translate(15775 13791)"
    >
      <path
        id="Vector"
        d="M19.514,3.539A4.99,4.99,0,0,0,14.757,0h-10A4.99,4.99,0,0,0,0,3.539L9.757,8.861Z"
        transform="translate(-15766.757 -13782)"
        fill="var(--primaryInvert)"
      />
      <path
        id="Vector-2"
        data-name="Vector"
        d="M10,5.315a1,1,0,0,1-.479-.122L0,0V7.315a5.006,5.006,0,0,0,5,5H15a5.006,5.006,0,0,0,5-5V0L10.479,5.193A1,1,0,0,1,10,5.315Z"
        transform="translate(-15767 -13776.316)"
        fill="var(--primaryInvert)"
      />
    </g>
  </svg>
);

export const userIcon = () => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    fill="none"
    className="user_avatar_img"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 11.796C14.7189 11.796 16.9231 9.60308 16.9231 6.89801C16.9231 4.19294 14.7189 2.00005 12 2.00005C9.28106 2.00005 7.07692 4.19294 7.07692 6.89801C7.07692 9.60308 9.28106 11.796 12 11.796Z"
      fill="#030D45"
    />
    <path
      d="M14.5641 13.8369H9.4359C6.46154 13.8369 4 16.2859 4 19.245C4 19.9593 4.30769 20.5716 4.92308 20.8777C5.84615 21.3879 7.89744 22.0001 12 22.0001C16.1026 22.0001 18.1538 21.3879 19.0769 20.8777C19.5897 20.5716 20 19.9593 20 19.245C20 16.1838 17.5385 13.8369 14.5641 13.8369Z"
      fill="#030D45"
    />
  </svg>
);

export const createdIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={36}
    height={36}
    viewBox="0 0 36 36"
    className="user_avatar_img"
  >
    <g
      id="Group_15706"
      data-name="Group 15706"
      transform="translate(-851 -531)"
    >
      <rect
        id="placeholder"
        width={36}
        height={36}
        rx={12}
        transform="translate(851 531)"
        fill="var(--sidenav)"
      />
      <path
        id="Path_58868"
        data-name="Path 58868"
        d="M16.757,3,9.291,10.466,9.3,14.713l4.238-.007L21,7.243V20a1,1,0,0,1-1,1H4a1,1,0,0,1-1-1V4A1,1,0,0,1,4,3Zm3.728-.9L21.9,3.516l-9.192,9.192-1.412,0,0-1.417Z"
        transform="translate(857 537.9)"
        fill="var(--primaryInvert)"
      />
    </g>
  </svg>
);

// TODO
// request to get country in filter api of device
// generate manufacturer req to check new flow in navbar component
