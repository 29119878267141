import React, { useEffect, useState } from "react";
import "./FileDrop.scss";
import Dropzone from "react-dropzone";
import { useDropzone } from "react-dropzone";
import reset from "../../assets/images/reset.svg";
import Settings from "../../pages/user/Settings/Settings";
import Button from "../Button/Button";
import Scroller from "../Scroller/ScrollBar";
import AnimatedModal from "../Modal/AnimatedModal";
import default_img from "../../assets/images/default_img.png";

export default function FileDrop({
  onclick,
  error,
  Name,
  img,
  FileName,
  multiple = false,
  disabled,
  activestatus,
  uploadedImage,
  setimg,
  Label,
}) {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    disabled: true,
  });

  // useEffect(() => {
  //   if (error) {
  //     var ele = document.querySelector("#" + label?.replace(/ /g, "_"));
  //     ele.classList.add("error_shake");
  //     setTimeout(function () {
  //       ele.classList.remove("error_shake");
  //     }, 300);
  //   }
  // }, [error]);

  return (
    <div className="filedrop">
      <div className="filedrop_name filedrop_label">
        {Name}
        <label>{Name && Label}</label>
      </div>

      <div
        className={
          error
            ? "filedrop_main_wrapper filedrop__error"
            : "filedrop_main_wrapper"
        }
        onClick={() => onclick()}
      >
        <div className="filedrop_subwrapper">
          <div className="profile_holder">
            {uploadedImage.length === 1 && Name === "App thumbnail" ? (
              <img
                className="no_profile  fadeIn"
                src={URL.createObjectURL(uploadedImage[0])}
              />
            ) : (
              <img className="no_profile" src={default_img}></img>
            )}
            {/* if(e.target.uploadedImage.length === 0){
      this.setuploadedImage({image: URL.createObjectURL(e.target.files[0])})
    } */}
          </div>

          <Dropzone
            resizeWidth={640}
            resizeHeight={480}
            resizeMethod={"contain"}
            disabled={disabled}
            multiple={multiple}
            accept={
              Name === ("App thumbnail")
                ? { "image/jpeg": [], "image/png": [] }
                : Name === "App Image"
                ? { "image/jpeg": [], "image/png": [] }
                : Name === "Additional Media"
                ? { "image/jpeg": [], "image/png": [], "video/mp4": [], "image/gif": []  }
                : ""
            }
            // accept={{ "image/jpeg": [], "image/png": [], "video/mp4": [] }}
            onDrop={(acceptedFiles) => {
              let _data = [...acceptedFiles];
              _data.map((x) => x);
              // setappimg(_data);
              setimg(_data);
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps({ className: "dropzone disabled" })}>
                <input {...getInputProps()} />

                {/* <p title={SingleImg && ProfilePic.name} className="file_name"> */}
                {/* {MultipleImg ?  ProfilePic : "Drag & Drop or choose file"}
                {/* {ProfilePic.size > 500 ? "" : "invalid file size"} */}
                {/* </p> */}
                <div className="filee_name">
                  <p>
                    {uploadedImage.length > 1 ? (
                      <Scroller
                        autoHeightMax="6vh"
                        verticalStyle={{
                          width: "0.12vw",
                          marginLeft: "0.2vw",
                          backgroundColor: "#283e92",
                        }}
                      >
                        {uploadedImage.map((x, i) => {
                          //heree
                          return (
                            <div className="dropzone_name">
                              <div title= {x.name}
                                className={
                                  uploadedImage?.length === 1
                                    ? "file_name"
                                    : "multiple_filename"
                                }
                              >
                                {x.name}
                                {uploadedImage?.length === 1 ? (
                                  ""
                                ) : (
                                  <span
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      // setMultipleImg(MultipleImg.splice(i, 1));
                                      let _arr = [...uploadedImage];
                                      _arr.splice(i, 1);
                                      setimg(_arr);
                                    }}
                                    className="multipleimg_cross"
                                  >
                                    x
                                  </span>
                                )}
                              </div>
                            </div>
                          );
                        })}

                        {/* {serviceimg?.length === 0 && "Drag & Drop or choose file"} */}

                        {/* { serviceimg? appimg.map((x) => x.size) : "Drag & Drop or choose file"} */}
                      </Scroller>
                    ) : (
                      uploadedImage.map((x) => (
                        <div
                          className={
                            uploadedImage?.length === 1 ? "file_name" : ""
                          }
                        >
                          {x.name}
                        </div>
                      ))
                    )}
                    <p className="draganddrop_tag">
                      {uploadedImage?.length === 0 &&
                        "Drag & drop or choose file"}
                    </p>
                  </p>
                </div>
              </div>
            )}
          </Dropzone>
          {uploadedImage.length > 0 && multiple ? (
            <Button
              onClick={() => {
                activestatus(true);
              }}
              name={"preview"}
            ></Button>
          ) : (
            ""
          )}
          {uploadedImage.length > 0 && Name === "App Image" ? (
            <Button
              onClick={() => {
                activestatus(true);
              }}
              name={"preview"}
            >
              {" "}
            </Button>
          ) : (
            ""
          )}
          {/* {uploadedImage.length > 0 && Name === "Service Thumbnail" ? (
            <button
              onClick={() => {
                activestatus(true);
              }}
            >
              {uploadedImage.map((x) =>
              x.name)}
              
            </button>
          ) : (
            ""
          )} */}

          <img
            className="filedrop_reset_image"
            src={reset}
            onClick={() => {
              // setserviceimg([]);
              setimg([]);
            }}
          ></img>
          <div className="files_uploaded_name">
            {uploadedImage.length === 0
              ? "No file choosen"
              : Name === "App thumbnail" && "Image uploaded"}
            {/* {uploadedImage.length > 0 && Name === "Service Thumbnail"?  uploadedImage.map((x) => x.name) : 
            ""} */}
          </div>
        </div>
        {uploadedImage.includes("mp4") ? (
          <p className="resolution_sent">
            * resolution shouldn't be more than 220kp
          </p>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
