import "./../../src/helper/Icons.scss";

export const closeIcon = (onClick) => (
  <svg
    width="0.93vw"
    height="0.93vw"
    viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg"
    className="closeIcon"
    onClick={onClick}
  >
    <path
      fill="#fff"
      d="M17.558 15.44a1.49 1.49 0 0 1 0 2.12 1.5 1.5 0 0 1-2.12 0L9 11.12l-6.438 6.44a1.5 1.5 0 0 1-2.12 0 1.49 1.49 0 0 1 0-2.12L6.88 9 .442 2.563a1.5 1.5 0 0 1 2.12-2.12L9 6.883l6.438-6.44a1.5 1.5 0 0 1 2.12 2.12L11.12 9z"
    />
  </svg>
);

export const tickIcon = (className) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="52px"
    height="52px"
    viewBox="0 0 52.4 52.4"
    className={className}

  >
    <defs>
      <linearGradient
        id="tick-gradient"
        x1="-0.08"
        y1="0.22"
        x2="1"
        y2="0.802"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#e8505b" />
        <stop offset="0" stopColor="#0942b0" />
        <stop offset="1" stopColor="#2b60ea" />
      </linearGradient>
    </defs>
    <g
      id="Group_1016"
      data-name="Group 1016"
      transform="translate(-233.8 -190.4)"
    >
      <g id="Group_1013" data-name="Group 1013">
        <circle
          id="Ellipse_42"
          data-name="Ellipse 42"
          cx="26.2"
          cy="26.2"
          r="26.2"
          transform="translate(233.8 190.4)"
          fill="url(#tick-gradient)"
        />
      </g>
      <g id="Group_1015" data-name="Group 1015">
        <g id="Group_1014" data-name="Group 1014">
          <path
            id="Path_13415"
            data-name="Path 13415"
            d="M272.9,205.5c.3.3-4.6,6.8-10.9,14.7-1.4,1.6-2.7,3.2-3.9,4.7l-.5.6-.6-.6c-5-5.1-8.4-8.9-8.1-9.2.2-.2,4,3.2,9.2,8.1l-1.1.1c1.2-1.5,2.5-3.1,3.8-4.7C267.1,211.4,272.6,205.2,272.9,205.5Z"
            fill="#fff"
          />
        </g>
      </g>
    </g>
  </svg>
);
export const dollarIcon = () => (
  <svg
    width="512px"
    height="512px"
    viewBox="-112 0 512 512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M209.2 233.4l-108-31.6C88.7 198.2 80 186.5 80 173.5c0-16.3 13.2-29.5 29.5-29.5h66.3c12.2 0 24.2 3.7 34.2 10.5 6.1 4.1 14.3 3.1 19.5-2l34.8-34c7.1-6.9 6.1-18.4-1.8-24.5C238 74.8 207.4 64.1 176 64V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48h-2.5C45.8 64-5.4 118.7.5 183.6c4.2 46.1 39.4 83.6 83.8 96.6l102.5 30c12.5 3.7 21.2 15.3 21.2 28.3 0 16.3-13.2 29.5-29.5 29.5h-66.3C100 368 88 364.3 78 357.5c-6.1-4.1-14.3-3.1-19.5 2l-34.8 34c-7.1 6.9-6.1 18.4 1.8 24.5 24.5 19.2 55.1 29.9 86.5 30v48c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16v-48.2c46.6-.9 90.3-28.6 105.7-72.7 21.5-61.6-14.6-124.8-72.5-141.7z" />
  </svg>
);

export const errorIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="35"
    height="35"
    viewBox="0 0 27 27"
  >
    <path
      id="error_black_24dp"
      d="M15.5,2A13.5,13.5,0,1,0,29,15.5,13.5,13.5,0,0,0,15.5,2Zm0,14.85a1.354,1.354,0,0,1-1.35-1.35V10.1a1.35,1.35,0,1,1,2.7,0v5.4A1.354,1.354,0,0,1,15.5,16.85Zm1.35,5.4h-2.7v-2.7h2.7Z"
      transform="translate(-2 -2)"
      fill="#ff3465"
    />
  </svg>
);

export const close_icon = (onClick, className) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={35}
    height={35}
    viewBox="0 0 35 35"
    onClick={onClick}
    className={className}
  >
    <g id="Group_3667" data-name="Group 3667" transform="translate(0.457)">
      <circle
        id="Ellipse_223"
        data-name="Ellipse 223"
        cx={17.5}
        cy={17.5}
        r={17.5}
        transform="translate(-0.457)"
        fill="#ff3465"
      />
      <path
        id="Path_531"
        data-name="Path 531"
        d="M-13244.145,1426.032l13-13"
        transform="translate(13254.688 -1402.032)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeWidth={2}
      />
      <path
        id="Path_532"
        data-name="Path 532"
        d="M-13231.145,1426.032l-13-13"
        transform="translate(13254.688 -1402.032)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeWidth={2}
      />
    </g>
  </svg>
);

export const successIcon = (onClick, className) => (
  <svg
    id="Group_3666"
    data-name="Group 3666"
    xmlns="http://www.w3.org/2000/svg"
    width={35}
    height={35}
    viewBox="0 0 35 35"
    onClick={onClick}
    className={className}
  >
    <circle
      id="Ellipse_222"
      data-name="Ellipse 222"
      cx={17.5}
      cy={17.5}
      r={17.5}
      fill="#64e45b"
    />
    <path
      id="Path_530"
      data-name="Path 530"
      d="M-13246.272,1390l4.415,4.415,10.462-10.46"
      transform="translate(13256.771 -1371.456)"
      fill="none"
      stroke="#fff"
      strokeLinecap="round"
      strokeWidth={2}
    />
  </svg>
);

export const modalSuccessIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="107"
    height="107"
    viewBox="0 0 107 107"
    className="modalSuccessIcon"
  >
    <defs>
      <linearGradient
        id="modal-success-gradient"
        x1="0.171"
        y1="-0.104"
        x2="0.915"
        y2="1"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#078d7f" />
        <stop offset="1" stopColor="#037065" />
      </linearGradient>
      <filter
        id="Ellipse_18437"
        x="0"
        y="0"
        width="107"
        height="107"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy="2" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="5.5" result="blur" />
        <feFlood floodColor="#605f5f" floodOpacity="0.349" />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g
      id="Group_14554"
      data-name="Group 14554"
      transform="translate(-24081.5 -131.5)"
    >
      <g id="Group_14552" data-name="Group 14552">
        <g
          transform="matrix(1, 0, 0, 1, 24081.5, 131.5)"
          filter="url(#Ellipse_18437)"
        >
          <circle
            id="Ellipse_18437-2"
            data-name="Ellipse 18437"
            cx="37"
            cy="37"
            r="37"
            transform="translate(16.5 14.5)"
            fill="url(#modal-success-gradient)"
          />
        </g>
        <path
          id="svgviewer-output_1_"
          data-name="svgviewer-output (1)"
          d="M40.412,6.449a1.626,1.626,0,0,1,0,2.17L17.749,33.171a1.341,1.341,0,0,1-2,0L4.415,20.9a1.626,1.626,0,0,1,0-2.17,1.341,1.341,0,0,1,2,0l10.33,11.191L38.409,6.449A1.341,1.341,0,0,1,40.412,6.449Z"
          transform="translate(24112.932 163.237)"
          fill="#fff"
          fillRule="evenodd"
        />
      </g>
    </g>
  </svg>
);

export const modalErrorIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="74px"
    height="74px"
    viewBox="0 0 107 107"
    className="modalSuccessIcon"
  >
    <defs>
      <linearGradient
        id="error-icon-gradient"
        x1="0.171"
        y1="-0.104"
        x2="0.915"
        y2="1"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#cc2b2f" />
        <stop offset="1" stopColor="#b61115" />
      </linearGradient>
      <filter
        id="Ellipse_18437"
        x="0"
        y="0"
        width="107"
        height="107"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy="2" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="5.5" result="blur" />
        <feFlood floodColor="#605f5f" floodOpacity="0.349" />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g
      id="Group_14561"
      data-name="Group 14561"
      transform="translate(-727.5 -110.5)"
    >
      <g
        id="Group_14554"
        data-name="Group 14554"
        transform="translate(-23354 -21)"
      >
        <g id="Group_14552" data-name="Group 14552">
          <g
            transform="matrix(1, 0, 0, 1, 24081.5, 131.5)"
            filter="url(#Ellipse_18437)"
          >
            <circle
              id="Ellipse_18437-2"
              data-name="Ellipse 18437"
              cx="37"
              cy="37"
              r="37"
              transform="translate(16.5 14.5)"
              fill="url(#error-icon-gradient)"
            />
          </g>
        </g>
      </g>
      <path
        id="dangerous_FILL0_wght400_GRAD0_opsz48"
        d="M30.913,34.91l9.8,9.8a2.583,2.583,0,0,0,1.9.761,2.827,2.827,0,0,0,2-4.854L34.91,30.913l9.8-9.8a2.583,2.583,0,0,0,.761-1.9,2.827,2.827,0,0,0-4.854-2l-9.709,9.709-9.8-9.8a2.583,2.583,0,0,0-1.9-.761,2.827,2.827,0,0,0-2,4.854l9.709,9.709-9.8,9.8a2.583,2.583,0,0,0-.761,1.9,2.827,2.827,0,0,0,4.854,2ZM30.913,30.913Z"
        transform="translate(749.65 130.65)"
        fill="#fff"
      />
    </g>
  </svg>
);

export const closeIcon2 = (onClick) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      onClick={onClick}
    >
      <rect
        id="Rectangle_640"
        data-name="Rectangle 640"
        width="21"
        height="20"
        rx="10"
        fill="#007dc6"
      />
      <path
        id="close_FILL0_wght400_GRAD0_opsz48"
        d="M15.827,16.612,11.9,20.536a.555.555,0,1,1-.785-.785l3.924-3.924L11.118,11.9a.555.555,0,1,1,.785-.785l3.924,3.924,3.924-3.924a.555.555,0,1,1,.785.785l-3.924,3.924,3.924,3.924a.555.555,0,1,1-.785.785Z"
        transform="translate(-5.553 -5.469)"
        fill="#fff"
      />
    </svg>
  );
};

export const deviceIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="64.28"
    height="59.939"
    viewBox="0 0 64.28 59.939"
    className="deviceIcon"
  >
    <g id="Layer_2" data-name="Layer 2" transform="translate(-1.77)">
      <g id="Layer_1" data-name="Layer 1">
        <path
          id="Path_13443"
          data-name="Path 13443"
          d="M39.83,13.39V4.93C39.81,1.66,41.37,0,44.69,0H61.17c3.39,0,4.88,1.71,4.88,4.91v30c0,3.52-2,5.11-5.36,5.16H55.02c-.41.72-.71,1-.71,1.29,0,11-.91,11-12,10.94s-22,0-33,0c-5.39,0-7.21-1.78-7.28-7.16q-.15-12,0-24c.06-5.68,2.15-7.66,8-7.69C19.72,13.35,29.38,13.39,39.83,13.39Zm0,2.86h-31c-3.12,0-4,1.7-3.95,4.48.06,7.84.22,15.68-.05,23.51C4.65,48.73,6.57,50,10.74,50c11-.16,22-.05,33-.06,8.13,0,9-1.11,7.43-9.65-1.26-.07-2.56-.17-3.87-.19-6.59-.13-7.48-1-7.5-7.57.01-5.2.02-10.36.02-16.28Zm2.48,3.38c0,4.65-.25,9.33.16,14A5,5,0,0,0,45.83,37c4.62.41,9.3,0,14,.21,3.15.11,4-1.31,4-4.2q-.16-13.22,0-26.44c0-2.95-1.07-4.29-4.08-4.22-4.32.09-8.65.14-13,0-3.19-.11-4.57,1.1-4.46,4.35.11,4.3,0,8.61.01,12.93Z"
        />
        <path
          id="Path_13444"
          data-name="Path 13444"
          d="M1.955,59.939"
          fill="none"
          stroke="#707070"
          strokeWidth="1"
        />
        <line
          id="Line_249"
          data-name="Line 249"
          x2="64"
          transform="translate(1.77 58.5)"
          fill="none"
          stroke="#000"
          strokeWidth="2"
        />
      </g>
    </g>
  </svg>
);

export const invIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 64.28 59.939"
    className="deviceIcon"
  >
    <g id="Layer_2" data-name="Layer 2" transform="translate(-1.77)">
      <g id="Layer_1" data-name="Layer 1">
        <path
          id="Path_13443"
          data-name="Path 13443"
          d="M39.83,13.39V4.93C39.81,1.66,41.37,0,44.69,0H61.17c3.39,0,4.88,1.71,4.88,4.91v30c0,3.52-2,5.11-5.36,5.16H55.02c-.41.72-.71,1-.71,1.29,0,11-.91,11-12,10.94s-22,0-33,0c-5.39,0-7.21-1.78-7.28-7.16q-.15-12,0-24c.06-5.68,2.15-7.66,8-7.69C19.72,13.35,29.38,13.39,39.83,13.39Zm0,2.86h-31c-3.12,0-4,1.7-3.95,4.48.06,7.84.22,15.68-.05,23.51C4.65,48.73,6.57,50,10.74,50c11-.16,22-.05,33-.06,8.13,0,9-1.11,7.43-9.65-1.26-.07-2.56-.17-3.87-.19-6.59-.13-7.48-1-7.5-7.57.01-5.2.02-10.36.02-16.28Zm2.48,3.38c0,4.65-.25,9.33.16,14A5,5,0,0,0,45.83,37c4.62.41,9.3,0,14,.21,3.15.11,4-1.31,4-4.2q-.16-13.22,0-26.44c0-2.95-1.07-4.29-4.08-4.22-4.32.09-8.65.14-13,0-3.19-.11-4.57,1.1-4.46,4.35.11,4.3,0,8.61.01,12.93Z"
        />
        <path
          id="Path_13444"
          data-name="Path 13444"
          d="M1.955,59.939"
          fill="none"
          stroke="#707070"
          strokeWidth="1"
        />
        <line
          id="Line_249"
          data-name="Line 249"
          x2="64"
          transform="translate(1.77 58.5)"
          fill="none"
          stroke="#000"
          strokeWidth="2"
        />
      </g>
    </g>
  </svg>
);

export const activeIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={47.314}
    height={47.314}
    // viewBox="0 0 47.314 47.314"
    viewBox="18 4 10 35"
    className="active_icon"
  >
    <defs>
      <linearGradient
        id="linear-gradientt"
        x1={0.227}
        y1={-0.25}
        x2={0.773}
        y2={1.25}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#dd1e42" />
        <stop offset={0.005} stopColor="#0f2" />
        <stop offset={1} stopColor="#025410" />
      </linearGradient>
      <filter
        id="circle"
        x={0}
        y={0}
        width={47.314}
        height={47.314}
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy={3} input="SourceAlpha" />
        <feGaussianBlur stdDeviation={4} result="blur" />
        <feFlood floodColor="#222" floodOpacity={0.2} />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g
      id="Group_14620"
      data-name="Group 14620"
      transform="translate(-20630 16254)"
    >
      <g
        id="Group_14605"
        data-name="Group 14605"
        transform="translate(19383 -16743)"
      >
        <g
          id="Group_14594"
          data-name="Group 14594"
          transform="translate(1261 500)"
        >
          <g transform="matrix(1, 0, 0, 1, -14, -11)" filter="url(#circle)">
            <g
              id="circle-2"
              data-name="circle"
              transform="translate(14 11)"
              stroke="#fafdff"
              strokeLinejoin="round"
              strokeWidth={2}
              fill="url(#linear-gradientt)"
            >
              <circle cx={9.657} cy={9.657} r={9.657} stroke="none" />
              <circle cx={9.657} cy={9.657} r={10.657} fill="none" />
            </g>
          </g>
        </g>
      </g>
      <path
        id="Vector"
        d="M0,4.76,3.06,7.82,10.88,0"
        transform="translate(20648.5 -16237.5)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      />
    </g>
  </svg>
);

export const inactiveIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={30}
    height={30}
    viewBox="8 8 30 26"
    className="inactive_icon"
  >
    <defs>
      <linearGradient
        id="linear-gradient2"
        x1="0.773"
        y1="1.25"
        x2="0.227"
        y2="-0.25"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#dd1e42" />
        <stop offset="0.005" stopColor="red" />
        <stop offset="1" stopColor="#a20000" />
      </linearGradient>
      <filter
        id="circle"
        x="0"
        y="0"
        width="47.314"
        height="47.314"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy="3" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="4" result="blur" />
        <feFlood floodColor="#222" floodOpacity="0.2" />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g
      id="Group_14632"
      data-name="Group 14632"
      transform="translate(-1247 -489)"
    >
      <g
        id="Group_14594"
        data-name="Group 14594"
        transform="translate(1261.371 500)"
      >
        <g transform="matrix(1, 0, 0, 1, -14.37, -11)" filter="url(#circle)">
          <g
            id="circle-2"
            data-name="circle"
            transform="translate(14 11)"
            stroke="#fafdff"
            strokeLinejoin="round"
            strokeWidth="2"
            fill="url(#linear-gradient2)"
          >
            <ellipse
              cx="9.657"
              cy="9.657"
              rx="9.657"
              ry="9.657"
              stroke="none"
            />
            <ellipse
              cx="9.657"
              cy="9.657"
              rx="10.657"
              ry="10.657"
              fill="none"
            />
          </g>
        </g>
      </g>
      <g
        id="Group_14595"
        data-name="Group 14595"
        transform="translate(-20361 18482)"
      >
        <path
          id="Path"
          d="M0,0H2V2H0Z"
          transform="translate(21631 -17969)"
          fill="#fff"
        />
        <path
          id="Path-2"
          data-name="Path"
          d="M0,0H2V6H0Z"
          transform="translate(21631 -17977)"
          fill="#fff"
        />
      </g>
    </g>
  </svg>
);

export const infoIcon = (tpFor) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={42.97}
      height={42.97}
      viewBox="10 8 24 26"
      data-for={tpFor}
      data-tip
      className="infoIcon"
    >
      <defs>
        <linearGradient
          id="infoIcon-gradient"
          x1={0.773}
          y1={1.25}
          x2={0.227}
          y2={-0.25}
          gradientUnits="objectBoundingBox"
        >
          <stop offset={0} stopColor="#dd1e42" />
          <stop offset={0.005} stopColor="#013aa2" />
          <stop offset={1} stopColor="#2d62ed" />
        </linearGradient>
        <filter
          id="circle"
          x={0}
          y={0}
          width={42.97}
          height={42.97}
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy={3} input="SourceAlpha" />
          <feGaussianBlur stdDeviation={4} result="blur" />
          <feFlood floodColor="#fff" floodOpacity={0.2} />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g
        id="Group_14876"
        data-name="Group 14876"
        transform="translate(-752.058 -601.896)"
      >
        <g
          id="Group_14846"
          data-name="Group 14846"
          transform="translate(765.914 612.896)"
        >
          <g transform="matrix(1, 0, 0, 1, -13.86, -11)" filter="url(#circle)">
            <g
              id="circle-2"
              data-name="circle"
              transform="translate(14 11)"
              stroke="#fafdff"
              strokeLinejoin="round"
              strokeWidth={2}
              fill="url(#infoIcon-gradient)"
            >
              <ellipse
                cx={7.485}
                cy={7.485}
                rx={7.485}
                ry={7.485}
                stroke="none"
              />
              <ellipse
                cx={7.485}
                cy={7.485}
                rx={8.485}
                ry={8.485}
                fill="none"
              />
            </g>
          </g>
        </g>
        <g
          id="Group_14847"
          data-name="Group 14847"
          transform="translate(185.058 43.896)"
        >
          <line
            id="Line_263"
            data-name="Line 263"
            y2={5}
            transform="translate(588.043 575.5)"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeWidth={1.5}
          />
          <g
            id="Ellipse_18438"
            data-name="Ellipse 18438"
            transform="translate(587 572)"
            fill="#fff"
            stroke="#fff"
            strokeWidth={1}
          >
            <circle cx={1} cy={1} r={1} stroke="none" />
            <circle cx={1} cy={1} r={0.5} fill="none" />
          </g>
        </g>
      </g>
    </svg>
  );
};

export const transparentInfoIcon = (style) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={12}
    height={12}
    viewBox="0 0 11.24 11.24"
    style={style}
  >
    <path
      id="info_FILL0_wght500_GRAD0_opsz48"
      d="M9.362,12.13a.449.449,0,0,0,.457-.457V9.209A.416.416,0,0,0,9.68,8.9a.449.449,0,0,0-.318-.132.449.449,0,0,0-.457.457v2.464A.416.416,0,0,0,9.043,12a.449.449,0,0,0,.318.132ZM9.32,7.8a.511.511,0,0,0,.367-.138.475.475,0,0,0,.145-.36.521.521,0,0,0-.145-.374.518.518,0,0,0-.734,0,.521.521,0,0,0-.145.374.475.475,0,0,0,.145.36A.511.511,0,0,0,9.32,7.8Zm0,7.143A5.725,5.725,0,0,1,7.1,14.511a5.39,5.39,0,0,1-2.969-2.969,5.93,5.93,0,0,1,0-4.43A5.463,5.463,0,0,1,5.313,5.327,5.555,5.555,0,0,1,7.1,4.136a5.843,5.843,0,0,1,4.43,0A5.56,5.56,0,0,1,14.94,9.32a5.646,5.646,0,0,1-.436,2.222,5.555,5.555,0,0,1-1.19,1.786,5.463,5.463,0,0,1-1.786,1.184A5.689,5.689,0,0,1,9.32,14.94ZM9.32,9.32ZM9.32,14a4.515,4.515,0,0,0,3.3-1.37A4.5,4.5,0,0,0,14,9.32a4.5,4.5,0,0,0-1.377-3.3,4.5,4.5,0,0,0-3.3-1.377,4.5,4.5,0,0,0-3.3,1.377,4.5,4.5,0,0,0-1.377,3.3,4.5,4.5,0,0,0,1.377,3.308A4.515,4.515,0,0,0,9.32,14Z"
      transform="translate(-3.7 -3.7)"
      fill="#013aa2"
    />
  </svg>
);

export const searchIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="27.838"
    height="27.84"
    viewBox="0 0 27.838 27.84"
    className="search_svg"
  >
    <g
      id="Group_2415"
      data-name="Group 2415"
      transform="translate(1.061 1.061)"
    >
      <g id="Group_2414" data-name="Group 2414">
        <circle
          id="Ellipse_18"
          data-name="Ellipse 18"
          cx="8.184"
          cy="8.184"
          r="8.184"
          transform="translate(0 11.574) rotate(-45)"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Vector_7"
          data-name="Vector 7"
          d="M0,2.217V0"
          transform="translate(17.425 17.428) rotate(-45)"
          fill="none"
          stroke="#000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <rect
          id="Rectangle_668"
          data-name="Rectangle 668"
          width="5.456"
          height="8.591"
          rx="2.728"
          transform="translate(16.844 20.704) rotate(-45)"
          fill="#1249bf"
        />
      </g>
    </g>
  </svg>
);

export const tickIcon2 = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="10 7 19 19"
    className="tickIcon2"
  >
    <defs>
      <filter
        id="circle"
        x={0}
        y={0}
        width={39.662}
        height={39.662}
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy={3} input="SourceAlpha" />
        <feGaussianBlur stdDeviation={4} result="blur" />
        <feFlood floodColor="#222" floodOpacity={0.2} />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g
      id="Group_14620"
      data-name="Group 14620"
      transform="translate(-20630.5 16253.5)"
    >
      <g
        id="Group_14605"
        data-name="Group 14605"
        transform="translate(20644 -16243)"
      >
        <g id="Group_14594" data-name="Group 14594" transform="translate(0 0)">
          <g transform="matrix(1, 0, 0, 1, -13.5, -10.5)" filter="url(#circle)">
            <g
              id="circle-2"
              data-name="circle"
              transform="translate(13.5 10.5)"
              fill="#2d62ed"
              stroke="#013aa2"
              strokeLinejoin="round"
              strokeWidth={1.5}
            >
              <circle cx={6.331} cy={6.331} r={6.331} stroke="none" />
              <circle cx={6.331} cy={6.331} r={7.081} fill="none" />
            </g>
          </g>
        </g>
      </g>
      <path
        id="Vector"
        d="M0,3.121,2.006,5.127,7.133,0"
        transform="translate(20646.949 -16239.395)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      />
    </g>
  </svg>
);

export const warningIcon = (onClick) => (
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="796 698.08 200 200"
    enableBackground="new 796 698.08 200 200"
    xmlSpace="preserve"
    className="modal_icon"
    onClick={onClick}
  >
    <path
      d="M994.47,869.072L905.896,715.66c-2.041-3.535-5.813-5.713-9.896-5.713c-4.083,0-7.854,2.178-9.896,5.713l-88.573,153.412 c-2.041,3.536-2.041,7.892,0,11.427c2.041,3.536,5.813,5.714,9.896,5.714h177.147c4.083,0,7.854-2.178,9.896-5.714 C996.511,876.963,996.511,872.608,994.47,869.072z M896,870.253c-7.22,0-13.072-5.852-13.072-13.071 c0-7.221,5.852-13.072,13.072-13.072c7.221,0,13.072,5.852,13.072,13.072C909.072,864.402,903.221,870.253,896,870.253z  M910.58,765.235l-7.793,62.267c-0.469,3.748-3.889,6.407-7.637,5.938c-3.168-0.396-5.557-2.908-5.938-5.938l-7.793-62.267 c-1.007-8.053,4.703-15.397,12.755-16.405c8.053-1.007,15.397,4.703,16.405,12.756C910.732,762.8,910.719,764.072,910.58,765.235z"
      fill="red"
      onClick={onClick}
    />
  </svg>
);

export const checkedIcon = (onClick) => (
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512.001 512.001"
    style={{
      enableBackground: "new 0 0 512.001 512.001",
    }}
    xmlSpace="preserve"
    className="success_icon"
    onClick={onClick}
  >
    <path
      style={{
        fill: "#00FF22",
      }}
      d="M256.001,24c-128.13,0-232,103.87-232,232s103.87,232,232,232S488,384.13,488,256.001 S384.13,24,256.001,24z"
      onClick={onClick}
    />
    <polygon
      style={{
        fill: "#FFFFFF",
      }}
      points="345.032,137.848 216.896,295.888 163.04,242.728 127.528,281.848 221.056,374.153  384.472,172.608 "
      onClick={onClick}
    />
  </svg>
);

export const cancelIcon = (onClick) => (
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512.001 512.001"
    style={{
      enableBackground: "new 0 0 512.001 512.001",
    }}
    xmlSpace="preserve"
    className="warning_icon"
    onClick={onClick}
  >
    <path
      onClick={onClick}
      style={{
        fill: "#FF6465",
      }}
      d="M256.001,512c141.384,0,255.999-114.615,255.999-256.001C512.001,114.615,397.386,0,256.001,0 S0.001,114.615,0.001,256.001S114.616,512,256.001,512z"
    />
    <path
      onClick={onClick}
      style={{
        opacity: 0.1,
        enableBackground: "new",
      }}
      d="M68.873,256.001c0-129.706,96.466-236.866,221.564-253.688 C279.172,0.798,267.681,0,256.001,0C114.616,0,0.001,114.615,0.001,256.001S114.616,512.001,256,512.001 c11.68,0,23.171-0.798,34.436-2.313C165.339,492.865,68.873,385.705,68.873,256.001z"
    />
    <path
      onClick={onClick}
      style={{
        fill: "#FFFFFF",
      }}
      d="M313.391,256.001l67.398-67.398c4.899-4.899,4.899-12.842,0-17.74l-39.65-39.65 c-4.899-4.899-12.842-4.899-17.74,0l-67.398,67.398l-67.398-67.398c-4.899-4.899-12.842-4.899-17.74,0l-39.65,39.65 c-4.899,4.899-4.899,12.842,0,17.74l67.398,67.398l-67.398,67.398c-4.899,4.899-4.899,12.842,0,17.741l39.65,39.65 c4.899,4.899,12.842,4.899,17.74,0l67.398-67.398L323.4,380.79c4.899,4.899,12.842,4.899,17.74,0l39.65-39.65 c4.899-4.899,4.899-12.842,0-17.741L313.391,256.001z"
    />
  </svg>
);

export const searchIcon2 = () => (
  <svg
    id="content"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={22.639}
    height={22.724}
    viewBox="0 0 22.639 22.724"
    className="search_icon"
  >
    <defs>
      <linearGradient
        id="search-gradient"
        x1={0.5}
        x2={0.5}
        y2={1}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#111b60" />
        <stop offset={1} stopColor="#111b60" />
      </linearGradient>
    </defs>
    <g id="Research" transform="translate(0 0)" opacity={0.4}>
      <path
        id="Vector"
        d="M21.611,16.839l-2.358-2.415a3.508,3.508,0,0,0-3.872-.649L14.356,12.75a7.973,7.973,0,1,0-1.606,1.606l1.014,1.014a3.417,3.417,0,0,0,.6,3.941l2.415,2.415a3.435,3.435,0,1,0,4.829-4.886ZM11.953,12.01a5.695,5.695,0,1,1,1.238-1.849,5.695,5.695,0,0,1-1.238,1.849Zm8.052,8.052a1.139,1.139,0,0,1-1.617,0l-2.415-2.415A1.144,1.144,0,1,1,17.59,16.03l2.415,2.415a1.139,1.139,0,0,1,0,1.617Z"
        transform="translate(0 0)"
        fill="url(#search-gradient)"
      />
    </g>
  </svg>
);
export const infoIcon2 = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1.096vw"
    // height={21.05}
    viewBox="0 0 21.05 21.05"
    role="img"
    className="infoIcon"
  >
    <path
      id="info_FILL0_wght400_GRAD0_opsz48"
      d="M14.6,19.788A.765.765,0,0,0,15.393,19V14.236a.715.715,0,0,0-.237-.539.779.779,0,0,0-.553-.224.765.765,0,0,0-.789.789v4.763a.715.715,0,0,0,.237.539A.779.779,0,0,0,14.6,19.788Zm-.079-8.262a.865.865,0,0,0,.618-.237.8.8,0,0,0,.25-.605.884.884,0,0,0-.25-.632.858.858,0,0,0-1.237,0,.884.884,0,0,0-.25.632.8.8,0,0,0,.25.605.865.865,0,0,0,.618.237Zm0,13.525a10.675,10.675,0,0,1-4.157-.8A10.187,10.187,0,0,1,4.8,18.682,10.675,10.675,0,0,1,4,14.525a10.607,10.607,0,0,1,.8-4.131A10.272,10.272,0,0,1,7.026,7.052,10.493,10.493,0,0,1,14.525,4,10.441,10.441,0,0,1,25.05,14.525,10.493,10.493,0,0,1,22,22.024a10.272,10.272,0,0,1-3.342,2.223A10.607,10.607,0,0,1,14.525,25.05ZM14.525,14.525Zm0,8.946A8.612,8.612,0,0,0,20.84,20.84a8.612,8.612,0,0,0,2.631-6.315A8.613,8.613,0,0,0,20.84,8.21a8.612,8.612,0,0,0-6.315-2.631A8.612,8.612,0,0,0,8.21,8.21a8.612,8.612,0,0,0-2.631,6.315A8.612,8.612,0,0,0,8.21,20.84,8.613,8.613,0,0,0,14.525,23.471Z"
      transform="translate(-4 -4)"
    />
  </svg>
);

export const barIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={45}
    height={46}
    viewBox="0 0 45 46"
    role="img"
  >
    <path
      id="chart"
      d="M34,46a1,1,0,0,1-1-1V11.387a1,1,0,0,1,1-1H44a1,1,0,0,1,1,1V45a1,1,0,0,1-1,1ZM17.5,46a1,1,0,0,1-1-1V1a1,1,0,0,1,1-1h10a1,1,0,0,1,1,1V45a1,1,0,0,1-1,1ZM1,46a1,1,0,0,1-1-1V18.806a1,1,0,0,1,1-1H11a1,1,0,0,1,1,1V45a1,1,0,0,1-1,1Z"
      fill="#72bfb2"
    />
  </svg>
);

export const groupIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45 40" role="img">
    <path
      id="Shape"
      d="M16.535,40C5.409,40,0,37.759,0,33.149a4.7,4.7,0,0,1,1.33-3.357A8.813,8.813,0,0,1,4.924,27.71c2.709-.915,6.507-1.36,11.612-1.36,11.127,0,16.535,2.241,16.535,6.851a4.7,4.7,0,0,1-1.33,3.356,8.815,8.815,0,0,1-3.594,2.082C25.438,39.555,21.64,40,16.535,40Zm21.347-5.274a.454.454,0,0,1-.34-.149.474.474,0,0,1-.119-.37,9.559,9.559,0,0,0-2.572-7.786,14.492,14.492,0,0,0-4.329-3.163.121.121,0,0,1-.076-.121.1.1,0,0,1,.084-.063c.215,0,.437-.006.66-.006a41.674,41.674,0,0,1,7.771.715c2.891.546,4.755,1.643,5.539,3.26a4.613,4.613,0,0,1,0,4.175c-1.238,2.584-5.327,3.335-6.551,3.5A.5.5,0,0,1,37.882,34.726ZM16.535,21.167a11.068,11.068,0,0,1-7.748-3.089,10.392,10.392,0,0,1,0-14.99,11.263,11.263,0,0,1,15.5,0,10.393,10.393,0,0,1,0,14.99A11.068,11.068,0,0,1,16.535,21.167Zm14.58-1.559h0q-.125,0-.251,0a9.119,9.119,0,0,1-1.26-.116.415.415,0,0,1-.316-.245.371.371,0,0,1,.041-.365,14.148,14.148,0,0,0-.217-16.531.247.247,0,0,1-.042-.275.343.343,0,0,1,.213-.124,9.519,9.519,0,0,1,1.841-.18,9.25,9.25,0,0,1,7.345,3.543,8.8,8.8,0,0,1,1.556,7.735A9.082,9.082,0,0,1,31.116,19.607Z"
      fill="#779ce6"
    />
  </svg>
);

export const cartIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45 45" role="img">
    <path
      id="Shape"
      d="M37.5,45a3.479,3.479,0,1,1,3.4-3.467A3.446,3.446,0,0,1,37.5,45ZM12.209,45a3.479,3.479,0,1,1,3.4-3.467A3.446,3.446,0,0,1,12.209,45ZM37.1,34.413H12.57a5.647,5.647,0,0,1-3.884-1.549,5.894,5.894,0,0,1-1.827-3.822L4.79,4.012,1.4,3.415A1.723,1.723,0,0,1,1.98.02L7.343.843A1.73,1.73,0,0,1,8.736,2.405l.428,5.144a1.389,1.389,0,0,0,1.37,1.288H40.9a3.8,3.8,0,0,1,3.17,1.538,5.059,5.059,0,0,1,.855,3.947L42.787,29.386a5.885,5.885,0,0,1-1.925,3.6A5.686,5.686,0,0,1,37.1,34.413ZM27.273,17.33a1.687,1.687,0,0,0-1.686,1.723,1.706,1.706,0,0,0,1.686,1.721H33.5a1.706,1.706,0,0,0,1.686-1.721A1.687,1.687,0,0,0,33.5,17.33Z"
      fill="#ee8059"
    />
  </svg>
);
export const pieIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45 45" role="img">
    <path
      id="Shape"
      d="M19.788,45A19.816,19.816,0,0,1,2.447,34.907a18.406,18.406,0,0,1-2.3-7.058A13.441,13.441,0,0,1,0,25.631a19.312,19.312,0,0,1,4.418-12.19A19.97,19.97,0,0,1,15.65,6.66a2.527,2.527,0,0,1,.464-.043A2.481,2.481,0,0,1,18.341,8a2.453,2.453,0,0,1,.285.579c.172,2.65.354,5.33.525,7.847l.033.481c.126,1.856.256,3.775.379,5.666a4.221,4.221,0,0,0,.224,1.419,2.292,2.292,0,0,0,2.14,1.423q.061,0,.123,0l14.976-.96.1.039a2.465,2.465,0,0,1,1.7.725,2.346,2.346,0,0,1,.682,1.694A19.056,19.056,0,0,1,34.77,38.259a19.692,19.692,0,0,1-10.734,6.287A20.19,20.19,0,0,1,19.788,45Zm6.05-24.019a2.056,2.056,0,0,1-1.376-.525,1.987,1.987,0,0,1-.66-1.482L22.641,2V1.722a1.8,1.8,0,0,1,.594-1.25A1.853,1.853,0,0,1,24.473,0l.083,0A21.2,21.2,0,0,1,44.993,17.754a.571.571,0,0,1,0,.18,1.742,1.742,0,0,1-.444,1.295,1.814,1.814,0,0,1-1.247.6L26.023,20.973C25.961,20.978,25.9,20.981,25.838,20.981Z"
      fill="#eb6077"
    />
  </svg>
);

export const emailIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36}
    height={36}
    viewBox="0 0 36 36"
    role="img"
  >
    <g id="iconPhone" transform="translate(-858 -27)">
      <rect
        id="placeholder"
        width={36}
        height={36}
        rx={12}
        transform="translate(858 27)"
        fill="var(--background)"
      />
      <g id="Mail" transform="translate(864 33)">
        <path
          id="_1-mail"
          data-name="1-mail"
          d="M18,.84a1.18,1.18,0,0,0,0-.2L17.91.45a.126.126,0,0,0-.05-.1L17.81.3,17.65.17A.58.58,0,0,0,17.5.06L17.33,0H.73L.56.07a.49.49,0,0,0-.15.1L.25.3V.35C.25.4.25.42.2.45L.11.64a1.18,1.18,0,0,0,0,.2L0,.95v12a1,1,0,0,0,1,1h9a1,1,0,1,0,0-2H2v-9l6.4,4.8a1,1,0,0,0,1.2,0L16,2.95v9H14a1,1,0,1,0,0,2h3a1,1,0,0,0,1-1V.84ZM9,5.7,4,1.95H14Z"
          transform="translate(3 5.05)"
          fill="var(--primaryInvert)"
        />
      </g>
    </g>
  </svg>
);

export const deleteIcon = (onClick, style, classname) => (
  <svg
    width="18px"
    viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg"
    className={"deleteIcon"}
    onClick={onClick}
    style={style}
  >
    <path
         fill="rgb(45, 224, 237)"

      d="M13 18H5a2 2 0 0 1-2-2V7a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v9a2 2 0 0 1-2 2zm3-15a1 1 0 0 1-1 1H3a1 1 0 0 1 0-2h3V1a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v1h3a1 1 0 0 1 1 1z"
    />
  </svg>
);
export const editIcon = (onClick, style) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={17.933}
    height={19.603}
    viewBox="0 0 17.933 19.603"
    className="editIcon"
    onClick={onClick}
    data-tip="Edit email"
    style={style}
  >
    <path
      id="border_color_FILL0_wght400_GRAD0_opsz48"
      d="M5.345,23.877a1.279,1.279,0,0,1-.953-.4A1.334,1.334,0,0,1,4,22.51a1.279,1.279,0,0,1,.4-.953,1.334,1.334,0,0,1,.964-.392h15.22a1.339,1.339,0,0,1,1.345,1.345,1.378,1.378,0,0,1-1.367,1.367Zm1.166-5.088a.652.652,0,0,1-.672-.672v-2.04a.658.658,0,0,1,.045-.247.683.683,0,0,1,.157-.224L14.2,7.446l2.981,2.981L9.021,18.587a.683.683,0,0,1-.224.157.658.658,0,0,1-.247.045Zm.672-1.345H8.192a90,90,0,0,0,7.061-7.061c1.513-2.017-1.009-1.009-1.009-1.009L7.183,16.435Zm10.984-8L15.185,6.46l1.883-1.883a.761.761,0,0,1,.56-.3.858.858,0,0,1,.628.3L20,6.326a.868.868,0,0,1,.291.616.941.941,0,0,1-.247.616ZM7.183,17.444Z"
      transform="translate(-4 -4.274)"
    // fill="var(--primary)"
    />
  </svg>
);

export const copyIcon = () => (
  <svg
    width={10}
    height={12}
    viewBox="0 0 10 12"
    fill="var(--text)"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 0.5H1C0.45 0.5 0 0.95 0 1.5V8.5H1V1.5H7V0.5ZM8.5 2.5H3C2.45 2.5 2 2.95 2 3.5V10.5C2 11.05 2.45 11.5 3 11.5H8.5C9.05 11.5 9.5 11.05 9.5 10.5V3.5C9.5 2.95 9.05 2.5 8.5 2.5ZM8.5 10.5H3V3.5H8.5V10.5Z"
      fill="#757575"
    />
  </svg>
);

export const location = () => (
  <svg
    width={9}
    height={12}
    viewBox="0 0 9 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 4.23529C9 1.89882 6.9825 0 4.5 0C2.0175 0 0 1.89882 0 4.23529C0 7.41177 4.5 12 4.5 12C4.5 12 9 7.41177 9 4.23529ZM3 4.23529C3 3.45882 3.675 2.82353 4.5 2.82353C5.325 2.82353 6 3.45882 6 4.23529C6 5.01177 5.3325 5.64706 4.5 5.64706C3.675 5.64706 3 5.01177 3 4.23529Z"
      fill="#757575"
    />
  </svg>
);

export const logs = () => (
  <svg
    width={9}
    height={9}
    viewBox="0 0 9 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.705 2.705L6.29 0.29C6.105 0.105 5.85 0 5.585 0H1C0.45 0 0 0.45 0 1V8C0 8.55 0.45 9 1 9H8C8.55 9 9 8.55 9 8V3.415C9 3.15 8.895 2.895 8.705 2.705ZM2 2H5.5V3H2V2ZM7 7H2V6H7V7ZM7 5H2V4H7V5Z"
      fill="#757575"
    />
  </svg>
);

export const viewIcon = () => (
  <svg
    width={12}
    height={12}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="#757575"
      d="M28 21.155c-.02-.07-.05-.14-.09-.2.03.04.05.09.07.13.01.02.02.05.02.07zM27.82 20.835c-.02-.03-.05-.06-.08-.09.01.01.02.01.03.02.02.02.04.04.05.07z"
    />
    <path
      fill="#757575"
      d="M28 21.155c-.02-.07-.05-.14-.09-.2.03.04.05.09.07.13.01.02.02.05.02.07zM27.77 20.765c.02.02.04.04.05.07-.02-.03-.05-.06-.08-.09.01.01.02.01.03.02zM6.5 15H5c-.55 0-1 .45-1 1s.45 1 1 1h2.81c1.18 1.23 2.85 2 4.69 2 1.44 0 2.76-.46 3.832-1.25l.91.91c-.17.67 0 1.4.52 1.93l2.83 2.83c.78.77 2.05.77 2.83 0 .77-.78.77-2.05 0-2.83l-2.83-2.83c-.39-.39-.9-.59-1.42-.58-.17-.01-.34.02-.51.06l-.91-.91c.79-1.07 1.25-2.39 1.25-3.83 0-3.59-2.91-6.5-6.5-6.5-1.28 0-2.47.37-3.47 1H5c-.55 0-1 .45-1 1s.45 1 1 1h2.02c-.39.6-.68 1.28-.84 2H5c-.55 0-1 .45-1 1s.45 1 1 1h1.02c-.01-.17.22 1.38.48 2zm1.76-1h4.742c1.1 0 2-.9 2-2s-.9-2-2-2h-4.24c.81-1.21 2.19-2 3.74-2 2.48 0 4.5 2.02 4.5 4.502s-2.02 4.5-4.5 4.5c-1.95 0-3.62-1.25-4.24-3z"
    />
    <path
      fill="#757575"
      d="M18 3v1c-.01.54-.46.98-1 .98s-.99-.44-1-.98V3c0-.54-.46-1-1-1H3c-.54 0-1 .46-1 1v18c0 .54.46 1 1 1h11c.55 0 1 .45 1 1s-.45 1-1 1H3c-1.65 0-3-1.35-3-3V3c0-1.65 1.35-3 3-3h12c1.65 0 3 1.35 3 3z"
    />
  </svg>
);

export const eyeOpenIcon = (onClick) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={128}
    height={88}
    viewBox="0 0 128 88"
    className="eyeOpenIcon"
    onClick={onClick}
  >
    <g id="Group_3" data-name="Group 3" transform="translate(-1116 -822)">
      <g id="eyeclosed_105133" transform="translate(1244 930) rotate(180)">
        <g id="Invisible">
          <g id="Group_2" data-name="Group 2">
            <path
              id="Path_2"
              data-name="Path 2"
              d="M120.836,70.2A98.229,98.229,0,0,0,128,60C113.367,35.75,90.195,20,64,20S14.633,35.75,0,60A98.38,98.38,0,0,0,7.164,70.2L4.6,72.766a4,4,0,0,0,5.656,5.664l2.1-2.1a85.885,85.885,0,0,0,6.4,6.031L16.14,86.89a4,4,0,0,0,6.93,4l2.094-3.633a74.788,74.788,0,0,0,7.328,4.492l-1.547,5.789a4,4,0,1,0,7.726,2.07l1.188-4.43A65.911,65.911,0,0,0,48,97.834V104a4,4,0,0,0,8,0V99.4a64.618,64.618,0,0,0,8,.6,64.426,64.426,0,0,0,8-.6V104a4,4,0,0,0,8,0V97.836a64.861,64.861,0,0,0,8.141-2.656l1.188,4.43a4,4,0,1,0,7.726-2.07l-1.547-5.781a76.367,76.367,0,0,0,7.328-4.492l2.094,3.625a4,4,0,0,0,6.93-4l-2.617-4.531a85.886,85.886,0,0,0,6.4-6.031l2.1,2.1a4,4,0,0,0,5.656-5.664ZM64,92C42.922,92,23.281,80.406,9.492,60,23.281,39.594,42.922,28,64,28s40.719,11.594,54.508,32C104.719,80.406,85.078,92,64,92Z"
            />
          </g>
        </g>
      </g>
      <g id="darkeye_83791" transform="translate(1123.817 743.037)">
        <path
          id="Path_3"
          data-name="Path 3"
          d="M59.183,20.9C20.355,20.9,0,50.062,0,54.686S20.355,88.471,59.183,88.471s59.183-29.161,59.183-33.786S98.009,20.9,59.183,20.9Zm0,59.775a25.991,25.991,0,1,1,26.3-25.99A26.149,26.149,0,0,1,59.183,80.675Zm13.151-25.99A13.152,13.152,0,1,1,59.183,41.691c3.925,0-2.408,10.3,0,12.995C61.23,56.976,72.335,51.39,72.335,54.686Z"
          transform="translate(-3 73)"
        />
      </g>
    </g>
  </svg>
);

export const eyeCloseIcon = (onClick) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={128}
    height={88}
    viewBox="0 0 128 88"
    className="eyeCloseIcon"
    onClick={onClick}
  >
    <g id="eyeopen_105134" transform="translate(0 -20)">
      <g id="Invisible">
        <g id="Group_2" data-name="Group 2">
          <path
            id="Path_2"
            data-name="Path 2"
            d="M120.836,70.2A98.229,98.229,0,0,0,128,60C113.367,35.75,90.195,20,64,20S14.633,35.75,0,60A98.38,98.38,0,0,0,7.164,70.2L4.6,72.766a4,4,0,0,0,5.656,5.664l2.1-2.1a85.885,85.885,0,0,0,6.4,6.031L16.14,86.89a4,4,0,0,0,6.93,4l2.094-3.633a74.788,74.788,0,0,0,7.328,4.492l-1.547,5.789a4,4,0,1,0,7.726,2.07l1.188-4.43A65.911,65.911,0,0,0,48,97.834V104a4,4,0,0,0,8,0V99.4a64.618,64.618,0,0,0,8,.6,64.426,64.426,0,0,0,8-.6V104a4,4,0,0,0,8,0V97.836a64.861,64.861,0,0,0,8.141-2.656l1.188,4.43a4,4,0,1,0,7.726-2.07l-1.547-5.781a76.367,76.367,0,0,0,7.328-4.492l2.094,3.625a4,4,0,0,0,6.93-4l-2.617-4.531a85.886,85.886,0,0,0,6.4-6.031l2.1,2.1a4,4,0,0,0,5.656-5.664ZM64,92C42.922,92,23.281,80.406,9.492,60,23.281,39.594,42.922,28,64,28s40.719,11.594,54.508,32C104.719,80.406,85.078,92,64,92Z"
          />
        </g>
      </g>
    </g>
  </svg>
);

export const noData = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={179.213}
    height={133.535}
    viewBox="0 0 179.213 133.535"
  >
    <g
      id="Button_style-rafiki"
      data-name="Button style-rafiki"
      transform="translate(0 -55)"
    >
      <g id="--background-complete--inject-6" transform="translate(0 55)">
        <rect
          id="Rectangle_149101"
          data-name="Rectangle 149101"
          width={179.213}
          height={0.09}
          transform="translate(0 117.349)"
          fill="#ebebeb"
        />
        <rect
          id="Rectangle_149102"
          data-name="Rectangle 149102"
          width={11.871}
          height={0.09}
          transform="translate(149.385 123.116)"
          fill="#ebebeb"
        />
        <rect
          id="Rectangle_149103"
          data-name="Rectangle 149103"
          width={3.115}
          height={0.09}
          transform="translate(115.603 124.091)"
          fill="#ebebeb"
        />
        <rect
          id="Rectangle_149104"
          data-name="Rectangle 149104"
          width={6.878}
          height={0.09}
          transform="translate(142.148 119.789)"
          fill="#ebebeb"
        />
        <rect
          id="Rectangle_149105"
          data-name="Rectangle 149105"
          width={15.48}
          height={0.09}
          transform="translate(18.803 120.392)"
          fill="#ebebeb"
        />
        <rect
          id="Rectangle_149106"
          data-name="Rectangle 149106"
          width={2.269}
          height={0.09}
          transform="translate(37.477 120.392)"
          fill="#ebebeb"
        />
        <rect
          id="Rectangle_149107"
          data-name="Rectangle 149107"
          width={33.577}
          height={0.09}
          transform="translate(47.122 121.904)"
          fill="#ebebeb"
        />
        <path
          id="Path_134124"
          data-name="Path 134124"
          d="M109.461,156.363H40.253a2.047,2.047,0,0,1-2.043-2.047V57.029A2.047,2.047,0,0,1,40.253,55h69.208a2.047,2.047,0,0,1,2.047,2.047v97.27A2.047,2.047,0,0,1,109.461,156.363ZM40.253,55.072A1.957,1.957,0,0,0,38.3,57.029v97.288a1.957,1.957,0,0,0,1.953,1.957h69.208a1.961,1.961,0,0,0,1.957-1.957V57.029a1.961,1.961,0,0,0-1.957-1.957Z"
          transform="translate(-24.515 -55)"
          fill="#ebebeb"
        />
        <path
          id="Path_134125"
          data-name="Path 134125"
          d="M325.759,156.363H256.547a2.05,2.05,0,0,1-2.047-2.047V57.029A2.05,2.05,0,0,1,256.547,55h69.212a2.047,2.047,0,0,1,2.039,2.029v97.288A2.047,2.047,0,0,1,325.759,156.363ZM256.547,55.072a1.961,1.961,0,0,0-1.957,1.957v97.288a1.961,1.961,0,0,0,1.957,1.957h69.212a1.961,1.961,0,0,0,1.957-1.957V57.029a1.961,1.961,0,0,0-1.957-1.957Z"
          transform="translate(-163.281 -55)"
          fill="#ebebeb"
        />
        <path
          id="Path_134126"
          data-name="Path 134126"
          d="M297.67,282.82h43.065a2.957,2.957,0,0,1-2.824,2.95c-4.627.208-11.749.943-14,3.5-4.208,4.774-3.8,21.541,1.24,25.423a65.747,65.747,0,0,0,6.186,3.634H307.072s4.3-2.466,5.8-3.634c5.018-3.914,5.158-20.67.928-25.423-2.168-2.437-8.842-3.226-13.319-3.473A2.982,2.982,0,0,1,297.67,282.82Z"
          transform="translate(-190.977 -201.163)"
          fill="#f5f5f5"
        />
        <path
          id="Path_134127"
          data-name="Path 134127"
          d="M343.77,291.49c-4.373.358-9.613,1.215-11.491,3.344a8.54,8.54,0,0,0-1.434,2.466h-7.222a8.65,8.65,0,0,0-1.434-2.466c-1.885-2.118-7.169-2.989-11.47-3.344Z"
          transform="translate(-199.35 -206.726)"
          fill="#e6e6e6"
        />
        <path
          id="Path_134128"
          data-name="Path 134128"
          d="M333.658,270.9l.588-6.692h-8.491l-.584,6.692Z"
          transform="translate(-208.621 -189.224)"
          fill="#ebebeb"
        />
        <path
          id="Path_134129"
          data-name="Path 134129"
          d="M348.85,270.9l.588-6.692h.455l-.584,6.692Z"
          transform="translate(-223.813 -189.224)"
          fill="#e0e0e0"
        />
        <path
          id="Path_134130"
          data-name="Path 134130"
          d="M334.506,271.44l.466-5.33h-6.756l-.466,5.33Z"
          transform="translate(-210.276 -190.443)"
          fill="#f0f0f0"
        />
        <path
          id="Path_134131"
          data-name="Path 134131"
          d="M335.055,271.782l.391-4.452H329.8l-.391,4.452Z"
          transform="translate(-211.341 -191.225)"
          fill="#fafafa"
        />
        <path
          id="Path_134132"
          data-name="Path 134132"
          d="M354.836,263.464h8.527l-.946-10.814H353.89Z"
          transform="translate(-227.047 -181.807)"
          fill="#e6e6e6"
        />
        <path
          id="Path_134133"
          data-name="Path 134133"
          d="M354.111,263.464h-.405l-.946-10.814h.405Z"
          transform="translate(-226.322 -181.807)"
          fill="#e0e0e0"
        />
        <path
          id="Path_134134"
          data-name="Path 134134"
          d="M357.343,264.339h6.789l-.753-8.609H356.59Z"
          transform="translate(-228.779 -183.783)"
          fill="#f0f0f0"
        />
        <path
          id="Path_134135"
          data-name="Path 134135"
          d="M358.951,264.894h5.67l-.631-7.194h-5.67Z"
          transform="translate(-229.889 -185.047)"
          fill="#fafafa"
        />
        <path
          id="Path_134136"
          data-name="Path 134136"
          d="M365.053,263.714h0a.986.986,0,0,1-1.4,0h0a.989.989,0,0,1,0-1.394h0a.986.986,0,0,1,1.4,0h0A.989.989,0,0,1,365.053,263.714Z"
          transform="translate(-233.128 -187.825)"
          fill="#ebebeb"
        />
        <path
          id="Path_134137"
          data-name="Path 134137"
          d="M366.507,269.717h0a.717.717,0,0,1,1.022,0h0a.717.717,0,0,1-.509,1.233h0a.717.717,0,0,1-.513-1.233Z"
          transform="translate(-235.001 -192.62)"
          fill="#f0f0f0"
        />
        <path
          id="Path_134138"
          data-name="Path 134138"
          d="M131.989,307.193h8.3l-4.366-35.556a3.172,3.172,0,0,0-3.061-2.728H96.63l5.312,38.283Z"
          transform="translate(-61.995 -192.239)"
          fill="#f5f5f5"
        />
        <path
          id="Path_134139"
          data-name="Path 134139"
          d="M99.064,307.192h-8.3L86.39,271.637a2.366,2.366,0,0,1,2.391-2.728h2.867a3.176,3.176,0,0,1,3.061,2.728Z"
          transform="translate(-55.408 -192.238)"
          fill="#f0f0f0"
        />
        <path
          id="Path_134140"
          data-name="Path 134140"
          d="M95.2,328.553l-1.505-17.2a1.638,1.638,0,0,0-1.434-1.484l-.62-.079a2.194,2.194,0,0,1-1.91-1.989l-.276-3.136a2.2,2.2,0,0,1,2.19-2.391H77.781a2.2,2.2,0,0,0-2.19,2.412l.272,3.136a2.2,2.2,0,0,0,1.914,1.989l.616.079a1.642,1.642,0,0,1,1.434,1.484l1.505,17.2a2.43,2.43,0,0,0,2.419,2.219H97.631a2.43,2.43,0,0,1-2.427-2.24Z"
          transform="translate(-48.491 -213.642)"
          fill="#e6e6e6"
        />
        <path
          id="Path_134141"
          data-name="Path 134141"
          d="M213.136,307.064a5.222,5.222,0,0,1,5.208-4.774H206.261a5.229,5.229,0,0,0-5.212,4.774l-1.19,13.62h12.083Z"
          transform="translate(-128.225 -213.655)"
          fill="#e6e6e6"
        />
        <path
          id="Path_134142"
          data-name="Path 134142"
          d="M168.808,302.29h-5.376a5.222,5.222,0,0,0-5.208,4.774l-1.194,13.62H128.263l-1.194-13.62a5.229,5.229,0,0,0-5.208-4.774h-5.376a2.2,2.2,0,0,0-2.19,2.391l.276,3.136a2.194,2.194,0,0,0,1.91,1.989l.62.079a1.638,1.638,0,0,1,1.434,1.484l1.505,17.2a2.43,2.43,0,0,0,2.419,2.219h40.387a2.427,2.427,0,0,0,2.416-2.219l1.505-17.2a1.642,1.642,0,0,1,1.434-1.484l.616-.079a2.194,2.194,0,0,0,1.914-1.989l.276-3.136a2.2,2.2,0,0,0-2.2-2.391Z"
          transform="translate(-73.324 -213.655)"
          fill="#f0f0f0"
        />
        <path
          id="Path_134143"
          data-name="Path 134143"
          d="M184.235,107.084a5.415,5.415,0,0,0-1.6-4.545c-2.022-1.774-1.842-2.832-1.265-2.971s1.111,1.638,2,2.125c0,0,.025-2.53-.875-3.462s-1.376-3.333-.516-3.373.946,1.434,1.122,2.466a8.847,8.847,0,0,0,1.136,2.509A9.85,9.85,0,0,1,185,96.64c.581-.932-.971-3.065-.358-4s1.663.842,1.573,2.842-1.434,4.125-1.108,6.212c0,0,.588-1.462,1.115-1.595S187.3,99,187.64,98.64s.8.885,0,2.039-2.842,4.24-2.53,6.735Z"
          transform="translate(-116.113 -78.987)"
          fill="#e6e6e6"
        />
        <path
          id="Path_134144"
          data-name="Path 134144"
          d="M185.457,129.87l-.663,4.151a2.115,2.115,0,0,0,2.086,2.444h.717a2.111,2.111,0,0,0,2.082-2.444l-.663-4.151Z"
          transform="translate(-118.542 -103.035)"
          fill="#f5f5f5"
        />
        <rect
          id="Rectangle_149108"
          data-name="Rectangle 149108"
          width={46.753}
          height={2.401}
          transform="translate(30.165 31.896)"
          fill="#e6e6e6"
        />
        <rect
          id="Rectangle_149109"
          data-name="Rectangle 149109"
          width={2.061}
          height={8.853}
          transform="translate(32.316 23.043)"
          fill="#f0f0f0"
        />
        <rect
          id="Rectangle_149110"
          data-name="Rectangle 149110"
          width={2.061}
          height={8.853}
          transform="translate(42.276 23.043)"
          fill="#f0f0f0"
        />
        <rect
          id="Rectangle_149111"
          data-name="Rectangle 149111"
          width={2.061}
          height={8.853}
          transform="matrix(0.985, -0.17, 0.17, 0.985, 53.613, 23.17)"
          fill="#f0f0f0"
        />
        <rect
          id="Rectangle_149112"
          data-name="Rectangle 149112"
          width={2.061}
          height={7.688}
          transform="translate(34.868 24.208)"
          fill="#f0f0f0"
        />
        <rect
          id="Rectangle_149113"
          data-name="Rectangle 149113"
          width={2.061}
          height={7.688}
          transform="translate(39.602 24.208)"
          fill="#f0f0f0"
        />
        <rect
          id="Rectangle_149114"
          data-name="Rectangle 149114"
          width={2.061}
          height={7.688}
          transform="translate(51.552 24.208)"
          fill="#f0f0f0"
        />
        <rect
          id="Rectangle_149115"
          data-name="Rectangle 149115"
          width={2.061}
          height={7.688}
          transform="translate(45.033 24.392) rotate(-12.53)"
          fill="#f0f0f0"
        />
        <rect
          id="Rectangle_149116"
          data-name="Rectangle 149116"
          width={1.029}
          height={9.294}
          transform="translate(37.681 22.602)"
          fill="#f0f0f0"
        />
        <rect
          id="Rectangle_149117"
          data-name="Rectangle 149117"
          width={1.029}
          height={9.294}
          transform="translate(49.932 22.602)"
          fill="#f0f0f0"
        />
        <rect
          id="Rectangle_149118"
          data-name="Rectangle 149118"
          width={46.753}
          height={2.401}
          transform="translate(76.918 52.233) rotate(180)"
          fill="#e6e6e6"
        />
        <rect
          id="Rectangle_149119"
          data-name="Rectangle 149119"
          width={2.061}
          height={8.853}
          transform="translate(74.764 49.832) rotate(180)"
          fill="#f0f0f0"
        />
        <rect
          id="Rectangle_149120"
          data-name="Rectangle 149120"
          width={2.061}
          height={8.853}
          transform="translate(64.807 49.832) rotate(180)"
          fill="#f0f0f0"
        />
        <rect
          id="Rectangle_149121"
          data-name="Rectangle 149121"
          width={2.061}
          height={8.853}
          transform="matrix(-0.985, -0.17, 0.17, -0.985, 51.966, 49.828)"
          fill="#f0f0f0"
        />
        <rect
          id="Rectangle_149122"
          data-name="Rectangle 149122"
          width={2.061}
          height={7.688}
          transform="translate(72.212 49.828) rotate(180)"
          fill="#f0f0f0"
        />
        <rect
          id="Rectangle_149123"
          data-name="Rectangle 149123"
          width={2.061}
          height={7.688}
          transform="translate(67.477 49.828) rotate(180)"
          fill="#f0f0f0"
        />
        <rect
          id="Rectangle_149124"
          data-name="Rectangle 149124"
          width={2.061}
          height={7.688}
          transform="translate(55.527 49.828) rotate(180)"
          fill="#f0f0f0"
        />
        <rect
          id="Rectangle_149125"
          data-name="Rectangle 149125"
          width={2.061}
          height={7.688}
          transform="translate(60.389 49.828) rotate(-167.47)"
          fill="#f0f0f0"
        />
        <rect
          id="Rectangle_149126"
          data-name="Rectangle 149126"
          width={1.029}
          height={9.294}
          transform="translate(69.402 49.832) rotate(180)"
          fill="#f0f0f0"
        />
        <rect
          id="Rectangle_149127"
          data-name="Rectangle 149127"
          width={1.029}
          height={9.294}
          transform="translate(57.151 49.832) rotate(180)"
          fill="#f0f0f0"
        />
        <rect
          id="Rectangle_149128"
          data-name="Rectangle 149128"
          width={10.036}
          height={6.928}
          transform="translate(44.685 49.753) rotate(180)"
          fill="#f5f5f5"
        />
        <rect
          id="Rectangle_149129"
          data-name="Rectangle 149129"
          width={11.115}
          height={15.724}
          transform="translate(145.768 22.441) rotate(90)"
          fill="#ebebeb"
        />
        <rect
          id="Rectangle_149130"
          data-name="Rectangle 149130"
          width={9.663}
          height={14.247}
          transform="translate(145.026 23.165) rotate(90)"
          fill="#f5f5f5"
        />
        <rect
          id="Rectangle_149131"
          data-name="Rectangle 149131"
          width={11.111}
          height={15.724}
          transform="translate(140.317 36.398) rotate(90)"
          fill="#ebebeb"
        />
        <rect
          id="Rectangle_149132"
          data-name="Rectangle 149132"
          width={9.663}
          height={14.247}
          transform="translate(139.575 37.126) rotate(90)"
          fill="#f5f5f5"
        />
        <rect
          id="Rectangle_149133"
          data-name="Rectangle 149133"
          width={10.007}
          height={14.158}
          transform="translate(127.058 33.552) rotate(180)"
          fill="#ebebeb"
        />
        <rect
          id="Rectangle_149134"
          data-name="Rectangle 149134"
          width={8.703}
          height={12.828}
          transform="translate(126.406 32.886) rotate(180)"
          fill="#f5f5f5"
        />
        <rect
          id="Rectangle_149135"
          data-name="Rectangle 149135"
          width={11.111}
          height={15.724}
          transform="translate(122.474 52.126) rotate(180)"
          fill="#ebebeb"
        />
        <rect
          id="Rectangle_149136"
          data-name="Rectangle 149136"
          width={9.663}
          height={14.247}
          transform="translate(121.75 51.388) rotate(180)"
          fill="#f5f5f5"
        />
        <rect
          id="Rectangle_149137"
          data-name="Rectangle 149137"
          width={8.703}
          height={12.312}
          transform="translate(101.345 33.556) rotate(-90)"
          fill="#ebebeb"
        />
        <rect
          id="Rectangle_149138"
          data-name="Rectangle 149138"
          width={7.566}
          height={11.154}
          transform="translate(101.922 32.986) rotate(-90)"
          fill="#f5f5f5"
        />
      </g>
      <g id="--Shadow--inject-6" transform="translate(20.111 180.42)">
        <ellipse
          id="--path--inject-6"
          cx={69.495}
          cy={4.057}
          rx={69.495}
          ry={4.057}
          transform="translate(0 0)"
          fill="#f5f5f5"
        />
      </g>
      <g id="--Plant--inject-6" transform="translate(131.926 143.282)">
        <path
          id="Path_134145"
          data-name="Path 134145"
          d="M384.378,332.267a10.7,10.7,0,0,0-.29-8.774c-2.312-4.355-2.986-5.376-1.509-6.272s2.151,3.484,2.151,3.484a9.836,9.836,0,0,0,.219-6.161c-1.025-3.151-1.67-8.283-.269-8.832s.265,6.4,1.484,8.771a20.428,20.428,0,0,0,1.19-5.169c.168-2.53-.1-6.574,1.011-7.609s1.817-.319.717,5.265a58.579,58.579,0,0,0-1.075,10.125s2.4-7.326,4.014-7.527,1.613,2.151.233,4.219-4.043,5.735-4.48,7.642c0,0,2.638-5.513,5.018-3.824s-2.065,5.018-4.043,6.222-3.351,8.746-3.351,8.746Z"
          transform="translate(-376.877 -301.304)"
          fill="#2d62ed"
        />
        <path
          id="Path_134146"
          data-name="Path 134146"
          d="M384.378,332.267a10.7,10.7,0,0,0-.29-8.774c-2.312-4.355-2.986-5.376-1.509-6.272s2.151,3.484,2.151,3.484a9.836,9.836,0,0,0,.219-6.161c-1.025-3.151-1.67-8.283-.269-8.832s.265,6.4,1.484,8.771a20.428,20.428,0,0,0,1.19-5.169c.168-2.53-.1-6.574,1.011-7.609s1.817-.319.717,5.265a58.579,58.579,0,0,0-1.075,10.125s2.4-7.326,4.014-7.527,1.613,2.151.233,4.219-4.043,5.735-4.48,7.642c0,0,2.638-5.513,5.018-3.824s-2.065,5.018-4.043,6.222-3.351,8.746-3.351,8.746Z"
          transform="translate(-376.877 -301.304)"
          opacity={0.2}
        />
        <path
          id="Path_134147"
          data-name="Path 134147"
          d="M377.438,337.2a10.681,10.681,0,0,1,.391-8.771c2.366-4.323,3.05-5.333,1.584-6.251s-2.19,3.452-2.19,3.452a9.825,9.825,0,0,1-.143-6.161c1.065-3.133,1.767-8.244.358-8.828s-.358,6.394-1.588,8.749a20.552,20.552,0,0,1-1.129-5.186c-.136-2.534.176-6.57-.921-7.624s-1.792-.358-.789,5.255a58.712,58.712,0,0,1,.957,10.14s-2.315-7.362-3.925-7.6-1.638,2.133-.28,4.212,3.971,5.8,4.387,7.706c0,0-2.573-5.552-4.964-3.9s2,5.05,3.968,6.283,3.226,8.8,3.226,8.8Z"
          transform="translate(-368.341 -304.425)"
          fill="#2d62ed"
        />
        <path
          id="Path_134148"
          data-name="Path 134148"
          d="M368.07,383.9h16.29l-1.319,11.656H369.389Z"
          transform="translate(-368.07 -354.295)"
          fill="#263238"
        />
      </g>
      <g id="--Panel--inject-6" transform="translate(56.201 73.857)">
        <rect
          id="Rectangle_149139"
          data-name="Rectangle 149139"
          width={85.133}
          height={78.922}
          rx={3.75}
          transform="translate(0.007)"
          fill="#2d62ed"
        />
        <path
          id="Path_134149"
          data-name="Path 134149"
          d="M156.82,134.65v67.886a1.344,1.344,0,0,0,1.348,1.344h82.438a1.344,1.344,0,0,0,1.344-1.344V134.65Z"
          transform="translate(-156.813 -124.958)"
          fill="#fff"
          opacity={0.7}
        />
        <path
          id="Path_134150"
          data-name="Path 134150"
          d="M377.933,117.057a2.423,2.423,0,1,1-.708-1.716,2.427,2.427,0,0,1,.708,1.716Z"
          transform="translate(-295.56 -112.114)"
          fill="#fff"
          opacity={0.4}
        />
        <path
          id="Path_134151"
          data-name="Path 134151"
          d="M378.372,117.1a1.867,1.867,0,1,1-.546-1.323,1.871,1.871,0,0,1,.546,1.323Z"
          transform="translate(-296.554 -112.499)"
          fill="#fff"
          opacity={0.4}
        />
        <path
          id="Path_134152"
          data-name="Path 134152"
          d="M358.513,117.057a2.423,2.423,0,1,1-.708-1.716,2.427,2.427,0,0,1,.708,1.716Z"
          transform="translate(-283.1 -112.114)"
          fill="#fff"
          opacity={0.4}
        />
        <path
          id="Path_134153"
          data-name="Path 134153"
          d="M358.952,117.1a1.867,1.867,0,1,1-.546-1.323A1.871,1.871,0,0,1,358.952,117.1Z"
          transform="translate(-284.095 -112.499)"
          fill="#fff"
          opacity={0.4}
        />
        <path
          id="Path_134154"
          data-name="Path 134154"
          d="M234.7,132.64H164.04a36.66,36.66,0,0,0-7.24.717h85.14A36.661,36.661,0,0,0,234.7,132.64Z"
          transform="translate(-156.8 -123.669)"
          opacity={0.2}
        />
        <path
          id="Path_134155"
          data-name="Path 134155"
          d="M240.909,109.807H158.4a.333.333,0,0,1-.333-.333h0a.333.333,0,0,1,.333-.333h82.506a.333.333,0,0,1,.333.333h0a.333.333,0,0,1-.333.333Z"
          transform="translate(-157.615 -108.592)"
          fill="#fff"
          opacity={0.2}
        />
      </g>
      <g id="--button-3--inject-6" transform="translate(59.413 131.295)">
        <path
          id="Path_134156"
          data-name="Path 134156"
          d="M242.323,284.444H166.864a1.1,1.1,0,0,1-1.1-1.1V268.964a1.1,1.1,0,0,1,1.1-1.1h75.459a1.1,1.1,0,0,1,1.1,1.1V283.34a1.1,1.1,0,0,1-1.1,1.1ZM166.864,268.2a.767.767,0,0,0-.763.763V283.34a.767.767,0,0,0,.763.767h75.459a.767.767,0,0,0,.763-.767V268.964a.767.767,0,0,0-.763-.763Z"
          transform="translate(-165.76 -267.86)"
          fill="#263238"
        />
        <g
          id="Group_16227"
          data-name="Group 16227"
          transform="translate(0 0)"
          opacity={0.6}
        >
          <path
            id="Path_134157"
            data-name="Path 134157"
            d="M242.323,284.444H166.864a1.1,1.1,0,0,1-1.1-1.1V268.964a1.1,1.1,0,0,1,1.1-1.1h75.459a1.1,1.1,0,0,1,1.1,1.1V283.34a1.1,1.1,0,0,1-1.1,1.1ZM166.864,268.2a.767.767,0,0,0-.763.763V283.34a.767.767,0,0,0,.763.767h75.459a.767.767,0,0,0,.763-.767V268.964a.767.767,0,0,0-.763-.763Z"
            transform="translate(-165.76 -267.86)"
            fill="#fff"
          />
        </g>
        <path
          id="Path_134158"
          data-name="Path 134158"
          d="M203.808,277.34H181.586a4.9,4.9,0,0,0,0,9.792h22.222a4.9,4.9,0,1,0,0-9.792Z"
          transform="translate(-172.772 -273.942)"
          fill="#263238"
        />
        <path
          id="Path_134159"
          data-name="Path 134159"
          d="M203.808,277.34H181.586a4.9,4.9,0,0,0,0,9.792h22.222a4.9,4.9,0,1,0,0-9.792Z"
          transform="translate(-172.772 -273.942)"
          fill="#fff"
          opacity={0.3}
        />
        <path
          id="Path_134160"
          data-name="Path 134160"
          d="M209.764,279.743l-.043-.047a2.021,2.021,0,0,0-1.48-.656H184.793a2.029,2.029,0,0,0-1.48.656l-.043.047a2.351,2.351,0,0,0,1.749,3.943h23a2.351,2.351,0,0,0,1.746-3.943Z"
          transform="translate(-176.595 -275.033)"
          fill="#fff"
          opacity={0.2}
        />
        <g
          id="Group_16228"
          data-name="Group 16228"
          transform="translate(12.294 6.86)"
          opacity={0.2}
        >
          <path
            id="Path_134161"
            data-name="Path 134161"
            d="M201.788,288.534a.828.828,0,0,1-.849.867.742.742,0,0,1-.656-.358v.337h-.222V287h.229v1.022a.746.746,0,0,1,.649-.333.824.824,0,0,1,.849.846Zm-.233,0a.634.634,0,1,0-.631.663.624.624,0,0,0,.631-.663Z"
            transform="translate(-200.06 -287)"
          />
          <path
            id="Path_134162"
            data-name="Path 134162"
            d="M207.683,288.9v1.7h-.219v-.312a.677.677,0,0,1-.613.33.66.66,0,0,1-.717-.717v-1h.229v.964a.477.477,0,0,0,.513.548.552.552,0,0,0,.577-.62V288.9Z"
            transform="translate(-203.956 -288.219)"
          />
          <path
            id="Path_134163"
            data-name="Path 134163"
            d="M212.681,289.857a.537.537,0,0,1-.358.122.452.452,0,0,1-.5-.5v-1.029h-.3v-.194h.3V287.9h.226v.358h.516v.194h-.516v1.018a.272.272,0,0,0,.294.312.4.4,0,0,0,.254-.09Z"
            transform="translate(-207.412 -287.577)"
          />
          <path
            id="Path_134164"
            data-name="Path 134164"
            d="M216.221,289.857a.537.537,0,0,1-.358.122.452.452,0,0,1-.5-.5v-1.029h-.3v-.194h.3V287.9h.229v.358h.52v.194H215.6v1.018a.272.272,0,0,0,.294.312.383.383,0,0,0,.251-.09Z"
            transform="translate(-209.684 -287.577)"
          />
          <path
            id="Path_134165"
            data-name="Path 134165"
            d="M218.809,289.727a.867.867,0,1,1,.867.867.839.839,0,0,1-.867-.867Zm1.5,0a.634.634,0,1,0-.631.663.62.62,0,0,0,.634-.663Z"
            transform="translate(-212.089 -288.193)"
          />
          <path
            id="Path_134166"
            data-name="Path 134166"
            d="M226.548,289.6v.986h-.229v-.95a.477.477,0,0,0-.509-.545.563.563,0,0,0-.6.617v.892H225v-1.717h.222v.315a.717.717,0,0,1,.645-.326.649.649,0,0,1,.681.728Z"
            transform="translate(-216.061 -288.197)"
          />
          <path
            id="Path_134167"
            data-name="Path 134167"
            d="M233,288.431c0-.717.376-1.151.9-1.151s.907.43.907,1.151-.38,1.151-.907,1.151S233,289.151,233,288.431Zm1.566,0c0-.606-.269-.939-.667-.939s-.667.333-.667.939.272.939.667.939.67-.333.67-.939Z"
            transform="translate(-221.193 -287.18)"
          />
          <path
            id="Path_134168"
            data-name="Path 134168"
            d="M240.138,288.946c0,.376-.276.667-.821.667a1.179,1.179,0,0,1-.817-.29l.115-.183a1,1,0,0,0,.717.262c.376,0,.581-.176.581-.455s-.19-.452-.609-.452h-.161v-.172l.627-.785h-1.158v-.208h1.455v.165l-.67.792C239.894,288.326,240.138,288.584,240.138,288.946Z"
            transform="translate(-224.722 -287.212)"
          />
        </g>
        <path
          id="Path_134169"
          data-name="Path 134169"
          d="M310.008,277.34H287.786a4.9,4.9,0,0,0,0,9.792h22.222a4.9,4.9,0,1,0,0-9.792Z"
          transform="translate(-240.908 -273.942)"
          fill="#2d62ed"
        />
        <path
          id="Path_134170"
          data-name="Path 134170"
          d="M315.962,279.743l-.043-.047a2.021,2.021,0,0,0-1.48-.656H290.994a2.028,2.028,0,0,0-1.484.656l-.043.047a2.355,2.355,0,0,0,1.749,3.943h23a2.351,2.351,0,0,0,1.746-3.943Z"
          transform="translate(-244.732 -275.033)"
          fill="#fff"
          opacity={0.2}
        />
        <path
          id="Path_134171"
          data-name="Path 134171"
          d="M307.984,288.534a.828.828,0,0,1-.849.867.742.742,0,0,1-.656-.358v.337h-.219V287h.229v1.022a.738.738,0,0,1,.645-.333.821.821,0,0,1,.849.846Zm-.229,0a.634.634,0,1,0-.634.663.624.624,0,0,0,.634-.663Z"
          transform="translate(-255.901 -280.14)"
          fill="#fff"
        />
        <path
          id="Path_134172"
          data-name="Path 134172"
          d="M313.858,288.9v1.7h-.237v-.312a.677.677,0,0,1-.609.33.66.66,0,0,1-.717-.717v-1h.251v.964a.48.48,0,0,0,.513.548.552.552,0,0,0,.577-.62V288.9Z"
          transform="translate(-259.771 -281.359)"
          fill="#fff"
        />
        <path
          id="Path_134173"
          data-name="Path 134173"
          d="M318.921,289.857a.538.538,0,0,1-.358.122.452.452,0,0,1-.5-.5v-1.029h-.3v-.194h.3V287.9h.229v.358h.52v.194h-.538v1.018a.272.272,0,0,0,.294.312.394.394,0,0,0,.254-.09Z"
          transform="translate(-263.279 -280.717)"
          fill="#fff"
        />
        <path
          id="Path_134174"
          data-name="Path 134174"
          d="M322.411,289.857a.537.537,0,0,1-.358.122.452.452,0,0,1-.5-.5v-1.029h-.3v-.194h.3V287.9h.229v.358h.516v.194h-.516v1.018a.272.272,0,0,0,.294.312.4.4,0,0,0,.254-.09Z"
          transform="translate(-265.518 -280.717)"
          fill="#fff"
        />
        <path
          id="Path_134175"
          data-name="Path 134175"
          d="M325,289.727a.867.867,0,1,1,.867.867.835.835,0,0,1-.867-.867Zm1.5,0a.634.634,0,1,0-.631.663.62.62,0,0,0,.634-.663Z"
          transform="translate(-267.923 -281.333)"
          fill="#fff"
        />
        <path
          id="Path_134176"
          data-name="Path 134176"
          d="M332.706,289.593v.986h-.229v-.95a.477.477,0,0,0-.509-.545.559.559,0,0,0-.595.617v.892h-.233v-1.717h.222v.315a.717.717,0,0,1,.645-.326.649.649,0,0,1,.7.728Z"
          transform="translate(-271.864 -281.335)"
          fill="#fff"
        />
        <path
          id="Path_134177"
          data-name="Path 134177"
          d="M339.2,288.431c0-.717.376-1.151.9-1.151s.9.43.9,1.151-.38,1.151-.9,1.151S339.2,289.151,339.2,288.431Zm1.57,0c0-.606-.272-.939-.667-.939s-.667.333-.667.939.269.939.667.939.667-.333.667-.939Z"
          transform="translate(-277.035 -280.319)"
          fill="#fff"
        />
        <path
          id="Path_134178"
          data-name="Path 134178"
          d="M346.328,288.946c0,.376-.272.667-.821.667a1.179,1.179,0,0,1-.817-.29l.115-.183a1,1,0,0,0,.717.262c.358,0,.581-.176.581-.455s-.19-.452-.609-.452h-.161v-.172l.627-.785H344.8v-.208h1.434v.165l-.634.792C346.088,288.326,346.328,288.584,346.328,288.946Z"
          transform="translate(-280.557 -280.351)"
          fill="#fff"
        />
      </g>
      <g id="--button-2--inject-6" transform="translate(59.413 109.664)">
        <path
          id="Path_134179"
          data-name="Path 134179"
          d="M242.323,224.1H166.864a1.1,1.1,0,0,1-1.1-1.1V208.618a1.108,1.108,0,0,1,1.1-1.108h75.459a1.108,1.108,0,0,1,1.1,1.108v14.376a1.1,1.1,0,0,1-1.1,1.1Zm-75.459-16.247a.767.767,0,0,0-.763.767v14.376a.767.767,0,0,0,.763.763h75.459a.767.767,0,0,0,.763-.763V208.618a.767.767,0,0,0-.763-.767Z"
          transform="translate(-165.76 -207.51)"
          fill="#263238"
        />
        <g id="Group_16229" data-name="Group 16229" opacity={0.6}>
          <path
            id="Path_134180"
            data-name="Path 134180"
            d="M242.323,224.1H166.864a1.1,1.1,0,0,1-1.1-1.1V208.618a1.108,1.108,0,0,1,1.1-1.108h75.459a1.108,1.108,0,0,1,1.1,1.108v14.376a1.1,1.1,0,0,1-1.1,1.1Zm-75.459-16.247a.767.767,0,0,0-.763.767v14.376a.767.767,0,0,0,.763.763h75.459a.767.767,0,0,0,.763-.763V208.618a.767.767,0,0,0-.763-.767Z"
            transform="translate(-165.76 -207.51)"
            fill="#fff"
          />
        </g>
        <rect
          id="Rectangle_149140"
          data-name="Rectangle 149140"
          width={32.025}
          height={9.792}
          rx={2.58}
          transform="translate(3.674 3.398)"
          fill="#263238"
        />
        <rect
          id="Rectangle_149141"
          data-name="Rectangle 149141"
          width={32.025}
          height={9.792}
          rx={2.58}
          transform="translate(3.674 3.398)"
          fill="#fff"
          opacity={0.5}
        />
        <g
          id="Group_16230"
          data-name="Group 16230"
          transform="translate(12.04 6.839)"
          opacity={0.2}
        >
          <path
            id="Path_134181"
            data-name="Path 134181"
            d="M201.074,228.146a.824.824,0,0,1-.849.867.738.738,0,0,1-.656-.358v.337h-.219v-2.4h.233v1.022a.749.749,0,0,1,.645-.337.824.824,0,0,1,.846.871Zm-.229,0a.634.634,0,1,0-.634.663.624.624,0,0,0,.634-.663Z"
            transform="translate(-199.35 -226.59)"
          />
          <path
            id="Path_134182"
            data-name="Path 134182"
            d="M206.973,228.56v1.7h-.222v-.312a.67.67,0,0,1-.609.326.66.66,0,0,1-.717-.717v-.986h.229v.964a.477.477,0,0,0,.513.545.556.556,0,0,0,.581-.617v-.892Z"
            transform="translate(-203.246 -227.854)"
          />
          <path
            id="Path_134183"
            data-name="Path 134183"
            d="M211.971,229.51a.57.57,0,0,1-.358.118.452.452,0,0,1-.5-.495v-1.022h-.3v-.194h.3v-.358h.229v.358h.52v.194h-.52v1.014a.272.272,0,0,0,.294.312.384.384,0,0,0,.254-.086Z"
            transform="translate(-206.702 -227.212)"
          />
          <path
            id="Path_134184"
            data-name="Path 134184"
            d="M215.5,229.51a.552.552,0,0,1-.358.118.452.452,0,0,1-.5-.495v-1.022h-.3v-.194h.3v-.358h.229v.358h.52v.194h-.52v1.014a.272.272,0,0,0,.294.312.384.384,0,0,0,.255-.086Z"
            transform="translate(-208.967 -227.212)"
          />
          <path
            id="Path_134185"
            data-name="Path 134185"
            d="M218.1,229.377a.867.867,0,1,1,.867.867.839.839,0,0,1-.867-.867Zm1.5,0a.634.634,0,1,0-.631.663.62.62,0,0,0,.631-.663Z"
            transform="translate(-211.379 -227.822)"
          />
          <path
            id="Path_134186"
            data-name="Path 134186"
            d="M225.826,229.245v.989h-.219v-.964a.477.477,0,0,0-.513-.545.556.556,0,0,0-.6.613v.9h-.229v-1.7h.219v.312a.717.717,0,0,1,.649-.326.652.652,0,0,1,.688.728Z"
            transform="translate(-215.338 -227.825)"
          />
          <path
            id="Path_134187"
            data-name="Path 134187"
            d="M232.29,228.084c0-.717.38-1.154.9-1.154s.907.43.907,1.154-.384,1.151-.907,1.151S232.29,228.8,232.29,228.084Zm1.57,0c0-.606-.272-.939-.667-.939s-.667.333-.667.939.272.936.667.936S233.86,228.686,233.86,228.084Z"
            transform="translate(-220.483 -226.808)"
          />
          <path
            id="Path_134188"
            data-name="Path 134188"
            d="M239.6,229.006v.208h-1.588v-.168l.943-.921c.255-.251.3-.405.3-.559,0-.262-.186-.423-.53-.423a.774.774,0,0,0-.62.251l-.165-.14a1.018,1.018,0,0,1,.8-.326c.459,0,.749.233.749.613a.932.932,0,0,1-.358.717l-.771.756Z"
            transform="translate(-224.108 -226.805)"
          />
        </g>
        <path
          id="Path_134189"
          data-name="Path 134189"
          d="M179.05,222.822c1.649.3,6.986-1.581,14.577-1.581,7.412,0,12.9,1.871,14.574,1.577a.652.652,0,0,0,.538-.645v-2.151a.656.656,0,0,0-.656-.652H179.176a.656.656,0,0,0-.656.652v2.151A.656.656,0,0,0,179.05,222.822Z"
          transform="translate(-173.946 -215.119)"
          opacity={0.2}
        />
        <rect
          id="Rectangle_149142"
          data-name="Rectangle 149142"
          width={32.025}
          height={9.792}
          rx={2.58}
          transform="translate(41.735 3.398)"
          fill="#2d62ed"
        />
        <path
          id="Path_134190"
          data-name="Path 134190"
          d="M307.264,228.146a.824.824,0,0,1-.846.867.738.738,0,0,1-.659-.358v.337h-.219v-2.4h.229v1.022a.749.749,0,0,1,.649-.337.824.824,0,0,1,.846.871Zm-.229,0a.634.634,0,1,0-.634.663.624.624,0,0,0,.634-.663Z"
          transform="translate(-255.439 -219.751)"
          fill="#fff"
        />
        <path
          id="Path_134191"
          data-name="Path 134191"
          d="M313.163,228.56v1.7h-.219v-.312a.677.677,0,0,1-.613.326.66.66,0,0,1-.717-.717v-.986h.229v.964a.475.475,0,0,0,.509.545.552.552,0,0,0,.581-.617v-.892Z"
          transform="translate(-259.335 -221.015)"
          fill="#fff"
        />
        <path
          id="Path_134192"
          data-name="Path 134192"
          d="M318.161,229.51a.57.57,0,0,1-.358.118.452.452,0,0,1-.5-.495v-1.022H317v-.194h.3v-.358h.233v.358h.516v.194h-.516v1.014a.272.272,0,0,0,.294.312.38.38,0,0,0,.251-.086Z"
          transform="translate(-262.792 -220.374)"
          fill="#fff"
        />
        <path
          id="Path_134193"
          data-name="Path 134193"
          d="M321.7,229.51a.57.57,0,0,1-.358.118.452.452,0,0,1-.5-.495v-1.022h-.3v-.194h.3v-.358h.219v.358h.52v.194h-.52v1.014a.276.276,0,0,0,.3.312.38.38,0,0,0,.251-.086Z"
          transform="translate(-265.063 -220.374)"
          fill="#fff"
        />
        <path
          id="Path_134194"
          data-name="Path 134194"
          d="M324.289,229.377a.867.867,0,1,1,.867.867.839.839,0,0,1-.867-.867Zm1.5,0a.634.634,0,1,0-.631.663.62.62,0,0,0,.631-.663Z"
          transform="translate(-267.468 -220.983)"
          fill="#fff"
        />
        <path
          id="Path_134195"
          data-name="Path 134195"
          d="M332,229.242v.989h-.229v-.964a.477.477,0,0,0-.513-.545.556.556,0,0,0-.595.613v.9h-.229v-1.7h.2v.312a.717.717,0,0,1,.649-.326.652.652,0,0,1,.713.728Z"
          transform="translate(-271.408 -220.984)"
          fill="#fff"
        />
        <path
          id="Path_134196"
          data-name="Path 134196"
          d="M338.48,228.084c0-.717.38-1.154.9-1.154s.907.43.907,1.154-.383,1.151-.907,1.151S338.48,228.8,338.48,228.084Zm1.57,0c0-.606-.272-.939-.667-.939s-.667.333-.667.939.272.936.667.936S340.05,228.686,340.05,228.084Z"
          transform="translate(-276.573 -219.969)"
          fill="#fff"
        />
        <path
          id="Path_134197"
          data-name="Path 134197"
          d="M345.79,229.006v.208H344.2v-.168l.943-.921c.254-.251.3-.405.3-.559,0-.262-.186-.423-.53-.423a.774.774,0,0,0-.62.251l-.165-.14a1.022,1.022,0,0,1,.8-.326c.459,0,.753.233.753.613a.939.939,0,0,1-.358.717l-.771.756Z"
          transform="translate(-280.198 -219.967)"
          fill="#fff"
        />
        <path
          id="Path_134198"
          data-name="Path 134198"
          d="M314.4,230.055a85.413,85.413,0,0,0-14.577-1.14,87.643,87.643,0,0,0-14.574,1.143.652.652,0,0,0-.538.645v2.151a.656.656,0,0,0,.656.656h28.911a.656.656,0,0,0,.656-.656V230.7A.656.656,0,0,0,314.4,230.055Z"
          transform="translate(-242.075 -221.242)"
          opacity={0.2}
        />
      </g>
      <g id="--button-1--inject-6" transform="translate(59.413 88.036)">
        <path
          id="Path_134199"
          data-name="Path 134199"
          d="M242.323,163.754H166.864a1.1,1.1,0,0,1-1.1-1.1V148.274a1.1,1.1,0,0,1,1.1-1.1h75.459a1.1,1.1,0,0,1,1.1,1.1V162.65a1.1,1.1,0,0,1-1.1,1.1ZM166.864,147.51a.763.763,0,0,0-.763.763V162.65a.763.763,0,0,0,.763.763h75.459a.763.763,0,0,0,.763-.763V148.274a.763.763,0,0,0-.763-.763Z"
          transform="translate(-165.76 -147.17)"
          fill="#263238"
        />
        <g id="Group_16231" data-name="Group 16231" opacity={0.6}>
          <path
            id="Path_134200"
            data-name="Path 134200"
            d="M242.323,163.754H166.864a1.1,1.1,0,0,1-1.1-1.1V148.274a1.1,1.1,0,0,1,1.1-1.1h75.459a1.1,1.1,0,0,1,1.1,1.1V162.65a1.1,1.1,0,0,1-1.1,1.1ZM166.864,147.51a.763.763,0,0,0-.763.763V162.65a.763.763,0,0,0,.763.763h75.459a.763.763,0,0,0,.763-.763V148.274a.763.763,0,0,0-.763-.763Z"
            transform="translate(-165.76 -147.17)"
            fill="#fff"
          />
        </g>
        <rect
          id="Rectangle_149143"
          data-name="Rectangle 149143"
          width={32.025}
          height={9.792}
          transform="translate(3.703 3.394)"
          fill="#263238"
        />
        <rect
          id="Rectangle_149144"
          data-name="Rectangle 149144"
          width={32.025}
          height={9.792}
          transform="translate(3.703 3.394)"
          fill="#fff"
          opacity={0.5}
        />
        <g
          id="Group_16232"
          data-name="Group 16232"
          transform="translate(4.28 3.975)"
          opacity={0.2}
        >
          <path
            id="Path_134201"
            data-name="Path 134201"
            d="M208.568,166.894H177.7V158.26h30.868Zm-30.782-.086h30.7v-8.462h-30.7Z"
            transform="translate(-177.7 -158.26)"
          />
        </g>
        <g
          id="Group_16233"
          data-name="Group 16233"
          transform="translate(12.405 6.857)"
          opacity={0.2}
        >
          <path
            id="Path_134202"
            data-name="Path 134202"
            d="M202.094,167.834a.824.824,0,0,1-.849.867.738.738,0,0,1-.656-.358v.358h-.219v-2.4h.226v1.022a.738.738,0,0,1,.645-.337.824.824,0,0,1,.853.85Zm-.229,0a.634.634,0,1,0-.634.667.624.624,0,0,0,.634-.667Z"
            transform="translate(-200.37 -166.3)"
          />
          <path
            id="Path_134203"
            data-name="Path 134203"
            d="M207.993,168.21v1.717h-.222v-.312a.674.674,0,0,1-.609.326.66.66,0,0,1-.717-.717v-.986h.229v.964a.48.48,0,0,0,.513.548.556.556,0,0,0,.581-.62v-.892Z"
            transform="translate(-204.266 -167.525)"
          />
          <path
            id="Path_134204"
            data-name="Path 134204"
            d="M212.981,169.167a.534.534,0,0,1-.358.118.452.452,0,0,1-.5-.495v-1.029h-.3v-.194h.3v-.358h.229v.358h.52v.194h-.52v1.018a.272.272,0,0,0,.294.308A.384.384,0,0,0,212.9,169Z"
            transform="translate(-207.716 -166.884)"
          />
          <path
            id="Path_134205"
            data-name="Path 134205"
            d="M216.521,169.167a.534.534,0,0,1-.358.118.452.452,0,0,1-.5-.495v-1.029h-.3v-.194h.3v-.358h.229v.358h.52v.194h-.52v1.018a.272.272,0,0,0,.294.308.391.391,0,0,0,.254-.086Z"
            transform="translate(-209.987 -166.884)"
          />
          <path
            id="Path_134206"
            data-name="Path 134206"
            d="M219.119,169.027a.867.867,0,1,1,.867.867.839.839,0,0,1-.867-.867Zm1.5,0a.634.634,0,1,0-.631.667.62.62,0,0,0,.631-.667Z"
            transform="translate(-212.399 -167.493)"
          />
          <path
            id="Path_134207"
            data-name="Path 134207"
            d="M226.839,168.924v1h-.226v-.964a.477.477,0,0,0-.509-.545.559.559,0,0,0-.595.617v.892h-.229v-1.7h.219v.315a.717.717,0,0,1,.645-.33.656.656,0,0,1,.7.717Z"
            transform="translate(-216.352 -167.523)"
          />
          <path
            id="Path_134208"
            data-name="Path 134208"
            d="M233.31,167.741c0-.717.376-1.151.9-1.151s.9.43.9,1.151-.38,1.151-.9,1.151S233.31,168.461,233.31,167.741Zm1.57,0c0-.606-.272-.939-.667-.939s-.667.333-.667.939.269.939.667.939S234.88,168.346,234.88,167.741Z"
            transform="translate(-221.503 -166.486)"
          />
          <path
            id="Path_134209"
            data-name="Path 134209"
            d="M239.627,166.64v2.28h-.233v-2.057h-.534v-.208Z"
            transform="translate(-225.064 -166.518)"
          />
        </g>
        <rect
          id="Rectangle_149145"
          data-name="Rectangle 149145"
          width={32.025}
          height={9.792}
          transform="translate(41.764 3.394)"
          fill="#2d62ed"
        />
        <rect
          id="Rectangle_149146"
          data-name="Rectangle 149146"
          width={8.548}
          height={30.782}
          transform="translate(73.169 4.018) rotate(90)"
          fill="none"
          stroke="#fff"
          strokeMiterlimit={10}
          strokeWidth={0.25}
        />
        <path
          id="Path_134210"
          data-name="Path 134210"
          d="M308.284,167.834a.824.824,0,0,1-.849.867.738.738,0,0,1-.656-.358v.358h-.219v-2.4h.229v1.022a.749.749,0,0,1,.645-.337.824.824,0,0,1,.849.849Zm-.229,0a.634.634,0,1,0-.634.667.624.624,0,0,0,.634-.667Z"
          transform="translate(-256.094 -159.443)"
          fill="#fff"
        />
        <path
          id="Path_134211"
          data-name="Path 134211"
          d="M314.223,168.21v1.717H314v-.312a.67.67,0,0,1-.609.326.659.659,0,0,1-.717-.717v-.986h.229v.964a.48.48,0,0,0,.513.548.556.556,0,0,0,.581-.62v-.892Z"
          transform="translate(-260.015 -160.669)"
          fill="#fff"
        />
        <path
          id="Path_134212"
          data-name="Path 134212"
          d="M319.165,169.167a.534.534,0,0,1-.358.118.452.452,0,0,1-.5-.495v-1.029H318v-.194h.3v-.358h.229v.358h.541v.194h-.538v1.018a.272.272,0,0,0,.294.308.384.384,0,0,0,.254-.086Z"
          transform="translate(-263.433 -160.027)"
          fill="#fff"
        />
        <path
          id="Path_134213"
          data-name="Path 134213"
          d="M322.711,169.167a.534.534,0,0,1-.358.118.452.452,0,0,1-.5-.495v-1.029h-.3v-.194h.3v-.358h.215v.358h.52v.194h-.52v1.018a.272.272,0,0,0,.294.308.384.384,0,0,0,.255-.086Z"
          transform="translate(-265.711 -160.027)"
          fill="#fff"
        />
        <path
          id="Path_134214"
          data-name="Path 134214"
          d="M325.309,169.027a.867.867,0,1,1,.867.867.839.839,0,0,1-.867-.867Zm1.5,0a.634.634,0,1,0-.631.667.62.62,0,0,0,.631-.667Z"
          transform="translate(-268.122 -160.637)"
          fill="#fff"
        />
        <path
          id="Path_134215"
          data-name="Path 134215"
          d="M333.016,168.923v1h-.229v-.964a.477.477,0,0,0-.513-.545.559.559,0,0,0-.6.616v.892h-.229v-1.7h.219v.315a.717.717,0,0,1,.649-.33.652.652,0,0,1,.7.717Z"
          transform="translate(-272.062 -160.665)"
          fill="#fff"
        />
        <path
          id="Path_134216"
          data-name="Path 134216"
          d="M339.5,167.741c0-.717.38-1.151.9-1.151s.907.43.907,1.151-.384,1.147-.914,1.147S339.5,168.461,339.5,167.741Zm1.57,0c0-.606-.272-.939-.667-.939s-.667.333-.667.939.272.939.667.939S341.07,168.346,341.07,167.741Z"
          transform="translate(-277.227 -159.629)"
          fill="#fff"
        />
        <path
          id="Path_134217"
          data-name="Path 134217"
          d="M345.837,166.64v2.28H345.6v-2.057h-.534v-.208Z"
          transform="translate(-280.801 -159.661)"
          fill="#fff"
        />
      </g>
      <g id="--Character--inject-6" transform="translate(28.263 91.004)">
        <path
          id="Path_134218"
          data-name="Path 134218"
          d="M119.749,228.28l-1.595,2.3a1.864,1.864,0,0,0-.251,1.136,10.273,10.273,0,0,0,1.011,3.226,6.265,6.265,0,0,0,1.685-.817c.211-.179-.817-2.444-.885-2.509a1.542,1.542,0,0,1-.179-.358l1.484-1.86Z"
          transform="translate(-103.9 -202.176)"
          fill="#eec1bb"
        />
        <path
          id="Path_134219"
          data-name="Path 134219"
          d="M118.62,192.88c1.627.563,5.351,6.66,5.706,8.767s-3.864,6.9-3.864,6.9l-1.516-1.459a54.751,54.751,0,0,0,2.939-5.065c.036-.477-2.914-5.018-2.914-5.018Z"
          transform="translate(-104.366 -179.464)"
          fill="#2d62ed"
        />
        <path
          id="Path_134220"
          data-name="Path 134220"
          d="M124.341,201.892a4.681,4.681,0,0,1-.566,1.878c-.115.229-.237.462-.358.7-.3.52-.642,1.043-.982,1.53-.151.215-.3.419-.448.616-.434.584-.839,1.075-1.122,1.434a5.543,5.543,0,0,1-.394.466l-1.516-1.459.194-.312c.158-.247.394-.627.667-1.075.118-.2.247-.4.358-.617.219-.358.448-.738.663-1.1.129-.219.254-.43.358-.631.147-.258.28-.5.387-.717a2.739,2.739,0,0,0,.283-.642.316.316,0,0,0-.025-.122,5.8,5.8,0,0,0-.427-.878c-.108-.194-.229-.409-.358-.631-.244-.412-.513-.86-.778-1.29l-.086-.136c-.1-.168-.2-.333-.3-.491-.52-.828-.935-1.47-.935-1.47V196.8l-.086-.971-.079-.932-.176-2.075a4.387,4.387,0,0,1,1.495,1.272c.158.183.319.384.484.591.358.455.717.968,1.075,1.5.147.222.29.448.43.674.315.505.616,1.022.889,1.527a7.857,7.857,0,0,1,.358.717,13.261,13.261,0,0,1,.871,2.057,4.052,4.052,0,0,1,.1.444A1.792,1.792,0,0,1,124.341,201.892Z"
          transform="translate(-104.36 -179.425)"
          fill="#fff"
          opacity={0.7}
        />
        <g
          id="Group_16234"
          data-name="Group 16234"
          transform="translate(14.508 14.689)"
          opacity={0.2}
        >
          <path
            id="Path_134221"
            data-name="Path 134221"
            d="M121.054,197.021a11.976,11.976,0,0,1-1.638,2.115l-.086-.971a11.57,11.57,0,0,0,1.24-1.735C120.728,196.613,120.889,196.814,121.054,197.021Z"
            transform="translate(-119.33 -196.43)"
          />
          <path
            id="Path_134222"
            data-name="Path 134222"
            d="M124.413,202.944a11.735,11.735,0,0,1-1.792,2.179l-.391-.627a11.111,11.111,0,0,0,1.76-2.226C124.13,202.492,124.273,202.718,124.413,202.944Z"
            transform="translate(-121.191 -200.177)"
          />
          <path
            id="Path_134223"
            data-name="Path 134223"
            d="M127.746,209.109a11.535,11.535,0,0,1-1.918,1.875c-.108-.194-.229-.409-.358-.631a11.571,11.571,0,0,0,1.91-1.943C127.509,208.647,127.635,208.88,127.746,209.109Z"
            transform="translate(-123.269 -204.116)"
          />
          <path
            id="Path_134224"
            data-name="Path 134224"
            d="M126.94,217.559a2.739,2.739,0,0,0,.283-.642.315.315,0,0,0-.025-.122,11.469,11.469,0,0,0,2.362-.7,4.053,4.053,0,0,1,.1.444,1.791,1.791,0,0,1,.021.283A11.4,11.4,0,0,1,126.94,217.559Z"
            transform="translate(-124.212 -209.05)"
          />
          <path
            id="Path_134225"
            data-name="Path 134225"
            d="M127.771,222.568c-.115.229-.237.462-.358.7a11.14,11.14,0,0,1-2.563-.513c.129-.219.254-.43.358-.631a10.961,10.961,0,0,0,2.563.448Z"
            transform="translate(-122.871 -212.912)"
          />
          <path
            id="Path_134226"
            data-name="Path 134226"
            d="M124.55,227.893c-.151.215-.3.419-.448.617a11.532,11.532,0,0,1-2.172-.943c.118-.2.247-.4.358-.617a10.752,10.752,0,0,0,2.262.943Z"
            transform="translate(-120.998 -216.011)"
          />
          <path
            id="Path_134227"
            data-name="Path 134227"
            d="M121.44,232.945c-.244.294-.394.466-.394.466l-1.516-1.459.194-.312A10.163,10.163,0,0,0,121.44,232.945Z"
            transform="translate(-119.458 -219.02)"
          />
        </g>
        <path
          id="Path_134228"
          data-name="Path 134228"
          d="M120.545,202.677c-.1-.168-.2-.333-.3-.491-.52-.828-.935-1.47-.935-1.47v-.143l-.086-.971-.079-.932A3.543,3.543,0,0,1,120.545,202.677Z"
          transform="translate(-104.7 -183.178)"
          opacity={0.2}
        />
        <path
          id="Path_134229"
          data-name="Path 134229"
          d="M82.412,156.9a4.1,4.1,0,0,1,3.136-1.434c2.276-.158,4.828.487,6.057,3.624l-.993.24a23.258,23.258,0,0,1,.853,7.24,42.378,42.378,0,0,0-10.8,1.222,23.713,23.713,0,0,1-.215-7.419C81.018,156.69,82.412,156.9,82.412,156.9Z"
          transform="translate(-79.754 -155.449)"
          fill="#263238"
        />
        <path
          id="Path_134230"
          data-name="Path 134230"
          d="M140.542,396.127l-2.262.4-.925-3.082-.846-2.828,2.606-.914.756,3.4Z"
          transform="translate(-115.844 -305.738)"
          fill="#e4897b"
        />
        <path
          id="Path_134231"
          data-name="Path 134231"
          d="M139.893,393.205l-2.52.33-.853-2.86,2.606-.925Z"
          transform="translate(-115.85 -305.771)"
          opacity={0.2}
        />
        <path
          id="Path_134232"
          data-name="Path 134232"
          d="M100.254,398.648H97.96l-.38-3.233-.341-2.95,2.728-.455.154,3.513Z"
          transform="translate(-90.649 -307.22)"
          fill="#e4897b"
        />
        <path
          id="Path_134233"
          data-name="Path 134233"
          d="M100.122,395.568l-2.541-.115-.341-2.964,2.728-.459Z"
          transform="translate(-90.649 -307.233)"
          opacity={0.2}
        />
        <path
          id="Path_134234"
          data-name="Path 134234"
          d="M105.444,296.38l-3.717.656c-3.749-9.344-6.419-17.143-8.3-23.617-1.014-3.491-1.864-6.6-2.47-9.355l.115,10.172V297.15H87.408c-9.183-43.728-2.674-53.692-2.674-53.692l10.982-1.828a50.96,50.96,0,0,1,.939,6.169l.477,3.642,4.3,22.176Z"
          transform="translate(-81.19 -210.741)"
          fill="#2d62ed"
        />
        <path
          id="Path_134235"
          data-name="Path 134235"
          d="M108.676,296.118c-1.014-3.491-1.792-6.6-2.416-9.355a86.054,86.054,0,0,1,.717-9.753S108.575,289.591,108.676,296.118Z"
          transform="translate(-96.436 -233.439)"
          opacity={0.2}
        />
        <path
          id="Path_134236"
          data-name="Path 134236"
          d="M80.571,198.384c.43,1.384.767,2.584,1.032,3.656a39.757,39.757,0,0,1,1.194,8.244l11.2-.968a99.277,99.277,0,0,0,.043-14.4c-.222-2.308-.581-2.394-.581-2.394s-2.509-.244-4.685-.226a35.871,35.871,0,0,0-5.018.527,32.8,32.8,0,0,0-3.584.8,1.075,1.075,0,0,0-.717,1.355l.358,1.029C80.084,196.85,80.338,197.646,80.571,198.384Z"
          transform="translate(-79.21 -179.09)"
          fill="#2d62ed"
        />
        <path
          id="Path_134237"
          data-name="Path 134237"
          d="M93.972,209.272l-11.2.964c-.061-.964-.125-1.849-.215-2.731a35.7,35.7,0,0,0-.979-5.495l-.237-.925h0c-.219-.828-.484-1.731-.8-2.731l-.29-.928c-.122-.38-.251-.774-.384-1.183-.032-.09-.061-.183-.093-.276l-.358-1.029a1.075,1.075,0,0,1,.717-1.362c.946-.269,1.9-.491,2.867-.674l.717-.125a35.126,35.126,0,0,1,4.416-.5c.2,0,.409-.021.613-.029h.717c1.975.029,3.961.222,3.961.222s.358.086.581,2.4A97.024,97.024,0,0,1,93.972,209.272Z"
          transform="translate(-79.181 -179.06)"
          fill="#fff"
          opacity={0.7}
        />
        <path
          id="Path_134238"
          data-name="Path 134238"
          d="M95.45,201.7a66.564,66.564,0,0,0-13.95,1.434l.118.358c.036.108.068.211.1.319A65.632,65.632,0,0,1,95.5,202.42C95.482,202.165,95.468,201.922,95.45,201.7Z"
          transform="translate(-80.551 -185.122)"
          opacity={0.2}
        />
        <path
          id="Path_134239"
          data-name="Path 134239"
          d="M96.7,208.355c0-.247,0-.487-.021-.717a66.594,66.594,0,0,0-13.441,1.29q.108.358.2.688A66.288,66.288,0,0,1,96.7,208.355Z"
          transform="translate(-81.667 -188.931)"
          opacity={0.2}
        />
        <path
          id="Path_134240"
          data-name="Path 134240"
          d="M97.773,214.295v-.717a66.939,66.939,0,0,0-12.9,1.176c.065.24.125.47.183.7a66.1,66.1,0,0,1,12.721-1.154Z"
          transform="translate(-82.713 -192.742)"
          opacity={0.2}
        />
        <path
          id="Path_134241"
          data-name="Path 134241"
          d="M86.26,220.612c.054.237.1.47.154.717a65.951,65.951,0,0,1,12.233-1.075v-.717A66.552,66.552,0,0,0,86.26,220.612Z"
          transform="translate(-83.605 -196.565)"
          opacity={0.2}
        />
        <path
          id="Path_134242"
          data-name="Path 134242"
          d="M87.4,226.478q.057.358.108.717a65.592,65.592,0,0,1,11.828-1c0-.244,0-.484.021-.717a66.989,66.989,0,0,0-11.957,1Z"
          transform="translate(-84.336 -200.377)"
          opacity={0.2}
        />
        <path
          id="Path_134243"
          data-name="Path 134243"
          d="M88.18,232.349c0,.09.021.176.029.265l.043.452a65.763,65.763,0,0,1,11.495-.961,5.961,5.961,0,0,1,.029-.717A66.83,66.83,0,0,0,88.18,232.349Z"
          transform="translate(-84.837 -204.17)"
          opacity={0.2}
        />
        <path
          id="Path_134244"
          data-name="Path 134244"
          d="M88.74,238.978a66.225,66.225,0,0,1,11.222-.932c0-.24.029-.48.039-.717a66.915,66.915,0,0,0-11.312.928C88.708,238.494,88.726,238.734,88.74,238.978Z"
          transform="translate(-85.164 -207.981)"
          opacity={0.2}
        />
        <path
          id="Path_134245"
          data-name="Path 134245"
          d="M87.4,195.047a1.773,1.773,0,0,0,1.556-.642c1.613-.125,3.358-.2,5.19-.194-.036-.28-.075-.52-.115-.717-1.674,0-3.276.061-4.774.168a3.839,3.839,0,0,0-.061-1.434h-.24a35.873,35.873,0,0,0-5.018.527l-.717.125a2.914,2.914,0,0,0,1.1,1.315c-2.151.315-3.774.66-4.638.857l.229.685c1.007-.233,2.986-.642,5.584-.982A6.549,6.549,0,0,0,87.4,195.047Z"
          transform="translate(-79.383 -179.047)"
          opacity={0.2}
        />
        <path
          id="Path_134246"
          data-name="Path 134246"
          d="M88.1,202.35c-1.487,1.1-3.943-.029-5.857-1.265h0c-.219-.828-.484-1.731-.8-2.731l-.29-.928c-.122-.38-.251-.774-.384-1.183l.624-1.387s2.315.857,5.376,1.878a7.057,7.057,0,0,0,1.459-3.8l.828-.652c.2,0,.409-.021.613-.029h.717C90.4,194.687,90.236,200.763,88.1,202.35Z"
          transform="translate(-80.083 -179.06)"
          opacity={0.2}
        />
        <path
          id="Path_134247"
          data-name="Path 134247"
          d="M88.385,240.7l11.2-.964v1.918l-11.47,1.014Z"
          transform="translate(-84.798 -209.528)"
          fill="#263238"
        />
        <path
          id="Path_134248"
          data-name="Path 134248"
          d="M106.183,240.346a.717.717,0,0,0-.53-.179l-1.4.118a.692.692,0,0,0-.634.7v.892a.7.7,0,0,0,.7.7h.061l1.4-.122a.692.692,0,0,0,.634-.692v-.9A.717.717,0,0,0,106.183,240.346Zm-.441,1.738-1.4.125a.358.358,0,0,1-.258-.09.337.337,0,0,1-.108-.247v-.892a.337.337,0,0,1,.308-.337l1.4-.122h.029a.358.358,0,0,1,.229.09.337.337,0,0,1,.108.247v.136a.14.14,0,0,0-.054,0l-.394.029a.24.24,0,1,0,.039.477l.391-.032h.018v.294A.333.333,0,0,1,105.742,242.084Z"
          transform="translate(-94.742 -209.8)"
          fill="#fff"
        />
        <path
          id="Path_134249"
          data-name="Path 134249"
          d="M97.173,405.945c0-.237,2.95-.05,2.95-.05a9.3,9.3,0,0,0,3.584,2.258c1.771.416.774,1.47-1.19,1.47H97.639C96.241,409.623,97.115,408.433,97.173,405.945Z"
          transform="translate(-90.399 -316.084)"
          fill="#263238"
        />
        <path
          id="Path_134250"
          data-name="Path 134250"
          d="M108.065,405.682a3.658,3.658,0,0,1-1.222-.358.064.064,0,0,1-.025-.018h0a.072.072,0,0,1,0-.061c0-.029.258-.717.656-.882a.448.448,0,0,1,.384,0q.2.1.194.247c0,.154-.222.312-.459.437a1.2,1.2,0,0,1,.66.072.323.323,0,0,1,.158.2.287.287,0,0,1-.072.315.4.4,0,0,1-.272.047Zm-.953-.423c.434.186.975.358,1.111.233,0,0,.05-.039.021-.154a.161.161,0,0,0-.086-.1,1.939,1.939,0,0,0-1.047.025Zm.527-.81a.279.279,0,0,0-.118.025,1.283,1.283,0,0,0-.505.634c.437-.168.857-.394.864-.527,0-.043-.065-.082-.1-.1A.316.316,0,0,0,107.639,404.449Z"
          transform="translate(-96.79 -315.118)"
          fill="#2d62ed"
        />
        <path
          id="Path_134251"
          data-name="Path 134251"
          d="M139.173,405.945c0-.237,2.95-.05,2.95-.05a9.3,9.3,0,0,0,3.584,2.258c1.771.416.774,1.47-1.19,1.47h-4.878C138.241,409.623,139.115,408.433,139.173,405.945Z"
          transform="translate(-117.345 -316.084)"
          fill="#263238"
        />
        <path
          id="Path_134252"
          data-name="Path 134252"
          d="M150.065,405.682a3.658,3.658,0,0,1-1.222-.358.064.064,0,0,1-.025-.018h0a.072.072,0,0,1,0-.061c0-.029.258-.717.656-.882a.448.448,0,0,1,.384,0q.2.1.194.247c0,.154-.222.312-.459.437a1.2,1.2,0,0,1,.66.072.322.322,0,0,1,.158.2.287.287,0,0,1-.072.315.4.4,0,0,1-.272.047Zm-.953-.423c.434.186.975.358,1.111.233,0,0,.05-.039.021-.154a.161.161,0,0,0-.086-.1,1.939,1.939,0,0,0-1.047.025Zm.527-.81a.279.279,0,0,0-.118.025,1.283,1.283,0,0,0-.505.634c.437-.168.857-.394.864-.527,0-.043-.065-.082-.1-.1A.316.316,0,0,0,149.639,404.449Z"
          transform="translate(-123.736 -315.118)"
          fill="#2d62ed"
        />
        <path
          id="Path_134253"
          data-name="Path 134253"
          d="M95.688,188.7c-2.613-.333-3.4-1.434-3.4-1.434,1.656-1.563.28-4.276.28-4.276l3.147.588a9.072,9.072,0,0,0-.122,1.323c-.025,1.91.839,1.932.839,1.932C96.985,187.847,95.688,188.7,95.688,188.7Z"
          transform="translate(-87.473 -173.119)"
          fill="#eec1bb"
        />
        <path
          id="Path_134254"
          data-name="Path 134254"
          d="M96.217,183.528a9.071,9.071,0,0,0-.122,1.323,3.5,3.5,0,0,1-3.025-1.91Z"
          transform="translate(-87.974 -173.087)"
          opacity={0.2}
        />
        <path
          id="Path_134255"
          data-name="Path 134255"
          d="M95.134,167.536c-.025.093-.05.183-.079.269a2.464,2.464,0,0,1-2.509,1.824,4.339,4.339,0,0,1-.6-.021l-.4-.054c-4.7-.767-4.946-9.315-.4-9.574,2.735-.151,3.7,1.269,4.05,4.075A9.677,9.677,0,0,1,95.134,167.536Z"
          transform="translate(-84.64 -158.349)"
          fill="#eec1bb"
        />
        <path
          id="Path_134256"
          data-name="Path 134256"
          d="M103.34,171.24a5.834,5.834,0,0,0,.86,1.075.756.756,0,0,1-.717.358Z"
          transform="translate(-94.563 -165.58)"
          fill="#d4827d"
        />
        <path
          id="Path_134257"
          data-name="Path 134257"
          d="M97.661,170.176c0,.222-.09.416-.24.427s-.287-.161-.3-.384.09-.416.24-.43S97.643,169.95,97.661,170.176Z"
          transform="translate(-90.569 -164.648)"
          fill="#263238"
        />
        <path
          id="Path_134258"
          data-name="Path 134258"
          d="M97.62,169.429l.473-.229S97.882,169.63,97.62,169.429Z"
          transform="translate(-90.893 -164.271)"
          fill="#263238"
        />
        <path
          id="Path_134259"
          data-name="Path 134259"
          d="M105.438,169.436c0,.222-.09.416-.24.427s-.283-.161-.3-.384.09-.416.24-.43S105.42,169.21,105.438,169.436Z"
          transform="translate(-95.56 -164.174)"
          fill="#263238"
        />
        <path
          id="Path_134260"
          data-name="Path 134260"
          d="M105.39,168.689l.473-.229S105.655,168.89,105.39,168.689Z"
          transform="translate(-95.878 -163.797)"
          fill="#263238"
        />
        <path
          id="Path_134261"
          data-name="Path 134261"
          d="M96.172,165.594a1.434,1.434,0,0,0-1.172.48"
          transform="translate(-89.212 -161.956)"
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={0.725}
        />
        <path
          id="Path_134262"
          data-name="Path 134262"
          d="M103.8,164.9a1.28,1.28,0,0,1,1.1.168"
          transform="translate(-94.858 -161.474)"
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={0.725}
        />
        <path
          id="Path_134263"
          data-name="Path 134263"
          d="M86.971,162.9a8.249,8.249,0,0,0,.867-4.043s5.3-2.509,5.867,2.975c0,0,.265-4.914-4.444-4.767s-4.3,7.029-1.771,8.961C87.491,166,86.258,164.347,86.971,162.9Z"
          transform="translate(-83.205 -156.483)"
          fill="#263238"
        />
        <path
          id="Path_134264"
          data-name="Path 134264"
          d="M87.127,173.017a1.2,1.2,0,0,0-1.351-1.036c-.842.136-1.151,1.9.982,2.2C87.063,174.239,87.217,173.934,87.127,173.017Z"
          transform="translate(-82.884 -166.048)"
          fill="#eec1bb"
        />
        <path
          id="Path_134265"
          data-name="Path 134265"
          d="M99.71,176.65a1.391,1.391,0,0,0,1.3.946"
          transform="translate(-92.234 -169.051)"
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={0.5}
        />
        <path
          id="Path_134266"
          data-name="Path 134266"
          d="M103.419,181.83a2.464,2.464,0,0,1-2.509,1.824A4.872,4.872,0,0,1,103.419,181.83Z"
          transform="translate(-93.004 -172.374)"
          opacity={0.1}
        />
        <path
          id="Path_134267"
          data-name="Path 134267"
          d="M98.264,169.582a1.122,1.122,0,1,1-1.122-1.122,1.122,1.122,0,0,1,1.122,1.122Z"
          transform="translate(-89.867 -163.797)"
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={0.25}
        />
        <path
          id="Path_134268"
          data-name="Path 134268"
          d="M106.474,168.322a1.122,1.122,0,1,1-1.122-1.122,1.122,1.122,0,0,1,1.122,1.122Z"
          transform="translate(-95.134 -162.988)"
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={0.25}
        />
        <path
          id="Path_134269"
          data-name="Path 134269"
          d="M102.21,170.491s.437-.477.717-.1"
          transform="translate(-93.838 -164.943)"
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={0.25}
        />
        <line
          id="Line_363"
          data-name="Line 363"
          x1={2.466}
          y2={0.168}
          transform="translate(3.687 5.785)"
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={0.25}
        />
        <path
          id="Path_134270"
          data-name="Path 134270"
          d="M101.21,186.937l.405-2.1s-.272,0-.2-.584a4.821,4.821,0,0,1,2.1-2.237c.609-.179,1.125,2.1,1.172,2.237s-1.351,1.366-1.738,1.222l-.358,1.853Z"
          transform="translate(-93.196 -172.485)"
          fill="#eec1bb"
        />
        <path
          id="Path_134271"
          data-name="Path 134271"
          d="M79,194.139c-1.244,3.011,5.806,6.953,7.48,7.1s2.358-8.677,2.358-8.677l-1.857-.315s-.717,6.4-1.4,6.018S79.909,191.927,79,194.139Z"
          transform="translate(-78.852 -179.06)"
          fill="#2d62ed"
        />
        <path
          id="Path_134272"
          data-name="Path 134272"
          d="M88.842,192.585a4.877,4.877,0,0,1-.054.609c-.022.2-.043.448-.075.717-.061.552-.14,1.229-.247,1.946-.036.247-.072.5-.115.749-.133.806-.29,1.627-.48,2.351-.075.283-.154.548-.24.792-.312.925-.692,1.541-1.147,1.5a2.626,2.626,0,0,1-.513-.111,7.226,7.226,0,0,1-.753-.276,16.091,16.091,0,0,1-1.875-.957c-.208-.125-.419-.254-.631-.391a16,16,0,0,1-1.405-1.011c-.2-.161-.391-.326-.577-.491a8.671,8.671,0,0,1-1.176-1.3,4.489,4.489,0,0,1-.423-.717A2.233,2.233,0,0,1,79,194.144c.262-.638.864-.595,1.6-.2a6.526,6.526,0,0,1,.62.391,13.118,13.118,0,0,1,1.039.821c.183.154.358.319.545.484.405.358.8.753,1.158,1.111.186.183.358.358.527.523.3.3.566.563.763.746a2.219,2.219,0,0,0,.326.265c.237.129.477-.527.685-1.434.093-.387.176-.824.254-1.269l.129-.789c.082-.541.154-1.075.208-1.487.043-.308.072-.57.1-.749l.029-.272Z"
          transform="translate(-78.857 -179.079)"
          fill="#fff"
          opacity={0.7}
        />
        <g
          id="Group_16235"
          data-name="Group 16235"
          transform="translate(0.275 13.452)"
          opacity={0.2}
        >
          <path
            id="Path_134273"
            data-name="Path 134273"
            d="M81.713,197.358a13.456,13.456,0,0,0-1.67,2.391,4.488,4.488,0,0,1-.423-.717A13.75,13.75,0,0,1,81.093,197,6.517,6.517,0,0,1,81.713,197.358Z"
            transform="translate(-79.62 -195.559)"
          />
          <path
            id="Path_134274"
            data-name="Path 134274"
            d="M86.159,200.781a13.706,13.706,0,0,0-1.5,2.867c-.2-.161-.391-.326-.577-.491a14.2,14.2,0,0,1,1.534-2.867C85.8,200.451,85.98,200.616,86.159,200.781Z"
            transform="translate(-82.481 -197.67)"
          />
          <path
            id="Path_134275"
            data-name="Path 134275"
            d="M91.391,205.283a13.5,13.5,0,0,0-1.151,2.642c-.208-.125-.419-.255-.631-.391a13.581,13.581,0,0,1,1.254-2.774C91.051,204.943,91.23,205.118,91.391,205.283Z"
            transform="translate(-86.029 -200.538)"
          />
          <path
            id="Path_134276"
            data-name="Path 134276"
            d="M99.153,207.129c-.075.283-.154.548-.24.792a14.7,14.7,0,0,1-1.613-1.979,13.477,13.477,0,0,0-.047,3.369,7.236,7.236,0,0,1-.753-.276,13.939,13.939,0,0,1,.039-2.867,2.216,2.216,0,0,0,.326.265c.237.129.477-.527.685-1.434A13.505,13.505,0,0,0,99.153,207.129Z"
            transform="translate(-90.415 -200.692)"
          />
          <path
            id="Path_134277"
            data-name="Path 134277"
            d="M102.183,200.34c-.036.247-.072.5-.115.749a14.169,14.169,0,0,1-1.828-1.05l.129-.789A13.679,13.679,0,0,0,102.183,200.34Z"
            transform="translate(-92.849 -197.003)"
          />
          <path
            id="Path_134278"
            data-name="Path 134278"
            d="M103.108,193.643c-.021.2-.043.448-.075.717a14.119,14.119,0,0,1-1.853-.631c.043-.308.072-.57.1-.749A12.693,12.693,0,0,0,103.108,193.643Z"
            transform="translate(-93.452 -192.98)"
          />
        </g>
      </g>
    </g>
  </svg>
);

export const editPen = (classname) => (
  <svg
    fill="#000000"
    width="1.041vw"
    height="1.041vw"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    className={classname}

  >
    <path d="M22,7.24a1,1,0,0,0-.29-.71L17.47,2.29A1,1,0,0,0,16.76,2a1,1,0,0,0-.71.29L13.22,5.12h0L2.29,16.05a1,1,0,0,0-.29.71V21a1,1,0,0,0,1,1H7.24A1,1,0,0,0,8,21.71L18.87,10.78h0L21.71,8a1.19,1.19,0,0,0,.22-.33,1,1,0,0,0,0-.24.7.7,0,0,0,0-.14ZM6.83,20H4V17.17l9.93-9.93,2.83,2.83ZM18.17,8.66,15.34,5.83l1.42-1.41,2.82,2.82Z" />
  </svg>

);

export const sad = (classname) => (
  <svg
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    fill="blue"
    viewBox="0 0 490 490"
    style={{
      enableBackground: "new 0 0 490 490",
    }}
    xmlSpace="preserve"
    className={classname}

  >
    <path d="M69.086,490h351.829C459.001,490,490,459.001,490,420.914V69.086C490,30.991,459.001,0,420.914,0H69.086 C30.999,0,0,30.991,0,69.086v351.829C0,459.001,30.999,490,69.086,490z M332.349,132.647c23.551,0,42.642,19.091,42.642,42.641 c0,23.551-19.091,42.642-42.642,42.642c-23.55,0-42.641-19.091-42.641-42.642C289.708,151.738,308.799,132.647,332.349,132.647z  M370.61,339.597l-18.303,24.554c-105.797-78.91-210.188-3.26-214.584,0l-18.333-24.539 C120.646,338.684,246.196,246.787,370.61,339.597z M157.651,132.647c23.55,0,42.641,19.091,42.641,42.641 c0,23.551-19.091,42.642-42.641,42.642c-23.551,0-42.642-19.091-42.642-42.642C115.009,151.738,134.1,132.647,157.651,132.647z" />
  </svg>
);



export const dots = (classname) => {
  return (
    <svg
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      fill="#A5A5A5"
      className={classname}
    >
      <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
    </svg>
  );
};

export const star = () => (
  <svg
    width="20px"
    height="10px"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M10 1.3l2.388 6.722H18.8l-5.232 3.948 1.871 6.928L10 14.744l-5.438 4.154 1.87-6.928-5.233-3.948h6.412L10 1.3z" />
  </svg>
);
export const next = (classname) => (
  <svg
    width="18px"
    height="24px"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    className={classname}
  >
    <path
      fill="none"
      stroke="#000"
      strokeWidth={2}
      d="M2,12 L22,12 M13,3 L22,12 L13,21"
    />
  </svg>
);
export const halfStar = (className) => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path fill="none" d="M0 0h24v24H0z" />
      <path d="M12 14.656l2.817 1.72-.766-3.21 2.507-2.147-3.29-.264L12 7.708v6.948zM12 17l-5.878 3.59 1.598-6.7-5.23-4.48 6.865-.55L12 2.5l2.645 6.36 6.866.55-5.231 4.48 1.598 6.7L12 17z" />
    </g>
  </svg>
);

export const emptyStar = (className) => (
  <svg
    width="20px"
    height="20px"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M18.8 8.022h-6.413L10 1.3 7.611 8.022H1.199l5.232 3.947-1.871 6.929L10 14.744l5.438 4.154-1.869-6.929L18.8 8.022zM10 12.783l-3.014 2.5 1.243-3.562-2.851-2.3 3.522.101 1.1-4.04 1.099 4.04 3.521-.101-2.851 2.3 1.243 3.562-3.012-2.5z" />
  </svg>
);

export const doubleTick = (classname) => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    className={classname}
  >
    <g>
      <path fill="none" d="M0 0h24v24H0z" />
      <path d="M11.602 13.76l1.412 1.412 8.466-8.466 1.414 1.414-9.88 9.88-6.364-6.364 1.414-1.414 2.125 2.125 1.413 1.412zm.002-2.828l4.952-4.953 1.41 1.41-4.952 4.953-1.41-1.41zm-2.827 5.655L7.364 18 1 11.636l1.414-1.414 1.413 1.413-.001.001 4.951 4.951z" />
    </g>
  </svg>
);
export const rightAngle = (classname) => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={classname}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.96967 16.2803C9.67678 15.9874 9.67678 15.5126 9.96967 15.2197L13.1893 12L9.96967 8.78033C9.67678 8.48744 9.67678 8.01256 9.96967 7.71967C10.2626 7.42678 10.7374 7.42678 11.0303 7.71967L14.7803 11.4697C15.0732 11.7626 15.0732 12.2374 14.7803 12.5303L11.0303 16.2803C10.7374 16.5732 10.2626 16.5732 9.96967 16.2803Z"
      fill="#3A52EE"
    />
  </svg>
);

export const plus = (classname) => (
  <svg
    width="1.6vw"
    height="1.6vw"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={classname}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 10C16.5523 10 17 10.4477 17 11V15H21C21.5523 15 22 15.4477 22 16C22 16.5523 21.5523 17 21 17H17V21C17 21.5523 16.5523 22 16 22C15.4477 22 15 21.5523 15 21V17L11 17C10.4477 17 10 16.5523 10 16C10 15.4477 10.4477 15 11 15L15 15V11C15 10.4477 15.4477 10 16 10Z"
      fill="#fff"
    />
  </svg>
);

export const trash = (classname) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448 512"
    className={classname}
    width="20px"
    height="20px"
    fill="red">
    <path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z" />
  </svg>
);