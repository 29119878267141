import React, { useEffect, useState } from "react";
import InputBox from "../../../../../components/Inputbox/InputBox";
import { editPen, tickIcon, tickIcon2 } from "../../../../../helper/icons";
import "./AssignInventoryCard.scss";
import { handleFocus } from "../../../../../components/DeveloperModal/ManufacturerModal ";
let nameArr = [];

export default function AssignInventoryCard({
  CardAttributeName,
  AttributeData,
  Item,
  index,
  setAttributeData,
  AttributeName,
  errors,
  setErrors,
  attributeDataArr,
  setAttributeDataArr,
  clearError,
  setAttDataIndex,
  attDataIndex,
}) {
  const [open, setOpen] = useState(false);
  const [quantity, setQuantity] = useState(Item.Quantity);
  const printError = (id) => {
    document.getElementById(id).classList.add("error_shake", "input__error");
  };
  useEffect(() => {
    console.log("useeffect");
    setAttDataIndex(index);
  }, []);

  const validateData = () => {
    let res = false;
    for (let i = 0; i < AttributeData.length; i++) {
      if (AttributeData[i].Name === "") {
        res = true;
        printError("input_" + i);
      }
    }
    return res;
  };
  const deleteRow = (index) => {
    let newArr = [...AttributeData];
    newArr.splice(index, 1);
    setAttributeData([...newArr]);
  };
  const resetRow = (idx) => {
    // let newArr = [...AttributeData];
    // for (let i = 0; i < arr.length; i++) {
    //   if (arr[i] !== "attribute_name") {
    //     newArr[idx][arr[i]].amount = 0;
    //   }
    // }
    // setLicense([...newArr]);
  };
  return (
    <div className="assigncard_main_wrapper">
      {/* {Item["Name"] !== "" ? (
        <div
          onClick={() => {
            // deleteRow(index);
          }}
          className=""
        ></div>
      ) : (
        <div
          onClick={() => {
            // resetRow(index);
            deleteRow(index);
          }}
          className="center"
        >
          X
        </div>
      )} */}
      <div
        onClick={() => {
          // resetRow(index);
          deleteRow(index);
        }}
        className="center"
      >
        X
      </div>

      <div className="assigncard_subwrapper">
        <div className="assigncard_partition">
          <div className="assigncard_lhs">
            <p className="attName">Name</p>
            {/* <p className="attUnderName">{Item.Name}</p> */}

            <InputBox
              id={"attributeName" + index}
              // onFocus={() => handleFocus("attributeName" + index)}
              onChange={(e) => {
                let arr = [...AttributeData];
                arr[index]["Name"] = e.target.value;
                setAttributeData(arr);
              }}
              onFocus={() =>
                AttributeData.map((element, idx) => {
                  document
                    .getElementById("attributeName" + idx)
                    .classList.remove("input__error");
                })
              }
              value={Item["Name"]}
            />
          </div>
          <div className="assigncard_rhs">
            <p className="attQuantity">Quantity</p>
            <div className="assigncard_rhs_content">
              {!open && (
                <div className="edit_wrapper" onClick={() => setOpen(true)}>
                  <p title={quantity} className="attUnderQuantity">{quantity}</p>
                  {editPen("editpen")}
                </div>
              )}
              {open && (
                <div className="edit_quantity">
                  <InputBox
                    id={"quantity" + index}
                    onChange={(e) => {
                      if (!isNaN(e.target.value)) {
                      
                      let arr = [...AttributeData];
                      arr[index]["Quantity"] = Number(e.target.value);
                      // const regex = /^[0-9]*$/;
                      // if (
                      //   arr[index]["Quantity"].match(regex) ||
                      //   arr[index]["Quantity"] === ""
                      // ) {
                        setAttributeData(arr);
                      // }

                      {
                        AttributeData.map((element, idx) => {
                          document
                            .getElementById(element["Name"])
                            .classList.remove("input__error");
                        });
                      }
                    }}}
                    onFocus={() => handleFocus("quantity" + index)}
                    value={Item.Quantity}
                    children={
                      <div
                        onClick={() => {
                          setOpen(false);
                          setQuantity(Item.Quantity);
                          validateData();
                        }}
                      >
                        {tickIcon("create_license_tick")}
                      </div>
                    }
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
