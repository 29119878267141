import React from "react";
import "../ViewApp/AppDescrption.scss";
import { next } from "../../../../../helper/icons";

export default function AppDescription({ text, date, choices = ["Healthcare", "Banking"], onclick }) {

  const formatDate = (date) => {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [day, month, year].join('/');
  }

  return (
    <div>
      <div className="usecase_description_wrapper">
        <div className="usecase_about_wrapper">
        <h4 className="about">About this app</h4>
        <span onClick={onclick} className="description_next_icon">
          {" "}
          {next("next_icon")}
        </span>
        </div>
     
        <div className="decription">
          {text
            ? text
            : `No description found`}
        </div>
      </div>
      <div className="usecase_updated_wrapper">
        <h4 style={{ marginBottom: "0.5vw", fontSize: "1.042vw" }}>Updated on</h4>
        <p style={{ marginBottom: "1vw" }}>{date ? formatDate(date) : formatDate(new Date())}</p>
      </div>
      <h4 style={{ marginBottom: "0.5vw", fontSize: "1.042vw" }}>{choices.length === 1 ? "Target industry" : "Target industries"}</h4>

      <div className="usecase_targetinds_wrapper">
        {choices.map((choice) => (
          <p className="industries_tag">{choice}</p>
        ))}
      </div>
    </div>
  );
}
