import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { encryptStorage } from "./helper/storage";
import { useContext, useEffect, useState, useCallback } from "react";
import AuthLogin from "./pages/auth/login/AuthLogin";
import AuthRegister from "./pages/auth/register/AuthRegister";
import AuthRegisterDeveloper from "./pages/auth/register_developer/AuthRegisterDeveloper";
import AuthRegisterManufacturer from "./pages/auth/register_manufacturer/AuthRegisterManufacturer";
import Home from "./pages/user/Home/Home";
import Settings from "./pages/user/Settings/Settings";
import Profile from "./pages/user/Profile/Profile";
import Device from "./pages/shared/Device/Device";
// import { onMessageListener, fetchToken } from "./helper/firebase";
import DeviceDetail from "./pages/shared/DeviceDetail/DeviceDetail";
import Users from "./pages/user/Users/Users";
import SuperHome from "./pages/user/Superadmin/Home/SuperHome";
import { ThemeContext } from "./helper/context";
import RedirectPage from "./pages/Redirect";
import PageNotFound from "./pages/PageNotFound";
import Usecases from "./pages/user/Usecases/Usecases";
import React from "react";
import Cart from "./pages/user/Cart/Cart";
import SuperProfile from "./pages/user/Superadmin/Profile/SuperProfile";
import UserDetails from "./pages/user/Users/UserDetails";
import UserDetails2 from "./pages/user/Users/UserDetails2";
import { closeIcon } from "./helper/icons";
import ManufacturerUsers from "./pages/user/Manufacturer/Users/ManufacturerUsers";
import useInterval from "./helper/useInterval";
import { axiosApiInstance } from "./helper/request";
import { toast, ToastContainer } from "react-toastify";
import Inventory from "./pages/user/Inventory/Inventory";
import License from "./pages/user/License/License";
import AppVersion from "./pages/user/App/AppVersion";
import Order from "./pages/user/Order/Order";
import AddedVersion from "./pages/user/Apps/AddedVersion";
import VersionDetails from "./pages/user/Apps/VersionDetails";
import BasicDetails from "./pages/user/Apps/Component/VersionDetails/BasicDetails";
import Combpatibility from "./pages/user/Apps/Component/VersionDetails/Compatibility";
import VersionSettings from "./pages/user/Apps/Component/VersionDetails/VersionSettings";
import Apps from "./pages/user/Apps/Apps";
import AddApp from "./pages/user/Apps/AddApp";
import Usecase from "./pages/user/Apps/Usecase.js";
import AppLicense from "./pages/user/Apps/AppLicense/AppLicense";
import AppInventory from "./pages/user/Apps/AppInventory/AppInventory";
import TOS from "./pages/shared/TOS";
import Privacy from "./pages/shared/Privacy";

export default function Routes() {
  // const { toasts } = useToasterStore();
  const location = useLocation();
  const themeContext = useContext(ThemeContext);
  const [isTokenFound, setTokenFound] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [pageSize, setPageSize] = useState({
    width: 1366,
    height: 768,
  });

  const onResize = () => {
    var divWidth = window.innerWidth;
    var divHeight = window.innerHeight;
    setPageSize({
      width: divWidth,
      height: divHeight,
      realWidth: window.screen.availWidth,
      realHeight: window.screen.availHeight,
    });
  };
  // fetchToken(setTokenFound);
  // onMessageListener()
  //   .then((payload) => {
  //     // console.log(payload);
  //     // setShow(true);
  //     // setNotification({
  //     //   title: payload.notification.title,
  //     //   body: payload.notification.body,
  //     // });
  //     console.log(payload);
  //   })
  //   .catch((err) => console.log("failed: ", err));

  const [Links, setLinks] = useState({
    user: [
      <PrivateRoute path="/home" component={Home} strict exact />,
      <PrivateRoute path="/profile" component={Profile} strict exact />,
      <PrivateRoute path="/order" component={Order} strict exact />,
      // <PrivateRoute path="/device" component={Device} strict exact />,
      <PrivateRoute path="/inventory" component={Inventory} strict exact />,
      <PrivateRoute
        path="/device/detail/:idee"
        component={DeviceDetail}
        strict
        exact
      />,
      <PrivateRoute path="/apps/add" component={AddApp} strict exact />,
      <PrivateRoute path="/apps/usecase" component={Usecase} strict exact />,
      <PrivateRoute path="/apps/license" component={AppLicense} strict exact />,
      <PrivateRoute
        path="/apps/appsInventory"
        component={AppInventory}
        strict
        exact
      />,
      // <PrivateRoute
      //   path="/apps/addedversion"
      //   component={AddedVersion}
      //   strict
      //   exact
      // />,
      <PrivateRoute
        path="/apps/addedversion/versiondetails"
        component={VersionDetails}
        strict
        exact
      />,
    ],
    manufacturer: [
      <PrivateRoute path="/home" component={Home} strict exact />,
      // <PrivateRoute path="/device" component={Device} strict exact />,
      <PrivateRoute path="/profile" component={Profile} strict exact />,
      <PrivateRoute path="/apps" component={Apps} strict exact />,
      <PrivateRoute path="/apps/add" component={AddApp} strict exact />,
      <PrivateRoute path="/apps/usecase" component={Usecase} strict exact />,
      <PrivateRoute path="/apps/license" component={AppLicense} strict exact />,
      <PrivateRoute
        path="/apps/appsInventory"
        component={AppInventory}
        strict
        exact
      />,
      <PrivateRoute
        path="/apps/addedversion"
        component={AddedVersion}
        strict
        exact
      />,
      <PrivateRoute
        path="/apps/addedversion/versiondetails"
        component={VersionDetails}
        strict
        exact
      />,
      <PrivateRoute path="/users" component={ManufacturerUsers} strict exact />,
      <PrivateRoute path="/license" component={License} strict exact />,
      <PrivateRoute path="/inventory" component={Inventory} strict exact />,
      <PrivateRoute path="/apps" component={Apps} strict exact />,
      <PrivateRoute path="/apps/add" component={AddApp} strict exact />,
      // <PrivateRoute path="/apps/add/addedapps" component={AddedApps} strict exact />,
      <PrivateRoute path="/apps/usecase" component={Usecase} strict exact />,
      // <PrivateRoute
      //   path="/apps/addedversion"
      //   component={AddedVersion}
      //   strict
      //   exact
      // />,
      <PrivateRoute
        path="/apps/addedversion/versiondetails"
        component={VersionDetails}
        strict
        exact
      />,
      <PrivateRoute
        path="/device/detail/:idee"
        component={DeviceDetail}
        strict
        exact
      />,
    ],
    developer: [
      <PrivateRoute path="/home" component={Home} strict exact />,
      // <PrivateRoute path="/device" component={Device} strict exact />,
      <PrivateRoute path="/profile" component={Profile} strict exact />,
      <PrivateRoute path="/apps" component={Apps} strict exact />,
      <PrivateRoute path="/apps/add" component={AddApp} strict exact />,
      <PrivateRoute path="/apps/usecase" component={Usecase} strict exact />,
      // <PrivateRoute
      //   path="/apps/addedversion"
      //   component={AddedVersion}
      //   strict
      //   exact
      // />,
      <PrivateRoute
        path="/apps/addedversion/versiondetails"
        component={VersionDetails}
        strict
        exact
      />,

      <PrivateRoute
        path="/device/detail/:idee"
        component={DeviceDetail}
        strict
        exact
      />,
      // <PrivateRoute path="/apps" component={Apps} strict exact />,
    ],
    superadmin: [
      <PrivateRoute path="/home" component={SuperHome} strict exact />,
      // <PrivateRoute path="/device" component={Device} strict exact />,
      <PrivateRoute path="/profile" component={SuperProfile} strict exact />,
      <PrivateRoute
        path="/device/detail/:idee"
        component={DeviceDetail}
        strict
        exact
      />,
      <PrivateRoute path="/apps" component={Apps} strict exact />,
      <PrivateRoute path="/apps/add" component={AddApp} strict exact />,
      <PrivateRoute path="/apps/usecase" component={Usecase} strict exact />,
      <PrivateRoute path="/apps/license" component={AppLicense} strict exact />,
      <PrivateRoute
        path="/apps/addedversion"
        component={AddedVersion}
        strict
        exact
      />,
      <PrivateRoute
        path="/apps/addedversion/versiondetails"
        component={VersionDetails}
        strict
        exact
      />,
      <PrivateRoute path="/users" component={Users} strict exact />,
      // <PrivateRoute path="/apps" component={Apps} strict exact />,
      <PrivateRoute path="/apps/add" component={AddApp} strict exact />,
      // <PrivateRoute path="/apps/add/addedapps" component={AddedApps} strict exact />,
      <PrivateRoute path="/apps/usecase" component={Usecase} strict exact />,
      // <PrivateRoute
      //   path="/apps/addedversion"
      //   component={AddedVersion}
      //   strict
      //   exact
      // />,
      <PrivateRoute
        path="/apps/addedversion/versiondetails"
        component={VersionDetails}
        strict
        exact
      />,
      <PrivateRoute
        path="/users/:idee"
        component={UserDetails2}
        strict
        exact
      />,
    ],
    moderator: [],
  });

  const loadJWT = useCallback(async () => {
    setIsLoading(true);
    try {
      let ldata = encryptStorage.getItem("UID");
      if (ldata) {
        if (ldata.roles?.includes("superadmin")) {
          themeContext.getRequest();
        } else {
          themeContext.getCart();
        }

        themeContext.getNotification();
        themeContext.setRole({
          all: ldata.roles,
          current: ldata.current,
          pending: ldata.pending,
          rejected: ldata.rejected,
          suspended: ldata.suspended,
        });
        setIsLoading(false);
      } else {
        themeContext.setRole({
          all: null,
          current: null,
          pending: null,
        });
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      themeContext.setRole({
        all: null,
        current: null,
        pending: null,
      });
    }
  }, []);
  // useEffect(() => {
  //   toasts
  //     .filter((t) => t.visible) // Only consider visible toasts
  //     .filter((_, i) => i >= 1) // Is toast index over limit
  //     .forEach((t) => toast.dismiss(t.id)); // Dismiss – Use toast.remove(t.id) removal without animation
  // }, [toasts]);

  useEffect(() => {
    loadJWT();
  }, [loadJWT]);

  useInterval(
    async () => {
      let response = await axiosApiInstance.get("user/actions");
      if (response?.status === 200) {
        themeContext.setNotification([...response.data]);
      }
    },
    themeContext.Role.current ? 60000 : null
  );

  useInterval(
    async () => {
      let response = await axiosApiInstance.get("user/superadmin/requests");
      if (response?.status === 200) {
        themeContext.setRequest([...response.data.requests]);
      }
    },
    themeContext.Role.current === "superadmin" ? 60000 : null
  );

  if (isLoading) {
    return <React.Fragment></React.Fragment>;
  }

  return (
    <AnimatePresence exitBeforeEnter={true}>
      <ToastContainer
        hideProgressBar
        autoClose={4000}
        className="Toastify_adjust"
        limit={3}
      />
      {/* <Toaster
        containerStyle={{
          top: 120,
        }}
        // containerClassName="toast_parent_container"
        toastOptions={{
          style: {
            maxWidth: "525px",
            backgroundColor: "var(--background)",
          },
        }}
      /> */}
      <Switch location={location} key={location.pathname}>
        <PublicRoute path="/" component={AuthLogin} strict exact />
        <Route path="/register" component={AuthRegister} strict exact />
        <Route path="/tos" component={TOS} strict exact />
        <Route path="/privacy" component={Privacy} strict exact />
        <Route
          path="/register/developer"
          component={AuthRegisterDeveloper}
          strict
          exact
        />
        <Route
          path="/register/manufacturer/:token"
          component={AuthRegisterManufacturer}
          strict
          exact
        />
        {/* <Route path="/home" component={Home} strict exact /> */}
        <Route path="/superadmin/home" component={SuperHome} strict exact />
        <Route path="/cart" component={Cart} strict exact />
        <Route path="/settings" component={Settings} strict exact />
        <Route
          path="/app/version/general"
          component={AppVersion}
          strict
          exact
        />
        <Route path="/device" component={Device} strict exact />
        <Route path="/apps/serial/:id" component={Apps} strict exact />,
        <Route
          path="/apps/addedversion"
          component={AddedVersion}
          strict
          exact
        />
        ,{/* <Route path="/profile" component={Profile} strict exact />, */}
        <Route path="/redirect" component={RedirectPage} strict exact />
        {Links[themeContext.Role.current]?.map((item) => item)}
        <Route path="*" component={PageNotFound} strict exact />
      </Switch>
    </AnimatePresence>
  );
}

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      encryptStorage.getItem("UID") ? (
        Component ? (
          <Component {...props} />
        ) : (
          rest.render(props)
        )
      ) : (
        <Redirect
          to={{
            pathname: "/",
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

const PublicRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      encryptStorage.getItem("UID") ? (
        <Redirect
          to={{
            pathname: "/home",
            state: { from: props.location },
          }}
        />
      ) : (
        <Component {...props} />
      )
    }
  />
);

export const notify = ({
  type = "error",
  msg = "Server Error",
  duration = 3000,
}) =>
  toast(<div>{msg}</div>, {
    type,
  });

const tick = () => (
  <svg
    width={34}
    height={34}
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="toast_success"
  >
    <path
      d="M17 0.333328C7.80004 0.333328 0.333374 7.79999 0.333374 17C0.333374 26.2 7.80004 33.6667 17 33.6667C26.2 33.6667 33.6667 26.2 33.6667 17C33.6667 7.79999 26.2 0.333328 17 0.333328ZM13.6667 25.3333L5.33337 17L7.68337 14.65L13.6667 20.6167L26.3167 7.96666L28.6667 10.3333L13.6667 25.3333Z"
      fill="#1EC003"
    />
  </svg>
);

const error = () => (
  <svg
    width={34}
    height={34}
    viewBox="0 0 34 34"
    xmlns="http://www.w3.org/2000/svg"
    className="toast_error"
  >
    <path
      d="M12.884 2.532c-.346-.654-1.422-.654-1.768 0l-9 17A.999.999 0 0 0 3 21h18a.998.998 0 0 0 .883-1.467L12.884 2.532zM13 18h-2v-2h2v2zm-2-4V9h2l.001 5H11z"
      fill="red"
    />
  </svg>
);
