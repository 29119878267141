import "./radio.scss";

export const Radio = ({
  name,
  checked,
  onClick,
  onMouseEnter,
  onMouseLeave,
  className,
  namee,
  disabled,
  value,
  id,
  style,
}) => {
  return (
    <div
      className={className ? "checkbox_ " + className : "checkbox_"}
      style={style}
    >
      <input
        type="checkbox"
        className="styled-checkbox"
        id={id}
        checked={checked}
        onChange={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        name={namee}
        disabled={disabled}
        value={value}
      />
      <label className="label_" htmlFor={id}>
        {name}
      </label>
    </div>
  );
};

export const Radio2 = ({
  name,
  checked,
  onClick,
  onMouseEnter,
  onMouseLeave,
  className,
  namee,
  disabled,
  value,
  id,
  style,
}) => {
  return (
    <div
      className={className ? "checkbox2_ " + className : "checkbox2_"}
      style={style}
      onClick={onClick}
    >
      <div className="radio_wrapper">
        <div
          className="circle_"
          style={{ backgroundColor: checked ? "var(--primary)" : "white" }}
        />
      </div>
      <span className="radio_name">{name}</span>
    </div>
  );
};
