import React, { useContext, useEffect, useState } from "react";
import { ManufacturerRequestCard } from "../../../components/Navbar/Navbar";
import Scroller from "../../../components/Scroller/ScrollBar";
import Toggle from "../../../components/Toggle/Toggle";
import { ThemeContext } from "../../../helper/context";
import { axiosApiInstance } from "../../../helper/request";
import { timeAgo } from "../../../helper/storage";
import { notify } from "../../../Routes";
import { userIcon } from "./Users";

export default function UserProfile({ id, setProfilePic, setLoadingScreen }) {
  const { getRequest } = useContext(ThemeContext);
  // const [LoadingScreen, setLoadingScreen] = useState(false);

  const [UserData, setUserData] = useState({
    industry: "",
    email: "",
    phone: "",
    roles: "",
    full_name: "",
    profile_pic: "",
    address: "",
    created: "",
  });

  const getUser = () => {
    setLoadingScreen(true);
    axiosApiInstance
      // .get("user/superadmin/users/data?username=" + id)
      .get("user/superadmin/users/data?user_id=" + id)
      .then((res) => {
        let {
          industry,
          email,
          phone,
          roles,
          address,
          profile_pic,
          created,
          full_name,
          request_data,
        } = res.data;
        setUserData({
          industry,
          email,
          phone,
          roles,
          profile_pic,
          full_name,
          address,
          created,
          request_data,
        });
        setProfilePic(profile_pic);
      })
      .finally(() => {
        setLoadingScreen(false);
      });
  };

  const patchRole = (value, obj, item) => {
    setLoadingScreen(true);
    axiosApiInstance
      .patch("user/update/role", obj)
      .then((res) => {
        // alert("Saved");
        notify({
          type: "success",
          msg: !value ? "Unsuspended User Role" : "Supended User Role"
        })
        let _userdata = { ...UserData };
        if (!value) {
          _userdata.roles[item] = "rejected";
        } else {
          _userdata.roles[item] = "active";
        }
        setUserData({ ..._userdata });
      })
      .catch((err) => {
        console.log(err);
        let _userdata = { ...UserData };
        // if (!value) {
        //   _userdata.roles[item] = "rejected";
        // } else {
        //   _userdata.roles[item] = "active";
        // }
        setUserData({ ..._userdata });
        alert("Failed");
      })
      .finally(() => {
        setLoadingScreen(false);
      });
  };

  console.log(UserData);
  const postRequest = (status, username) => {
    axiosApiInstance
      .post("user/superadmin/requests", {
        username: id,
        status,
      })
      .then((res) => {
        getUser();
        getRequest();
        notify({
          msg: "Request accepted",
          type: "success",
        });
      })
      .catch((err) => {
        notify({
          msg: "Server Error",
          type: "error",
        });
      });
  };
  useEffect(() => {
    getUser();
  }, []);

  return (
    <div className="profile_right __details__ fadeIn">
      <div className="left">
        <Scroller autoHeightMin="59vh">
          <h1 className="user-name">{UserData.full_name}</h1>
          <p className="user-created">{UserData.created ? "Created " + timeAgo(UserData.created) : ""}</p>
          <div className="flex">
            <div className="flex_right">
              {UserData.full_name ? (
                <Info text="Name" value={UserData.full_name} icon={usernameIcon} />
              ) : (
                <Info text="Name" value={"NA"} icon={usernameIcon} />
              )}
              {UserData.industry ? (
                <Info
                  text="Industry"
                  value={UserData.industry}
                  icon={industryIcon}
                />
              ) : (
                <Info text="Industry" value={"NA"} icon={industryIcon} />
              )}
              {UserData.phone ? (
                <Info
                  text="Phone Number"
                  value={UserData.phone}
                  icon={phoneIcon}
                />
              ) : (
                <Info text="Phone Number" value={"NA"} icon={phoneIcon} />
              )}
              {UserData.email ? (
                <Info text="Email" value={UserData.email} icon={emailIcon} />
              ) : (
                <Info text="Email" value={"NA"} icon={emailIcon} />
              )}
              {UserData.address ? (
                <Info
                  text="Address"
                  value={UserData.address}
                  icon={locationIcon}
                />
              ) : (
                <Info text="Address" value={"NA"} icon={locationIcon} />
              )}
            </div>
            <div className="flex_left">
              {/* {UserData.profile_pic ? (
                <img
                  src={UserData.profile_pic}
                  alt="user_image"
                  className="profile_image"
                />
              ) : (
                userIcon()
              )} */}
              {UserData.request_data && (
                <div className="requestCard">
                  <p className="_text">Request To Become Manufacturer</p>
                  <ManufacturerRequestCard
                    name={UserData.request_data.full_name}
                    company={UserData.request_data.company_name}
                    time={UserData.request_data.created}
                    onAcceptClick={() => {
                      postRequest("accepted");
                    }}
                    onRejectClick={() => {
                      postRequest("rejected");
                    }}
                    onHover
                    message={UserData.request_data.message}
                    id={"req_message_"}
                  />
                </div>
              )}
              {UserData.roles && (
                <React.Fragment>
                  <p className="_text">Add & Suspend User Role</p>
                  <table className="role_table">
                    {Object.keys(UserData?.roles)?.map((item, idx) => {
                      if (UserData.roles[item] !== "pending") {
                        return (
                          <tr key={item}>
                            <td>{item}</td>
                            <td>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  marginTop: "0.4166666667vw",
                                }}
                              >
                                <Toggle
                                  handleValue={(value) => {
                                    let obj = {
                                      role: item,
                                      status: value ? "active" : "suspended",
                                      username: id,
                                    };
                                    patchRole(value, obj, item);
                                  }}
                                  // value={true}
                                  value={UserData.roles[item] === "active"}
                                  id={"toggle_" + idx}
                                />
                                <span
                                  style={{ marginLeft: "8px" }}
                                  className="value fadeIn"
                                >
                                  {true ? "On" : "Off"}
                                </span>
                              </div>
                            </td>
                          </tr>
                        );
                      }
                      return null;
                    })}
                  </table>
                </React.Fragment>
              )}
            </div>
          </div>
        </Scroller>
      </div>
    </div>
  );
}

const Info = ({ icon, value, text }) => (
  <div className="user-data">
    <div className="icon">{icon()}</div>
    <div className="info">
      <p className="_text">{text}</p>
      <p style={{textTransform: "capitalize"}}>{value}</p>
    </div>
  </div>
);

const industryIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={20.956}
    height={21.314}
    viewBox="0 0 20.956 21.314"
  >
    <defs>
      <linearGradient
        id="industry-gradient"
        x1={0.5}
        x2={0.5}
        y2={1}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#013aa2" />
        <stop offset={1} stopColor="#295ee6" />
      </linearGradient>
    </defs>
    <path
      id="category_FILL1_wght400_GRAD0_opsz48"
      d="M11.377,14.366a.778.778,0,0,1-.731-.427.793.793,0,0,1,.014-.841l4.6-7.362a.808.808,0,0,1,1.379,0l4.6,7.362a.793.793,0,0,1,.014.841.778.778,0,0,1-.731.427Zm10.781,12.3a4.71,4.71,0,0,1-4.8-4.8,4.71,4.71,0,0,1,4.8-4.8,4.71,4.71,0,0,1,4.8,4.8,4.71,4.71,0,0,1-4.8,4.8ZM6.827,25.975A.8.8,0,0,1,6,25.148V18.42a.8.8,0,0,1,.827-.827h6.728a.8.8,0,0,1,.827.827v6.728a.8.8,0,0,1-.827.827Z"
      transform="translate(-6 -5.35)"
      fill="url(#industry-gradient)"
    />
  </svg>
);

const phoneIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={20.078}
    height={20.078}
    viewBox="0 0 20.078 20.078"
  >
    <defs>
      <linearGradient
        id="phone-gradient"
        x1={0.5}
        x2={0.5}
        y2={1}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#013aa2" />
        <stop offset={1} stopColor="#295ee6" />
      </linearGradient>
    </defs>
    <path
      id="call_FILL1_wght400_GRAD0_opsz48"
      d="M24.823,26.078A15.042,15.042,0,0,1,18.061,24.4a22.96,22.96,0,0,1-6.037-4.35,22.96,22.96,0,0,1-4.35-6.037A15.042,15.042,0,0,1,6,7.255,1.238,1.238,0,0,1,7.255,6h3.9a.986.986,0,0,1,.683.265,1.232,1.232,0,0,1,.376.711l.753,3.514a2.006,2.006,0,0,1-.014.711,1.074,1.074,0,0,1-.293.544L9.876,14.561a23.152,23.152,0,0,0,3.5,4.518,19.342,19.342,0,0,0,4.392,3.263l2.649-2.733a1.542,1.542,0,0,1,.641-.432,1.289,1.289,0,0,1,.725-.042l3.318.725a1.218,1.218,0,0,1,.976,1.2v3.765a1.238,1.238,0,0,1-1.255,1.255Z"
      transform="translate(-6 -6)"
      fill="url(#phone-gradient)"
    />
  </svg>
);

const emailIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="20"
    height="17.999"
    viewBox="0 0 20 17.999"
  >
    <defs>
      <linearGradient
        id="email-gradient"
        x1="0.5"
        x2="0.5"
        y2="1"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#013aa2" />
        <stop offset="1" stopColor="#295ee6" />
      </linearGradient>
    </defs>
    <g
      id="Group_15435"
      data-name="Group 15435"
      transform="translate(-669 -656)"
    >
      <path
        id="Vector"
        d="M19.514,3.539A4.99,4.99,0,0,0,14.757,0h-10A4.99,4.99,0,0,0,0,3.539L9.757,8.861Z"
        transform="translate(669.243 656)"
        fill="url(#email-gradient)"
      />
      <path
        id="Vector-2"
        data-name="Vector"
        d="M10,5.315a1,1,0,0,1-.479-.122L0,0V7.315a5.006,5.006,0,0,0,5,5H15a5.006,5.006,0,0,0,5-5V0L10.479,5.193A1,1,0,0,1,10,5.315Z"
        transform="translate(669 661.684)"
        fill="url(#email-gradient)"
      />
    </g>
  </svg>
);

const locationIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={19.37}
    height={23.85}
    viewBox="0 0 19.37 23.85"
  >
    <defs>
      <linearGradient
        id="location-gradient"
        x1={0.5}
        x2={0.5}
        y2={1}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#013aa2" />
        <stop offset={1} stopColor="#295ee6" />
      </linearGradient>
    </defs>
    <path
      id="person_pin_circle_FILL1_wght400_GRAD0_opsz48"
      d="M17.685,19.436a5.49,5.49,0,0,0,2.8-.726A5.7,5.7,0,0,0,22.5,16.772a7.46,7.46,0,0,0-2.24-1.18,8.607,8.607,0,0,0-5.145,0,7.46,7.46,0,0,0-2.24,1.18,5.7,5.7,0,0,0,2.013,1.937A5.49,5.49,0,0,0,17.685,19.436Zm0-6.053a2.119,2.119,0,0,0,1.5-3.617,2.119,2.119,0,0,0-3,3A2.041,2.041,0,0,0,17.685,13.382Zm.03,14.467a1.237,1.237,0,0,1-.393-.061,1.072,1.072,0,0,1-.333-.182,38.6,38.6,0,0,1-6.749-7.355A11.649,11.649,0,0,1,8,13.927a9.363,9.363,0,0,1,2.921-7.234,9.84,9.84,0,0,1,13.529,0,9.363,9.363,0,0,1,2.921,7.234,11.649,11.649,0,0,1-2.24,6.326,38.6,38.6,0,0,1-6.749,7.355,1.122,1.122,0,0,1-.318.182,1.011,1.011,0,0,1-.348.061Z"
      transform="translate(-8 -4)"
      fill="url(#location-gradient)"
    />
  </svg>
);

const usernameIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={50}
    height={50}
    viewBox="0 0 50 50"
  >
    <defs>
      <linearGradient
        id="user-linear-gradient"
        x1={0.5}
        x2={0.5}
        y2={1}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#013aa2" />
        <stop offset={1} stopColor="#295ee6" />
      </linearGradient>
    </defs>
    <g
      id="Group_15699"
      data-name="Group 15699"
      transform="translate(-632 -471)"
    >
      <path
        id="background"
        d="M25,0A25,25,0,1,1,0,25,25,25,0,0,1,25,0Z"
        transform="translate(632 471)"
        fill="#f4f7fe"
      />
      <path
        id="mail_email_at_icon_194565"
        d="M24.725,8a16.725,16.725,0,1,0,9.132,30.738,1.621,1.621,0,0,0,.374-2.385l-.686-.838a1.614,1.614,0,0,0-2.116-.346,12.409,12.409,0,1,1-6.7-22.852c6.753,0,12.409,3.886,12.409,10.79,0,2.616-1.423,5.378-3.923,5.644-1.17-.031-1.14-.867-.909-2.025l1.58-8.168a1.619,1.619,0,0,0-1.589-1.926H29.26a.912.912,0,0,0-.906.809v.006a5.186,5.186,0,0,0-4.044-1.469c-5.03,0-9.3,4.2-9.3,10.214,0,4.4,2.481,7.14,6.474,7.14a6.824,6.824,0,0,0,5.057-2.585c.642,2.3,2.739,2.3,4.769,2.3,7.345,0,10.136-4.83,10.136-9.941C41.45,13.911,34.033,8,24.725,8ZM23.263,28.531c-1.5,0-2.433-1.054-2.433-2.75,0-3.034,2.076-4.9,3.954-4.9,1.5,0,2.4,1.028,2.4,2.75,0,3.039-2.285,4.9-3.922,4.9Z"
        transform="translate(632 471)"
        fill="url(#user-linear-gradient)"
      />
    </g>
  </svg>
);
