import React, { Component } from "react";
import "../VersionDetails/Content.scss";

export default class Content extends Component {
  render() {
    return (
      <div className="basicDetails_upper_wrapper">
        {Object.keys(this.props.contentData).map((item) => (
          <div className="content_main_wrapper">
            {" "}
            <p className="content_tag">{item.replaceAll("_", " ")}</p>
            <p className="content_subwrapper">
              {(this.props.contentData[item]?.length === 0 || this.props.contentData[item]=== undefined)
                ? "None"
                : this.props.contentData[item]}
            </p>
            {console.log(this.props.contentData[item]?.length)}
          </div>
        ))}
        
      </div>
    );
  }
}
