import React, { Fragment, useEffect, useState, useRef } from "react";
import "./marquee.scss";
const Marquee = ({
  style = {},
  className = "",
  play = true,
  pauseOnHover = false,
  pauseOnClick = false,
  direction = "left",
  speed = 20,
  delay = 0,
  loop = 0,
  gradient = true,
  gradientColor = [255, 255, 255],
  gradientWidth = 200,
  onFinish,
  onCycleComplete,
  children,
}) => {
  // React Hooks
  const [containerWidth, setContainerWidth] = useState(0);
  const [marqueeWidth, setMarqueeWidth] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isMounted, setIsMounted] = useState(false);
  const containerRef = useRef(null);
  const marqueeRef = useRef(null);
  const calculateWidth = () => {
    // Find width of container and width of marquee
    if (marqueeRef.current && containerRef.current) {
      setContainerWidth(containerRef.current.getBoundingClientRect().width);
      setMarqueeWidth(marqueeRef.current.getBoundingClientRect().width);
    }
    if (marqueeWidth < containerWidth) {
      setDuration(containerWidth / speed);
    } else {
      setDuration(marqueeWidth / speed);
    }
  };
  useEffect(() => {
    calculateWidth();
    // Rerender on window resize
    window.addEventListener("resize", calculateWidth);
    return () => {
      window.removeEventListener("resize", calculateWidth);
    };
  });
  useEffect(() => {
    setIsMounted(true);
  }, []);
  // Gradient color in an unfinished rgba format
  const rgbaGradientColor = `rgba(${gradientColor[0]}, ${gradientColor[1]}, ${gradientColor[2]}`;
  return React.createElement(
    Fragment,
    null,
    !isMounted
      ? null
      : React.createElement(
          "div",
          {
            ref: containerRef,
            style: Object.assign(Object.assign({}, style), {
              ["--pause-on-hover"]: pauseOnHover ? "paused" : "running",
              ["--pause-on-click"]: pauseOnClick ? "paused" : "running",
            }),
            className: className + " marquee-container",
          },
          gradient &&
            React.createElement("div", {
              style: {
                ["--gradient-color"]: `${rgbaGradientColor}, 1), ${rgbaGradientColor}, 0)`,
                ["--gradient-width"]:
                  typeof gradientWidth === "number"
                    ? `${gradientWidth}px`
                    : gradientWidth,
              },
              className: "overlay",
            }),
          React.createElement(
            "div",
            {
              ref: marqueeRef,
              style: {
                ["--play"]: play ? "running" : "paused",
                ["--direction"]: direction === "left" ? "normal" : "reverse",
                ["--duration"]: `${duration}s`,
                ["--delay"]: `${delay}s`,
                ["--iteration-count"]: !!loop ? `${loop}` : "infinite",
              },
              className: "marquee",
              onAnimationIteration: onCycleComplete,
              onAnimationEnd: onFinish,
            },
            children
          ),
          React.createElement(
            "div",
            {
              style: {
                ["--play"]: play ? "running" : "paused",
                ["--direction"]: direction === "left" ? "normal" : "reverse",
                ["--duration"]: `${duration}s`,
                ["--delay"]: `${delay}s`,
                ["--iteration-count"]: !!loop ? `${loop}` : "infinite",
              },
              className: "marquee marquee2",
            },
            children
          )
        )
  );
};
export default Marquee;
