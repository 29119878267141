import React, { useEffect, useState } from "react";
import Button from "../../../components/Button/Button";
import { handleFocus } from "../../../components/DeveloperModal/ManufacturerModal ";
import InputBox from "../../../components/Inputbox/InputBox";
import AnimatedModal from "../../../components/Modal/AnimatedModal";
import Scroller from "../../../components/Scroller/ScrollBar";
import { closeIcon, deleteIcon, editIcon } from "../../../helper/icons";
import { axiosApiInstance } from "../../../helper/request";
import { encryptStorage } from "../../../helper/storage";
import { useDebouncedEffect } from "../../../helper/useDebounce";
import { notify } from "../../../Routes";
let key = "markup";
let currentIndex = 0;
let editType = "Add New";
let adminData = [];
const obj = {
  attribute_name: "",
  monthly: {
    amount: "",
    markup: 0,
  },
  yearly: {
    amount: "",
    markup: 0,
  },
  quarterly: {
    amount: "",
    markup: 0,
  },
  lifetime: {
    amount: "",
    markup: 0,
  },
};
export default function AddDeviceLicence({
  AddLicence,
  setAddLicence,
  setDeleteConfirm,
}) {
  const [isLoading, setisLoading] = useState(true);
  const [isOpen, setisOpen] = useState(false);
  const [UniqueCheck, setUniqueCheck] = useState(false);
  const [Data, setData] = useState({});
  const [DisableName, setDisableName] = useState(false);

  const [Licence, setLicence] = useState([
    {
      attribute_name: "Attribute 1",
      monthly: {
        amount: 0,
        markup: 0,
      },
      yearly: {
        amount: 0,
        markup: 0,
      },
      quarterly: {
        amount: 0,
        markup: 0,
      },
      lifetime: {
        amount: 0,
        markup: 0,
      },
      one_time: {
        amount: 0,
        markup: 0,
      },
    },
    {
      attribute_name: "Attribute 2",
      monthly: {
        amount: 0,
        markup: 0,
      },
      yearly: {
        amount: 0,
        markup: 0,
      },
      quarterly: {
        amount: 0,
        markup: 0,
      },
      lifetime: {
        amount: 0,
        markup: 0,
      },
      one_time: {
        amount: 0,
        markup: 0,
      },
    },
    {
      attribute_name: "Attribute 3",
      monthly: {
        amount: 0,
        markup: 0,
      },
      yearly: {
        amount: 0,
        markup: 0,
      },
      quarterly: {
        amount: 0,
        markup: 0,
      },
      lifetime: {
        amount: 0,
        markup: 0,
      },
      one_time: {
        amount: 0,
        markup: 0,
      },
    },
    {
      attribute_name: "Attribute 4",
      monthly: {
        amount: 0,
        markup: 0,
      },
      yearly: {
        amount: 0,
        markup: 0,
      },
      quarterly: {
        amount: 0,
        markup: 0,
      },
      lifetime: {
        amount: 0,
        markup: 0,
      },
      one_time: {
        amount: 0,
        markup: 0,
      },
    },
  ]);

  useDebouncedEffect(
    () => (Data?.attribute_name ? getUnique() : undefined),
    [Data.attribute_name],
    1000
  );

  const getUnique = () => {
    if (!DisableName) {
      axiosApiInstance
        .get("pricing/unique-check/" + Data.attribute_name)
        .then((res) => {
          console.log(res);
          setUniqueCheck(res.data.details);
          if (res.data.details) {
            document
              .getElementById("attribute_name")
              .classList.add("error_shake", "input__error");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const toggleSidebar = () => {
    setisOpen(!isOpen);
    const sidebar = document.querySelector(".sidebar");
    const mainContent = document.querySelector(".mainContent");
    const btn = document.querySelector("#add_licence");
    sidebar.classList.toggle("sidebar_open");
    mainContent.classList.toggle("mainContent_small");
    setTimeout(() => {
      btn.classList.toggle("off");
    }, 500);
  };

  const printError = (id) =>
    document.getElementById(id).classList.add("error_shake", "input__error");

  const percentage = (percent, total, item) => {
    console.log(percent);
    console.log(total, "total");
    let result = Number(((percent / 100) * total).toFixed(2));
    console.log(result, "result");
    setData({
      ...Data,
      [item]: { [key]: Number(percent), amount: Number(result + total) },
    });
  };

  const validateData = () => {
    let res = false;
    Object.keys(Data).map((item) => {
      if (item === "attribute_name") {
        if (!Data[item] || UniqueCheck) {
          printError(item);
          res = true;
        }
      } else {
        console.log(Data[item][key], Data[item][key]);
        if (Data[item][key] === "") {
          res = true;
          printError(item);
        }
      }
    });
    console.log(res);
    return res;
  };

  const postData = () => {
    if (validateData()) {
      return;
    }
    console.log(validateData());
    //if add attribute then PRICING else MARKUP
    let body = {
      pricing_type: key === "markup" ? key : "price",
      pricing_details: {
        ...Data,
      },
    };
    console.log(body);
    setisLoading(true);
    axiosApiInstance
      .post("pricing/device", body)
      .then((res) => {
        getPricing();
        notify({
          msg: "New licence added successfully!",
          type: "success",
        });
      })
      .catch((err) => {
        notify({});
        console.log(err);
      })
      .finally(() => {
        setisLoading(false);
      });
  };

  const patchData = () => {
    console.log(validateData());
    //if add attribute then PRICING else MARKUP
    let body = {
      pricing_type: encryptStorage.getItem("UID")?.roles?.includes("superadmin")
        ? "price"
        : "markup",
      pricing_details: {
        ...Data,
      },
    };
    console.log(body);
    setisLoading(true);
    axiosApiInstance
      .patch("pricing/device", body)
      .then((res) => {
        getPricing();
        notify({
          msg: "Licence updated successfully!",
          type: "success",
        });
      })
      .catch((err) => {
        notify({});
        console.log(err);
      })
      .finally(() => {
        setisLoading(false);
      });
  };
  

  const getPricing = () => {
    let _lData = encryptStorage.getItem("UID");
    let { username } = _lData;
    let { roles } = _lData;
    axiosApiInstance
      .get("pricing/device")
      .then((res) => {
        setisOpen(false);
        if (roles.includes("superadmin")) {
          key = "amount";
        } else {
          let _keys = Object.keys(res.data);
          let idx = _keys.indexOf(username);
          _keys.splice(idx, 1);
          let adminName = _keys[0];
          adminData = [...res.data[adminName]];
        }
        let _data = res.data[username];
        setLicence([..._data]);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setisLoading(false);
      });
  };

  useEffect(() => {
    if (AddLicence) {
      getPricing();
    }
    return () => {
      setLicence([]);
      setisOpen(false);
      setisLoading(true);
      key = "markup";
      currentIndex = 0;
      editType = "Add New";
      adminData = [];
    };
  }, [AddLicence]);

  if (!AddLicence) {
    return <></>;
  }

  return (
    <AnimatedModal modalOpen={AddLicence} className="add_licence_modal">
      <div
        className="close_otp_modal"
        onClick={() => {
          setAddLicence(false);
        }}
      >
        {closeIcon()}
      </div>
      {isLoading && (
        <div className="s_wrapper">
          {[1, 2, 3, 4, 5, 6].map((item) => (
            <SkeletonCard />
          ))}
        </div>
      )}
      {!isLoading ? (
        <div className="flex_container">
          <div className="sidebar">
            <div className="inputs">
              <h2 className="header">{editType + " "} Licence</h2>
              <Scroller autoHeight autoHeightMin="20vw" autoHeightMax="100vw">
                <div>
                  {console.log(Data)}
                  {Object.keys(Data).map((item, idx) => (
                    <InputBox
                      key={item + Number(idx + 12)}
                      id={item}
                      header={item.replace(/_/g, " ")}
                      onChange={(e) => {
                        if (item === "attribute_name") {
                          if (key !== "markup") {
                            setData({
                              ...Data,
                              [item]: e.target.value,
                            });
                          }
                        } else {
                          if (
                            encryptStorage
                              .getItem("UID")
                              ?.roles?.includes("superadmin")
                          ) {
                            setData({
                              ...Data,
                              [item]: {
                                ...Data[item],
                                [key]: Number(
                                  e.target.value.replace(/[^0-9]/g, "")
                                ),
                              },
                            });
                          } else {
                            if (Number(e.target.value) <= 100) {
                              percentage(
                                e.target.value,
                                adminData[currentIndex][item].amount,
                                item
                              );
                            }
                          }
                        }
                      }}
                      onFocus={() => handleFocus(item)}
                      value={
                        item === "attribute_name" ? Data[item] : Data[item][key]
                      }
                      children={
                        key === "markup" &&
                        item !== "attribute_name" && (
                          <div className="markup_wrapper">
                            {Data[item]["amount"]} $
                          </div>
                        )
                      }
                      disabled={item == "attribute_name" && DisableName}
                    />
                  ))}
                </div>
              </Scroller>
            </div>
            <div className="save_cancel">
              <Button
                name={"Save"}
                type="gradient"
                onClick={() => {
                  if (editType == "Add New") {
                    postData();
                  } else {
                    setDisableName(false)
                    patchData();
                  }
                }}
              />
              <Button
                name={"Cancel"}
                onClick={() => {
                  setDisableName(false)
                  toggleSidebar();
                  setTimeout(() => {
                    setData({});
                  }, 1000);
                }}
              />
            </div>
          </div>
          <div className="mainContent">
            {Licence.length > 0 &&
              encryptStorage.getItem("UID")?.roles?.includes("superadmin") && (
                <Button
                  name="Add Licence"
                  onClick={() => {
                    editType = "Add New";
                    setData({ ...obj });
                    toggleSidebar();
                  }}
                  id={"add_licence"}
                  style={{
                    position: "absolute",
                    top: "0.4vw",
                    left: "1.2vw",
                    padding: "0.4vw",
                    width: "10vw",
                  }}
                />
              )}

            {Licence.length > 0 ? (
              <div>
                <Scroller autoHeightMax={"30vw"}>
                  <div className="preview_wrapper">
                    {Licence.map((item, idx) => (
                      <PreviewCard
                        key={item.attribute_name}
                        item={item}
                        disabled={isOpen}
                        handleEdit={() => {
                          setDisableName(true);
                          editType = "Edit";
                          currentIndex = idx;
                          setData({ ...item });
                          toggleSidebar();
                        }}
                        handleDelete={() =>
                          setDeleteConfirm(
                            getPricing,
                            true,
                            item.attribute_name
                          )
                        }
                      />
                    ))}
                  </div>
                </Scroller>
              </div>
            ) : (
              <div className="no_licence fadeIn">
                {noData()}
                {encryptStorage.getItem("UID")?.roles?.includes("superadmin") &&
                  !isOpen && (
                    <Button
                      name="Add Licence"
                      onClick={() => {
                        editType = "Add New";
                        setData({ ...obj });
                        toggleSidebar();
                      }}
                    />
                  )}
              </div>
            )}
          </div>
        </div>
      ) : null}
    </AnimatedModal>
  );
}

const PreviewCard = ({ item, handleEdit, handleDelete, disabled }) => (
  <div className="licence_preview_wrapper">
    {encryptStorage.getItem("UID").roles.includes("superadmin") &&
      deleteIcon(
        () => {
          !disabled && handleDelete();
        },
        {
          opacity: disabled ? "0.5" : null,
          cursor: disabled ? "not-allowed" : null,
        }
      )}
    {editIcon(
      () => {
        !disabled && handleEdit();
      },
      {
        opacity: disabled ? "0.5" : null,
        cursor: disabled ? "not-allowed" : null,
      }
    )}
    {Object.keys(item).map((licence, idx) => (
      <div className="licence_card" key={licence + "_"}>
        <p className="l_header">{licence.replace(/_/g, " ")}</p>
        <p className="l_data">
          {licence === "attribute_name"
            ? item[licence]
            : item[licence]["amount"]}
        </p>
      </div>
    ))}
  </div>
);

const SkeletonCard = () => {
  return (
    <div className="skeleton skeleton--card">
      <div className="skeleton--content">
        <div className="skeleton--content-wrapper">
          <div className="s_loader skeleton--title"></div>
        </div>
        <div className="skeleton--content-wrapper2">
          <div className="s_loader skeleton--line"></div>
          <div className="s_loader skeleton--line"></div>
          <div className="s_loader skeleton--line"></div>
        </div>
      </div>
    </div>
  );
};

export const noData = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={1920}
    height={1080}
    viewBox="0 0 1920 1080"
  >
    <defs>
      <clipPath id="clip-Web_1920_1">
        <rect width={1920} height={1080} />
      </clipPath>
    </defs>
    <g
      id="Web_1920_1"
      data-name="Web 1920 \u2013 1"
      clipPath="url(#clip-Web_1920_1)"
    >
      <rect width={1920} height={1080} fill="var(--background)" />
      <g
        id="_2953960-ai"
        data-name="2953960-ai"
        transform="translate(398 -29.9)"
      >
        <g id="Background_Complete" data-name="Background Complete">
          <g id="_Group_" data-name="&lt;Group&gt;">
            <path
              id="_Path_"
              data-name="&lt;Path&gt;"
              d="M1000,765.3H0v-.5H1000Z"
              fill="#ebebeb"
            />
            <path
              id="_Path_2"
              data-name="&lt;Path&gt;"
              d="M833.6,797h66.2v.5H833.6Z"
              fill="#ebebeb"
            />
            <path
              id="_Path_3"
              data-name="&lt;Path&gt;"
              d="M645.1,802.4h17.3v.5H645.1Z"
              fill="#ebebeb"
            />
            <path
              id="_Path_4"
              data-name="&lt;Path&gt;"
              d="M793.2,778.4h38.4v.5H793.2Z"
              fill="#ebebeb"
            />
            <path
              id="_Path_5"
              data-name="&lt;Path&gt;"
              d="M104.9,781.8h86.4v.5H104.9Z"
              fill="#ebebeb"
            />
            <path
              id="_Path_6"
              data-name="&lt;Path&gt;"
              d="M209.1,781.8h12.7v.5H209.1Z"
              fill="#ebebeb"
            />
            <path
              id="_Path_7"
              data-name="&lt;Path&gt;"
              d="M262.9,790.2H450.3v.5H262.9Z"
              fill="#ebebeb"
            />
            <path
              id="_Compound_Path_"
              data-name="&lt;Compound Path&gt;"
              d="M474,675.6H87.8a11.4,11.4,0,0,1-11.4-11.4V121.3a11.4,11.4,0,0,1,11.4-11.4H474a11.4,11.4,0,0,1,11.4,11.4V664.2A11.4,11.4,0,0,1,474,675.6ZM87.8,110.4a10.926,10.926,0,0,0-10.9,10.9V664.2a10.926,10.926,0,0,0,10.9,10.9H474a10.926,10.926,0,0,0,10.9-10.9V121.3A10.926,10.926,0,0,0,474,110.4Z"
              fill="#ebebeb"
              fillRule="evenodd"
              stroke="wheat"
            />
            <path
              id="_Compound_Path_2"
              data-name="&lt;Compound Path&gt;"
              d="M906.6,675.6H520.4A11.4,11.4,0,0,1,509,664.2V121.3a11.4,11.4,0,0,1,11.4-11.4H906.6A11.4,11.4,0,0,1,918,121.3V664.2A11.4,11.4,0,0,1,906.6,675.6ZM520.4,110.4a10.926,10.926,0,0,0-10.9,10.9V664.2a10.926,10.926,0,0,0,10.9,10.9H906.6a10.926,10.926,0,0,0,10.9-10.9V121.3a10.926,10.926,0,0,0-10.9-10.9Z"
              fill="#ebebeb"
              stroke="wheat"
              fillRule="evenodd"
            />
          </g>
          <g id="_Group_2" data-name="&lt;Group&gt;">
            <path
              id="_Path_8"
              data-name="&lt;Path&gt;"
              d="M579.4,167.7H854.9V348.1H579.4Z"
              fill="#e6e6e6"
            />
            <path
              id="_Path_9"
              data-name="&lt;Path&gt;"
              d="M571,167.7H851V348.1H571Z"
              fill="#f0f0f0"
            />
            <path
              id="_Path_10"
              data-name="&lt;Path&gt;"
              d="M579.4,348.1H854.9v35.4H579.4Z"
              fill="#e6e6e6"
            />
            <path
              id="_Path_11"
              data-name="&lt;Path&gt;"
              d="M557,348.1H837v35.4H557Z"
              fill="#f0f0f0"
            />
            <path
              id="_Path_12"
              data-name="&lt;Path&gt;"
              d="M582.7,336.3V179.4H839.2V336.3Z"
              fill="#fafafa"
            />
            <path
              id="_Path_13"
              data-name="&lt;Path&gt;"
              d="M730.3,336.3,697.5,179.4h51.1l32.8,156.9Z"
              fill="#fff"
            />
            <path
              id="_Path_14"
              data-name="&lt;Path&gt;"
              d="M833,323.8V187.1a.8.8,0,1,1,1.6,0V323.8a.8.8,0,1,1-1.6,0Z"
              fill="#f0f0f0"
            />
            <path
              id="_Path_15"
              data-name="&lt;Path&gt;"
              d="M699.4,336.3,666.6,179.4h19.9l32.8,156.9Z"
              fill="#fff"
            />
            <path
              id="_Path_16"
              data-name="&lt;Path&gt;"
              d="M582.7,336.3V179.4h1.5V336.3Z"
              fill="#e6e6e6"
            />
            <path
              id="_Path_17"
              data-name="&lt;Path&gt;"
              d="M569.3,184H844.8l-1,13.2H568.2Z"
              fill="#ebebeb"
              opacity={0.6}
            />
            <path
              id="_Path_18"
              data-name="&lt;Path&gt;"
              d="M569.3,205.6H844.8l-1,13.2H568.2Z"
              fill="#ebebeb"
              opacity={0.6}
            />
            <path
              id="_Path_19"
              data-name="&lt;Path&gt;"
              d="M569.3,227.2H844.8l-1,13.2H568.2Z"
              fill="#ebebeb"
              opacity={0.6}
            />
            <path
              id="_Path_20"
              data-name="&lt;Path&gt;"
              d="M569.3,248.8H844.8l-1,13.2H568.2Z"
              fill="#ebebeb"
              opacity={0.6}
            />
            <path
              id="_Path_21"
              data-name="&lt;Path&gt;"
              d="M569.3,270.4H844.8l-1,13.2H568.2Z"
              fill="#ebebeb"
              opacity={0.6}
            />
            <path
              id="_Path_22"
              data-name="&lt;Path&gt;"
              d="M569.3,292H844.8l-1,13.2H568.2Z"
              fill="#ebebeb"
              opacity={0.6}
            />
          </g>
          <g id="_Group_3" data-name="&lt;Group&gt;">
            <g id="_Group_4" data-name="&lt;Group&gt;">
              <g id="_Group_5" data-name="&lt;Group&gt;">
                <path
                  id="_Path_23"
                  data-name="&lt;Path&gt;"
                  d="M815.4,645H757.6V633.6h57.8Z"
                  fill="#e6e6e6"
                />
                <path
                  id="_Path_24"
                  data-name="&lt;Path&gt;"
                  d="M648.6,502.7h10.7V764.8H648.6Z"
                  fill="#e6e6e6"
                />
                <path
                  id="_Path_25"
                  data-name="&lt;Path&gt;"
                  d="M611.7,633.6H757.6V645H611.7Z"
                  fill="#f5f5f5"
                />
                <path
                  id="_Path_26"
                  data-name="&lt;Path&gt;"
                  d="M815.4,707.3H757.6V695.9h57.8Z"
                  fill="#e6e6e6"
                />
                <path
                  id="_Path_27"
                  data-name="&lt;Path&gt;"
                  d="M611.7,695.9H757.6v11.4H611.7Z"
                  fill="#f5f5f5"
                />
                <path
                  id="_Path_28"
                  data-name="&lt;Path&gt;"
                  d="M815.4,520.3H757.6V508.9h57.8Z"
                  fill="#e6e6e6"
                />
                <path
                  id="_Path_29"
                  data-name="&lt;Path&gt;"
                  d="M611.7,508.9H757.6v11.4H611.7Z"
                  fill="#f5f5f5"
                />
                <path
                  id="_Path_30"
                  data-name="&lt;Path&gt;"
                  d="M815.4,582.6H757.6V571.2h57.8Z"
                  fill="#e6e6e6"
                />
                <path
                  id="_Path_31"
                  data-name="&lt;Path&gt;"
                  d="M611.7,571.2H757.6v11.4H611.7Z"
                  fill="#f5f5f5"
                />
              </g>
            </g>
            <path
              id="_Path_32"
              data-name="&lt;Path&gt;"
              d="M794.5,502.7h10.7V764.8H794.5Z"
              fill="#e6e6e6"
            />
            <path
              id="_Path_33"
              data-name="&lt;Path&gt;"
              d="M746.9,502.7h10.7V764.8H746.9Z"
              fill="#f5f5f5"
            />
            <path
              id="_Path_34"
              data-name="&lt;Path&gt;"
              d="M611.7,502.7h10.6V764.8H611.7Z"
              fill="#f5f5f5"
            />
          </g>
          <g id="_Group_6" data-name="&lt;Group&gt;">
            <g id="_Group_7" data-name="&lt;Group&gt;">
              <path
                id="_Path_35"
                data-name="&lt;Path&gt;"
                d="M130.7,553H239.9V764.8H130.7Z"
                fill="#e6e6e6"
              />
              <path
                id="_Path_36"
                data-name="&lt;Path&gt;"
                d="M159.9,764.8H130.7V735.6h59.8Z"
                fill="#fafafa"
              />
              <path
                id="_Path_37"
                data-name="&lt;Path&gt;"
                d="M428.4,553H537.5V764.8H428.4Z"
                fill="#e6e6e6"
              />
              <path
                id="_Path_38"
                data-name="&lt;Path&gt;"
                d="M130.7,553H453.8V754.7H130.7Z"
                fill="#fafafa"
              />
              <path
                id="_Path_39"
                data-name="&lt;Path&gt;"
                d="M394.1,735.6h59.7v29.2H424.7Z"
                fill="#fafafa"
              />
              <path
                id="_Path_40"
                data-name="&lt;Path&gt;"
                d="M153.4,628.2H431.2v50.5H153.4Z"
                fill="#f0f0f0"
              />
              <path
                id="_Path_41"
                data-name="&lt;Path&gt;"
                d="M153.4,689.5H431.2v50.4H153.4Z"
                fill="#f0f0f0"
              />
              <path
                id="_Path_42"
                data-name="&lt;Path&gt;"
                d="M197,624.4v-.6H387.5v.6a9.155,9.155,0,0,1-9.1,9.2H206.2A9.176,9.176,0,0,1,197,624.4Z"
                fill="#fafafa"
              />
              <path
                id="_Path_43"
                data-name="&lt;Path&gt;"
                d="M153.4,566.9H431.2v50.5H153.4Z"
                fill="#f0f0f0"
              />
              <path
                id="_Path_44"
                data-name="&lt;Path&gt;"
                d="M197,563.2v-.7H387.5v.7a9.133,9.133,0,0,1-9.1,9.1H206.2A9.155,9.155,0,0,1,197,563.2Z"
                fill="#fafafa"
              />
              <path
                id="_Path_45"
                data-name="&lt;Path&gt;"
                d="M197,685.7v-.6H387.5v.6a9.155,9.155,0,0,1-9.1,9.2H206.2A9.176,9.176,0,0,1,197,685.7Z"
                fill="#fafafa"
              />
            </g>
          </g>
          <g id="_Group_8" data-name="&lt;Group&gt;">
            <path
              id="_Path_46"
              data-name="&lt;Path&gt;"
              d="M148.1,167.7H423.7V348.1H148.1Z"
              fill="#e6e6e6"
            />
            <path
              id="_Path_47"
              data-name="&lt;Path&gt;"
              d="M139.7,167.7H419.8V348.1H139.7Z"
              fill="#f0f0f0"
            />
            <path
              id="_Path_48"
              data-name="&lt;Path&gt;"
              d="M148.1,348.1H423.7v35.4H148.1Z"
              fill="#e6e6e6"
            />
            <path
              id="_Path_49"
              data-name="&lt;Path&gt;"
              d="M125.7,348.1H405.8v35.4H125.7Z"
              fill="#f0f0f0"
            />
            <path
              id="_Path_50"
              data-name="&lt;Path&gt;"
              d="M151.5,336.3V179.4H408V336.3Z"
              fill="#fafafa"
            />
            <path
              id="_Path_51"
              data-name="&lt;Path&gt;"
              d="M299,336.3,266.3,179.4h51.1l32.8,156.9Z"
              fill="#fff"
            />
            <path
              id="_Path_52"
              data-name="&lt;Path&gt;"
              d="M401.7,323.8V187.1a.789.789,0,0,1,.8-.8.817.817,0,0,1,.9.8V323.8a.817.817,0,0,1-.9.8A.789.789,0,0,1,401.7,323.8Z"
              fill="#f0f0f0"
            />
            <path
              id="_Path_53"
              data-name="&lt;Path&gt;"
              d="M268.1,336.3,235.4,179.4h19.9l32.8,156.9Z"
              fill="#fff"
            />
            <path
              id="_Path_54"
              data-name="&lt;Path&gt;"
              d="M151.5,336.3V179.4H153V336.3Z"
              fill="#e6e6e6"
            />
            <path
              id="_Path_55"
              data-name="&lt;Path&gt;"
              d="M138,184H413.6l-1.1,13.2H136.9Z"
              fill="#ebebeb"
              opacity={0.6}
            />
            <path
              id="_Path_56"
              data-name="&lt;Path&gt;"
              d="M138,191.4H413.6l-1.1,13.2H136.9Z"
              fill="#ebebeb"
              opacity={0.6}
            />
            <path
              id="_Path_57"
              data-name="&lt;Path&gt;"
              d="M138,198.9H413.6L412.5,212H136.9Z"
              fill="#ebebeb"
              opacity={0.6}
            />
            <path
              id="_Path_58"
              data-name="&lt;Path&gt;"
              d="M138,206.3H413.6l-1.1,13.2H136.9Z"
              fill="#ebebeb"
              opacity={0.6}
            />
            <path
              id="_Path_59"
              data-name="&lt;Path&gt;"
              d="M138,213.7H413.6l-1.1,13.2H136.9Z"
              fill="#ebebeb"
              opacity={0.6}
            />
            <path
              id="_Path_60"
              data-name="&lt;Path&gt;"
              d="M138,221.2H413.6l-1.1,13.1H136.9Z"
              fill="#ebebeb"
              opacity={0.6}
            />
          </g>
          <g id="_Group_9" data-name="&lt;Group&gt;">
            <path
              id="_Path_61"
              data-name="&lt;Path&gt;"
              d="M192,440.4h9.6V546.5H192Z"
              fill="#f5f5f5"
            />
            <path
              id="_Path_62"
              data-name="&lt;Path&gt;"
              d="M193.3,440.3h2.6V546.5h-2.6Z"
              fill="#fafafa"
            />
            <path
              id="_Path_63"
              data-name="&lt;Path&gt;"
              d="M197.1,440.3h1V546.5h-1Z"
              fill="#fafafa"
            />
            <path
              id="_Path_64"
              data-name="&lt;Path&gt;"
              d="M161.5,553a8.92,8.92,0,0,1,8.9-8.9h52.8a8.92,8.92,0,0,1,8.9,8.9Z"
              fill="#f0f0f0"
            />
            <path
              id="_Path_65"
              data-name="&lt;Path&gt;"
              d="M176.9,503.5V433.9a2.775,2.775,0,0,1,2.8-2.7,2.689,2.689,0,0,1,2.7,2.7v69.6a2.775,2.775,0,0,1-2.7,2.8A2.862,2.862,0,0,1,176.9,503.5Z"
              fill="#f0f0f0"
            />
            <path
              id="_Path_66"
              data-name="&lt;Path&gt;"
              d="M164,406h65.6l9.8,59.6H154.1Z"
              fill="#e0e0e0"
            />
          </g>
        </g>
        <g id="Shadow">
          <path
            id="_Path_130_"
            data-name="&lt;Path&gt; 130 "
            d="M887.8,832.5c0,12.5-173.6,22.6-387.8,22.6S112.2,845,112.2,832.5,285.8,809.8,500,809.8,887.8,820,887.8,832.5Z"
            fill="#f5f5f5"
          />
        </g>
        <g id="Window_No_Data" data-name="Window No Data">
          <g id="_Group_10" data-name="&lt;Group&gt;">
            <g id="_Group_11" data-name="&lt;Group&gt;">
              <path
                id="_Path_67"
                data-name="&lt;Path&gt;"
                d="M208.9,441.7l-2.7-36.8,2-.2,2.7,36.9Z"
                fill="#407bff"
              />
              <path
                id="_Path_68"
                data-name="&lt;Path&gt;"
                d="M211.3,474.7l-1-13.9,2-.2,1,13.9Z"
                fill="#407bff"
              />
              <path
                id="_Path_69"
                data-name="&lt;Path&gt;"
                d="M675.4,630.3H247.1a19.283,19.283,0,0,1-18.9-17.6l-21-292.3a16.206,16.206,0,0,1,16.3-17.6H651.8a19.363,19.363,0,0,1,18.9,17.6l21.1,292.3A16.231,16.231,0,0,1,675.4,630.3Z"
                fill="#407bff"
              />
              <g id="_Group_12" data-name="&lt;Group&gt;">
                <g id="_Group_13" data-name="&lt;Group&gt;">
                  <path
                    id="_Path_70"
                    data-name="&lt;Path&gt;"
                    d="M677.1,630.3H248.7a19.262,19.262,0,0,1-18.8-17.6L208.8,320.4a16.284,16.284,0,0,1,16.4-17.6H653.5a19.363,19.363,0,0,1,18.9,17.6l21,292.3A16.154,16.154,0,0,1,677.1,630.3Z"
                    fill="#407bff"
                  />
                  <path
                    id="_Path_71"
                    data-name="&lt;Path&gt;"
                    d="M677.1,630.3H248.7a19.262,19.262,0,0,1-18.8-17.6L208.8,320.4a16.284,16.284,0,0,1,16.4-17.6H653.5a19.363,19.363,0,0,1,18.9,17.6l21,292.3A16.154,16.154,0,0,1,677.1,630.3Z"
                    fill="#fff"
                    opacity={0.5}
                  />
                </g>
                <path
                  id="_Path_72"
                  data-name="&lt;Path&gt;"
                  d="M655.6,311.7c11,.9,11.2,17.6.1,17.6h-429c-11,0-13.3-16.7-2.3-17.6a9.885,9.885,0,0,1,1.4-.1H654.1a12.128,12.128,0,0,1,1.5.1Z"
                  fill="#407bff"
                />
                <path
                  id="_Path_73"
                  data-name="&lt;Path&gt;"
                  d="M237,320.4a3.814,3.814,0,0,1-3.8,4.1,4.363,4.363,0,0,1-4.3-4.1,3.65,3.65,0,0,1,3.7-4,4.446,4.446,0,0,1,4.4,4Z"
                  fill="#fafafa"
                />
                <path
                  id="_Path_74"
                  data-name="&lt;Path&gt;"
                  d="M250.7,320.4a3.7,3.7,0,0,1-3.8,4.1,4.435,4.435,0,0,1-4.3-4.1,3.727,3.727,0,0,1,3.8-4A4.424,4.424,0,0,1,250.7,320.4Z"
                  fill="#fafafa"
                />
                <path
                  id="_Path_75"
                  data-name="&lt;Path&gt;"
                  d="M264.4,320.4a3.682,3.682,0,0,1-3.7,4.1,4.363,4.363,0,0,1-4.3-4.1,3.65,3.65,0,0,1,3.7-4A4.35,4.35,0,0,1,264.4,320.4Z"
                  fill="#fafafa"
                />
              </g>
              <path
                id="_Path_76"
                data-name="&lt;Path&gt;"
                d="M665.7,601.2H255.9a6.944,6.944,0,0,1-6.8-6.4L231.8,354.4a5.8,5.8,0,0,1,5.9-6.4H647.5a6.944,6.944,0,0,1,6.8,6.4l17.3,240.4a5.858,5.858,0,0,1-5.9,6.4Z"
                fill="#fff"
              />
            </g>
            <g id="_Group_14" data-name="&lt;Group&gt;">
              <g id="_Group_15" data-name="&lt;Group&gt;">
                <g id="_Group_16" data-name="&lt;Group&gt;">
                  <g id="_Group_17" data-name="&lt;Group&gt;">
                    <path
                      id="_Path_77"
                      data-name="&lt;Path&gt;"
                      d="M418.1,509.6l-7.5-104.8h55.6L486.4,417l6.7,92.6Z"
                      fill="#fff"
                    />
                    <path
                      id="_Compound_Path_3"
                      data-name="&lt;Compound Path&gt;"
                      d="M493.1,510.6h-75a1.063,1.063,0,0,1-1-.9l-7.5-104.8a1.136,1.136,0,0,1,.3-.8.779.779,0,0,1,.7-.3h55.6a.972.972,0,0,1,.5.2l20.2,12.1a1.374,1.374,0,0,1,.5.8l6.6,92.6a.893.893,0,0,1-.9,1.1Zm-74.1-2h73l-6.5-91.1-19.6-11.7H411.6Z"
                      fill="#407bff"
                      fillRule="evenodd"
                    />
                  </g>
                  <g id="_Group_18" data-name="&lt;Group&gt;">
                    <path
                      id="_Path_78"
                      data-name="&lt;Path&gt;"
                      d="M473.6,420.9l-7.4-16.1L486.4,417Z"
                      fill="#ebebeb"
                    />
                    <path
                      id="_Compound_Path_4"
                      data-name="&lt;Compound Path&gt;"
                      d="M473.6,421.9a.961.961,0,0,1-.9-.6l-7.4-16.1a.955.955,0,0,1,1.4-1.2l20.2,12.1a1.231,1.231,0,0,1,.5,1,1.143,1.143,0,0,1-.7.8l-12.8,3.9a.767.767,0,0,1-.3.1Zm-5.2-14.7,5.7,12.5,9.9-3Z"
                      fill="#407bff"
                      fillRule="evenodd"
                    />
                  </g>
                </g>
                <g id="_Group_19" data-name="&lt;Group&gt;">
                  <path
                    id="_Path_79"
                    data-name="&lt;Path&gt;"
                    d="M441.9,452a3.86,3.86,0,0,1-3.9,4.2,4.565,4.565,0,0,1-4.5-4.2,3.86,3.86,0,0,1,3.9-4.2A4.565,4.565,0,0,1,441.9,452Z"
                    fill="#407bff"
                  />
                  <path
                    id="_Path_80"
                    data-name="&lt;Path&gt;"
                    d="M469.4,452a3.86,3.86,0,0,1-3.9,4.2,4.661,4.661,0,0,1-4.6-4.2,3.938,3.938,0,0,1,4-4.2A4.639,4.639,0,0,1,469.4,452Z"
                    fill="#407bff"
                  />
                  <path
                    id="_Path_81"
                    data-name="&lt;Path&gt;"
                    d="M477.2,479.5a.987.987,0,0,1-1-.9c-.5-6.8-11.2-12.4-23.8-12.4-8.4,0-15.9,2.5-19.6,6.5a7.524,7.524,0,0,0-2.3,5.7,1.088,1.088,0,0,1-.9,1.1.987.987,0,0,1-1-.9,9.459,9.459,0,0,1,2.8-7.2c4-4.4,12.1-7.1,21-7.1,13.9,0,25.2,6.2,25.7,14.1a.945.945,0,0,1-.9,1.1h0Z"
                    fill="#407bff"
                  />
                </g>
                <path
                  id="_Path_82"
                  data-name="&lt;Path&gt;"
                  d="M426.3,443.4a.945.945,0,0,1-.7-1.6l4.3-4.6a.958.958,0,0,1,1.4,1.3l-4.3,4.6a.779.779,0,0,1-.7.3Z"
                  fill="#407bff"
                />
                <path
                  id="_Path_83"
                  data-name="&lt;Path&gt;"
                  d="M475.2,443.4a1.232,1.232,0,0,1-.7-.2l-4.9-4.6a.967.967,0,0,1,0-1.4.853.853,0,0,1,1.3,0l4.9,4.6a.935.935,0,0,1,.1,1.3,1.08,1.08,0,0,1-.7.3Z"
                  fill="#407bff"
                />
              </g>
            </g>
            <text
              id="NO_LICENCE"
              data-name="NO LICENCE"
              transform="translate(370 543.9)"
              fill="#407bff"
              fontSize={20}
              fontFamily="Arial-BoldMT, Arial"
              fontWeight={700}
              letterSpacing="0.009em"
            >
              <tspan x={0} y={0}>
                {"NO LICENCE FOUND"}
              </tspan>
            </text>
          </g>
        </g>
        <g id="Character">
          <g id="_Group_20" data-name="&lt;Group&gt;">
            <path
              id="_Path_84"
              data-name="&lt;Path&gt;"
              d="M707.4,346.2c1.8-.9,3.9-2.1,5.9-3.2q3-1.6,5.9-3.4c3.9-2.4,7.9-4.8,11.6-7.5a154.3,154.3,0,0,0,21.1-17.3c.8-.7,1.5-1.6,2.3-2.4l1.1-1.2.6-.6.3-.3c.1-.1,0-.1,0-.1l-.1.1c-.2.6,0,.4.1-.1a10.584,10.584,0,0,0,.3-2.2,59.734,59.734,0,0,0-1.5-12.6,281.542,281.542,0,0,0-7.1-27.3l7.8-3.4a170.865,170.865,0,0,1,12.2,27.2,62.164,62.164,0,0,1,3.4,15.8,28.206,28.206,0,0,1-.1,5,15.159,15.159,0,0,1-2.5,6.6l-.3.5-.3.3-.3.4-.6.7-1.2,1.5c-.9,1-1.7,2.1-2.5,3A145.957,145.957,0,0,1,740.7,346c-4.1,3-8.2,5.8-12.6,8.4q-3.2,1.9-6.5,3.7c-2.3,1.3-4.4,2.4-7,3.6Z"
              fill="#ffb573"
            />
            <path
              id="_Compound_Path_5"
              data-name="&lt;Compound Path&gt;"
              d="M686.4,815.5a2.544,2.544,0,0,1-.7-2.1,1.253,1.253,0,0,1,.8-1.1c2-.9,7.1,2.4,7.6,2.7a.4.4,0,0,1,.2.4c0,.2-.1.3-.3.4a27.525,27.525,0,0,1-4.4.6,5.075,5.075,0,0,1-3.2-.9Zm.5-2.5c-.2,0-.3.2-.4.5a1.621,1.621,0,0,0,.4,1.4c.9.8,3.1.9,6,.3C690.8,813.9,687.9,812.5,686.9,813Z"
              fill="#407bff"
              fillRule="evenodd"
            />
            <path
              id="_Compound_Path_6"
              data-name="&lt;Compound Path&gt;"
              d="M693.7,815.7c-1.6-.9-4.6-4.5-4.2-6.4,0-.4.3-1,1.3-1.1a2.33,2.33,0,0,1,1.7.5c1.8,1.6,1.8,6.5,1.8,6.7a.32.32,0,0,1-.2.3.25.25,0,0,1-.4,0Zm-2.8-6.7a.682.682,0,0,0-.7.5c-.2,1.1,1.7,3.8,3.3,5.1-.1-1.6-.5-4.3-1.6-5.3a1.081,1.081,0,0,0-.8-.3Z"
              fill="#407bff"
              fillRule="evenodd"
            />
            <path
              id="_Path_85"
              data-name="&lt;Path&gt;"
              d="M692.4,296.1c-1.9,10-6,30.1.9,36.7,0,0-2.7,10.1-21.1,10.1-20.4,0-9.7-10.1-9.7-10.1,11-2.6,10.7-10.8,8.8-18.6Z"
              fill="#ffb573"
            />
            <path
              id="_Path_86"
              data-name="&lt;Path&gt;"
              d="M658.6,336.8c-3.2.5-.5-7.8.8-8.6,3-2,41.7-4.8,41.4-.1-.1,2-1.1,6-2.8,7.3s-11.6-2.7-39.4,1.4Z"
              fill="#263238"
            />
            <path
              id="_Path_87"
              data-name="&lt;Path&gt;"
              d="M664.9,334.1c-2.6.8-2.3-7.5-1.5-8.5,2-2.3,33.4-10.4,34.3-5.8.4,2,.5,5.9-.5,7.4C696.1,328.7,686.9,326.2,664.9,334.1Z"
              fill="#263238"
            />
            <path
              id="_Path_88"
              data-name="&lt;Path&gt;"
              d="M653.2,278.2a.713.713,0,0,1-.6-.3,6.436,6.436,0,0,0-5.2-2.4.876.876,0,0,1-.9-.7.808.808,0,0,1,.7-.9,7.861,7.861,0,0,1,6.6,3,.772.772,0,0,1-.1,1.1.678.678,0,0,1-.5.2Z"
              fill="#263238"
            />
            <path
              id="_Path_89"
              data-name="&lt;Path&gt;"
              d="M650.3,297.5s-3,1-4.9-.4c2.2-3.1,3.9-9.1,3.9-9.1Z"
              fill="#ff5652"
            />
            <path
              id="_Path_90"
              data-name="&lt;Path&gt;"
              d="M650.3,285.6c.2,1.3-.5,2.5-1.3,2.6-.9.1-1.7-1-1.9-2.3-.1-1.4.5-2.5,1.4-2.6S650.2,284.2,650.3,285.6Z"
              fill="#263238"
            />
            <path
              id="_Path_91"
              data-name="&lt;Path&gt;"
              d="M648.9,283.3l-3.3-.6S647.5,285.1,648.9,283.3Z"
              fill="#263238"
            />
            <path
              id="_Path_92"
              data-name="&lt;Path&gt;"
              d="M713.4,815.4H696.6l1.4-38.8h16.7Z"
              fill="#ffb573"
            />
            <path
              id="_Path_93"
              data-name="&lt;Path&gt;"
              d="M695.3,813.4h18.8a1.4,1.4,0,0,1,1.4,1.2l2.1,14.9a2.625,2.625,0,0,1-2.7,2.9c-6.5-.1-9.7-.5-18-.5-5.1,0-12.5.6-19.5.6-6.9,0-7.4-7-4.5-7.6,13.1-2.8,15.2-6.7,19.6-10.4a4.3,4.3,0,0,1,2.8-1.1Z"
              fill="#263238"
            />
            <g id="_Group_21" data-name="&lt;Group&gt;" opacity={0.2}>
              <path
                id="_Path_94"
                data-name="&lt;Path&gt;"
                d="M714,796.6H697.3l.7-20h16.7Z"
              />
            </g>
            <path
              id="_Path_95"
              data-name="&lt;Path&gt;"
              d="M646.7,356.1c-10.3-4.1-20.2-8.5-30.1-13.5-9.8-5.1-19.5-10.4-28.8-17.6a63.421,63.421,0,0,1-6.8-6.1c-.6-.6-1.1-1.2-1.7-1.9-.5-.7-1-1.1-1.7-2.2a16.115,16.115,0,0,1-2.7-7.2,15.64,15.64,0,0,1,1-7.3,19.824,19.824,0,0,1,2.8-5.2,34.93,34.93,0,0,1,6.8-7,86,86,0,0,1,14.6-9.4q3.7-1.9,7.5-3.6c2.6-1.1,5.1-2.1,7.9-3l3.5,7.7c-8.7,5.5-17.8,11.5-24.4,18.3a27.325,27.325,0,0,0-3.9,5c-1,1.5-.9,2.7-.8,2.6.1,0,.1,0,.3.2l.8.9,1.1,1.1a63.715,63.715,0,0,0,5.4,4.3,116.637,116.637,0,0,0,12.9,7.9q6.7,3.8,13.8,7.2c9.5,4.5,19.2,8.9,28.8,12.8Z"
              fill="#ffb573"
            />
            <path
              id="_Path_96"
              data-name="&lt;Path&gt;"
              d="M614.9,281.2c-4.7-5.5-.8-17.3-.8-17.3l12.6,4.4-2.8,6A12.049,12.049,0,0,1,614.9,281.2Z"
              fill="#ffb573"
            />
            <path
              id="_Path_97"
              data-name="&lt;Path&gt;"
              d="M626.7,268.3l-12.6-4.4,2.5-11.5,10.1,2.9Z"
              fill="#ffb573"
            />
            <path
              id="_Path_98"
              data-name="&lt;Path&gt;"
              d="M751.9,273.1c-6.9-2-6.7-15.2-6.7-15.2l13-3.1-1.5,14.1Z"
              fill="#ffb573"
            />
            <path
              id="_Path_99"
              data-name="&lt;Path&gt;"
              d="M758.2,254.8l-13,3.1-1.7-8.9,8.4-4Z"
              fill="#ffb573"
            />
            <path
              id="_Path_100"
              data-name="&lt;Path&gt;"
              d="M695.1,276.5c.6,16.6,1.2,23.6-6.3,32.9-11.3,14-31.8,11.1-37.4-5-5-14.4-5.2-39.2,10.4-47.3C677,249,694.5,259.9,695.1,276.5Z"
              fill="#ffb573"
            />
            <g id="_Group_22" data-name="&lt;Group&gt;">
              <path
                id="_Path_101"
                data-name="&lt;Path&gt;"
                d="M660.9,286.7c-7.9-8.6-23.1-23.2-7-36.7,9.3-7.8,24.7-.9,30.9-5.6,6.2-4.6,21.2-2.4,25.6,19.9,4.5,23.2-6.2,38.1-22.6,45.1C671.8,316.3,665.6,296.4,660.9,286.7Z"
                fill="#263238"
              />
              <path
                id="_Path_102"
                data-name="&lt;Path&gt;"
                d="M684.7,236.4c7.5-5.2,18.1-2.9,23.6,5.1,5.5,7.9,3.9,18.6-3.7,23.8-7.5,5.2-18,2.9-23.5-5-5.5-8-3.9-18.7,3.6-23.9Z"
                fill="#407bff"
              />
              <path
                id="_Path_103"
                data-name="&lt;Path&gt;"
                d="M685.3,246c-2.3-13.4,10.3-23.7,27.3-18.7,16.9,5,8,20,4.5,32s5.6,23.4,9.4,14.3c3.9-9.2-2.6-12-2.6-12s18,4.7,1.3,25-31.8-2.1-27.7-15.4c3.3-10.6-10-12.5-12.2-25.2Z"
                fill="#263238"
              />
              <path
                id="_Path_104"
                data-name="&lt;Path&gt;"
                d="M660.7,271.8l-22.8-1.5s-1.4-8.1,2.2-14c7.4-12.5,20.5-9.3,28.3-5.2Z"
                fill="#263238"
              />
              <path
                id="_Path_105"
                data-name="&lt;Path&gt;"
                d="M635.2,266.4h0a.634.634,0,0,1-.5-.6c0-.3.6-8.4,5.5-13.4,11.7-12.2,25.5-2.1,29.4,1.3a.483.483,0,0,1,0,.7c-.1.2-.5.2-.7.1-3.7-3.3-16.9-13-28-1.4-4.6,4.8-5.2,12.7-5.2,12.8A.472.472,0,0,1,635.2,266.4Z"
                fill="#263238"
              />
            </g>
            <path
              id="_Path_106"
              data-name="&lt;Path&gt;"
              d="M663.7,286.1a13.726,13.726,0,0,1-3.3,8.5c-2.8,3.3-5.9,1.7-6.7-1.9-.6-3.2,0-8.8,3.5-10.8,3.5-1.9,6.5.5,6.5,4.2Z"
              fill="#ffb573"
            />
            <g id="_Group_23" data-name="&lt;Group&gt;">
              <path
                id="_Path_107"
                data-name="&lt;Path&gt;"
                d="M710.2,438.2c21.8,30.4-5.2,54.9,5.2,185.9,4.2,51.8,1.9,168.6,1.9,168.6H694.5s-15.1-121-23.2-173.4c-10.1-64.8-11.2-181.1-11.2-181.1Z"
                fill="#263238"
              />
              <path
                id="_Path_108"
                data-name="&lt;Path&gt;"
                d="M710.2,438.2c21.8,30.4-5.2,54.9,5.2,185.9,4.2,51.8,1.9,168.6,1.9,168.6H694.5s-15.1-121-23.2-173.4c-10.1-64.8-11.2-181.1-11.2-181.1Z"
                fill="#fff"
                opacity={0.1}
              />
            </g>
            <path
              id="_Path_109"
              data-name="&lt;Path&gt;"
              d="M672.1,491c8,35.1,1.6,90.4-2.7,114.7-4.7-36.9-7-84.8-8.2-119.9,4.2-6.8,8.1-7.2,10.9,5.2Z"
              opacity={0.3}
            />
            <path
              id="_Path_110"
              data-name="&lt;Path&gt;"
              d="M691.2,781.9,722,783l-1.5,10.2H691.4Z"
              fill="#407bff"
            />
            <g id="_Group_24" data-name="&lt;Group&gt;">
              <path
                id="_Path_111"
                data-name="&lt;Path&gt;"
                d="M699.7,340.1c2.7-5.5,17.4-8.9,25.5-8.9l6,26.8s-16,23.7-22.7,21.2C700.6,376.2,693.8,351.7,699.7,340.1Z"
                fill="#407bff"
              />
              <path
                id="_Path_112"
                data-name="&lt;Path&gt;"
                d="M699.7,340.1c2.7-5.5,17.4-8.9,25.5-8.9l6,26.8s-16,23.7-22.7,21.2C700.6,376.2,693.8,351.7,699.7,340.1Z"
                opacity={0.4}
              />
            </g>
            <path
              id="_Compound_Path_7"
              data-name="&lt;Compound Path&gt;"
              d="M682.7,786.7c-1.9,2-5.4,5.4-7.8,5.1a2.4,2.4,0,0,1-1.8-1.1,1.481,1.481,0,0,1,0-1.3c1-2.1,8.4-3.2,9.2-3.4a.52.52,0,0,1,.4.2.425.425,0,0,1,0,.4v.1Zm-8.9,3.1a.644.644,0,0,0,0,.5,1.223,1.223,0,0,0,1.1.7c1.4.2,3.7-1.3,6.4-4-3,.5-6.8,1.6-7.5,2.7Z"
              fill="#407bff"
              fillRule="evenodd"
            />
            <path
              id="_Compound_Path_8"
              data-name="&lt;Compound Path&gt;"
              d="M682.4,786.8c-2.1.5-7.4-.1-8.3-1.9-.2-.4-.3-1,.6-1.7a2.554,2.554,0,0,1,2.1-.8c2.8.2,5.7,3.6,5.9,3.8q.1.1,0,.3v.1a.32.32,0,0,1-.3.2Zm-7.6-2.2c.5,1,4.4,1.7,6.8,1.5-1-.9-3-2.8-4.8-2.9a2.021,2.021,0,0,0-1.6.5,2.986,2.986,0,0,1-.3.4.523.523,0,0,0-.1.5Z"
              fill="#407bff"
              fillRule="evenodd"
            />
            <path
              id="_Path_113"
              data-name="&lt;Path&gt;"
              d="M700.2,779.5l-15.4,6.8L678,772.1l-8.9-18.6-1.1-2.1,15.4-6.9,1.1,2.5,8.7,18Z"
              fill="#ffb573"
            />
            <path
              id="_Path_114"
              data-name="&lt;Path&gt;"
              d="M693.2,765,678,772.1l-8.9-18.6,15.4-6.5Z"
              opacity={0.2}
            />
            <g id="_Group_25" data-name="&lt;Group&gt;">
              <path
                id="_Path_115"
                data-name="&lt;Path&gt;"
                d="M693.2,438.2c0,53.8-44.5,124.4-37.6,174.5,3.6,25.7,36.4,144.1,36.4,144.1L671.5,767s-53.1-98-65-149.8c-13.3-57.3,35.6-179,35.6-179Z"
                fill="#263238"
              />
              <path
                id="_Path_116"
                data-name="&lt;Path&gt;"
                d="M693.2,438.2c0,53.8-44.5,124.4-37.6,174.5,3.6,25.7,36.4,144.1,36.4,144.1L671.5,767s-53.1-98-65-149.8c-13.3-57.3,35.6-179,35.6-179Z"
                fill="#fff"
                opacity={0.1}
              />
            </g>
            <path
              id="_Path_117"
              data-name="&lt;Path&gt;"
              d="M682.1,784.2l15.2-11.1a1.377,1.377,0,0,1,1.8.2L709.6,784a2.681,2.681,0,0,1-.4,4c-5.4,3.8-8.2,5.4-14.9,10.2-4.1,3-12.3,9.6-18,13.8-5.6,4-10.1-1.3-8.1-3.5,9-10,10.9-16.2,12.3-21.8a4.208,4.208,0,0,1,1.6-2.5Z"
              fill="#263238"
            />
            <path
              id="_Path_118"
              data-name="&lt;Path&gt;"
              d="M664.9,761.4l28.3-12.9,3.4,10.8-26.5,12.1Z"
              fill="#407bff"
            />
            <g id="_Group_26" data-name="&lt;Group&gt;">
              <path
                id="_Path_119"
                data-name="&lt;Path&gt;"
                d="M642.5,376.8c-6.8,1.9-22.5-20.6-22.5-20.6l4.2-31.6c9.6,2.2,28.7,8.4,30.9,14.1,4.7,12.2-4.4,35.9-12.6,38.1Z"
                fill="#407bff"
              />
              <path
                id="_Path_120"
                data-name="&lt;Path&gt;"
                d="M642.5,376.8c-6.8,1.9-22.5-20.6-22.5-20.6l4.2-31.6c9.6,2.2,28.7,8.4,30.9,14.1,4.7,12.2-4.4,35.9-12.6,38.1Z"
                opacity={0.4}
              />
            </g>
            <g id="_Group_27" data-name="&lt;Group&gt;">
              <path
                id="_Path_121"
                data-name="&lt;Path&gt;"
                d="M662.5,332.8c10-.9,19.4-.5,30.8,0a199.014,199.014,0,0,1,28.9,3.8c-13.2,56.9-13.2,74-12,101.6H642.1c-15.9-98.3-8-101.1-8-101.1s15.1-3,28.4-4.3Z"
                fill="#407bff"
              />
              <path
                id="_Path_122"
                data-name="&lt;Path&gt;"
                d="M662.5,332.8c10-.9,19.4-.5,30.8,0a199.014,199.014,0,0,1,28.9,3.8c-13.2,56.9-13.2,74-12,101.6H642.1c-15.9-98.3-8-101.1-8-101.1s15.1-3,28.4-4.3Z"
                opacity={0.4}
              />
            </g>
            <g id="_Group_28" data-name="&lt;Group&gt;">
              <path
                id="_Path_123"
                data-name="&lt;Path&gt;"
                d="M711.2,434.3l3.1,6c.2.5-.4,1-1.1,1H641.8a1.175,1.175,0,0,1-1.2-.7l-.6-6.1c0-.4.5-.8,1.2-.8h68.9A1.149,1.149,0,0,1,711.2,434.3Z"
                fill="#407bff"
              />
              <path
                id="_Path_124"
                data-name="&lt;Path&gt;"
                d="M711.2,434.3l3.1,6c.2.5-.4,1-1.1,1H641.8a1.175,1.175,0,0,1-1.2-.7l-.6-6.1c0-.4.5-.8,1.2-.8h68.9A1.149,1.149,0,0,1,711.2,434.3Z"
                fill="#fff"
                opacity={0.3}
              />
            </g>
            <path
              id="_Path_125"
              data-name="&lt;Path&gt;"
              d="M701.2,441.5l-.8-7.9c-.1-.2.2-.4.6-.4h1.8c.4,0,.7.2.7.4l.9,7.9c0,.3-.2.5-.6.5h-1.9A.752.752,0,0,1,701.2,441.5Z"
              fill="#263238"
            />
            <path
              id="_Path_126"
              data-name="&lt;Path&gt;"
              d="M656.5,441.5l-.9-7.9c0-.2.3-.4.7-.4h1.8c.4,0,.7.2.7.4l.9,7.9a.56.56,0,0,1-.6.5h-1.9C656.8,442,656.5,441.8,656.5,441.5Z"
              fill="#263238"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
