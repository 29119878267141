import axios from "axios";
import React, { useEffect, useState } from "react";
import Button from "../../../components/Button/Button";
import AnimatedModal from "../../../components/Modal/AnimatedModal";
import MultiSelectDropdown from "../../../components/MultiSelectDropdown/MultiSelectDropdown";
import Pagination from "../../../components/Pagination/Pagination";
import Table from "../../../components/Table/Table";
import { deleteIcon } from "../../../helper/icons";
import { axiosApiInstance } from "../../../helper/request";
import { encryptStorage, isSuspended } from "../../../helper/storage";
import { notify } from "../../../Routes";
import { LoadingText } from "../../auth/register/AuthRegister";
import Dropdown from "../../../components/Dropdown/Dropdown";

export default function UserInfo({
  id,
  handleModal,
  getUserAPI,
  handleDelete,
  handlePermission,
  Theme,
}) {
  const [Data, setData] = useState([]);
  const [CurrentPage, setCurrentPage] = useState(1);
  const [TotalPages, setTotalPages] = useState(0);
  const [Owner, setOwner] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [defaultText, setDefaultText] = useState("");
  const [Header, setHeader] = useState([
    "User Name",
    "Email",
    "User Type",
    "Action",
  ]);

  const getUsers = (page = 1) => {
    setIsLoading(true);
    axiosApiInstance
      .get("device/fetch/users?page=" + page + "&items=10", {
        headers: {
          "serial-number": id,
        },
      })
      .then((res) => {
        // let _data = [...res.data.users];
        // for (let i = 0; i < _data.length; i++) {
        //   let arr = [];
        //   if (_data[i].permissions.includes("canView")) {
        //     arr.push({ name: "Viewer", id: "canView" });
        //     setDefaultText("Viewer")
        //     // arr={ name: "Viewer", id: "canView" }
        //   }
        //   if (_data[i].permissions.includes("canEdit")) {
        //     arr.push({ name: "Editor", id: "canEdit" });
        //     setDefaultText("Editor")
        //     // arr={ name: "Editer", id: "canEdit" }
        //   }
        //   _data[i].permissions = [...arr];
        // }
        let _totalPages = Math.ceil(res.data.total_count / 10);
        setTotalPages(_totalPages);
        setData([...res.data.users]);
        setOwner(res.data.owner_name || "");
        if (res.data.owner_name !== encryptStorage.getItem("UID")?.username) {
          Header.splice(3, 1);
        } else {
          if (isSuspended()) {
            Header.splice(3, 1);
          }
        }
      })
      .catch((err) => {
        notify();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const patchPermission = (data, idx) => {
    // let permissions = Data[idx].permissions.map((item) => item.id);
    let permissions = data;
    // let index = permissions.indexOf(data.id);
    // if (index >= 0) {
    //   permissions.splice(index, 1);
    // } else {
    // permissions = [...permissions, data.id];
    // }
    // permissions = permissions;
    // permissions = [...new Set(permissions)];
    let body = {
      username: Data[idx].user.username,
      // permissions,
    };
    axiosApiInstance
      .patch("device/operator/permission", body, {
        headers: {
          "serial-number": id,
        },
      })
      .then((res) => {
        let _data = [...Data];
        let myArr = null;
        let obj = Data[idx].permissions.find((o) => o.name === data.name);
        if (obj) {
          myArr = _data[idx].permissions.filter(
            (obj) => obj.name !== data.name
          );
        } else {
          myArr = [..._data[idx].permissions, { ...data }];
        }
        _data[idx].permissions = [...myArr];
        console.log(myArr, "myArr");
        // setData([..._data]);
        notify({
          type: "success",
          msg: "Updated Successfully!",
        });
      })
      .catch((err) => {
        notify();
      });
  };
  useEffect(() => {
    getUserAPI(getUsers);
    getUsers();
  }, []);

  const renderTableData = () => {
    return Data?.map((item, idx) => {
      const temp = item.permissions;
      return (
        <tr
          className={
            encryptStorage.getItem("UID")?.username !== Owner ? "block" : null
          }
          key={item.user.username}
        >
          <td>
            {console.log(item)}
            <div className="usernameImage">
              <img
                alt={item.user.username}
                className="user_avatar"
                src={
                  "https://source.unsplash.com/random/200x200?sig=" +
                  Number(idx + 1)
                }
              />
              <p
                style={{
                  color: "var(--label)",
                  // textDecoration: "underline",
                }}
              >
                {item.user.username}
              </p>
            </div>
          </td>
          <td>{item.user.email}</td>
          <td>
            <div className="usertype">
              {item.user_type === "operator" ||
              item.user_type === "Web Developer"
                ? operatorIcon()
                : ownerIcon()}
              <p className="type">{item.user_type}</p>
            </div>
          </td>
          {encryptStorage.getItem("UID")?.username === Owner &&
            !isSuspended() && (
              <td>
                {item.user_type !== "owner" ? (
                  <React.Fragment>
                    {/* <MultiSelectDropdown
                    optionsList={[
                      {
                        name: "Viewer",
                        id: "canView",
                      },
                      {
                        name: "Editor",
                        id: "canEdit",
                      },
                    ]}
                    label=""
                    id={"Action_" + idx}
                    className="adjust_mdd"
                    defaultText={item.permissions}
                    handleObjectOption={(data) => {
                      patchPermission(data, idx);
                    }}
                    isObject
                  /> */}
                    {item.user_type !== "owner" &&
                    encryptStorage.getItem("UID").current !== "developer"
                      ? deleteIcon(() =>
                          handleDelete({
                            username: item.user.username,
                            "serial-number": id,
                          })
                        )
                      : "NA"}
                    {/* <Dropdown
                      optionsList={["Viewer", "Editor"]}
                      label=""
                      id={"Action_" + idx}
                      className="adjust_mdd"
                      defaultText={
                        item?.permissions === "canView" ? "Viewer" : "Editor"
                      }
                      handleOption={(data) => {
                        patchPermission(
                          data === "Viewer" ? "canView" : "canEdit",
                          idx
                        );
                      }}
                    /> */}

                    {/* {item.user_type !== "owner" &&
                      encryptStorage.getItem("UID").current !== "developer" &&
                      deleteIcon(() =>
                        handleDelete({
                          username: item.user.username,
                          "serial-number": id,
                        })
                      )} */}
                  </React.Fragment>
                ) : (
                  "NA"
                )}
              </td>
            )}
        </tr>
      );
    });
  };
  return (
    <div className="profile_right __userinfo__ fadeIn">
      <div className="device_header">
        <div />
        <div className="flex_">
          {encryptStorage.getItem("UID")?.username === Owner &&
            encryptStorage.getItem("UID").current !== "developer" &&
            !isSuspended() && (
              <Button
                disabled={isLoading}
                name={"Add Operator"}
                onClick={() => handleModal()}
              />
            )}

          <div className="device_count">{Data?.length}</div>
          <p className="device_count_text">Users</p>
        </div>
      </div>
      {isLoading ? (
        <div className="loader__">
          <LoadingText />
        </div>
      ) : Data.length > 0 ? (
        <Table
          className="adjust_table"
          header={Header}
          tableData={renderTableData}
        />
      ) : (
        <div className="loader__">
          <p className="noData">NO USER FOUND</p>
        </div>
      )}

      {/* Remove pagination if Totalpage==1 */}
      {Data.length > 0 && TotalPages > 1 && (
        <div className="userinfo_bottom">
          <div />
          <Pagination
            // style={{ position: "absolute", bottom: "1vw" }}
            totPages={TotalPages}
            currentPage={CurrentPage}
            rowCount={10}
            pageClicked={(ele, count_) => {
              // isAllSelected = false;
              // setselectedRow([]);
              setCurrentPage(ele);
              getUsers(ele);
              // if (type === "all") {
              //   getUnfilteredData(ele, count_);
              // }
              // if (type === "filter" || type === "search") {
              //   postFilter(ele, count_);
              // }
            }}
          />
        </div>
      )}
    </div>
  );
}

const operatorIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={30}
    height={30}
    viewBox="0 0 30 30"
    style={{
      width: "1.6625vw",
      height: "1.6625vw",
      zIndex: 1,
    }}
  >
    <path
      id="Path_58703"
      data-name="Path 58703"
      d="M15,0A15,15,0,1,1,0,15,15,15,0,0,1,15,0Z"
      fill="#2de0ed"
    />
    <path
      id="drive_file_rename_outline_FILL0_wght400_GRAD0_opsz48"
      d="M10.375,20.246,13.217,17.4h5.6a1.421,1.421,0,1,1,0,2.842ZM5.218,19.028h.893l8.994-8.994-.893-.893L5.218,18.134ZM17.684,9.161l-2.6-2.6.853-.853a1.137,1.137,0,0,1,.863-.335,1.206,1.206,0,0,1,.863.355l.873.873a1.226,1.226,0,0,1,0,1.705ZM4.609,20.246A.59.59,0,0,1,4,19.637V17.891a.6.6,0,0,1,.041-.223.619.619,0,0,1,.142-.2l10.05-10.05,2.6,2.6L6.781,20.063a.619.619,0,0,1-.2.142.6.6,0,0,1-.223.041ZM14.659,9.587l-.447-.447.893.893Z"
      transform="translate(3 1.625)"
      fill="#fff"
    />
  </svg>
);

const ownerIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={30}
    height={30}
    viewBox="0 0 30 30"
    style={{
      width: "1.6625vw",
      height: "1.6625vw",
      zIndex: 1,
    }}
  >
    <defs>
      <linearGradient
        id="owner-linear-gradient"
        x1={0.108}
        x2={0.802}
        y2={1}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#2d62ed" />
        <stop offset={1} stopColor="#013aa2" />
      </linearGradient>
    </defs>
    <circle
      id="Ellipse_18439"
      data-name="Ellipse 18439"
      cx={15}
      cy={15}
      r={15}
      fill="url(#owner-linear-gradient)"
    />
    <g id="ic-users-confirm" transform="translate(7 6)">
      <path
        id="Path_395"
        data-name="Path 395"
        d="M3,19.042l.635-2.316C4.689,12.922,7.463,11,10.238,11"
        transform="translate(-3 -2.942)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      />
      <circle
        id="Ellipse_131"
        data-name="Ellipse 131"
        cx={4.021}
        cy={4.021}
        r={4.021}
        transform="translate(3.217)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="bevel"
        strokeWidth={1.5}
      />
      <circle
        id="Ellipse_132"
        data-name="Ellipse 132"
        cx={4.021}
        cy={4.021}
        r={4.021}
        transform="translate(7.238 9.666)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      />
      <path
        id="Path_396"
        data-name="Path 396"
        d="M14.87,17.841l1.166,1.552,2.581-2.823"
        transform="translate(-5.324 -4.033)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
      />
    </g>
  </svg>
);
