import { useEffect } from "react";

export const useDebouncedEffect = (effect, deps, delay) => {
  useEffect(() => {
    const handler = setTimeout(() => effect(), delay);

    return () => clearTimeout(handler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...(deps || []), delay]);
};

// export function useDebouncedValue(value, wait) {
//   const [debouncedValue, setDebouncedValue] = useState(value);

//     useEffect(() => {
//        const id = setTimeout(() => setDebouncedValue(value), wait);
//        return () => clearTimeout(id);
//     }, [value]);
//   return debouncedValue;

//   }
