import axios from "axios";
import { encryptStorage } from "./storage";
// import socketio from "socket.io-client";

let host = window.location.host;
export const API_URL = "http://" + host + "/api/v1/";
export const SOCKET_URL = "http://" + host;

// export const API_URL = "http://182.70.113.98:8000/api/v1/";
// export const API_URL = "http://192.168.1.132:8000/api/v1/";
// export const API_URL = "https://alpha.platform.rdx.ai/api/v1/";
// export const API_URL = "http://203.123.38.226:8880/api/v1/";
// export const API_URL = "https://marketplace.easemyai.com/api/v1/";
// export const API_URL = "https://test.marketplace.rdx.ai/api/v1/";
// export const SOCKET_URL = "http://192.168.1.140";
// export const API_URL = "https://test.marketplace.rdx.ai/api/v1/";
// export const SOCKET_URL = "http://192.168.1.140";
export const OTP_TIME = 180;

const defaultOptions = {
  baseURL: API_URL,
  headers: {
    Accept: "application/json",
  },
};
export const axiosApiInstance = axios.create(defaultOptions);

// Request interceptor for API calls
axiosApiInstance.interceptors.request.use(
  async (config) => {
    const data = encryptStorage.getItem("UID");
    config.headers.Authorization = "Bearer " + data.accessToken;
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Response interceptor for API calls
axiosApiInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    if (!error.response) {
      // network error
      localStorage.setItem("theme", "Light");
      // window.location.pathname = "/issue/server";
    }
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      let _data = encryptStorage.getItem("UID");
      originalRequest._retry = true;
      const accessToken = await refreshAccessToken();
      if (!accessToken) {
        encryptStorage.removeItem("UID");
        encryptStorage.removeItem("VID");
        encryptStorage.removeItem("VID2");
        // window.location.replace("/");
        return;
      }
      axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
      _data.accessToken = accessToken;

      localStorage.setItem("accessToken", accessToken);
      encryptStorage.setItem("UID", _data);
      localStorage.setItem("accessToken", accessToken);
      return axiosApiInstance(originalRequest);
    }
    return Promise.reject(error);
  }
);

async function refreshAccessToken() {
  let dataa = encryptStorage.getItem("UID");
  const headers = {
    Authorization: "Bearer " + dataa.refreshToken,
  };
  try {
    const data = await axios.post(
      API_URL + "auth/refresh",
      {},
      {
        headers: headers,
      }
    );
    if (data.status === 200) {
      return data.data.accessToken;
    }
    return "null";
  } catch (error) {
    // window.location.replace("/");
  }
}

// export const globalSocket = async () => {
//   return socketio.connect(SOCKET_URL);
// };

export const randomID = (len = 6) => {
  const chars = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678";
  const maxPos = chars.length;
  let id = "";
  for (let i = 0; i < len; i++) {
    id += chars.charAt(Math.floor(Math.random() * maxPos));
  }
  return id;
};
