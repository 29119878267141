import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { motion } from "framer-motion";
import { encryptStorage } from "../../helper/storage";
import "./otp.scss";
import { container, xMotion } from "../../helper/motions";
import Button from "../Button/Button";
import { closeIcon } from "../../helper/icons";
import { OTP_TIME } from "../../helper/request";
let _lData__ = encryptStorage.getItem("VID");
let timeout = null;

export default function OTP({
  showOTP,
  setShowOTP,
  postResendOTP,
  header = "Verify OTP",
  subheader = "Enter The OTP Received On Your Registered Email ID",
  postVerifyOTP,
  resendFunc,
  setOTPCloseConfirm,
  _stop,
  setStop,
}) {
  const [otp1, setOtp1] = useState("");
  const [otp2, setOtp2] = useState("");
  const [otp3, setOtp3] = useState("");
  const [otp4, setOtp4] = useState("");
  const [Disabled, setDisabled] = useState(true);
  const [counter, setCounter] = useState(
    _lData__ ? _lData__?.counter : OTP_TIME
  );
  // const [attempts, setAttempts] = useState(2);

  const resendOTP = () => {
    let _lData = encryptStorage.getItem("VID");
    _lData.counter = OTP_TIME;
    setCounter(OTP_TIME);
    setStop(false);
    encryptStorage.setItem("VID", _lData);
  };

  const getCodeBoxElement = (index) => {
    return document.getElementById("otp" + index);
  };

  const onFocusEvent = (index) => {
    for (let item = 1; item < index; item++) {
      const currentElement = getCodeBoxElement(item);
      if (!currentElement.value) {
        currentElement.focus();
        break;
      }
    }
  };

  const inputfocus = (index, event) => {
    if (event.ctrlKey && event.keyCode === 86) {
      getCodeBoxElement(4).focus();
    } else {
      const eventCode = event.which || event.keyCode;
      if (getCodeBoxElement(index).value.length === 1) {
        if (index !== 4) {
          getCodeBoxElement(index + 1).focus();
        } else {
          // getCodeBoxElement(index).blur();
          // Submit code
        }
      }
      if (eventCode === 8 && index !== 1) {
        getCodeBoxElement(index - 1).focus();
      }
    }
  };

  const isNumeric = (n) => {
    return !isNaN(parseFloat(n)) && isFinite(n);
  };

  const handlePaste = (e) => {
    let pastedData = e.clipboardData.getData("text/plain").trim();
    if (isNumeric(pastedData)) {
      pastedData = pastedData.split("");
      if (pastedData.length === 4) {
        setOtp1(pastedData[0]);
        setOtp2(pastedData[1]);
        setOtp3(pastedData[2]);
        setOtp4(pastedData[3]);
        getCodeBoxElement(4).focus();
      } else {
        setOtp1("");
      }
    } else {
      setOtp1("");
    }
  };

  useEffect(() => {
    if (showOTP) {
      if (!_stop) {
        counter > 0 &&
          setTimeout(() => {
            let _lData = encryptStorage.getItem("VID");
            if (_lData) {
              _lData.counter = counter - 1;
            }
            if (_lData) {
              encryptStorage.setItem("VID", _lData);
            }
            setCounter(counter - 1);
          }, 1000);
        if (counter === 0) {
          setStop(true);
          setCounter(-1);
        }
      }
      if (counter === 0) {
        setOtp1("");
        setOtp2("");
        setOtp3("");
        setOtp4("");
        setDisabled(true);
      }
    }
  }, [counter]);

  useEffect(() => {
    if (showOTP) {
      let lData = encryptStorage.getItem("VID");
      if (lData) {
        if (lData.counter > 1) {
          setCounter(lData.counter - 1);
        } else if (lData.counter === 0) {
          setCounter(0);
          lData.counter = 0;
        }
        encryptStorage.setItem("VID", lData);
      } else {
      }
    }
  }, [showOTP]);

  useEffect(() => {
    resendFunc(resendOTP);
    if (_lData__) {
      if (_lData__.counter === 0) {
        setCounter(0);
      } else {
        setCounter(_lData__.counter);
      }
      // }
    }
  }, []);

  useEffect(() => {
    if (otp1 != "" && otp2 != "" && otp3 != "" && otp4 != "") {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [otp1, otp2, otp3, otp4]);

  return (
    <div className="pass_container">
      <div
        className="close_otp_modal"
        onClick={() => {
          setOTPCloseConfirm(true);
        }}
      >
        {closeIcon()}
      </div>
      <p className="v_email">{header}</p>
      <p className="otp_desc">{subheader}</p>
      <motion.form
        variants={container}
        initial="hidden"
        animate="visible"
        className="auth_form"
        style={{ width: "100%" }}
      >
        <div className="otpContainer">
          <motion.input
            variants={xMotion}
            id="otp1"
            type="text"
            autoComplete="off"
            className="otpInput"
            disabled={counter <= 0}
            value={otp1}
            onChange={(e) => setOtp1(e.target.value.replace(/[^0-9]/g, ""))}
            tabIndex="1"
            maxLength="1"
            onKeyUp={(e) => inputfocus(1, e)}
            onFocus={() => onFocusEvent(1)}
            onPaste={handlePaste}
            autoFocus
          />
          <motion.input
            id="otp2"
            variants={xMotion}
            type="text"
            autoComplete="off"
            className="otpInput"
            value={otp2}
            onChange={(e) => setOtp2(e.target.value.replace(/[^0-9]/g, ""))}
            disabled={counter <= 0}
            tabIndex="2"
            maxLength="1"
            onKeyUp={(e) => inputfocus(2, e)}
            onFocus={() => onFocusEvent(2)}
            onPaste={(e) => e.preventDefault()}
          />
          <motion.input
            id="otp3"
            type="text"
            variants={xMotion}
            autoComplete="off"
            className="otpInput"
            value={otp3}
            onChange={(e) => setOtp3(e.target.value.replace(/[^0-9]/g, ""))}
            tabIndex="3"
            disabled={counter <= 0}
            maxLength="1"
            onKeyUp={(e) => inputfocus(3, e)}
            onFocus={() => onFocusEvent(3)}
            onPaste={(e) => e.preventDefault()}
          />
          <motion.input
            id="otp4"
            variants={xMotion}
            type="text"
            autoComplete="off"
            className="otpInput"
            value={otp4}
            disabled={counter <= 0}
            onChange={(e) => setOtp4(e.target.value.replace(/[^0-9]/g, ""))}
            tabIndex="4"
            maxLength="1"
            onKeyUp={(e) => inputfocus(4, e)}
            onFocus={() => onFocusEvent(4)}
            onPaste={(e) => e.preventDefault()}
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                let _otp = otp1 + otp2 + otp3 + otp4;
                postVerifyOTP(_otp);
              }
            }}
          />
        </div>
        {counter > 0 && (
          //   <p className="_counter">OTP Expires in {counter} seconds</p>
          <p className="_counter">
            OTP Expires in{" "}
            {"0" +
              Math.floor(counter / 60) +
              ":" +
              (counter % 60
                ? counter % 60 > 9
                  ? counter % 60
                  : "0" + (counter % 60)
                : "00")}
          </p>
        )}
        <p className="resend__">
          <span
            className={counter > 1 ? "disabled" : undefined}
            onClick={counter <= 0 ? postResendOTP : null}
          >
            Resend OTP
          </span>
        </p>
      </motion.form>

      <Button
        style={{ marginTop: "1vw" }}
        name={"Verify"}
        type="gradient"
        onClick={() => {
          let _otp = otp1 + otp2 + otp3 + otp4;
          postVerifyOTP(_otp);
        }}
        disabled={Disabled}
      />
    </div>
  );
}
