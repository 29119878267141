import React from "react";
import Button from "../../../components/Button/Button";
import { encryptStorage } from "../../../helper/storage";
import Toggle from "../../../components/Toggle/Toggle";
import licenseImage from "../../../assets/images/licenseImage.png"
import Scroller from "../../../components/Scroller/ScrollBar";

export default function LicenseList({
  name,
  pricingDetails,
  editMarkup,
  editLicense,
  assignMfg,
  Role,
  editRestrcitUsers,
  toggleValue,
  toggleId,
  handleToggleValue,
  disableLicense,
}) {
  const arr = ["attribute_name", "monthly", "quarterly", "yearly", "lifetime"];

  return (
    <div className="license_list_wrapper">
      <img
        className="license_image"
        src= {licenseImage}
        alt=""
      />
      <div className="license_info">
        <p className="license_name">{name}</p>
        <div className="toggle_div">
          <p className="license_para">Activate License:</p>
          <div className="license_toggle">
            <Toggle
              value={toggleValue}
              id={toggleId}
              handleValue={handleToggleValue}
            />
          </div>
        </div>
        <button onClick={editRestrcitUsers} className="restrict_users_btn">
          {restrictIcon()} Edit Restricted Users
        </button>
        <div className="edit_assign_btns">
          <Button
            style={{
              fontSize: "0.8333333333vw",
              borderRadius: "3.5px",
              padding: "0.2vw",
              fontWeight: 500,
              // width: Role.includes("superadmin") ? "6vw" : "12vw",
            }}
            onClick={assignMfg}
            name="Assign"
          />
          <Button
            style={{
              borderRadius: "3.5px",
              padding: "0.2vw",
              color: "var(--primary)",
              fontSize: "0.8333333333vw",
              backgroundColor: "#fff",
              fontWeight: "500",
              border: "1px solid var(--primary)",
            }}
            onClick={Role.includes("superadmin") ? editLicense : editMarkup}
            name={Role.includes("superadmin") ? "Edit Pricing" : "Edit Markup"}
          />
        </div>
      </div>
      <div className="license_table">
        <Scroller
          autoHeightMax="7vw"
          verticalStyle={{
            width: "0.12vw",
            marginLeft: "0.2vw",
            backgroundColor: "#283e92",
          }}
        >
        <table>
          <tr>
            {arr.map((header, index) => {
              return header === "attribute_name" ? (
                <th style={{ width: "4vw" }}>Name</th>
              ) : (
                <th style={{ textAlign: "center" }} key={header + index}>
                  {header.replace(/_/g, " ")}
                </th>
              );
            })}
          </tr>
          {pricingDetails.map((item, idx) => {
            return (
              <tr>
                {arr.map((header) => {
                  return header === "attribute_name" ? (
                    <td>{item[header]}</td>
                  ) : (
                    <td style={{ textAlign: "center" }}>
                      {item[header]?.amount}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </table>
        </Scroller>
      </div>
    </div>
  );
}

const restrictIcon = () => {
  return (
    <svg
      className="resitrct_icon"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      fill="#464646"
      style={{ width: "0.6vw", height: "0.6vw" }}
    >
      <path d="M367.2 412.5L99.5 144.8C77.1 176.1 64 214.5 64 256c0 106 86 192 192 192c41.5 0 79.9-13.1 111.2-35.5zm45.3-45.3C434.9 335.9 448 297.5 448 256c0-106-86-192-192-192c-41.5 0-79.9 13.1-111.2 35.5L412.5 367.2zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256z" />
    </svg>
  );
};
