import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import "./loading.scss";
let interval = null;

export default function Loading({ type, text }) {
  const [timeOut, setTimeOut] = useState(false);
  useEffect(() => {
    interval = setTimeout(() => {
      setTimeOut(!timeOut);
    }, 5000);
    return () => {
      clearTimeout(interval);
    };
  }, []);

  return (
    <motion.div
      className="__loading__"
      initial={{ opacity: 0 }}
      animate={{
        opacity: 1,
      }}
      id="loading_frame"
    >
      <div className="loading_wrapper">
        <div className="loading_anim" />
        {timeOut ? (
          <h2>
            It's taking a bit longer than usual, but it should be ready soon.
          </h2>
        ) : (
          <h2>{text !== null ? text : "Loading..."}</h2>
        )}
      </div>
    </motion.div>
  );
}
