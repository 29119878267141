import React, { useEffect, useState } from "react";
import "../ViewApp/Suggestions.scss";
import { sad, star } from "../../../../../helper/icons";
import StarRating from "./StarRating";
import { useQuery } from "../../../../../helper/storage";
import { useHistory } from "react-router-dom";
import { axiosApiInstance } from "../../../../../helper/request";
import Loading from "../../../../../components/Loading/Loading";

export default function Suggestions({ suggest }) {
  return (
    <div>
      <div className="rhs_suggestions_wrapper">
        <h3 className="suggestions_header">
          {suggest <= 1 ? <div style={{textAlign: "center"}}>Suggestion</div> : "Suggestions"}
        </h3>
        <div className="nosuggestions">{suggest === 0 && <div>{sad("suugestions_sad")}<p style={{fontWeight: "600", fontSize: "1.042vw"}}>Sorry, no suggestions found</p></div>}</div>
      </div>
    </div>
  );
}

export const SuggestApp = ({
  suggestName,
  suggestDeveloper,
  suggestReview,
  idx,
  onclick,
  suggeststar,
  suggestThumb,
  setState,
  fetchApp,
}) => {
  const history = useHistory();
  let firstLoad = true;

  const [LoadingScreen, setLoadingScreen] = useState(false);

  const queryy = useQuery();
  // useEffect(() => {
  //   let app_name = queryy.get("app_name") || "";
  //   // setLoadingScreen(true);

  //   axiosApiInstance
  //     .get(`apps/suggestions?app_name=${app_name}`)
  //     .then((res) => {
  //       setState(res.data.detail);
  //     })
  //     // .finally(() => {
  //     //   setLoadingScreen(false);
  //     //   setTimeout(() => {
  //     //     firstLoad = false;
  //     //   }, 2000);
  //     // });
  //   return () => {};
  // }, [fetchApp]);

  return (
    <div
      className="suggest_main_wrapper"
      onClick={() => onclick()}
      // onClick={(e) => {
      //   let app_name = queryy.get("app_name") || "";
      //   history.push({
      //     pathname: "/apps/usecase",
      //     search: `?app_name=${app_name}`,
      //   });
      //   console.log("first")
      // }}
    >
      <img className="suggest_image" src={suggestThumb}></img>
      <div className="suggest_side_wrapper">
        <h4 className="suggestion_appname">{suggestName}</h4>
        <p title={suggestDeveloper} className="suggest_content">
          {suggestDeveloper ? suggestDeveloper : "NA"}
        </p>
        <p>
          {suggestReview}
          {/* {suggeststar.length ? <StarRating total={5} selected={suggeststar} /> : "" } */}
          <StarRating total={5} selected={suggeststar} />
        </p>
      </div>
      {/* {LoadingScreen && <Loading type={"transparent"} text={"Loading"} />} */}
    </div>
  );
};
