import React from "react";
import { useRef } from "react";
import { useState } from "react";
import Button from "../../../../components/Button/Button";
import InputBox from "../../../../components/Inputbox/InputBox";
import AnimatedModal from "../../../../components/Modal/AnimatedModal";
import Scroller from "../../../../components/Scroller/ScrollBar";
import { closeIcon } from "../../../../helper/icons";
import { axiosApiInstance } from "../../../../helper/request";
let msg = {
  type: "alert",
  header: "Error",
  errorText: "Something Went Wrong",
};

export default function EditMarkup({
  MarkupModal,
  setMarkupModal,
  license_name,
  UpdatedMarkup,
  setUpdatedMarkup,
  fetchLicense,
}) {
  const userScroll = useRef();
  const arr = ["attribute_name", "monthly", "quarterly", "yearly", "lifetime"];
  const arr2 = [
    "attribute_name",
    "monthly",
    "quarterly",
    "yearly",
    "lifetime",
    "",
  ];
  const [isActive, setIsActive] = useState("");
  const [ShowAlert, setShowAlert] = useState(false);
  const [currentAmount, setcurrentAmount] = useState(0);

  function percentage(val, idx, idx2) {
    let newArr = [...UpdatedMarkup];
    let result = Number((val / 100) * currentAmount.toFixed(2));
    newArr[idx][arr[idx2]].markup = val;
    newArr[idx][arr[idx2]].amount = result + Number(currentAmount);
    setUpdatedMarkup([...newArr]);
  }

  function reversePercentage(item) {
    let a = item.markup + 100;
    let b = item.amount / a;
    let d = Math.round(b * 100);
    return d;
  }

  const postMarkup = () => {
    let newArr = [...UpdatedMarkup];
    newArr.map((item, idx) => {
      arr.map((header, idx2) => {
        if (header !== "attribute_name") {
          if (newArr[idx][arr[idx2]].markup === "") {
            newArr[idx][arr[idx2]].markup = 0;
          }
        }
      });
    });
    axiosApiInstance
      .post("license/add/markup", {
        license_name: license_name,
        pricing_details: newArr,
      })
      .then((res) => {
        console.log(res);
        msg.errorText = "Successfully Added Markup";
        msg.header = "Success";
        msg.type = "success";
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 3000);
      })
      .catch((err) => {
        console.log(err);
        msg.errorText = "Couldn't Add Markup";
        msg.header = "Error";
        msg.type = "alert";
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 3000);
      })
      .finally(() => {
        setUpdatedMarkup([]);
        setMarkupModal(false);
        fetchLicense(1, "Standard License");
        setIsActive("");
      });
  };

  return (
    <div className="apps_markup_modal">
      <AnimatedModal modalOpen={MarkupModal} className="apps_edit_markup_modal">
        <div className="apps_markup_modal_wrapper">
          <div
            className="close_otp_modal"
            onClick={() => {
              setMarkupModal(false);
              setIsActive("");
            }}
          >
            {closeIcon()}
          </div>
          <div className="apps_edit_markup_table">
            <InputBox id="__apps_license_name" value={license_name} disabled />
            <Scroller autoHeightMax="21vw" ref={userScroll}>
              <table>
                <tr>
                  {arr2.map((header, idx) => {
                    return (
                      <th
                        key={header + idx + "markup"}
                        style={{ textTransform: "capitalize" }}
                      >
                        {header.replace(/_/g, " ")}
                      </th>
                    );
                  })}
                </tr>
                {UpdatedMarkup.map((item, idx) => {
                  return (
                    <tr
                      key={idx + "markup" + "row"}
                      className="attribute_row"
                      style={{
                        height:
                          item.attribute_name === isActive ? "10vh" : "5vh",
                      }}
                    >
                      {arr2.map((header, idx2) => {
                        return header === "attribute_name" ? (
                          <td key={item[header] + idx2 + "markup" + "column"}>
                            {item[header]}
                          </td>
                        ) : header === "" ? (
                          <td key={idx2 + "markup" + "column"}>
                            {item.attribute_name !== isActive ? (
                              <Button
                                onClick={() => {
                                  setIsActive(item.attribute_name);
                                }}
                                name="Add"
                                style={{
                                  padding: "0.8vw",
                                  backgroundColor: "#0433980D",
                                  color: "var(--primary)",
                                  borderRadius: "0px 4px 4px 0px",
                                }}
                              />
                            ) : (
                              <Button
                                onClick={() => {
                                  setIsActive("");
                                }}
                                style={{
                                  height: "10vh",
                                  backgroundColor: "#0433980D",
                                  color: "var(--primary)",
                                  borderRadius: "0px 4px 4px 0px",
                                }}
                                name="Done"
                              />
                            )}
                          </td>
                        ) : (
                          <td>
                            <p
                              style={{
                                height:
                                  item.attribute_name === isActive && "2vw",
                              }}
                            >
                              {item[header]?.amount}
                            </p>{" "}
                            {item.attribute_name === isActive && (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                {" "}
                                <input
                                  className="markup_input_"
                                  value={item[header].markup}
                                  maxlength={5}
                                  onFocus={() => {
                                    console.log(item[header].amount);
                                    setcurrentAmount(
                                      reversePercentage(item[header])
                                    );
                                  }}
                                  onChange={(e) => {
                                    if (Number(e.target.value) <= 100) {
                                      percentage(
                                        e.target.value.replace(/[^\d.]+/g, ""),
                                        idx,
                                        idx2
                                      );
                                    }
                                  }}
                                />
                                <p className="percentage_para_">%</p>
                              </div>
                            )}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </table>
            </Scroller>
            <Button
              id="save_btn"
              onClick={() => {
                postMarkup();
              }}
              style={{
                position: "absolute",
                right: "2%",
                bottom: "2%",
              }}
              name="Save"
            />
          </div>
        </div>
      </AnimatedModal>
      <AnimatedModal
        modalOpen={ShowAlert}
        handleClose={() => {
          setShowAlert(false);
        }}
        type={msg.type}
        errorHeader={msg.header}
        errorText={msg.errorText}
        // timeout={5000}
        alwayOpen={true}
      />
    </div>
  );
}
