import React, { useContext, useEffect, useState, useRef, useMemo } from "react";
import Navbar from "../../../components/Navbar/Navbar";
import bg from "../../../assets/images/background.png";
import "./device.scss";
import DeviceCard from "../../../components/DeviceCard/DeviceCard";
import AnimatedModal from "../../../components/Modal/AnimatedModal";
import { closeIcon, searchIcon, tickIcon2 } from "../../../helper/icons";
import InputBox from "../../../components/Inputbox/InputBox";
import Button from "../../../components/Button/Button";
import { ThemeContext } from "../../../helper/context";
import Dropdown from "../../../components/Dropdown/Dropdown";
import { useHistory } from "react-router-dom";
import { axiosApiInstance } from "../../../helper/request";
import Loading from "../../../components/Loading/Loading";
import Scroller from "../../../components/Scroller/ScrollBar";
import Pagination from "../../../components/Pagination/Pagination";
import MultiSelectDropdown from "../../../components/MultiSelectDropdown/MultiSelectDropdown";
import { useDebouncedEffect } from "../../../helper/useDebounce";
import { useQuery } from "../../../helper/useQuery";
import {
  encryptStorage,
  getParams,
  isSuspended,
} from "../../../helper/storage";
import DeviceList from "./DeviceList";
import AddDeviceLicence from "./AddDeviceLicence";
import useClickOutside from "../../../helper/useClickOutside";
import { Radio } from "../../../components/Radio/Radio";
import { notify } from "../../../Routes";
import UpgradeLicence from "./UpgradeLicence";
import UpgradeLicense2 from "./UpgradeLicense2";
import SelectLicense from "./SelectLicense";
import UserLoginModal from "./UserLoginModal";
import Searchbar from "../../../components/Searchbar/Searchbar";
let changeUserPage = 1;
let isFirstLoad = true;
let isFilterLoaded = false;
let locationData = [];
let getLicenceData = null;
let _attribute_name = null;
let _license_index = 0;
let _license_name = null;
let _mapping_id = null;
let base_price = 0;
let selectedDevice = {
  licence: false,
  manu_key: null,
};
let msg = {
  type: "success",
  header: "success",
  errorText: "successfully activated",
};

export default function Device() {
  const queryy = useQuery();
  const primaryRef = useRef();
  const page = queryy.get("page") || "";
  const size = queryy.get("size") || "";
  const themeContext = useContext(ThemeContext);
  const history = useHistory();
  const [CurrentPage, setCurrentPage] = useState(1);
  const [TotalPages, setTotalPages] = useState(0);
  const [DeviceCount, setDeviceCount] = useState(null);
  const [Device, setDevice] = useState([]);
  const [AddLicence, setAddLicence] = useState(false);
  const [RenewLicence, setRenewLicence] = useState(false);
  const [FetchLicense, setFetchLicense] = useState(false);
  const [StandardLicenses, setStandardLicenses] = useState([]);
  const [IsLoading, setIsLoading] = useState(true);
  const [Warranty, setWarranty] = useState(null);
  const [isModalOpen, setisModalOpen] = useState(false);
  const [SearchText, setSearchText] = useState("");
  const [ShowLocation, setShowLocation] = useState(false);
  const [Location, setLocation] = useState("");
  const [LocationSearch, setLocationSearch] = useState("");
  const [SelectedLicenseDetails, setSelectedLicenseDetails] = useState([]);
  const [DeleteConfirm, setDeleteConfirm] = useState(false);
  const [flag, setFlag] = useState(false);
  const [specialLicense, setspecialLicense] = useState();
  const [changePage, setChangePage] = useState(1);
  const [specialLicenseTotalCount, setSpecialLicenseTotalCount] = useState([]);
  const [selectCard, setselectCard] = useState(false);
  const [ShowAlert, setShowAlert] = useState(false);
  const [manuName, setManuName] = useState("");
  const [showUserLogin, setshowUserLogin] = useState(false);
  const [searchedManuList, setSearchedManuList] = useState([]);
  const [deviceType, setDeviceType] = useState("");
  const [searchManu, setSearchManu] = useState("");
  const [sortByChoice, setSortByChoice] = useState("");

  const onSearchInput = (e) => {
    setSearchManu(e.target.value);
    if (e.target.value === "") {
      setSearchedManuList([]);
    }
  };
  useDebouncedEffect(
    () => (searchManu ? getFilterOptions() : undefined),
    [searchManu],
    1000
  );

  useClickOutside(primaryRef, () => {
    setShowLocation(false);
  });
  const [FilterOptions, setFilterOptions] = useState({
    architecture: [],
    manufacturer: [],
    version: [],
    processor: [],
    location: [],
    sort_by: ["Newest", "Oldest"],
    user_type: ["Owner", "Operator"],
  });

  const [SelectedFilter, setSelectedFilter] = useState({
    architecture: [],
    processor: [],
    location: [],
    manufacturer: [],
    version: [],
    sort_by: [],
    user_type: [],
  });

  useDebouncedEffect(
    () => (SelectedFilter ? getFilter() : undefined),
    [SelectedFilter],
    500
  );

  useDebouncedEffect(() => getFilter(), [SearchText], 500);

  const getDevice = (page = 1) => {
    let users = ["user", "developer"];
    let role = null;
    if (users.includes(themeContext.Role.current)) {
      role = "user";
    } else {
      role = themeContext.Role.current;
    }
    let serial_number = queryy.get("serial_number") || "";
    let url = "device/" + role + "/devices?page=" + page + "&items=10";
    if (serial_number !== "") {
      url += "&serial_number=" + serial_number;
    }
    // if (deviceType !== "") {
    //   url += "&user_type=" + deviceType.toLowerCase();
    // }
    // if (sortByChoice !== "") {
    //   url += "&sort_by=" + sortByChoice.toLowerCase();
    // }
    setIsLoading(true);
    axiosApiInstance
      .get(url)
      .then((res) => {
        setTimeout(() => {
          isFirstLoad = false;
        }, 1000);
        setDevice([...res.data.devices]);
        let _totalPages = Math.ceil(res.data.total_count / 10);
        document.querySelector(".device_count").innerHTML =
          res.data.total_count;
        setTotalPages(_totalPages);
        setCurrentPage(page);
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getFilterOptions = () => {
    const locationFilterKeys = (locationData) => {
      let res = false;
      Object.keys(locationData).map((item) => {
        if (locationData[item].length) {
          res = true;
        }
      });
      if (!res) {
        return [];
      }
      return Object.keys(locationData);
    };
    let role =
      themeContext.Role.current === "developer"
        ? "user"
        : themeContext.Role.current === null
        ? "user"
        : themeContext.Role.current;
    axiosApiInstance
      .get("device/" + role + "/unique/filters")
      .then((res) => {
        isFilterLoaded = true;
        locationData = { ...res.data.location };
        setFilterOptions({
          ...res.data,
          location: locationFilterKeys(locationData),
          sort_by: ["Newest", "Oldest"],
          user_type: ["Owner", "Operator"],
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getFilter = (page = 1) => {
    if (isFirstLoad) return;
    let query = "";
    const params = new URLSearchParams(window.location.search);
    let paramKeys = getParams();
    let _type = paramKeys.type;
    if (SearchText) {
      query += "&search=" + SearchText.split(" ");
      let url = new URL(window.location.href);
      url.searchParams.set("search", SearchText);
      window.history.pushState(null, null, url);
    } else {
      params.delete("search");
      var _url = window.location.pathname + "?" + params.toString();
      window.history.pushState(null, null, _url);
    }
    // if (SelectedFilter.sortBy) {
    //   query +=
    //     "&role_status=" + SelectedFilter.role + ":" + SelectedFilter.status;
    //   let url = new URL(window.location.href);
    //   url.searchParams.set("role", SelectedFilter.role);
    //   url.searchParams.set("status", SelectedFilter.status);
    //   window.history.pushState(null, null, url);
    // }
    Object.keys(SelectedFilter).forEach((element) => {
      if (SelectedFilter[element].length > 0) {
        let url = new URL(window.location.href);
        if (element === "location") {
          url.searchParams.set(element, SelectedFilter[element].join());
          window.history.pushState(null, null, url);
          query += "&" + Location + "=" + SelectedFilter[element].join();
        } else {
          url.searchParams.set(element, SelectedFilter[element]);
          window.history.pushState(null, null, url);
          query += "&" + element + "=" + SelectedFilter[element].join();
        }
        // if (SelectedFilter.sortBy) {
        //   query +=
        //     "&sort_by" + SelectedFilter.sortBy
        //   let url = new URL(window.location.href);
        //   // url.searchParams.set("sort_by", SelectedFilter.sortBy);
        //   // url.searchParams.set("status", SelectedFilter.status);
        //   window.history.pushState(null, null, url);
        // }
      } else {
        const params = new URLSearchParams(window.location.search);
        params.delete(element);
        var _url = window.location.pathname + "?" + params.toString();
        window.history.pushState(null, null, _url);
      }
    });
    if (!query && !SearchText) {
      getDevice(page);
      return;
    } else {
      let url = new URL(window.location.href);
      url.searchParams.set("page", page);
      window.history.pushState(null, null, url);
      setCurrentPage(page);
    }
    setIsLoading(true);
    axiosApiInstance
      .get(
        "device/" +
          themeContext.Role.current +
          "/devices?page=" +
          page +
          "&items=10" +
          query
      )
      .then((res) => {
        setDevice([...res.data.devices]);
        let _totalPages = Math.ceil(res.data.total_count / 10);
        document.querySelector(".device_count").innerHTML =
          res.data.total_count;
        setTotalPages(_totalPages);
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getLicenses = (name) => {
    setIsLoading(true);
    axiosApiInstance
      .get("license/fetch/manufacturer/licenses?manufacturer_username=" + name)
      .then((res) => {
        setStandardLicenses([...res.data.details]);
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };
  const getSpecialLicenses = (changePage = 1) => {
    setIsLoading(true);
    axiosApiInstance
      .get(`inventory/role?license_type=device&page=${changePage}&items=10`)
      .then((res) => {
        let temp = [];
        setFlag(false);
        temp = res.data.details;
        // setLoadingScreen(false);
        setspecialLicense((prevState) => {
          return changePage === 1 ? [...temp] : [...prevState, ...temp];
        });
        setSpecialLicenseTotalCount(res.data.total_count);
        // scrollbar?.current?.scrollTop(20);
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const deleteData = () => {
    setIsLoading(true);
    axiosApiInstance
      .delete("pricing/device?attribute_name=" + _attribute_name)
      .then((res) => {
        getLicenceData();
        notify({
          msg: "Licence deleted successfully!",
          type: "success",
        });
      })
      .catch((err) => {
        notify({});
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const addCountKey = (index) => {
    let newArr = [...StandardLicenses[index].pricing_details];
    // newArr.splice(0,1)
    for (let i = 0; i < newArr.length; i++) {
      let _keys = Object.keys(newArr[i]);
      for (let j = 0; j < _keys.length; j++) {
        if (_keys[j] != "attribute_name") {
          newArr[i][_keys[j]].count = 1;
        }
      }
    }
    _mapping_id = StandardLicenses[index].manufacturer_mapping_id;
    setSelectedLicenseDetails([...newArr]);
  };

  useEffect(() => {
    let ldata = encryptStorage.getItem("UID");
    if (!ldata) return;
    if (isFilterLoaded) {
      setIsLoading(true);
      if (Object.keys(getParams()).length <= 2) {
        getDevice(page || 1);
        var url = new URL(window.location.href);
        url.searchParams.set("page", page || 1);
        url.searchParams.set("size", size || 10);
        window.history.pushState(null, null, url);
      } else {
        let paramKeys = getParams();
        delete paramKeys.page;
        delete paramKeys.size;
        let _search = null;
        let _type = paramKeys.type;
        Object.keys(paramKeys).forEach((element) => {
          paramKeys[element] = paramKeys[element].split(",");
          if (element === "search") {
            _search = paramKeys[element];
          }
        });
        delete paramKeys?.search;
        delete paramKeys.type;
        setTimeout(() => {
          isFirstLoad = false;
          if (_type) {
            setLocation(_type);
          }
          setSearchText(_search?.join(" "));
          setSelectedFilter((prev) => {
            return {
              ...prev,
              ...paramKeys,
            };
          });
        }, 500);
      }
    }
    setselectCard(true);
  }, [FilterOptions, FetchLicense, deviceType, sortByChoice]);

  useEffect(() => {
    let ldata = encryptStorage.getItem("UID");
    if (ldata) {
      if (!isFilterLoaded) {
        getFilterOptions();
      }
    } else {
      setshowUserLogin(true);
      setIsLoading(false);
    }
    return () => {
      isFirstLoad = true;
      isFilterLoaded = false;
    };
  }, []);

  const toggleDevice = (beta_status, serial_number, idx) => {
    axiosApiInstance
      .patch(
        "device/beta_testing",
        {
          beta_status,
        },
        {
          headers: {
            "serial-number": serial_number,
          },
        }
      )
      .then((res) => {
        notify({ msg: "Updated!", type: "success" });
      })
      .catch((err) => {
        let _data = [...Device];
        _data[idx].beta_testing = !beta_status;
        setDevice([...Device]);
        notify({ msg: "Failed to update!" });
      });
  };

  const filteredItems = useMemo(() => {
    return locationData[Location]?.filter((item) => {
      return item?.toLowerCase().startsWith(LocationSearch.toLowerCase());
    });
  }, [LocationSearch, Location]);

  return (
    <div className="__device_wrapper__">
      <Navbar sidenav>
        <div className="device_body">
          <img src={bg} className="device_bg" />
          {console.log(Location, "Location")}
          <div className="device_content">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h1>Device</h1>
            </div>
            <div className="device_data">
              <div className="device_filter">
                <div className="search_container">
                  <InputBox
                    id="search"
                    placeholder="Search using Name, Tags, Architecture"
                    onChange={(e) => {
                      setSearchText(e.target.value);
                    }}
                    value={SearchText}
                    children={searchIcon()}
                  />
                </div>

                <div className="filter-options-wrapper">
                  <div className="filter-options">
                    <div className="architecture_filter_wrapper">
                      {FilterOptions.architecture.length > 0 && (
                        <MultiSelectDropdown
                          label="Architecture"
                          style={{ width: "7.2vw" }}
                          className="adjust_dd"
                          id={"architecture"}
                          optionsList={FilterOptions.architecture}
                          handleOption={(data) => {
                            setIsLoading(true);
                            setSelectedFilter({
                              ...SelectedFilter,
                              architecture: [...data],
                            });
                          }}
                          defaultText={SelectedFilter.architecture}
                        />
                      )}
                    </div>
                    {themeContext.Role.current === "user" && (
                      // <MultiSelectDropdown
                      //   style={{ width: "7.2vw" }}
                      //   className="adjust_dd"
                      //   label="Processor"
                      //   id={"Processor"}
                      //   optionsList={FilterOptions.processor}
                      //   handleOption={(data) => {
                      //     setIsLoading(true);
                      //     setSelectedFilter({
                      //       ...SelectedFilter,
                      //       processor: [...data],
                      //     });
                      //   }}
                      //   defaultText={SelectedFilter.processor}
                      // />
                      <div className="device_type">
                        <Dropdown
                          optionsList={FilterOptions.user_type}
                          label="User type"
                          id={"Action_"}
                          className="adjust_mdd"
                          handleOption={(data) => {
                            setSelectedFilter({
                              ...SelectedFilter,
                              user_type: [data.toLowerCase()],
                            });
                          }}
                          defaultText={SelectedFilter.user_type.toString()}
                        />
                      </div>
                    )}

                    <div className="sortby_container">
                      <Dropdown
                        optionsList={FilterOptions.sort_by}
                        label="Sort by"
                        id={"Action_"}
                        className="adjust_mdd"
                        handleOption={(data) => {
                          setSelectedFilter({
                            ...SelectedFilter,
                            sort_by: [data.toLowerCase()],
                          });
                        }}
                        defaultText={SelectedFilter.sort_by.toString()}
                      />
                    </div>

                    {FilterOptions.location.length > 0 && (
                      <div className="location_search">
                        <div style={{ display: "flex" }}>
                          <Dropdown
                            label={"Location"}
                            style={{ width: "7.2vw" }}
                            className="adjust_location_dd"
                            id={"location"}
                            optionsList={FilterOptions.location}
                            handleOption={(data) => {
                              var url = new URL(window.location.href);
                              url.searchParams.set("type", data);
                              window.history.pushState(null, null, url);
                              setLocation(data);
                              setShowLocation(true);
                              setLocationSearch("");
                              if (SelectedFilter.location.length > 0) {
                                setSelectedFilter({
                                  ...SelectedFilter,
                                  location: [],
                                });
                              } else {
                                // isFirstLoad = true;
                                // setTimeout(() => {
                                //   isFirstLoad = false;
                                // }, 1500);
                              }
                            }}
                            defaultText={Location}
                          />
                          <div
                            className="selected_options"
                            style={{ cursor: Location ? null : "not-allowed" }}
                            onClick={() => {
                              if (Location) {
                                setShowLocation(true);
                              }
                            }}
                          >
                            {SelectedFilter?.location.length > 0 ? (
                              <p title={SelectedFilter?.location?.join()}>
                                {SelectedFilter?.location?.join()}
                              </p>
                            ) : (
                              <p style={{ textTransform: "none" }}>
                                Select a location
                              </p>
                            )}

                            {ShowLocation && (
                              <div
                                className="suggestion_wrapper"
                                ref={primaryRef}
                              >
                                <input
                                  type={"text"}
                                  placeholder="search"
                                  className="location_search_input"
                                  onChange={(e) => {
                                    setLocationSearch(e.target.value);
                                  }}
                                  value={LocationSearch}
                                />
                                <Scroller autoHeightMax="18vh">
                                  <div className="suggestion">
                                    {filteredItems?.map((item, idx) => (
                                      <button
                                        key={item}
                                        className="options"
                                        onClick={() => {
                                          let _selectedFilter = {
                                            ...SelectedFilter,
                                          };
                                          if (
                                            _selectedFilter.location.includes(
                                              item
                                            )
                                          ) {
                                            let _idx =
                                              _selectedFilter.location.indexOf(
                                                item
                                              );
                                            _selectedFilter.location.splice(
                                              _idx,
                                              1
                                            );
                                            setSelectedFilter({
                                              ..._selectedFilter,
                                            });
                                          } else {
                                            setSelectedFilter({
                                              ...SelectedFilter,
                                              location: [
                                                ...SelectedFilter.location,
                                                item,
                                              ],
                                            });
                                          }
                                        }}
                                        style={{
                                          backgroundColor:
                                            SelectedFilter?.location?.includes(
                                              item
                                            )
                                              ? "var(--primary)"
                                              : null,
                                          color:
                                            SelectedFilter?.location?.includes(
                                              item
                                            )
                                              ? "#fff"
                                              : null,
                                        }}
                                      >
                                        {item}
                                        <Radio
                                          style={{ pointerEvents: "none" }}
                                          id={"checkbox_" + idx}
                                          checked={SelectedFilter?.location?.includes(
                                            item
                                          )}
                                        />
                                      </button>
                                    ))}
                                  </div>
                                </Scroller>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}

                    {/* {FilterOptions.location.length > 0 && (
                      <MultiSelectDropdown
                        style={{ width: "7.2vw" }}
                        className="adjust_dd"
                        label="Location"
                        id={"Location"}
                        optionsList={FilterOptions.location}
                        handleOption={(data) => {
                          setIsLoading(true);
                          setSelectedFilter({
                            ...SelectedFilter,
                            location: [...data],
                          });
                        }}
                        defaultText={SelectedFilter.location}
                      />
                    )} */}
                    {/* <div className="manufacturer_filter_classname">
                      {FilterOptions.manufacturer.length > 0 && (
                        <MultiSelectDropdown
                          style={{ width: "7.5vw" }}
                          className="adjust_dd"
                          label="Manufacturer"
                          id={"Manufacturer"}
                          optionsList={FilterOptions.manufacturer}
                          handleOption={(data) => {
                            setIsLoading(true);
                            setSelectedFilter({
                              ...SelectedFilter,
                              manufacturer: [...data],
                            });
                          }}
                          defaultText={SelectedFilter.manufacturer}
                        />
                      )}
                    </div> */}
                    {FilterOptions.version.length > 0 && (
                      <MultiSelectDropdown
                        style={{ width: "7.2vw" }}
                        className="adjust_dd"
                        label="Version"
                        id={"Version"}
                        optionsList={FilterOptions.version}
                        handleOption={(data) => {
                          setIsLoading(true);
                          setSelectedFilter({
                            ...SelectedFilter,
                            version: [...data],
                          });
                        }}
                        defaultText={SelectedFilter.version}
                      />
                    )}
                  </div>
                  <div className="selected_filter">
                    {Object.keys(SelectedFilter).map((item) =>
                      SelectedFilter[item].map((item2, idx) => (
                        <FilterTab
                          key={item2}
                          tag={item2}
                          onDelete={() => {
                            setIsLoading(true);
                            let _data = { ...SelectedFilter };
                            _data[item].splice(idx, 1);
                            setSelectedFilter({ ..._data });
                          }}
                        />
                      ))
                    )}
                  </div>
                </div>
                <div className="flex_">
                  {/* {encryptStorage.getItem("UID")?.current === "manufacturer" &&
                    !isSuspended() && (
                      <Button
                        name={"Add / Edit Licence"}
                        onClick={() => setAddLicence(true)}
                        style={{
                          padding: "0.4vw",
                          position: "absolute",
                          top: "0.5vw",
                          width: "10vw",
                          right: "0.5vw",
                        }}
                      />
                    )} */}
                  <div className="device_count"></div>
                  <p className="device_count_text">Devices</p>
                </div>
              </div>
              {Device?.length > 0 && !IsLoading && (
                <Scroller autoHeightMax="57vh">
                  <div
                    className="device_list_container"
                    style={{ paddingBottom: TotalPages > 1 ? "6vw" : "1vw" }}
                  >
                    {Device?.map((item, idx) => (
                      <DeviceList
                        idx={idx}
                        key={item.device_name + "_" + idx}
                        name={item.device_name?.replaceAll("_", " ")}
                        status={item.status}
                        serial_number={item.serial_number}
                        id={"device_" + idx}
                        history={history}
                        configurations={{
                          serial_number: item.serial_number,
                          local_ip: item.configurations.local_ip,
                          present_status: item.status,
                        }}
                        user_type={item.user_type}
                        created_on={item.created_on}
                        beta_testing={item.beta_testing}
                        handleToggle={(value) => {
                          toggleDevice(value, item.serial_number, idx);
                        }}
                        item={item}
                        SelectedFilter={SelectedFilter}
                        loc="Bandra"
                        Location={Location}
                        onAddClick={() => {
                          // selectedDevice.manu_key = item.manufacturer_key;
                          // selectedDevice.licence = item?.licence ? true : false;
                          selectedDevice.serial_number = item.serial_number;
                          selectedDevice.device_name = item.device_name;
                          // setRenewLicence(true);
                          setFetchLicense(true);
                          getLicenses(item.manufacturer_username);
                          setManuName(item.manufacturer_username);
                        }}
                      />
                    ))}
                  </div>
                </Scroller>
              )}

              {Device.length === 0 && !IsLoading && (
                <div className="noData">No Device Found</div>
              )}

              {Device.length > 0 && TotalPages > 1 && (
                <div
                  className="device_bottom"
                  style={{
                    background:
                      themeContext.Theme === "Light"
                        ? "linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.6783088235294117) 100%)"
                        : "linear-gradient(0deg, rgba(41,49,58,1) 0%, rgba(41,49,58,0.5183088235294117) 100%)",
                  }}
                >
                  <div style={{ width: "11.1979166667vw" }}></div>
                  {/* <Button
                    name={"Show More Devices"}
                    type="gradient"
                    bg={true}
                  /> */}
                  <Pagination
                    totPages={TotalPages}
                    // totPages={10}
                    currentPage={CurrentPage}
                    rowCount={10}
                    pageClicked={(ele, count_) => {
                      var url = new URL(window.location.href);
                      url.searchParams.set("page", ele);
                      window.history.pushState(null, null, url);
                      // isAllSelected = false;
                      // setselectedRow([]);
                      setCurrentPage(ele);
                      getFilter(ele);
                      // if (type === "all") {
                      //   getUnfilteredData(ele, count_);
                      // }
                      // if (type === "filter" || type === "search") {
                      //   postFilter(ele, count_);
                      // }
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </Navbar>
      <AnimatedModal
        modalOpen={isModalOpen}
        handleClose={() => {
          // setshowModal((prevState) => ({
          //   ...prevState,
          //   showPop: false,
          // }));
        }}
        // type={showModal.type ? showModal.type : "alert"}
        errorHeader={"Error"}
        errorText={"showModal.msg"}
      >
        <div
          className="close_otp_modal"
          onClick={() => {
            setisModalOpen(false);
          }}
        >
          {closeIcon()}
        </div>
        <h1 className="modal_head">Add Devices</h1>
        <InputBox
          id="devices"
          placeholder="in number"
          header="Number of devices"
          onChange={(e) => {
            setDeviceCount(e.target.value.replace(/[^0-9]/g, ""));
          }}
          // error={errors["isFNameEmpty"]}
          value={DeviceCount}
          // onFocus={() => clearError("isFNameEmpty")}
        />
        <div style={{ height: "1vw" }} />
        <InputBox
          id="warranty"
          placeholder="example 1 year"
          header="Warranty In years"
          onChange={(e) => {
            setWarranty(e.target.value.replace(/[^0-9]/g, ""));
          }}
          // error={errors["isFNameEmpty"]}
          value={Warranty}
          // onFocus={() => clearError("isFNameEmpty")}
        />
        <Button
          style={{
            display: "block",
            margin: "2vw auto",
            marginTop: "2.2vw",
            marginBottom: "1.2vw",
            width: "90%",
          }}
          type="gradient"
          bg
          name="Generate Devices"
          onClick={() => {
            // setshowModal(true);
            // setshowOTPContainer(true);
          }}
        />
      </AnimatedModal>
      <AddDeviceLicence
        setIsLoading={setIsLoading}
        AddLicence={AddLicence}
        setAddLicence={setAddLicence}
        setDeleteConfirm={(apiData, value, attribute_name) => {
          getLicenceData = apiData;
          _attribute_name = attribute_name;
          setDeleteConfirm(value);
        }}
      />

      {/* <UpgradeLicence
        setIsLoading={setIsLoading}
        RenewLicence={RenewLicence}
        setRenewLicence={setRenewLicence}
        selectedDevice={selectedDevice}
      /> */}

      <UpgradeLicense2
        RenewLicence={RenewLicence}
        setRenewLicence={setRenewLicence}
        SelectedLicenseDetails={SelectedLicenseDetails}
        setSelectedLicenseDetails={setSelectedLicenseDetails}
        selectedDevice={selectedDevice}
        _mapping_id={_mapping_id}
        _license_name={_license_name}
      />

      <SelectLicense
        FetchLicense={FetchLicense}
        setFetchLicense={setFetchLicense}
        StandardLicenses={StandardLicenses}
        getLicenses={getLicenses}
        manuName={manuName}
        onBuyClick={(index) => {
          _license_name = StandardLicenses[index].license_details.name;
          addCountKey(index);
          setRenewLicence(true);
          setFetchLicense(false);
          // _license_index = index
          // license_type = "Standard"
        }}
        type="device"
        getSpecialLicenses={getSpecialLicenses}
        specialLicense={specialLicense}
        changePage={changePage}
        setChangePage={setChangePage}
        specialLicenseTotalCount={specialLicenseTotalCount}
        flag={flag}
        setFlag={setFlag}
        selectCard={selectCard}
        ShowAlert={ShowAlert}
        setShowAlert={setShowAlert}
        msg={msg}
        serialNumber={selectedDevice.serial_number}
        // serialNumber={Device.}
      />

      <AnimatedModal
        modalOpen={DeleteConfirm}
        onConfirm={() => {
          setDeleteConfirm(false);
          deleteData();
          //call delete API
        }}
        handleClose={() => {
          setDeleteConfirm(false);
        }}
        type={"confirm"}
        errorText={"Are you sure?"}
        alwayOpen={true}
      />
      <AnimatedModal
        modalOpen={ShowAlert}
        handleClose={() => {
          setShowAlert(false);
        }}
        type={msg.type}
        errorHeader={msg.header}
        errorText={msg.errorText}
        // timeout={5000}
        alwayOpen={true}
      />

      {IsLoading && <Loading type={"transparent"} text={"Loading"} />}
      <UserLoginModal
        showUserLogin={showUserLogin}
        setshowUserLogin={setshowUserLogin}
        getFilterOptions={getFilterOptions}
        // setConfirm={setConfirm}
      />
    </div>
  );
}

const FilterTab = ({ tag, onDelete }) => (
  <div className="filter_tab">
    <p>{tag}</p>
    {tickIcon2()}
    {close(onDelete)}
  </div>
);

const gridIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14.333"
    height="14.333"
    viewBox="0 0 14.333 14.333"
  >
    <g id="ic-layout-square-grid" transform="translate(0.5 0.5)">
      <g id="Layer">
        <path
          id="Vector"
          d="M1.333,0H12a1.333,1.333,0,0,1,1.333,1.333V12A1.333,1.333,0,0,1,12,13.333H1.333A1.333,1.333,0,0,1,0,12V1.333A1.333,1.333,0,0,1,1.333,0Z"
          fill="none"
          stroke="#4d4d4d"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Vector-2"
          data-name="Vector"
          d="M0,0H13.333"
          transform="translate(0 6.667)"
          fill="none"
          stroke="#4d4d4d"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Vector-3"
          data-name="Vector"
          d="M0,0V13.333"
          transform="translate(6.667)"
          fill="none"
          stroke="#4d4d4d"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </g>
  </svg>
);

const listIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14.333"
    height="14.333"
    viewBox="0 0 14.333 14.333"
  >
    <g id="ic-layout-sections" transform="translate(0.5 0.5)">
      <g id="Layer">
        <path
          id="Vector"
          d="M1.333,0H12a1.333,1.333,0,0,1,1.333,1.333V12A1.333,1.333,0,0,1,12,13.333H1.333A1.333,1.333,0,0,1,0,12V1.333A1.333,1.333,0,0,1,1.333,0Z"
          fill="none"
          stroke="#4d4d4d"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Vector-2"
          data-name="Vector"
          d="M0,0H13.333"
          transform="translate(0 3.333)"
          fill="none"
          stroke="#4d4d4d"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Vector-3"
          data-name="Vector"
          d="M0,0H13.333"
          transform="translate(0 6.667)"
          fill="none"
          stroke="#4d4d4d"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
        <path
          id="Vector-4"
          data-name="Vector"
          d="M0,0H13.333"
          transform="translate(0 10)"
          fill="none"
          stroke="#4d4d4d"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </g>
  </svg>
);

export const addIcon = (onClick) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="36"
    viewBox="0 0 36 36"
    className="addDevice"
    onClick={onClick}
  >
    <defs>
      <linearGradient
        id="linear-gradient3"
        y1="0.203"
        x2="0.927"
        y2="0.831"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#013aa2" />
        <stop offset="1" stopColor="#2d62ed" />
      </linearGradient>
    </defs>
    <g id="Create" transform="translate(0.063 0)">
      <circle
        id="Ellipse_17668"
        data-name="Ellipse 17668"
        cx="18"
        cy="18"
        r="18"
        transform="translate(-0.063 0)"
        fill="url(#linear-gradient3)"
      />
      <g
        id="Group_1338"
        data-name="Group 1338"
        transform="translate(9.247 9.252)"
      >
        <rect
          id="Rectangle_2545"
          data-name="Rectangle 2545"
          width="2.313"
          height="16.962"
          transform="translate(7.325 0)"
          fill="#fff"
        />
        <rect
          id="Rectangle_3266"
          data-name="Rectangle 3266"
          width="2.313"
          height="16.962"
          transform="translate(16.962 7.325) rotate(90)"
          fill="#fff"
        />
      </g>
    </g>
  </svg>
);

const close = (onClick) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={12.499}
    height={12.499}
    viewBox="0 0 12.499 12.499"
    className="closeIcon fadeIn"
    onClick={onClick}
  >
    <g
      id="Group_15572"
      data-name="Group 15572"
      transform="translate(-1484.439 -359.439)"
    >
      <line
        id="Line_302"
        data-name="Line 302"
        x2={10.378}
        y2={10.378}
        transform="translate(1485.5 360.5)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeWidth={1.5}
      />
      <line
        id="Line_303"
        data-name="Line 303"
        x1={10.378}
        y2={10.378}
        transform="translate(1485.5 360.5)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeWidth={1.5}
      />
    </g>
  </svg>
);
