import React, { useRef } from "react";
import Navbar from "../../../components/Navbar/Navbar";
import "./appversion.scss";
import bg from "../../../assets/images/background.png";
import { useState } from "react";
import { item } from "../../../helper/motions";
import { randomID } from "../../../helper/request";
import Toggle from "../../../components/Toggle/Toggle";
import InputBox from "../../../components/Inputbox/InputBox";
import logo from "../../../assets/images/logo.png";
import { Radio } from "../../../components/Radio/Radio";
import RangeSlider from "../../../components/RangeSlider/RangeSlider";
import { eyeCloseIcon, eyeOpenIcon, noData } from "../../../helper/icons";
let scrollToBottom = null;
const elementObj = {
  "Text Box": {
    elementName: randomID(),
    type: "Text Box",
    label: "Key name",
    pattern: "",
    required: false,
    options: [],
    isVisible: true,
  },
  "Radio Button": {
    elementName: randomID(),
    type: "Radio Button",
    label: "Key name",
    // pattern: "",
    required: false,
    options: ["Option 1", "Option 2"],
    isVisible: true,
  },
  Checkbox: {
    elementName: randomID(),
    type: "Checkbox",
    label: "Key name",
    // pattern: "",
    required: false,
    options: ["Option 1", "Option 2"],
    isVisible: true,
  },
  "Drop Down": {
    elementName: randomID(),
    type: "Drop Down",
    label: "Key name",
    multiselect: false,
    required: false,
    options: ["Option 1", "Option 2"],
    isVisible: true,
  },
  Time: {
    elementName: randomID(),
    type: "Time",
    label: "Key name",
    required: false,
    options: [],
    isVisible: true,
  },
  Range: {
    elementName: randomID(),
    type: "Range",
    label: "Key name",
    required: false,
    options: [0, 10, 1], //min, max, step
    isFloating: false,
    isVisible: true,
  },
};
export default function AppVersion() {
  const optionRef = useRef(null);
  const optionRef2 = useRef(null);
  const [Dummy, setDummy] = useState(10);
  const [ElementTypes, setElementTypes] = useState([
    "Text Box",
    "Radio Button",
    "Drop Down",
    "Checkbox",
    "Time",
    "Range",
  ]);
  const [Elements, setElements] = useState([]);

  const updateVisibilty = (idx, type = "hideAll") => {
    console.log("updateVisibilty", idx);
    let _element = [...Elements];
    // _element.map((item) => (item.isVisible = false));
    if (_element[idx].isVisible) {
      console.log("IF");
      _element[idx].isVisible = false;
      setElements([..._element]);
    } else {
      console.log("ELSE");
      console.log(idx);
      _element[idx].isVisible = true;
      // _element.map((item, index) => {
      //   if (idx != index) {
      //     console.log(idx, index);
      //     _element[index].isVisible = false;
      //   } else {
      //     console.log(index);
      //     _element[index].isVisible = true;
      //   }
      // });
      setElements([..._element]);
    }
    console.log(JSON.stringify(_element));
  };

  const getFormElement = (
    { type, pattern, options, required, multiselect, isFloating },
    idx
  ) => {
    switch (type) {
      case "Text Box":
        return (
          <div className="textbox_wrapper">
            <InputBox
              id={"pattern" + idx}
              header="Pattern (Optional)"
              onFocus={(event) => event.target.select()}
              onChange={(e) => {
                const value = e.target.value;
                let _element = [...Elements];
                _element[idx].pattern = value.split("/").join("");
                setElements([..._element]);
              }}
              value={pattern}
              placeholder="Enter regex pattern (example: ^[a-zA-Z ]*$)"
            />

            <div className="_flex">
              <InputBox
                id={"min" + idx}
                header="Min length of value (Optional)"
                onFocus={(event) => event.target.select()}
                onChange={(e) => {
                  const value = e.target.value;
                  let _element = [...Elements];
                  if (/^[1-9][0-9]*$/.test(value) || value === "") {
                    _element[idx].options[0] = value;
                    setElements([..._element]);
                  }
                }}
                value={options[0] ? options[0] : ""}
              />
              <InputBox
                id={"max" + idx}
                header="Max length of value (Optional)"
                onFocus={(event) => event.target.select()}
                onChange={(e) => {
                  const value = e.target.value;
                  let _element = [...Elements];
                  if (/^[1-9][0-9]*$/.test(value) || value === "") {
                    _element[idx].options[1] = value;
                    setElements([..._element]);
                  }
                }}
                value={options[1] ? options[1] : ""}
              />
            </div>
          </div>
        );
      case "Radio Button":
      case "Checkbox":
        return (
          <div className="textbox_wrapper radiobtn_wrapper">
            <InputBox
              ref={optionRef}
              id={"min" + idx}
              header="Options"
              placeholder="Enter a option and press enter"
              onFocus={(event) => event.target.select()}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  const value = optionRef.current.value;
                  console.log(value);
                  if (value) {
                    let _element = [...Elements];
                    _element[idx].options.push(value);
                    setElements([..._element]);
                    optionRef.current.value = "";
                  }
                }
              }}
            />
            <div className="radio_options_wrapper">
              {options.map((optionItem, oidx) => {
                return (
                  <InputBox
                    id={"radio_option_" + Number(idx + oidx)}
                    key={"radio__option_" + Number(idx + oidx)}
                    onFocus={(event) => event.target.select()}
                    value={optionItem}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value) {
                        let _element = [...Elements];
                        _element[idx].options[oidx] = value;
                        setElements([..._element]);
                      }
                    }}
                    children={
                      ![0, 1].includes(oidx) &&
                      deleteIcon(() => {
                        let _element = [...Elements];
                        _element[idx].options.splice(oidx, 1);
                        setElements([..._element]);
                      })
                    }
                  />
                );
              })}
            </div>
          </div>
        );
      case "Drop Down":
        return (
          <div className="textbox_wrapper radiobtn_wrapper">
            <p className="d_label">Multiselect?</p>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "0.9375vw",
              }}
            >
              <Toggle
                handleValue={(value) => {
                  let _element = [...Elements];
                  _element[idx].multiselect = value;
                  setElements([..._element]);
                }}
                value={multiselect}
                id={"multiselect" + idx}
              />
              <span className="tpggle_value fadeIn">
                {multiselect ? "Yes" : "No"}
              </span>
            </div>
            <InputBox
              ref={optionRef2}
              id={"min" + idx}
              header="Options"
              placeholder="Enter a option and press enter"
              onFocus={(event) => event.target.select()}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  const value = optionRef2.current.value;
                  if (value) {
                    let _element = [...Elements];
                    _element[idx].options.push(value);
                    setElements([..._element]);
                    optionRef2.current.value = "";
                  }
                }
              }}
            />
            <div className="radio_options_wrapper">
              {options.map((optionItem, oidx) => {
                return (
                  <InputBox
                    key={"dd__option_" + Number(idx + oidx)}
                    id={"dd_option_" + Number(idx + oidx)}
                    onFocus={(event) => event.target.select()}
                    value={optionItem}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value) {
                        let _element = [...Elements];
                        _element[idx].options[oidx] = value;
                        setElements([..._element]);
                      }
                    }}
                    children={
                      ![0, 1].includes(oidx) &&
                      deleteIcon(() => {
                        let _element = [...Elements];
                        _element[idx].options.splice(oidx, 1);
                        setElements([..._element]);
                      })
                    }
                  />
                );
              })}
            </div>
          </div>
        );
      case "Time":
        return <div className="timer_wrapper">Timer</div>;
      case "Range":
        return (
          <div className="textbox_wrapper range_wrapper">
            <p className="d_label">Floating?</p>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "0.9375vw",
              }}
            >
              {console.log(isFloating)}
              <Toggle
                handleValue={(value) => {
                  console.log(value);
                  let _element = [...Elements];
                  _element[idx].isFloating = value;
                  setElements([..._element]);
                }}
                value={isFloating}
                id={"toggle__" + idx}
              />
              <span className="tpggle_value fadeIn">
                {isFloating ? "Yes" : "No"}
              </span>
            </div>
            {isFloating && (
              <InputBox
                id={"step" + idx}
                header="Step"
                onFocus={(event) => event.target.select()}
                onChange={(e) => {
                  const value = e.target.value;
                  let _element = [...Elements];
                  if (/^(\d)*(\.)?([0-9]{1})?$/.test(value) || value === "") {
                    _element[idx].options[2] = value;
                    setElements([..._element]);
                  }
                }}
                value={options[2]}
              />
            )}
            <div className="_flex">
              <InputBox
                id={"min" + idx}
                header="Min length of value (Optional)"
                onFocus={(event) => event.target.select()}
                onChange={(e) => {
                  const value = e.target.value;
                  const value2 = Number(e.target.value) + 1;
                  let _element = [...Elements];
                  _element[idx].options[0] = value.replace(/[^0-9]/g, "");
                  _element[idx].options[1] = value2
                    .toString()
                    .replace(/[^0-9]/g, "");
                  setElements([..._element]);
                }}
                value={options[0] >= 0 ? options[0] : ""}
              />
              <InputBox
                id={"max" + idx}
                header="Max length of value (Optional)"
                onFocus={(event) => event.target.select()}
                onChange={(e) => {
                  const value = e.target.value;
                  let _element = [...Elements];
                  if (/^[1-9][0-9]*$/.test(value) || value === "") {
                    _element[idx].options[1] = value;
                    setElements([..._element]);
                  }
                }}
                value={options[1] ? options[1] : ""}
              />
            </div>
          </div>
        );
      default:
        return "foo";
    }
  };

  const getPreviewFormElement = (element, idx) => {
    switch (element.type) {
      case "Text Box":
        return <InputBox key={element.label + idx} header={element.label} />;
      case "Radio Button":
        return (
          <React.Fragment>
            <p className="radio-header _star">{element.label}</p>
            <div className="radio_group">
              {element.options.map((item, index) => (
                <Radio
                  key={item}
                  name={item}
                  id={"radio_" + index}
                  className="adjust_cbb"
                />
              ))}
            </div>
          </React.Fragment>
        );
      case "Range":
        return (
          <React.Fragment>
            <RangeSlider
              min={element.options[0]}
              max={element.options[1]}
              step={element.options[2]}
              value={0}
              onChange={(value) => {
                setDummy(value.toFixed(2));
                console.log(value);
              }}
              //  onChangeComplete={this.handleChangeComplete}
              orientation={"horizontal"}
            />
          </React.Fragment>
        );

      default:
        break;
    }
    // if (element.type === "text" || element.type === "input") {
    //   return <InputBox label={element.label} />;
    // }
    // if (element.type === "select") {
    //   return (
    //     <SelectField
    //       name={element.elementName}
    //       label={element.label}
    //       options={element.options}
    //       value={element.value?.length > 0 ? element.value : ""}
    //     />
    //   );
    // }
    // if (element.type === "radio") {
    //   return (
    //     <RadioField
    //       name={element.elementName}
    //       error={element.error}
    //       label={element.label}

    //       options={element.options}
    //     />
    //   );
    // }
    // if (element.type === "checkbox") {
    //   return (
    //     <CheckboxField
    //       name={element.elementName}
    //       error={element.error}
    //       label={element.label}

    //       options={element.options}
    //     />
    //   );
    // }
    // if (element.type === "counter") {
    //   return (
    //     <CounterField
    //       name={element.elementName}
    //       error={element.error}
    //       label={element.label}
    //       options={element.options}
    //     />
    //   );
    // }

    // if (element.type === "time") {
    //   return (
    //     <TimeField
    //       name={element.elementName}
    //       error={element.error}
    //       label={element.label}
    //       options={element.options}
    //     />
    //   );
    // }
  };
  return (
    <div className="_app_v_wrapper_">
      {console.log(Elements)}
      <Navbar
        sidenav
        handleScroll={(data) => {
          scrollToBottom = data;
        }}
      >
        <div className="app_v_body">
          <img src={bg} className="profile_bg" />
          <div className="body_content fadeIn">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                opacity: 0,
              }}
            >
              <h1>App Version</h1>
              <p className="goBack"></p>
            </div>
            <div className="app_v_data">
              <h1 className="app_header">
                Create Version For <span>Mask Detection</span>
              </h1>
              <div className="steps"></div>
              <div className="element_type_wrapper">
                <div className="element_types">
                  <p style={{ fontSize: "1.3541666667vw" }}>
                    Select UI Element as per Your App Requirement From Given
                    list below :
                  </p>
                  <div className="element_Container">
                    {ElementTypes.map((item) => (
                      <div
                        className="elements"
                        role={"button"}
                        key={item}
                        onClick={() => {
                          setElements([...Elements, elementObj[item]]);
                          setTimeout(() => {
                            scrollToBottom();
                          }, 0);
                        }}
                      >
                        {item} +
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              {Elements.length === 0 ? (
                <div className="noData_wrapper">
                  <div className="noData-block">
                    {noData()}
                    <p>Configure your own customized settings.</p>
                  </div>
                </div>
              ) : (
                <div className="element_preview_setting_container">
                  <div className="element_setting">
                    {Elements.map((element, idx) => {
                      return (
                        <div
                          key={"element__" + idx}
                          className="dynamic_element_wrapper"
                        >
                          <div className="element_header">
                            <div className="element_type">
                              {Number(idx + 1)}.<span>{element.type}</span>
                            </div>
                            <div className="line" />
                            {element.isVisible
                              ? eyeOpenIcon(() => updateVisibilty(idx))
                              : eyeCloseIcon(() => updateVisibilty(idx))}
                            {/* {deleteIcon(() => {
                              let _element = [...Elements];
                              _element.splice(idx, 1);
                              setElements([..._element]);
                            })} */}
                          </div>
                          {element.isVisible && (
                            <React.Fragment>
                              <div className="element_static_info">
                                <InputBox
                                  id={"keyName_" + idx}
                                  header="Key name"
                                  onFocus={(event) => event.target.select()}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    let _element = [...Elements];
                                    _element[idx].label = value;
                                    setElements([..._element]);
                                    // const regex = /^[a-zA-Z ]*$/;
                                    // if (value.match(regex) || value === "") {
                                    //   setCompanyName(value);
                                    // }
                                  }}
                                  value={element.label}
                                />
                                <p className="d_label">Required?</p>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginBottom: "0.9375vw",
                                  }}
                                >
                                  <Toggle
                                    handleValue={(value) => {
                                      let _element = [...Elements];
                                      _element[idx].required = value;
                                      setElements([..._element]);
                                    }}
                                    value={element.required}
                                    id={"toggle_" + idx}
                                  />
                                  <span className="tpggle_value fadeIn">
                                    {element.required ? "Yes" : "No"}
                                  </span>
                                </div>
                              </div>

                              {getFormElement(element, idx)}
                            </React.Fragment>
                          )}
                        </div>
                      );
                    })}
                  </div>
                  <div className="element_preview">
                    <div className="canvas">
                      <img src={logo} alt="" className="canvas_image" />
                    </div>
                    <div className="element_preview_block">
                      {Elements.map((item, idx) =>
                        getPreviewFormElement(item, idx)
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Navbar>
    </div>
  );
}

const deleteIcon = (onClick) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={29}
    height={29}
    viewBox="0 0 29 29"
    className="deleteIcon"
    onClick={onClick}
  >
    <defs>
      <linearGradient
        id="delete-linear-gradient"
        x2={1.085}
        y2={1.062}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#013aa2" />
        <stop offset={1} stopColor="#2d62ed" />
      </linearGradient>
    </defs>
    <g
      id="Group_16211"
      data-name="Group 16211"
      transform="translate(-2092 4076)"
    >
      <rect
        id="background"
        width={29}
        height={29}
        rx={14.5}
        transform="translate(2092 -4076)"
        fill="url(#delete-linear-gradient)"
      />
    </g>
    <path
      id="delete_forever_FILL0_wght500_GRAD0_opsz48"
      d="M10.036,23.294a1.566,1.566,0,0,1-1.162-.5,1.6,1.6,0,0,1-.484-1.15V7.944H8.123A.782.782,0,0,1,7.53,7.7a.814.814,0,0,1-.23-.581A.788.788,0,0,1,8.123,6.3h3.971a.821.821,0,0,1,.242-.605.792.792,0,0,1,.581-.242h4.939a.792.792,0,0,1,.581.242.821.821,0,0,1,.242.605H22.65a.782.782,0,0,1,.593.242.814.814,0,0,1,.23.581.788.788,0,0,1-.823.823h-.266v13.7A1.6,1.6,0,0,1,21.9,22.8a1.566,1.566,0,0,1-1.162.5Zm0-15.35v13.7h10.7V7.944Zm0,0v0Zm5.351,8.014,2.276,2.3a.908.908,0,0,0,1.235,0,.941.941,0,0,0,.254-.617.734.734,0,0,0-.254-.593l-2.276-2.324L18.9,12.4a.864.864,0,0,0,.254-.605.748.748,0,0,0-.254-.605.908.908,0,0,0-1.235,0l-2.276,2.3-2.252-2.3a.734.734,0,0,0-.593-.254,1.038,1.038,0,0,0-.642.254.792.792,0,0,0-.254.605,1.024,1.024,0,0,0,.254.629l2.276,2.3-2.276,2.3a.875.875,0,0,0-.242.605.83.83,0,0,0,.872.872.875.875,0,0,0,.605-.242Z"
      transform="translate(-1.3 0.55)"
      fill="#fff"
    />
  </svg>
);
