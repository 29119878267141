import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import Scroller from "../../../../../../components/Scroller/ScrollBar";
import { SetInventoryCount } from "./SetInventoryCount/SetInventoryCount";

let flag = false;

export const AssignedUsersList = forwardRef((props, ref) => {
  const scrollbar = useRef(null);
  const [userList, setUserList] = useState({});
  const [selectedUsername, setSelectedUsername] = useState("");

  const handleScroll = () => {
    let totalUserCount =
      props.totalCount + Object.keys(props.newlyAddedUserList).length;

    if (
      scrollbar.current.getValues().top > 0.95 &&
      totalUserCount > Object.keys(userList).length
    ) {
      if (
        props.page <=
          Math.floor(totalUserCount / Object.keys(userList).length) &&
        !flag
      ) {
        flag = true;
        console.log("bottom");
        props.setPage((prevState) => prevState + 1);
      }
    }
  };

  const setInventoryCount = (username, count) => {
    setUserList((prevState) => {
      let _temp = { ...prevState };
      _temp[username] = { ...count };
      return _temp;
    });
  };

  useImperativeHandle(ref, () => ({
    getUserList: () => {
      return userList;
    },
  }));

  useEffect(() => {
    if (
      Object.keys(userList).length !==
      Object.keys({ ...props.assignedUserList, ...props.newlyAddedUserList })
        .length
    ) {
      setUserList({ ...props.assignedUserList, ...props.newlyAddedUserList });
      flag = false;
    }
  });

  return (
    <div className="table_special_wrapper">
      {console.log(ref, "refff")}
      <table style={{ marginTop: "0.6vw" }} id="mfr">
        <tr style={{ height: "4vh" }}>
          <th style={{ textAlign: "center" }}>Sr.No</th>
          <th style={{ textAlign: "left", textTransform: "capitalize", width: "19vw" }}>{props.userRole}</th>
          <th style={{ textAlign: "center" }}>No. of licenses</th>
        </tr>
      </table>
      {console.log(Object.keys(userList).length, "lengthhhh")}
      <Scroller
        autoHeightMax="9.5vw"
        verticalStyle={{
          width: "0.3vw",
          marginLeft: "0.1vw",
          background: "#D9D9D9",
        }}
        onScroll={handleScroll}
        ref={scrollbar}
      >
        <table id="mfr_data" style={{ width: "100%" }}>
          {Object.keys(userList).map((item, index) => (
            <tr
              key={index + "mfrMap"}
              className={
                index % 2 === 0 ? "change_color tr_color  " : "tr_color"
              }
            >
              <td style={{ textAlign: "center", width: "7vw" }}>{index + 1}</td>
              <td
                style={{ textAlign: "left", width: "22vw" }}
                id={"name" + index}
              >
                {item}
              </td>
              <td
                style={{
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                className="table_mfr_input"
              >
                <SetInventoryCount
                  remainingCount={props.remainingCount}
                  setRemainingCount={props.setRemainingCount}
                  availableCount={props.availableCount}
                  userList={userList}
                  username={item}
                  counts={userList[item]}
                  setInventoryCount={setInventoryCount}
                  setDisableSave={props.setDisableSave}
                  selectedUsername={selectedUsername}
                  setSelectedUsername={setSelectedUsername}
                  newlyAddedUserList={props.newlyAddedUserList}
                />
              </td>
              
              {Object.keys(props.newlyAddedUserList).includes(item) && (
                <td
                  style={{
                    cursor: "pointer",
                    textAlign: "right",
                    fontSize: "0.7vw",
                    width: "5vw",
                  }}
                  onClick={() => {
                    props.removeUser(item);
                    // Object.keys(props.newlyAddedUserList).length !== 0 && props.setDisableSave(false)
                  }}
                >
                  {trashIcon()} remove
                </td>
              )}
            </tr>
          ))}
        </table>
      </Scroller>
    </div>
  );
});

const trashIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
      style={{ height: "0.6vw", width: "0.6vw" }}
      fill="#00000080"
    >
      <path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z" />
    </svg>
  );
};
