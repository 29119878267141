import React, { useState, useEffect, useRef, useContext } from "react";
import Navbar from "../../../components/Navbar/Navbar";
import "./apps.scss";
import bg from "../../../assets/images/background.png";
import Searchbar from "../../../components/Searchbar/Searchbar";
import Dropdown from "../../../components/Dropdown/Dropdown";
import Button from "../../../components/Button/Button";
import Widget from "../../../components/Widget/Widget";
import OTP from "../../../components/OTP/OTP";
import Loading from "../../../components/Loading/Loading";
import { encryptStorage } from "../../../helper/storage";
import AnimatedModal from "../../../components/Modal/AnimatedModal";
import Scroller from "../../../components/Scroller/ScrollBar";
import { axiosApiInstance } from "../../../helper/request";
import axios from "axios";
import { API_URL, OTP_TIME } from "../../../helper/request";
import addapp from "../../../assets/images/addapp.png";
import { Link, useHistory, useParams } from "react-router-dom";
import { useDebouncedEffect } from "../../../helper/useDebounce";
import { plus } from "../../../helper/icons";
import { item } from "../../../helper/motions";
import { ThemeContext } from "../../../helper/context";
import UserLoginModal from "../../shared/Device/UserLoginModal";
import { useQuery } from "../../../helper/useQuery";
import SelectLicense from "../../shared/Device/SelectLicense";
import UpgradeLicense2 from "../../shared/Device/UpgradeLicense2";
import { notify } from "../../../Routes";
let changePage = 1;
let flag = false;
let selectedAppName = "";
let Role = [];
let msg = {
  type: "alert",
  header: "Error",
  errorText: "Server Error",
};
let resetCounter = null;
let _license_name = null;
let _mapping_id = null;
let selectedDevice = {
  licence: false,
  manu_key: null,
};
let deviceName = "";
let manufacturerName = "";
export default function Apps(props) {
  const queryy = useQuery();
  let { id } = useParams();

  {
    console.log(props.match.params.id, id, "serialllllllllll");
  }

  let serial_number = queryy.get("id");

  const appGroup = {
    owned: "Owned",
    purchased: "Purchased",
  };
  const [FilterOptions, setFilterOptions] = useState({
    logic: "logic",
    processing: "processing",
    action: "action",
  });

  const [ShowOTP, setShowOTP] = useState(false);
  const [OTPCloseConfirm, setOTPCloseConfirm] = useState(false);
  const [Stop, setStop] = useState(false);
  const [items, setItems] = useState(12);
  const [SelectedFilter, setSelectedFilter] = useState("");
  const [appDetails, setAppDetails] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const [loadingScreen, setLoadingScreen] = useState(true);
  const [searchAppName, setSearchAppName] = useState(undefined);
  const [totalCount, setTotalCount] = useState();
  const history = useHistory();
  const scrollbar = useRef();
  const themeContext = useContext(ThemeContext);
  const [showUserLogin, setshowUserLogin] = useState(false);
  const [OpenAddLicense, setOpenAddLicense] = useState(false);
  const [standardLicenses, setStandardLicenses] = useState([]);
  const [specialLicenses, setSpecialLicense] = useState([]);
  const [specialLicenseTotalCount, setSpecialLicenseTotalCount] = useState([]);
  const [changeLicensePage, setchangeLicensePage] = useState(1);
  const [RenewLicence, setRenewLicence] = useState(false);
  const [SelectedLicenseDetails, setSelectedLicenseDetails] = useState([]);
  const [addLicenseData, setAddLicenseData] = useState([]);
  const [clickedAppName, setClickedAppName] = useState("");
  const [clickedThumbnail, setClickedThumbnail] = useState("");
  const [clickedId, setClickedId] = useState("");
  const [selectCard, setSelectCard] = useState(false);
  const [selectedAppGroup, setSelectedAppGroup] = useState(
    themeContext.Role.current === "user" ? "purchased" : "owned"
  );
  useEffect(() => {
    let ldata = encryptStorage.getItem("UID");
    if (ldata) {
      Role = ldata.roles;
    } else {
      setshowUserLogin(true);
      setLoadingScreen(false);
    }
    OpenAddLicense && getStandardLicenses();
    // getAddLicenseData();
    setSelectCard(true);
  }, [OpenAddLicense]);

  const handleScroll = () => {
    if (scrollbar.current.getValues().top >= 0.95 && totalCount > items) {
      if (changePage <= Math.floor(totalCount / items) && !flag) {
        flag = true;
        changePage += 1;
        fetchApp(changePage);
      }
    } else if (
      scrollbar.current.getValues().top === 0 &&
      changePage > 1 &&
      !flag
    ) {
      changePage -= 1;
      flag = true;
      fetchApp(changePage);
    }
  };

  const fetchApp = (value = 1) => {
    setLoadingScreen(true);
    let url =
      themeContext.Role.current === "user" || themeContext.Role.current === null
        ? `apps/device/fetch?page=${value}&items=${items}`
        : `apps/?page=${value}&items=${items}`;
    if (id !== undefined) {
      url += "&serial_number=" + id;
    }

    axiosApiInstance
      .get(url)
      .then((res) => {
        flag = false;
        let detailsData = {};
        let detailsLength = res.data?.detail?.details.length;
        setTotalCount(res.data.detail?.total_count);
        console.log(detailsLength, items);
        if (detailsLength <= items / 2) {
          Object.keys(appDetails)
            .splice(3, items)
            .map((item) => (detailsData[item] = appDetails[item]));
        }

        detailsLength === 0
          ? setSearchAppName(undefined)
          : res.data.detail?.details.map((details) => {
              if (deviceName === "") {
                deviceName = details["device_name"];
              }
              if (manufacturerName === "") {
                manufacturerName = details["manufacturer_username"];
              }
              detailsData[details["app_name"]] = {
                Name: details["app_name"],
                version: details["version_name"],
                thumbnail: details["app_thumbnail"],
                status: details["status"],
                downloads: details["ratings"]["downloads"],
                type: details["app_type"],
                AppId: details["_id"],
              };
            });
        setTimeout(() => {
          setLoadingScreen(false);
          setAppDetails(detailsData);
          scrollbar?.current?.scrollTop(20);
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
        setLoadingScreen(false)
        notify({
          msg: err?.response?.data?.detail,
          type: "error",
        });
      });
  };

  const getAddLicenseData = () => {
    axiosApiInstance
      .get(`apps/device/fetch`, {
        params: { serial_number: id },
      })
      .then((res) => setAddLicenseData(res.data));
  };

  useDebouncedEffect(
    () => {
      console.log(searchAppName);
      if (searchAppName?.length >= 3) {
        searchAppDetails();
      }
      if (searchAppName?.length === 0) {
        fetchApp(1);
      }
    },
    [searchAppName],
    1000
  );

  const searchAppDetails = (apptype = undefined) => {
    // let url = `apps/?search=true&page=${changePage}&items=${items}`;
    let url =
      themeContext.Role.current === "user" || themeContext.Role.current === null
        ? `apps/device/fetch?page=${changePage}&items=${items}`
        : `apps/?search=true&page=${changePage}&items=${items}`;
    if (id !== undefined) {
      url += "&serial_number=" + id;
    }
    let appTypeQuery = "";
    let appNameQuery = "";
    if (searchAppName !== undefined && searchAppName !== "") {
      appNameQuery = `&app_name=${searchAppName}`;
    }
    if (SelectedFilter !== undefined && SelectedFilter !== "") {
      appTypeQuery = `&app_type=${FilterOptions[SelectedFilter]}`;
    }
    if (apptype !== undefined && apptype !== "") {
      appTypeQuery = `&app_type=${FilterOptions[apptype]}`;
    }

    if (appTypeQuery !== "" || appNameQuery !== "") {
      url += appNameQuery;
      url += appTypeQuery;
      axiosApiInstance
        .get(API_URL + url)
        .then((res) => {
          let data = {};
          setTotalCount(res.data.detail.total_count);
          res.data.detail.details.map((details) => {
            if (deviceName === "") {
              deviceName = details["device_name"];
            }
            if (manufacturerName === "") {
              manufacturerName = details["manufacturer_username"];
            }
            data[details["app_name"]] = {
              Name: details["app_name"],
              version: details["version_name"],
              thumbnail: details["app_thumbnail"],
              status: details["status"],
              downloads: details["ratings"]["downloads"],
              type: details["app_type"],
            };
          });
          setAppDetails(data);
        })
        .catch((err) => {
          console.log(err);
          notify({
            msg: "Server Error",
            type: "error",
          });
        })
        .finally(() => {
          setLoadingScreen(false);
        });
    }
  };

  const deleteApp = () => {
    axiosApiInstance
      .delete(`apps/?app_name=${selectedAppName}`)
      .then((res) => {
        setShowOTP(false);
        msg.errorText = "App deleted successfully";
        msg.header = "Success";
        msg.type = "success";
        setShowAlert(true);
        setTimeout(() => {
          fetchApp();
          setShowAlert(false);
        }, 2000);
      })
      .catch((err) => {
        msg.errorText = "Something went wrong";
        msg.header = "Error";
        msg.type = "alert";
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 3000);
      })
      .finally(() => {
        setLoadingScreen(false);
      });
  };

  const postVerifyOTP = (otp_) => {
    let lData = encryptStorage.getItem("VID");
    let obj = {
      username: lData.username,
      email: lData.destination,
      otp: otp_,
    };
    setLoadingScreen(true);
    axios
      .post(API_URL + "user/otp/verify", obj)
      .then((res) => {
        msg.errorText = "Successfully verified OTP";
        msg.header = "Success";
        msg.type = "success";
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 3000);
        encryptStorage.removeItem("VID");
        deleteApp();
      })
      .catch((err) => {
        // msg.errorText = "Invalid OTP. Please Try Again!";
        msg.errorText = err.response.data.detail;
        msg.header = "Error";
        msg.type = "alert";
        setLoadingScreen(false);
        // setShowOTP(false);
        setShowAlert(true);
      })
      .finally(() => {
        setLoadingScreen(false);
      });
  };

  const postResendOTP = () => {
    let _lData = encryptStorage.getItem("VID");

    let obj = {
      username: _lData.username,
      email: _lData.destination,
    };
    setLoadingScreen(true);
    axios
      .post(API_URL + "user/otp/send", obj)
      .then((res) => {
        msg.errorText = "OTP resent to your Email ID.";
        msg.header = "Success";
        msg.type = "success";
        setShowAlert(true);
        resetCounter();
      })
      .catch((err) => {
        msg.errorText = "Server Error";
        msg.header = "Error";
        msg.type = "alert";
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 3000);
      })
      .finally(() => {
        setLoadingScreen(false);
      });
  };

  const postOtp = (appName) => {
    selectedAppName = appName;
    let lData = encryptStorage.getItem("UID");
    let obj = {
      username: lData.username,
    };
    setLoadingScreen(true);
    axiosApiInstance
      .get("user/profile/data")
      .then((res) => {
        obj["email"] = res.data.email;
        axios
          .post(API_URL + "user/otp/send", obj)
          .then((res) => {
            setTimeout(() => {}, 500);
            encryptStorage.setItem("VID", {
              counter: OTP_TIME,
              username: obj.username,
              destination: obj.email,
            });
            setShowOTP(true);
          })
          .catch((err) => {
            if (err.response.status === 401) {
              // msg.errorText = "Too many OTP Attempts";
              msg.errorText = err.response.data.detail;
              msg.header = "Error";
              msg.type = "alert";
              setShowAlert(true);
            } else {
              msg.errorText = "Something went wrong!";
              msg.header = "Error";
              msg.type = "alert";
              setShowAlert(true);
            }
          })
          .finally(() => {
            setLoadingScreen(false);
          });
      })
      .catch((err) => {})
      .finally(() => {
        setLoadingScreen(false);
      });
  };

  useEffect(() => {
    let ldata = encryptStorage.getItem("UID");
    if (!ldata) return;
    fetchApp(1);
  }, []);
  const getStandardLicenses = (name) => {
    setLoadingScreen(true);
    axiosApiInstance
      .get(
        `license/fetch/manufacturer/licenses?license_type=app&manufacturer_username=admin&app_name=${clickedAppName}`
      )
      .then((res) => {
        setStandardLicenses([...res.data.details]);
      })
      .catch((err) => {})
      .finally(() => {
        setLoadingScreen(false);
      });
  };
  const getSpecialLicenses = (changePage = 1) => {
    setLoadingScreen(true);
    axiosApiInstance
      .get(
        `inventory/role?license_type=app&app_name=${clickedAppName}&page=${changePage}&items=10`
      )
      .then((res) => {
        let temp = [];
        // setFlag(false);
        // temp = res.data.details;
        // setLoadingScreen(false);
        // setSpecialLicense((prevState) => {
        //   return changePage === 1 ? [...temp] : [...prevState, ...temp];
        // });
        setSpecialLicense(res.data.details);
        setSpecialLicenseTotalCount(res.data.total_count);
        // scrollbar?.current?.scrollTop(20);
      })
      .catch((err) => {})
      .finally(() => {
        setLoadingScreen(false);
      });
  };

  const addCountKey = (index) => {
    let newArr = [...standardLicenses[index].pricing_details];
    // newArr.splice(0,1)
    for (let i = 0; i < newArr.length; i++) {
      let _keys = Object.keys(newArr[i]);
      for (let j = 0; j < _keys.length; j++) {
        if (_keys[j] != "attribute_name") {
          newArr[i][_keys[j]].count = 1;
        }
      }
    }
    _mapping_id = standardLicenses[index].manufacturer_mapping_id;
    setSelectedLicenseDetails([...newArr]);
  };

  const renderUpperBar = () => {
    return (
      <div className="AddedApps_data_upper_subwrapper">
        <div className="searchbar_filterby_wrapper">
          <Searchbar
            placeholder="Search by app name"
            handleSearch={(e) => {
              changePage = 1;
              setSearchAppName(e.target.value);
            }}
            autoFocus={true}
            value={searchAppName}
          />

          <div className="filterby_wrapper">
            <Dropdown
              style={{ width: "7.2vw" }}
              className="adjust_dd"
              id={"targetindustry"}
              optionsList={Object.keys(FilterOptions)}
              handleOption={(apptype) => {
                changePage = 1;
                flag = true;
                setSelectedFilter(apptype);
                searchAppDetails(apptype);
              }}
              defaultText={SelectedFilter ? SelectedFilter : "Choose type"}
            />
          </div>
          {themeContext.Role.current === "developer" && (
            <div className="adedapps_addapp_btn">
              <Button
                name="Add app"
                type={"gradient"}
                onClick={() => {
                  history.push("apps/add");
                }}
                btnImg={plus("plus")}
              />
            </div>
          )}
        </div>
        {/* {themeContext.Role.current === "developer" && (
          <Dropdown
            style={{ width: "7.2vw" }}
            className="adjust_dd"
            id={"appGroup"}
            optionsList={Object.keys(appGroup)}
            handleOption={(apptype) => {
              setSelectedAppGroup(apptype);
            }}
            defaultText={selectedAppGroup ? selectedAppGroup : "Filter by"}
          />
        )} */}
      </div>
    );
  };
  const fetchHeader = () => {
    let header = "";
    if (themeContext.Role.current === "superadmin") {
      header = "Uploaded Apps";
    } else if (themeContext.Role.current === "manufacturer") {
      header = "Approved Apps";
    } else if (themeContext.Role.current === "user") {
      header = id ? "App Store" : "Purchased Apps";
    } else if (themeContext.Role.current === "developer") {
      header = "Your owned app";
    }

    return <h2 className="addedapps_header">{header}</h2>;
  };
  return (
    <div className="_AddedApps_wrapper_">
      <Navbar sidenav>
        <div className="AddedApps_body">
          <img src={bg} className="profile_bg" />
          <div className="body_content fadeIn">
            {fetchHeader()}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <p className="goBack"></p>
            </div>
            <div className="AddedApps_data">
              <div className="AddedApps_data_wrapper">
                <div className="AddedApps_data_subwrapper">
                  {/* {selectedAppGroup === "purchased" ? ( */}
                  <div
                    className="AddedApps_data_lower_subwrapper"
                    style={{ overflow: "hidden" }}
                  >
                    {Object.keys(appDetails).length === 0 ? (
                      searchAppName === undefined ? (
                        SelectedFilter &&
                        Object.keys(appDetails).length === 0 ? (
                          <>
                            {renderUpperBar()}

                            <div className="apps_data">
                              <div className="apps_data_wrapper">
                                <div className="apps_data_subwrapper">
                                  <div className="apps_data_error">
                                    No Apps Found
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <div className="apps_data">
                            <div className="ownedapps_noapps_dropdown">
                              {/* {themeContext.Role.current === "developer" && (
                                  <Dropdown
                                    style={{ width: "7.2vw" }}
                                    className="adjust_dd"
                                    id={"appGroup"}
                                    optionsList={Object.keys(appGroup)}
                                    handleOption={(apptype) => {
                                      setSelectedAppGroup(apptype);
                                    }}
                                    defaultText={
                                      selectedAppGroup
                                        ? selectedAppGroup
                                        : "Filter by"
                                    }
                                  />
                                )} */}
                            </div>
                            {id !== undefined &&
                            Object.keys(appDetails).length === 0 ? (
                              <div className="purchased_data_lower_subwrapper">
                                No apps found for purchase
                              </div>
                            ) : (
                              <div className="apps_data_wrapper">
                                <div className="apps_data_subwrapper">
                                  <img
                                    src={addapp}
                                    className="addapp_img"
                                  ></img>
                                  <div className="apps_data_description">
                                    Make your own customized usecase by getting
                                    started now.
                                  </div>
                                  <p className="apps_data_btninfo">
                                    Click below to add apps
                                  </p>
                                  <Button
                                    name="Add your Application"
                                    type={"gradient"}
                                    onClick={() => {
                                      history.push("apps/add");
                                    }}
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        )
                      ) : (
                        <>
                          {renderUpperBar()}

                          <div className="apps_data">
                            <div className="apps_data_wrapper">
                              <div className="apps_data_subwrapper">
                                <div className="apps_data_error">
                                  No Apps Found
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )
                    ) : (
                      <>
                        {renderUpperBar()}
                        <Scroller
                          autoHeightMax="46vh"
                          verticalStyle={{
                            width: "0.3vw",
                            marginLeft: "0vw",
                            backgroundColor: "#283e92",
                            height: "1px",
                          }}
                          onScroll={handleScroll}
                          ref={scrollbar}
                        >
                          {Object.values(appDetails).map((item, idx) => (
                            <Widget
                              key={item.Name}
                              appName={item.Name}
                              version={item.version}
                              appStatus={item.status}
                              appDownloads={item.downloads}
                              appThumbnail={item.thumbnail}
                              apptype={item.type}
                              id={"app_" + idx}
                              setOtp={postOtp}
                              selectedAppGroup={selectedAppGroup}
                              setOpenAddLicense={setOpenAddLicense}
                              setClickedAppName={setClickedAppName}
                              setClickedThumbnail={setClickedThumbnail}
                              AppId={item.AppId}
                              setClickedId={setClickedId}
                              serialNumber={id}
                            />
                          ))}
                          {/* {Object.values(appDetails).map((item) => (
                                        <Widget appName={item.name} />
                                      ))} */}
                        </Scroller>
                      </>
                    )}
                  </div>
                  {/* ) : (
                    <div className="purchased_data_subwrapper">
                      <div className="purchased_data_upper_subwrapper">
                        {themeContext.Role.current === "developer" && (
                          <Dropdown
                            style={{ width: "7.2vw" }}
                            className="adjust_dd"
                            id={"appGroup"}
                            optionsList={Object.keys(appGroup)}
                            handleOption={(apptype) => {
                              setSelectedAppGroup(apptype);
                            }}
                            defaultText={
                              selectedAppGroup ? selectedAppGroup : "Filter by"
                            }
                          />
                        )}
                      </div>
                      <div className="purchased_data_lower_subwrapper">
                        {id === ""
                          ? "No purchased app found" owned
                          : "No apps found for purchase"} 
                      </div>
                    </div>
                  )} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Navbar>
      {loadingScreen && <Loading type={"transparent"} text={"Loading"} />}

      <AnimatedModal modalOpen={ShowOTP} className="preview_modal">
        <OTP
          showOTP={ShowOTP}
          setShowOTP={setShowOTP}
          postVerifyOTP={(otp_) => {
            postVerifyOTP(otp_);
          }}
          subheader={
            "Enter the OTP received on your registered email ID to delete this application"
          }
          setOTPCloseConfirm={setOTPCloseConfirm}
          postResendOTP={postResendOTP}
          resendFunc={(func) => {
            resetCounter = func;
          }}
          _stop={Stop}
          setStop={setStop}
        />
      </AnimatedModal>
      <UpgradeLicense2
        RenewLicence={RenewLicence}
        setRenewLicence={setRenewLicence}
        SelectedLicenseDetails={SelectedLicenseDetails}
        setSelectedLicenseDetails={setSelectedLicenseDetails}
        selectedDevice={selectedDevice}
        _mapping_id={_mapping_id}
        _license_name={_license_name}
        deviceName={deviceName}
        manufacturerName={manufacturerName}
        serialNumber={id}
        clickedAppName={clickedAppName}
        clickedThumbnail={clickedThumbnail}
        licenseType={"app"}
        clickedAppId={clickedId}
      />
      <SelectLicense
        clickedAppName={clickedAppName}
        FetchLicense={OpenAddLicense}
        setFetchLicense={setOpenAddLicense}
        StandardLicenses={standardLicenses}
        getLicenses={getStandardLicenses}
        getSpecialLicenses={getSpecialLicenses}
        specialLicense={specialLicenses}
        changePage={changeLicensePage}
        setChangePage={setchangeLicensePage}
        manuName={"admin"}
        onBuyClick={(index) => {
          _license_name = standardLicenses[index].license_details.name;
          addCountKey(index);
          setRenewLicence(true);
          setOpenAddLicense(false);
          // _license_index = index
          // license_type = "Standard"
        }}
        selectCard={selectCard}
        ShowAlert={showAlert}
        setShowAlert={setShowAlert}
        msg={msg}
        serialNumber={id}
        clickedThumbnail={clickedThumbnail}
      />
      <AnimatedModal
        modalOpen={showAlert}
        // modalOpen={true}
        handleClose={() => {
          setShowAlert(false);
        }}
        type={msg.type}
        errorHeader={msg.header}
        errorText={msg.errorText}
        // timeout={5000}
        alwayOpen={true}
      />
      <AnimatedModal
        modalOpen={OTPCloseConfirm}
        onConfirm={() => {
          setOTPCloseConfirm(false);
          setShowOTP(false);
          encryptStorage.removeItem("VID");
        }}
        handleClose={() => {
          setOTPCloseConfirm(false);
        }}
        type={"confirm"}
        errorText={"Are you sure you want to close?"}
        alwayOpen={true}
      />
      <UserLoginModal
        showUserLogin={showUserLogin}
        setshowUserLogin={setshowUserLogin}
        getFilterOptions={fetchApp}
        // setConfirm={setConfirm}
      />
    </div>
  );
}
