import React, { Component } from "react";
import "../VersionDetails/HeroSidebar.scss";
import BasicDetails from "./BasicDetails";
import Compatibility from "./Compatibility";
import VersionAppDetails from "./VersionAppDetails";
import VersionOptions from "./VersionOptions";
import VersionSettings from "./VersionSettings";

export class HeroSidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      choices: {
        basic_details: BasicDetails,
        compatibility: Compatibility,
        settings: VersionSettings,
      },
      selected: BasicDetails,
    };
  }
  render() {
    return (
      <div>
        <div className="versionDt_upper_sidebar">
          <div className="versiondt_upper_subwrapper">
            <VersionAppDetails />
          </div>
        </div>
        <div className="versionDt_lower_sidebar">
          <VersionOptions setselected={this.props.setselected} />
        </div>
      </div>
    );
  }
}

export default HeroSidebar;
