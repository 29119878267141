import React, { useState, useRef } from "react";
import Button from "../../../../components/Button/Button";
import { handleFocus } from "../../../../components/DeveloperModal/ManufacturerModal ";
import InputBox from "../../../../components/Inputbox/InputBox";
import AnimatedModal from "../../../../components/Modal/AnimatedModal";
import Scroller from "../../../../components/Scroller/ScrollBar";
import { closeIcon } from "../../../../helper/icons";
import { axiosApiInstance } from "../../../../helper/request";
import { useDebouncedEffect } from "../../../../helper/useDebounce";
let msg = {
  type: "alert",
  header: "Error",
  errorText: "Something Went Wrong",
};

export default function CreateLicense({
  CreateLicenseModal,
  setCreateLicenseModal,
  License,
  setLicense,
  attributeName,
  app_name,
  fetchLicense,
}) {
  const createLicenseScroll = useRef();
  const arr = ["attribute_name", "monthly", "quarterly", "yearly", "lifetime"];
  const [UniqueCheck, setUniqueCheck] = useState(false);
  const [ShowAlert, setShowAlert] = useState(false);
  const [LicenseName, setLicenseName] = useState("");

  useDebouncedEffect(
    () => (LicenseName ? getUnique() : undefined),
    [LicenseName],
    1000
  );

  const disableLicenseSave = () => {
    let res = false;
    if (LicenseName === "") {
      res = true;
    }
    for (let i = 0; i < License?.length; i++) {
      if (License[i].attribute_name === "") {
        res = true;
      }
    }
    if (License.length === 0) {
      res = true;
    }
    // if (errors["isPlanNameUnique"]) {
    //   res = true;
    // }

    if (!res) {
      return false;
    } else {
      return true;
    }
  };

  const getUnique = () => {
    axiosApiInstance
      .get("license/uniquecheck?licensename=" + LicenseName)
      .then((res) => {
        setUniqueCheck(res.data.details);
        if (res.data.details) {
          document
            .getElementById("_license_name")
            .classList.add("error_shake", "input__error");
        }
      })
      .catch((err) => {
      });
  };

  function editAtris(val, idx, idx2, header) {
    let newArr = [...License];
    let _arr2 = [];
    if (
      header === "attribute_name" &&
      !attributeName.includes(newArr[idx][arr[idx2]])
    ) {
      newArr[idx][arr[idx2]] = val;
      setLicense([...newArr]);
    } else if (header !== "attribute_name") {
      newArr[idx][arr[idx2]].amount = val;
      setLicense([...newArr]);
    }
    for (let i = 0; i < newArr.length; i++) {
      if (attributeName[i] !== newArr[i].attribute_name) {
        _arr2.push(newArr[i].attribute_name);
      }
    }
    // setAttributeNames([..._arr2]);
  }

  function addRow() {
    let newArr = [...License];
    let object = {
      attribute_name: "",
      monthly: {
        amount: 0,
      },
      yearly: {
        amount: 0,
      },
      quarterly: {
        amount: 0,
      },
      lifetime: {
        amount: 0,
      },
    };
    newArr.push(object);
    setLicense([...newArr]);
  }

  function deleteRow(idx) {
    let newArr = [...License];
    newArr.splice(idx, 1);
    setLicense([...newArr]);
  }

  function resetRow(idx) {
    let newArr = [...License];
    for (let i = 0; i < arr.length; i++) {
      if (arr[i] !== "attribute_name") {
        newArr[idx][arr[i]].amount = 0;
      }
    }
    setLicense([...newArr]);
  }

  const printError = (id) => {
    document.getElementById(id).classList.add("error_shake", "input__error");
  };

  const validateData = () => {
    let res = false;
    if (LicenseName === "" || UniqueCheck) {
      res = true;
      printError("apps_license_name");
    }
    for (let i = 0; i < License.length; i++) {
      if (License[i].attribute_name === "") {
        res = true;
        printError("input_" + i);
      }
    }
    return res;
  };

  const postData = () => {
    if (validateData()) {
      return;
    }
    axiosApiInstance
      .post("license/add", {
        name: LicenseName.toLowerCase(),
        pricing_details: License,
        license_type: "app",
        app_license_name: app_name,
      })
      .then((res) => {
        setLicense([]);
        msg.errorText = "Successfully Added License";
        msg.header = "Success";
        msg.type = "success";
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 3000);
      })
      .catch((err) => {
        msg.errorText = "Couldn't Add License";
        msg.header = "Error";
        msg.type = "alert";
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 3000);
      })
      .finally(() => {
        setCreateLicenseModal(false);
        fetchLicense(1, "Standard License");
        setLicenseName("");
      });
  };

  return (
    <div className="apps_license_modal">
      <AnimatedModal
        modalOpen={CreateLicenseModal}
        className="apps_create_license_modal"
      >
        <div className="apps_license_modal_wrapper">
          <div
            className="close_otp_modal"
            onClick={() => {
              setCreateLicenseModal(false);
              setLicenseName("");
              setLicense([]);
            }}
          >
            {closeIcon()}
          </div>
          <div className="edit_apps_license_table">
            <InputBox
              id="apps_license_name"
              value={LicenseName}
              placeholder="Enter License Name"
              onChange={(e) => {
                setLicenseName(e.target.value);
              }}
              onFocus={() => {
                handleFocus("apps_license_name");
              }}
                helperText={UniqueCheck ? "Name already exists" : ""}
            />
            <Scroller autoHeightMax="17vw" ref={createLicenseScroll}>
              <table>
                <tr>
                  {arr.map((header, index) => {
                    return <th>{header.replace(/_/g, " ")}</th>;
                  })}
                </tr>
                {License.map((item, idx) => {
                  return (
                    <tr className="attribute_row">
                      {arr.map((header, idx2) => {
                        return header === "attribute_name" ? (
                          <td>
                            {!attributeName.includes(item[header]) ? (
                              <div
                                onClick={() => {
                                  deleteRow(idx);
                                }}
                                className="center"
                              >
                                x
                              </div>
                            ) : (
                              <div
                                onClick={() => {
                                  resetRow(idx);
                                }}
                                className="center"
                              >
                                -
                              </div>
                            )}
                            <input
                              // autoFocus
                              id={"input_" + idx}
                              value={item[header]}
                              onChange={(e) => {
                                editAtris(e.target.value, idx, idx2, header);
                              }}
                              onFocus={() => handleFocus("input_" + idx)}
                            />
                          </td>
                        ) : (
                          <td key={item[header] + "idx2" + idx2 + "secondcol"}>
                            <input
                              value={item[header]?.amount}
                              onChange={(e) => {
                                if (Number(e.target.value) <= 9999999) {
                                  editAtris(
                                    Number(
                                      e.target.value.replace(/[^0-9]/g, "")
                                    ),
                                    idx,
                                    idx2
                                  );
                                }
                              }}
                            />
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </table>
            </Scroller>
            <Button
              id="add_row"
              onClick={() => {
                addRow();
              }}
              name="+ Attribute"
            />
            <Button
              id="save_btn"
              onClick={() => {
                postData();
                // postAttributes();
              }}
              style={{
                position: "absolute",
                right: "3%",
                bottom: "2%",
              }}
              name="Save"
              disabled={disableLicenseSave()}
            />
          </div>
        </div>
      </AnimatedModal>
      <AnimatedModal
        modalOpen={ShowAlert}
        handleClose={() => {
          setShowAlert(false);
        }}
        type={msg.type}
        errorHeader={msg.header}
        errorText={msg.errorText}
        // timeout={5000}
        alwayOpen={true}
      />
    </div>
  );
}
