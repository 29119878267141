import React, { useEffect, useState } from "react";
import { editPen, tickIcon } from "../../../../../../../helper/icons";
import InputBox from "../../../../../../../components/Inputbox/InputBox";
import { encryptStorage } from "../../../../../../../helper/storage";
import "./SetInventoryCount.scss";

export const SetInventoryCount = (props) => {
  let activeRoles = encryptStorage.getItem("UID").roles;
  const [clickedEdit, setClickedEdit] = useState(false);
  const [count, setCount] = useState(0);

  const handleOnClick = () => {
    props.counts.total = 0;
    setClickedEdit(true);
    props.setSelectedUsername(props.username);
  };

  const onTickClicked = () => {
    setClickedEdit(false);
    props.setInventoryCount(props.username, props.counts);
  };

  const onChange = (e) => {
    let arr = [];
    const regex = /^[0-9]*$/;
    let number = e.target.value;
    if (number.match(regex) || number === "") {
      Object.keys(props.userList).map((item) => {
        if (item !== props.username)
          arr.push(Number(props.userList[item]["current"]));
      });
      let sums =
        Number(number) + arr.reduce((partialSum, a) => partialSum + a, 0);
      console.log(sums, "sums");
      if (
        props.availableCount !== undefined &&
        Number(number) <= props.availableCount &&
        sums <= props.availableCount
      ) {
        props.setRemainingCount(props.availableCount - sums);
        setCount(Number(number));
        props.counts.current = Number(number);
        sums > 0 ? props.setDisableSave(false) : props.setDisableSave(true);
      } else if (activeRoles.includes("superadmin")) {
        setCount(Number(number));
        props.counts.current = Number(number);
        sums > 0 ? props.setDisableSave(false) : props.setDisableSave(true);
      }
    }
  };

  useEffect(() => {
    setCount(props.counts.current);
    // Object.keys(props.userList).map(
    //   (item) =>
    //     props.userList[item]["current"] === 0 && props.setDisableSave(true)
    // );
    // console.log(  Object.keys(props.userList).map(
    //   (item) =>
    //     props.userList[item]["prev"]
    // ))
  }, [
    // props.userList
  ]);

  return (
    <div className="mfr_count_wrapper">
      {/* <p className="mfr_count">
        {props.counts.total && !clickedEdit
          ? props.counts.total
          : props.counts.prev}
      </p> */}
      <div
        title={
          props.counts.total && !clickedEdit
            ? props.counts.total
            : props.counts.prev
        }
        className="mfr_count"
      >
        {/* <div className="mfr_count_overflow"> */}
        {props.counts.total && !clickedEdit
          ? props.counts.total
          : props.counts.prev}
        {/* </div> */}
        {/* <span className="tooltiptext_mfrcount"> */}
        {/* {props.counts.total && !clickedEdit
            ? props.counts.total
            : props.counts.prev} */}
        {/* </span> */}
      </div>
      {clickedEdit && props.username === props.selectedUsername ? (
        <div className="td_edit_count">
          +
          <InputBox onChange={onChange} value={count} />=
          <p className="total">
            {(props.counts.total = Number(props.counts.prev) + Number(count))}
          </p>
          <div onClick={onTickClicked}>{tickIcon("edit_count_tick")}</div>
        </div>
      ) : (
        <div onClick={handleOnClick}>{editPen()}</div>
      )}
    </div>
  );
};
