import React, { useState } from "react";
import { closeIcon } from "../../../helper/icons";
import Button from "../../../components/Button/Button";
import InputBox from "../../../components/Inputbox/InputBox";
import AnimatedModal from "../../../components/Modal/AnimatedModal";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";

import { encryptStorage } from "../../../helper/storage";
import { API_URL } from "../../../helper/request";
import { notify } from "../../../Routes";
import { axiosApiInstance } from "../../../helper/request";
import { ThemeContext } from "../../../helper/context";
import { useContext } from "react";
import ReCAPTCHAV2 from "react-google-recaptcha";

import "./userloginmodal.scss";

let msg = {
  type: "alert",
  header: "Error",
  errorText: "Something Went Wrong",
};

export default function UserLoginModal({
  showUserLogin,
  setshowUserLogin,
  getFilterOptions,
}) {
  const themeContext = useContext(ThemeContext);

  const [Username, setUsername] = useState("admin");
  const [Password, setPassword] = useState("Admin@123");
  const [LoadingScreen, setLoadingScreen] = useState(false);
  const [ShowAlert, setShowAlert] = useState(false);
  const [captchaValue, setcaptchaValue] = useState("");

  const [oldType, setOldType] = useState("password");
  const [errors, setErrors] = useState({
    isOldPassWordEmpty: false,
    isPasswordEmpty: false,
    isCPasswordEmpty: false,
  });
  const clearError = (name) => {
    let _errors = { ...errors };
    _errors[name] = false;
    setErrors({ ..._errors });
  };

  const getNotification = () => {
    themeContext.getNotification((res) => {});
  };

  const getRequest = () => {
    themeContext.getRequest((res) => {
      if (res) {
        getNotification();
      }
    });
  };

  const handlePassword = (type) => {
    if (!type) {
      setOldType("password");
    } else {
      setOldType("text");
    }
  };

  const getCart = () => {
    themeContext.getCart();
  };

  const getNavbarData = (user) => {
    console.log("getNavbarData");
    axiosApiInstance
      .get("user/navbar")
      .then((res) => {
        console.log(res);
        let { profile_pic, username, roles } = res.data;
        let keys = Object.keys(roles);
        let arr = [];
        let pending = [];
        let suspended = [];
        let isRejected = false;
        for (let i = 0; i < keys.length; i++) {
          if (roles[keys[i]] !== "pending" && roles[keys[i]] !== "rejected") {
            arr.push(keys[i]);
          }
          if (roles[keys[i]] === "pending" || roles[keys[i]] === "suspended") {
            pending.push(keys[i]);
          }
          if (roles[keys[i]] === "suspended") {
            suspended.push(keys[i]);
          }
          if (roles[keys[i]] === "rejected") {
            isRejected = true;
          }
        }
        let _data = {
          ...user,
          profile_pic,
          rejected: isRejected,
          username,
          roles: arr,
          pending: [...pending],
          suspended: [...suspended],
        };
        if (arr.includes("superadmin")) {
          _data.current = "superadmin";
        } else {
          _data.current = "user";
        }

        console.log(_data);
        encryptStorage.setItem("UID", _data);
        themeContext.setRole({
          all: [...arr],
          current: _data.current,
          pending: [...pending],
          suspended: [...suspended],
          rejected: isRejected,
          username,
        });

        if (arr.includes("superadmin")) {
          getRequest();
        } else {
          getCart();
          getNotification();
        }
      })
      .catch((err) => {
        msg.errorText = "Failed to fetch user details!";
        msg.header = "Error";
        msg.type = "alert";
        setShowAlert(true);
      })
      .finally(() => {
        setLoadingScreen(false);
      });
  };

  const postData = () => {
    let _errors = { ...errors };
    if (Username === "") _errors["isUsernameEmpty"] = true;
    if (Password === "") _errors["isPasswordEmpty"] = true;
    setErrors({ ..._errors });
    let error = false;
    if (captchaValue === "") {
      error = true;
      notify({
        msg: "Captcha validation is required",
      });
    }
    if (Object.values(_errors).includes(true) || error === true) {
      return;
    }

    setLoadingScreen(true);
    axios
      .post(
        API_URL + "auth/login",
        {},
        {
          headers: {
            field: Username,
            password: Password,
          },
        }
      )
      .then((res) => {
        let _data = { ...res.data };
        console.log(_data);
        encryptStorage.setItem("UID", _data);
        getFilterOptions && getFilterOptions();
        getNavbarData(_data);
        setshowUserLogin(false);
      })
      .catch((err) => {
        console.log(err);
        setLoadingScreen(false);
        if (err.response.status) {
          notify({
            msg: err.response.data.detail,
          });
        } else {
          notify({
            msg: "Invalid Credential!",
          });
        }
      });
  };
  return (
    <AnimatedModal modalOpen={showUserLogin} className="reset_password_modal">
      <h1 className="modal_head">User Login</h1>
      <div>
        <InputBox
          id="emailAddress"
          header="Username / Email / Phone"
          icon={emailIcon()}
          style={{ paddingLeft: "3vw" }}
          onChange={(e) => {
            const value = e.target.value;
            setUsername(value);
          }}
          error={errors["isUsernameEmpty"]}
          value={Username}
          onFocus={() => clearError("isUsernameEmpty")}
          onKeyDown={(e) => {
            if (e.keyCode === 13) {
              postData();
            }
          }}
          autoFocus
        />

        <div style={{ height: "1vw" }} />
        <InputBox
          id="Password"
          header="Password"
          type={oldType}
          password
          typeValue={(data) => handlePassword(data)}
          // icon={<img src={visibilityoff} className="input_icon" />}
          icon={passIcon()}
          style={{ paddingLeft: "3vw" }}
          onChange={(e) => setPassword(e.target.value)}
          error={errors["isPasswordEmpty"]}
          value={Password}
          onFocus={() => clearError("isPasswordEmpty")}
          onKeyDown={(e) => {
            if (e.keyCode === 13) {
              postData();
            }
          }}
        />
      </div>
      <div className="recaptcha_login">
        <ReCAPTCHAV2
          data-type="image"
          sitekey={process.env.REACT_APP_SITE_KEY}
          onChange={(value) => {
            setcaptchaValue(value);
          }}
          // size="compact"
        />
      </div>
      <div style={{ height: "1vw" }} />
      <div className="res_success_btn" style={{ margin: "0 1vw" }}>
        <Button type="gradient" bg name="Submit" onClick={postData} />
      </div>
    </AnimatedModal>
  );
}

const emailIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21.591"
    height="21.591"
    viewBox="0 0 21.591 21.591"
    className="input_icon"
  >
    <defs>
      <clipPath id="clipPath">
        <rect
          id="_36_Background_Mask_"
          data-name="36 (Background/Mask)"
          width="21.591"
          height="21.591"
          fill="none"
        />
      </clipPath>
    </defs>
    <g id="_36" data-name="36" clipPath="url(#clipPath)">
      <g
        id="Group_1683"
        data-name="Group 1683"
        transform="translate(3.356 4.646)"
      >
        <path
          id="Vector"
          d="M1.823,11.587A1.829,1.829,0,0,1,0,9.764V.912A.914.914,0,0,1,.912,0h13.3a.914.914,0,0,1,.912.912V9.764A1.829,1.829,0,0,1,13.3,11.587Z"
          fill="none"
          stroke="#aaa"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="1.2"
          strokeDasharray="0 0"
        />
        <path
          id="Vector-2"
          data-name="Vector"
          d="M0,0,6.737,4.686a.893.893,0,0,0,1.012,0L14.486,0"
          transform="translate(0.319 0.574)"
          fill="none"
          stroke="#aaa"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="1.2"
          strokeDasharray="0 0"
        />
      </g>
    </g>
  </svg>
);

const passIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21.591"
    height="21.591"
    viewBox="0 0 21.591 21.591"
  >
    <defs>
      <clipPath id="clipPath">
        <rect
          id="_35_Background_Mask_"
          data-name="35 (Background/Mask)"
          width="21.591"
          height="21.591"
          fill="none"
        />
      </clipPath>
    </defs>
    <g id="_35" data-name="35" clipPath="url(#clipPath)">
      <g
        id="Group_1696"
        data-name="Group 1696"
        transform="translate(4.359 3.158)"
      >
        <g id="Group">
          <path
            id="Vector"
            d="M1.823,9.253A1.829,1.829,0,0,1,0,7.43V1.823A1.829,1.829,0,0,1,1.823,0H11.888a1.829,1.829,0,0,1,1.823,1.823V7.43a1.829,1.829,0,0,1-1.823,1.823Z"
            transform="translate(0 6.637)"
            fill="none"
            stroke="#aaa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="1.2"
            strokeDasharray="0 0"
          />
          <path
            id="Vector-2"
            data-name="Vector"
            d="M8.15,6.637V4.075a4.075,4.075,0,1,0-8.15,0V6.637"
            transform="translate(2.781)"
            fill="none"
            stroke="#aaa"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="1.2"
            strokeDasharray="0 0"
          />
        </g>
        <path
          id="Vector-3"
          data-name="Vector"
          d="M1.112,2.224A1.112,1.112,0,1,1,2.224,1.112,1.112,1.112,0,0,1,1.112,2.224Z"
          transform="translate(5.743 9.034)"
          fill="#aaa"
        />
        <path
          id="Vector-4"
          data-name="Vector"
          d="M0,0V1.805"
          transform="translate(6.856 10.867)"
          fill="none"
          stroke="#aaa"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="1.2"
          strokeDasharray="0 0"
        />
      </g>
    </g>
  </svg>
);
