import React from "react";
import { deviceIcon } from "../../../helper/icons";
import { getDateTime } from "../../../helper/storage";

export default function OrderDetails({ data }) {
  return (
    <div className="order_list_wrapper">
      <div className="o_leftwrapper">
        {data.device_name ? (
          <div className="icon_name_block">
            <div className="deviceIcon_wrapper">{deviceIcon()}</div>
            <div className="name_block">
              <p className="name" style={{ color: "var(--primaryInvert)" }}>
                {data.device_name}
              </p>
              <p className="name">{data.amount} $</p>
              <p
                className="status"
                style={{ color: data.status === "approved" ? "green" : "red" }}
              >
                {data.status}
              </p>
            </div>
          </div>
        ) : (
          <div></div>
        )}
      </div>
      <div className="order_info_wrapper">
        <div className="info_wrapper">
          <Info name={"Order ID"} value={data.order_id} />
          <Info name={"Plan Type"} value={data.license_duration} />
        </div>
        <div className="info_wrapper">
          <Info name={"Order Placed"} value={getDateTime(data.placed_on)} />
          <Info name={"Plan Type"} value={data.license_duration} />
        </div>
        <div className="info_wrapper">
          <div className="device_info beta_info">
            <div className="beta_labels">
              <p className="log_icon">{invoiceIcon()}</p>
              <p className="b_label">Download Invoice</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const Info = ({ name, value }) => (
  <div className="device_info">
    <p className="d_label">{name}</p>
    <div className="value_wrapper" title={value}>
      {value}
    </div>
  </div>
);

const invoiceIcon = () => (
  <svg
    width={15}
    height={15}
    viewBox="-64 0 512 512"
    xmlns="http://www.w3.org/2000/svg"
    className="invoiceIcon"
  >
    <path
      d="M377 105L279.1 7c-4.5-4.5-10.6-7-17-7H256v128h128v-6.1c0-6.3-2.5-12.4-7-16.9zm-153 31V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zM64 72c0-4.42 3.58-8 8-8h80c4.42 0 8 3.58 8 8v16c0 4.42-3.58 8-8 8H72c-4.42 0-8-3.58-8-8V72zm0 80v-16c0-4.42 3.58-8 8-8h80c4.42 0 8 3.58 8 8v16c0 4.42-3.58 8-8 8H72c-4.42 0-8-3.58-8-8zm144 263.88V440c0 4.42-3.58 8-8 8h-16c-4.42 0-8-3.58-8-8v-24.29c-11.29-.58-22.27-4.52-31.37-11.35-3.9-2.93-4.1-8.77-.57-12.14l11.75-11.21c2.77-2.64 6.89-2.76 10.13-.73 3.87 2.42 8.26 3.72 12.82 3.72h28.11c6.5 0 11.8-5.92 11.8-13.19 0-5.95-3.61-11.19-8.77-12.73l-45-13.5c-18.59-5.58-31.58-23.42-31.58-43.39 0-24.52 19.05-44.44 42.67-45.07V232c0-4.42 3.58-8 8-8h16c4.42 0 8 3.58 8 8v24.29c11.29.58 22.27 4.51 31.37 11.35 3.9 2.93 4.1 8.77.57 12.14l-11.75 11.21c-2.77 2.64-6.89 2.76-10.13.73-3.87-2.43-8.26-3.72-12.82-3.72h-28.11c-6.5 0-11.8 5.92-11.8 13.19 0 5.95 3.61 11.19 8.77 12.73l45 13.5c18.59 5.58 31.58 23.42 31.58 43.39 0 24.53-19.05 44.44-42.67 45.07z"
      fill="var(--text)"
    />
  </svg>
);
