import React from "react";
import Navbar from "../../../components/Navbar/Navbar";
import Table from "../../../components/Table/Table";
import { activeIcon } from "../../../helper/icons";
import { suspendedIcon } from "../Users/Users";

export default function Usecases() {
   const header = [
    "Use-Case Name",
    "Purchased date and time",
    "Status",
    "Use-Case Info",
  ];
  const renderTableData = () => {
    return _data.map((item, idx) => (
      <tr>
        <td>
          <div className="usernameImage">
            <p
              style={{
                color: "var(--label)",
                textDecoration: "underline",
              }}
            >
              {item.UserName}
            </p>
          </div>
        </td>
        <td>{item.Email}</td>
        <td>
          <div className="usertype">
            {item.UserType === "Active" ? activeIcon() : suspendedIcon()}
            <p className="type">{item.UserType}</p>
          </div>
        </td>
        <td>Info</td>
      </tr>
    ));
  };
  return (
    <div className="_usecase_wrapper_">
      <Navbar sidenav>
      {/* <Table
        className="adjust_table"
        header={header}
        tableData={renderTableData}
      /> */}
      </Navbar>
    </div>
  );
}

const _data = [
  {
    UserName: "vpetruk0",
    Email: "kantonio0@imageshack.us",
    UserType: "Suspended",
    Action: null,
  },
  {
    UserName: "ewilfinger1",
    Email: "fmanifield1@cmu.edu",
    UserType: "Active",
    Action: null,
  },
  {
    UserName: "tphillot2",
    Email: "cmolineaux2@tmall.com",
    UserType: "Suspended",
    Action: null,
  },
  {
    UserName: "jsidebotham3",
    Email: "rzamora3@addtoany.com",
    UserType: "Active",
    Action: null,
  },
  {
    UserName: "smaccaffery4",
    Email: "ahaighton4@webnode.com",
    UserType: "Active",
    Action: null,
  },
  {
    UserName: "cgreggersen5",
    Email: "hpurdom5@wikia.com",
    UserType: "Suspended",
    Action: null,
  },
  {
    UserName: "kpilcher6",
    Email: "ojiru6@samsung.com",
    UserType: "Active",
    Action: null,
  },
  {
    UserName: "jdee7",
    Email: "schipperfield7@howstuffworks.com",
    UserType: "Active",
    Action: null,
  },
  {
    UserName: "jscally8",
    Email: "jfurlong8@flickr.com",
    UserType: "Suspended",
    Action: null,
  },
  {
    UserName: "cbendall9",
    Email: "ogrishunin9@behance.net",
    UserType: "Suspended",
    Action: null,
  },
  {
    UserName: "mgoghina",
    Email: "srichardeaua@indiatimes.com",
    UserType: "Active",
    Action: null,
  },
  {
    UserName: "aashb",
    Email: "tsealoveb@about.com",
    UserType: "Suspended",
    Action: null,
  },
  {
    UserName: "ndaniellic",
    Email: "ajirusc@unc.edu",
    UserType: "Suspended",
    Action: null,
  },
  {
    UserName: "rtrevilliond",
    Email: "btiversd@imgur.com",
    UserType: "Active",
    Action: null,
  },
  {
    UserName: "msainese",
    Email: "rjorczyke@edublogs.org",
    UserType: "VP Sales",
    Action: null,
  },
  {
    UserName: "wfreundf",
    Email: "rteesf@t-online.de",
    UserType: "Active",
    Action: null,
  },
  {
    UserName: "dhanrottg",
    Email: "lbrodeurg@webs.com",
    UserType: "Suspended",
    Action: null,
  },
  {
    UserName: "emacelhargeh",
    Email: "cmcbayh@loc.gov",
    UserType: "Active",
    Action: null,
  },
  {
    UserName: "kfewlessi",
    Email: "sneeshami@google.co.jp",
    UserType: "Active",
    Action: null,
  },
  {
    UserName: "jpassiej",
    Email: "vjoirisj@abc.net.au",
    UserType: "Suspended",
    Action: null,
  },
  {
    UserName: "bsugark",
    Email: "mbrailsfordk@cpanel.net",
    UserType: "Suspended",
    Action: null,
  },
  {
    UserName: "ndevoyl",
    Email: "bblackhalll@parallels.com",
    UserType: "Active",
    Action: null,
  },
  {
    UserName: "aharbertm",
    Email: "jdonohoem@nbcnews.com",
    UserType: "Active",
    Action: null,
  },
  {
    UserName: "sangrockn",
    Email: "crivaln@wikia.com",
    UserType: "Active",
    Action: null,
  },
  {
    UserName: "bmanthorpeo",
    Email: "dclemenso@springer.com",
    UserType: "Suspended",
    Action: null,
  },
];