import React from "react";
import Navbar from "../../../../components/Navbar/Navbar";
import bg from "../../../../assets/images/background.png";
import "./manuusers.scss";
import Button from "../../../../components/Button/Button";
import { useState, useEffect } from "react";
import Loading from "../../../../components/Loading/Loading";
import { motion } from "framer-motion";
import AnimatedModal from "../../../../components/Modal/AnimatedModal";
import { activeIcon, closeIcon, searchIcon } from "../../../../helper/icons";
import Searchbar from "../../../../components/Searchbar/Searchbar";
import { useDebouncedEffect } from "../../../../helper/useDebounce";
import { axiosApiInstance } from "../../../../helper/request";
import { notify } from "../../../../Routes";
import Scroller from "../../../../components/Scroller/ScrollBar";
import {
  callIcon,
  createdIcon,
  suspendedIcon,
  userIcon,
  emailIcon,
} from "../../Users/Users";
import { getDateTime } from "../../../../helper/storage";
import Pagination from "../../../../components/Pagination/Pagination";
import AssignInventory from "../../Inventory/AssignInventory";
import InputBox from "../../../../components/Inputbox/InputBox";
let isUserFound = false;
let msg = {
  type: "alert",
  header: "Error",
  errorText: "Something Went Wrong",
};

export default function ManufacturerUsers() {
  const [LoadingScreen, setLoadingScreen] = useState(false);
  const [SearchLoading, setSearchLoading] = useState(false);
  const [RenewLicence, setRenewLicence] = useState(false);
  const [AddUserModal, setAddUserModal] = useState(false);
  const [SearchText, setSearchText] = useState("");
  const [CurrentPage, setCurrentPage] = useState(1);
  const [TotalPage, setTotalPage] = useState([]);
  const [Message, setMessage] = useState("");
  const [Status, setStatus] = useState(null);
  const [Data, setData] = useState({
    email: "shaikhshahbaz44@gmail.com",
    is_manufacturer: null,
    username: "sbaz44",
    is_empty: null,
  });
  const [UserData, setUserData] = useState([]);
  const [ShowAlert, setShowAlert] = useState(false);
  const [searchUsers, setSearchUsers] = useState(undefined)

  useDebouncedEffect(() => getUser(), [SearchText], 500);

  const emailValidation = (email) => {
    if (
      email.match(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      return true;
    } else {
      return false;
    }
  };

  useDebouncedEffect(
    () => {
      if (searchUsers?.length >= 3) {
        searchMfgUserDetails();
      }
      if (searchUsers?.length === 0) {
        geManutUser();
      }
    },
    [searchUsers],
    1000
  );
 

  const getUser = () => {
    if (!emailValidation(SearchText)) {
      setData({ ...Data, is_manufacturer: null, is_empty: null });
      setStatus(null);
      setMessage("");
      return;
    }
    setSearchLoading(true);
    axiosApiInstance
      .get("user/invite/manufacturer?email=" + SearchText.trim())
      .then((res) => {
        console.log(res.data);
        if (Object.keys(res.data).length === 0) {
          setData((prev) => {
            return {
              ...prev,
              is_empty: true,
              is_manufacturer: null,
            };
          });
          setStatus("");
        } else {
          setStatus(
            res.data.manufacturer_status === null
              ? "inactive"
              : res.data.manufacturer_status
          );
          if (!res.data.is_manufacturer) {
            setData((prev) => {
              return {
                ...prev,
                is_empty: null,
                is_manufacturer: false,
              };
            });
          } else {
            setData((prev) => {
              return {
                ...prev,
                is_empty: null,
                is_manufacturer: true,
              };
            });
          }
        }
      })
      .catch((err) => {})
      .finally(() => {
        setSearchLoading(false);
      });
    // setSearchLoading(true);
    // setTimeout(() => {
    //   isUserFound = true;
    //   setSearchLoading(false);
    // }, 2000);
  };

  const checkHierarchy = () => {
    setLoadingScreen(true);
    axiosApiInstance
      .get("user/manufacturers/check/invite")
      .then((res) => {
        console.log(res.data.details);
        if (res.data.details === true) {
          setAddUserModal(true);
        } else {
          msg.errorText = "You have reached invite user limit";
          msg.header = "Error";
          msg.type = "alert";
          setShowAlert(true);
          setTimeout(() => {
            setShowAlert(false);
          }, 3000);
        }
      })
      .catch((err) => {
        msg.errorText = "You have been suspended";
        msg.header = "Error";
        msg.type = "alert";
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 3000);
      })
      .finally(() => {
        setLoadingScreen(false);
      });
  };

  const resetState = () => {
    setSearchText("");
    setMessage("");
    setAddUserModal(false);
    setData({
      email: "",
      is_manufacturer: null,
      username: "",
      is_empty: null,
    });
  };

  const postRequest = (type = "Request") => {
    if (Message === "") {
      document.getElementById("msg_").style.border =
        "2px solid rgba(248, 78, 78, 0.7)";
      return;
    }
    setLoadingScreen(true);
    let body = {
      email: SearchText,
      role: "manufacturer",
      message: Message,
    };
    console.log(body);
    axiosApiInstance
      .post("user/invite/manufacturer", body)
      .then((res) => {
        resetState();
        notify({
          msg:
            Status === ""
              ? "Invitation sent successfully!"
              : "Request sent successfully!",
          type: "success",
        });
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.detail === "Already Exists") {
          notify({ msg: "Invitation already sent!" });
        } else {
          notify({});
        }
      })
      .finally(() => {
        setLoadingScreen(false);
      });
  };

  const searchMfgUserDetails = (page = 1) => {
    axiosApiInstance.get( `user/manufacturer/users/details?username=${searchUsers}&page=${page}&items=10`)
    .then((res) => {
      setUserData([...res.data.users]);
      let _totalPages = Math.ceil(res.data.total_count / 10);
      setTotalPage(_totalPages);
    })
      };

  const geManutUser = (page = 1) => {
    axiosApiInstance
      .get("user/manufacturer/users/details?page=" + page + "&items=10")
      .then((res) => {
        setUserData([...res.data.users]);
        let _totalPages = Math.ceil(res.data.total_count / 10);
        setTotalPage(_totalPages);
      });
  };

  useEffect(() => {
    geManutUser();
  }, []);

  return (
    <div className="_manufacturer_users_wrapper_">
      {console.log(UserData, "userdata")}
      <Navbar sidenav>
        <div className="manufacturer_body">
          <img src={bg} className="profile_bg" />
          <div className="body_content fadeIn">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h1>Manufacturers</h1>
              <p className="goBack"></p>
            </div>
            <div className="manufacturer_data">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingTop: "0.8vw",
                  paddingRight: "0.8vw",
                }}
              >
                <InputBox
                  id="search"
                  placeholder="Search by user name"
                  onChange={(e) => {
                    //  = 1;
                    setSearchUsers(e.target.value);
                  }}
                  value={searchUsers}
                  children={searchIcon()}
                />
                <Button
                  style={{
                    width: "11vw",
                    padding: "0.5vw 0",
                    fontSize: "0.7vw",
                    height: "2.5vw"
                  }}
                  name={"Add/Invite User"}
                  onClick={() => checkHierarchy()}
                />
              </div>

              <div
                className="result_table_container"
                // style={{ height: TotalPage <= 1 ? "51vh" : null }}
                style={{ height: "56vh" }}
              >
                {(!LoadingScreen && UserData.length) > 0 ? (
                  <Scroller
                    // autoHeightMax={TotalPage == 1 ? "49vh" : "45vh"}
                    autoHeightMax={"55vh"}
                  >
                    <motion.table
                      cellPadding="0"
                      cellSpacing="0"
                      border="0"
                      className="data_table"
                    >
                      <tbody>
                        {UserData.map((item, idx) => (
                          <tr
                            style={{ cursor: "auto" }}
                            // onClick={() =>
                            //   history.push(
                            //     "/users/" +
                            //       item.username +
                            //       window.location.search
                            //   )
                            // }
                          >
                            <td className="sr"># {idx + 1}</td>
                            <td>
                              <div className="userInfo">
                                {item.profile_pic ? (
                                  <img
                                    alt={"username" + idx}
                                    className="user_avatar_img"
                                    src={item.profile_pic}
                                  />
                                ) : (
                                  userIcon()
                                )}

                                <div className="meta">
                                  <p>{item.full_name}</p>
                                  <p>{item.username}</p>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="adjust_info">
                                {emailIcon()}
                                <p style={{ display: "inline-block" }}>
                                  {" "}
                                  {item.email}
                                </p>
                              </div>
                            </td>
                            <td>
                              <div className="adjust_info">
                                {createdIcon()}
                                <p style={{ display: "inline-block" }}>
                                  {getDateTime(item.created)}
                                </p>
                              </div>
                            </td>
                            <td>
                              <div className="adjust_info">
                                {callIcon()}
                                <p style={{ display: "inline-block" }}>
                                  {item.phone}
                                </p>
                              </div>
                            </td>
                            {/* <td style={{ cursor: "pointer" }}>
                              <div className="adjust_info">
                                <Button
                                  style={{ height: "2.5vw", cursor: "pointer", padding: 0 }}
                                  onClick={() => {
                                    setRenewLicence(true);
                                  }}
                                  name="Assign Inventory"
                                />
                              </div>
                            </td> */}
                            <td>
                              <div className="adjust_info">
                                {item?.roles?.manufacturer === "active"
                                  ? activeIcon()
                                  : suspendedIcon()}
                                <p
                                  style={{
                                    display: "inline-block",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {item?.user_status}
                                </p>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </motion.table>
                  </Scroller>
                ) : (
                  <div className="noData">No user found</div>
                )}
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "0.7vw",
                  padding: "0 1.5vw",
                }}
              >
                <div />
                {TotalPage > 1 && (
                  <Pagination
                    totPages={TotalPage}
                    currentPage={CurrentPage}
                    rowCount={10}
                    pageClicked={(ele, count_) => {
                      console.log(ele);
                      geManutUser(ele);
                      searchMfgUserDetails(ele)
                      // var url = new URL(window.location.href);
                      // url.searchParams.set("page", ele);
                      // window.history.pushState(null, null, url);
                      // getUser();
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </Navbar>
      <AnimatedModal modalOpen={AddUserModal} className="operator_modal_adjust">
        <div className="close_otp_modal" onClick={resetState}>
          {closeIcon()}
        </div>
        <p className="continue_msg" style={{ marginTop: "1vw" }}>
          Add Manufacturer / Invite
        </p>
        <Searchbar
          placeholder="Search / Add by email"
          handleSearch={(e) => {
            setSearchText(e.target.value.toLowerCase());
          }}
          autoFocus={true}
          value={SearchText}
          isLoading={SearchLoading}
        />
        {(Status === "active" || Status === "pending") && (
          <p className="error">
            User is a manufacturer OR Request to become to become a manufacturer
            is still in process.
          </p>
        )}

        {(Status === "" || Status === "inactive") && (
          <div style={{ marginTop: "1vw" }}>
            <p className="label">Message</p>
            <textarea
              id="msg_"
              className="msg_area"
              rows="5"
              cols="50"
              value={Message}
              onChange={(e) => setMessage(e.target.value)}
              onFocus={() => {
                document.getElementById("msg_").style.border =
                  "2px solid #dddddd";
              }}
            ></textarea>
            <Button
              style={{ width: "12vw", marginTop: "1vw" }}
              name={Status === "" ? "Invite User" : "Send Request"}
              onClick={() => postRequest()}
              disabled={!emailValidation(SearchText)}
            />
          </div>
        )}
      </AnimatedModal>
      <AnimatedModal
        modalOpen={ShowAlert}
        handleClose={() => {
          setShowAlert(false);
        }}
        type={msg.type}
        errorHeader={msg.header}
        errorText={msg.errorText}
        // timeout={5000}
        alwayOpen={true}
      />

      {LoadingScreen && <Loading type={"transparent"} text={"Loading"} />}
    </div>
  );
}
