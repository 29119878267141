import axios from "axios";
import React, { useContext, useRef, useState } from "react";
import { useEffect } from "react";
import Scrollbars from "react-custom-scrollbars";
import ReCAPTCHAV2 from "react-google-recaptcha";
import { useHistory } from "react-router-dom";
import { ThemeContext } from "../../helper/context";
import { closeIcon, transparentInfoIcon } from "../../helper/icons";
import { API_URL, axiosApiInstance } from "../../helper/request";
import { encryptStorage } from "../../helper/storage";
import { useDebouncedEffect } from "../../helper/useDebounce";
import { LoadingText } from "../../pages/auth/register/AuthRegister";
import { notify } from "../../Routes";
import Button from "../Button/Button";
import Dropdown from "../Dropdown/Dropdown";
import { FormHeader } from "../FormCard/FormCard";
import InputBox from "../Inputbox/InputBox";
import AnimatedModal from "../Modal/AnimatedModal";
import PhoneInputBox from "../PhoneInputBox/PhoneInputBox";
import { Radio, Radio2 } from "../Radio/Radio";
import Scroller from "../Scroller/ScrollBar";
import "./devModal.scss";
const radios = ["Freelancer", "Student", "Company"];
const develop = ["Usecase", "AI Model", "IOT Firmware"];
let msg = {
  type: "alert",
  header: "Error",
  errorText: "Something Went Wrong",
};
let firstLoad = true;
export default function ManufacturerModal({
  setManufModal,
  ManufModal,
  setLoadingScreen = false,
  setShowAlert,
  handleAlert,
  newURL = null,
}) {
  const [ShowBank, setShowBank] = useState(true);
  const [IsBankAvailable, setIsBankAvailable] = useState(true);
  const [CompanyName, setCompanyName] = useState("");
  const [Bank, setBank] = useState({});
  const [TOS, setTOS] = useState(false);
  const [Message, setMessage] = useState("");
  const themeContext = useContext(ThemeContext);
  const [Name, setName] = useState("");
  const [Phone, setPhone] = useState("+91 ");
  const [Country, setCountry] = useState("");
  const [LocationLoading, setLocationLoading] = useState(false);
  const [LocationData, setLocationData] = useState([]);
  const [LocationSelected, setLocationSelected] = useState(false);
  useDebouncedEffect(
    () => (Country ? getCountry() : undefined),
    [Country],
    1000
  );

  const getCountry = () => {
    if (firstLoad) return;
    axios
      .get(API_URL + "admin/country/filters?country=" + Country)
      .then((res) => {
        let _data = res.data.detail;
        let keys = null;
        keys = Object.keys(_data[0]);
        if (!keys?.includes("state")) {
          setLocationData(_data);
          setLocationSelected(false);
        } else {
          setLocationData([]);
          getBank(Country);
          setLocationSelected(true);
        }
      })
      .catch((err) => {
      })
      .finally(() => {
        setLocationLoading(false);
      });
  };
  const handleChanges = (value, idx) => {
    const _bank = { ...Bank };
    _bank.fields[idx].value = value;
    setBank({ ..._bank });
  };

  const renderElement = (item, idx) => {
    switch (item.type) {
      case "string":
      case "number":
        return (
          <InputBox
            id={item.name}
            header={item.name.replace(/_/g, " ")}
            onChange={(e) => {
              const value =
                item.type === "string"
                  ? e.target.value
                  : e.target.value.replace(/[^0-9]/g, "");
              handleChanges(value, idx);
            }}
            value={item.value}
            onFocus={() => handleFocus(item.name)}
          />
        );
      case "dropdown":
        return (
          <Dropdown
            label={item.name.replace(/_/g, " ")}
            className="adjust_dd"
            id={item.name}
            optionsList={item.options}
            handleOption={(data) => {
              handleChanges(data, idx);
              handleFocus(item.name);
              // setAccountType(data);
            }}
            defaultText={item.value}
            // error={BankError["isAccountTypeEmpty"]}
          />
        );
      default:
        return <p> NO ELEMENT FOUND</p>;
    }
  };

  const getKeyCode = (e) => {
    switch (e.keyCode) {
      case 38:
        arrowKey(e, "up");
        break;

      case 40:
        arrowKey(e, "down");
        break;

      case 13:
        enterKey(e);
        break;
    }
  };

  const arrowKey = (e, type) => {
    let myReferenceDiv = document.querySelector("._span");
    let active_flag = document.querySelector(".active_flag");
    let active_next = active_flag?.nextElementSibling;
    let active_prev = active_flag?.previousElementSibling;
    let next = myReferenceDiv?.nextElementSibling;
    let prev = myReferenceDiv?.previousElementSibling;
    if (type === "down") {
      if (!active_flag) {
        next.classList.add("active_flag");
      } else {
        if (active_next) {
          active_flag.classList.remove("active_flag");
          active_next.classList.add("active_flag");
          var childElement =
            document.querySelector(".active_flag").offsetTop -
            myReferenceDiv.offsetHeight -
            20;
        }
      }
    }
    if (type === "up") {
      if (active_prev && !active_prev.classList.contains("_span")) {
        active_flag.classList.remove("active_flag");
        active_prev.classList.add("active_flag");
        var childElement =
          document.querySelector(".active_flag").offsetTop -
          myReferenceDiv.offsetHeight -
          20;
      }
    }
  };

  const enterKey = () => {
    let active_flag = document.querySelector(".active_flag");
    let dataCountry = active_flag.getAttribute("data-country");
    if (dataCountry) {
      setCountry(dataCountry);
      getBank(dataCountry);
      setLocationData([]);
      setLocationSelected(true);
    }
  };

  const getBank = (country) => {
    axios
      .get(API_URL + "admin/bank/details?country=" + country)
      .then((res) => {
        // setShowBank(true);
        let dict = { ...res.data };
        for (let i = 0; i < dict.fields.length; i++) {
          dict.fields[i].value = "";
        }
        setIsBankAvailable(true);
        setBank({ ...dict });
      })
      .catch((err) => {
        setIsBankAvailable(false);
        // setShowBank(false);
        setBank({});
        // notify({});
      })
      .finally(() => {
        setLoadingScreen(false);
        setTimeout(() => {
          firstLoad = false;
        }, 2000);
      });
  };

  const validateBankData = () => {
    let res = false;
    for (let i = 0; i < Bank.fields.length; i++) {
      if (Bank.fields[i].required) {
        if (!Bank.fields[i].value) {
          let ele = document.getElementById(Bank.fields[i].name);
          ele.classList.add("error_shake", "input__error");
          res = true;
        }
      }
    }
    return res;
  };

  const postData = () => {
    let _errors = false;
    let _mobile = Phone.split(" ")[1];
    if (CompanyName === "") {
      document.getElementById("companyName").style.border =
        "2px solid rgba(248, 78, 78, 0.7)";
      _errors = true;
    }

    if (Message === "") {
      document.getElementById("msg_").style.border =
        "2px solid rgba(248, 78, 78, 0.7)";
      _errors = true;
    }

    if (TOS === false) {
      document.querySelector("._tos").style.border =
        "2px solid rgba(248, 78, 78, 0.7)";
      document.querySelector("._tos").style.padding = "0.3vw";
      _errors = true;
      notify({
        msg: "Please accept our Terms of Service.",
      });
    }

    if (ShowBank && IsBankAvailable) {
      if (!Name) {
        document
          .getElementById("full_Name")
          .classList.add("error_shake", "input__error");
        _errors = true;
      }
      if (!_mobile) {
        document
          .getElementById("_mobile_number_")
          .classList.add("error_shake", "input__error");
        _errors = true;
      }
      if (!Country) {
        document
          .getElementById("country")
          .classList.add("error_shake", "input__error");
        _errors = true;
      }
      if (validateBankData()) {
        notify({
          msg: "Please fill bank details",
        });
        _errors = true;
      }
    }

    if (_errors) {
      notify({
        msg: "All field required",
      });
      return;
    }

    let body = {
      company_name: CompanyName,
      message: Message,
    };

    if (ShowBank && IsBankAvailable) {
      let _bank = {};
      for (let i = 0; i < Bank.fields.length; i++) {
        _bank[Bank.fields[i].name] = Bank.fields[i].value;
      }
      body.contact_person_name = Name;
      body.contact_person_phone = Phone;
      body.country = Country;
      body.bank_details = { ..._bank };
    }
    let url = "user/resend/manufacturer";
    if (!encryptStorage.getItem("UID").rejected) {
      url = "user/signup/manufacturer";
    }
    if (newURL) {
      url = newURL;
      body.status = "accepted";
    }
    axiosApiInstance
      .post(url, body)
      .then((res) => {
        let _data = encryptStorage.getItem("UID");
        _data.pending = ["manufacturer"];
        _data.rejected = false;
        encryptStorage.setItem("UID", _data);
        themeContext.setRole({
          ...themeContext.Role,
          pending: ["manufacturer"],
          rejected: false,
        });
        msg.errorText = "Request to become a manufacturer sent successfully!";
        msg.header = "Success";
        msg.type = "success";
        handleAlert(true, msg);
        setShowAlert(true);
        setTimeout(() => {
          setManufModal(false);
        }, 3000);
      })
      .catch((err) => {
        msg.errorText = "Failed to send manufacturer data.!";
        msg.header = "Error";
        msg.type = "alert";
        handleAlert(true, msg);
        setShowAlert(true);
      });
  };

  useEffect(() => {
    if (ManufModal) {
      setLoadingScreen(true);
      axiosApiInstance
        .get("user/details/check")
        .then((res) => {
          if (res.data?.company_name) {
            setCompanyName(res.data?.company_name);
          }
          if (!res.data?.bank_present) {
            setShowBank(true);
            setCountry(res.data?.country);
            getBank(res.data?.country);
          } else {
            setShowBank(false);
            setCountry(res.data?.country);
            setLoadingScreen(false);
          }
        })
        .catch((err) => {
          notify({});
          setLoadingScreen(false);
        });
    }
    return () => {
      firstLoad = true;
    };
  }, [ManufModal]);

  if (!ManufModal) {
    return;
  }

  return (
    <AnimatedModal modalOpen={ManufModal} className="dev_modal_wrapper">
      <div
        className="close_otp_modal"
        onClick={() => {
          setManufModal(false);
          setMessage("");
          setCountry("");
          setBank({});
          setShowBank(false);
          setName("");
          setPhone("");
          setTOS(false);
        }}
      >
        {closeIcon()}
      </div>
      <FormHeader name="Registering " bold="Manufacturer." />
      <p className="sub">Join us to make AI reach the masses.</p>
      <Scroller autoHeightMax={"26vw"} autoHeightMin={"15vw"}>
        <div style={{ paddingRight: "1.2vw" }}>
          <InputBox
            id="companyName"
            header="Company name"
            onChange={(e) => {
              const value = e.target.value;
              setCompanyName(value);
              // const regex = /^[a-zA-Z ]*$/;
              // if (value.match(regex) || value === "") {
              //   setCompanyName(value);
              // }
            }}
            // error={errors["isDevelopEmpty"]}
            value={CompanyName}
            onFocus={() => {
              document.getElementById("companyName").style.border =
                "2px solid #dddddd";
            }}
          />
          <p className="label">Message</p>
          <textarea
            id="msg_"
            className="msg_area"
            rows="5"
            cols="50"
            value={Message}
            onChange={(e) => setMessage(e.target.value)}
            onFocus={() => {
              document.getElementById("msg_").style.border =
                "2px solid #dddddd";
            }}
          />

          {ShowBank && (
            <React.Fragment>
              <div className="country_section">
                <InputBox
                  id="country"
                  header="Country"
                  onChange={(e) => {
                    const value = e.target.value;
                    const regex = /^[a-zA-Z ]*$/;
                    if (value.match(regex) || value === "") {
                      setCountry(value);
                      setLocationLoading(true);
                    } else {
                      setLocationLoading(false);
                    }
                    setName("");
                    setPhone("+91 ");
                    setIsBankAvailable(false);
                  }}
                  onKeyDown={getKeyCode}
                  value={Country}
                />
                {LocationLoading && Country ? (
                  <div className="suggestions">
                    <LoadingText />
                  </div>
                ) : (
                  LocationData.length > 0 && (
                    <div className="suggestions">
                      <Scrollbars autoHeight autoHeightMax="15vh">
                        <span className="_span" />
                        {LocationData.map((item) => (
                          <div
                            key={item.country}
                            className="suggestion_item"
                            data-country={item?.country}
                            onClick={() => {
                              setCountry(item?.country);
                              setLocationData([]);
                              getBank(item?.country);
                              setLocationSelected(true);
                              firstLoad = true;
                              setTimeout(() => {
                                firstLoad = false;
                              }, 2000);
                            }}
                          >
                            {item?.country}
                          </div>
                        ))}
                      </Scrollbars>
                    </div>
                  )
                )}
              </div>
              <div style={{ marginTop: "1vw" }} />
              {IsBankAvailable && (
                <div className="contact_person">
                  <span className="radio-header">Contact Person</span>
                  <div className="bank_detail">
                    <InputBox
                      id="full_Name"
                      header="Name"
                      onChange={(e) => {
                        const value = e.target.value;
                        const regex = /^[a-zA-Z ]*$/;
                        if (value.match(regex) || value === "") {
                          setName(value);
                        }
                      }}
                      value={Name}
                      onFocus={() => handleFocus("full_Name")}
                    />
                    <PhoneInputBox
                      id="_mobile_number_"
                      isEdit={true}
                      onChange={(data) => {
                        // if (data.inputNumber) {
                        let _number = data?.countryCode + " " + data.inputNumber;
                        setPhone(_number);
                        // }
                      }}
                      onFocus={() => handleFocus("_mobile_number_")}
                      value={Phone}
                    />
                  </div>
                </div>
              )}
              {IsBankAvailable && (
                <span className="radio-header">
                  Bank Details
                  {/* {transparentInfoIcon({
                  marginLeft: "1vw",
                  cursor: "pointer",
                  verticalAlign: "middle",
                })} */}
                </span>
              )}

              {IsBankAvailable && (
                <div className="bank_detail">
                  {Bank?.fields?.map((item, idx) => renderElement(item, idx))}
                </div>
              )}
            </React.Fragment>
          )}
          {!IsBankAvailable && LocationSelected && (
            <div className="noBank">
              Your country's bank is currently not supported. Please Contact us
              for further query.
            </div>
          )}
        </div>
      </Scroller>
      <div className="recaptcha">
        <ReCAPTCHAV2
          data-type="image"
          sitekey={process.env.REACT_APP_SITE_KEY}
          // onChange={onChange}
          // size="compact"
        />
      </div>
      <div className="_tos">
        <Radio
          id="checkbox_002"
          checked={TOS}
          onClick={() => {
            if (TOS === false) {
              document.querySelector("._tos").style.border = "unset";
              document.querySelector("._tos").style.padding = "unset";
            }
            setTOS(!TOS);
          }}
          className="adjust_cb"
        />
        <p>
          Creating an account means you’re okay with our{" "}
          <span>
            Terms of Service,
            <br /> Privacy Policy,
          </span>{" "}
          and our default <span>Notification Settings</span>.
        </p>
      </div>
      <Button
        onClick={postData}
        style={{
          display: "block",
          margin: "2vw auto",
          marginBottom: "0",
          width: "90%",
        }}
        type="gradient"
        bg
        name="Submit"
      />
    </AnimatedModal>
  );
}
export const handleFocus = (name) => {
  let ele = document.getElementById(name);
  ele.classList.remove("error_shake", "input__error");
};
