import React, { useEffect, useState } from "react";
import "../ViewApp/RatingsReviews.scss";
import { star, next } from "../../../../../helper/icons";
import StarRating from "./StarRating";
import ProgressBar from "../../../../user/Apps/Component/ViewApp/ProgressBar";
import { useQuery } from "../../../../../helper/storage";
import { axiosApiInstance } from "../../../../../helper/request";
import Loading from "../../../../../components/Loading/Loading";

export default function RatingsReviews({ bar, onclick, number, total = 100, setState, fetchApp }) {
  const average = Array.isArray(number)
    ? number.reduce((a, b) => a + b, 0) / number.length
    : 0;
  let progressBar = [0, 0, 0, 0, 0];

  Array.isArray(bar) &&
    bar.map((x) => {
      progressBar.splice(parseInt(x.rating) - 1, 1, x.rating_count);
    });

  // console.log(bar, number, total, progressBar);
  const queryy = useQuery();
  let firstLoad = true;

  const [LoadingScreen, setLoadingScreen] = useState(false);

  useEffect(() => {
    let app_name = queryy.get("app_name") || "";
    setLoadingScreen(true);

    axiosApiInstance
      .get(`apps/rating?app_name=${app_name}`)
      .then((res) => {
        setState(res.data.detail.ratings);
      })
      .finally(() => {
        setLoadingScreen(false);
        setTimeout(() => {
          firstLoad = false;
        }, 2000);
      });
    return () => {};
  }, [fetchApp]);
  return (
    <div>
      <div className="usecase_ratings_reviews">
        <div style={{ position: "relative", fontWeight: "800", fontSize: "1.04vw" }}>
          {number <= 1 ? "Rating and review" : "Ratings and reviews"}
        </div>
        {number > 0 && (
          <span onClick={onclick} className="next_icon">
            {next("ratings_next_icon")}
          </span>
        )}
      </div>

      <div className="usecase_rr_main_wrapper">
        <div className="usecase_rr_upper_wrapper">
          <h2 className="number_of_stars">{Math.round(average)}</h2>
          <StarRating total={5} selected={Math.round(average)} />
          <div className="review_tag">
            <p>{Array.isArray(number) ? number.length : 0}</p>{" "}
            <p>{number === 0 ? "review" : "reviews"}</p>
          </div>
        </div>
        <div className="ratings_wrapper">
          <div className="stars_progressbar">
            {progressBar.map((info, i) => (
              <ProgressBar
                bgcolor="#043DA8"
                progress={(info / total) * 100}
                height={10}
                name={i}
                Width={total}
              />
            ))}
          </div>
          {/* <h5 className="stars">{star()}</h5> */}
        </div>
      </div>
      {LoadingScreen && <Loading type={"transparent"} text={"Loading"} />}

    </div>
  );
}

export const ReviewCard = ({
  reviewerName,
  content,
  stars,
  setState,
  fetchApp,
}) => {
  const queryy = useQuery();

  useEffect(() => {
    let app_name = queryy.get("app_name") || "";
    // setLoadingScreen(true);

    axiosApiInstance.get(`apps/review?app_name=${app_name}`).then((res) => {
      setState(res.data.detail);
    });
    // .finally(() => {
    //   setLoadingScreen(false);
    //   setTimeout(() => {
    //     firstLoad = false;
    //   }, 2000);
    // });
    return () => {};
  }, [fetchApp]);
  return (
    <div className="usecase_rr_lower_wrapper">
      <div className="image_name_wrapper">
        <img
          className="reviewer_img"
          src="https://source.unsplash.com/random/200x200?sig="
        ></img>
        <h4 style={{fontSize: "1.45vw"}} className="usecase_reviewer_name">{reviewerName}</h4>
      </div>
      <div>
        <StarRating total={5} selected={stars} />
      </div>

      <p style={{fontSize: "1.041vw"}}>{content}</p>
    </div>
  );
};
