import React, { useEffect, useRef, useState } from "react";
import { useDebouncedEffect } from "../../../../helper/useDebounce";
import { axiosApiInstance } from "../../../../helper/request";
import AnimatedModal from "../../../../components/Modal/AnimatedModal";
import { closeIcon } from "../../../../helper/icons";
import Searchbar from "../../../../components/Searchbar/Searchbar";
import { LoadingText } from "../../../auth/register/AuthRegister";
import Scroller from "../../../../components/Scroller/ScrollBar";
import Button from "../../../../components/Button/Button";

let changeUserPage = 1;
let msg = {
  type: "alert",
  header: "Error",
  errorText: "Something Went Wrong",
};
let mgfUserName = [];

export default function AssignUserMgf({
  assignModal,
  setassignModal,
  Type,
  mfrDetails,
  setmfrDetails,
  usersUserName,
  removeMfgData,
  TotalRestrictedUsers,
  userFlag,
  license_name,
  getMfgNames,
  license_id,
  pricing_details,
  app_name,
}) {
  const scrollbar = useRef(null);
  const [SearchText, setSearchText] = useState("");
  const [UserList, setUserList] = useState([]);
  const [OperatorLoading, setOperatorLoading] = useState(false);
  const [ShowAlert, setShowAlert] = useState(false);
  const [DisappearSearch, setDisappearSearch] = useState(false);

  useDebouncedEffect(
    () => (SearchText ? getUsers() : undefined),
    [SearchText],
    1000
  );

  const handleUserScroll = () => {
    if (scrollbar.current.getValues().top >= 0.95) {
      if (
        changeUserPage <= Math.floor(TotalRestrictedUsers / 10) &&
        !userFlag
      ) {
        userFlag = true;
        changeUserPage += 1;
        getMfgNames(license_name, Type, changeUserPage);
      }
    }
  };

  const getUsers = () => {
    if (!SearchText) {
      return;
    }
    let url = "user/superadmin/manufacturers?field=";
    if (Type === "restrictUser") {
      url = "user/search/restricted/users?searchname=";
    }
    setOperatorLoading(true);
    axiosApiInstance
      .get(url + SearchText)
      .then((res) => {
        let _arr = [];

        res.data.details.forEach((element) => {
          let _mfrDetails = [...mfrDetails];
          console.log(_mfrDetails);
          _mfrDetails.length
            ? _mfrDetails.map((suggestion) => {
                if (suggestion.username !== element.username) {
                  _arr.push({ username: element.username });
                }
              })
            : _arr.push({ username: element.username });
        });
        setUserList(_arr);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setOperatorLoading(false);
      });
  };

  const removeMfg = (id) => {
    console.log("removeeee");
    let newArr = [...mfrDetails];
    newArr.splice(id, 1);
    mgfUserName.splice(id, 1);
    usersUserName.splice(id, 1);
    console.log(usersUserName, "userrrrrrrrr");
    setmfrDetails([...newArr]);
    if (
      removeMfgData.includes(mfrDetails[id].username) &&
      Type === "assignMfg"
    ) {
      axiosApiInstance
        .delete(
          "license/delete?license_id=" +
            license_id +
            "&username=" +
            mfrDetails[id].username
        )
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const postMfg = () => {
    let url = ``;
    if (app_name !== undefined) {
      url = `license/assignupdate?license_type=apps&app_name=${app_name.replaceAll(
        " ",
        "_"
      )}`;
    } else url = `license/assignupdate`;
    if (Type === "assignMfg") {
      axiosApiInstance
        .post(url, {
          name: license_name,
          pricing_details: pricing_details,
          child_manufacturer: mgfUserName,
          show_to_user: false,
        })
        .then((res) => {
          msg.errorText = "Successfully Assigned";
          msg.header = "Success";
          msg.type = "success";
          setShowAlert(true);
          setTimeout(() => {
            setShowAlert(false);
          }, 3000);
        })
        .catch((err) => {
          msg.errorText = "Couldn't Assign";
          msg.header = "Error";
          msg.type = "alert";
          setShowAlert(true);
          setTimeout(() => {
            setShowAlert(false);
          }, 3000);
        })
        .finally(() => {
          setassignModal(false);
          setSearchText("");
          setUserList([]);
          setmfrDetails([]);
          mgfUserName = [];
          usersUserName = [];
          changeUserPage = 1;
        });
    } else {
      axiosApiInstance
        .post("license/edit/restricted_users", {
          license_id: license_id,
          restrictedlist: usersUserName,
        })
        .then((res) => {
          msg.errorText = "Successfully Restricted";
          msg.header = "Success";
          msg.type = "success";
          setShowAlert(true);
          setTimeout(() => {
            setShowAlert(false);
          }, 3000);
        })
        .catch((err) => {
          msg.errorText = "Couldn't Restrict";
          msg.header = "Error";
          msg.type = "alert";
          setShowAlert(true);
          setTimeout(() => {
            setShowAlert(false);
          }, 3000);
        })
        .finally(() => {
          setassignModal(false);
          setmfrDetails([]);
          mgfUserName = [];
          usersUserName = [];
        });
    }
  };

  return (
    <div className="apps_assign_modal">
      {console.log(mgfUserName)}
      <AnimatedModal
        modalOpen={assignModal}
        className="apps_upgrade_licence_modal"
      >
        <div className="apps_assign_mfr_wrapper">
          <div
            className="close_otp_modal"
            onClick={() => {
              setassignModal(false);
              setSearchText("");
              setUserList([]);
              changeUserPage = 1;
              mgfUserName = [];
            }}
          >
            {closeIcon()}
          </div>
          <p className="assign_label">
            {Type === "assignMfg"
              ? "Assign to Manufacturers"
              : "Add Users To Restrict"}
          </p>

          <Searchbar
            placeholder="Search by username"
            handleSearch={(e) => {
              setSearchText(e.target.value);
              if (e.target.value === "") {
                setUserList([]);
              }
            }}
            autoFocus
            onFocus={() => {
              setDisappearSearch(true);
            }}
            value={SearchText}
            className="search_bar_mfr"
          />
          {OperatorLoading && <LoadingText />}
          {UserList.length > 0 && DisappearSearch && (
            <ul className="autocomplete_results">
              <Scroller autoHeightMax="15vw">
                <span className="_span" />
                {UserList?.map((item, index) => (
                  <li
                    key={item.username + index + "index"}
                    className="options"
                    onClick={() => {
                      setSearchText("");
                      setUserList([]);

                      let _mfrDetails = [...mfrDetails];
                      let repeated = false;

                      _mfrDetails.map((items, index) => {
                        console.log(
                          items.username,
                          "already",
                          item.username,
                          "searched"
                        );
                        console.log(
                          _mfrDetails,
                          "_mfrDetails",
                          UserList,
                          "UserList"
                        );
                        if (items.username === item.username) {
                          repeated = true;
                          console.log("hhh");
                          // document.getElementById("license_" + index).focus();
                        }
                      });

                      if (!repeated) {
                        mgfUserName.push(item.username);
                        setmfrDetails([..._mfrDetails, item]);
                        usersUserName.push(item.username);
                      }
                    }}
                    style={{ textTransform: "capitalize" }}
                  >
                    {item.username}
                  </li>
                ))}
              </Scroller>
            </ul>
          )}

          <div className="mfr_table">
            <Scroller
              onScroll={handleUserScroll}
              ref={scrollbar}
              autoHeightMax={"18vw"}
              autoHeightMin={"18vw"}
            >
              {mfrDetails.length > 0 ? (
                <table id="mfr">
                  <tr style={{ height: "4vh" }}>
                    <th>Sr.No</th>
                    <th style={{ textAlign: "left" }}>Manufacturer</th>
                    <th></th>
                  </tr>

                  {mfrDetails?.map((item, index) => (
                    <tr key={index + "mfrMap"} className="td_child_row">
                      <td>{index + 1}</td>
                      <td style={{ textAlign: "left" }}> {item.username}</td>
                      <td
                        style={{
                          cursor: "pointer",
                          textAlign: "right",
                          fontSize: "0.7vw",
                        }}
                        onClick={() => {
                          removeMfg(index);
                        }}
                      >
                        {trashIcon()} remove
                      </td>
                    </tr>
                  ))}
                </table>
              ) : (
                <p className="no_data_found">No Data Found</p>
              )}
            </Scroller>
          </div>

          <div className="mfr_btns">
            <Button
              name="Save"
              style={{ width: "9vw", padding: "0.4vw" }}
              onClick={postMfg}
            />
          </div>
        </div>
      </AnimatedModal>
      <AnimatedModal
        modalOpen={ShowAlert}
        handleClose={() => {
          setShowAlert(false);
        }}
        type={msg.type}
        errorHeader={msg.header}
        errorText={msg.errorText}
        // timeout={5000}
        alwayOpen={true}
      />
    </div>
  );
}

const trashIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
      style={{ height: "0.6vw", width: "0.6vw" }}
      fill="#00000080"
    >
      <path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z" />
    </svg>
  );
};
