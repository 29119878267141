import React from "react";
import AnimatedModal from "../../../../components/Modal/AnimatedModal";
import { closeIcon } from "../../../../helper/icons";
import Scroller from "../../../../components/Scroller/ScrollBar";
import { motion } from "framer-motion";
import Button from "../../../../components/Button/Button";
import InputBox from "../../../../components/Inputbox/InputBox";

export default function EditPricing({
  EditLicenseModal,
  setEditLicenseModal,
  license_name,
  EditLicense,
  setEditLicense,
  className,
  postEditLicense,
}) {
  const arr = ["attribute_name", "monthly", "quarterly", "yearly", "lifetime"];

  function editLicenseAtris(val, idx, idx2, header) {
    let newArr = [...EditLicense];
    // let arr2 = [];
    if (header !== "attribute_name") {
      newArr[idx][arr[idx2]].amount = val;
    }
    setEditLicense([...newArr]);
    console.log(newArr);
  }

  return (
    <div className="_apps_edit_license_modal">
      <AnimatedModal
        modalOpen={EditLicenseModal}
        className="apps_edit_license_modal"
      >
        <div className="apps_edit_license_modal_wrapper">
          <div
            className="close_otp_modal"
            onClick={() => {
              setEditLicenseModal(false);
            }}
          >
            {closeIcon()}
          </div>
          <div className="apps _edit_license_table">
            <InputBox
              id="__apps_license_name"
              value={license_name}
              disabled
            />
            <div
              className={
                className ? "table_wrapper__ " + className : "table_wrapper__"
              }
            >
              <table className="_table_header_">
                <thead>
                  <tr>
                    {arr.map((header) => (
                      <th key={header}>{header.replace(/_/g, " ")}</th>
                    ))}
                  </tr>
                </thead>
              </table>
              <div className="_tbl_content_">
                <Scroller autoHeightMax="20vw" autoHeightMin="20vw">
                  <table className="_data_table_">
                    <tbody>
                      {EditLicense.map((item, idx) => {
                        return (
                          <tr
                            key={"idx" + idx + "row"}
                            className="attribute_row"
                          >
                            {arr.map((header, idx2) => {
                              return header === "attribute_name" ? (
                                <td
                                  key={item[header] + "idx2" + idx2 + "column"}
                                >
                                  <input
                                    // autoFocus
                                    id={"input_" + idx}
                                    value={item[header]}
                                  />
                                </td>
                              ) : (
                                <td
                                  key={
                                    item[header] + "idx2" + idx2 + "secondcol"
                                  }
                                >
                                  <input
                                    value={item[header]?.amount}
                                    onChange={(e) => {
                                      if (Number(e.target.value) <= 9999999) {
                                        editLicenseAtris(
                                          Number(
                                            e.target.value.replace(
                                              /[^0-9]/g,
                                              ""
                                            )
                                          ),
                                          idx,
                                          idx2
                                        );
                                      }
                                    }}
                                  />
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </Scroller>
              </div>
            </div>
            <Button
              id="save_btn"
              onClick={() => {
                postEditLicense();
              }}
              style={{
                position: "absolute",
                right: "3%",
                bottom: "2%",
              }}
              name="Save"
            />
          </div>
        </div>
      </AnimatedModal>
    </div>
  );
}
