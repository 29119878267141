import React from "react";
import "./SliderCard.scss"
let videoid = null;


export default function SliderCard({
  className,
  imgupload,
  videoplayer,
  slider_name,
  uploadedMedia,
  appImgMedia,
  appImgUpload,
  ImageClass,
  MediaClass,
  videoid,
  id,
}) {
  return (
    <div>
      <div
        className={
          className
            ? "slider_card_wrapper " + className
            : "slider_card_wrapper"
        }
      >
        {uploadedMedia?.type.split("/")[0] === "image" && (
          <img
            src={imgupload}
            alt="slider"
            className={
              className ? "slider_image " + MediaClass : "slider_image"
            }
          ></img>
        )}
        {uploadedMedia?.type.split("/")[0] === "video" && (
          <video
            onClick={() => videoid((videoid = id))}
            width="500vw"
            height="280vw"
            id={id}
            controls
            style={{borderRadius: "10px", objectFit: "cover"}}
          >
            <source src={videoplayer} />
          </video>
        )}

        {appImgMedia?.type.split("/")[0] === "image" && (
          <img
            src={appImgUpload}
            alt="slider"
            className={
              className ? "slider_image " + ImageClass : "slider_image"
            }
          ></img>
        )}

        <p className="slider_name">{slider_name}</p>
      </div>
    </div>
  );
}
