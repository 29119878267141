import React, { useState } from "react";
import { useRef } from "react";
import { closeIcon } from "../../helper/icons";
import { axiosApiInstance } from "../../helper/request";
import { encryptStorage } from "../../helper/storage";
import Button from "../Button/Button";
import InputBox from "../Inputbox/InputBox";
import AnimatedModal from "../Modal/AnimatedModal";
import "./confirmmodal.scss";
export default function ConfirmPasswordModal({
  setConfirmModal,
  ConfirmModal,
  handleAlert,
  handleEnableValue = null,
  handleEnableEmail = null,
  type = null,
  setShowResetPassword = null,
}) {
  const [Password, setPassword] = useState("");
  const postData = () => {
    axiosApiInstance
      .post(
        "user/validate/user",
        {},
        {
          headers: {
            password: Password,
          },
        }
      )
      .then((res) => {
        setConfirmModal(false);
        setPassword("");

        // if (setShowEmailInputModal) {
        //   setShowEmailInputModal(true);
        // }
        if (type === "edit") {
          handleEnableValue(Password);
          setTimeout(() => {}, 500);
        } else if (type === "password") {
          setShowResetPassword(true);
        } else {
          setTimeout(() => {}, 500);
          handleEnableEmail(Password);
        }
        // if (setShowOTP) {
        //   setShowOTP(true);
        // }
      })
      .catch((err) => {
        let msg = {};
        msg.errorText = "Incorrect Password";
        msg.header = "Alert";
        msg.type = "alert";
        handleAlert(true, msg);
      });
  };
  return (
    <AnimatedModal
      modalOpen={ConfirmModal}
      className="confirm_password_wrapper"
    >
      <div
        className="close_otp_modal"
        onClick={() => {
          setPassword("");
          setConfirmModal(false);
          // setEmail("");
        }}
      >
        {closeIcon()}
      </div>
      <p className="sub">Confirm access</p>
      <InputBox
        id="passwordConfirm"
        autoFocus
        header="Password"
        type="password"
        onChange={(e) => {
          const value = e.target.value;
          const regex = /^[a-zA-Z0-9!@#$%&()+]*$/;
          if (value.match(regex) || value === "") {
            setPassword(value);
          }
        }}
        value={Password}
        onKeyDown={(e) => {
          if (e.keyCode === 13) {
            postData();
          }
        }}
      />
      <Button name="Confirm" disabled={!Password} onClick={postData} />
    </AnimatedModal>
  );
}
