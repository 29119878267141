import React, { useCallback, useEffect, useRef, useState } from "react";
import Button from "../../../components/Button/Button";
import useClickOutside from "../../../helper/useClickOutside";
let option = ["Past 7 days", "Past 30 days", "Past 60 days", "Past 90 days"];
let minDate,
  maxDate = null;
export default function DateFilter({ id, handleValue }) {
  const primaryRef = useRef();
  const primaryClose = useCallback(() => {
    document.querySelector("." + id).classList.remove("active__");
    setTimeout(() => {
      setShowOption(false);
    }, 200);
  }, []);
  useClickOutside(primaryRef, primaryClose);
  const [Selected, setSelected] = useState("Past 30 days");
  const [ShowOption, setShowOption] = useState(false);
  const [startDate, setstartDate] = useState("");
  const [endtDate, setendtDate] = useState("");

  useEffect(() => {
    if (ShowOption) {
      setTimeout(() => {
        document.querySelector("." + id).classList.add("active__");
      }, 100);
    }
  }, [ShowOption]);

  const formatDate = (date) => {
    let _res = date.split("-");
    [_res[0], _res[2]] = [_res[2], _res[0]];
    _res = _res.join("-");
    return _res;
  };
  const setIntialTime = (days = 30) => {
    let today = new Date();
    let lastdays = new Date(today.setDate(today.getDate() - days));
    let dd = String(lastdays.getDate()).padStart(2, "0");
    let mm = String(lastdays.getMonth() + 1).padStart(2, "0"); //January is 0!
    let yy = lastdays.getFullYear();
    handleValue(dd + "-" + mm + "-" + yy, formatDate(currentDate()));
  };
  let currentDate = () => {
    let _today = new Date();
    let ddd = _today.getDate();
    let mmm = _today.getMonth() + 1;

    let yyyy = _today.getFullYear();
    if (ddd < 10) {
      ddd = "0" + ddd;
    }
    if (mmm < 10) {
      mmm = "0" + mmm;
    }
    maxDate = yyyy + "-" + mmm + "-" + ddd;
    return maxDate;
  };
  useEffect(() => {
    let today = new Date();
    let lastdays = new Date(today.setDate(today.getDate() - 30));
    let dd = String(lastdays.getDate()).padStart(2, "0");
    let mm = String(lastdays.getMonth() + 1).padStart(2, "0"); //January is 0!
    let yy = lastdays.getFullYear();
    setstartDate(yy + "-" + mm + "-" + dd);
    setendtDate(currentDate());
    setIntialTime();
  }, []);

  // useEffect(() => {
  //   console.log(!option.includes(Selected));
  //   if (!option.includes(Selected)) {
  //     handleValue(startDate, endtDate);
  //   }
  // }, [Selected]);

  return (
    <div className="date__filter_container">
      <label htmlFor="date_container">Date</label>
      <button
        id="date_container"
        onClick={() => {
          if (!ShowOption) {
            setShowOption(true);
            setTimeout(() => {
              document.querySelector("." + id).classList.add("active__");
            }, 100);
          } else {
            primaryClose();
          }
        }}
      >
        {Selected}
        {dateIcon()}
      </button>
      {ShowOption && (
        <div className={"select-options " + id} id={id} ref={primaryRef}>
          {option.map((item) => (
            <button
              type="button"
              key={item}
              className="select-option"
              // className={
              //   defaultSelectText === option.name
              //     ? "custom-select-option active_option"
              //     : "custom-select-option"
              // }
              // data-name={option.name}
              // key={option.id}
              onClick={() => {
                let num = item.replace(/[^0-9]/g, "");
                setIntialTime(num);
                setSelected(item);
                primaryClose();
              }}
            >
              {item}
            </button>
          ))}
          <div className="dates">
            <div className="date_time">
              <p className="time-label">From</p>
              <input
                id="startDate"
                type="date"
                className=""
                style={{ fontWeight: "bold" }}
                value={startDate}
                onChange={(e) => setstartDate(e.target.value)}
                max={maxDate}
                min={minDate}
              />
            </div>

            <div className="date_time" style={{ marginTop: "0.5vw" }}>
              <p className="time-label">To</p>
              <input
                id="startDate"
                type="date"
                className=""
                style={{ fontWeight: "bold" }}
                value={endtDate}
                onChange={(e) => setendtDate(e.target.value)}
                max={maxDate}
                min={startDate}
              />
            </div>
            <Button
              name={"Apply"}
              type="gradient"
              onClick={() => {
                handleValue(formatDate(startDate), formatDate(endtDate));
                let str = `${formatDate(startDate)}  ~   ${formatDate(
                  endtDate
                )}`;
                setSelected(str);
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
}

const dateIcon = () => (
  <svg
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
    className="dateIcon"
  >
    <defs>
      <style>
        {".cls-1{fill:var(--primaryInvert);}.cls-2{fill:var(--primaryInvert);}"}
      </style>
    </defs>
    <g data-name="5. Calendar" id="_5._Calendar">
      <path
        className="cls-1"
        d="M31,9V5a3,3,0,0,0-3-3H24V1a1,1,0,0,0-2,0V2H17V1a1,1,0,0,0-2,0V2H10V1A1,1,0,0,0,8,1V5a1,1,0,0,0,2,0V4h5V5a1,1,0,0,0,2,0V4h5V5a1,1,0,0,0,2,0V4h4a1,1,0,0,1,1,1V8H3V5A1,1,0,0,1,4,4H5A1,1,0,0,0,5,2H4A3,3,0,0,0,1,5V29a3,3,0,0,0,3,3H28a3,3,0,0,0,3-3V13a1,1,0,0,0-2,0V29a1,1,0,0,1-1,1H4a1,1,0,0,1-1-1V10H30A1,1,0,0,0,31,9Z"
      />
      <path
        className="cls-1"
        d="M11,15a2,2,0,0,0-2-2H7a2,2,0,0,0-2,2v2a2,2,0,0,0,2,2H9a2,2,0,0,0,2-2ZM9,17H7V15H9Z"
      />
      <path
        className="cls-1"
        d="M15,13a2,2,0,0,0-2,2v2a2,2,0,0,0,2,2h2a2,2,0,0,0,2-2V15a2,2,0,0,0-2-2Zm2,4H15V15h2Z"
      />
      <path
        className="cls-1"
        d="M27,15a2,2,0,0,0-2-2H23a2,2,0,0,0-2,2v2a2,2,0,0,0,2,2h2a2,2,0,0,0,2-2Zm-2,2H23V15h2Z"
      />
      <path
        className="cls-1"
        d="M9,21H7a2,2,0,0,0-2,2v2a2,2,0,0,0,2,2H9a2,2,0,0,0,2-2V23A2,2,0,0,0,9,21Zm0,4H7V23H9Z"
      />
      <path
        className="cls-1"
        d="M25,21H23a2,2,0,0,0-2,2v2a2,2,0,0,0,2,2h2a2,2,0,0,0,2-2V23A2,2,0,0,0,25,21Zm0,4H23V23h2Z"
      />
      <path
        className="cls-2"
        d="M17.414,24l1.293-1.293a1,1,0,0,0-1.414-1.414L16,22.586l-1.293-1.293a1,1,0,0,0-1.414,1.414L14.586,24l-1.293,1.293a1,1,0,1,0,1.414,1.414L16,25.414l1.293,1.293a1,1,0,0,0,1.414-1.414Z"
      />
    </g>
  </svg>
);
