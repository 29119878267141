import React, { useEffect, useState } from "react";
import "./Selector.scss";

export default function Selector({
  selector_name = ["Usecase", "AI Model", "IOT Firmware"],
  setoption,
}) {
  // const [isActive, setIsActive] = useState([
  //   ...selector_name.map((x, i) => (i === 0 ? true : false)),
  // ]);
  const [isActive, setIsActive] = useState([
    ...selector_name.map((x, i) => (i === 0 ? true : false)),
  ]);

  // "Usecase" : "usecase",
  // "AI Model": "ai",
  // "IOT Firmware": "firmware"
  
  const handleClick = (i) => {
    let _arr = [...isActive];

    let finalarr = _arr.map((element, idx) =>
      idx === i ? (_arr[i] = true) : (_arr[i] = false)
    );
    setIsActive(finalarr);
    {
      setoption(selector_name[i]);
    }
  };

  useEffect(() => {
    setoption(selector_name[0]);
  }, []);

  return (
    <div className="selector_mainwrapper">
      {selector_name.map((x, i) => (
        <div className="selector_wrapper">
          <div
            style={{
              backgroundColor: isActive[i] ? "#013AA2    " : "white",
              color: isActive[i] ? "white" : "#013AA2",
            }}
            className="selector_option"
            onClick={() => {
              handleClick(i);
            }}
          ></div>
          <div>{x}</div>
        </div>
      ))}
    </div>
  );
}
