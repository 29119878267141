import React, { useContext } from "react";
import Button from "../../../../components/Button/Button";
import Toggle from "../../../../components/Toggle/Toggle";
import { ThemeContext } from "../../../../helper/context";

export default function AppLicenseList({
  name,
  toggleValue,
  toggleId,
  handleToggleValue,
  Role,
  editMarkup,
  editLicense,
  editRestrcitUsers,
  assignMfg,
  assignSpecailMfg,
  LicenseType,
  count,
  assignDevice
}) {
  const themeContext = useContext(ThemeContext);

  return (
    <div className="apps_license_list_wrapper">
      <div className="license_name_div __flex___">
        <p className="__license_name__">License Name</p>
        <p className="__license_name_para__" title={name}>
          {name}
        </p>
      </div>
      {/* {LicenseType === "Special License" &&
        themeContext.Role.current === "manufacturer" &&
        !Role.includes("superadmin") && (
          <div className="license_name_div __flex___">
            <p className="__license_name__">License Count</p>
            <p className="__license_name_para__" title={count}>
              {count}
            </p>
          </div>
        )} */}

      {/* {(LicenseType === "Special License" &&
            themeContext.Role.current === "manufacturer" &&
            !Role.includes("user")) &&
        <div style={{ gap: "0.5vw" }} className="license_status_div __flex___">
        </div>
      } */}

      { LicenseType === "Standard License"  &&  <div style={{ gap: "0.5vw" }} className="license_status_div __flex___">
          <p className="__license_name__">Status</p>
          <div className="license_toggle">
            <Toggle
              value={toggleValue}
              id={toggleId}
              handleValue={handleToggleValue}
            />
          </div>
        </div>}

      <div className="edit_restricted_div flex_end">
        {LicenseType !== "Special License" && (
          <button onClick={editRestrcitUsers} className="restrict_users_btn">
            {restrictIcon()} Edit Restricted Users
          </button>
        )}
      </div>
      {LicenseType !== "Special License" && (
        <div className="view_pricing_div flex_end">
          <Button
            style={{
              borderRadius: "3.5px",
              padding: "0.2vw",
              color: "var(--primary)",
              fontSize: "0.8333333333vw",
              backgroundColor: "#fff",
              fontWeight: "500",
              border: "1px solid var(--primary)",
              width: "10vw",
            }}
            name={Role.includes("superadmin") ? "Edit Pricing" : "Edit Markup"}
            onClick={Role.includes("superadmin") ? editLicense : editMarkup}
          />
        </div>
      )}
      {themeContext.Role.current === "manufacturer" && (
        <div className="assign_license_div flex_end">
          <Button
            style={{
              borderRadius: "3.5px",
              padding: "0.2vw",
              color: "var(--primary)",
              fontSize: "0.8333333333vw",
              backgroundColor: "#fff",
              fontWeight: "500",
              border: "1px solid var(--primary)",
              minWidth: "10vw",
            }}
            onClick={
              LicenseType === "Standard License" ? assignMfg : assignSpecailMfg
            }
            name={(LicenseType === "Special License" &&
            themeContext.Role.current === "manufacturer" &&
            !Role.includes("superadmin")) ? "Assign to manufacturer" : "Assign "}
          />
        </div>
      )}
        {/* {(LicenseType === "Special License" &&
            themeContext.Role.current === "manufacturer" &&
            !Role.includes("superadmin"))  && (
        <div className="assign_license_div flex_end">
          <Button
            style={{
              borderRadius: "3.5px",
              padding: "0.2vw",
              color: "var(--primary)",
              fontSize: "0.8333333333vw",
              backgroundColor: "#fff",
              fontWeight: "500",
              border: "1px solid var(--primary)",
              width: "10vw",
            }}
            onClick={
              assignDevice
            }
            name={ "Assign to device"}
          />
        </div>
      )} */}
    </div>
  );
}

const restrictIcon = () => {
  return (
    <svg
      className="resitrct_icon"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      fill="#464646"
      style={{ width: "0.6vw", height: "0.6vw" }}
    >
      <path d="M367.2 412.5L99.5 144.8C77.1 176.1 64 214.5 64 256c0 106 86 192 192 192c41.5 0 79.9-13.1 111.2-35.5zm45.3-45.3C434.9 335.9 448 297.5 448 256c0-106-86-192-192-192c-41.5 0-79.9 13.1-111.2 35.5L412.5 367.2zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256z" />
    </svg>
  );
};
