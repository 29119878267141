import { useState } from "react";
import { FaStar } from "react-icons/fa";

const StarRating = (props) => {
  const { total, selected } = props;
  const [current, setCurrent] = useState(selected);
  const [hover, setHover] = useState(null);

  const addRating = (index) => {
    setCurrent(index);
  };

  return (
    <div>
      {[...Array(total)].map((_star, index) => (
        <FaStar
          key={index}
          className="star"
          size={12}
          color={
            index + 1 <= (hover || isNaN(current) ? selected : current)
              ? "#043DA8"
              : "whitesmoke"
              // : "#848484"

          }
        />
      ))}
    </div>
  );
};

export default StarRating;
