import React from "react";
import Button from "../../../components/Button/Button";
import MultiSelectDropdown from "../../../components/MultiSelectDropdown/MultiSelectDropdown";
import Pagination from "../../../components/Pagination/Pagination";
import Table from "../../../components/Table/Table";

export default function DeployedUsecase() {
  const header = [
    "Use-Case Name",
    "Purchased date and time",
    "Status",
    "Use-Case Info",
  ];
  const renderTableData = () => {
    return _data.map((item, idx) => (
      <tr>
        <td>
          <div className="usernameImage">
            <img
              alt="username"
              className="user_avatar"
              src={
                "https://source.unsplash.com/random/200x200?sig=" +
                Number(idx + 1)
              }
            />
            <p
              style={{
                color: "var(--label)",
                textDecoration: "underline",
              }}
            >
              {item.UserName}
            </p>
          </div>
        </td>
        <td>{item.Email}</td>
        <td>
          <div className="usertype">
            {item.UserType === "Active" ? activeIcon() : suspendedIcon()}
            <p className="type">{item.UserType}</p>
          </div>
        </td>
        <td>Info</td>
      </tr>
    ));
  };
  return (
    <div className="profile_right __userinfo__ __deployeduc fadeIn">
      <Table
        className="adjust_table"
        header={header}
        tableData={renderTableData}
      />
      <div className="userinfo_bottom">
        <div />
        <Pagination
          // style={{ position: "absolute", bottom: "1vw" }}
          totPages={100}
          currentPage={1}
          rowCount={10}
          // totPages={20}
          // currentPage={1}

          pageClicked={(ele, count_) => {
            console.log(ele, count_);
            // isAllSelected = false;
            // setselectedRow([]);
            // setCurrentPage(ele);
            // if (type === "all") {
            //   getUnfilteredData(ele, count_);
            // }
            // if (type === "filter" || type === "search") {
            //   postFilter(ele, count_);
            // }
          }}
          handleSelect={(curr, ele) => {
            console.log(curr, ele);
            // isAllSelected = false;
            // setselectedRow([]);
            // setPerPage(ele);
            // setCurrentPage(1);
            // if (type === "all") {
            //   getUnfilteredData(1, ele);
            // }
            // if (type === "filter" || type === "search") {
            //   postFilter(1, ele);
            // }
          }}
        />
      </div>
    </div>
  );
}

const _data = [
  {
    UserName: "vpetruk0",
    Email: "kantonio0@imageshack.us",
    UserType: "Suspended",
    Action: null,
  },
  {
    UserName: "ewilfinger1",
    Email: "fmanifield1@cmu.edu",
    UserType: "Active",
    Action: null,
  },
  {
    UserName: "tphillot2",
    Email: "cmolineaux2@tmall.com",
    UserType: "Suspended",
    Action: null,
  },
  {
    UserName: "jsidebotham3",
    Email: "rzamora3@addtoany.com",
    UserType: "Active",
    Action: null,
  },
  {
    UserName: "smaccaffery4",
    Email: "ahaighton4@webnode.com",
    UserType: "Active",
    Action: null,
  },
  {
    UserName: "cgreggersen5",
    Email: "hpurdom5@wikia.com",
    UserType: "Suspended",
    Action: null,
  },
  {
    UserName: "kpilcher6",
    Email: "ojiru6@samsung.com",
    UserType: "Active",
    Action: null,
  },
  {
    UserName: "jdee7",
    Email: "schipperfield7@howstuffworks.com",
    UserType: "Active",
    Action: null,
  },
  {
    UserName: "jscally8",
    Email: "jfurlong8@flickr.com",
    UserType: "Suspended",
    Action: null,
  },
  {
    UserName: "cbendall9",
    Email: "ogrishunin9@behance.net",
    UserType: "Suspended",
    Action: null,
  },
  {
    UserName: "mgoghina",
    Email: "srichardeaua@indiatimes.com",
    UserType: "Active",
    Action: null,
  },
  {
    UserName: "aashb",
    Email: "tsealoveb@about.com",
    UserType: "Suspended",
    Action: null,
  },
  {
    UserName: "ndaniellic",
    Email: "ajirusc@unc.edu",
    UserType: "Suspended",
    Action: null,
  },
  {
    UserName: "rtrevilliond",
    Email: "btiversd@imgur.com",
    UserType: "Active",
    Action: null,
  },
  {
    UserName: "msainese",
    Email: "rjorczyke@edublogs.org",
    UserType: "VP Sales",
    Action: null,
  },
  {
    UserName: "wfreundf",
    Email: "rteesf@t-online.de",
    UserType: "Active",
    Action: null,
  },
  {
    UserName: "dhanrottg",
    Email: "lbrodeurg@webs.com",
    UserType: "Suspended",
    Action: null,
  },
  {
    UserName: "emacelhargeh",
    Email: "cmcbayh@loc.gov",
    UserType: "Active",
    Action: null,
  },
  {
    UserName: "kfewlessi",
    Email: "sneeshami@google.co.jp",
    UserType: "Active",
    Action: null,
  },
  {
    UserName: "jpassiej",
    Email: "vjoirisj@abc.net.au",
    UserType: "Suspended",
    Action: null,
  },
  {
    UserName: "bsugark",
    Email: "mbrailsfordk@cpanel.net",
    UserType: "Suspended",
    Action: null,
  },
  {
    UserName: "ndevoyl",
    Email: "bblackhalll@parallels.com",
    UserType: "Active",
    Action: null,
  },
  {
    UserName: "aharbertm",
    Email: "jdonohoem@nbcnews.com",
    UserType: "Active",
    Action: null,
  },
  {
    UserName: "sangrockn",
    Email: "crivaln@wikia.com",
    UserType: "Active",
    Action: null,
  },
  {
    UserName: "bmanthorpeo",
    Email: "dclemenso@springer.com",
    UserType: "Suspended",
    Action: null,
  },
];

export const suspendedIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={30}
    height={30}
    viewBox="0 0 30 30"

    // style={{
    //   width: "1.6625vw",
    //   height: "1.6625vw",
    //   zIndex: 1,
    // }}
  >
    <g
      id="Group_15107"
      data-name="Group 15107"
      transform="translate(-1338.925 -493.75)"
    >
      <circle
        id="Ellipse_18446"
        data-name="Ellipse 18446"
        cx="15"
        cy="15"
        r="15"
        transform="translate(1338.925 493.75)"
        fill="#d10f0f"
      />
      <path
        id="cancel_FILL0_wght400_GRAD0_opsz48"
        d="M9.59,17.94a.708.708,0,0,0,1.025,0l3.149-3.149,3.174,3.174a.639.639,0,0,0,.5.183.721.721,0,0,0,.5-.208.708.708,0,0,0,0-1.025L14.79,13.765l3.174-3.174a.639.639,0,0,0,.183-.5.721.721,0,0,0-.208-.5.708.708,0,0,0-1.025,0L13.765,12.74,10.591,9.566a.639.639,0,0,0-.5-.183.721.721,0,0,0-.5.208.708.708,0,0,0,0,1.025l3.149,3.149L9.566,16.939a.639.639,0,0,0-.183.5A.721.721,0,0,0,9.59,17.94Zm4.175,5.59a9.9,9.9,0,0,1-3.857-.745,9.451,9.451,0,0,1-5.163-5.163A9.9,9.9,0,0,1,4,13.765a9.841,9.841,0,0,1,.745-3.833,9.53,9.53,0,0,1,2.063-3.1A9.735,9.735,0,0,1,13.765,4a9.687,9.687,0,0,1,9.765,9.765A9.735,9.735,0,0,1,20.7,20.723a9.53,9.53,0,0,1-3.1,2.063A9.841,9.841,0,0,1,13.765,23.53ZM13.765,13.765Zm0,8.3a7.991,7.991,0,0,0,5.859-2.441,7.991,7.991,0,0,0,2.441-5.859,7.991,7.991,0,0,0-2.441-5.859,7.991,7.991,0,0,0-5.859-2.441A7.991,7.991,0,0,0,7.906,7.906a7.991,7.991,0,0,0-2.441,5.859,7.991,7.991,0,0,0,2.441,5.859A7.991,7.991,0,0,0,13.765,22.065Z"
        transform="translate(1340.16 494.985)"
        fill="#fff"
      />
    </g>
  </svg>
);

export const activeIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={30}
    height={30}
    viewBox="0 0 30 30"
    className="activeIcon"
    // style={{
    //   width: "1.6625vw",
    //   height: "1.6625vw",
    //   zIndex: 1,
    // }}
  >
    <defs>
      <linearGradient
        id="activeIcon"
        x1={0.108}
        x2={0.802}
        y2={1}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#2ff263" />
        <stop offset={1} stopColor="#06a201" />
      </linearGradient>
    </defs>
    <g
      id="Group_15108"
      data-name="Group 15108"
      transform="translate(-1339.249 -568.5)"
    >
      <circle
        id="Ellipse_18445"
        data-name="Ellipse 18445"
        cx={15}
        cy={15}
        r={15}
        transform="translate(1339.249 568.5)"
        fill="url(#activeIcon)"
      />
      <path
        id="check_circle_FILL0_wght400_GRAD0_opsz48"
        d="M12.324,15.985,9.931,13.593a.73.73,0,0,0-.537-.22.835.835,0,0,0-.806.806.7.7,0,0,0,.244.537l2.978,2.978a.708.708,0,0,0,1.025,0l5.858-5.858A.69.69,0,0,0,18.9,11.3a.775.775,0,0,0-.232-.537.708.708,0,0,0-.549-.244.785.785,0,0,0-.574.244Zm1.44,7.542a9.9,9.9,0,0,1-3.857-.744A9.45,9.45,0,0,1,4.744,17.62,9.9,9.9,0,0,1,4,13.764a9.84,9.84,0,0,1,.744-3.832,9.529,9.529,0,0,1,2.063-3.1A9.734,9.734,0,0,1,13.764,4a9.686,9.686,0,0,1,9.764,9.764A9.734,9.734,0,0,1,20.7,20.72a9.529,9.529,0,0,1-3.1,2.063A9.84,9.84,0,0,1,13.764,23.527ZM13.764,13.764Zm0,8.3a8.131,8.131,0,0,0,8.3-8.3,8.131,8.131,0,0,0-8.3-8.3,8.131,8.131,0,0,0-8.3,8.3,8.131,8.131,0,0,0,8.3,8.3Z"
        transform="translate(1340.619 569.5)"
        fill="#fff"
      />
    </g>
  </svg>
);
