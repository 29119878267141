import React, { useEffect } from "react";
import { useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import InputBox from "../../../components/Inputbox/InputBox";
import { axiosApiInstance } from "../../../helper/request";
import copy from "copy-to-clipboard";
import { getDateTime, timeAgo } from "../../../helper/storage";
import ReactTooltip from "react-tooltip";
import Scroller from "../../../components/Scroller/ScrollBar";

export default function Details({ id, history }) {
  const [License, setLicense] = useState(null);
  const [Serial, setSerial] = useState(null);
  const [InstallationData, setInstallationData] = useState(null);
  const [LicenseDetails, setLicenseDetails] = useState({
    camera_limit: null,
    usecase_limit: null,
    activated_on: null,
    expiry: null,
  });
  const [Location, setLocation] = useState({
    country: null,
    state: null,
    city: null,
    area: null,
  });
  const setData = (id, data) => {
    document.getElementById(id).innerHTML = data;
    document.getElementById(id).title = data;
  };

  const convertPlanToString = () => {
    let plan = "";

    Object.keys(License.license_details).map((element) => {
      plan += element + " : " + License.license_details[element] + ", ";
    });
    plan = plan.slice(0, -2);
    return plan;
  };

  useEffect(() => {
    const getDeviceDetail = () => {
      axiosApiInstance
        .get("device/fetch", {
          headers: {
            "serial-number": id,
          },
        })
        .then((res) => {
          let _license = res.data.license;
          setLicense(_license);
          let {
            device_name,
            serial_number,
            manufacturer_username,
            software_version,
            created_on,
            metadata,
          } = res.data;
          setInstallationData(created_on);

          setData("Device_Name", device_name);
          // setData("Serial_Number", serial_number);
          setData("Manufacturer", manufacturer_username);
          setData(
            "RDX_Version",
            res.data.configurations.software_version
              ? res.data.configurations.software_version
              : "NA"
          );
          // setData("Installation_Date_&_Time", created_on);
          setData(
            "Base_OS",
            res.data.configurations.os ? res.data.configurations.os : "NA"
          );
          setData(
            "Local_IP",
            res.data.configurations.local_ip
              ? res.data.configurations.local_ip
              : "NA"
          );
          setData(
            "RAM",
            res.data.configurations.ram ? res.data.configurations.ram : "NA"
          );
          setData(
            "Processor",
            res.data.configurations.processor
              ? res.data.configurations.processor
              : "NA"
          );
          setData(
            "GPU",
            res.data.configurations.gpu ? res.data.configurations.gpu : "NA"
          );
          if (_license) {
            setLicenseDetails({
              camera_limit: _license.camera_limit,
              usecase_limit: _license.usecase_limit,
              activated_on: _license.activated_on,
              expiry: _license.expiry,
            });
          }
          if (metadata?.country) {
            setLocation({
              country: metadata?.country,
              state: metadata?.state,
              city: metadata?.city,
              area: metadata?.area,
            });
          }
          setSerial(serial_number);
        })
        .catch((err) => {
          history.goBack();
        });
    };
    getDeviceDetail();
  }, []);

  return (
    <div className="profile_right __details__ fadeIn">
      <Scrollbars
        autoHeight
        autoHeightMax="68vh"
        renderThumbHorizontal={({ style, ...props }) => {
          const thumbStyle = {
            borderRadius: 6,
            backgroundColor: "rgba(35, 49, 86, 0.8)",
          };
          return <div style={{ ...style, ...thumbStyle }} {...props} />;
        }}
        renderThumbVertical={({ style, ...props }) => {
          const thumbStyle = {
            borderRadius: 6,
            backgroundColor: "rgba(0, 0, 0, 0.2)",
            width: "3px",
          };
          return <div style={{ ...style, ...thumbStyle }} {...props} />;
        }}
      >
        <div style={{ paddingRight: "1vw", height: "63vh" }}>
          <h2 className="device_detail_title">Details</h2>
          <div className="device_detail">
            <DeviceTile name="Device Name" value="Andheri" />
            <div style={{ position: "relative" }}>
              <DeviceTile name="Serial Number" value={Serial} />
              {copyIcon(() => {
                copy(Serial);
              })}
            </div>
            <DeviceTile
              name="Manufacturer"
              underline
              // value="ManufacturerName"
            />
            <DeviceTile name="RDX Version" value="Version_1.2" />
            <DeviceTile
              name="Installation Date & Time"
              // value="July 21, 2022 05:45AM"
              value={getDateTime(InstallationData)}
            />
            {Location?.country && (
              <React.Fragment>
                <DeviceTile name="Country" value={Location?.country} />
                <DeviceTile name="State" value={Location?.state} />
                <DeviceTile name="City" value={Location?.city} />
                <DeviceTile
                  name="Area"
                  underline
                  value={Location.area ? Location.area : "NA"}
                />
              </React.Fragment>
            )}
          </div>
          <h2 className="device_detail_title">Hardware Details</h2>
          <div className="device_detail2">
            <DeviceTile name="Base OS" value="Ubuntu" />
            <DeviceTile name="Local IP" value="192.168.1.123" />
            <DeviceTile name="RAM" value="16GB" />
            <DeviceTile name="Processor" value="Intel i5" />
            <DeviceTile name="GPU" value="RTX 3060 Ti" />
          </div>
          {License && (
            <React.Fragment>
              <h2 className="device_detail_title">License Details</h2>
              <div
                className="device_detail_adjust"
                style={{ borderBottom: 0, display: "flex" }}
              >
                {/* <DeviceTile
                  name="Camera Limits"
                  value={LicenseDetails.camera_limit}
                />
                <DeviceTile
                  name="Usecase Limits"
                  value={LicenseDetails.usecase_limit}
                /> */}

                <DeviceTile
                  name="License Activation Date & Time"
                  value={getDateTime(LicenseDetails.activated_on)}
                />
                <DeviceTile
                  name="License Expiry Date & Time"
                  value={getDateTime(LicenseDetails.expiry)}
                />
                <div>
                  <div className="device_details_license_header">
                    <p className="label">Plan : </p>
                    <p className="label">{License.license_duration}</p>
                  </div>
                  <Scroller
                    autoHeightMax="6vh"
                    verticalStyle={{
                      width: "0.12vw",
                      marginLeft: "0.3vw",
                      backgroundColor: "#e7e7e7",
                    }}
                  >
                    <table className="device_details_license_table">
                      <tr className="device_details_license_tr">
                        {Object.keys(License?.license_details).map((item) => (
                          <tb>{item}</tb>
                        ))}
                      </tr>
                      <tr className="device_details_license_tr">
                        {Object.values(License?.license_details).map((item) => (
                          <tb>{item}</tb>
                        ))}
                      </tr>
                    </table>
                  </Scroller>
                </div>
                {/* <DeviceTile
                  name="Plan"
                  value={
                    <div
                      style={{
                        textTransform: "capitalize",
                      }}
                    >
                      <p>{License.license_duration} </p>
                      <div
                        style={{
                          display: "flex",
                          fontSize: "0.78vw",
                        }}
                      >
                        <div>
                          <div
                            className="license_plan"
                            style={{
                              display: "flex",
                            }}
                            title=""
                          >
                            <p
                              style={{
                                whiteSpace: "nowrap",
                                width: "150px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                              id="my-element"
                              data-tip
                              data-for="registerTip"
                            >
                              {convertPlanToString()}
                            </p>

                            <ReactTooltip
                              id="registerTip"
                              place="top"
                              effect="solid"
                            >
                              {convertPlanToString()}
                            </ReactTooltip>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                /> */}
              </div>
            </React.Fragment>
          )}
        </div>
        {/* <div className="register_data ">
        <div className="left-section">
          <InputBox id="RAM" header="RAM" value={FullName} />
          <InputBox id="GPU" header="GPU" value={FullName} />
          <InputBox id="Storage" header="Storage" value={FullName} />
          <InputBox id="OSBuild" header="OS Build" value={FullName} />
        </div>
        <div className="right-section">
          <InputBox id="username" header="CPU" value={Username} />
          <InputBox id="memory" header="Memory" value={Mobile} />
          <InputBox id="processor" header="Processor" value={Mobile} />
          <InputBox id="email" header="Lorem" value={Mobile} />
        </div>
      </div> */}
      </Scrollbars>
    </div>
  );
}

const DeviceTile = ({ name, value, underline, onClick }) => (
  <div className="device_tile">
    <p className="label">{name}</p>
    <p
      className="value"
      style={{
        textDecoration: underline ? "underline" : null,
        cursor: underline ? "pointer" : "default",
        color: underline ? "var(--label)" : null,
      }}
      id={name.replace(/ /g, "_")}
      title={value}
    >
      {value}
    </p>
  </div>
);

const copyIcon = (onClick) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={11.156}
    height={13.125}
    viewBox="0 0 11.156 13.125"
    className="copyIcon"
    onClick={onClick}
  >
    <path
      id="content_copy_FILL0_wght400_GRAD0_opsz48"
      d="M8.953,15.106a.98.98,0,0,1-.984-.984V4.934a.98.98,0,0,1,.984-.984h7.219a.98.98,0,0,1,.984.984v9.187a.98.98,0,0,1-.984.984Zm0-.984h7.219V4.934H8.953ZM6.984,17.075A.98.98,0,0,1,6,16.09V6.69a.492.492,0,1,1,.984,0v9.4h7.284a.492.492,0,1,1,0,.984ZM8.953,4.934v0Z"
      transform="translate(-6 -3.95)"
    />
  </svg>
);
