import React, { useEffect, useState, version } from "react";
import Navbar from "../../../components/Navbar/Navbar";
import "./AddedVersion.scss";
import bg from "../../../assets/images/background.png";
import Searchbar from "../../../components/Searchbar/Searchbar";
import Loading from "../../../components/Loading/Loading";
import Scroller from "../../../components/Scroller/ScrollBar";
import {
  closeIcon,
  dots,
  doubleTick,
  infoIcon,
  infoIcon2,
  transparentInfoIcon,
  trash,
} from "../../../../src/helper/icons";
import { API_URL, OTP_TIME, axiosApiInstance } from "../../../helper/request";
import { useQuery } from "../../../helper/useQuery";
import { useHistory, useLocation, useParams } from "react-router-dom";
import noversion from "../../../assets/images/noversion.png";
import { useDebouncedEffect } from "../../../helper/useDebounce";
import AnimatedModal from "../../../components/Modal/AnimatedModal";
import { useRef } from "react";
import { ThemeContext } from "../../../helper/context";
import { useContext } from "react";
import Button from "../../../components/Button/Button";
import { encryptStorage } from "../../../helper/storage";
import useClickOutside from "../../../helper/useClickOutside";
import { useCallback } from "react";
import SelectLicense from "../../shared/Device/SelectLicense";
import AppVersionTable from "./Component/AppVersion/AppVersionTable/AppVersionTable";
import UpgradeLicense2 from "../../shared/Device/UpgradeLicense2";
import OTP from "../../../components/OTP/OTP";
import axios from "axios";
import UserLoginModal from "../../shared/Device/UserLoginModal";
let Role = [];
let _mapping_id = null;
let resetCounter = null;
let _license_name = null;
let changePage = 1;
let selectedDevice = {
  licence: false,
  manu_key: null,
};
let deviceName = "";
let manufacturerName = "";
let flag = false;
let msg = {
  type: "alert",
  header: "Error",
  errorText: "Server Error",
};
let AppId = "";
let current_version_name = "";
let ids = {};

export default function AddedVersion(props) {
  const location = useLocation();
  const themeContext = useContext(ThemeContext);
  const query = useQuery();
  const history = useHistory();
  let app_name = query.get("app_name") || "";
  let serialNumber = query.get("serial_number") || "";
  const [appThumbnail, setAppThumbnail] = useState(null);
  const apptype = {
    logic: "logic",
    processing: "processing",
    action: "action",
  };

  const [appType, setAppType] = useState(null);
  const [ShowOTP, setShowOTP] = useState(false);
  const [OTPCloseConfirm, setOTPCloseConfirm] = useState(false);
  const [Stop, setStop] = useState(false);
  const [showUserLogin, setshowUserLogin] = useState(false);


  const [searchVersionName, setSearchVersionName] = useState(undefined);

  const [loadingScreen, setLoadingScreen] = useState(false);

  const [addedVersionDetails, setAddedVersionDetails] = useState([]);

  const [openInfo, setOpenInfo] = useState(false);

  const [showAlert, setShowAlert] = useState(false);

  const [totalCount, setTotalCount] = useState();

  const [items, setItems] = useState(8);

  const [approveVersion, setApproveVersion] = useState(false);

  const [rejecVersion, setRejectVersion] = useState(false);

  const [showApprovalWrapper, setShowApprovalWrapper] = useState(true);

  const [openAddLicense, setOpenAddLicense] = useState(false);

  const [standardLicenses, setStandardLicenses] = useState([]);
  const [SelectedLicenseDetails, setSelectedLicenseDetails] = useState([]);
  const [specialLicenses, setSpecialLicense] = useState([]);
  const [specialLicenseTotalCount, setSpecialLicenseTotalCount] = useState([]);
  const [changeLicensePage, setchangeLicensePage] = useState(1);
  const [selectCard, setSelectCard] = useState(false);
  const [RenewLicence, setRenewLicence] = useState(false);
  const [clickedAppId, setClickedAppId] = useState("");
  const queryy = useQuery();
  let serial_number = queryy.get("serial_number");
  const scrollbar = useRef(null);
  const getStandardLicenses = (name) => {
    setLoadingScreen(true);
    axiosApiInstance
      .get(
        `license/fetch/manufacturer/licenses?license_type=app&manufacturer_username=admin&app_name=${app_name}`
      )
      .then((res) => {
        setStandardLicenses([...res.data.details]);
      })
      .catch((err) => {})
      .finally(() => {
        setLoadingScreen(false);
      });
  };
  const getSpecialLicenses = (changePage = 1) => {
    setLoadingScreen(true);
    axiosApiInstance
      .get(
        `inventory/role?license_type=app&app_name=${app_name}&page=${changePage}&items=10`
      )
      .then((res) => {
        let temp = [];
        // setFlag(false);
        // temp = res.data.details;
        // setLoadingScreen(false);
        // setSpecialLicense((prevState) => {
        //   return changePage === 1 ? [...temp] : [...prevState, ...temp];
        // });
        setSpecialLicense(res.data.details);
        setSpecialLicenseTotalCount(res.data.total_count);
        // scrollbar?.current?.scrollTop(20);
      })
      .catch((err) => {})
      .finally(() => {
        setLoadingScreen(false);
      });
  };
  const addCountKey = (index) => {
    let newArr = [...standardLicenses[index].pricing_details];
    // newArr.splice(0,1)
    for (let i = 0; i < newArr.length; i++) {
      let _keys = Object.keys(newArr[i]);
      for (let j = 0; j < _keys.length; j++) {
        if (_keys[j] != "attribute_name") {
          newArr[i][_keys[j]].count = 1;
        }
      }
    }
    _mapping_id = standardLicenses[index].manufacturer_mapping_id;
    setSelectedLicenseDetails([...newArr]);
  };

  const postOtp = (version_name) => {
    current_version_name = version_name;
    let lData = encryptStorage.getItem("UID");
    let obj = {
      username: lData.username,
    };
    setLoadingScreen(true);
    axiosApiInstance
      .get("user/profile/data")
      .then((res) => {
        obj["email"] = res.data.email;
        axios
          .post(API_URL + "user/otp/send", obj)
          .then((res) => {
            setTimeout(() => {}, 500);
            encryptStorage.setItem("VID", {
              counter: OTP_TIME,
              username: obj.username,
              destination: obj.email,
            });
            setShowOTP(true);
          })
          .catch((err) => {
            console.log(err.response.status);
            if (err.response.status === 401) {
              msg.errorText = "Too many OTP Attempts";
              msg.header = "Error";
              msg.type = "alert";
              setShowAlert(true);
            } else {
              msg.errorText = "Something went wrong!";
              msg.header = "Error";
              msg.type = "alert";
              setShowAlert(true);
            }
          })
          .finally(() => {
            setLoadingScreen(false);
          });
      })
      .catch((err) => {})
      .finally(() => {
        setLoadingScreen(false);
      });
  };

  const deleteAppVersion = () => {
    axiosApiInstance
      .delete(
        "apps/versions?app_name=" +
          app_name +
          "&version_name=" +
          current_version_name
      )
      .then((res) => {
        msg.errorText = "Version deleted successfully";
        msg.header = "Success";
        msg.type = "success";
        setShowAlert(true);
        setTimeout(() => {
          fetchAddedVersion(changePage);
          setShowAlert(false);
        }, 2000);
      })
      .catch((err) => {
        console.log(err);
        msg.errorText = "Something went wrong";
        msg.header = "Error";
        msg.type = "alert";
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 3000);
      });
  };

  const postVerifyOTP = (otp_) => {
    let lData = encryptStorage.getItem("VID");
    let obj = {
      username: lData.username,
      email: lData.destination,
      otp: otp_,
    };
    setLoadingScreen(true);
    axios
      .post(API_URL + "user/otp/verify", obj)
      .then((res) => {
        msg.errorText = "Successfully verified OTP";
        msg.header = "Success";
        msg.type = "success";
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 3000);
        deleteAppVersion();
        encryptStorage.removeItem("VID");
        setShowOTP(false);
      })
      .catch((err) => {
        msg.errorText = "Invalid OTP. Please Try Again!";
        msg.header = "Error";
        msg.type = "alert";
        setLoadingScreen(false);
        // setShowOTP(false);
        setShowAlert(true);
      })
      .finally(() => {
        setLoadingScreen(false);
      });
  };

  const postResendOTP = () => {
    let _lData = encryptStorage.getItem("VID");

    let obj = {
      username: _lData.username,
      email: _lData.destination,
    };
    setLoadingScreen(true);
    axiosApiInstance
      .post("user/otp/send", obj)
      .then((res) => {
        msg.errorText = "OTP resent to your Email ID.";
        msg.header = "Success";
        msg.type = "success";
        setShowAlert(true);
        resetCounter();
      })
      .catch((err) => {
        msg.errorText = "Server Error";
        msg.header = "Error";
        msg.type = "alert";
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 3000);
      })
      .finally(() => {
        setLoadingScreen(false);
      });
  };

  useDebouncedEffect(
    () => {
      if (searchVersionName?.length >= 3) {
        searchCheck();
      }
      if (searchVersionName?.length === 0) {
        fetchAddedVersion(1);
      }
    },
    [searchVersionName],
    1000
  );

  const searchCheck = () => {
    let url = `apps/versions?app_name=${app_name}&page=1&items=10`;
    let appVersionQuery = "";
    if (searchVersionName !== undefined && searchVersionName !== "") {
      appVersionQuery = `&version_name=${searchVersionName}`;
    }
    if (appVersionQuery !== "") {
      url += appVersionQuery;
      axiosApiInstance
        .get(API_URL + url)
        .then((res) => {
          let _data = [];
          res.data.detail.details.map((details) => {
            if (deviceName === "") {
              deviceName = res.data.detail.device_name;
            }
            if (manufacturerName === "") {
              manufacturerName = res.data.detail.manufacturer_username;
            }

            _data.push({
              version_name: details.version_name,
              version_type: details.version_type,
              created_date: details.uploaded,
              status: details.status,
            });
          });
          console.log(_data);
          setAddedVersionDetails(_data);
        })

        .finally(() => {
          setLoadingScreen(false);
        });
    }
  };

  const handleScroll = () => {
    if (scrollbar.current.getValues().top >= 0.95 && totalCount > items) {
      if (changePage <= Math.floor(totalCount / items) && !flag) {
        flag = true;
        changePage += 1;
        fetchAddedVersion(changePage);
      }
    } else if (
      scrollbar.current.getValues().top === 0 &&
      changePage > 1 &&
      !flag
    ) {
      changePage -= 1;
      flag = true;
      fetchAddedVersion(changePage);
    }
  };
  const fetchAddedVersion = (value=1) => {
    setLoadingScreen(true);
    axiosApiInstance
      .get(`apps/versions?app_name=${app_name}&page=${value}&items=${items}`, {
        headers: {
          "serial-number": serialNumber,
        },
      })
      .then((res) => {
        flag = false;
        let _data = [];
        console.log(res.data.detail);
        console.log(items);
        if (deviceName === "") {
          deviceName = res.data.detail.device_name;
        }

        if (manufacturerName === "") {
          manufacturerName = res.data.detail.manufacturer_username;
        }

        setTotalCount(res.data.detail.total_count);
        if (res.data.detail.details.length <= items && value > 1) {
          addedVersionDetails.splice(0, res.data.detail.details.length);
          _data = [...addedVersionDetails];
        }
        res.data.detail.details.length === 0
          ? setSearchVersionName(undefined)
          : res.data.detail.details.map((details) => {
              if (AppId === "") {
                AppId = details.app_id;
              }
              if (appThumbnail === null) setAppThumbnail(details.app_thumbnail);
              if (appType === null) setAppType(details.app_type);
              details.version_name !== null &&
                _data.push({
                  version_name: details.version_name,
                  version_type: details.version_type,
                  created_date: details.uploaded,
                  status: details.status,
                  appId: details.app_id,
                  // action: "",
                });
            });
        // let _dataa = [
        //   { name: "subha", number: "123" },
        //   { name: "sneha", number: "456" },
        //   { name: "rupam", number: "7891" },
        //   { name: "sneha", number: "456" },
        // ];
        // let uniqueObject1 = {};
        // let objTitle1 = "";

        // for (let i in _dataa) {
        //   // Extract the title
        //   objTitle1 = _dataa[i]["name"];
        //   console.log(objTitle1, i);
        //   // Use the title as the index
        //   uniqueObject1[objTitle1] = _dataa[i];
        //   console.log("subhaaaaaaaaa", uniqueObject1);
        // }
        // console.log("_datassss", _dataa);

        // console.log("uniqueObject1", uniqueObject1);

        let newArray = [];
        let uniqueObject = {};
        let objTitle = "";

        for (let i in _data) {
          objTitle = _data[i]["version_name"];
          uniqueObject[objTitle] = _data[i];
        }

        for (let i in uniqueObject) {
          newArray.push(uniqueObject[i]);
        }

        // _data.length > 0 && setAddedVersionDetails(newArray);

        setTimeout(() => {
          setLoadingScreen(false);
          _data.length > 0 && setAddedVersionDetails(newArray);

          scrollbar.current.scrollTop(20);
        }, 1000);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  // const deleteVersion = () => {
  //   axiosApiInstance
  //     .delete(
  //       `apps/versions?app_name=${app_name}&version_name=${deleteVersionName}`
  //     )
  //     .then((res) => {
  //       msg.errorText = "Version deleteted successfully";
  //       msg.header = "Success";
  //       msg.type = "success";
  //       setShowAlert(true);
  //       setTimeout(() => {
  //         fetchAddedVersion(changePage);
  //         setShowAlert(false);
  //       }, 2000);
  //     })
  //     .catch((err) => {
  //       msg.errorText = "Something went wrong";
  //       msg.header = "Error";
  //       msg.type = "alert";
  //       setShowAlert(true);
  //       setTimeout(() => {
  //         setShowAlert(false);
  //       }, 3000);
  //     })
  //     .finally(() => {
  //       setLoadingScreen(false);
  //     });
  // };

  useEffect(() => {
    console.log("added version");
    let ldata = encryptStorage.getItem("UID");
    if (!ldata) return;
    fetchAddedVersion(1);
    // return () => {};
  }, []);
  useEffect(() => {
    let ldata = encryptStorage.getItem("UID");
    if (ldata) {
      Role = ldata.roles;
    } else {
      setshowUserLogin(true);
      setLoadingScreen(false);
    }
    // openAddLicense && getStandardLicenses();
    // setSelectCard(true);
  // }, [openAddLicense]);
  }, [openAddLicense]);

  // useEffect(() => {
    
  //   OpenAddLicense && getStandardLicenses();
  //   // getAddLicenseData();
  //   setSelectCard(true);
  // }, [OpenAddLicense]);

  const renderWithSearchBar = () => {
    return (
      <div className="AddedVersion_data_lower_subwrapper">
        <div className="addver_header_wrapper">
          <div className="addver_header_wrapper_lhs">
            <img className="addedver_appimg" src={appThumbnail}></img>
            <h4
              title={app_name.replaceAll("_", " ")}
              className="appver_appname"
            >
              {app_name.replaceAll("_", " ")}
            </h4>
            <div className="addver_apptype">{apptype[appType]}</div>
          </div>
          <div className="searchbar_filterby_wrapper">
            <Searchbar
              placeholder="Search by version name"
              handleSearch={(e) => {
                // changePage=1;

                setSearchVersionName(e.target.value);
              }}
              autoFocus={true}
              value={searchVersionName}
            />
          </div>
        </div>
      </div>
    );
  };
  const renderNoSearchBar = () => {
    return (
      <div className="AddedVersion_data_lower_subwrapper">
        <div className="addver_header_wrapper">
          <div className="addver_header_wrapper_lhs">
            <img className="addedver_appimg" src={appThumbnail}></img>
            <h4
              title={app_name.replaceAll("_", " ")}
              className="appver_appname"
            >
              {app_name.replaceAll("_", " ")}
            </h4>
            <div className="addver_apptype">{apptype[appType]}</div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className="_AddedVersion_wrapper_">
      <Navbar sidenav>
        <div className="AddedVersion_body">
          <img src={bg} className="profile_bg" />
          <div className="body_content fadeIn">
            <h2 className="addedeversion_header">
              {addedVersionDetails.length <= 1
                ? "Added app version"
                : "Added app versions"}
            </h2>
            <p
              className="goBack"
              onClick={() => {
                themeContext.Role.current === "user"
                  ? history.push("/apps/serial/" + query.get("serial_number"))
                  : history.push("/apps");
              }}
            >
              Go Back
            </p>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            ></div>
            <div className="AddedVersion_data">
              <div className="AddedVersion_data_wrapper">
                <div className="AddedVersion_data_subwrapper">
                  <div className="AddedVersion_data_upper_subwrapper"></div>
                  {addedVersionDetails.length === 0 ? (
                    searchVersionName === undefined ? (
                      addedVersionDetails.length === 0 && (
                        <>
                          {renderNoSearchBar()}
                          <p className="error_text">
                            <img
                              style={{ width: "18vw" }}
                              src={noversion}
                            ></img>
                          </p>
                        </>
                      )
                    ) : (
                      <>
                        {renderWithSearchBar()}
                        <p className="error_text">
                          <img style={{ width: "20vw" }} src={noversion}></img>
                        </p>
                      </>
                    )
                  ) : (
                    <>
                      {renderWithSearchBar()}
                      <Scroller
                        autoHeightMax="48vh"
                        verticalStyle={{
                          width: "0.3vw",
                          marginLeft: "0vw",
                          backgroundColor: "#283e92",
                        }}
                        onScroll={handleScroll}
                        ref={scrollbar}
                      >
                        <div className="addver_table_wrapper">
                          {addedVersionDetails.map((item, idx) => {
                            if (
                              !Object.keys(ids).includes("appVersion" + idx)
                            ) {
                              ids["appVersion" + idx] = false;
                            }
                            return (
                              <AppVersionTable
                                app_name={app_name}
                                serialNumber={serialNumber}
                                setShowOTP={setShowOTP}
                                showApprovalWrapper={showApprovalWrapper}
                                setRejectVersion={setRejectVersion}
                                setOpenInfo={setOpenInfo}
                                openInfo={openInfo}
                                setApproveVersion={setApproveVersion}
                                AppId={AppId}
                                setClickedAppId={setClickedAppId}
                                setOpenAddLicense={setOpenAddLicense}
                                appThumbnail={appThumbnail}
                                onDeleteClick={(e, version_name) => {
                                  e.stopPropagation();
                                  postOtp(version_name);
                                }}
                                item={item}
                                ids={ids}
                                id={"appVersion" + idx}
                              />
                            );
                            // <div
                            //   className="addver_table "
                            //   onClick={() => {
                            //     let searchParam = `?app_name=${app_name}`;
                            //     if (serialNumber) {
                            //       searchParam += `&serial_number=${serialNumber}`;
                            //     }
                            //     searchParam += `&version_name=${item.version_name}`;
                            //     history.push({
                            //       pathname: "/apps/addedversion/versiondetails",
                            //       search: searchParam,
                            //     });
                            //   }}
                            // >
                            //   {themeContext.Role.current === "user" && (
                            //     <AddLicenseVersion
                            //       app_name={app_name}
                            //       serialNumber={serialNumber}
                            //       id={"version" + idx}
                            //       openAddLicense={openAddLicense}
                            //       setOpenAddLicense={setOpenAddLicense}
                            //       appThumbnail={appThumbnail}
                            //       AppId={item}
                            //       setClickedAppId={setClickedAppId}
                            //     />
                            //   )}

                            //   {Object.keys(item).map((ele, i) => {
                            //     if (ele === "appId") {
                            //       return false;
                            //     }
                            //     return (
                            //       <div className="addver_td">
                            //         <div>
                            //           <h4 className="addver_td_header">
                            //             {ele === "status" &&
                            //               themeContext.Role.current ===
                            //                 "developer" && (
                            //                 <h4 className="onhover_td">
                            //                   Action
                            //                 </h4>
                            //               )}
                            //             {ele.replace("_", " ")}
                            //           </h4>

                            //           <p
                            //             className={
                            //               arr.includes(item[ele])
                            //                 ? "status_style addver_td_values "
                            //                 : "addver_td_values"
                            //             }
                            //             style={{
                            //               color: getStatusColor(item[ele]),
                            //               backgroundColor: getStatusBgc(
                            //                 item[ele]
                            //               ),
                            //             }}
                            //           >
                            //             {ele === "created_date" ? (
                            //               formatDate(item.created_date)
                            //             ) : ele === "version" ? (
                            //               item[ele]
                            //             ) : ele === "status" ? (
                            //               <p style={{ width: "5vw" }}>
                            //                 {item[ele]}
                            //               </p>
                            //             ) : (
                            //               item[ele]
                            //             )}

                            //             {item[ele]?.includes("uploaded") && (
                            //               <div>
                            //                 {doubleTick("addedver_doubletick")}
                            //                 {themeContext.Role.current ===
                            //                   "developer" && (
                            //                   <div className="hoverData hoverDataGreen">
                            //                     <div className="shadow  shadowGreen">
                            //                       <div
                            //                         onClick={(e) => {
                            //                           e.stopPropagation();
                            //                           setVersionDeleteConfirm(
                            //                             true
                            //                           );
                            //                           setDeleteVersionName(
                            //                             item.version_name
                            //                           );
                            //                         }}
                            //                         className="material-icons upload_delete_icon"
                            //                       >
                            //                         {trash()}
                            //                       </div>
                            //                     </div>
                            //                   </div>
                            //                 )}
                            //               </div>
                            //             )}
                            //             {item[ele]?.includes("failed") && (
                            //               <div className="delete_info">
                            //                 <div className="hoverData hoverDataRed">
                            //                   <div className="shadow shadowRed" />
                            //                   <div
                            //                     onClick={(e) => {
                            //                       e.stopPropagation();
                            //                       setOpenInfo(!openInfo);
                            //                     }}
                            //                   >
                            //                     {infoIcon2()}
                            //                   </div>

                            //                   <div className="shadow" />
                            //                   <div
                            //                     onClick={(e) => {
                            //                       e.stopPropagation();
                            //                       setVersionDeleteConfirm(true);
                            //                       setDeleteVersionName(
                            //                         item.version_name
                            //                       );
                            //                     }}
                            //                     className="failed_delete_icon"
                            //                   >
                            //                     {trash()}
                            //                   </div>
                            //                 </div>
                            //               </div>
                            //             )}

                            //             {ele === "Version type" &&
                            //               item[ele] === null &&
                            //               "None"}
                            //             {ele === "version Name" &&
                            //               item[ele] === null &&
                            //               "None"}
                            //             {ele === "Status" &&
                            //               item[ele] === null &&
                            //               "None"}
                            //             {ele === "Created Date" &&
                            //               item[ele] === null &&
                            //               "None"}
                            //           </p>

                            //           {ele === "version_name" &&
                            //             themeContext.Role.current ===
                            //               "superadmin" &&
                            //             item.status === "uploaded" &&
                            //             showApprovalWrapper && (
                            //               <div className="approval_wrapper">
                            //                 <Button
                            //                   name={"Approve"}
                            //                   style={{
                            //                     backgroundColor: "#25D431",
                            //                     color: "#ffff",
                            //                   }}
                            //                   btnImg={tick("approve_icon")}
                            //                   onClick={(e) => {
                            //                     e.stopPropagation();
                            //                     setApproveVersion(true);
                            //                   }}
                            //                 />
                            //                 <Button
                            //                   name={"Reject"}
                            //                   style={{
                            //                     backgroundColor: "#FE1A1A21",
                            //                     color: "#FE1A1A",
                            //                   }}
                            //                   btnImg={cross("reject_icon")}
                            //                   onClick={(e) => {
                            //                     e.stopPropagation();
                            //                     setRejectVersion(true);
                            //                   }}
                            //                 />
                            //               </div>
                            //             )}
                            //         </div>
                            //       </div>
                            //     );
                            //   })}
                            // </div>
                          })}
                        </div>
                      </Scroller>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Navbar>
      {loadingScreen && <Loading type={"transparent"} text={"Loading"} />}
      <AnimatedModal modalOpen={ShowOTP} className="preview_modal">
        <OTP
          showOTP={ShowOTP}
          setShowOTP={setShowOTP}
          postVerifyOTP={(otp_) => {
            postVerifyOTP(otp_);
          }}
          subheader={
            "Enter the OTP received on your registered email ID to delete this application"
          }
          setOTPCloseConfirm={setOTPCloseConfirm}
          postResendOTP={postResendOTP}
          resendFunc={(func) => {
            resetCounter = func;
          }}
          _stop={Stop}
          setStop={setStop}
        />
      </AnimatedModal>
      <AnimatedModal
        modalOpen={OTPCloseConfirm}
        onConfirm={() => {
          setOTPCloseConfirm(false);
          setShowOTP(false);
          encryptStorage.removeItem("VID");
        }}
        handleClose={() => {
          setOTPCloseConfirm(false);
        }}
        type={"confirm"}
        errorText={"Are you sure you want to close?"}
        alwayOpen={true}
      />
      <AnimatedModal modalOpen={openInfo}>
        <Scroller
          autoHeightMax="75vh"
          verticalStyle={{
            width: "0.12vw",
            marginLeft: "0.2vw",
            backgroundColor: "#283e92",
          }}
        >
          <div className="info_card">
            <h4 className="info_upper_wrapper">Failed note for that version</h4>
            <div className="info_lower_wrapper">
              Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sed
              neque fugit, nostrum saepe eaque, placeat error blanditiis dolore
              et explicabo molestias dignissimos veritatis? Ut tempora, aut
              atque nobis laudantium harum.
            </div>
            <div
              className="close_otp_modal"
              onClick={() => {
                setOpenInfo(false);
              }}
            >
              {closeIcon()}
            </div>
          </div>
        </Scroller>
      </AnimatedModal>
      <UpgradeLicense2
        RenewLicence={RenewLicence}
        setRenewLicence={setRenewLicence}
        SelectedLicenseDetails={SelectedLicenseDetails}
        setSelectedLicenseDetails={setSelectedLicenseDetails}
        selectedDevice={selectedDevice}
        _mapping_id={_mapping_id}
        _license_name={_license_name}
        deviceName={deviceName}
        manufacturerName={manufacturerName}
        serialNumber={serial_number}
        clickedAppName={app_name}
        clickedThumbnail={appThumbnail}
        licenseType={"app"}
        clickedAppId={clickedAppId}
      />
      <SelectLicense
        clickedAppName={app_name}
        FetchLicense={openAddLicense}
        setFetchLicense={setOpenAddLicense}
        StandardLicenses={standardLicenses}
        getLicenses={getStandardLicenses}
        getSpecialLicenses={getSpecialLicenses}
        specialLicense={specialLicenses}
        changePage={changeLicensePage}
        setChangePage={setchangeLicensePage}
        manuName={"admin"}
        onBuyClick={(index) => {
          _license_name = standardLicenses[index].license_details.name;
          addCountKey(index);
          setRenewLicence(true);
          setOpenAddLicense(false);
          // _license_index = index
          // license_type = "Standard"
        }}
        selectCard={selectCard}
        ShowAlert={showAlert}
        setShowAlert={setShowAlert}
        msg={msg}
        serialNumber={serial_number}
        clickedThumbnail={appThumbnail}
      />
      <AnimatedModal
        modalOpen={approveVersion}
        onConfirm={() => {
          // deleteVersion();
          setApproveVersion(false);
          // setShowApprovalWrapper(false)

          // setShowOTP(false);
          // encryptStorage.removeItem("VID");
        }}
        handleClose={() => {
          setApproveVersion(false);
          setShowApprovalWrapper(false);
        }}
        type={"confirm"}
        errorText={"Are you sure you want to approve this app version?"}
        alwayOpen={true}
      />
      <AnimatedModal
        modalOpen={rejecVersion}
        onConfirm={() => {
          // deleteVersion();
          setRejectVersion(false);
          // setShowOTP(false);
          // encryptStorage.removeItem("VID");
        }}
        handleClose={() => {
          setRejectVersion(false);
        }}
        type={"confirm"}
        errorText={"Are you sure you want to reject this app version?"}
        alwayOpen={true}
      />
      {/* <AnimatedModal
        modalOpen={versionDeleteConfirm}
        onConfirm={() => {
          deleteVersion();
          setVersionDeleteConfirm(false);
          // setShowOTP(false);
          // encryptStorage.removeItem("VID");
        }}
        handleClose={() => {
          setVersionDeleteConfirm(false);
        }}
        type={"confirm"}
        errorText={"Are you sure you want to delete this version?"}
        alwayOpen={true}
      /> */}
      <AnimatedModal
        modalOpen={showAlert}
        // modalOpen={true}
        handleClose={() => {
          setShowAlert(false);
        }}
        type={msg.type}
        errorHeader={msg.header}
        errorText={msg.errorText}
        // timeout={5000}
        alwayOpen={true}
      />
      <UserLoginModal
        showUserLogin={showUserLogin}
        setshowUserLogin={setshowUserLogin}
        getFilterOptions={fetchAddedVersion}
        // setConfirm={setConfirm}
      />
    </div>
  );
}
