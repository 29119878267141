import React from "react";
import { searchIcon } from "../../helper/icons";
import "./searchbar.scss";
export default function Searchbar({
  placeholder = "search",
  handleSearch,
  onKeyDown = null,
  autoFocus = false,
  value,
  disabled,
  onFocus,
  isLoading = false,
}) {
  return (
    <div className="search_wrapper_">
      <input
        placeholder={placeholder}
        type={"search"}
        className="search_input_"
        onChange={handleSearch}
        onKeyDown={onKeyDown}
        autoFocus={autoFocus}
        value={value}
        disabled={disabled}
        onFocus = {onFocus}
      />
      {isLoading ? <span className="search_loader"></span> : searchIcon()}
    </div>
  );
}
