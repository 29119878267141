import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import "./Widget.scss";
import { item } from "../../helper/motions";
import { dots } from "../../helper/icons";
import { motion } from "framer-motion";
import useClickOutside from "../../helper/useClickOutside";
import { useHistory } from "react-router-dom";
import noversion from "../../assets/images/noversion.png";
import { ThemeContext } from "../../helper/context";
import { encryptStorage } from "../../helper/storage";
let Role = [];

export default function Widget({
  appName,
  version,
  appStatus,
  appDownloads,
  appThumbnail,
  id,
  otp,
  setOtp,
  selectedAppGroup,
  apptype,
  setOpenAddLicense,
  setClickedAppName,
  setClickedThumbnail,
  AppId,
  setClickedId,
  serialNumber,
}) {
  const history = useHistory();
  const themeContext = useContext(ThemeContext);
  const primaryRef = useRef();
  const [isOpen, setisOpen] = useState(false);
  const primaryClose = useCallback(() => {
    console.log("app prinmary");
    document.getElementById(id).classList.remove("active");
    setTimeout(() => {
      setisOpen(false);
    }, 500);
  }, []);

  useClickOutside(primaryRef, primaryClose);
  useEffect(() => {
    let dataa = encryptStorage.getItem("UID").roles;
    Role = dataa;
    if (isOpen) {
      setTimeout(() => {
        document.getElementById(id).classList.add("active");
      }, 200);
    }
  }, [isOpen]);
  // const appType = {
  //   logic: "logic",
  //   processing: "AI Model",
  //   firmware: "IOT Firmware",
  // };
  const formatDownload = (n) => {
    if (n < 1e3) return n;
    if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
    if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
    if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
    if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
  };

  useEffect(() => {
    let dataa = encryptStorage.getItem("UID").roles;
    Role = dataa;
  }, []);
  return (
    <div className="widget_wrapper">
      <div className="widget_main_wrapper">
        <div className="widget_subwrapper">
          <div
            className="dots_svg"
            onClick={() => {
              if (isOpen) {
                document.getElementById(id)?.classList.remove("active");
                setTimeout(() => {
                  setisOpen(!isOpen);
                }, 500);
              } else {
                setisOpen(!isOpen);
                document.getElementById(id)?.classList?.add("active");
              }
            }}
          >
            {dots("dot_icon_active")}
          </div>
          <div
            className="widget_upper_subwrapper"
            // onClick={(e) => {
            //   version === null ? <img src={noversion}></img> :
            //   history.push({
            //     pathname: "/apps/addedversion",
            //     search: `?app_name=${appName}`,
            //     // state: {img: appThumbnail}
            //   });
            // }}
            onClick={(e) => {
              let searchParam = `app_name=${appName}`;
              if (serialNumber) {
                searchParam += `&serial_number=${serialNumber}`;
              }
              // version === null ? <img src={noversion}></img> :
              history.push({
                pathname: "/apps/addedversion",
                search: searchParam,
                state: { img: appThumbnail, id: id },
              });
            }}
          >
            {/* {version === null && history.push("/apps")} */}
            {isOpen && (
              <div className="open" id={id} ref={primaryRef}>
                <p
                  className="dots_options"
                  onClick={(e) => {
                    let searchParam = `app_name=${appName}`;
                    if (serialNumber) {
                      searchParam += `&serial_number=${serialNumber}`;
                    }
                    e.stopPropagation();
                    history.push({
                      pathname: "/apps/usecase",
                      search: searchParam,
                    });
                  }}
                >
                  View Details
                </p>
                {themeContext?.Role.current === "manufacturer" && (
                  <p
                    className="dots_options"
                    onClick={(e) => {
                      e.stopPropagation();
                      history.push({
                        pathname: "/apps/license",
                        search: `?app_name=${appName}`,
                        // state: {detail: {img : appThumbnail}}
                        state: { img: appThumbnail },
                      });
                    }}
                  >
                    License
                  </p>
                )}

                {/* {Role.includes("user") &&
                  themeContext.Role.current !== "developer" && (
                    <p
                      className="dots_options"
                      onClick={(e) => {
                        let searchParam = `app_name=${appName}`;
                        if (serialNumber) {
                          searchParam += `&serial_number=${serialNumber}`;
                        }
                        e.stopPropagation();
                        history.push({
                          pathname: "/apps/appsInventory",
                          search: searchParam,
                          // state: {detail: {img : appThumbnail}}
                          state: { img: appThumbnail },
                        });
                      }}
                    >
                      Inventory
                    </p>
                  )} */}
                {/* {selectedAppGroup === "purchased" && (
                  <p
                    className="dots_options"
                    onClick={(e) => {
                      e.stopPropagation();
                      history.push({
                        pathname: "/apps/appsInventory",
                        search: `?app_name=${appName}`,
                        // state: {detail: {img : appThumbnail}}
                        state: { img: appThumbnail },
                      });
                    }}
                  >
                    Inventory
                  </p>
                )} */}
                {
                  appStatus === "uploaded" &&
                    themeContext.Role.current === "developer" && (
                      // selectedAppGroup === "owned" && (
                      <p
                        onClick={(e) => {
                          e.stopPropagation();
                          setOtp(appName);
                        }}
                        className="dots_options "
                      >
                        Delete
                      </p>
                    )
                  // )
                }
                {/* {themeContext.Role.current === "user" && (
                  <p
                    onClick={(e) => {
                      setClickedAppName(appName);
                      setClickedThumbnail(appThumbnail);
                      setClickedId(AppId);
                      setOpenAddLicense(true);
                      e.stopPropagation();
                    }}
                    className="dots_options "
                  >
                    Add license
                  </p>
                )} */}
                {/* {appStatus === "uploaded" &&
                  themeContext.Role.current === "developer" && (
                    <p
                      onClick={(e) => {
                        e.stopPropagation();
                        setOtp(appName);
                      }}
                      className="dots_options "
                    >
                      Delete
                    </p>
                  )} */}
                {/* {Role.includes("user") &&
                  themeContext.Role.current !== "developer" && (
                    <p
                      className="dots_options"
                      onClick={(e) => {
                        e.stopPropagation();
                        history.push({
                          pathname: "/apps/appsInventory",
                          search: `?app_name=${appName}`,
                          // state: {detail: {img : appThumbnail}}
                          state: { img: appThumbnail },
                        });
                      }}
                    >
                      inventory
                    </p>
                  )} */}
                {/* <p className="dots_options" style={{ borderBottom: 0 }}>
              View Logs
            </p> */}
              </div>
            )}
            <div className="widget_upper_subwrapper_content">
              <img className="widget_image" src={appThumbnail}></img>
              <div className="appname_version">
                <p
                  title={appName.replaceAll("_", " ")}
                  className="widget_appname"
                >
                  {appName.replaceAll("_", " ")}
                </p>
                <p className="version">
                  Latest version - {version === null ? "NA" : version}
                </p>
                <p className="version">{apptype}</p>
              </div>
            </div>
          </div>
          {/* })} */}

          <div className="widget_lower_subwrapper">
            <div className="widget_lower_subwrapper_content">
              <div className="status_main_wrapper">
                <div
                  className="status"
                  style={{
                    color:
                      appStatus === "active"
                        ? "green"
                        : appStatus === "failed"
                        ? "red"
                        : appStatus === "uploaded"
                        ? "blue"
                        : "orange",
                  }}
                >
                  {appStatus}
                </div>
                <span className="recentstatus">Recent status</span>
              </div>
              {themeContext.Role.current === "developer" ? (
                <div className="downloads">
                  {appDownloads ? formatDownload(appDownloads) : "0"}
                  <span className="downloads_content">
                    {appDownloads <= 0 ? "Download" : "Downloads"}
                  </span>
                </div>
              ) : (
                <div className="downloads">
                  {appDownloads ? formatDownload(appDownloads) : "0"}
                  <span className="downloads_content">
                    {appDownloads <= 0 ? "License" : "Licenses"}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
