import React, { Component } from "react";
import Scroller from "../../../../../components/Scroller/ScrollBar";
import "../VersionDetails/ContentCard.scss";

export default class ContentCard extends Component {
  render() {
    return (
      <div className="ContentCard_main_wrapper">
        {Object.keys(this.props.basicdetailsContentCard).map((item, i) => (
          <div className="ContentCard_main_subwrapper">
            <p className="ContentCard_tag">{item.replace("_", " ")}</p>

            <div className="ContentCard_subwrapper">
              {typeof this.props.basicdetailsContentCard[item] === "string" && (
                <Scroller
                  autoHeightMax="10vh"
                  verticalStyle={{
                    width: "0.12vw",
                    marginLeft: "0.3vw",
                    backgroundColor: "#283e92",
                  }}
                >
                  <div className="item">
                    {this.props.basicdetailsContentCard[item]}
                  </div>
                </Scroller>
              )}
              {/* {console.log(typeof(this.props.basicdetailsContentCard[item]))} */}
              {typeof this.props.basicdetailsContentCard[item] === "object"
                ? this.props.basicdetailsContentCard[item].map((xx) => (
                    <p className="ContentCard_item">{xx}</p>
                  ))
                : ""}
            </div>
          </div>
        ))}
      </div>
    );
  }
}
