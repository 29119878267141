import React, { useState, useRef, useEffect, useMemo, useContext } from "react";
import "./profile.scss";
import Navbar from "../../../components/Navbar/Navbar";
import bg from "../../../assets/images/background.png";
import InputBox from "../../../components/Inputbox/InputBox";
import Button from "../../../components/Button/Button";
import Scroller from "../../../components/Scroller/ScrollBar";
import {
  encryptStorage,
  getDateTime,
  isSuspended,
} from "../../../helper/storage";
import { ThemeContext } from "../../../helper/context";
import DeveloperModal from "../../../components/DeveloperModal/DeveloperModal";
import ManufacturerModal from "../../../components/DeveloperModal/ManufacturerModal ";
import AnimatedModal from "../../../components/Modal/AnimatedModal";
import { closeIcon, editIcon } from "../../../helper/icons";
import devIcon from "../../../assets/images/devIcon.png";
import manuIcon from "../../../assets/images/manuIcon.png";
import { API_URL, axiosApiInstance, OTP_TIME } from "../../../helper/request";
import Scrollbars from "react-custom-scrollbars";
import { useDebouncedEffect } from "../../../helper/useDebounce";
import axios from "axios";
import { LoadingText } from "../../auth/register/AuthRegister";
import ConfirmPasswordModal from "../../../components/ConfirmPasswordModal/ConfirmPasswordModal";
import ResetPassword from "../../../components/ResetPassword/ResetPassword";
import Loading from "../../../components/Loading/Loading";
import OTP from "../../../components/OTP/OTP";
import { userIcon } from "../Users/Users";
import PhoneInputBox from "../../../components/PhoneInputBox/PhoneInputBox";
let isExact = false;
let resetCounter = null;
let isCityEmpty = false;
let isDataEmpty = null;
let timeout = null;
let firstLoad = true;
let isProfileChanged = false;
let isEditing = false;
let _pass = null;
let values = {
  country: null,
  state: null,
  city: null,
};
let msg = {
  type: "alert",
  header: "Error",
  errorText: "Something Went Wrong",
};
let type_ = null;
let _lData__ = encryptStorage.getItem("VID");
let userData = {};
export default function Profile() {
  const countryScrollRef = useRef();
  const cityScrollRef = useRef();
  const fNameRef = useRef();
  const countryRef = useRef();
  const stateRef = useRef();
  const cityRef = useRef();
  const themeContext = useContext(ThemeContext);
  const [LoadingScreen, setLoadingScreen] = useState(true);
  const [Activity, setActivity] = useState([]);
  const [ShowAlert, setShowAlert] = useState(false);
  const [Stop, setStop] = useState(false);
  const [Confirm, setConfirm] = useState(false);
  const [ConfirmModal, setConfirmModal] = useState(false);
  const [ShowResetPassword, setShowResetPassword] = useState(false);
  const [DevModal, setDevModal] = useState(false);
  const [ManufModal, setManufModal] = useState(false);
  const [ShowOTP, setShowOTP] = useState(false);
  const [OTPCloseConfirm, setOTPCloseConfirm] = useState(false);
  const [ShowEmailInputModal, setShowEmailInputModal] = useState(false);
  const [Both, setBoth] = useState(false);
  const [Upgrade, setUpgrade] = useState({
    header: "Become A Developer Or Manufacturer",
    subheader: null,
    button: null,
    hide: false,
    type: null,
  });

  const [Name, setName] = useState("");
  const [DefaultEmail, setDefaultEmail] = useState("");
  const [Mobile, setMobile] = useState("");
  const [Country, setCountry] = useState("");
  const [State, setState] = useState("");
  const [City, setCity] = useState("");
  const [LocationData, setLocationData] = useState({
    country: [],
    state: [],
    city: [],
    citySuggestion: [],
  });
  const [LocationLoading, setLocationLoading] = useState({
    country: false,
    state: false,
    city: false,
  });
  const [LocationSelected, setLocationSelected] = useState({
    isCountrySelected: true,
    isStateSelected: true,
    isCitySelected: true,
  });

  const [Email, setEmail] = useState("");
  // const [Country, setCountry] = useState("");
  const [Password, setPassword] = useState("");
  const [ConfirmPassword, setConfirmPassword] = useState("");
  const [ProfilePic, setProfilePic] = useState(null);
  const [errors, setErrors] = useState({
    isEmailUnique: false,
    isMobileUnique: false,
  });

  useDebouncedEffect(
    () => (Country ? getCountry("country") : undefined),
    [Country],
    1000
  );

  useDebouncedEffect(
    () => (State ? getCountry("state") : undefined),
    [State],
    1000
  );

  useDebouncedEffect(
    () => (Mobile ? uniqueCheck("phone") : undefined),
    [Mobile],
    1000
  );

  useDebouncedEffect(
    () => (Email ? uniqueCheck("Email") : undefined),
    [Email],
    1000
  );

  const clearError2 = (name) => {
    let _errors = { ...errors };
    _errors[name] = false;
    setErrors({ ..._errors });
  };

  const uniqueCheck = (type = "phone") => {
    console.log("uniqueCheck", firstLoad, isEditing);
    if (firstLoad || !isEditing) {
      return;
    }
    if (type === "phone") {
      if (Mobile === userData?.phoneRef) return;
    }
    let value = {
      Email,
      phone: Mobile,
    };
    let url = `user/unique-check/${type.toLowerCase()}?fieldValue=${
      value[type]
    }`;
    axios.get(API_URL + url).then((res) => {
      if (res.data.detail) {
        if (type === "Email") {
          error("newEmail");
          setErrors((prevState) => ({
            ...prevState,
            isEmailUnique: true,
          }));
        }

        if (type === "phone") {
          error("mobile_number");
          setErrors((prevState) => ({
            ...prevState,
            isMobileUnique: true,
          }));
        }
      } else {
        if (type === "Email") {
          handleFocus("newEmail");

          setErrors((prevState) => ({
            ...prevState,
            isEmailUnique: false,
          }));
        }

        if (type === "phone") {
          handleFocus("mobile_number");

          setErrors((prevState) => ({
            ...prevState,
            isMobileUnique: false,
          }));
        }
      }
    });
  };

  const getCountry = (type = "country") => {
    console.log("getCountry");
    console.log(firstLoad);
    if (firstLoad) {
      return;
    }
    let url = "";
    if (type === "country") {
      url = "admin/country/filters?country=" + Country;
    } else {
      url = "admin/country/filters?country=" + Country + "&state=" + State;
    }
    axios.get(API_URL + url).then((res) => {
      // console.log(res.data.detail);
      let _data = res.data.detail;
      let keys = null;
      if (_data.length) {
        keys = Object.keys(_data[0]);
      } else {
        if (type === "state") {
          setLocationData((prevState) => ({
            ...prevState,
            state: [],
          }));
        } else {
          setLocationData((prevState) => ({
            ...prevState,
            country: [],
          }));
        }
        setLocationLoading((prevState) => ({
          ...prevState,
          state: false,
          country: false,
          city: false,
        }));
        isDataEmpty = true;
        return;
      }

      if (keys?.includes("state")) {
        console.log("state");
        if (State === _data[0].state) {
          if (_data[0]?.cities?.length === 0) {
            isCityEmpty = true;
          } else {
            isCityEmpty = false;
          }
          setLocationData((prevState) => ({
            ...prevState,
            city: [..._data[0].cities],
          }));
          setLocationLoading((prevState) => ({
            ...prevState,
            state: false,
            city: false,
          }));
        } else {
          setLocationSelected((prevState) => ({
            ...prevState,
            isCountrySelected: true,
          }));
          setLocationData((prevState) => ({
            ...prevState,
            state: [..._data],
          }));
          setLocationLoading((prevState) => ({
            ...prevState,
            state: false,
            country: false,
            city: false,
          }));
        }
      } else {
        console.log("ELSE....");
        setLocationLoading((prevState) => ({
          ...prevState,
          country: false,
        }));
        setLocationData((prevState) => ({
          ...prevState,
          country: [..._data],
        }));
      }
    });
  };

  useEffect(() => {
    if (firstLoad) return;
    setState("");
    setCity("");
    isCityEmpty = false;
    if (Country === "") {
      setLocationData((prevState) => ({
        ...prevState,
        country: [],
        state: [],
        city: [],
        citySuggestion: [],
      }));
    } else {
      setLocationData((prevState) => ({
        ...prevState,
        state: [],
        city: [],
        citySuggestion: [],
      }));
    }

    setLocationSelected((prevState) => ({
      ...prevState,
      isStateSelected: false,
      isCitySelected: false,
      isCountrySelected: false,
    }));
    if (values.country) {
      setLocationSelected((prevState) => ({
        ...prevState,
        isCountrySelected: true,
      }));
    }
  }, [Country]);

  useEffect(() => {
    if (firstLoad) return;
    setCity("");
    if (State) {
      setLocationData((prevState) => ({
        ...prevState,
        city: [],
        citySuggestion: [],
        state: [],
      }));
    } else {
      setLocationLoading((prevState) => ({
        ...prevState,
        state: false,
      }));
      setLocationData((prevState) => ({
        ...prevState,
        city: [],
        citySuggestion: [],
      }));
    }

    setLocationSelected((prevState) => ({
      ...prevState,
      isStateSelected: false,
      isCitySelected: false,
    }));
    if (values.state) {
      setLocationSelected((prevState) => ({
        ...prevState,
        isStateSelected: true,
      }));
    }
  }, [State]);

  const getKeyCode = (e) => {
    switch (e.keyCode) {
      case 38:
        arrowKey(e, "up");
        break;

      case 40:
        arrowKey(e, "down");
        break;

      case 13:
        enterKey(e);
        // setIsFlagOpen(false);
        break;
    }
  };

  const arrowKey = (e, type) => {
    let myReferenceDiv = document.querySelector("._span");
    let active_flag = document.querySelector(".active_flag");
    let active_next = active_flag?.nextElementSibling;
    let active_prev = active_flag?.previousElementSibling;
    let next = myReferenceDiv?.nextElementSibling;
    let prev = myReferenceDiv?.previousElementSibling;
    if (type === "down") {
      if (!active_flag) {
        next.classList.add("active_flag");
      } else {
        if (active_next) {
          active_flag.classList.remove("active_flag");
          active_next.classList.add("active_flag");
          var childElement =
            document.querySelector(".active_flag").offsetTop -
            myReferenceDiv.offsetHeight -
            20;
          cityScrollRef?.current?.scrollTop(childElement);
          countryScrollRef?.current?.scrollTop(childElement);
        }
      }
    }
    if (type === "up") {
      if (active_prev && !active_prev.classList.contains("_span")) {
        active_flag.classList.remove("active_flag");
        active_prev.classList.add("active_flag");
        var childElement =
          document.querySelector(".active_flag").offsetTop -
          myReferenceDiv.offsetHeight -
          20;
        cityScrollRef?.current?.scrollTop(childElement);
        countryScrollRef?.current?.scrollTop(childElement);
      }
    }
  };

  const enterKey = () => {
    let active_flag = document.querySelector(".active_flag");
    let dataCity = active_flag.getAttribute("data-city");
    let dataCountry = active_flag.getAttribute("data-country");
    let dataState = active_flag.getAttribute("data-state");
    console.log(active_flag, dataCity, dataCountry, dataState);
    if (dataCity) {
      isDataEmpty = null;
      setCity(dataCity);
      setLocationSelected((prevState) => ({
        ...prevState,
        isCitySelected: true,
      }));
      setLocationData((prevState) => ({
        ...prevState,
        citySuggestion: [],
      }));
    }

    if (dataState) {
      values.state = dataState;
      isExact = true;
      isDataEmpty = null;
      setState(dataState);
      setLocationSelected((prevState) => ({
        ...prevState,
        isStateSelected: true,
      }));
    }

    if (dataCountry) {
      isExact = true;
      isDataEmpty = null;
      values.country = dataCountry;
      setCountry(dataCountry);
      setLocationSelected((prevState) => ({
        ...prevState,
        isCountrySelected: true,
      }));
      setLocationLoading((prevState) => ({
        ...prevState,
        state: true,
      }));
    }
  };

  const emailValidation = (_email) => {
    if (
      _email.match(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      return true;
    } else {
      return false;
    }
  };

  const clearError = (name) => {
    let _errors = { ...errors };
    _errors[name] = false;
    setErrors({ ..._errors });
  };
  const postResetUserPassword = (_password, _old_password) => {
    console.log(_password);
    // setLoadingScreen(true);
    axios
      .post(API_URL + "user/updatePassword", {
        old_password: _old_password,
        current_password: _password,
        email: DefaultEmail,
      })
      .then((res) => {
        msg.errorText = "Password changed successfully!";
        msg.header = "Success";
        msg.type = "success";
        setShowAlert(true);
        setPassword("");
        setConfirmPassword("");
        setShowResetPassword(false);
        setTimeout(() => {
          setShowAlert(false);
        }, 3000);
      })
      .catch((err) => {})
      .finally(() => {
        // setLoadingScreen(false);
      });
  };
  const getCity = (country, state) => {
    axios
      .get(
        API_URL + "admin/country/filters?country=" + country + "&state=" + state
      )
      .then((res) => {
        let _data = res.data.detail;
        if (_data[0]?.cities?.length === 0) {
          isCityEmpty = true;
        } else {
          isCityEmpty = false;
        }
        setLocationData((prevState) => ({
          ...prevState,
          city: [..._data[0].cities],
        }));

        console.log(res);
      });
  };
  const getProfile = () => {
    setLoadingScreen(true);
    axiosApiInstance
      .get("user/profile/data")
      .then((res) => {
        // phoneRef.current.value = phone;
        // phoneRef.current.disabled = true;
        let { fullname, email, country, city, phone, state, profile_pic } =
          res.data;
        let ldata = encryptStorage.getItem("UID");
        ldata.profile_pic = profile_pic;
        document.getElementById("userFullName").innerHTML = fullname;
        setDefaultEmail(email);
        setMobile(phone);
        console.log(phone);
        setCountry(country);
        setState(state);
        setCity(city);
        setProfilePic(profile_pic);
        if (country && state) {
          getCity(country, state);
        }
        setLocationSelected({
          isCountrySelected: false,
          isStateSelected: false,
          isCitySelected: false,
        });
        console.log(res);
        setName(fullname);
        setTimeout(() => {
          // fNameRef.current.value = fullname;
          // emailRef.current.value = email;
          fNameRef.current.disabled = true;
          countryRef.current.disabled = true;
          cityRef.current.disabled = true;
        }, 0);
        setTimeout(() => {
          firstLoad = false;
        }, 2000);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoadingScreen(false);
      });
  };

  const sendOTP = () => {
    let obj = {
      username: encryptStorage.getItem("UID")?.username,
      email: Email,
    };
    setLoadingScreen(true);
    setShowEmailInputModal(false);
    axios
      .post(API_URL + "user/otp/send", obj)
      .then((res) => {
        setTimeout(() => {
          setShowOTP(true);
        }, 500);
        encryptStorage.setItem("VID", {
          counter: OTP_TIME,
          destination: Email,
          username: obj.username,
        });
      })
      .catch((err) => {
        msg.errorText = "Something went wrong!";
        msg.header = "Error";
        msg.type = "alert";
        setShowAlert(true);
      })
      .finally(() => {
        setLoadingScreen(false);
      });
  };

  const postVerifyOTP = (otp_) => {
    console.log(otp_);
    let lData = encryptStorage.getItem("VID");
    console.log(lData);
    let obj = {
      username: lData.username,
      email: lData.destination,
      otp: otp_,
    };
    setShowEmailInputModal(false);
    setLoadingScreen(true);
    axios
      .post(API_URL + "user/otp/verify", obj)
      .then((res) => {
        console.log(res);
        msg.type = "success";
        msg.header = "Success";
        msg.errorText = "OTP Verified Successfully. Email Updated!";
        setLoadingScreen(false);
        setShowAlert(true);
        setShowOTP(false);
        encryptStorage.removeItem("VID");
        // emailRef.current.value = Email;
        setDefaultEmail(Email);
        postUserData();
        setTimeout(() => {
          setShowAlert(false);
        }, 3000);
        // var ele = document.querySelector("#otp_container");
        // ele.classList.add("slideOutLeft");
        // timeout = setTimeout(() => {
        //   setshowOTPContainer(false);
        //   setshowRegSuccessContainer(true);
        //   ele.classList.remove("slideOutLeft");
        // }, 500);
      })
      .catch((err) => {
        // setStop(false);
        // _stop = false;
        msg.errorText = "Invalid OTP. Please Try Again!";
        msg.header = "Error";
        msg.type = "alert";
        setShowAlert(true);
        setLoadingScreen(false);
        console.log(err);
      });
  };
  const postResendOTP = () => {
    let _lData = encryptStorage.getItem("VID");

    let obj = {
      username: _lData.username,
      email: _lData.destination,
    };
    setLoadingScreen(true);
    axios
      .post(API_URL + "user/otp/send", obj)
      .then((res) => {
        msg.errorText = "OTP resent to your Email ID.";
        msg.header = "Success";
        msg.type = "success";
        setShowAlert(true);
        resetCounter();
        // setCounter(time);
        // _lData.attempts = _lData.attempts - 1;
        // encryptStorage.setItem("VID", _lData);
      })
      .catch((err) => {
        msg.errorText = "Server Error";
        msg.header = "Error";
        msg.type = "alert";
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 3000);
        // clearTimeout(timeout);
        // timeout = setTimeout(() => {
        //   encryptStorage.removeItem("VID");
        //   window.location.reload();
        // }, 5000);
        console.log(err.response);
      })
      .finally(() => {
        setLoadingScreen(false);
      });
  };

  const handleFocus = (name) => {
    let ele = document.getElementById(name);
    ele.classList.remove("error_shake", "input__error");
  };
  const error = (id) =>
    document.getElementById(id).classList.add("error_shake", "input__error");
  const postUserData = async () => {
    let _email = Email ? Email : DefaultEmail;
    let isError = false;
    let _mobile = Mobile.split(" ")[1];

    if (!Name) {
      error("fullName");
      isError = true;
    }
    if (!_mobile) {
      error("mobile_number");
      isError = true;
    }
    if (!_email) {
      error("email");
      isError = true;
    }
    if (!Country) {
      error("country");
      isError = true;
    }
    if (!State) {
      error("state");
      isError = true;
    }
    if (!City) {
      error("city");
      isError = true;
    }
    if (Object.values(errors).includes(true)) {
      if (errors.isMobileUnique) {
        error("mobile_number");
      }
      isError = true;
    }
    if (isError) {
      return;
    }

    let formData = new FormData();

    formData.append("full_name", Name);
    formData.append("phone", Mobile);
    formData.append("email", _email);
    formData.append("username", encryptStorage.getItem("UID")?.username);
    formData.append("country", Country);
    formData.append("state", State);
    formData.append("city", City);
    if (isProfileChanged) {
      formData.append("profile_pic", ProfilePic, ProfilePic.name);
    }
    setLoadingScreen(true);

    axios
      .post(API_URL + "user/edit/profile/data", formData, {
        headers: {
          password: _pass,
          Authorization: "Bearer " + encryptStorage.getItem("UID").refreshToken,
        },
      })
      .then((res) => {
        msg.errorText = "Updated Successfully";
        msg.header = "Success";
        msg.type = "success";
        isEditing = false;
        setEmail("");
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
          window.location.reload();
        }, 3000);
        firstLoad = true;
        isProfileChanged = false;

        // getProfile();
        // fNameRef.current.disabled = true;
        // phoneRef.current.disabled = true;
        // emailRef.current.disabled = true;
        // countryRef.current.disabled = true;
        // stateRef.current.disabled = true;
        // cityRef.current.disabled = true;
        // setTimeout(() => {
        // }, 1000);
        // setLocationSelected({
        //   isCountrySelected: true,
        //   isStateSelected: false,
        //   isCitySelected: false,
        // });
      })
      .catch((err) => {
        msg.errorText = "Failed to update profile detail!";
        msg.header = "Error";
        msg.type = "alert";
        setShowAlert(true);
      })
      .finally(() => {
        setLoadingScreen(false);
      });
  };

  const getActivity = () => {
    axiosApiInstance
      .get("user/activity")
      .then((res) => {
        console.log(res);
        // console.log(getDateTime(res.data[0].created));
        setActivity([...res.data]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getActivity();
    getProfile();
    if (_lData__) {
      setShowOTP(true);
      setStop(false);
    }
    return () => (firstLoad = true);
  }, []);

  const upgradeCard = useMemo(() => {
    let _upgrade = { ...Upgrade };
    let roles = null;
    if (encryptStorage?.getItem("UID")?.roles) {
      roles = encryptStorage?.getItem("UID").roles;
    }
    let pending = themeContext.Role?.pending;
    let suspended = encryptStorage?.getItem("UID")?.suspended;
    console.log(suspended);
    if (!roles && !pending) {
      return;
    }
    if (roles.includes("developer") && roles.includes("manufacturer")) {
      _upgrade.hide = false;
    } else {
      if (roles.includes("developer")) {
        _upgrade.hide = true;
        _upgrade.header = "Become a Manufacturer";
        _upgrade.subheader = "Upgrade to manufacturer to get more features.";
        _upgrade.type = "manufacturer";
      } else if (roles.includes("manufacturer")) {
        _upgrade.hide = true;
        _upgrade.header = "Become a Developer";
        _upgrade.subheader = "Upgrade to developer to get more features.";
        _upgrade.type = "developer";
      } else {
        _upgrade.hide = true;
        _upgrade.header = "Become A Developer or Manufacturer";
        _upgrade.subheader =
          "Upgrade to developer or manufacturer to get more features.";
        _upgrade.type = "both";
      }
      if (pending?.includes("manufacturer")) {
        if (roles?.includes("developer")) {
          _upgrade.hide = false;
        } else {
          _upgrade.hide = true;
          _upgrade.header = "Become a Developer";
          _upgrade.subheader = "Upgrade to developer to get more features.";
          _upgrade.type = "developer";
        }
      }
      // if (pending?.includes("developer")) {
      //   if (suspended.includes("developer")) {
      //     _upgrade.hide = false;
      //   }
      // }
    }
    if (suspended.includes("developer") && suspended.includes("manufacturer")) {
      _upgrade.hide = false;
    }
    setUpgrade({ ..._upgrade });
    console.log(_upgrade);
    return { header: _upgrade.header, subheader: _upgrade.subheader };
  }, [themeContext.Role]);

  return (
    <div className="_profile_wrapper_">
      {console.log(LocationLoading, Country)}
      <Navbar sidenav>
        <div className="profile_body fadeIn">
          <img src={bg} className="profile_bg" />
          <div className="profile_content">
            <h1>Profile</h1>
            <div className="profile_data">
              <div className="profile_data2">
                <div className="top">
                  <div className="profile_with_name">
                    <h1 className="name">
                      Welcome back, <span id="userFullName"></span>
                    </h1>
                    <div className="profile_holder_">
                      <div
                        className="image_holder"
                        draggable={false}
                        style={{ cursor: isEditing ? "pointer" : "default" }}
                      >
                        {ProfilePic ? (
                          isProfileChanged ? (
                            <img
                              src={URL.createObjectURL(ProfilePic)}
                              alt="profile"
                              draggable={false}
                            />
                          ) : (
                            <img src={ProfilePic} alt="profile" />
                          )
                        ) : (
                          userIcon()
                        )}
                        {isEditing && (
                          <React.Fragment>
                            <div id="circle" />
                            <div
                              className="click"
                              onClick={() => {
                                document.getElementById("profileInput").click();
                              }}
                            >
                              {profileIcon()}
                              <p>Click here to change profile</p>
                            </div>
                          </React.Fragment>
                        )}

                        <input
                          type={"file"}
                          id="profileInput"
                          style={{ display: "none" }}
                          accept="image/png, image/jpeg"
                          onChange={(e) => {
                            let file = e.target.files[0];
                            console.log(file);
                            if (!file) {
                              isProfileChanged = false;
                            } else {
                              isProfileChanged = true;
                              setProfilePic(file);
                            }
                          }}
                        />
                      </div>
                      <div className="username_button">
                        <p>{encryptStorage.getItem("UID")?.username}</p>
                        {!isEditing && (
                          <Button
                            name={"Change Password"}
                            type="gradient"
                            onClick={() => {
                              if (isSuspended()) return;
                              type_ = "password";
                              // setConfirmModal(true);
                              setShowResetPassword(true);
                            }}
                            style={{
                              alignSelf: "flex-end",
                              background: isSuspended() ? "gray" : null,
                            }}
                            disabled={isSuspended()}
                          />
                        )}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                          }}
                        >
                          {isEditing && (
                            <Button
                              name={"Cancel"}
                              type="gradient"
                              onClick={() => {
                                isEditing = false;
                                firstLoad = true;
                                isProfileChanged = false;
                                [
                                  "fullName",
                                  "mobile_number",
                                  "email",
                                  "country",
                                  "state",
                                ].map((item) => handleFocus(item));
                                // document.getElementById(
                                //   "userFullName"
                                // ).innerHTML = userData.fNameRef;
                                // phoneRef.current.value = userData.phoneRef;
                                // phoneRef.current.disabled = true;
                                setTimeout(() => {
                                  // fNameRef.current.value = userData.fNameRef;
                                  // emailRef.current.value = userData.emailRef;
                                  fNameRef.current.disabled = true;
                                  countryRef.current.disabled = true;
                                  cityRef.current.disabled = true;
                                }, 100);
                                setErrors({
                                  isEmailUnique: false,
                                  isMobileUnique: false,
                                });
                                setDefaultEmail(userData.emailRef);
                                setName(userData.fNameRef);
                                setMobile(userData.phoneRef);
                                setCountry(userData.Country);
                                setState(userData.State);
                                setCity(userData.City);
                                setProfilePic(userData.ProfilePic);
                                setLocationSelected({
                                  isCountrySelected: false,
                                  isStateSelected: false,
                                  isCitySelected: false,
                                });
                                setLocationData({
                                  city: userData?.city,
                                  country: [],
                                  state: [],
                                  citySuggestion: [],
                                });
                                setLocationLoading({
                                  country: false,
                                  state: false,
                                  city: false,
                                });
                                setTimeout(() => {
                                  firstLoad = false;
                                }, 2000);
                              }}
                            />
                          )}

                          <Button
                            name={isEditing ? "Save" : "Edit Profile"}
                            type="gradient"
                            style={{
                              background: isSuspended() ? "gray" : null,
                            }}
                            onClick={() => {
                              if (isSuspended()) return;
                              if (isEditing) {
                                postUserData();
                              } else {
                                type_ = "edit";
                                setConfirmModal(true);
                              }
                            }}
                            disabled={isSuspended()}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* {_data.roles?.includes("user")} */}
                  {Upgrade?.hide && (
                    <div className="role_selector">
                      <p className="text">{upgradeCard?.header}</p>
                      <p className="sub-text">{upgradeCard?.subheader}</p>
                      <Button
                        name={"Upgrade Now"}
                        style={{
                          background: isSuspended() ? "gray" : null,
                        }}
                        disabled={isSuspended()}
                        onClick={() => {
                          if (isSuspended()) return;
                          if (Upgrade.type === "manufacturer") {
                            setManufModal(true);
                            console.log("OPEN MANU MODAL");
                          } else if (Upgrade.type === "developer") {
                            setDevModal(true);
                            console.log("OPEN DEV MODAL");
                          } else if (Upgrade.type === "both") {
                            console.log("OPEN BOTH MODAL");
                            setBoth(true);
                          }
                        }}
                      />
                      {mask1()}
                      {mask2()}
                      {mask3()}
                    </div>
                  )}
                  <div className="recent_activity">
                    <h1>Recent Activity</h1>
                    <div className="activities_wrapper">
                      <Scroller autoHeightMax="8vw">
                        {Activity?.map((item) => (
                          <div className="activities">
                            <div id="circle" />
                            <div className="msg_wrapper">
                              <div>
                                <h2>{getDateTime(item.created)}</h2>
                                <p>{item.activity}</p>
                              </div>
                            </div>
                          </div>
                        ))}
                      </Scroller>
                    </div>
                  </div>
                </div>
                {!LoadingScreen && (
                  <div className="bottom">
                    <Scroller autoHeightMax="30vw" autoHeightMin="15vw">
                      <div className="grid_box">
                        <InputBox
                          id="email"
                          header="Email"
                          children={
                            !isEditing &&
                            !isSuspended() &&
                            editIcon(() => {
                              if (isSuspended()) return;
                              type_ = "email";
                              setConfirmModal(true);
                            })
                          }
                          disabled
                          // ref={emailRef}
                          onFocus={() => handleFocus("email")}
                          value={DefaultEmail}
                        />
                        <InputBox
                          id="fullName"
                          header="Full Name"
                          ref={fNameRef}
                          value={Name}
                          onFocus={() => handleFocus("fullName")}
                          disabled
                          onChange={(e) => {
                            setName(e.target.value);
                          }}
                        />
                        <PhoneInputBox
                          id="mobile_number"
                          isEdit={true}
                          onChange={(data) => {
                            // if (data.inputNumber) {
                            let _number =
                              data.countryCode + " " + data.inputNumber;
                            setMobile(_number);
                            // }
                          }}
                          onFocus={(e) => {
                            clearError2("isMobileEmpty");
                            handleFocus("mobile_number");
                          }}
                          value={Mobile}
                          disabled={!isEditing}
                          helperText={
                            errors["isMobileUnique"] && "Already used"
                          }
                        />
                        {/* <InputBox
                        id="phone"
                        header="Phone"
                        onChange={(e) => {
                          // const value = e.target.value;
                          // const regex = /^[a-zA-Z ]*$/;
                          // if (value.match(regex) || value === "") {
                          //   setFullName(value);
                          // }
                        }}
                        onFocus={() => handleFocus("phone")}
                        disabled
                        ref={phoneRef}
                      /> */}
                        <div id="_country_">
                          <InputBox
                            id="country"
                            header="Country"
                            onChange={(e) => {
                              const value = e.target.value;
                              const regex = /^[a-zA-Z ]*$/;
                              if (value.match(regex) || value === "") {
                                values.country = null;
                                setCountry(value);
                                setLocationLoading((prevState) => ({
                                  ...prevState,
                                  country: true,
                                }));
                              } else {
                                setLocationLoading((prevState) => ({
                                  ...prevState,
                                  country: false,
                                }));
                              }
                            }}
                            disabled
                            onKeyDown={getKeyCode}
                            error={errors["isCountryEmpty"]}
                            value={Country}
                            onFocus={() => {
                              clearError("isCountryEmpty");
                              handleFocus("country");
                            }}
                            ref={countryRef}
                          />
                          {LocationLoading.country &&
                          Country &&
                          !isDataEmpty ? (
                            <div className="suggestions">
                              <LoadingText />
                            </div>
                          ) : (
                            LocationData.country.length > 0 &&
                            !LocationSelected.isCountrySelected && (
                              <div className="suggestions">
                                <Scrollbars autoHeight autoHeightMax="15vh">
                                  <span className="_span" />
                                  {LocationData.country.map((item) => (
                                    <div
                                      key={item.country}
                                      className="suggestion_item"
                                      data-country={item.country}
                                      onClick={() => {
                                        isExact = true;
                                        isDataEmpty = null;
                                        values.country = item.country;
                                        setCountry(item.country);
                                        setLocationSelected((prevState) => ({
                                          ...prevState,
                                          isCountrySelected: true,
                                        }));
                                        setLocationLoading((prevState) => ({
                                          ...prevState,
                                          state: true,
                                        }));
                                      }}
                                    >
                                      {item.country}
                                    </div>
                                  ))}
                                </Scrollbars>
                              </div>
                            )
                          )}
                        </div>
                        <div id="_country_">
                          <InputBox
                            id="state"
                            header="State"
                            disabled={!LocationSelected.isCountrySelected}
                            onChange={(e) => {
                              const value = e.target.value;
                              const regex = /^[a-zA-Z ]*$/;
                              if (value.match(regex) || value === "") {
                                values.state = null;
                                setState(value);
                                setLocationLoading((prevState) => ({
                                  ...prevState,
                                  state: true,
                                }));
                              } else {
                                setLocationLoading((prevState) => ({
                                  ...prevState,
                                  state: false,
                                }));
                              }
                            }}
                            error={errors["isStateEmpty"]}
                            ref={stateRef}
                            value={State}
                            onFocus={() => {
                              clearError("isStateEmpty");
                              handleFocus("state");
                            }}
                          />
                          {LocationLoading.state && !isDataEmpty ? (
                            <div className="suggestions">
                              <LoadingText />
                            </div>
                          ) : (
                            LocationData.state.length > 0 &&
                            !LocationSelected.isStateSelected && (
                              <div className="suggestions">
                                <Scrollbars autoHeight autoHeightMax="15vh">
                                  {LocationData.state.map((item) => (
                                    <div
                                      key={item.state}
                                      className="suggestion_item"
                                      data-state={item.state}
                                      onClick={() => {
                                        values.state = item.state;
                                        isExact = true;
                                        isDataEmpty = null;
                                        clearError("isStateEmpty");
                                        handleFocus("state");
                                        setState(item.state);
                                        setLocationSelected((prevState) => ({
                                          ...prevState,
                                          isStateSelected: true,
                                        }));
                                      }}
                                    >
                                      {item.state}
                                    </div>
                                  ))}
                                </Scrollbars>
                              </div>
                            )
                          )}
                        </div>

                        <div id="_country_">
                          <InputBox
                            id="city"
                            header="City"
                            onKeyDown={getKeyCode}
                            disabled={
                              !LocationSelected.isStateSelected || isCityEmpty
                            }
                            onChange={(e) => {
                              const value = e.target.value;
                              const regex = /^[a-zA-Z ]*$/;
                              if (value.match(regex) || value === "") {
                                setCity(value);
                                const found = LocationData?.city?.filter((e) =>
                                  e.toLowerCase().includes(value.toLowerCase())
                                );
                                setLocationData((prevState) => ({
                                  ...prevState,
                                  citySuggestion: [...found],
                                }));
                              }
                            }}
                            error={errors["isCityEmpty"]}
                            ref={cityRef}
                            value={City}
                            onFocus={() => {
                              clearError("isCityEmpty");
                              handleFocus("city");
                            }}
                          />
                          {LocationLoading?.city && !isDataEmpty ? (
                            <div className="suggestions">
                              <LoadingText />
                            </div>
                          ) : (
                            LocationData?.citySuggestion.length > 0 && (
                              <div className="suggestions">
                                <Scrollbars
                                  autoHeight
                                  autoHeightMax="15vh"
                                  ref={cityScrollRef}
                                >
                                  <span className="_span" />
                                  {LocationData?.citySuggestion.map((item) => (
                                    <div
                                      key={item}
                                      className="suggestion_item"
                                      data-city={item}
                                      onClick={() => {
                                        isDataEmpty = null;
                                        setCity(item);
                                        setLocationSelected((prevState) => ({
                                          ...prevState,
                                          isCitySelected: true,
                                        }));
                                        setLocationData((prevState) => ({
                                          ...prevState,
                                          citySuggestion: [],
                                        }));
                                      }}
                                    >
                                      {item}
                                    </div>
                                  ))}
                                </Scrollbars>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    </Scroller>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Navbar>
      <DeveloperModal
        DevModal={DevModal}
        setDevModal={setDevModal}
        setLoadingScreen={setLoadingScreen}
        setShowAlert={setShowAlert}
        handleAlert={(bool, data) => {
          if (bool) {
            msg = { ...data };
            setShowAlert(bool);
          }
        }}
      />
      <ManufacturerModal
        ManufModal={ManufModal}
        setManufModal={setManufModal}
        setLoadingScreen={setLoadingScreen}
        setShowAlert={setShowAlert}
        handleAlert={(bool, data) => {
          if (bool) {
            msg = { ...data };
            setShowAlert(bool);
          }
        }}
      />
      <AnimatedModal modalOpen={Both} className="both_modal_adjust">
        <div
          className="close_otp_modal"
          onClick={() => {
            setBoth(false);
          }}
        >
          {closeIcon()}
        </div>
        <p className="continue_msg" style={{ marginTop: "1vw" }}>
          Before you continue..
        </p>
        <p className="continue_msg">
          Make your selection from the options below
        </p>
        <div className="_flex">
          <div className="image_wrapper">
            <div
              className="hover_wrapper"
              onClick={() => {
                setBoth(false);
                setDevModal(true);
              }}
            >
              <div className="img_bg" />
              <img src={devIcon} alt="cctv" className="continue_img" />
            </div>
            <p className="title">Developer</p>
          </div>
          <div className="image_wrapper">
            <div
              className="hover_wrapper"
              onClick={() => {
                setBoth(false);
                setManufModal(true);
              }}
            >
              <div className="img_bg" />
              <img src={manuIcon} alt="nvr" className="continue_img" />
            </div>
            <p className="title">Manufacturer</p>
          </div>
        </div>
      </AnimatedModal>
      <ConfirmPasswordModal
        setConfirmModal={setConfirmModal}
        ConfirmModal={ConfirmModal}
        setShowOTP={setShowOTP}
        handleAlert={(bool, data) => {
          if (bool) {
            msg = { ...data };
            setShowAlert(bool);
          }
        }}
        setShowEmailInputModal={setShowEmailInputModal}
        type={type_}
        handleEnableValue={(pass) => {
          setTimeout(() => {
            userData = {
              fNameRef: Name,
              emailRef: DefaultEmail,
              phoneRef: Mobile,
              Country,
              State,
              City,
              ProfilePic,
              city: LocationData?.city,
            };
          }, 100);
          // phoneRef.current.disabled = false;
          console.log(userData);
          _pass = pass;
          isEditing = true;
          fNameRef.current.disabled = false;
          countryRef.current.disabled = false;
          cityRef.current.disabled = false;
          setLocationSelected((prevState) => ({
            ...prevState,
            isStateSelected: true,
            isCountrySelected: true,
          }));
        }}
        handleEnableEmail={(data) => {
          userData = {
            fNameRef: Name,
            emailRef: DefaultEmail,
            phoneRef: Mobile,
            Country,
            State,
            City,
            ProfilePic,
            city: LocationData?.city,
          };
          _pass = data;
          setShowEmailInputModal(true);
        }}
        setShowResetPassword={setShowResetPassword}
      />
      <AnimatedModal
        modalOpen={ShowEmailInputModal}
        className="confirm_password_wrapper"
      >
        <div
          className="close_otp_modal"
          onClick={() => {
            setShowEmailInputModal(false);
            setEmail("");
            setErrors({
              isEmailUnique: false,
              isMobileUnique: false,
            });
          }}
        >
          {closeIcon()}
        </div>
        <p className="sub">New Email Address</p>
        <InputBox
          id="newEmail"
          header="Email"
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          value={Email}
          onKeyDown={(e) => {
            if (e.keyCode === 13) {
              if (emailValidation(Email) && !errors.isEmailUnique) {
                sendOTP();
              }
            }
          }}
          // error={errors["isEmailEmpty"]}
          helperText={errors["isEmailUnique"] && "Already used"}
          autoFocus
        />
        <Button
          name="Confirm"
          disabled={!emailValidation(Email) || errors.isEmailUnique}
          onClick={sendOTP}
        />
      </AnimatedModal>
      <ResetPassword
        oldPassBolean
        ShowResetPassword={ShowResetPassword}
        handlePassword={(data, oldData) => {
          postResetUserPassword(data, oldData);
        }}
        setConfirm={setConfirm}
      />
      <AnimatedModal modalOpen={ShowOTP} className="otp_modal_adjust">
        <OTP
          showOTP={ShowOTP}
          setShowOTP={setShowOTP}
          postVerifyOTP={(otp_) => {
            postVerifyOTP(otp_);
          }}
          setOTPCloseConfirm={setOTPCloseConfirm}
          postResendOTP={postResendOTP}
          resendFunc={(func) => {
            resetCounter = func;
            console.log(func);
          }}
          _stop={Stop}
          setStop={setStop}
        />
      </AnimatedModal>
      <AnimatedModal
        modalOpen={OTPCloseConfirm}
        onConfirm={() => {
          setName(userData.fNameRef);
          // fNameRef.current.value = userData.fNameRef;
          // emailRef.current.value = userData.emailRef;
          setDefaultEmail(userData.emailRef);
          setOTPCloseConfirm(false);
          setShowOTP(false);
          encryptStorage.removeItem("VID");

          //call api and then reset the URL and reset the password field on 200
          // var url = new URL(window.location.href);
          // const params = new URLSearchParams(window.location.search);
          // params.delete("token");
          // var url = window.location.pathname;
          // window.history.pushState(null, null, url);
          // setConfirm(false);
          // setPassword("");
          // setShowResetPassword(false);
        }}
        handleClose={() => {
          setOTPCloseConfirm(false);
        }}
        type={"confirm"}
        errorText={"Are you sure you want to close?"}
        alwayOpen={true}
      />
      <AnimatedModal
        modalOpen={Confirm}
        onConfirm={() => {
          setConfirm(false);
          setShowResetPassword(false);
        }}
        handleClose={() => {
          setConfirm(false);
        }}
        type={"confirm"}
        errorText={"Are you sure you want to close?"}
        alwayOpen={true}
      />
      <AnimatedModal
        modalOpen={ShowAlert}
        handleClose={() => {
          if (msg.errorText === "Updated Successfully") {
            window.location.reload();
          } else {
            setShowAlert(false);
          }
        }}
        type={msg.type}
        errorHeader={msg.header}
        errorText={msg.errorText}
        // timeout={5000}
        alwayOpen={true}
      />
      {LoadingScreen && <Loading type={"transparent"} text={"Loading"} />}
    </div>
  );
}

const profileIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18.701}
    height={17.034}
    viewBox="0 0 18.701 17.034"
    className="profileIcon"
    // style={{ display: "block", margin: "0,auto" }}
  >
    <path
      id="add_a_photo_FILL1_wght400_GRAD0_opsz48"
      d="M2.966,20.684a1.215,1.215,0,0,1-.887-.38,1.215,1.215,0,0,1-.38-.887V8.589a1.227,1.227,0,0,1,.38-.876,1.2,1.2,0,0,1,.887-.39h3.1L7.61,5.486h5.91V8.019h2.512v2.533h2.554v8.865a1.2,1.2,0,0,1-.39.887,1.227,1.227,0,0,1-.876.38ZM10.143,17.6a3.533,3.533,0,0,0,3.588-3.588,3.516,3.516,0,0,0-3.588-3.588,3.5,3.5,0,0,0-3.588,3.588A3.516,3.516,0,0,0,10.143,17.6Zm0-1.266a2.24,2.24,0,0,1-1.667-.665,2.317,2.317,0,0,1,1.667-3.979,2.268,2.268,0,0,1,1.657.654,2.41,2.41,0,0,1,0,3.324A2.249,2.249,0,0,1,10.143,16.335ZM17.3,8.568V6.753H15.483V5.486H17.3V3.65h1.266V5.486H20.4V6.753H18.565V8.568Z"
      transform="translate(-1.7 -3.65)"
      fill="rgba(255,255,255,0.75)"
    />
  </svg>
);

const mask1 = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={206.515}
    height={157.291}
    viewBox="0 0 206.515 157.291"
    className="mask1"
  >
    <defs>
      <linearGradient
        id="mask1-gradient"
        x1={0.5}
        x2={0}
        y2={0.842}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#fff" stopOpacity={0.6} />
        <stop offset={1} stopColor="#fff" stopOpacity={0} />
      </linearGradient>
    </defs>
    <ellipse
      id="Ellipse_5"
      data-name="Ellipse 5"
      cx={103.258}
      cy={78.646}
      rx={103.258}
      ry={78.646}
      fill="url(#mask1-gradient)"
    />
  </svg>
);

const mask2 = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={36.469}
    height={13.312}
    viewBox="0 0 36.469 13.312"
    className="mask2"
  >
    <path
      id="Vector"
      d="M2.13,1.4a1.269,1.269,0,0,1-1.065,1.4A1.269,1.269,0,0,1,0,1.4,1.269,1.269,0,0,1,1.065,0,1.269,1.269,0,0,1,2.13,1.4Z"
      transform="translate(36.469 11.182) rotate(90)"
      fill="#fff"
    />
    <path
      id="Vector-2"
      data-name="Vector"
      d="M2.13,1.4a1.269,1.269,0,0,1-1.065,1.4A1.269,1.269,0,0,1,0,1.4,1.269,1.269,0,0,1,1.065,0,1.269,1.269,0,0,1,2.13,1.4Z"
      transform="translate(36.469 5.591) rotate(90)"
      fill="#fff"
    />
    <path
      id="Vector-3"
      data-name="Vector"
      d="M2.13,1.4a1.269,1.269,0,0,1-1.065,1.4A1.269,1.269,0,0,1,0,1.4,1.269,1.269,0,0,1,1.065,0,1.269,1.269,0,0,1,2.13,1.4Z"
      transform="translate(36.469) rotate(90)"
      fill="#fff"
    />
    <path
      id="Vector-4"
      data-name="Vector"
      d="M2.13,1.4a1.269,1.269,0,0,1-1.065,1.4A1.269,1.269,0,0,1,0,1.4,1.269,1.269,0,0,1,1.065,0,1.269,1.269,0,0,1,2.13,1.4Z"
      transform="translate(28.053 11.182) rotate(90)"
      fill="#fff"
    />
    <path
      id="Vector-5"
      data-name="Vector"
      d="M2.13,1.4a1.269,1.269,0,0,1-1.065,1.4A1.269,1.269,0,0,1,0,1.4,1.269,1.269,0,0,1,1.065,0,1.269,1.269,0,0,1,2.13,1.4Z"
      transform="translate(28.053 5.591) rotate(90)"
      fill="#fff"
    />
    <path
      id="Vector-6"
      data-name="Vector"
      d="M2.13,1.4a1.269,1.269,0,0,1-1.065,1.4A1.269,1.269,0,0,1,0,1.4,1.269,1.269,0,0,1,1.065,0,1.269,1.269,0,0,1,2.13,1.4Z"
      transform="translate(28.053) rotate(90)"
      fill="#fff"
    />
    <path
      id="Vector-7"
      data-name="Vector"
      d="M2.13,1.4a1.269,1.269,0,0,1-1.065,1.4A1.269,1.269,0,0,1,0,1.4,1.269,1.269,0,0,1,1.065,0,1.269,1.269,0,0,1,2.13,1.4Z"
      transform="translate(19.637 11.182) rotate(90)"
      fill="#fff"
    />
    <path
      id="Vector-8"
      data-name="Vector"
      d="M2.13,1.4a1.269,1.269,0,0,1-1.065,1.4A1.269,1.269,0,0,1,0,1.4,1.269,1.269,0,0,1,1.065,0,1.269,1.269,0,0,1,2.13,1.4Z"
      transform="translate(19.637 5.591) rotate(90)"
      fill="#fff"
    />
    <path
      id="Vector-9"
      data-name="Vector"
      d="M2.13,1.4a1.269,1.269,0,0,1-1.065,1.4A1.269,1.269,0,0,1,0,1.4,1.269,1.269,0,0,1,1.065,0,1.269,1.269,0,0,1,2.13,1.4Z"
      transform="translate(19.637) rotate(90)"
      fill="#fff"
    />
    <path
      id="Vector-10"
      data-name="Vector"
      d="M2.13,1.4a1.269,1.269,0,0,1-1.065,1.4A1.269,1.269,0,0,1,0,1.4,1.269,1.269,0,0,1,1.065,0,1.269,1.269,0,0,1,2.13,1.4Z"
      transform="translate(11.221 11.182) rotate(90)"
      fill="#fff"
    />
    <path
      id="Vector-11"
      data-name="Vector"
      d="M2.13,1.4a1.269,1.269,0,0,1-1.065,1.4A1.269,1.269,0,0,1,0,1.4,1.269,1.269,0,0,1,1.065,0,1.269,1.269,0,0,1,2.13,1.4Z"
      transform="translate(11.221 5.591) rotate(90)"
      fill="#fff"
    />
    <path
      id="Vector-12"
      data-name="Vector"
      d="M2.13,1.4a1.269,1.269,0,0,1-1.065,1.4A1.269,1.269,0,0,1,0,1.4,1.269,1.269,0,0,1,1.065,0,1.269,1.269,0,0,1,2.13,1.4Z"
      transform="translate(11.221) rotate(90)"
      fill="#fff"
    />
    <path
      id="Vector-13"
      data-name="Vector"
      d="M2.13,1.4a1.269,1.269,0,0,1-1.065,1.4A1.269,1.269,0,0,1,0,1.4,1.269,1.269,0,0,1,1.065,0,1.269,1.269,0,0,1,2.13,1.4Z"
      transform="translate(2.805 11.182) rotate(90)"
      fill="#fff"
    />
    <path
      id="Vector-14"
      data-name="Vector"
      d="M2.13,1.4a1.269,1.269,0,0,1-1.065,1.4A1.269,1.269,0,0,1,0,1.4,1.269,1.269,0,0,1,1.065,0,1.269,1.269,0,0,1,2.13,1.4Z"
      transform="translate(2.805 5.591) rotate(90)"
      fill="#fff"
    />
    <path
      id="Vector-15"
      data-name="Vector"
      d="M2.13,1.4a1.269,1.269,0,0,1-1.065,1.4A1.269,1.269,0,0,1,0,1.4,1.269,1.269,0,0,1,1.065,0,1.269,1.269,0,0,1,2.13,1.4Z"
      transform="translate(2.805) rotate(90)"
      fill="#fff"
    />
  </svg>
);

const mask3 = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={109.64}
    height={62.928}
    viewBox="0 0 109.64 62.928"
    className="mask3"
  >
    <path
      id="Vector"
      d="M48.342,2.42c0,1.337-1.42,2.42-3.171,2.42S42,3.757,42,2.42,43.42,0,45.171,0,48.342,1.084,48.342,2.42Z"
      transform="translate(-8.702)"
      fill="rgba(255,255,255,0.15)"
    />
    <path
      id="Vector-2"
      data-name="Vector"
      d="M27.342,2.42c0,1.337-1.42,2.42-3.171,2.42S21,3.757,21,2.42,22.42,0,24.171,0,27.342,1.084,27.342,2.42Z"
      transform="translate(-4.351)"
      fill="rgba(255,255,255,0.15)"
    />
    <path
      id="Vector-3"
      data-name="Vector"
      d="M6.342,2.42c0,1.337-1.42,2.42-3.171,2.42S0,3.757,0,2.42,1.42,0,3.171,0,6.342,1.084,6.342,2.42Z"
      fill="rgba(255,255,255,0.15)"
    />
    <path
      id="Vector-4"
      data-name="Vector"
      d="M48.342,26.42c0,1.337-1.42,2.42-3.171,2.42S42,27.757,42,26.42,43.42,24,45.171,24,48.342,25.084,48.342,26.42Z"
      transform="translate(-8.702 -9.478)"
      fill="rgba(255,255,255,0.15)"
    />
    <path
      id="Vector-5"
      data-name="Vector"
      d="M27.342,26.42c0,1.337-1.42,2.42-3.171,2.42S21,27.757,21,26.42,22.42,24,24.171,24,27.342,25.084,27.342,26.42Z"
      transform="translate(-4.351 -9.478)"
      fill="rgba(255,255,255,0.15)"
    />
    <path
      id="Vector-6"
      data-name="Vector"
      d="M6.342,26.42c0,1.337-1.42,2.42-3.171,2.42S0,27.757,0,26.42,1.42,24,3.171,24,6.342,25.084,6.342,26.42Z"
      transform="translate(0 -9.478)"
      fill="rgba(255,255,255,0.15)"
    />
    <path
      id="Vector-7"
      data-name="Vector"
      d="M48.342,50.42c0,1.337-1.42,2.42-3.171,2.42S42,51.757,42,50.42,43.42,48,45.171,48,48.342,49.084,48.342,50.42Z"
      transform="translate(-8.702 -18.956)"
      fill="rgba(255,255,255,0.15)"
    />
    <path
      id="Vector-8"
      data-name="Vector"
      d="M27.342,50.42c0,1.337-1.42,2.42-3.171,2.42S21,51.757,21,50.42,22.42,48,24.171,48,27.342,49.084,27.342,50.42Z"
      transform="translate(-4.351 -18.956)"
      fill="rgba(255,255,255,0.15)"
    />
    <path
      id="Vector-9"
      data-name="Vector"
      d="M6.342,50.42c0,1.337-1.42,2.42-3.171,2.42S0,51.757,0,50.42,1.42,48,3.171,48,6.342,49.084,6.342,50.42Z"
      transform="translate(0 -18.956)"
      fill="rgba(255,255,255,0.15)"
    />
    <path
      id="Vector-10"
      data-name="Vector"
      d="M48.342,74.42c0,1.337-1.42,2.42-3.171,2.42S42,75.757,42,74.42,43.42,72,45.171,72,48.342,73.084,48.342,74.42Z"
      transform="translate(-8.702 -28.435)"
      fill="rgba(255,255,255,0.15)"
    />
    <path
      id="Vector-11"
      data-name="Vector"
      d="M27.342,74.42c0,1.337-1.42,2.42-3.171,2.42S21,75.757,21,74.42,22.42,72,24.171,72,27.342,73.084,27.342,74.42Z"
      transform="translate(-4.351 -28.435)"
      fill="rgba(255,255,255,0.15)"
    />
    <path
      id="Vector-12"
      data-name="Vector"
      d="M6.342,74.42c0,1.337-1.42,2.42-3.171,2.42S0,75.757,0,74.42,1.42,72,3.171,72,6.342,73.084,6.342,74.42Z"
      transform="translate(0 -28.435)"
      fill="rgba(255,255,255,0.15)"
    />
    <path
      id="Vector-13"
      data-name="Vector"
      d="M48.342,98.42c0,1.337-1.42,2.42-3.171,2.42S42,99.757,42,98.42,43.42,96,45.171,96,48.342,97.084,48.342,98.42Z"
      transform="translate(61.298 -37.913)"
      fill="rgba(255,255,255,0.15)"
    />
    <path
      id="Vector-14"
      data-name="Vector"
      d="M27.342,98.42c0,1.337-1.42,2.42-3.171,2.42S21,99.757,21,98.42,22.42,96,24.171,96,27.342,97.084,27.342,98.42Z"
      transform="translate(65.649 -37.913)"
      fill="rgba(255,255,255,0.15)"
    />
    <path
      id="Vector1"
      d="M6.342,98.42c0,1.337-1.42,2.42-3.171,2.42S0,99.757,0,98.42,1.42,96,3.171,96,6.342,97.084,6.342,98.42Z"
      transform="translate(70 -37.913)"
      fill="rgba(255,255,255,0.15)"
    />
  </svg>
);
