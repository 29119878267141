import React from "react";
import "./tos.scss";
import Scroller from "../../components/Scroller/ScrollBar";
import { Link } from "react-router-dom";
export default function Privacy() {
  return (
    <div className="__tos_wrapper__">
      <div className="tos_body">
        <h1>
          <span>
            <Link to={"/"}>
              <BackIcon />
            </Link>
          </span>
          Terms and Conditions
        </h1>
        <hr style={{ marginBottom: "20px" }} />
        <Scroller autoHeightMax="75vh">
          <div className="data_block">
            <ol>
              <li className="parent">
                <h2>Introduction</h2>
                <ol className="child">
                  <li>
                    These Terms and Conditions ("Terms") govern your access to
                    and use of the AI Marketplace ("Marketplace"), an online
                    platform that enables users to purchase and developers to
                    upload AI models, IoT processing services, computer vision
                    models, and use cases. The Marketplace also facilitates the
                    integration of these services and products into clients'
                    systems by system integrators.
                  </li>
                  <li>
                    By accessing or using the Marketplace, you agree to be bound
                    by these Terms. If you do not agree with any part of these
                    Terms, you must immediately discontinue use of the
                    Marketplace.
                  </li>
                  <li>
                    These Terms apply to all users, developers, and system
                    integrators accessing the Marketplace.
                  </li>
                </ol>
              </li>

              <li className="parent">
                <h2>Definitions</h2>
                <ol className="child">
                  <li>
                    "Users" are individuals or entities who browse, purchase, or
                    otherwise interact with AI models, IoT processing services,
                    computer vision models, or use cases available on the
                    Marketplace.
                  </li>
                  <li>
                    "Developers" are individuals or entities who create and
                    upload AI models, IoT processing services, computer vision
                    models, or use cases to the Marketplace for sale or
                    distribution.
                  </li>
                  <li>
                    "System Integrators" are individuals or entities that
                    provide services related to integrating the AI models, IoT
                    processing services, computer vision models, or use cases
                    purchased from the Marketplace into their clients' systems
                    or infrastructure.
                  </li>
                </ol>
              </li>
              <li className="parent">
                <h2>Registration and Account Security</h2>
                <ol className="child">
                  <li>
                    In order to access certain features of the Marketplace, you
                    may be required to register for an account. When registering
                    for an account, you agree to provide accurate, current, and
                    complete information as prompted by the registration form.
                    You also agree to update your account information promptly
                    in case of any changes.
                  </li>
                  <li>
                    You are responsible for safeguarding the confidentiality of
                    your account credentials, including your username and
                    password. You must notify us immediately of any unauthorized
                    use of your account or any other breach of security.
                  </li>
                  <li>
                    You are solely responsible for all activities that occur
                    under your account, whether or not you have authorized such
                    activities.
                  </li>
                </ol>
              </li>
              <li className="parent">
                <h2> User Conduct</h2>
                <ol className="child">
                  <li>
                    As a user of the Marketplace, you agree to use the platform
                    in a lawful manner and comply with all applicable local,
                    state, national, and international laws and regulations.
                  </li>
                  <li>
                    {" "}
                    You may not use the Marketplace to:
                    <br />
                    <p>
                      a. Engage in any activity that is harmful, abusive,
                      harassing, threatening, defamatory, libelous, invasive of
                      another's privacy, or otherwise objectionable.
                    </p>
                    <p>
                      b. Violate any intellectual property rights, including
                      copyrights, trademarks, patents, or trade secrets.
                    </p>
                    <p>
                      c. Impersonate any person or entity or falsely state or
                      otherwise misrepresent your affiliation with a person or
                      entity.
                    </p>
                    <p>
                      d. Upload, post, transmit, or otherwise make available any
                      content containing viruses, malware, or any other harmful
                      or malicious code.
                    </p>
                    <p>
                      e. Attempt to gain unauthorized access to the Marketplace,
                      other user accounts, or any computer systems or networks
                      connected to the Marketplace.
                    </p>
                    <p>
                      f. Interfere with or disrupt the Marketplace or any
                      servers or networks connected to the Marketplace.
                    </p>
                    <p>
                      g. Use any automated means, including bots, crawlers, or
                      data mining tools, to access, collect, or scrape data from
                      the Marketplace, or otherwise interact with the
                      Marketplace, without our express written permission.
                    </p>
                  </li>
                  <li>
                    We reserve the right to take appropriate action, including
                    suspending or terminating your account, in response to any
                    violations of these Terms.
                  </li>
                </ol>
              </li>
              <li className="parent">
                <h2>Data Collection and Usage</h2>
                <ol className="child">
                  <li>
                    By using the Marketplace, you acknowledge and agree that we
                    may collect, store, and process device information (such as
                    RAM, CPU, GPU specifications), images from IP cameras, and
                    personal and financial information (such as name, address,
                    phone number, credit card information, and bank information)
                    in accordance with our Privacy Policy.
                  </li>
                  <li>
                    You represent and warrant that you have the necessary rights
                    and permissions to provide any data you submit to the
                    Marketplace, and you grant us a worldwide, non-exclusive,
                    royalty-free, sublicensable, and transferable license to
                    use, reproduce, distribute, and display such data for the
                    purposes of providing and improving the Marketplace.
                  </li>
                </ol>
              </li>
              <li className="parent">
                <h2> Developer Content</h2>
                <ol className="child">
                  <li>
                    Developers are solely responsible for the AI models, IoT
                    processing services, computer vision models, and use cases
                    they upload to the Marketplace. Developers must ensure that
                    their content does not infringe upon any intellectual
                    property rights, including but not limited to copyright,
                    trademark, and patent rights.
                  </li>
                  <li>
                    Developers agree not to upload content that is harmful,
                    offensive, or otherwise inappropriate. We reserve the right
                    to remove any content that we deem, in our sole discretion,
                    to be in violation of these Terms or applicable law.
                  </li>
                  <li>
                    Developers grant the Marketplace a worldwide, non-exclusive,
                    royalty-free, sublicensable, and transferable license to
                    use, reproduce, distribute, and display the content they
                    upload to the Marketplace for the purposes of providing and
                    promoting the Marketplace and its services.
                  </li>
                </ol>
              </li>
              <li className="parent">
                <h2> System Integrator Responsibilities</h2>
                <ol className="child">
                  <li>
                    System Integrators are responsible for properly integrating
                    the AI models, IoT processing services, computer vision
                    models, or use cases purchased from the Marketplace into
                    their clients' systems. System Integrators must comply with
                    all applicable laws and regulations and ensure that the
                    integration process does not violate any intellectual
                    property rights or create security vulnerabilities.
                  </li>
                  <li>
                    System Integrators shall indemnify and hold harmless the
                    Marketplace, its affiliates, and its licensors from and
                    against any and all claims, liabilities, damages, losses, or
                    expenses arising out of or related to the System
                    Integrators' services or any breach of these Terms by the
                    System Integrators.
                  </li>
                </ol>
              </li>
              <li className="parent">
                <h2>Intellectual Property Rights</h2>
                <ol className="child">
                  <li>
                    All intellectual property rights, including but not limited
                    to copyright, trademark, and patent rights, in the
                    Marketplace and its content belong to their respective
                    owners. You agree not to infringe upon any intellectual
                    property rights in your use of the Marketplace.
                  </li>
                  <li>
                    The Marketplace and its licensors retain all rights not
                    expressly granted under these Terms.
                  </li>
                </ol>
              </li>
              <li className="parent">
                <h2>Disclaimer of Warranties</h2>
                <ol className="child">
                  <li>
                    The Marketplace and its content are provided on an "as is"
                    and "as available" basis without warranties of any kind,
                    either express or implied. We disclaim all warranties,
                    including but not limited to, implied warranties of
                    merchantability, fitness for a particular purpose, and
                    non-infringement.
                  </li>
                  <li>
                    We do not warrant that the Marketplace will be error-free,
                    uninterrupted, secure, or available at all times, or that
                    the information provided on the Marketplace is accurate,
                    complete, or reliable.
                  </li>
                </ol>
              </li>
              <li className="parent">
                <h2> Limitation of Liability</h2>
                <ol className="child">
                  <li>
                    In no event shall the Marketplace, its affiliates, or its
                    licensors be liable for any direct, indirect, incidental,
                    special, consequential, or punitive damages arising out of
                    or in connection with your use of the Marketplace, its
                    content, or any products or services obtained through the
                    Marketplace, including but not limited to loss of data, loss
                    of profits, or loss of goodwill, even if we have been
                    advised of the possibility of such damages.
                  </li>
                </ol>
              </li>
              <li className="parent">
                <h2>Indemnification</h2>
                <ol className="child">
                  <li>
                    You agree to indemnify, defend, and hold harmless the
                    Marketplace, its affiliates, and its licensors from and
                    against any and all claims, liabilities, damages, losses, or
                    expenses, including reasonable attorneys' fees and costs,
                    arising out of or in any way connected with your access to
                    or use of the Marketplace, your submission of data to the
                    Marketplace, or any products or services obtained through
                    the Marketplace.
                  </li>
                </ol>
              </li>
              <li className="parent">
                <h2> Governing Law and Jurisdiction</h2>
                <ol className="child">
                  <li>
                    These Terms shall be governed by and construed in accordance
                    with the laws of India, without regard to its conflict of
                    law principles. You agree to submit to the exclusive
                    jurisdiction of the courts located in India, Maharashtra,
                    for the resolution of any disputes arising out of or
                    relating to these Terms or your use of the Marketplace.
                  </li>
                </ol>
              </li>
              <li className="parent">
                <h2> Termination</h2>
                <ol className="child">
                  <li>
                    We reserve the right to terminate or suspend your access to
                    the Marketplace at any time, with or without cause, and with
                    or without notice. Upon termination, your right to use the
                    Marketplace will immediately cease.
                  </li>
                  <li>
                    Any provisions of these Terms that, by their nature, should
                    survive termination shall survive termination, including,
                    without limitation, intellectual property rights,
                    disclaimers, indemnification, and limitations of liability.
                  </li>
                </ol>
              </li>
              <li className="parent">
                <h2>Changes to Terms and Conditions</h2>
                <ol className="child">
                  <li>
                    We reserve the right to modify or replace these Terms at any
                    time. If a revision is material, we will make reasonable
                    efforts to provide at least 30 days' notice prior to any new
                    terms taking effect. By continuing to access or use the
                    Marketplace after those revisions become effective, you
                    agree to be bound by the revised terms.
                  </li>
                </ol>
              </li>
              <li className="parent">
                <h2>Privacy</h2>
                <ol className="child">
                  <li>
                    Your use of the Marketplace is subject to our Privacy
                    Policy, which governs our collection and use of your
                    personal information, including data from IP cameras, device
                    information, and personal and financial information. Please
                    review our Privacy Policy to understand our practices.
                  </li>
                </ol>
              </li>
              <li className="parent">
                <h2>Contact Information</h2>
                <ol className="child">
                  <li>
                    If you have any questions about these Terms or the
                    Marketplace, please contact us at{" "}
                    <a href="mailto: info@easemyai.com">info@easemyai.com</a>
                  </li>
                </ol>
              </li>
            </ol>
          </div>
        </Scroller>
      </div>
    </div>
  );
}

export const BackIcon = (props) => (
  <svg
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 477.175 477.175"
    style={{
      enableBackground: "new 0 0 477.175 477.175",
    }}
    xmlSpace="preserve"
    className="backIcon"
    {...props}
  >
    <g>
      <path d="M145.188,238.575l215.5-215.5c5.3-5.3,5.3-13.8,0-19.1s-13.8-5.3-19.1,0l-225.1,225.1c-5.3,5.3-5.3,13.8,0,19.1l225.1,225 c2.6,2.6,6.1,4,9.5,4s6.9-1.3,9.5-4c5.3-5.3,5.3-13.8,0-19.1L145.188,238.575z" />
    </g>
  </svg>
);
