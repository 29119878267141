import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { ThemeContext } from "../../../../../../helper/context";
import {
  dots,
  doubleTick,
  infoIcon2,
  trash,
} from "../../../../../../helper/icons";
import { useHistory } from "react-router-dom";
import Button from "../../../../../../components/Button/Button";
import useClickOutside from "../../../../../../helper/useClickOutside";
import { encryptStorage } from "../../../../../../helper/storage";
let Role = [];

export default function AppVersionTable({
  serialNumber,
  app_name,
  showApprovalWrapper,
  setRejectVersion,
  openInfo,
  setOpenInfo,
  setApproveVersion,
  AppId,
  setClickedAppId,
  setOpenAddLicense,
  appThumbnail,
  item,
  ids,
  id,
  onDeleteClick,
}) {
  const history = useHistory();
  const primaryRef = useRef();
  const [isOpen, setisOpen] = useState(false);
  const primaryClose = useCallback(() => {
    console.log("primary");
    Object.keys(ids).map((item) => (ids[item] = false));
    document.getElementById(id).classList.remove("active");
    setTimeout(() => {
      setisOpen(false);
    }, 500);
  }, []);
  useClickOutside(primaryRef, primaryClose);
  useEffect(() => {
    console.log(ids, document.getElementById(id), isOpen);
    Object.keys(ids).forEach((item) => {
      if (item === id && Object.values(ids).includes(true)) {
        setTimeout(() => {
          document.getElementById(item)?.classList.add("active");
        }, 100);
        setisOpen(true);
      } else {
        document.getElementById(item)?.classList.remove("active");
      }
    });
  }, [isOpen]);

  let arr = [
    "activated",
    "failed",
    "under review",
    " action required",
    "uploaded",
  ];
  const formatDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [day, month, year].join("/");
  };
  const themeContext = useContext(ThemeContext);
  const getStatusColor = (value) => {
    switch (value) {
      case "activated":
        return "#00B52A";

      case "failed":
        return "#FE1A1A";

      case "under review":
        return "#2B0082";

      case "action require":
        return "#FF9900";

      case "uploaded":
        return "#25D431";
    }
  };
  const getStatusBgc = (value) => {
    switch (value) {
      case "activated":
        return "#E9FFEE";

      case "failed":
        return "#FE1A1A21";

      case "under review":
        return "#0617FF17";

      case "action require":
        return "#FFD78745";

      case "uploaded":
        return "#25D4311C";
    }
  };
  return (
    <div>
      <div
        className="addver_table "
        onClick={() => {
          let searchParam = `?app_name=${app_name}`;
          if (serialNumber) {
            searchParam += `&serial_number=${serialNumber}`;
          }
          searchParam += `&version_name=${item.version_name}`;
          history.push({
            pathname: "/apps/addedversion/versiondetails",
            search: searchParam,
          });
        }}
      >
        {
          themeContext.Role.current === "user" && (
            <div
              className="dots_svg"
              onClick={(e) => {
                e.stopPropagation();
                Object.keys(ids).forEach((item) => {
                  if (item === id) {
                    ids[item] = !ids[item];
                  } else {
                    ids[item] = false;
                  }
                });

                // setTimeout(() => {
                setisOpen(!isOpen);
                // }, 500);
              }}
            >
              {dots("dot_icon_active")}
              {isOpen && (
                <div className="open" id={id} ref={primaryRef}>
                  <p
                    onClick={(e) => {
                      console.log(AppId, "addlicense");
                      setClickedAppId(AppId.appId);
                      e.stopPropagation();
                      // setClickedAppName(appName);
                      // setClickedThumbnail(appThumbnail);
                      // setClickedId(AppId);
                      setOpenAddLicense(true);
                    }}
                    className="dots_options "
                  >
                    Add license
                  </p>
                  <p
                    className="dots_options"
                    onClick={(e) => {
                      let searchParam = `app_name=${app_name}`;
                      if (serialNumber) {
                        searchParam += `&serial_number=${serialNumber}`;
                      }
                      e.stopPropagation();
                      history.push({
                        pathname: "/apps/appsInventory",
                        search: searchParam,
                        state: { img: appThumbnail },
                      });
                    }}
                  >
                    Inventory
                  </p>
                </div>
              )}
            </div>
          )
          //   <AddLicenseVersion
          //     app_name={app_name}
          //     serialNumber={serialNumber}
          //     id={"version" + idx}
          //     openAddLicense={openAddLicense}
          //     setOpenAddLicense={setOpenAddLicense}
          //     appThumbnail={appThumbnail}
          //     AppId={item}
          //     setClickedAppId={setClickedAppId}
          //   />
        }

        {Object.keys(item).map((ele, i) => {
          if (ele === "appId") {
            return false;
          }
          return (
            <div className="addver_td">
              <div>
                <h4 className="addver_td_header">
                  {ele === "status" &&
                    themeContext.Role.current === "developer" && (
                      <h4 className="onhover_td">Action</h4>
                    )}
                  {ele.replace("_", " ")}
                </h4>

                <p
                  className={
                    arr.includes(item[ele])
                      ? "status_style addver_td_values "
                      : "addver_td_values"
                  }
                  style={{
                    color: getStatusColor(item[ele]),
                    backgroundColor: getStatusBgc(item[ele]),
                  }}
                >
                  {ele === "created_date" ? (
                    formatDate(item.created_date)
                  ) : ele === "version" ? (
                    item[ele]
                  ) : ele === "status" ? (
                    <p style={{ width: "5vw" }}>{item[ele]}</p>
                  ) : (
                    item[ele]
                  )}

                  {item[ele]?.includes("uploaded") && (
                    <div>
                      {doubleTick("addedver_doubletick")}
                      {themeContext.Role.current === "developer" && (
                        <div className="hoverData hoverDataGreen">
                          <div className="shadow  shadowGreen">
                            <div
                              onClick={(e) => {
                                onDeleteClick(e, item.version_name);
                              }}
                              className="material-icons upload_delete_icon"
                            >
                              {trash()}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                  {item[ele]?.includes("failed") && (
                    <div className="delete_info">
                      <div className="hoverData hoverDataRed">
                        <div className="shadow shadowRed" />
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                            setOpenInfo(!openInfo);
                          }}
                        >
                          {infoIcon2()}
                        </div>

                        <div className="shadow" />
                        <div
                          onClick={(e) => {
                            onDeleteClick(e, item.version_name);
                          }}
                          className="failed_delete_icon"
                        >
                          {trash()}
                        </div>
                      </div>
                    </div>
                  )}

                  {ele === "Version type" && item[ele] === null && "None"}
                  {ele === "version Name" && item[ele] === null && "None"}
                  {ele === "Status" && item[ele] === null && "None"}
                  {ele === "Created Date" && item[ele] === null && "None"}
                </p>

                {ele === "version_name" &&
                  themeContext.Role.current === "superadmin" &&
                  item.status === "uploaded" &&
                  showApprovalWrapper && (
                    <div className="approval_wrapper">
                      <Button
                        name={"Approve"}
                        style={{
                          backgroundColor: "#25D431",
                          color: "#ffff",
                        }}
                        btnImg={tick("approve_icon")}
                        onClick={(e) => {
                          e.stopPropagation();
                          setApproveVersion(true);
                        }}
                      />
                      <Button
                        name={"Reject"}
                        style={{
                          backgroundColor: "#FE1A1A21",
                          color: "#FE1A1A",
                        }}
                        btnImg={cross("reject_icon")}
                        onClick={(e) => {
                          e.stopPropagation();
                          setRejectVersion(true);
                        }}
                      />
                    </div>
                  )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
const tick = (classname) => (
  <svg
    fill="#fff"
    width={18}
    height={18}
    viewBox="0 0 24 24"
    baseProfile="tiny"
    xmlns="http://www.w3.org/2000/svg"
    className={classname}
  >
    <path d="M16.972 6.251c-.967-.538-2.185-.188-2.72.777l-3.713 6.682-2.125-2.125c-.781-.781-2.047-.781-2.828 0-.781.781-.781 2.047 0 2.828l4 4c.378.379.888.587 1.414.587l.277-.02c.621-.087 1.166-.46 1.471-1.009l5-9c.537-.966.189-2.183-.776-2.72z" />
  </svg>
);
const cross = (classname) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    className={classname}
    fill="#fff"
  >
    <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
  </svg>
);
