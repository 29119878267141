import React, {useContext, useState, useEffect, useRef } from "react";
import Navbar from "../../../components/Navbar/Navbar";
import "./order.scss";
import bg from "../../../assets/images/background.png";
import { notify } from "../../../Routes";
import { axiosApiInstance } from "../../../helper/request";
import Table from "../../../components/Table/Table";
import DateFilter from "./DateFilter";
import { getDateTime } from "../../../helper/storage";
import InputBox from "../../../components/Inputbox/InputBox";
import { searchIcon } from "../../../helper/icons";
import { useDebouncedEffect } from "../../../helper/useDebounce";
import OrderDetails from "./OrderDetails";
import Scroller from "../../../components/Scroller/ScrollBar";
import Pagination from "../../../components/Pagination/Pagination";
import { ThemeContext } from "../../../helper/context";
const header = ["Order  ID", "Plan Type", "Order Placed", "Status", "Amount"];
let isFirstLoad = false;
let dates = {
  from: null,
  to: null,
};
export default function Order() {
    const themeContext = useContext(ThemeContext);
  const [Search, setSearch] = useState("");
  const [CurrentPage, setCurrentPage] = useState(1);
  const [TotalPages, setTotalPages] = useState(0);
  useDebouncedEffect(() => getData(dates.from, dates.to, CurrentPage), [Search], 500);

  const [isLoading, setisLoading] = useState(true);
  const [Data, setData] = useState([]);
  const [Coupon, setCoupon] = useState({
    coupon: "",
    amount: "",
    id: "",
  });

  const getData = (from, to, page = 1) => {
    if (isFirstLoad) return;
    console.log(page, "pageeeeeeeeeeee");
    setisLoading(true);
    console.log("getData");
    axiosApiInstance
      .get(
        "order/history?field=" +
          Search +
          "&page=" + page + "&items=10&From=" +
          from +
          "&To=" +
          to
      )
      .then((res) => {
        let _totalPages = Math.ceil(res.data.total / 10);
        setTotalPages(_totalPages);
        setData([...res.data.data]);
      })
      .catch((err) => {
        console.log(err);
        // notify({});
      })
      .finally(() => {
        setisLoading(false);
      });
  };

  return (
    <div className="_order_wrapper_">
      {console.log(Search)}
      <Navbar sidenav>
        <div className="order_body">
          <img src={bg} className="profile_bg" />
          <div className="body_content fadeIn">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h1>My Orders</h1>
              <p className="goBack"></p>
            </div>
            <div className="order_data">
              <div className="filter_items">
                <div className="search_container">
                  <InputBox
                    id="search"
                    placeholder="Search "
                    onChange={(e) => setSearch(e.target.value)}
                    value={Search}
                    children={searchIcon()}
                  />
                </div>
                <DateFilter
                  id="order_filter_date"
                  handleValue={(startDate, endtDate) => {
                    getData(startDate, endtDate, CurrentPage);
                    if (!isFirstLoad) {
                      isFirstLoad = true;
                    }
                    setTimeout(() => {
                      isFirstLoad = false;
                    }, 1000);
                    dates = { ...dates, from: startDate, to: endtDate };
                  }}
                />
              </div>
              <div className="order_item">
                {Data.length > 0 && !isLoading ? (
                  <Scroller
                    autoHeightMax="57vh"
                    onScrollFrame={(d) => {
                      // console.log(d);
                    }}
                  >
                    <div className="order_list fadeIn">
                      {Data.map((item) => (
                        <OrderDetails data={item} />
                      ))}
                    </div>
                  </Scroller>
                ) : (
                  !isLoading && (
                    <div className="noData">
                      {/* {emptyCartIcon()} */}
                      {noOrderIcon()}
                    </div>
                  )
                )}
              </div>
              {Data.length > 0 && TotalPages > 1 && (
                <div
                  className="order_bottom"
                  // style={{
                  //   background:
                  //     themeContext.Theme === "Light"
                  //       ? "linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.6783088235294117) 100%)"
                  //       : "linear-gradient(0deg, rgba(41,49,58,1) 0%, rgba(41,49,58,0.5183088235294117) 100%)",
                  // }}
                >
                  <div style={{ width: "11.1979166667vw" }}></div>
                  {/* <Button
                    name={"Show More Devices"}
                    type="gradient"
                    bg={true}
                  /> */}
                  <Pagination
                    totPages={TotalPages}
                    // totPages={10}
                    currentPage={CurrentPage}
                    rowCount={10}
                    pageClicked={(ele, count_) => {
                      setCurrentPage(ele);
                      getData(dates.from, dates.to, ele)
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </Navbar>
    </div>
  );
}

const noOrderIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={118.623}
    height={100}
    viewBox="0 0 118.623 121.107"
    className="noOrderIcon"
  >
    <g
      id="Group_16009"
      data-name="Group 16009"
      transform="translate(-1093 -687)"
    >
      <g
        id="ssss_copy"
        data-name="ssss copy"
        transform="translate(192.918 207.461)"
      >
        <path
          id="Path_134015"
          data-name="Path 134015"
          d="M1016.734,562.794c-2.7-3.32-8.04-8.777-10.24-12.407a10,10,0,0,0-1.83-2.25c-2.815-3.242-5.122-3.784-10.434-2.2-4.078,1.213-1.636.383-12.845,3.832-5.78,1.274-12.046,3.272-18.095,4.695a5.429,5.429,0,0,0-3.3,3c-2.53,5.75-3.55,10.76-6.67,16.43.03-.58-1.518-.3-1.328-.818,1.45-3.85,2.72-7.8,4.52-11.49.27-.553.5-1.409.869-2.405.25-.671.644-1.542.928-2.437.892-3.231.6-6.139-.637-9.928a106.2,106.2,0,0,1-3.193-11.336c3.22-.56,6.923-1.344,9.963-1.924,3.6-.69,5.56-3.25,5.14-6.6-.38-3.1-2.9-5.15-6.41-4.86-3.45.29-6.87.89-10.58,1.39-.94-4.29-1.84-8.39-2.83-12.91,14.82-3.23,29.4-6.4,44.47-9.69,1.03,4.55,2.03,8.87,2.98,13.2,2.05,9.39,4,18.8,6.16,28.16a13.15,13.15,0,0,0,2.52,4.88c3.87,4.99,7.91,9.86,11.88,14.78C1017.42,562.25,1017.084,562.474,1016.734,562.794Z"
          fill="#295ee6"
        />
        <path
          id="Path_134016"
          data-name="Path 134016"
          d="M948.88,512.92c.73,3.31,1.35,6.07,2.03,9.13-2.67.51-5.11,1.4-7.5,1.29a6.891,6.891,0,0,1-4.5-2.5c-3.07-3.93-5.73-8.18-8.62-12.25-2.65-3.73-5.9-4.54-8.9-2.34-2.78,2.04-3.46,5.79-.63,8.87,5.21,5.66,5.79,10.82,1.72,17.7-3.92,6.61-3.56,7.61,2.97,11.84,12.09,7.83,19.51,18.36,22.12,32.54.83,4.51,2.71,8.81,4.06,13.23,1.41,4.59-.3,8.53-4.19,9.8-4.19,1.38-7.86-.73-9.42-5.55-2.72-8.43-5.26-16.92-8.13-25.3a12.716,12.716,0,0,0-3.65-5.34q-10.575-8.97-21.5-17.52c-3.94-3.06-5.19-6.95-4.46-11.56a61.3,61.3,0,0,1,14.15-30.53c4.65-5.5,13.75-7.32,19.31-.58a79.518,79.518,0,0,0,6.45,6.26C942.58,512.41,944.63,515.55,948.88,512.92Z"
        />
        <path
          id="Path_134017"
          data-name="Path 134017"
          d="M909.6,552.08c4.92,3.98,9.89,7.9,14.69,12.02a4.554,4.554,0,0,1,1,3.3c-.41,11.04-4.37,20.89-10.5,29.92a6.927,6.927,0,0,1-9.59,2c-3.32-2.13-4.1-6.13-2.28-9.88,2.54-5.24,5.38-10.43,7.1-15.95C912.16,566.59,911.51,559.46,909.6,552.08Z"
        />
        <path
          id="Path_134018"
          data-name="Path 134018"
          d="M968.39,528.91a4.348,4.348,0,0,1-3.71,3.14c-8.31,1.62-16.7,2.92-25.11,3.98-1.36.17-3.44-1.03-4.32-2.23-4.74-6.42-9.2-13.05-13.78-19.59-1.77-2.53-1.82-4.91.71-6.91,2.23-1.76,4.88-1.04,6.97,1.91,3.61,5.08,7.05,10.3,10.79,15.28a4.919,4.919,0,0,0,3.83,1.65c6.31-.75,12.58-1.87,18.87-2.81C965.87,522.86,968.29,524.74,968.39,528.91Z"
        />
        <path
          id="Path_134019"
          data-name="Path 134019"
          d="M944.32,505.15A12.643,12.643,0,0,1,931.7,492.2a12.823,12.823,0,0,1,12.99-12.66,13.021,13.021,0,0,1,12.7,13.02A12.841,12.841,0,0,1,944.32,505.15Z"
        />
        <path
          id="Path_134020"
          data-name="Path 134020"
          d="M986.13,578.11c1.11-.98,2.15-2.62,3.35-2.75,1.14-.12,2.72,1.13,3.55,2.2.41.52-.41,2.92-.75,2.94a33.239,33.239,0,0,1-5.59-.53C986.51,579.35,986.32,578.73,986.13,578.11Z"
          transform="translate(2.943 -20)"
        />
        <path
          id="Path_134021"
          data-name="Path 134021"
          d="M984.99,593.15c1.25.25,2.9.11,3.64.87.62.64.53,2.44.11,3.46a3.285,3.285,0,0,1-2.56.96,22.593,22.593,0,0,1-2.15-3.97Z"
        />
        <path
          id="Path_134022"
          data-name="Path 134022"
          d="M993.4,560.4c-.99-1.7-1.97-2.63-1.92-3.51.04-.85,1.15-1.64,1.78-2.46.8.75,2.18,1.44,2.25,2.25C995.59,557.58,994.49,558.6,993.4,560.4Z"
          transform="translate(-25 14.952)"
        />
        <path
          id="Path_134023"
          data-name="Path 134023"
          d="M978.31,563.4c-1.06,2.66-2.65,3.94-5.59,2.63C973.85,561.81,974.81,561.39,978.31,563.4Z"
          transform="translate(32 4)"
        />
      </g>
      <text
        id="No"
        transform="matrix(0.899, -0.438, 0.438, 0.899, 1155.963, 777.164)"
        fill="#e91313"
        fontSize={12}
        fontFamily="Swiss721BT-Heavy, Swis721 Hv BT"
        fontWeight={800}
      >
        <tspan x={0} y={0}>
          {"No"}
        </tspan>
      </text>
      <text
        id="order"
        transform="matrix(0.914, 0.407, -0.407, 0.914, 1176.596, 779.356)"
        fill="#e91313"
        fontSize={12}
        fontFamily="Swiss721BT-Heavy, Swis721 Hv BT"
        fontWeight={800}
      >
        <tspan x={0} y={0}>
          {"order"}
        </tspan>
      </text>
      <text
        id="found"
        transform="matrix(0.995, -0.105, 0.105, 0.995, 1156.098, 803.805)"
        fill="#e91313"
        fontSize={12}
        fontFamily="Swiss721BT-Heavy, Swis721 Hv BT"
        fontWeight={800}
      >
        <tspan x={0} y={0}>
          {"found"}
        </tspan>
      </text>
      <circle
        id="Ellipse_18451"
        data-name="Ellipse 18451"
        cx={1.5}
        cy={1.5}
        r={1.5}
        transform="translate(1144 696)"
        fill="#fff"
      />
      <line
        id="Line_340"
        data-name="Line 340"
        x1={4}
        y2={4}
        transform="translate(1141.5 692.5)"
        fill="none"
        stroke="#fff"
        strokeWidth={1}
      />
      <path
        id="Path_134024"
        data-name="Path 134024"
        d="M-22873.311,2668.136s-5.23-3.238-9.59,0"
        transform="translate(24022.605 -1963)"
        fill="none"
        stroke="#fff"
        strokeWidth={1}
      />
    </g>
  </svg>
);
