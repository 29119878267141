import React, { useState } from "react";
import Navbar from "../../../../components/Navbar/Navbar";
import "./home.scss";
import { Responsive, WidthProvider } from "react-grid-layout";
import StatsCard from "../../../../components/StatsCard/StatsCard";
import {
  barIcon,
  cartIcon,
  groupIcon,
  pieIcon,
} from "../../../../helper/icons";
import RegisteredUser from "./RegisteredUser";
import RequestUser from "./RequestUser";
const defaultProps = {
  cols: { lg: 12, md: 12, sm: 6, xs: 4, xxs: 2 },
  className: "layout",
  rowHeight: 50,
  isResizable: false,
  isDraggable: false,
  breakpoints: { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 },
  // containerPadding: [50, 50],
};
const GridLayout = WidthProvider(Responsive);

export default function SuperHome() {
  return (
    <div className="_home_wrapper_">
      <Navbar sidenav>
        {/* <main className="home_body">
          <GridLayout
            {...defaultProps}
            layouts={_storedLayouts}
            // onBreakpointChange={onBreakpointChange}
          >
            <div key={"card"} className="grid-style">
              <StatsCard
                name={"Total Devices Online"}
                value="20"
                image={barIcon}
                color="rgba(114, 191, 178,0.3)"
              />
            </div>
            <div key={"card2"} className="grid-style">
              <StatsCard
                name={"Total Users"}
                value="30"
                image={groupIcon}
                color="rgba(119, 156, 230,0.3)"
              />
            </div>
            <div key={"card3"} className="grid-style">
              <StatsCard
                name={"Total Usecases"}
                value="40"
                image={cartIcon}
                color="rgba(238, 128, 89,0.3)"
              />
            </div>
            <div key={"card4"}>
              <StatsCard
                name={"Total Device"}
                value="220"
                image={pieIcon}
                color="rgba(235, 96, 119,0.3)"
              />
            </div>
            <div key={"graph"} className="grid-style">
              <StatsCard
                name={"Total Device"}
                value="220"
                image={pieIcon}
                color="rgba(235, 96, 119,0.3)"
              />
            </div>
            <div key={"registeredUser"}>
              <RegisteredUser />
             
            </div>
            <div key={"request"}>
              <RequestUser />
            </div>
          </GridLayout>
        </main> */}
      </Navbar>
    </div>
  );
}

const _storedLayouts = {
  lg: [
    { i: "card", w: 3, h: 3, x: 0, y: 0 },
    { i: "card2", w: 3, h: 3, x: 3, y: 0 },
    { i: "card3", w: 3, h: 3, x: 6, y: 0 },
    { i: "card4", w: 3, h: 3, x: 9, y: 0 },
    { i: "graph", w: 8, h: 8, x: 0, y: 2 },
    { i: "registeredUser", w: 4, h: 8, x: 8, y: 2 },
    { i: "request", w: 7, h: 9, x: 0, y: 10 },
    { i: "powerOn", w: 1.5, h: 3, x: 6, y: 6 },
    { i: "powerOff", w: 1.5, h: 3, x: 7.5, y: 6 },
    { i: "network_speed", w: 3, h: 3, x: 6, y: 9 },
  ],
  md: [
    { i: "card", w: 3, h: 2, x: 6, y: 0 },
    { i: "card2", w: 3, h: 2, x: 9, y: 0 },
    { i: "card3", w: 3, h: 2, x: 6, y: 0 },
    { i: "card4", w: 3, h: 2, x: 9, y: 0 },
    { i: "graph", w: 4, h: 6, x: 0, y: 6 },
    { i: "registeredUser", w: 4, h: 6, x: 4, y: 6 },
    { i: "request", w: 4, h: 6, x: 0, y: 9 },
    { i: "powerOn", w: 2, h: 3, x: 8, y: 6 },
    { i: "powerOff", w: 2, h: 3, x: 10, y: 9 },
    { i: "upload", w: 2, h: 3, x: 8, y: 6 },
    { i: "download", w: 2, h: 3, x: 10, y: 9 },
    { i: "network_speed", w: 4, h: 3, x: 8, y: 9 },
  ],
  sm: [
    { i: "attendance", w: 6, h: 4, x: 0, y: 0 },
    { i: "notifications", w: 3, h: 2, x: 4, y: 0 },
    { i: "notifications2", w: 3, h: 2, x: 3, y: 0 },
    { i: "notifications3", w: 3, h: 2, x: 0, y: 2 },
    { i: "notifications4", w: 3, h: 2, x: 3, y: 2 },
    { i: "powerOn", w: 3, h: 3, x: 0, y: 0 },
    { i: "powerOff", w: 3, h: 3, x: 3, y: 0 },
    { i: "timeZone", w: 3, h: 6, x: 0, y: 0 },
    { i: "deviceLimitation", w: 3, h: 6, x: 3, y: 0 },
    { i: "upload", w: 3, h: 3, x: 8, y: 6 },
    { i: "download", w: 3, h: 3, x: 10, y: 9 },
    { i: "network", w: 3, h: 6, x: 0, y: 9 },
    { i: "network_speed", w: 3, h: 3, x: 6, y: 9 },
  ],
};
