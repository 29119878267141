import React from "react";
import { withRouter } from "react-router-dom";
import BasicDetails from "./BasicDetails";
import Compatibility from "./Compatibility";
import VersionSettings from "./VersionSettings";
import "../VersionDetails/VersionOptions.scss";
import { rightAngle } from "../../../../../helper/icons";
import double from "../../../../../assets/images/double.svg";

class VersionOptions extends React.Component {
  //   rightAngleToggler = () => {
  //   this.setState({isActive: !this.state.isActive})
  // }
  constructor(props) {
    super(props);
    // this.props.selected = BasicDetails
    this.state = {
      choices: {
        "Basic details": BasicDetails,
        "Compatibility details & dependent services": Compatibility,
        Settings: VersionSettings,
      },
      activeBasicDT: [BasicDetails, Compatibility, VersionSettings],
      active: "Basic details",
    };
  }

  render() {
    return (
      <div className="options_wrapper">
        {Object.keys(this.state.choices).map((x, i) => (
          <p
            className="options_tag"
            key={i}
            onClick={() => {
              this.props.setselected({ selected: this.state.choices[x] });
              this.setState({
                active: x,
              });
            }}
            style={{ color: this.state.active === x && "#285de4" }}
          >
            {x}
            {this.state.active === x && rightAngle("verdt_rightAngle")}
          </p>
        ))}
        {/* {this.state.active} */}
      </div>
    );
  }
}
export default withRouter(VersionOptions);
