import React, { useEffect, useRef, useState } from "react";
import Scroller from "../../../../../components/Scroller/ScrollBar";
import Searchbar from "../../../../../components/Searchbar/Searchbar";
import { closeIcon } from "../../../../../helper/icons";
import Button from "../../../../../components/Button/Button";
import { axiosApiInstance } from "../../../../../helper/request";
import { useDebouncedEffect } from "../../../../../helper/useDebounce";
import { LoadingText } from "../../../../auth/register/AuthRegister";
import { encryptStorage } from "../../../../../helper/storage";
import { AssignedUsersList } from "./AssignedUsersList/AssignedUsersList";
import AnimatedModal from "../../../../../components/Modal/AnimatedModal";
import "./AssignInventoryModal.scss";

// let msg = {
//   type: "alert",
//   header: "Error",
//   errorText: "Something Went Wrong",
// };

export default function AssignInventoryModal(props) {
  const childStateRef = useRef();
  let activeRoles = encryptStorage.getItem("UID").roles;
  const [userRole, setUserRole] = useState("manufacturer");
  const [searchText, setSearchText] = useState("");
  const [searchBarLoading, setSearchBarLoading] = useState(false);
  const [searchedUserList, setSearchedUserList] = useState([]);
  const [inventoryAssignedUserList, setInventoryAssignedUserList] = useState(
    {}
  );
  const [selectedUserList, setSelectedUserList] = useState({});
  const [showAlert, setShowAlert] = useState(false);
  const [disableSave, setDisableSave] = useState(true);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  useDebouncedEffect(
    () => (searchText ? fetchUserBasedOnRole() : undefined),
    [searchText],
    1000
  );

  const onSearchInput = (e) => {
    setSearchText(e.target.value);
    if (e.target.value === "") {
      setSearchedUserList([]);
    }
  };

  const onSearchFocus = (e) => {};

  const clearSearchStates = () => {
    setSearchText("");
    setSearchedUserList([]);
  };
  const clearStateList = () => {
    setInventoryAssignedUserList([]);
  };

  const onSearchedOptionClick = (details) => {
    setSelectedUserList((prevState) => {
      prevState[details["username"]] = {
        prev: 0,
        current: 0,
        total: 0,
      };
      return prevState;
    });
    clearSearchStates();
  };

  const removeUser = (username) => {
    setSelectedUserList((prevState) => {
      let _temp = { ...prevState };
      delete _temp[username];
      let arr = [];
      Object.keys(_temp).map((item) => {
        arr.push(Number(_temp[item]["current"]));
      });
      let sums = arr.reduce((partialSum, a) => partialSum + a, 0);
      props.setRemainingCount(props.availableCount - sums);
      sums > 0 ? setDisableSave(false) : setDisableSave(true);
      return _temp;
    });
  };

  const getAssignedUserList = (initial = false) => {
    let url = `inventory/manufacturer/distribution?role=${userRole}&license_name=${props.licenseName}&page=${page}&items=10`;
    if (userRole === "user") {
      url = `inventory/manufacturer/distribution?role=${userRole}&license_name=${props.licenseName}&page=${page}&items=10`;
    }
    axiosApiInstance
      .get(url)
      .then((data) => {
        let _temp = {};
        data.data.details.map((item) => {
          _temp[item.inventory_owner.username] = {
            prev: item["total_count"],
            current: 0,
            total: item["total_count"],
          };
        });
        setInventoryAssignedUserList((prevState) => {
          return initial && page === 1
            ? { ..._temp }
            : { ...prevState, ..._temp };
        });
        setTotalCount(data.data.total_count);
      })
      .catch((err) => {})
      .finally(() => {});
  };

  const fetchUserBasedOnRole = () => {
    let url = "user/superadmin/manufacturers?field=";
    if (userRole === "user") {
      url = "user/search/restricted/users?searchname=";
    }
    setSearchBarLoading(true);
    axiosApiInstance
      .get(url + searchText)
      .then((data) => {
        let _arr = [];
        data.data.details.forEach((element) => {
          if (
            Object.keys(inventoryAssignedUserList).includes(
              element.username
            ) === false &&
            Object.keys(selectedUserList).includes(element.username) === false
          ) {
            _arr.push({ username: element.username });
          }
        });
        setSearchedUserList(_arr);
      })
      .catch((err) => {})
      .finally(() => {
        setSearchBarLoading(false);
      });
  };

  const postUserInventoryCount = () => {
    const userList = childStateRef.current.getUserList();
    let arr = [];
    Object.keys(userList).forEach((item) => {
      if (userList[item]["current"] > 0)
        arr.push({
          username: item,
          count: userList[item]["current"],
        });
    });
    let url = ``;
    if (props.app_name !== undefined) {
      url = `inventory/assign?app_name=${props.app_name}&license_type=apps`;
    } else url = `inventory/assign`;
    axiosApiInstance
      .post(url, {
        license_name: props.licenseName,
        child_manufacturer: arr,
        status: false,
        role: userRole,
      })
      .then((res) => {
        props.msg.errorText = "Successfully Assigned";
        props.msg.header = "Success";
        props.msg.type = "success";
        props.setShowAlert(true);
        setTimeout(() => {
          props.setShowAlert(false);
        }, 3000);
        props.fetchInventory(1);
      })
      .catch((err) => {
        if (err.response.status) {
          props.msg.errorText = "Couldn't Assign";
          props.msg.header = "Error";
          props.msg.type = "alert";
          props.setShowAlert(true);
          setTimeout(() => {
            props.setShowAlert(false);
          }, 3000);
        }
      })
      .finally(() => {
        setSelectedUserList({});
        props.setOpenAssignInventoryModal(false);
        props.fetchLicense(1, "Special License");
      });
  };

  useEffect(() => {
    getAssignedUserList(true);
  }, [userRole, selectedUserList, page]);

  return (
    <div className="assign_modal">
      <div className="assign_mfr_wrapper">
        <div
          className="close_otp_modal"
          onClick={() => {
            props.setOpenAssignInventoryModal(false);
          }}
        >
          {closeIcon()}
        </div>
        <p className="assign_label">
          {userRole === "manufacturer"
            ? "Assign to manufacturers"
            : "Assign to users"}
        </p>
        <div className="assign_slection_wrapper">
          <div
            style={{
              backgroundColor:
                userRole === "manufacturer" ? "#043398" : "white",
              color: userRole === "manufacturer" ? "#fff" : "black",
            }}
            className="manufacturer_label"
            onClick={() => {
              clearSearchStates();
              setUserRole("manufacturer");
              setSelectedUserList({});
              setInventoryAssignedUserList([]);
              setPage(1);
            }}
          >
            manufacturer
          </div>
          <div
            style={{
              backgroundColor:
                props.app_name === undefined
                  ? userRole === "user"
                    ? "#043398"
                    : "white"
                  : "gray",
              cursor: props.app_name === undefined ? "pointer" : "not-allowed",
              color: userRole === "user" ? "#fff" : "black",
            }}
            onClick={() => {
              if (props.app_name === undefined) {
                clearSearchStates();
                setUserRole("user");
                setSelectedUserList({});
                setInventoryAssignedUserList([]);
                setPage(1);
              }
            }}
            className="user_label"
          >
            user
          </div>
        </div>
        <Searchbar
          placeholder="Search by username"
          handleSearch={onSearchInput}
          autoFocus
          onFocus={onSearchFocus}
          value={searchText}
          className="search_bar_mfr"
        />
        {searchBarLoading && <LoadingText />}
        {searchedUserList.length > 0 && (
          <ul className="autocomplete_results">
            <Scroller autoHeightMax="15vw">
              <span className="_span" />
              {searchedUserList?.map((item, index) => (
                <li
                  key={item.username + index + "index"}
                  className="options"
                  onClick={() => onSearchedOptionClick(item)}
                  style={{ textTransform: "capitalize" }}
                >
                  {item.username}
                </li>
              ))}
            </Scroller>
          </ul>
        )}
        <div className="mfr_table">
          {Object.keys(inventoryAssignedUserList).length > 0 ||
          Object.keys(selectedUserList).length > 0 ? (
            <AssignedUsersList
              ref={childStateRef}
              assignedUserList={inventoryAssignedUserList}
              newlyAddedUserList={selectedUserList}
              removeUser={removeUser}
              remainingCount={props.remainingCount}
              setRemainingCount={props.setRemainingCount}
              availableCount={props.availableCount}
              setDisableSave={setDisableSave}
              userRole = {userRole}
              page={page}
              setPage={setPage}
              totalCount={totalCount}
            />
          ) : (
            <p className="no_data_found">No data found</p>
          )}
        </div>
        {!activeRoles.includes("superadmin") && (
          <p className="remianing_count_">
            Remaining Count: {props.remainingCount}
          </p>
        )}
        <div className="mfr_btns">
          <Button
            name="Save"
            style={{ width: "9vw", padding: "0.4vw" }}
            disabled={disableSave}
            onClick={postUserInventoryCount}
          />
        </div>
      </div>
      {/* <AnimatedModal
        modalOpen={showAlert}
        handleClose={() => {
          setShowAlert(false);
        }}
        type={msg.type}
        errorHeader={msg.header}
        errorText={msg.errorText}
        alwayOpen={true}
      /> */}
    </div>
  );
}
