import React from "react";
import { useMemo } from "react";
import { useState } from "react";
import Scrollbars from "react-custom-scrollbars";
import { toast } from "react-toastify";
import Button from "../../../components/Button/Button";
import Toggle from "../../../components/Toggle/Toggle";
import {
  copyIcon,
  deviceIcon,
  inactiveIcon,
  location,
  logs,
  viewIcon,
} from "../../../helper/icons";
import {
  encryptStorage,
  getDateTime,
  isSuspended,
} from "../../../helper/storage";
import { notify } from "../../../Routes";
import { activeIcon } from "../../shared/DeviceDetail/DeployedUsecase";
import copy from "copy-to-clipboard";

export default function UserDeviceList({
  name = "NA",
  status,
  id,
  history,
  configurations,
  serial_number,
  beta_testing = false,
  handleToggle,
  user_type,
  created_on,
  item,
  SelectedFilter,
  loc,
  Location,
  onAddClick,
}) {
  const [Beta, setBeta] = useState(beta_testing);

  const checkDisable = useMemo(() => {
    if (
      encryptStorage.getItem("UID").current === "user" &&
      !isSuspended() &&
      user_type === "owner"
    ) {
      return false;
    } else {
      return true;
    }
  }, [user_type]);

  //   const FilterValue = useMemo(() => {
  //     console.log(SelectedFilter);
  //     let arr = [];
  //     Object.keys(SelectedFilter).forEach((element) => {
  //       if (SelectedFilter[element].length > 0) {
  //         if (element === "version") {
  //           arr.push({
  //             value: item.software_version || "NA",
  //             color: "green",
  //           });
  //         }
  //         if (element === "architecture") {
  //           arr.push({
  //             value: item.configurations.hardware_architecture || "NA",
  //             color: "gray",
  //           });
  //         }
  //         if (element === "manufacturer") {
  //           arr.push({
  //             value: item.manufacturer_username || "NA",
  //             color: "blue",
  //           });
  //         }
  //         if (element === "processor") {
  //           arr.push({
  //             value: item.configurations.processor || "NA",
  //             color: "orange",
  //           });
  //         }
  //         if (element === "location") {
  //           arr.push({
  //             value: item?.metadata[Location] || "NA",
  //             color: "brown",
  //           });
  //         }
  //       }
  //     });
  //     console.log(arr);
  //     return arr;
  //   }, [SelectedFilter]);

  return (
    <div className="device_list_wrapper">
      <div className="d_name_leftwrapper">
        <div className="device_name_wrapper">
          {console.log(name, "nameeeeeeeeeeeeee")}

          <div
            className="deviceIcon_wrapper"
            style={{
              backgroundColor: !status && "#FFF0F0",
              // marginBottom: FilterValue?.length > 0 ? "0.78125vw" : null,
            }}
          >
            {deviceIcon()}
            {/* {status ? activeIcon() : inactiveIcon()} */}
          </div>

          <div className="device_status_wrapper">
            <p
              style={{
                color: status === "suspended" ? "red" : "#008000",
                border:
                  status === "suspended"
                    ? "1px solid red"
                    : "1px solid #008000",
              }}
              className="device_status"
            >
              {status}
            </p>
            <div className="device_user_name">
              <p className="device_username"
                style={{
                  // lineHeight: 1,
                  // maxWidth: "9vw",
                  // wordBreak: "break-word",
                }}
              >
                {name.replaceAll("_", " ")}
              </p>
              <div
                className={
                  status
                    ? "status_holder"
                    : "status_holder status_holder_inactive"
                }
              >
                {/* {status ? "Active" : "Suspended"} */}
              </div>
            </div>
            {loc && (
              <p className="device_location">
                {location()}
                {" " + loc}
              </p>
            )}
          </div>
        </div>

        {/* {FilterValue.length > 0 && (
          <div className="filter_wrapper">
            {FilterValue.map((item) => (
              <p
                className="tags"
                style={{ backgroundColor: item.color }}
                key={item.value}
                title={item.value}
              >
                {item?.value?.length > 20
                  ? item?.value.substring(0, 20) + "..."
                  : item?.value}
              </p>
            ))}
          </div>
        )} */}
      </div>

      <div className="device_info_wrapper">
        <div className="info_wrapper">
          <div className="device_info">
            <p className="d_label">IP Address</p>
            <div className="value_wrapper">{configurations.local_ip}</div>
          </div>

          <div className="device_info">
            <p className="d_label">Created</p>
            <div className="value_wrapper">{getDateTime(created_on)}</div>
          </div>
        </div>

        <div className="info_wrapper">
          <div className="device_info">
            <p className="d_label">Serial Number</p>
            <div
              className="value_wrapper"
              style={{ display: "inline-flex", paddingRight: "2vw" }}
            >
              <p className="d_value"> {serial_number.substring(0, 20)} ...</p>
              <p className="copy_icon" onClick={() => copy(serial_number)}>
                {copyIcon()}
              </p>
            </div>
          </div>

          <div className="device_info license_info">
            <p className="d_label">License expiry date</p>
            <div className="value_wrapper">
              <p className="d_value">
                {item.license
                  ? getDateTime(item.license.expiry)
                  : "No license found"}
              </p>
              {/* <button
                className="renew_btn"
                disabled={checkDisable}
                onClick={() => {
                  !checkDisable && onAddClick();
                }}
              >
                {item.license ? "Renew License" : "Add License"}
              </button> */}
            </div>
          </div>
        </div>

        {/* <div className="info_wrapper">
          <div
            className="device_info"
            style={{
              opacity: checkDisable ? "0.4" : "1",
              cursor: checkDisable ? "not-allowed" : null,
            }}
          >
            <p className="d_label" style={{ marginLeft: "0.6vw" }}>
              Beta Testing
            </p>
            <div className="beta_toggle">
              <Toggle
                handleValue={(value) => {
                  setBeta(value);
                  handleToggle(value);
                }}
                disable={checkDisable}
                value={Beta}
                id={id + "_toggle"}
              />
            </div>
          </div>

          <div className="device_info beta_info">
            <div className="beta_labels">
              <p className="log_icon">{logs()}</p>
              <p className="b_label">Check Logs</p>
            </div>

            <div
              className="beta_labels"
              onClick={() => {
                history.push(
                  "/device/detail/" + serial_number + window.location.search
                );
              }}
            >
              <p className="log_icon">{viewIcon()}</p>
              <p className="b_label">View Details</p>
            </div>

            <div className="beta_labels">
              <p className="log_icon">{logs()}</p>
              <p className="b_label">Suspend</p>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}
