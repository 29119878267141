import React, { useEffect, useRef, useState } from "react";
import { FormCard, FormHeader } from "../../../components/FormCard/FormCard";
import InputBox from "../../../components/Inputbox/InputBox";
import MultiSelectDropdown from "../../../components/MultiSelectDropdown/MultiSelectDropdown";
import PhoneInputBox from "../../../components/PhoneInputBox/PhoneInputBox";
import "../register/register.scss";
import Dropzone from "react-dropzone";
import ReCAPTCHAV2 from "react-google-recaptcha";
import { Scrollbars } from "react-custom-scrollbars";
import { Radio } from "../../../components/Radio/Radio";
import Button from "../../../components/Button/Button";
import { useDebouncedEffect } from "../../../helper/useDebounce";
import { Link, useHistory } from "react-router-dom";
import { transparentInfoIcon } from "../../../helper/icons";
import axios from "axios";
import { API_URL, axiosApiInstance, OTP_TIME } from "../../../helper/request";
import { encryptStorage } from "../../../helper/storage";
import AnimatedModal from "../../../components/Modal/AnimatedModal";
import Loading from "../../../components/Loading/Loading";
import { LoadingText } from "../../auth/register/AuthRegister";
import OTP from "../../../components/OTP/OTP";
import { notify } from "../../../Routes";
import Scroller from "../../../components/Scroller/ScrollBar";
import { ThemeContext } from "../../../helper/context";
import { useContext } from "react";
import Dropdown from "../../../components/Dropdown/Dropdown";
let parent_username = "";
let _lData__ = encryptStorage.getItem("VID");
let _devData = null;
let isExact = false;
let isCityEmpty = false;
let isDataEmpty = null;
let timeout = null;
let values = {
  country: null,
  state: null,
  city: null,
};
let msg = {
  type: "alert",
  header: "Error",
  errorText: "Something Went Wrong",
};
let firstLoad = true;

//otp
let resetCounter = null;

export default function AuthRegisterManufacturer(props) {
  const countryScrollRef = useRef();
  const cityScrollRef = useRef();
  const history = useHistory();
  //otp
  const [ShowOTP, setShowOTP] = useState(false);
  const [OTPCloseConfirm, setOTPCloseConfirm] = useState(false);
  const [Stop, setStop] = useState(false);

  //manu modal
  const [CompanyName, setCompanyName] = useState("");
  const [Bank, setBank] = useState({});
  const [ShowBank, setShowBank] = useState(true);
  const [IsBankAvailable, setIsBankAvailable] = useState(true);
  const [Message, setMessage] = useState("");
  const [Name, setName] = useState("");
  const [Country2, setCountry2] = useState("");
  const [Phone, setPhone] = useState("+91 ");
  const themeContext = useContext(ThemeContext);

  const [ShowAlert, setShowAlert] = useState(false);
  const [LoadingScreen, setLoadingScreen] = useState(false);
  const [ValidationToken, setValidationToken] = useState(true);
  const [FullName, setFullName] = useState("");
  const [Username, setUsername] = useState("");
  const [Mobile, setMobile] = useState("+91 ");
  const [Email, setEmail] = useState("");
  const [Country, setCountry] = useState("");
  const [Captcha, setCaptcha] = useState("");
  const [State, setState] = useState("");
  const [City, setCity] = useState("");
  const [Industry, setIndustry] = useState([]);
  const [Password, setPassword] = useState("");
  const [ConfirmPassword, setConfirmPassword] = useState("");
  const [ProfilePic, setProfilePic] = useState(null);
  const [TOS, setTOS] = useState(false);
  const [ShowManufacturer, setShowManufacturer] = useState(false);
  const [LocationData, setLocationData] = useState({
    country: [],
    state: [],
    city: [],
    citySuggestion: ["Mumbai", "Pune"],
  });
  const [LocationLoading, setLocationLoading] = useState({
    country: false,
    state: false,
    city: false,
  });
  const [LocationSelected, setLocationSelected] = useState({
    isCountrySelected: false,
    isStateSelected: false,
    isCitySelected: false,
  });
  const [errors, setErrors] = useState({
    isFNameEmpty: false,
    isUsernameEmpty: false,
    isMobileEmpty: false,
    isEmailEmpty: false,
    isPasswordEmpty: false,
    isCPasswordEmpty: false,
    isCountryEmpty: false,
    isStateEmpty: false,
    isCityEmpty: false,
    isIndustryEmpty: false,
    isCaptchaEmpty: false,
    isTOSChecked: false,
    isUsernameUnique: false,
    isEmailUnique: false,
    isMobileUnique: false,
  });
  //Password Validation
  const [isLowerCase, setisLowerCase] = useState(false);
  const [isUpperCase, setisUpperCase] = useState(false);
  const [isNumber, setisNumber] = useState(false);
  const [areMinEightChar, setareMinEightChar] = useState(false);
  const [areSpecialCharacters, setareSpecialCharacters] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [PassError, setPassError] = useState(false);
  const [oldType, setOldType] = useState("password");
  const [oldType2, setOldType2] = useState("password");

  const checkPassword = (password) => {
    if (password.match(/[a-z]/g)) {
      setisLowerCase(true);
    } else {
      setisLowerCase(false);
    }

    if (password.match(/[A-Z]/g)) {
      setisUpperCase(true);
    } else {
      setisUpperCase(false);
    }

    if (password.match(/[0-9]/g)) {
      setisNumber(true);
    } else {
      setisNumber(false);
    }

    if (password.length >= 8) {
      setareMinEightChar(true);
    } else {
      setareMinEightChar(false);
    }

    if (password.match(/[!@#$%&()+]/g)) {
      setareSpecialCharacters(true);
    } else {
      setareSpecialCharacters(false);
    }
    if (
      !password.match(/[a-z]/g) ||
      !password.match(/[A-Z]/g) ||
      !password.match(/[0-9]/g) ||
      password.length < 8 ||
      !password.match(/[!@#$%&()+]/g)
    ) {
      setPassError(true);
    } else {
      setPassError(false);
    }

    // if (password !== ConfirmPassword) {
    //   setdoesPasswordMatch(true);
    // } else {
    //   setdoesPasswordMatch(false);
    // }
    if (
      password.match(/[a-z]/g) &&
      password.match(/[A-Z]/g) &&
      password.match(/[0-9]/g) &&
      password.length >= 8 &&
      password.match(/[!@#$%&()+]/g)
    ) {
      setDropdown(false);
    } else {
      setDropdown(true);
    }
  };
  const handlePassword = (type) => {
    if (!type) {
      setOldType("password");
    } else {
      setOldType("text");
    }
  };
  const handleConfirmPassword = (type) => {
    if (!type) {
      setOldType2("password");
    } else {
      setOldType2("text");
    }
  };
  const clearError = (name) => {
    let _errors = { ...errors };
    _errors[name] = false;
    setErrors({ ..._errors });
  };

  useDebouncedEffect(
    () => (Country ? getCountry("country") : undefined),
    [Country],
    1000
  );

  useDebouncedEffect(
    () => (Country2 ? getCountry2("country") : undefined),
    [Country2],
    1000
  );

  useDebouncedEffect(
    () => (State ? getCountry("state") : undefined),
    [State],
    1000
  );

  useDebouncedEffect(
    () => (Username ? uniqueCheck("Username") : undefined),
    [Username],
    1000
  );

  useDebouncedEffect(
    () => (Email ? uniqueCheck("Email") : undefined),
    [Email],
    1000
  );

  useDebouncedEffect(
    () => (Mobile ? uniqueCheck("phone") : undefined),
    [Mobile],
    1000
  );

  const getCountry = (type = "country") => {
    if (firstLoad && !ShowManufacturer) {
      return;
    }
    let url = "";
    if (type === "country") {
      url = "admin/country/filters?country=" + Country;
    } else {
      url = "admin/country/filters?country=" + Country + "&state=" + State;
    }
    axios.get(API_URL + url).then((res) => {
      let _data = res.data.detail;
      let keys = null;
      if (_data.length) {
        keys = Object.keys(_data[0]);
      } else {
        if (type === "state") {
          setLocationData((prevState) => ({
            ...prevState,
            state: [],
          }));
        } else {
          setLocationData((prevState) => ({
            ...prevState,
            country: [],
          }));
        }
        setLocationLoading((prevState) => ({
          ...prevState,
          state: false,
          country: false,
          city: false,
        }));
        isDataEmpty = true;
        return;
      }

      if (keys?.includes("state")) {
        if (State === _data[0].state) {
          if (_data[0]?.cities?.length === 0) {
            isCityEmpty = true;
            // setLocationSelected((prevState) => ({
            //   ...prevState,
            //   isCitySelected: false,
            // }));
            // return;
          } else {
            isCityEmpty = false;
          }
          setLocationData((prevState) => ({
            ...prevState,
            city: [..._data[0]?.cities],
          }));
          setLocationLoading((prevState) => ({
            ...prevState,
            state: false,
            city: false,
          }));
        } else {
          setLocationSelected((prevState) => ({
            ...prevState,
            isCountrySelected: true,
          }));
          setLocationData((prevState) => ({
            ...prevState,
            state: [..._data],
          }));
          setLocationLoading((prevState) => ({
            ...prevState,
            state: false,
            country: false,
            city: false,
          }));
        }
      } else {
        setLocationLoading((prevState) => ({
          ...prevState,
          country: false,
        }));
        setLocationData((prevState) => ({
          ...prevState,
          country: [..._data],
        }));
      }
    });
  };

  const getCountry2 = () => {
    if (firstLoad) return;
    setLocationLoading({ ...LocationLoading, country: true });
    axios
      .get(API_URL + "admin/country/filters?country=" + Country2)
      .then((res) => {
        let _data = res.data.detail;
        let keys = null;
        keys = Object.keys(_data[0]);
        if (!keys?.includes("state")) {
          setLocationData(_data);
          setLocationSelected((prevState) => ({
            ...prevState,
            isCountrySelected: false,
          }));
        } else {
          setLocationData([]);
          getBank(Country2);
          setLocationSelected((prevState) => ({
            ...prevState,
            isCountrySelected: true,
          }));
        }
      })
      .catch((err) => {
      })
      .finally(() => {
        setLocationLoading({ ...LocationLoading, country: false });
      });
  };

  const uniqueCheck = (type = "Username") => {
    if (firstLoad) {
      return;
    }

    let value = {
      Username,
      Email,
      phone: Mobile,
    };
    let url = `user/unique-check/${type.toLowerCase()}?fieldValue=${value[type]
      }`;
    axios.get(API_URL + url).then((res) => {
      if (res.data.detail) {
        if (type === "Username")
          setErrors((prevState) => ({
            ...prevState,
            isUsernameUnique: true,
          }));
        if (type === "Email")
          setErrors((prevState) => ({
            ...prevState,
            isEmailUnique: true,
          }));

        if (type === "phone")
          setErrors((prevState) => ({
            ...prevState,
            isMobileUnique: true,
          }));
      } else {
        if (type === "Username")
          setErrors((prevState) => ({
            ...prevState,
            isUsernameUnique: false,
          }));

        if (type === "Email")
          setErrors((prevState) => ({
            ...prevState,
            isEmailUnique: false,
          }));

        if (type === "phone")
          setErrors((prevState) => ({
            ...prevState,
            isMobileUnique: false,
          }));
      }
    });
  };

  const validateImage = (type) => {
    const res = ["image/png", "image/jpg", "image/jpeg"].some(
      (item) => item == type
    );
    return res;
  };

  const postData = async () => {
    let _mobile = Mobile.split(" ")[1];
    let _errors = { ...errors };
    if (FullName === "") _errors["isFNameEmpty"] = true;
    if (Username === "") _errors["isUsernameEmpty"] = true;
    if (_mobile === "") _errors["isMobileEmpty"] = true;
    if (Email === "") _errors["isEmailEmpty"] = true;
    if (Country === "") _errors["isCountryEmpty"] = true;
    if (State === "") _errors["isStateEmpty"] = true;
    // if (Captcha === "") _errors["isCaptchaEmpty"] = true;
    // if (Industry.length === 0) _errors["isIndustryEmpty"] = true;
    if (Password === "" || PassError) _errors["isPasswordEmpty"] = true;
    if (ConfirmPassword === "") _errors["isCPasswordEmpty"] = true;
    if (!isCityEmpty) {
      if (City === "") _errors["isCityEmpty"] = true;
    }
    if (ProfilePic) {
      if (!validateImage(ProfilePic.type)) {
        notify({
          msg: "Invalid Profile Picture",
        });
        // msg.errorText = "Invalid Profile Picture";
        // msg.header = "Error";
        // msg.type = "alert";
        // setShowAlert(true);
        return;
      }
    }
    if (Password !== ConfirmPassword) {
      _errors["isPasswordEmpty"] = true;
      _errors["isCPasswordEmpty"] = true;
    }
    if (Password && ConfirmPassword && Password === ConfirmPassword) {
      _errors["isPasswordEmpty"] = false;
      _errors["isCPasswordEmpty"] = false;
    }
    if (PassError) {
      _errors["isPasswordEmpty"] = true;
      setDropdown(true);
    }
    setErrors({ ..._errors });
    if (!TOS) {
      notify({
        msg: "Please accept our Terms of Service.",
      });
      // msg.errorText = "Please accept out Terms of Service.";
      // msg.header = "Error";
      // msg.type = "alert";
      // setShowAlert(true);
      return;
    }
    // if (Captcha === "") {
    //   document.getElementById("recaptcha")?.classList.add("error_shake");
    //   document.getElementById("recaptcha").style.border =
    //     "2px solid rgba(248, 78, 78, 0.7)";
    //   setTimeout(() => {
    //     document.getElementById("recaptcha")?.classList.remove("error_shake");
    //   }, 30000);
    // }
    if (Object.values(_errors).includes(true) || PassError) {
      return;
    }


    const toBase64 = (file) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    let obj = {
      username: Username,
      email: Email,
      // interval: "20",
    };
    let dp = "";
    if (ProfilePic) {
      dp = await toBase64(ProfilePic);
    }
    let userData = {
      FullName,
      Username: Username.toLowerCase(),
      Mobile,
      Email: Email.toLowerCase(),
      Country,
      State,
      City,
      Industry,
      Password,
      ProfilePic: dp,
      picName: ProfilePic,
    };
    setLoadingScreen(true);
    axios
      .post(API_URL + "user/otp/send", obj)
      .then((res) => {
        setTimeout(() => {
          setShowOTP(true);
        }, 500);
        encryptStorage.setItem("VID", {
          // attempts: 2,
          userData,
          counter: OTP_TIME,
          destination: Email.toLowerCase(),
          username: Username.toLowerCase(),
        });
      })
      .catch((err) => {
        msg.errorText = "Something went wrong!";
        msg.header = "Error";
        msg.type = "alert";
        setShowAlert(true);
      })
      .finally(() => {
        setLoadingScreen(false);
      });
    // axios
    //   .post(API_URL + "auth/otp/send", obj)
    //   .then((res) => {

    //   })
    //   .catch((err) => {
    //     msg.errorText = "Something went wrong!";
    //     msg.header = "Error";
    //     msg.type = "alert";
    //     setShowAlert(true);
    //   })
    //   .finally(() => {
    //     setLoadingScreen(false);
    //   });
  };

  const postVerifyOTP = (otp_) => {
    // let _otp = otp1 + otp2 + otp3 + otp4;
    // if (otp1 === "" || otp2 === "" || otp3 === "" || otp4 === "") {
    //   msg.errorText = "Invalid OTP. Please Try Again!";
    //   msg.header = "Error";
    //   msg.type = "alert";
    //   setShowAlert(true);
    //   return;
    // }
    let lData = encryptStorage.getItem("VID");
    let obj = {
      username: lData.username,
      email: lData.destination,
      otp: otp_,
    };
    setLoadingScreen(true);
    axios
      .post(API_URL + "user/otp/verify", obj)
      .then((res) => {
        postUserData();
        // msg.type = "success";
        // msg.header = "Success";
        // msg.errorText = "OTP Verified Successfully";
        // var ele = document.querySelector("#otp_container");
        // ele.classList.add("slideOutLeft");
        // timeout = setTimeout(() => {
        //   setshowOTPContainer(false);
        //   setshowRegSuccessContainer(true);
        //   ele.classList.remove("slideOutLeft");
        // }, 500);
      })
      .catch((err) => {
        // setStop(false);
        // _stop = false;
        msg.errorText = "Invalid OTP. Please Try Again!";
        msg.header = "Error";
        msg.type = "alert";
        setShowAlert(true);
        setLoadingScreen(false);
      });
  };

  const postResendOTP = () => {
    let _lData = encryptStorage.getItem("VID");

    let obj = {
      username: _lData.username,
      email: _lData.destination,
    };
    setLoadingScreen(true);
    axios
      .post(API_URL + "user/otp/send", obj)
      .then((res) => {
        msg.errorText = "OTP resent to your Email ID.";
        msg.header = "Success";
        msg.type = "success";
        setShowAlert(true);
        resetCounter();
        // setCounter(time);
        // _lData.attempts = _lData.attempts - 1;
        // encryptStorage.setItem("VID", _lData);
      })
      .catch((err) => {
        msg.errorText = "Server Error";
        msg.header = "Error";
        msg.type = "alert";
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 3000);
        // clearTimeout(timeout);
        // timeout = setTimeout(() => {
        //   encryptStorage.removeItem("VID");
        //   window.location.reload();
        // }, 5000);
      })
      .finally(() => {
        setLoadingScreen(false);
      });
  };

  const postUserData = async () => {
    let lData = encryptStorage.getItem("VID");
    let formData = new FormData();
    formData.append("full_name", lData.userData.FullName);
    formData.append("phone", lData.userData.Mobile);
    formData.append("email", lData.userData.Email);
    formData.append("username", lData.userData.Username);
    formData.append("password", lData.userData.Password);

    formData.append("country", lData.userData.Country);
    formData.append("state", lData.userData.State);
    formData.append("city", lData.userData.City);
    formData.append("industries", lData.userData.Industry);
    _devData = {
      username: lData.userData.Username,
      country: lData.userData.Country,
    };
    if (lData.userData.ProfilePic) {
      const res = await fetch(lData.userData.ProfilePic);
      const blob = await res.blob();
      let newFile = new File([blob], lData.userData.picName.path, {
        type: blob.type,
      });
      formData.append("profile_pic", newFile, newFile.name);
    }

    axios
      .post(API_URL + "user/signup", formData)
      .then((res) => {
        encryptStorage.setItem("UCRED", {
          username: lData.userData.Username,
          password: lData.userData.Password,
          country: lData.userData.Country,
        });
        setStop(true);
        setShowOTP(false);
        encryptStorage.removeItem("VID");
        setLoadingScreen(true);
        postLogin();
        //login user after registration
      })
      .catch((err) => {
        msg.errorText = "Error while sending user data to server";
        msg.header = "Error";
        msg.type = "alert";
        setShowAlert(true);
        setLoadingScreen(false);
      });
  };

  const postLogin = () => {
    let ucred = encryptStorage.getItem("UCRED");
    axios
      .post(
        API_URL + "auth/login",
        {},
        {
          headers: {
            field: ucred.username,
            password: ucred.password,
          },
        }
      )
      .then((res) => {
        ucred = { ...ucred, ...res.data };
        encryptStorage.setItem("UCRED", ucred);
        encryptStorage.setItem("UID", { ...res.data });
        getBank(ucred.country);
      })
      .catch((err) => {
        setLoadingScreen(false);
        notify({});
      });
  };

  useEffect(() => {
    setState("");
    setCity("");
    isCityEmpty = false;
    if (Country === "") {
      setLocationData((prevState) => ({
        ...prevState,
        country: [],
        state: [],
        city: [],
        citySuggestion: [],
      }));
    } else {
      setLocationData((prevState) => ({
        ...prevState,
        state: [],
        city: [],
        citySuggestion: [],
      }));
    }

    setLocationSelected((prevState) => ({
      ...prevState,
      isStateSelected: false,
      isCitySelected: false,
      isCountrySelected: false,
    }));
    if (values.country) {
      setLocationSelected((prevState) => ({
        ...prevState,
        isCountrySelected: true,
      }));
    }
  }, [Country]);

  useEffect(() => {
    setCity("");
    if (State) {
      setLocationData((prevState) => ({
        ...prevState,
        city: [],
        citySuggestion: [],
        state: [],
      }));
    } else {
      setLocationLoading((prevState) => ({
        ...prevState,
        state: false,
      }));
      setLocationData((prevState) => ({
        ...prevState,
        city: [],
        citySuggestion: [],
      }));
    }

    setLocationSelected((prevState) => ({
      ...prevState,
      isStateSelected: false,
      isCitySelected: false,
    }));
    if (values.state) {
      setLocationSelected((prevState) => ({
        ...prevState,
        isStateSelected: true,
      }));
      // if (LocationData.city.length === 0) {
      //   setLocationSelected((prevState) => ({
      //     ...prevState,
      //     isCitySelected: false,
      //   }));
      // }
    }
  }, [State]);

  const getToken = () => {
    axios
      .get(
        API_URL + "user/invite/link/verify?token=" + props.match.params.token
      )
      .then((res) => {
        let ucred = encryptStorage.getItem("UCRED");
        if (ucred) {
          setTimeout(() => {
            firstLoad = false;
          }, 2000);
          setShowManufacturer(true);
          getBank(ucred.country);
        }
        setEmail(res.data.email);
        parent_username = res.data.parent_username;
      })
      .catch((err) => {
        msg.errorText = "Failed to validate token. Redirecting to login page.";
        msg.header = "Error";
        msg.type = "alert";
        setShowAlert(true);
        setTimeout(() => {
          history.push("/");
        }, 5000);
      })
      .finally(() => {
        setValidationToken(false);
        setTimeout(() => {
          firstLoad = false;
        }, 2000);
      });
  };

  useEffect(() => {
    const html = document.querySelector("html");
    html.classList.add("light-theme");
    if (_lData__) {
      setShowOTP(true);
      setStop(false);
    }
    getToken();
  }, []);

  const getKeyCode = (e) => {
    switch (e.keyCode) {
      case 38:
        arrowKey(e, "up");
        break;

      case 40:
        arrowKey(e, "down");
        break;

      case 13:
        enterKey(e);
        // setIsFlagOpen(false);
        break;
    }
  };

  const arrowKey = (e, type) => {
    let myReferenceDiv = document.querySelector("._span");
    let active_flag = document.querySelector(".active_flag");
    let active_next = active_flag?.nextElementSibling;
    let active_prev = active_flag?.previousElementSibling;
    let next = myReferenceDiv?.nextElementSibling;
    let prev = myReferenceDiv?.previousElementSibling;
    if (type === "down") {
      if (!active_flag) {
        next.classList.add("active_flag");
      } else {
        if (active_next) {
          active_flag.classList.remove("active_flag");
          active_next.classList.add("active_flag");
          var childElement =
            document.querySelector(".active_flag").offsetTop -
            myReferenceDiv.offsetHeight -
            20;
          cityScrollRef?.current?.scrollTop(childElement);
          countryScrollRef?.current?.scrollTop(childElement);
        }
      }
    }
    if (type === "up") {
      if (active_prev && !active_prev.classList.contains("_span")) {
        active_flag.classList.remove("active_flag");
        active_prev.classList.add("active_flag");
        var childElement =
          document.querySelector(".active_flag").offsetTop -
          myReferenceDiv.offsetHeight -
          20;
        cityScrollRef?.current?.scrollTop(childElement);
        countryScrollRef?.current?.scrollTop(childElement);
      }
    }
  };

  const enterKey = () => {
    let active_flag = document.querySelector(".active_flag");
    let dataCity = active_flag.getAttribute("data-city");
    let dataCountry = active_flag.getAttribute("data-country");
    let dataState = active_flag.getAttribute("data-state");
    if (dataCity) {
      isDataEmpty = null;
      setCity(dataCity);
      setLocationSelected((prevState) => ({
        ...prevState,
        isCitySelected: true,
      }));
      setLocationData((prevState) => ({
        ...prevState,
        citySuggestion: [],
      }));
    }

    if (dataState) {
      values.state = dataState;
      isExact = true;
      isDataEmpty = null;
      setState(dataState);
      setLocationSelected((prevState) => ({
        ...prevState,
        isStateSelected: true,
      }));
    }

    if (dataCountry) {
      isExact = true;
      isDataEmpty = null;
      values.country = dataCountry;
      ShowManufacturer ? setCountry2(dataCountry) : setCountry(dataCountry);
      setLocationSelected((prevState) => ({
        ...prevState,
        isCountrySelected: true,
      }));
      setLocationLoading((prevState) => ({
        ...prevState,
        state: true,
      }));
    }
  };

  //manu modal

  const handleChanges = (value, idx) => {
    const _bank = { ...Bank };
    _bank.fields[idx].value = value;
    setBank({ ..._bank });
  };

  const handleFocus = (name) => {
    let ele = document.getElementById(name);
    ele.classList.remove("error_shake", "input__error");
  };

  const renderElement = (item, idx) => {
    switch (item.type) {
      case "string":
      case "number":
        return (
          <InputBox
            id={item.name}
            header={item.name.replace(/_/g, " ")}
            onChange={(e) => {
              const value =
                item.type === "string"
                  ? e.target.value
                  : e.target.value.replace(/[^0-9]/g, "");
              handleChanges(value, idx);
            }}
            value={item.value}
            onFocus={() => handleFocus(item.name)}
          />
        );
      case "dropdown":
        return (
          <Dropdown
            label={item.name.replace(/_/g, " ")}
            className="adjust_dd"
            id={item.name}
            optionsList={item.options}
            handleOption={(data) => {
              handleChanges(data, idx);
              handleFocus(item.name);
              // setAccountType(data);
            }}
            defaultText={item.value}
          // error={BankError["isAccountTypeEmpty"]}
          />
        );
      default:
        return <p> NO ELEMENT FOUND</p>;
    }
  };

  const getBank = (country) => {
    setLoadingScreen(true);
    axios
      .get(API_URL + "admin/bank/details?country=" + country)
      .then((res) => {
        let dict = { ...res.data };
        for (let i = 0; i < dict.fields.length; i++) {
          dict.fields[i].value = "";
        }
        setBank({ ...dict });
        setIsBankAvailable(true);
        setCountry2(country);
        // setShowBank(true);
        setTOS(false);
        setShowManufacturer(true);
        setLocationSelected((prevState) => ({
          ...prevState,
          isCountrySelected: false,
        }));
      })
      .catch((err) => {
        setShowManufacturer(true);
        setIsBankAvailable(false);
        // setShowBank(false);
        setBank({});
        // notify({});
      })
      .finally(() => {
        setLoadingScreen(false);
      });
  };

  const validateBankData = () => {
    let res = false;
    for (let i = 0; i < Bank.fields.length; i++) {
      if (Bank.fields[i].required) {
        if (!Bank.fields[i].value) {
          let ele = document.getElementById(Bank.fields[i].name);
          ele.classList.add("error_shake", "input__error");
          res = true;
        }
      }
    }
    return res;
  };

  const postData2 = () => {
    let _errors = false;
    let _mobile = Phone.split(" ")[1];
    if (CompanyName === "") {
      document.getElementById("companyName").style.border =
        "2px solid rgba(248, 78, 78, 0.7)";
      _errors = true;
    }

    if (Message === "") {
      document.getElementById("msg_").style.border =
        "2px solid rgba(248, 78, 78, 0.7)";
      _errors = true;
    }

    if (Country2 === "") {
      document.getElementById("country2").style.border =
        "2px solid rgba(248, 78, 78, 0.7)";
      _errors = true;
    }

    if (TOS === false) {
      document.querySelector("._tos").style.border =
        "2px solid rgba(248, 78, 78, 0.7)";
      document.querySelector("._tos").style.padding = "0.3vw";
      notify({
        msg: "Please accept our Terms of Service.",
      });
      _errors = true;
    }

    if (ShowBank && IsBankAvailable) {
      if (!Name) {
        document
          .getElementById("full_Name")
          .classList.add("error_shake", "input__error");
        _errors = true;
      }
      if (!_mobile) {
        document
          .getElementById("_mobile_number_")
          .classList.add("error_shake", "input__error");
        _errors = true;
      }
      if (!Country2) {
        document
          .getElementById("country2")
          .classList.add("error_shake", "input__error");
        _errors = true;
      }
      if (validateBankData()) {
        notify({
          msg: "Please fill bank details",
        });
        _errors = true;
      }
    }

    if (_errors) return;

    let body = {
      company_name: CompanyName,
      message: Message,
      parent_username,
    };

    if (ShowBank && IsBankAvailable) {
      let _bank = {};
      for (let i = 0; i < Bank.fields.length; i++) {
        _bank[Bank.fields[i].name] = Bank.fields[i].value;
      }
      body.contact_person_name = Name;
      body.contact_person_phone = Phone;
      body.country = Country2;
      body.bank_details = { ..._bank };
    }
    setLoadingScreen(true);

    axiosApiInstance
      .post(API_URL + "user/signup/manufacturer", body)
      .then((res) => {
        msg.errorText = "Request to become a manufacturer sent successfully!";
        msg.header = "Success";
        msg.type = "success";
        encryptStorage.removeItem("UCRED");
        encryptStorage.removeItem("UID");
        setTimeout(() => {
          setShowAlert(true);
        }, 500);
      })
      .catch((err) => {
        msg.errorText = "Failed to send manufacturer data!";
        msg.header = "Error";
        msg.type = "alert";
        setShowAlert(true);
      })
      .finally(() => {
        setLoadingScreen(false);
      });
  };

  if (ValidationToken) {
    return <Loading type={"transparent"} text={"Validating"} />;
  }

  return (
    <div className="__auth_register__ light-theme manu_register">
      <FormCard>
        {!ShowManufacturer && (
          <React.Fragment>
            <FormHeader name="Sign up to" bold="Diycam Marketplace." />
            <p className="sub">Start your journey of AI with us.</p>
            <p className="already_account">
              Already a member?{" "}
              <Link to={"/"}>
                <span> Sign In</span>
              </Link>
            </p>
            <div className="register_data_wrapper" style={{ width: "110%" }}>
              <Scrollbars
                renderThumbHorizontal={({ style, ...props }) => {
                  const thumbStyle = {
                    borderRadius: 6,
                    backgroundColor: "rgba(35, 49, 86, 0.8)",
                  };
                  return <div style={{ ...style, ...thumbStyle }} {...props} />;
                }}
                renderThumbVertical={({ style, ...props }) => {
                  const thumbStyle = {
                    borderRadius: 6,
                    backgroundColor: "rgba(0, 0, 0, 0.2)",
                    width: "3px",
                  };
                  return <div style={{ ...style, ...thumbStyle }} {...props} />;
                }}
                autoHeight
                autoHeightMax="50vh"
              >
                <div className="register_data">
                  <div className="left-section">
                    <InputBox
                      id="fullName"
                      header="Full Name"
                      onChange={(e) => {
                        const value = e.target.value;
                        const regex = /^[a-zA-Z ]*$/;
                        if (value.match(regex) || value === "") {
                          setFullName(value);
                        }
                      }}
                      error={errors["isFNameEmpty"]}
                      value={FullName}
                      onFocus={() => clearError("isFNameEmpty")}
                    />
                    <PhoneInputBox
                      id="mobile_number"
                      isEdit={true}
                      onChange={(data) => {
                        // if (data.inputNumber) {
                        let _number = data.countryCode + " " + data.inputNumber;
                        setMobile(_number);
                        // }
                      }}
                      onFocus={(e) => {
                        clearError("isMobileEmpty");
                      }}
                      value={Mobile}
                      error={
                        errors["isMobileEmpty"] || errors["isMobileUnique"]
                      }
                      helperText={errors["isMobileUnique"] && "Already used"}
                    />
                    <div id="_country_">
                      <InputBox
                        id="country"
                        header="Country"
                        onChange={(e) => {
                          const value = e.target.value;
                          const regex = /^[a-zA-Z ]*$/;
                          if (value.match(regex) || value === "") {
                            values.country = null;
                            setCountry(value);
                            setLocationLoading((prevState) => ({
                              ...prevState,
                              country: true,
                            }));
                          } else {
                            setLocationLoading((prevState) => ({
                              ...prevState,
                              country: false,
                            }));
                          }
                        }}
                        onKeyDown={getKeyCode}
                        error={errors["isCountryEmpty"]}
                        value={Country}
                        onFocus={() => clearError("isCountryEmpty")}
                      />
                      {LocationLoading.country && Country && !isDataEmpty ? (
                        <div className="suggestions">
                          <LoadingText />
                        </div>
                      ) : (
                        LocationData?.country?.length > 0 &&
                        !LocationSelected?.isCountrySelected && (
                          <div className="suggestions">
                            <Scrollbars autoHeight autoHeightMax="15vh">
                              <span className="_span" />
                              {LocationData.country.map((item) => (
                                <div
                                  key={item.country}
                                  className="suggestion_item"
                                  data-country={item.country}
                                  onClick={() => {
                                    isExact = true;
                                    isDataEmpty = null;
                                    values.country = item.country;
                                    setCountry(item.country);
                                    setLocationSelected((prevState) => ({
                                      ...prevState,
                                      isCountrySelected: true,
                                    }));
                                    setLocationLoading((prevState) => ({
                                      ...prevState,
                                      state: true,
                                    }));
                                  }}
                                >
                                  {item.country}
                                </div>
                              ))}
                            </Scrollbars>
                          </div>
                        )
                      )}
                    </div>
                    <div id="_country_">
                      <InputBox
                        id="city"
                        header="City"
                        onKeyDown={getKeyCode}
                        disabled={
                          !LocationSelected.isStateSelected || isCityEmpty
                        }
                        onChange={(e) => {
                          const value = e.target.value;
                          const regex = /^[a-zA-Z ]*$/;
                          if (value.match(regex) || value === "") {
                            setCity(value);
                            const found = LocationData.city.filter((e) =>
                              e.toLowerCase().includes(value.toLowerCase())
                            );
                            setLocationData((prevState) => ({
                              ...prevState,
                              citySuggestion: [...found],
                            }));
                          }
                        }}
                        error={errors["isCityEmpty"]}
                        value={City}
                        onFocus={() => clearError("isCityEmpty")}
                      />
                      {LocationLoading.city && !isDataEmpty ? (
                        <div className="suggestions">
                          <LoadingText />
                        </div>
                      ) : (
                        LocationData.citySuggestion.length > 0 && (
                          <div className="suggestions">
                            <Scrollbars
                              autoHeight
                              autoHeightMax="15vh"
                              ref={cityScrollRef}
                            >
                              <span className="_span" />
                              {LocationData.citySuggestion.map((item) => (
                                <div
                                  key={item}
                                  className="suggestion_item"
                                  data-city={item}
                                  onClick={() => {
                                    isDataEmpty = null;
                                    setCity(item);
                                    setLocationSelected((prevState) => ({
                                      ...prevState,
                                      isCitySelected: true,
                                    }));
                                    setLocationData((prevState) => ({
                                      ...prevState,
                                      citySuggestion: [],
                                    }));
                                  }}
                                >
                                  {item}
                                </div>
                              ))}
                            </Scrollbars>
                          </div>
                        )
                      )}
                    </div>
                    <div>
                      <InputBox
                        id="password"
                        onInput={(e) => checkPassword(e.target.value)}
                        error={errors["isPasswordEmpty"]}
                        type={oldType}
                        typeValue={(data) => handlePassword(data)}
                        password
                        header="Password"
                        onChange={(e) => {
                          const value = e.target.value;
                          const regex = /^[a-zA-Z0-9!@#$%&()+]*$/;
                          if (value.match(regex) || value === "") {
                            setPassword(value);
                          }
                        }}
                        value={Password}
                        onFocus={() => clearError("isPasswordEmpty")}
                        onBlur={() => {
                          setDropdown(false);
                        }}
                      />
                      {dropdown && (
                        <div className="dropdown fadeIn">
                          <div className="dropdown-content">
                            <p>Password must contain:</p>

                            <p className={isUpperCase ? "valid" : "invalid"}>
                              An <b>Uppercase</b> letter
                            </p>
                            <p className={isLowerCase ? "valid" : "invalid"}>
                              A <b>Lowercase</b> letter
                            </p>
                            <p className={isNumber ? "valid" : "invalid"}>
                              A <b>number</b>
                            </p>
                            <p
                              className={areMinEightChar ? "valid" : "invalid"}
                            >
                              Minimum <b>8 characters</b>
                            </p>
                            <p
                              className={
                                areSpecialCharacters ? "valid" : "invalid"
                              }
                            >
                              A Special Character
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="right-section">
                    <InputBox
                      id="username"
                      header="Username"
                      onChange={(e) => {
                        const value = e.target.value;
                        const regex = /^[a-zA-Z0-9]*$/;
                        if (value.match(regex) || value === "") {
                          setUsername(value);
                        }
                      }}
                      error={errors["isUsernameEmpty"]}
                      value={Username}
                      onFocus={() => {
                        clearError("isUsernameEmpty");
                        // clearError("isUsernameUnique");
                      }}
                      helperText={errors["isUsernameUnique"] && "Already used"}
                    />
                    <InputBox
                      id="email"
                      header="Email"
                      // onChange={(e) => setEmail(e.target.value)}
                      // error={errors["isEmailEmpty"]}
                      value={Email}
                      disabled
                    // onBlur={emailValidation}
                    // onFocus={() => clearError("isEmailEmpty")}
                    // helperText={errors["isEmailUnique"] && "Already used"}
                    />
                    <div id="_country_">
                      <InputBox
                        id="state"
                        header="State"
                        disabled={!LocationSelected.isCountrySelected}
                        onChange={(e) => {
                          const value = e.target.value;
                          const regex = /^[a-zA-Z ]*$/;
                          if (value.match(regex) || value === "") {
                            values.state = null;
                            setState(value);
                            setLocationLoading((prevState) => ({
                              ...prevState,
                              state: true,
                            }));
                          } else {
                            setLocationLoading((prevState) => ({
                              ...prevState,
                              state: false,
                            }));
                          }
                        }}
                        error={errors["isStateEmpty"]}
                        value={State}
                        onFocus={() => clearError("isStateEmpty")}
                      />
                      {LocationLoading.state && !isDataEmpty ? (
                        <div className="suggestions">
                          <LoadingText />
                        </div>
                      ) : (
                        LocationData.state.length > 0 &&
                        !LocationSelected.isStateSelected && (
                          <div className="suggestions">
                            <Scrollbars autoHeight autoHeightMax="15vh">
                              {LocationData.state.map((item) => (
                                <div
                                  key={item.state}
                                  className="suggestion_item"
                                  data-state={item.state}
                                  onClick={() => {
                                    values.state = item.state;
                                    isExact = true;
                                    isDataEmpty = null;
                                    setState(item.state);
                                    setLocationSelected((prevState) => ({
                                      ...prevState,
                                      isStateSelected: true,
                                    }));
                                  }}
                                >
                                  {item.state}
                                </div>
                              ))}
                            </Scrollbars>
                          </div>
                        )
                      )}
                    </div>
                    <MultiSelectDropdown
                      error={errors["isIndustryEmpty"]}
                      optionsList={[
                        "Healthcare",
                        "Banking",
                        "BSFI",
                        "Textile",
                        "Logistics",
                      ]}
                      label="Industry"
                      id="Action"
                      defaultText={Industry}
                      handleOption={(data) => {
                        setIndustry([...data]);
                      }}
                      onFocus={(e) => clearError("isIndustryEmpty")}
                    />
                    <InputBox
                      id="password2"
                      error={errors["isCPasswordEmpty"]}
                      type={oldType2}
                      value={ConfirmPassword}
                      typeValue={(data) => handleConfirmPassword(data)}
                      password
                      header="Confirm Password"
                      onChange={(e) => {
                        const value = e.target.value;
                        const regex = /^[a-zA-Z0-9!@#$%&()+]*$/;
                        if (value.match(regex) || value === "") {
                          setConfirmPassword(value);
                        }
                      }}
                      onFocus={() => clearError("isCPasswordEmpty")}
                      onBlur={() => {
                        let _errors = { ...errors };
                        if (Password !== ConfirmPassword) {
                          _errors["isPasswordEmpty"] = true;
                          _errors["isCPasswordEmpty"] = true;
                        } else {
                          _errors["isPasswordEmpty"] = false;
                          _errors["isCPasswordEmpty"] = false;
                        }
                        setErrors({ ..._errors });
                      }}
                    />
                  </div>
                </div>
                <p
                  style={{
                    fontSize: "0.78125vw",
                    color: " #111b60",
                    fontWeight: 600,
                    marginBottom: "5px",
                  }}
                >
                  Profile Picture
                </p>
                <div className="profile_pic_uploader_wrapper">
                  <div className="profile_holder">
                    {ProfilePic ? (
                      <img
                        className="no_profile  fadeIn"
                        src={URL.createObjectURL(ProfilePic)}
                      />
                    ) : (
                      <NoProfile />
                    )}
                  </div>
                  <Dropzone
                    multiple={false}
                    accept={{ "image/jpeg": [], "image/png": [] }}
                    onDrop={(acceptedFiles) => {
                      setProfilePic(acceptedFiles[0]);
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div {...getRootProps({ className: "dropzone" })}>
                        <input {...getInputProps()} />
                        <p
                          title={ProfilePic && ProfilePic.name}
                          className="file_name"
                        >
                          {ProfilePic
                            ? ProfilePic.name
                            : "Drag & Drop or choose file"}
                        </p>
                      </div>
                    )}
                  </Dropzone>
                  <div>
                    {ProfilePic ? (
                      <div style={{ width: "7vw", marginLeft: "-20px" }} />
                    ) : (
                      <p className="no_file">No file choosen</p>
                    )}
                  </div>
                </div>
                <div
                  className="recaptcha"
                  id="recaptcha"
                  style={{ marginBottom: "0.5vw" }}
                >
                  <ReCAPTCHAV2
                    data-type="image"
                    sitekey={process.env.REACT_APP_SITE_KEY}
                    onChange={(value) => {
                      document.getElementById("recaptcha").style.border = "0";
                      setCaptcha(value);
                      setErrors((prevState) => ({
                        ...prevState,
                        isCaptchaEmpty: false,
                      }));
                    }}
                    size="normal"
                  />
                  {/* {true < 850 && (
              <ReCAPTCHAV2
                data-type="image"
                sitekey="6LcaF0IbAAAAAGhlDZbAFM0mOEI3GOKFr1ao9N9_"
                // onChange={onChange}
                size="compact"
              />
            )}
            {false > 850 && (
              <ReCAPTCHAV2
                data-type="image"
                sitekey="6LcaF0IbAAAAAGhlDZbAFM0mOEI3GOKFr1ao9N9_"
                // onChange={onChange}
                size="normal"
              />
            )} */}
                </div>
              </Scrollbars>
            </div>
            <div className="_tos" style={{ marginTop: "1vw" }}>
              <Radio
                id="checkbox_002"
                checked={TOS}
                onClick={() => {
                  setTOS(!TOS);
                }}
                className="adjust_cb"
              />
              <p>
                Creating an account means you’re okay with our{" "}
                <span>
                  Terms of Service,
                  <br /> Privacy Policy,
                </span>{" "}
                and our default <span>Notification Settings</span>.
              </p>
            </div>
            <Button
              style={{
                display: "block",
                margin: "2vw auto",
                width: "90%",
              }}
              type="gradient"
              bg
              name="Create Account"
              onClick={postData}
            />
          </React.Fragment>
        )}
        {ShowManufacturer && (
          <React.Fragment>
            <FormHeader name="Registering " bold="Manufacturer." />
            <p className="sub">Join us to make AI reach the masses.</p>
            <Scroller autoHeightMax={"27vw"}>
              <div style={{ paddingRight: "1.2vw" }}>
                <InputBox
                  id="companyName"
                  header="Company name"
                  onChange={(e) => {
                    const value = e.target.value;
                    setCompanyName(value);
                  }}
                  value={CompanyName}
                  onFocus={() => {
                    document.getElementById("companyName").style.border =
                      "2px solid #dddddd";
                  }}
                />
                <p className="text-area-label">Message</p>
                <textarea
                  id="msg_"
                  className="msg_area"
                  rows="5"
                  cols="50"
                  value={Message}
                  onChange={(e) => setMessage(e.target.value)}
                  onFocus={() => {
                    document.getElementById("msg_").style.border =
                      "2px solid #dddddd";
                  }}
                ></textarea>

                {ShowBank && (
                  <React.Fragment>
                    <div className="country_section">
                      <InputBox
                        id="country2"
                        header="Country"
                        onChange={(e) => {
                          const value = e.target.value;
                          const regex = /^[a-zA-Z ]*$/;
                          if (value.match(regex) || value === "") {
                            setCountry2(value);
                            setLocationLoading((prevState) => ({
                              ...prevState,
                              country: true,
                            }));
                          } else {
                            setLocationLoading((prevState) => ({
                              ...prevState,
                              country: false,
                            }));
                          }
                          setName("");
                          setPhone("+91 ");
                          setIsBankAvailable(false);
                        }}
                        onFocus={() => {
                          handleFocus("country2");
                        }}
                        onKeyDown={getKeyCode}
                        value={Country2}
                      />
                      {LocationLoading.country && Country2 ? (
                        <div className="suggestions">
                          <LoadingText />
                        </div>
                      ) : (
                        LocationData.length > 0 && (
                          <div className="suggestions">
                            <Scrollbars autoHeight autoHeightMax="15vh">
                              <span className="_span" />
                              {LocationData.map((item) => (
                                <div
                                  key={item.country}
                                  className="suggestion_item"
                                  data-country={item.country}
                                  onClick={() => {
                                    setCountry2(item.country);
                                    setLocationData([]);
                                    getBank(item.country);
                                    setLocationSelected((prevState) => ({
                                      ...prevState,
                                      isCountrySelected: true,
                                    }));
                                    firstLoad = true;
                                    setTimeout(() => {
                                      firstLoad = false;
                                    }, 2000);
                                  }}
                                >
                                  {item.country}
                                </div>
                              ))}
                            </Scrollbars>
                          </div>
                        )
                      )}
                    </div>
                    <div style={{ marginTop: "1vw" }} />
                    {IsBankAvailable && (
                      <div className="contact_person">
                        <span className="radio-header">Contact Person</span>
                        <div className="bank_detail">
                          <InputBox
                            id="full_Name"
                            header="Name"
                            onChange={(e) => {
                              const value = e.target.value;
                              const regex = /^[a-zA-Z ]*$/;
                              if (value.match(regex) || value === "") {
                                setName(value);
                              }
                            }}
                            value={Name}
                            onFocus={() => handleFocus("full_Name")}
                          />
                          <PhoneInputBox
                            id="_mobile_number_"
                            isEdit={true}
                            onChange={(data) => {
                              // if (data.inputNumber) {
                              let _number =
                                data.countryCode + " " + data.inputNumber;
                              setPhone(_number);
                              // }
                            }}
                            onFocus={() => handleFocus("_mobile_number_")}
                            value={Phone}
                          />
                        </div>
                      </div>
                    )}
                  </React.Fragment>
                )}

                {IsBankAvailable && (
                  <span className="radio-header">Bank Details</span>
                )}

                {IsBankAvailable && (
                  <div className="bank_detail">
                    {Bank?.fields?.map((item, idx) => renderElement(item, idx))}
                  </div>
                )}
                {!IsBankAvailable &&
                  !LocationLoading.country &&
                  LocationSelected.isCountrySelected && (
                    <div className="noBank">
                      Your country's bank is currently not supported. Please
                      Contact us for further query.
                    </div>
                  )}
                <div className="recaptcha" style={{ marginBottom: "0.5vw" }}>
                  <ReCAPTCHAV2
                    data-type="image"
                    sitekey={process.env.REACT_APP_SITE_KEY}
                    // onChange={onChange}
                    size="normal"
                  />
                </div>
              </div>
            </Scroller>
            <div className="_tos" style={{ marginTop: "1vw" }}>
              <Radio
                id="checkbox_002"
                checked={TOS}
                onClick={() => {
                  if (TOS === false) {
                    document.querySelector("._tos").style.border = "unset";
                    document.querySelector("._tos").style.padding = "unset";
                  }
                  setTOS(!TOS);
                }}
                className="adjust_cb"
              />
              <p>
                Creating an account means you’re okay with our{" "}
                <span>
                  Terms of Service,
                  <br /> Privacy Policy,
                </span>{" "}
                and our default <span>Notification Settings</span>.
              </p>
            </div>
            <Button
              onClick={postData2}
              style={{
                display: "block",
                margin: "2vw auto",
                marginBottom: "0",
                width: "90%",
              }}
              type="gradient"
              bg
              name="Submit"
            />
          </React.Fragment>
        )}
      </FormCard>

      <AnimatedModal modalOpen={ShowOTP} className="otp_modal_adjust">
        <OTP
          showOTP={ShowOTP}
          setShowOTP={setShowOTP}
          postVerifyOTP={(otp_) => {
            postVerifyOTP(otp_);
          }}
          setOTPCloseConfirm={setOTPCloseConfirm}
          postResendOTP={postResendOTP}
          resendFunc={(func) => {
            resetCounter = func;
          }}
          _stop={Stop}
          setStop={setStop}
        />
      </AnimatedModal>
      <AnimatedModal
        modalOpen={OTPCloseConfirm}
        onConfirm={() => {
          setOTPCloseConfirm(false);
          setShowOTP(false);
          encryptStorage.removeItem("VID");
        }}
        handleClose={() => {
          setOTPCloseConfirm(false);
        }}
        type={"confirm"}
        errorText={"Are you sure you want to close?"}
        alwayOpen={true}
      />
      <AnimatedModal
        modalOpen={ShowAlert}
        // modalOpen={true}
        handleClose={() => {
          if (
            msg.errorText ===
            "Request to become a manufacturer sent successfully!"
          ) {
            encryptStorage.removeItem("UCRED");
            encryptStorage.removeItem("UID");
            history.push("/");
          } else if (
            msg.errorText ===
            "Failed to validate token. Redirecting to login page."
          ) {
            history.push("/");
          } else {
            setShowAlert(false);
          }
        }}
        type={msg.type}
        errorHeader={msg.header}
        errorText={msg.errorText}
        // timeout={5000}
        alwayOpen={true}
      />
      {LoadingScreen && <Loading type={"transparent"} text={"Loading"} />}
    </div>
  );
}

const NoProfile = () => {
  return (
    <svg
      className="no_profile"
      viewBox="-8 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 96c48.6 0 88 39.4 88 88s-39.4 88-88 88-88-39.4-88-88 39.4-88 88-88zm0 344c-58.7 0-111.3-26.6-146.5-68.2 18.8-35.4 55.6-59.8 98.5-59.8 2.4 0 4.8.4 7.1 1.1 13 4.2 26.6 6.9 40.9 6.9 14.3 0 28-2.7 40.9-6.9 2.3-.7 4.7-1.1 7.1-1.1 42.9 0 79.7 24.4 98.5 59.8C359.3 421.4 306.7 448 248 448z" />
    </svg>
  );
};

let dummyBank = {
  country: "India",
  fields: [
    { name: "bank_name", required: true, type: "string", options: [] },
    { name: "branch_name", required: true, type: "string", options: [] },
    { name: "ifsc_code", required: true, type: "string", options: [] },
    { name: "account_number", required: true, type: "string", options: [] },
    {
      name: "account_type",
      required: true,
      type: "dropdown",
      options: ["Savings", "Current"],
    },
  ],
};
