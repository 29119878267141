import React, { useEffect, useRef, useState } from "react";
import Button from "../../../components/Button/Button";
import AnimatedModal from "../../../components/Modal/AnimatedModal";
import Scroller from "../../../components/Scroller/ScrollBar";
import { closeIcon } from "../../../helper/icons";
import CreatedLicenseCard from "../../user/Inventory/Component/CreatedLicenseCard/CreatedLicenseCard";

export default function SelectLicense({
  FetchLicense,
  setFetchLicense,
  StandardLicenses,
  setStandardLicenses,
  onBuyClick,
  specialLicense,
  getSpecialLicenses,
  specialLicenseTotalCount,
  changePage,
  setChangePage,
  flag,
  setFlag,
  selectCard,
  ShowAlert,
  setShowAlert,
  serialNumber,
  msg,
  getLicenses,
  manuName,
  clickedAppName,
  clickedThumbnail,
  type = "app",
}) {
  const userScroll = useRef();
  const arr = ["attribute_name", "monthly", "quarterly", "yearly", "lifetime"];
  const [IsActive, setIsActive] = useState("standard");
  const scrollbar = useRef(null);
  const handleScroll = () => {
    if (scrollbar.current.getValues().top > 0.95) {
      if (
        changePage - 1 <= Math.floor(specialLicenseTotalCount / 10) &&
        !flag
      ) {
        setFlag(true);
        setChangePage(changePage + 1);
        getSpecialLicenses(changePage);
      }
    }
  };

  return (
    <div className="fetch_user_license">
      <AnimatedModal
        modalOpen={FetchLicense}
        className="fetch_user_license_modal"
      >
        <div className="fetch_user_license_modal_wrapper">
          <div
            className="close_otp_modal"
            onClick={() => {
              setFetchLicense(false);
              //   setMarkupModal(false);
              //   setIsActive("");
            }}
          >
            {closeIcon()}
          </div>
          <p className="assign_para">Assign</p>
          <div className="select_license_type">
            <p
              onClick={() => {
                setIsActive("standard");
                getLicenses(manuName);
              }}
              style={{
                background: IsActive === "standard" && "var(--primary)",
                color: IsActive === "standard" && "white",
                borderRadius: "6px 0px 0px 6px",
                boxShadow: "0px 1px 10px 4px rgb(0 0 0 / 7%)",
              }}
              className="standard_license_header"
            >
              Standard License
            </p>
            <p
              onClick={() => {
                setIsActive("special");
                setChangePage(1);
                getSpecialLicenses((changePage = 1));
              }}
              style={{
                background: IsActive === "special" && "var(--primary)",
                color: IsActive === "special" && "white",
                borderRadius: "0px 6px 6px 0px",
                boxShadow: "0px 1px 10px 4px rgb(0 0 0 / 7%)",
              }}
              className="standard_license_header"
            >
              Special License
            </p>
          </div>
          {IsActive === "standard" && StandardLicenses?.length !== 0 ? (
            <div className="license_table_wrapper">
              <Scroller autoHeightMax="20vw" ref={userScroll}>
                {StandardLicenses?.map((item, index) => {
                  return (
                    <div className="standard_license_card">
                      <div className="license_name_div">
                        <p className="license_name_para">
                          {item.license_details.name}
                        </p>
                        {/* <p className="warning_icon">{warningIcon()} Note</p> */}
                      </div>
                      <div className="license_table">
                        <table>
                          <tr>
                            {arr?.map((header, index) => {
                              return header === "attribute_name" ? (
                                <th style={{ width: "4vw" }}>Name</th>
                              ) : (
                                <th
                                  style={{ textAlign: "center" }}
                                  key={header + index}
                                >
                                  {header.replace(/_/g, " ")}
                                </th>
                              );
                            })}
                          </tr>
                          {item.pricing_details?.map((item, idx) => {
                            return (
                              <tr>
                                {arr?.map((header) => {
                                  return header === "attribute_name" ? (
                                    <td>{item[header]}</td>
                                  ) : (
                                    <td style={{ textAlign: "center" }}>
                                      {item[header].amount}
                                    </td>
                                  );
                                })}
                              </tr>
                            );
                          })}
                        </table>
                      </div>
                      <div className="buy_btn_div">
                        <Button
                          style={{ width: "6vw", padding: "0.2vw" }}
                          id="buy_license_btn"
                          name="Buy"
                          onClick={() => {
                            onBuyClick(index);
                          }}
                        />
                      </div>
                    </div>
                  );
                })}
              </Scroller>
            </div>
          ) : IsActive === "special" && specialLicense?.length !== 0 ? (
            <div className="license_table_wrapper">
              <Scroller
                autoHeightMax="20vw"
                verticalStyle={{
                  width: "0.2vw",
                  marginLeft: "0vw",
                  backgroundColor: "#283e92",
                }}
                onScroll={handleScroll}
                ref={scrollbar}
              >
                {specialLicense?.map((item) => (
                  <div className="special_license_card">
                    <CreatedLicenseCard
                      licenseName={item?.license_name}
                      licenseDuration={item?.license_duration}
                      licenseDetails={item?.license_details}
                      licenseQuantity={item?.total_count}
                      selectCard={selectCard}
                      specialId={item.special_license_id}
                      setFetchLicense={setFetchLicense}
                      ShowAlert={ShowAlert}
                      setShowAlert={setShowAlert}
                      serialNumber={serialNumber}
                      getSpecialLicenses={getSpecialLicenses}
                      msg={msg}
                      type={type}
                      clickedAppName={clickedAppName}
                      clickedThumbnail={clickedThumbnail}
                    />
                  </div>
                ))}
              </Scroller>
            </div>
          ) : (
            <div className="license_table_wrapper">
              <h1
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                No License Found
              </h1>
            </div>
          )}
        </div>
      </AnimatedModal>
    </div>
  );
}

const warningIcon = () => {
  return (
    <svg
      style={{ width: "0.8vw", height: "0.8vw", fill: "#FFA800" }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <path d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z" />
    </svg>
  );
};
