import React, { useState, useEffect } from "react";
import Button from "../../../components/Button/Button";
import Dropdown from "../../../components/Dropdown/Dropdown";
import AnimatedModal from "../../../components/Modal/AnimatedModal";
import { closeIcon, logs } from "../../../helper/icons";
import { API_URL, axiosApiInstance } from "../../../helper/request";
import { notify } from "../../../Routes";
import { motion } from "framer-motion";
import Counter from "../../../components/Counter/Counter";
import Scroller from "../../../components/Scroller/ScrollBar";
import { useRef } from "react";
import { ThemeContext } from "../../../helper/context";
import { useContext } from "react";
import "./assignInventory.scss";
import { Radio } from "../../../components/Radio/Radio";
import Toggle from "../../../components/Toggle/Toggle";
import InputBox from "../../../components/Inputbox/InputBox";
import { item } from "../../../helper/motions";
import { encryptStorage } from "../../../helper/storage";
import AssignInventoryCard from "./Component/AssignInventoryCard/AssignInventoryCard";
import { useDebouncedEffect } from "../../../helper/useDebounce";
let attData = {
  details: {
    source: 0,
  },
};
export default function AssignInventory({
  createLicenseOpen,
  setCreateLicenseCard,
  Attributedata,
  setAttributeData,
  getCreatedLicense,
  setAttributeDataArr,
  attributeDataArr,
  msg,
  setShowAlert,
  type,
  appName
}) {
  const { getCart } = useContext(ThemeContext);
  const [isLoading, setisLoading] = useState(true);
  // const [ShowAlert, setShowAlert] = useState(false);
  const [Data, setData] = useState([]);
  const [DisableArr, setDisableArr] = useState([]);
  const [SelectedType, setSelectedType] = useState("monthly");
  const [planName, setPlanName] = useState("");
  const [PlanNameError, setPlanNameError] = useState("");
  const [StructuredData, setStructuredData] = useState({
    license_type: "device",
    license_duration: "monthly",
    license_details: {},
  });
  const [attDataIndex, setAttDataIndex] = useState([]);
  const [attributeNameEmpty, setAttributeNameEmpty] = useState(false);

  const handleCounter = (idx, name, key, markup, type) => {
    let _data = [...Data];
    if (type == "increment") {
      _data[idx][key].markup += 1;
      StructuredData.license_details[name] += 1;
    } else {
      if (markup >= 2) {
        _data[idx][key].markup -= 1;
        StructuredData.license_details[name] -= 1;
      }
    }
    setData([..._data]);
  };
  // useDebouncedEffect(
  //   () => (SelectedType ? validateAttributeName() : undefined),
  //   [SelectedType],
  //   1000

  // );

  const addNewAttributeCard = () => {
    let newArr = [...Attributedata];
    let object = {
      Name: "",
      Quantity: 0,
    };

    newArr.push(object);
    setAttributeData([...newArr]);
  };
  const printError = (id) => {
    document.getElementById(id)?.classList.add("error_shake", "input__error");
  };
  const [errors, setErrors] = useState({
    isPlanNameUnique: false,
    isPlanNameEmpty: false,
    isAttributeNameEmpty: false,
  });

  const validateAttributeDropdown = (data, id = null) => {
    let res = false;
    for (let i = 0; i < Attributedata.length; i++) {
      if (Attributedata[i].Name === "") {
        res = true;
        printError("attributeName" + i);
        if (id !== null) {
          document.querySelector("." + id).classList.remove("active__");
        }
      }
      if (Attributedata[i].Name !== "") {
        data !== null && setSelectedType(data);
      }
    }
  };
  const validateAttributeName = (data) => {
    let res = false;
    for (let i = 0; i < Attributedata.length; i++) {
      if (Attributedata[i].Name === "") {
        res = true;
        printError("attributeName" + i);
      }
    }
    !res && addNewAttributeCard();
    // !res && setSelectedType(data);
  };

  const validateData = () => {
    let res = false;
    let _errors = { ...errors };
    if (planName === "") {
      _errors["isPlanNameEmpty"] = true;
      res = true;
    }
    for (let i = 0; i < Attributedata.length; i++) {
      if (Attributedata[i].Name === "") {
        res = true;
        printError("attributeName" + i);
      }
      if (Attributedata[i].Name !== "") {
        setAttributeNameEmpty(true);
      }
    }
    setErrors({ ..._errors });

    // if (planName === "") {
    //   res = true;
    // }
    // for (let i = 0; i < Attributedata.length; i++) {
    //   if (Attributedata[i].Name === "") {
    //     res = true;
    //     return
    //   }
    // }
    {
      !res && postPlanData();
    }
    res === false && setCreateLicenseCard(false);
    res === false && setPlanName("");
  };

  const clearError = (name) => {
    let _errors = { ...errors };
    _errors[name] = false;
    setErrors({ ..._errors });
  };

  useDebouncedEffect(
    () => (planName ? uniqueCheck() : undefined),
    [planName],
    1000
  );
  const uniqueCheck = () => {
    let url = `inventory/special/licence/name/uniquecheck?license_name=${planName}`;
    axiosApiInstance.get(API_URL + url).then((res) => {
      if (res.data.details) {
        setErrors((prevState) => ({
          ...prevState,
          isPlanNameUnique: true,
        }));
      } else {
        setErrors((prevState) => ({
          ...prevState,
          isPlanNameUnique: false,
        }));
      }
    });
  };
  const postPlanData = () => {
    let obj = {};
    Attributedata.map((details) => {
      obj[details["Name"]] = Number(details["Quantity"]);
    });

    type === "Special License" ?  axiosApiInstance
    .post(
      `inventory/admin/create/special_plans`,

      {
        license_name: planName.toLocaleLowerCase(),
        license_type: "app",
        license_duration: SelectedType,
        license_details: obj,
        app_license_name:appName
        
      }
    )
    .then((res) => {
      getCreatedLicense(1, "Special License");
        msg.errorText = "Successfully created License";
        msg.header = "Success";
        msg.type = "success";
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 3000);
    })
    .catch((err) => {
      msg.errorText = "Something went wrong";
      msg.header = "Error";
      msg.type = "alert";
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
    })

    // setLoadingScreen(true);
   : axiosApiInstance
      .post(
        `inventory/admin/create/special_plans`,

        {
          license_name: planName.toLocaleLowerCase(),
          license_type: "device",
          license_duration: SelectedType,
          license_details: obj,
          
        }
      )

      .then((res) => {
        msg.errorText = "Successfully created License";
        msg.header = "Success";
        msg.type = "success";
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 3000);
        getCreatedLicense(1, "Special License");

      })
      .catch((err) => {
        msg.errorText = "Something went wrong";
        msg.header = "Error";
        msg.type = "alert";
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 3000);
      });
  };

  const getDisabledStatus = () => {
    let res = false;
    if (planName === "") {
      res = true;
    }
    for (let i = 0; i < Attributedata?.length; i++) {
      if (Attributedata[i].Name === "") {
        res = true;
      }
    }
    if (Attributedata.length === 0) {
      res = true;
    }
    if (errors["isPlanNameUnique"]) {
      res = true;
    }

    if (!res) {
      return false;
    } else {
      return true;
    }
  };

  // useEffect(() => {
  //   // if licence is present then we need to call another api to fetch existing licence data and update the counter(we also need to check if the licence is expired or not, if licence is expire then counter should be 1)
  //   if (createLicenseOpen) {
  //     getData();
  //   }
  // }, [createLicenseOpen]);

  return (
    <div>
      <AnimatedModal
        modalOpen={createLicenseOpen}
        className="create_license_modal"
      >
        {/* <div className="create_license_modal"> */}
        <div
          className="close_otp_modal"
          onClick={() => {
            setDisableArr([]);
            setCreateLicenseCard(false);
            setPlanName("");
            clearError("isPlanNameEmpty");
            setErrors((prevState) => ({
              ...prevState,
              isPlanNameUnique: false,
            }));
          }}
        >
          {closeIcon()}
        </div>
        <div className="create_special_upper_wrapper">
          <InputBox
            // header="Plan name"
            placeholder="Name plan"
            error={errors["isPlanNameEmpty"]}
            value={planName}
            onChange={(e) => {
              const value = e.target.value;
              const alpharegex = /^[a-zA-Z0-9 ]*$/;
              if (value.match(alpharegex)) {
                setPlanName(value);
              }
            }}
            onBlur={(e) => {
              if (planName.length < 3) { setErrors((prevState)=>{
                return {...prevState,
                isPlanNameEmpty: true }
              })}
            }}
            onFocus={() => {
              clearError("isPlanNameEmpty");
              // clearError("isAppNameUnique");
              setPlanNameError("");
            }}
            helperText={
              errors["isPlanNameUnique"]
                ? "Plan name already exists"
                : ["isPlanNameEmpty"] && ""
            }
          />
          <Dropdown
            label={"Select duration"}
            className="adjust_dd"
            // id={"pricing_type"}
            optionsList={["monthly", "quarterly", "yearly", "lifetime"]}
            handleOption={(data) => {
              validateAttributeDropdown(data);
            }}
            id="dropdown"
            onFocus={(id) => validateAttributeDropdown(null, id)}
            defaultText={SelectedType}
          // handleOption={() => console.log("clicked")}
          />
        </div>

        {/* {isLoading && <div className="s_wrapper">{[1, 2].map((item) => "")}</div>} */}
        {/* {!isLoading ? ( */}
        <Scroller autoHeightMax={"15vw"}>
          <div className="create_special_lower_wrapper">
            {/* {Attributedata.map((item, index) => (
              <AssignInventoryCard
                setAttributeData={setAttributeData}
                Item={item}
                index={index}
                CardAttributeData={Attributedata}
              />
            ))} */}
            {Attributedata?.map((item, index) => (
              <AssignInventoryCard
                setAttributeData={setAttributeData}
                AttributeData={Attributedata}
                Item={item}
                index={index}
                attributeDataArr={attributeDataArr}
                setAttributeDataArr={setAttributeDataArr}
                errors={errors}
                setErrors={setErrors}
                setAttDataIndex={setAttDataIndex}
                attDataIndex={attDataIndex}
              // clearError={clearError()}
              />
            ))}

            <div
              className="addattribute_card"
              onClick={() => validateAttributeName()}
            >
              +
            </div>
            {/* <AnimatedModal
            modalOpen={ShowAlert}
            handleClose={() => {
              setShowAlert(false);
            }}
            type={msg.type}
            errorHeader={msg.header}
            errorText={msg.errorText}
            // timeout={5000}
            alwayOpen={true}
          /> */}
          </div>
        </Scroller>
        <div className="btn_wrapper">
          <Button
            disabled={getDisabledStatus()}
            name="Next"
            onClick={() => {
              validateData();
            }}
          />
        </div>

        {/* ) : null} */}
        {/* </div> */}
      </AnimatedModal>
    </div>
  );
}
