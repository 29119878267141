import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import Routes from "./Routes";
import "./master.scss";
import { ThemeProvider } from "./helper/context";
import "react-toastify/dist/ReactToastify.css";
if (process.env.NODE_ENV === 'production') {
  console.log = () => {}
  console.debug = () => {}
}
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeProvider>
        <Routes />
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>
);
