import React, { useState, useEffect, useRef } from "react";
import Navbar from "../../../components/Navbar/Navbar";
import "./cart.scss";
import bg from "../../../assets/images/background.png";
import Table from "../../../components/Table/Table";
import { axiosApiInstance } from "../../../helper/request";
import { notify } from "../../../Routes";
import { deviceIcon } from "../../../helper/icons";
import InputBox from "../../../components/Inputbox/InputBox";
import Button from "../../../components/Button/Button";
import { PayPalScriptProvider, PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import { ThemeContext } from "../../../helper/context";
import { useContext } from "react";
import { useHistory } from "react-router-dom";
import AnimatedModal from "../../../components/Modal/AnimatedModal";
let msg = {
  type: "alert",
  header: "Error",
  errorText: "Server Error",
};
let _amount = "";
const header = ["Item", "License Name", "Plan Type", "Price", ""];
const AppHeader = ["Item", "Device", "License Name", "Plan Type", "Price", ""];
export default function Cart() {
  const { getCart, Cart, CartData } = useContext(ThemeContext);
  const history = useHistory();

  const [isLoading, setisLoading] = useState(false);
  const [Data, setData] = useState([]);
  const [Coupon, setCoupon] = useState({
    coupon: "",
    amount: "",
  });
  const [AppCart, setAppCart] = useState("");
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  // const getData = () => {
  //   setisLoading(true);
  //   console.log("getData");
  //   axiosApiInstance
  //     .get("order/cart")
  //     .then((res) => {
  //       _amount = res.data.amount;
  //       // setCartId(res.data.cart_id);
  //       setData([...res.data.cart_items]);
  //       setAppCart(Data.map((item) => item.usecase.usecase_name))
  //       setCoupon({ ...Coupon, amount: res.data.amount.toString() });
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       notify({});
  //     })
  //     .finally(() => {
  //       setisLoading(false);
  //     });
  // };
  const pushToOrders = () => {
    history.push("/order")
  }
  const patchCartDetails = () => {
    axiosApiInstance.patch(
      "order/cart/status",
      {
        cart_id: CartData.cart_id
      })
  }
  const deleteData = (id) => {
    setisLoading(true);
    axiosApiInstance
      .delete("order/cart?id=" + id)
      .then((res) => {
        notify({
          type: "success",
          msg: "Item removed",
        });
        // getData();
        getCart();
      })
      .catch((err) => {
        notify({});
      })
      .finally(() => {
        // getData();
        setisLoading(false);
      });
  };

  const postOrder = (details) => {
    axiosApiInstance
      .post("order/webhooks", {
        ...details
        // transaction: {
        //   transaction_id: details.id,
        //   payment_method: "paypal",
        //   payment_mode: "online",
        //   transaction_data: { payer: { ...details.payer } },
        //   status: "success",
        //   created: details.create_time,
        //   updated: details.update_time,
        // },
        // coupon: Coupon.coupon ? Coupon.coupon : null,
      })
      .then((res) => {
        // getData();
        // getCart();
        // notify({
        //   msg: "Order created!",
        //   type: "success",
        // });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const createOrder = (data, actions) => {
    console.log("Creating order for amount", CartData.amount);
    let item = [];

    return actions.order
      .create({
        purchase_units: [
          {
            // reference_id: "PUHF",
            // description: "Some description",
            // custom_id: "Something7364",
            // soft_descriptor: "Great description 1",
            amount: {
              value: CartData.amount,
              currency_code: "USD",
              // breakdown: {
              //   item_total: { value: _amount, currency_code: "USD" },
              // },
            },
            // invoice_id: "muesli_invoice_id",
            // items: [
            //   {
            //     name: "Hafer",
            //     description: "BUYING DEVICE",
            //     unit_amount: {
            //       currency_code: "USD",
            //       value: _amount,
            //     },
            //     quantity: "1",
            //     description: "",
            //   },
            // ],
          },
        ],
      })
      .then((orderID) => {
        axiosApiInstance.post("order/cartdata", {
          order_id: orderID,
          cart_id: CartData.cart_id,
        });
        console.log(orderID, "orderid");
        //  this.setState({ orderID: orderID });
        return orderID;
      });
  };

  const onApprove = (data, actions) => {
    console.log(data, "actions");
    //  let app = this;
    return actions.order.capture().then(function (details) {
      console.log(JSON.stringify(details));
      // postOrder(details);
      //  app.setState({
      //    onApproveMessage: `Transaction completed by ${details.payer.name.given_name}!`,
      //  });
      msg.errorText = "Order completed successfully";
      msg.header = "Success";
      msg.type = "success";
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
        pushToOrders()

      }, 2000);
      getCart()
    });
    //order completed success popup
  };

  const onError = (err) => {
    console.log(err); //payment failed
    msg.errorText = "Payment failed";
    msg.header = "error";
    msg.type = "alert";
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
    }, 2000);
  };

  const onClick = () => {
    // console.log("When clicked, amount was", _amount);
  };
  useEffect(() => {
    console.log(Cart, "cart");
    console.log(CartData, "cartiddddd");
    console.log(Cart.length, "Cart.length");
    // Cart.length > 0 &&
    setAppCart(Cart.map((item) => item?.usecase?.usecase_name));
    console.log(CartData.amount, "amounttttt");
    // getData();
  }, [Cart]);

  const renderTableData = () => {
    console.log("renderTableData");
    return Cart?.map((item, idx) => {
      return (
        <tr>
          <td>
            <div className="userInfo">
              {item.item_type === "device" ? (
                <div className="profile_image_container">{deviceIcon()}</div>
              ) : (
                <img
                  alt={"username" + idx}
                  className="user_avatar_img"
                  src={item.usecase.usecase_image}
                />
              )}
              <div className="meta">
                <p style={{ textTransform: "capitalize" }}>
                  {item.item_type === "device" ? "Device" : "App"}
                </p>
                <p>
                  {item.item_type === "device"
                    ? item.device.device_name
                    : item.usecase.usecase_name}
                </p>
              </div>
            </div>
          </td>
          {AppCart && (
            <td style={{ textTransform: "capitalize" }}>
              <p
                style={{ cursor: "pointer" }}
                onClick={() => {
                  history.push("/device");
                }}
              >
                {item.device.device_name}
              </p>
            </td>
          )}
          <td style={{ textTransform: "capitalize" }}>
            {item.item_details.license_name}
          </td>
          <td
            style={{
              textTransform: "capitalize",
              display: "grid",
              placeItems: "center",
            }}
          >
            {item.item_details.license_duration}
            <div
              style={{
                border: "1px solid #ddd",
                borderRadius: "0.4vw",
                display: "inherit",
                padding: "0.4vw",
              }}
            >
              {Object.keys(item.item_details.license_details).map((items) => (
                <p>
                  {items} : {item.item_details.license_details[items]}
                </p>
              ))}
            </div>
            {/* <div>
              {Object.keys(item.item_details.license_du)}
            </div> */}
          </td>
          <td>{item.new_amount}$</td>
          <td>
            <div className="remove" onClick={() => deleteData(item.id)}>
              Remove
            </div>
          </td>
        </tr>
      );
    });
  };
  return (
    <div className="_cart_wrapper_">
      {console.log(Cart)}
      <Navbar sidenav>
        <div className="cart_body">
          <img src={bg} className="profile_bg" />
          <div className="body_content fadeIn">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h1>My Cart</h1>
              <p
                className="goBack"
                onClick={() => history.push("/device" + window.location.search)}
              >
                Go Back
              </p>
            </div>
            <div className="cart_data">
              <div className="cart_item">
                {Cart.length > 0 ? (
                  <Table
                    className="cart_adjust_table"
                    header={AppCart ? AppHeader : header}
                    tableData={renderTableData}
                    autoHeightMax={"52vh"}
                  />
                ) : (
                  <div className="noData">
                    {emptyCartIcon()}
                    <h3 className="cart_text">Your cart is empty</h3>
                    <p>Add something to make me happy :)</p>
                  </div>
                )}
              </div>
              {Cart.length > 0 && !isLoading && (
                <div className="cart_total">
                  <h1>Cart Value</h1>
                  <div className="subtotal">
                    <p>Subtotal</p>
                    <p>{CartData.amount}$</p>
                  </div>
                  {/* <div className="coupon">
                    <InputBox
                      header="Coupon?"
                      placeholder="Enter your code"
                      onChange={(e) =>
                        setCoupon({ ...Coupon, coupon: e.target.value })
                      }
                      value={Coupon.coupon}
                    />
                    <Button
                      name={"Apply"}
                      disabled={Coupon.coupon.length === 0}
                    />
                  </div> */}
                  <div className="total">
                    <div className="subtotal">
                      <p>Total</p>
                      <span>-</span>
                      <p>{CartData.amount}$</p>
                    </div>
                  </div>

                  <PayPalScriptProvider
                    options={{
                      "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
                    }}
                    showSpinner={true}
                  >
                    <PayPalButtons
                      createOrder={createOrder}
                      onApprove={onApprove}
                      onError={onError}
                      onClick={onClick}
                      onCancel={(data, actions) => {
                        // console.log("onCancel");
                        console.log(data, actions, "onCancel");
                        msg.errorText = "Payment cancelled!";
                        msg.header = "error";
                        msg.type = "alert";
                        setShowAlert(true);
                        setTimeout(() => {
                          setShowAlert(false);
                        }, 2000);
                        patchCartDetails()
                        // setConfirmationModal(true);
                        //confirmation modal, do you want to cancel payment
                      }}
                      style={{
                        label: "checkout",
                      }}
                    />
                  </PayPalScriptProvider>

                  {/* <Button
                    name={"Checkout"}
                    onClick={() => {
                      let item = [];
                      let obj = {
                        name: "Hafer",
                        description: "BUYING DEVICE",
                        unit_amount: {
                          currency_code: "USD",
                          value: _amount,
                        },
                        quantity: "1",
                        description: "",
                      };
                      for (let i = 0; i < Data.length; i++) {
                        obj.name =
                          Data[i].item_type === "usecase"
                            ? Data[i].usecase.usecase_name
                            : Data[i].device.device_name;
                        obj.description =
                          "Buying" + Data[i].item_type + "for" + _amount + "$";
                        // obj.unit_amount.value=
                      }
                      console.log(item);
                    }}
                  /> */}
                </div>
              )}
            </div>
          </div>
        </div>
      </Navbar>
      {/* <AnimatedModal
        modalOpen={confirmationModal}
        onConfirm={() => {
          setConfirmationModal(false);
          console.log("first")
          // setShowOTP(false);
          // encryptStorage.removeItem("VID");
        }}
        handleClose={() => {
          setConfirmationModal(false);
        }}
        type={"confirm"}
        errorText={"Are you sure you want to cancel payment?"}
        alwayOpen={true}
      /> */}
      <AnimatedModal
        modalOpen={showAlert}
        // modalOpen={true}
        handleClose={() => {
          setShowAlert(false);
        }}
        type={msg.type}
        errorHeader={msg.header}
        errorText={msg.errorText}
        // timeout={5000}
        alwayOpen={true}
      />
    </div>
  );
}

const emptyCartIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={348.271}
    viewBox="0 0 348.271 494.398"
    className="emptyCartIcon"
  >
    <g
      id="Group_16009"
      data-name="Group 16009"
      transform="translate(-485.999 -168)"
    >
      <path
        id="shoppingbag_122339"
        d="M420.185,480.857,381.486,148.8a10.3,10.3,0,0,0-10.228-9.108H329.921c-8.12-64.4-25.332-107.314-42.433-124.108A53.41,53.41,0,0,0,247.874.031c-15.847.613-31.352,8.328-42.541,21.162-23.456,26.908-35.247,75.8-41.175,118.5H121.644a10.3,10.3,0,0,0-10.228,9.108L72.48,482.879c-.851,8.655,7.225,11.852,10.228,11.492H410.192a.944.944,0,0,1,.207,0A10.239,10.239,0,0,0,420.185,480.857ZM220.862,34.733c7.473-8.574,17.612-13.72,27.811-14.117a32.877,32.877,0,0,1,24.382,9.665c11.921,11.709,28.037,48.408,36.081,109.413H184.984C190.529,100.649,201.017,57.494,220.862,34.733Zm-90.05,125.561h30.855c-3.406,32.787-3.5,58.245-3.5,60.706h20.6c0-.6.1-27.085,3.67-60.706H311.442c1.708,18.376,2.7,38.612,2.7,60.706h20.6c0-21.731-.932-41.993-2.6-60.706H362.09l27.236,263.424H102.641ZM94.281,473.771l5.834-31.168h293.35l5.155,31.168Z"
        transform="translate(413.582 168)"
      />
      <rect
        id="Rectangle_149057"
        data-name="Rectangle 149057"
        width={13}
        height={33}
        transform="translate(595 412)"
      />
      <rect
        id="Rectangle_149058"
        data-name="Rectangle 149058"
        width={13}
        height={33}
        transform="translate(712 412)"
      />
      <path
        id="Path_134010"
        data-name="Path 134010"
        d="M-23427,2481.732c25.2,0,58.344-18.034,75.5-18.034,52.607,0,56.877,18.034,56.877,18.034"
        transform="translate(24022 -1963)"
        fill="none"
        // stroke="#000"
        strokeWidth={12}
      />
    </g>
  </svg>
);
