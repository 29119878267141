import { EncryptStorage } from "encrypt-storage";
import CryptoJS from "crypto-js";
import { useLocation } from "react-router-dom";
import React from "react";

export const encryptStorage = new EncryptStorage(process.env.REACT_APP_LSKEY, {
  prefix: "@PRO",
  storageType: "sessionStorage",
});

export const encrypt = (value) => {
  return CryptoJS.AES.encrypt(value, process.env.REACT_APP_LSKEY).toString();
};

export function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const decrypt = (textToDecrypt) => {
  return JSON.parse(
    CryptoJS.AES.decrypt(textToDecrypt, process.env.REACT_APP_LSKEY).toString(
      CryptoJS.enc.Utf8
    )
  );
};

export const getParams = () => {
  const params = new URLSearchParams(window.location.search);
  const paramsObj = Array.from(params.keys()).reduce(
    (acc, val) => ({ ...acc, [val]: params.get(val) }),
    {}
  );
  return paramsObj;
};

export const getDateTime = (dateTimeString) => {
  function dateOrdinal(d) {
    return (
      d +
      (31 == d || 21 == d || 1 == d
        ? "st"
        : 22 == d || 2 == d
        ? "nd"
        : 23 == d || 3 == d
        ? "rd"
        : "th")
    );
  }
  let today = new Date(dateTimeString);
  var strArray = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  // var dd = String(today.getDate()).padStart(2, "0");
  // var mm = String(today.getMonth() + 1).padStart(2, "0");
  var mm = strArray[today.getMonth()];
  var yyyy = today.getFullYear();
  // var pcdate = dd + "/" + mm + "/" + yyyy;
  // console.log(pcdate);
  var hours = today.getHours();
  var minutes = today.getMinutes();
  var seconds = today.getSeconds();
  var ampm = hours >= 12 ? "PM" : "AM";
  if (hours !== 0) {
    hours = hours % 12;
    hours = hours ? hours : 12;
  }
  hours = hours < 10 ? "0" + hours : hours;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  seconds = seconds < 10 ? "0" + seconds : seconds;
  var strTime =
    mm +
    " " +
    dateOrdinal(today.getDate()) +
    " " +
    yyyy +
    ", " +
    // + " <br /> " +
    hours +
    ":" +
    minutes +
    " " +
    ampm;
  return strTime;
};

export const timeAgo = (date) => {
  var created_date = new Date(date);
  var seconds = Math.floor((new Date() - created_date.getTime()) / 1000);
  var interval = seconds / 31536000;
  if (interval > 1) {
    return Math.floor(interval) + " years ago";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " months ago";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " days ago";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hours ago";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " minutes ago";
  }
  return Math.floor(seconds) + " seconds ago";
};

export const isSuspended = () =>
  encryptStorage
    .getItem("UID")
    .suspended.includes(encryptStorage.getItem("UID").current);
