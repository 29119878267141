import React, { useRef } from "react";
import "../ViewApp/AppImage.scss";
import video from "../../../../../assets/images/vredefort.mp4";
import default_img from "../../../../../assets/images/default_img.png";
import { CategoriesCard } from "../../../../../components/UsecaseCard/UsecardCard";
import Slider from "react-slick";

export default function AppImage({ uploadedAppImage }) {
  const categorySliderRef = useRef();

  const CategorySliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    arrows: true,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    // nextArrow: <Arrow type="next" />,
    // prevArrow: <Arrow type="prev" />,
  };

  return (
    <div>
      <div className="usecase_additionalimgs_wrapper">
        <h4 className="additional_images">Additional Images</h4>
        <div className="images_wrapper"></div>
      </div>
    </div>
  );
}

export const ImageCard = ({ viewAppImage, idx }) => {
  return (
    <div className="viewappimage_main_wrapper">
      <div className="view_images">
        <img
          className="view_image"
          src={
            viewAppImage
              ? viewAppImage
              : "https://source.unsplash.com/random/200x200?sig=" + idx
          }
        ></img>
      </div>
    </div>
  );
};

export const MediaCard = ({ mediaType, viewMedia, idx, id, mediavideoid }) => {
  return (
    <div className="viewappimage_main_wrapper">
      <div className="view_images">
        {mediaType === null || mediaType === "image" ? (
          <img
            className="view_image"
            src={
              viewMedia
                ? viewMedia
                : "https://source.unsplash.com/random/200x200?sig=" + idx
            }
          />
        )
          : (
            // <video>
            //   <source src={viewMedia} />
            // </video>
            <video 
            style={{objectFit:"cover"}}
            onClick={() =>  mediavideoid(mediavideoid = id)}
             width="490vw" height="300vw" 
             id={id} 
             controls  >
            <source src={viewMedia} />
          </video>
          )
        }
      </div>
    </div>
  );
};

// {mediaType === "image"?<img className="view_image" src={viewMedia? viewMedia: "https://source.unsplash.com/random/200x200?sig=" + idx}></img>}
