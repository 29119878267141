import React, { useContext, useState, useRef, useEffect } from "react";
import { motion } from "framer-motion";
// import "./devicedetail.scss";
import "../../user/Profile/profile.scss";
import Navbar from "../../../components/Navbar/Navbar";
import bg from "../../../assets/images/background.png";
import { container, item as Items } from "../../../helper/motions";
import InputBox from "../../../components/Inputbox/InputBox";
import Button from "../../../components/Button/Button";
// import { userIcon } from "./Users";

// import Details from "./Details";
import AnimatedModal from "../../../components/Modal/AnimatedModal";
import {
  activeIcon,
  closeIcon,
  deviceIcon,
  inactiveIcon,
} from "../../../helper/icons";
// import ServiceRunning from "./ServiceRunning";
import { ThemeContext } from "../../../helper/context";
// import UserInfo from "./UserInfo";
// import DeployedUsecase from "./DeployedUsecase";
import { useHistory } from "react-router-dom";
import Searchbar from "../../../components/Searchbar/Searchbar";
import Scroller from "../../../components/Scroller/ScrollBar";
import { useDebouncedEffect } from "../../../helper/useDebounce";
import { axiosApiInstance } from "../../../helper/request";
import { Radio, Radio2 } from "../../../components/Radio/Radio";
import { LoadingText } from "../../auth/register/AuthRegister";
import Loading from "../../../components/Loading/Loading";
import UserProfile from "./UserProfile";
import UserDevice from "./UserDevice";
let _userInfo = null;
let _superInfo = null;
let _userAPI = null;
export default function UserDetails2(props) {
  const userScroll = useRef();
  const history = useHistory();
  const themeContext = useContext(ThemeContext);
  const [activeSetting, setactiveSetting] = useState("Profile Details");
  const [OperatorModal, setOperatorModal] = useState(false);
  const [ProfilePic, setProfilePic] = useState(null);
  const [OperatorLoading, setOperatorLoading] = useState(false);
  const [LoadingScreen, setLoadingScreen] = useState(false);
  const [SearchText, setSearchText] = useState("");
  const [UserList, setUserList] = useState([]);
  const [Username, setUserName] = useState("");
  const [ErrorModal, setErrorModal] = useState({
    showPop: false,
    msg: "",
    type: "alert",
    header: "",
  });
  const [UserData, setUserData] = useState({
    industry: "",
    email: "",
    phone: "",
    roles: "",
    full_name: "",
    profile_pic: "",
    address: "",
    created: "",
  });
  const [SelectedUser, setSelectedUser] = useState([]);
  useDebouncedEffect(
    () => (SearchText ? getUser() : undefined),
    [SearchText],
    1000
  );
  const [SettingList, setSettingList] = useState([
    "Profile Details",
    "Device Details",
    "Usecase Info",
  ]);
  const [ModalOpen, setModalOpen] = useState(false);
  const [FullName, setFullName] = useState("");
  const [Status, setStatus] = useState(true);

  //   const getKeyCode = (e) => {
  //     switch (e.keyCode) {
  //       case 38:
  //         arrowKey(e, "up");
  //         break;

  //       case 40:
  //         arrowKey(e, "down");
  //         break;

  //       case 13:
  //         enterKey(e);
  //         break;
  //     }
  //   };

  //   const arrowKey = (e, type) => {
  //     let myReferenceDiv = document.querySelector("._span");
  //     let active_flag = document.querySelector(".active_flag");
  //     let active_next = active_flag?.nextElementSibling;
  //     let active_prev = active_flag?.previousElementSibling;
  //     let next = myReferenceDiv?.nextElementSibling;
  //     let prev = myReferenceDiv?.previousElementSibling;
  //     if (type === "down") {
  //       if (!active_flag) {
  //         next.classList.add("active_flag");
  //       } else {
  //         if (active_next) {
  //           active_flag.classList.remove("active_flag");
  //           active_next.classList.add("active_flag");
  //           var childElement =
  //             document.querySelector(".active_flag").offsetTop -
  //             myReferenceDiv.offsetHeight -
  //             20;
  //           userScroll?.current?.scrollTop(childElement);
  //         }
  //       }
  //     }
  //     if (type === "up") {
  //       if (active_prev && !active_prev.classList.contains("_span")) {
  //         active_flag.classList.remove("active_flag");
  //         active_prev.classList.add("active_flag");
  //         var childElement =
  //           document.querySelector(".active_flag").offsetTop -
  //           myReferenceDiv.offsetHeight -
  //           20;
  //         userScroll?.current?.scrollTop(childElement);
  //       }
  //     }
  //   };

  const enterKey = () => {
    //  let active_flag = document.querySelector(".active_flag");
    //  let dataCity = active_flag.getAttribute("data-city");
    //  let dataCountry = active_flag.getAttribute("data-country");
    //  let dataState = active_flag.getAttribute("data-state");
    //  console.log(active_flag, dataCity, dataCountry, dataState);
    //  if (dataCity) {
    //    isDataEmpty = null;
    //    setCity(dataCity);
    //    setLocationSelected((prevState) => ({
    //      ...prevState,
    //      isCitySelected: true,
    //    }));
    //    setLocationData((prevState) => ({
    //      ...prevState,
    //      citySuggestion: [],
    //    }));
    //  }
    //  if (dataState) {
    //    values.state = dataState;
    //    isExact = true;
    //    isDataEmpty = null;
    //    setState(dataState);
    //    setLocationSelected((prevState) => ({
    //      ...prevState,
    //      isStateSelected: true,
    //    }));
    //  }
    //  if (dataCountry) {
    //    isExact = true;
    //    isDataEmpty = null;
    //    values.country = dataCountry;
    //    setCountry(dataCountry);
    //    setLocationSelected((prevState) => ({
    //      ...prevState,
    //      isCountrySelected: true,
    //    }));
    //    setLocationLoading((prevState) => ({
    //      ...prevState,
    //      state: true,
    //    }));
    //  }
  };
  const handlePermission = (idx, name) => {
    let _data = [...SelectedUser];
    if (!_data[idx].permissions.includes(name)) {
      _data[idx].permissions.push(name);
      setSelectedUser([..._data]);
    } else {
      const newArr = _data[idx].permissions.filter((e) => e !== name);
      _data[idx].permissions = [...newArr];
      console.log(newArr);
      setSelectedUser([..._data]);
    }
  };

  const getUser = () => {
    console.log("getUser");
    if (!SearchText) {
      return;
    }
    setOperatorLoading(true);
    axiosApiInstance
      .get("user/username/filter?username=" + SearchText)
      .then((res) => {
        console.log(_userInfo, res.data);
        let _data = res.data.filter((val) => !_userInfo.includes(val));
        setUserList([..._data]);
        console.log(_data);
      })
      .catch((err) => {})
      .finally(() => {
        setOperatorLoading(false);
      });
  };

  const postOperatorData = () => {
    axiosApiInstance
      .post("device/operator/add", SelectedUser, {
        headers: {
          "serial-number": props.match.params.idee,
        },
      })
      .then((res) => {
        _userAPI();
        setOperatorModal(false);
        setSearchText("");
        setUserList([]);
        setSelectedUser([]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (OperatorModal) {
      getExcludedUsername();
    }
    getUsernameUsingUserid();
  }, [OperatorModal]);


  const getExcludedUsername = () => {
    axiosApiInstance
      .get("device/fetch/users/username", {
        headers: {
          "serial-number": props.match.params.idee,
        },
      })
      .then((res) => {
        _userInfo = [...res.data];
        getExcludedSuperadmin();
      })
      .catch((err) => {});
  };
  const getExcludedSuperadmin = () => {
    axiosApiInstance
      .get("user/username/superadmin")
      .then((res) => {
        _userInfo.push(res.data.superadmin);
      })
      .catch((err) => {});
  };
  const getUsernameUsingUserid = () => {
    axiosApiInstance
      .get("user/fetch/username?user_id=" + props.match.params.idee)
      .then((res) => {
        console.log(_userInfo, res.data);
        setUserName(res.data.detail)
      })
      .catch((err) => {})
      .finally(() => {
      });
  }
  return (
    <div className="__profile_wrapper__ __device_wrapper__ ">
      <Navbar sidenav>
        <div className="profile_body fadeIn">
          <img src={bg} className="profile_bg" />
          <div className="profile_content">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h1>{activeSetting}</h1>
              <p
                className="goBack"
                onClick={() => history.push("/users" + window.location.search)}
              >
                Go Back
              </p>
            </div>
            <div className="profile_data">
              <motion.div
                variants={container}
                exit="exit"
                initial="hidden"
                animate="visible"
                className="profile_left"
              >
                <motion.div variants={Items} className="profile_holder">
                  <div className="profile_image_container">
                    {ProfilePic ? (
                      <img
                        src={ProfilePic}
                        alt="user_image"
                        className="profile_image"
                      />
                    ) : (
                      userIcon()
                    )}
                  </div>
                  <h2
                    style={{
                      color:
                        themeContext.Theme === "Light"
                          ? "var(--primary)"
                          : "#f2f4f8",
                          textTransform: "capitalize"
                    }}
                  >
                    {/* {props.match.params.idee} */}
                    {Username}
                  </h2>
                </motion.div>
                {console.log(SettingList, activeSetting,  "SettingList")}
                {SettingList.map((item) => (
                  <motion.div
                    className={"pro_option"}
                    style={{
                      color:
                        themeContext.Theme === "Light"
                          ? "var(--primary)"
                          : "#f2f4f8",
                    }}
                    variants={Items}
                    key={item}
                    onClick={() => {
                    
                        setactiveSetting(item);
                    }}
                  >
                    {item}
                    {item === activeSetting && (
                      <div className="arrow-right fadeIn" />
                    )}
                  </motion.div>
                ))}
              </motion.div>
              {Username && activeSetting === "Profile Details" && <UserProfile setLoadingScreen = {setLoadingScreen} setProfilePic = {setProfilePic} id={Username}/> }
              {Username && activeSetting === "Device Details" && <UserDevice setLoadingScreen = {setLoadingScreen} id = {props.match.params.idee}/>}
              {/* {activeSetting === "Device Details" && (
                <Details id={props.match.params.idee} history={history} />
              )}
              {activeSetting === "User Details" && (
                <UserInfo
                  id={props.match.params.idee}
                  theme={themeContext.Theme}
                  handleModal={() => {
                    setOperatorModal(true);
                  }}
                  handleAlert={(bool, data) => {
                    // if (bool) {
                    //   msg = { ...data };
                    //   setShowAlert(bool);
                    // }
                  }}
                  getUserAPI={(data) => {
                    console.log(data);
                    _userAPI = data;
                  }}
                  handleDelete={(headers) => {
                    console.log(headers);
                    axiosApiInstance
                      .delete("device/operator/remove", {
                        headers,
                      })
                      .then((res) => {
                        console.log(res);
                        _userAPI();
                      })
                      .catch((err) => {
                        console.log(err);
                      });
                  }}
                  handlePermission={(data, username) => {
                    console.log(data);
                    let _data = [...data];
                    data.forEach((element, index) => {
                      if (element === "Editor") {
                        _data[index] = "canEdit";
                      } else {
                        _data[index] = "canView";
                      }
                    });
                    console.log(_data);
                    axiosApiInstance
                      .patch(
                        "device/operator/permission",
                        {
                          username,
                          permissions: [..._data],
                        },
                        {
                          headers: {
                            "serial-number": props.match.params.idee,
                          },
                        }
                      )
                      .then((res) => {
                        console.log(res);
                      });
                  }}
                />
              )} */}
              {/* {activeSetting === "Deployed Usecase" && <DeployedUsecase />}
              {activeSetting === "Service Running" && <ServiceRunning />} */}
              {activeSetting === "Usecase Info" && <div className="deployed_usecase_nodata">
                 no usecase found</div>}
            </div>
          </div>
        </div>
      </Navbar>


      {/* <AnimatedModal
        modalOpen={ShowAlert}
        // modalOpen={true}
        handleClose={() => {
          setShowAlert(false);
          if (msg.errorText === "Registered as developer successfully!") {
            clearTimeout(timeout);
            setDevModal(false);
          }
        }}
        type={msg.type}
        errorHeader={msg.header}
        errorText={msg.errorText}
        alwayOpen={true}
      /> */}


      {LoadingScreen && <Loading type={"transparent"} text={"Loading"} />}
    </div>
  );
}

const userIcon = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    className="user_avatar_img"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 11.796C14.7189 11.796 16.9231 9.60308 16.9231 6.89801C16.9231 4.19294 14.7189 2.00005 12 2.00005C9.28106 2.00005 7.07692 4.19294 7.07692 6.89801C7.07692 9.60308 9.28106 11.796 12 11.796Z"
      fill="#030D45"
    />
    <path
      d="M14.5641 13.8369H9.4359C6.46154 13.8369 4 16.2859 4 19.245C4 19.9593 4.30769 20.5716 4.92308 20.8777C5.84615 21.3879 7.89744 22.0001 12 22.0001C16.1026 22.0001 18.1538 21.3879 19.0769 20.8777C19.5897 20.5716 20 19.9593 20 19.245C20 16.1838 17.5385 13.8369 14.5641 13.8369Z"
      fill="#030D45"
    />
  </svg>
);

const profileIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="31.244"
    height="24.996"
    viewBox="0 0 31.244 24.996"
  >
    <path
      id="Shape"
      d="M28.12,25h-25A3.128,3.128,0,0,1,0,21.87V7.811A3.127,3.127,0,0,1,3.123,4.687h3.75A1.392,1.392,0,0,0,8.108,3.8L9.077.889A1.388,1.388,0,0,1,10.309,0H20.934a1.388,1.388,0,0,1,1.233.889L23.136,3.8a1.39,1.39,0,0,0,1.235.889h3.75a3.127,3.127,0,0,1,3.123,3.124V21.87A3.128,3.128,0,0,1,28.12,25ZM15.621,6.249a7.811,7.811,0,1,0,7.812,7.81A7.819,7.819,0,0,0,15.621,6.249Zm11.4,1.56A1.093,1.093,0,1,0,28.12,8.9,1.095,1.095,0,0,0,27.025,7.809Zm-11.4,10.938a4.687,4.687,0,1,1,4.687-4.688A4.692,4.692,0,0,1,15.621,18.747Z"
      fill="#fff"
    />
  </svg>
);

const name = [
  "Shahbaz",
  "Sachin",
  "Janvi",
  "Shubham",
  "Dipesh",
  "Urvi",
  "Nitin",
  "Pushpak",
  "Subha",
];
