import React, { useRef, useState } from "react";
import Navbar from "../../../components/Navbar/Navbar";
import "./addapp.scss";
import bg from "../../../assets/images/background.png";
import Button from "../../../components/Button/Button";
import InputBox from "../../../components/Inputbox/InputBox";
import FileDrop from "../../../components/FileDrop/FileDrop";
import AnimatedModal from "../../../components/Modal/AnimatedModal";
import { closeIcon, dollarIcon, tickIcon2 } from "../../../helper/icons";
import Slider from "react-slick";
import MultiSelectDropdown from "../../../components/MultiSelectDropdown/MultiSelectDropdown";
import Selector from "../../../components/Selector/Selector";
import card from "../../../assets/images/card.png";
import { useHistory } from "react-router-dom";
import Scroller from "../../../components/Scroller/ScrollBar";
import { API_URL, axiosApiInstance } from "../../../helper/request";
import Loading from "../../../components/Loading/Loading";
import { m } from "framer-motion";
import { useDebouncedEffect } from "../../../helper/useDebounce";
import axios from "axios";
import SliderCard from "../../../components/SliderCard/SliderCard";
let msg = {
  type: "alert",
  header: "Error",
  errorText: "App Name Exists",
};
let setVideo = "";

export default function AddApp() {
  const [selectorBox, setSelectorBox] = useState(0);
  const [selectedAppType, setSelectedAppType] = useState("");
  const [ShowAlert, setShowAlert] = useState(false);
  const [LoadingScreen, setLoadingScreen] = useState(false);
  const [addmediaactive, setaddmediaactive] = useState(false);
  const [appimgactive, setappimgactive] = useState(false);
  const [pricingActive, setPricingActive] = useState(false);
  const [isPricingEmpty, setIsPricingEmpty] = useState(false);
  const [ServiceImg, setServiceImg] = useState([]);
  const [AppImg, setAppImg] = useState([]);
  const [isAppImgEmpty, setisAppImgEmpty] = useState(false);
  const [AdditionalMediaImg, setAdditionalMediaImg] = useState([]);
  const [isAdditionalMEmpty, setIsAdditionalMEmpty] = useState(false);
  const categorySliderRef = useRef();
  const [AppName, setAppName] = useState("");
  const [isAppThumbailEmpty, setisAppThumbailEmpty] = useState(false);
  const [Description, setDescription] = useState("");
  const [isDescriptionEmpty, setisDescriptionEmpty] = useState(false);
  const [videoId, setVideoId] = useState("");
  const [AppNameError, setAppNameError] = useState("");

  let timeout = null;
  let firstLoad = true;
  const history = useHistory();

  const [dataValues, setdataValues] = useState({
    selectorone: {
      monthly: "",
      quarterly: "",
      yearly: "",
      lifetime: "",
    },
    selectortwo: {
      onetime: "",
    },
  });

  const FilterTab = ({ tag, onDelete }) => (
    <div className="filter_tab">
      <p>{tag}</p>
      {tickIcon2()}
      {close(onDelete)}
    </div>
  );

  const [selectorname, setselectorname] = useState({
    logic: "logic",
    processing: "processing",
    action: "action",
  });
  const [FilterOptions, setFilterOptions] = useState({
    targetindustry: ["Healthcare", "Banking", "BSFI", "Textile", "Logistics"],
  });
  const [SelectedFilter, setSelectedFilter] = useState({
    targetindustry: [],
  });

  const [errors, setErrors] = useState({
    isAppnameEmpty: false,
    isTargetIndustriesEmpty: false,
    isPricingBoxEmpty: false,
    isAppNameUnique: false,
  });
  const showError = (iitem) => {
    document
      .getElementById(iitem)
      .classList.add("empty_error", "error_shake", "input__error");
  };

  const appPriceData = () => {
    let obj = {
      type: null,
      details: [],
    };
    Object.keys(dataValues[Object.keys(dataValues)[selectorBox]]).map((item) =>
      obj.details.push({
        pricing_type: item,
        pricing_details: {
          license_attribute: "device",
          amount: dataValues[Object.keys(dataValues)[selectorBox]][item],
          markup: null,
        },
      })
    );

    axiosApiInstance.post(
      API_URL + `apps/developer/pricing?app_name=${AppName}`,
      obj
    );
  };

  const pricingPostData = () => {
    // console.log(dataValues[Object.keys(dataValues)[selectorBox]]);

    validateData();
  };
  const validateData = () => {
    let res = false;

    Object.keys(dataValues[Object.keys(dataValues)[selectorBox]]).map(
      (iitem) => {
        if (dataValues[Object.keys(dataValues)[selectorBox]][iitem] === "") {
          showError(iitem);
          res = true;
        }
      }
    );

    res === false && setPricingActive(false);

    return res;
  };

  const postData = async () => {
    let _errors = { ...errors };
    if (AppName === "") _errors["isAppnameEmpty"] = true;
    if (SelectedFilter.targetindustry.length === 0)
      _errors["isTargetIndustriesEmpty"] = true;

    if (ServiceImg.length === 0) {
      setisAppThumbailEmpty(true);
    }
    if (Description === "") {
      setisDescriptionEmpty(true);
    }
    if (AppImg.length === 0) {
      setisAppImgEmpty(true);
    }
    if (AdditionalMediaImg.length === 0) {
      setIsAdditionalMEmpty(true);
    }
    if (AppName.length < 3) {
      setAppNameError("appname invalid");
    }

    if (
      Object.keys(dataValues[Object.keys(dataValues)[selectorBox]]).map(
        (iitem) => {
          if (dataValues[Object.keys(dataValues)[selectorBox]][iitem] === "") {
            setIsPricingEmpty(true);
          }
        }
      )
    )
      setErrors({ ..._errors });
    let res = false;
    if (
      AppName !== "" &&
      SelectedFilter.targetindustry.length !== 0 &&
      ServiceImg.length !== 0 &&
      Description !== "" &&
      AppImg.length !== 0 &&
      AdditionalMediaImg.length !== 0 &&
      Object.keys(dataValues[Object.keys(dataValues)[selectorBox]]).map(
        (iitem) => {
          if (dataValues[Object.keys(dataValues)[selectorBox]][iitem] === "") {
            res = true;
            return;
          }
        }
      ) &&
      AppName.length > 2
    ) {
      !res && postAddAppData();
    }
  };

  const clearError = (name) => {
    let _errors = { ...errors };
    _errors[name] = false;
    setErrors({ ..._errors });
  };
  useDebouncedEffect(
    () => (AppName ? uniqueCheck("AppName") : undefined),
    [AppName],
    1000
  );

  const uniqueCheck = (type = "AppName") => {
    let value = {
      AppName,
    };
    let url = `apps/unique?app_name=${value[type]}`;
    axiosApiInstance.get(API_URL + url).then((res) => {
      if (res.data.detail) {
        if (type === "AppName")
          setErrors((prevState) => ({
            ...prevState,
            isAppNameUnique: true,
          }));
      } else {
        if (type === "AppName")
          setErrors((prevState) => ({
            ...prevState,
            isAppNameUnique: false,
          }));
      }
    });
  };

  const postAddAppData = () => {
    let data = {
      app_name: AppName,
      app_type: selectorname[selectedAppType],
      app_thumbnail: ServiceImg,
      app_image: AppImg,
      description: Description,
      target_industries: SelectedFilter.targetindustry.toString(),
      upload_media: AdditionalMediaImg,
    };

    let formData = new FormData();
    Object.keys(data).map((ele, idx) => {
      if (typeof data[ele] === "object") {
        return data[ele].map((subele, subidx) =>
          formData.append(ele, subele, subele.name)
        );
      } else {
        formData.append(ele, data[ele]);
      }
    });

    setLoadingScreen(true);
    axiosApiInstance
      .post("apps/", formData)
      .then((res) => {
        appPriceData();
        msg.errorText = "App added successfully";
        msg.header = "Success";
        msg.type = "success";
        setShowAlert(true);
        timeout = setTimeout(() => {
          // encryptStorage.removeItem("DDATA");
          history.push("/apps");
        }, 2000);
        setTimeout(() => {
          setShowAlert(false);
        }, 3000);
      })
      .catch((err) => {
        msg.errorText = "Something went wrong";
        msg.header = "Error";
        msg.type = "alert";
        setShowAlert(true);
      })
      .finally(() => {
        setLoadingScreen(false);
        setTimeout(() => {
          firstLoad = false;
        }, 2000);
      });
  };

  const close = (onClick) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={12.499}
      height={12.499}
      viewBox="0 0 12.499 12.499"
      className="closeIcon fadeIn"
      onClick={onClick}
    >
      <g
        id="Group_15572"
        data-name="Group 15572"
        transform="translate(-1484.439 -359.439)"
      >
        <line
          id="Line_302"
          data-name="Line 302"
          x2={10.378}
          y2={10.378}
          transform="translate(1485.5 360.5)"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeWidth={1.5}
        />
        <line
          id="Line_303"
          data-name="Line 303"
          x1={10.378}
          y2={10.378}
          transform="translate(1485.5 360.5)"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeWidth={1.5}
        />
      </g>
    </svg>
  );

  const nextIcon = () => (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.46967 5.46967C9.76256 5.17678 10.2374 5.17678 10.5303 5.46967L16.5303 11.4697C16.8232 11.7626 16.8232 12.2374 16.5303 12.5303L10.5303 18.5303C10.2374 18.8232 9.76256 18.8232 9.46967 18.5303C9.17678 18.2374 9.17678 17.7626 9.46967 17.4697L14.9393 12L9.46967 6.53033C9.17678 6.23744 9.17678 5.76256 9.46967 5.46967Z"
        fill="#000"
      />
    </svg>
  );

  const prevIcon = () => (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5303 18.5303C14.2374 18.8232 13.7626 18.8232 13.4697 18.5303L7.46967 12.5303C7.17678 12.2374 7.17678 11.7626 7.46967 11.4697L13.4697 5.46967C13.7626 5.17678 14.2374 5.17678 14.5303 5.46967C14.8232 5.76256 14.8232 6.23744 14.5303 6.53033L9.06066 12L14.5303 17.4697C14.8232 17.7626 14.8232 18.2374 14.5303 18.5303Z"
        fill="#000"
      />
    </svg>
  );

  var x = document.getElementById(videoId);
  const videoPause = (videoId) => {
    x.pause();
  };

  function Arrow(props) {
    let className = props.type === "next" ? "nextArrow" : "prevArrow";
    className += " arrow " + props.className;
    return (
      <button
        onClick={() => {
          props.onClick();
          props.onclick();
        }}
        disabled={props.className?.includes("slick-disabled")}
        className={className}
      >
        {props.type === "next" ? nextIcon() : prevIcon()}
      </button>
    );
  }

  const CategorySliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    arrows: true,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <Arrow type="next" onclick={() => videoPause()} />,
    prevArrow: <Arrow type="prev" onclick={() => videoPause()} />,
  };

  return (
    <div className="_addapp_wrapper_">
      <Navbar sidenav>
        <div className="addapp_body">
          <img src={bg} className="profile_bg" />

          <div className="body_content fadeIn">
            <div className="addapp_label basic_details_text">
              <h4>Basic Details Of App</h4>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <p
                className="goBack"
                onClick={() => history.push("/apps" + window.location.search)}
              >
                Go Back
              </p>
            </div>
            <div className="addapp_data">
              <div className="addapp_data_main_wrapper">
                <Scroller
                  autoHeightMax="50vh"
                  verticalStyle={{
                    position: "absolute",
                    width: "0.3vw",
                    // height: "5vw",
                    // marginLeft: "0.1vw",
                    // bordrRadius: "1vw",
                    background: "linear-gradient(97deg, #013aa2, #2d62ed)",
                  }}
                >
                  <div className=" addapp_data_subwrappers">
                    <div className="addapp_data_subwrapper1">
                      <div>
                        <div className=" addapp_appname_subwrapper">
                          <InputBox
                            id="appnameinput"
                            header="App name"
                            maxLength={120}
                            type="text"
                            onChange={(e) => {
                              const value = e.target.value;
                              const alpharegex = /^[a-zA-Z ]*$/;
                              if (value.match(alpharegex)) {
                                setAppName(value);
                              }
                              if (value.length < 3) {
                                AppName.trim();
                              }
                            }}
                            error={errors["isAppnameEmpty"]}
                            value={
                              AppName.length < 4
                                ? AppName.trim()
                                : AppName.trimStart().replace("  ", " ")
                            }
                            onFocus={() => {
                              clearError("isAppnameEmpty");
                              // clearError("isAppNameUnique");
                              setAppNameError("");
                            }}
                            helperText={
                              errors["isAppNameUnique"]
                                ? "App Name already exists"
                                : ["isAppnameEmpty"] && ""
                            }
                          />
                          <p className="appname_error">
                            {AppNameError &&
                              "App name should be more than 3 letters"}
                          </p>
                        </div>
                        <div className="addapp_description_wrapper">
                          <div className="addapp_description_label">
                            Description
                            <label></label>
                          </div>
                          <textarea
                            style={{ overflow: "hidden" }}
                            value={Description}
                            maxlength="250"
                            className={
                              isDescriptionEmpty
                                ? "empty_error"
                                : "addapp_description_content"
                            }
                            onChange={(e) => setDescription(e.target.value)}
                            onFocus={() => {
                              setisDescriptionEmpty(false);
                            }}
                          ></textarea>
                        </div>
                        <FileDrop
                          Label={""}
                          Name={"App thumbnail"}
                          uploadedImage={ServiceImg}
                          setimg={setServiceImg}
                          error={isAppThumbailEmpty}
                          onclick={() => setisAppThumbailEmpty(false)}
                        />
                        <div className=" addapp_targetindustries_wrapper">
                          <div className="target_label_name">
                            Select target industries <label></label>
                          </div>
                          <div className="  addapp_targetindustries_content">
                            {FilterOptions.targetindustry.length > 0 && (
                              <MultiSelectDropdown
                                error={errors["isTargetIndustriesEmpty"]}
                                style={{ width: "7.2vw" }}
                                className="adjust_dd"
                                // label="Version"
                                id={"targetindustry"}
                                optionsList={FilterOptions.targetindustry}
                                handleOption={(data) => {
                                  setSelectedFilter({
                                    ...SelectedFilter,
                                    targetindustry: [...data],
                                  });
                                }}
                                defaultText={SelectedFilter.targetindustry}
                                onFocus={(e) =>
                                  clearError("isTargetIndustriesEmpty")
                                }
                              />
                            )}
                            <div className="selected_filter">
                              {Object.keys(SelectedFilter).map((item) =>
                                SelectedFilter[item].map((item2, idx) => (
                                  <FilterTab
                                    key={item2}
                                    tag={item2}
                                    onDelete={() => {
                                      let _data = { ...SelectedFilter };
                                      _data[item].splice(idx, 1);
                                      setSelectedFilter({ ..._data });
                                    }}
                                  />
                                ))
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="addapp_data_subwrapper2">
                      <div className="addapp_selector">
                        <p className=" addapp_selector_sent ">
                          What app type would you like to develop ?
                          <label></label>
                        </p>
                        <div className="addapp_selector_wrapper">
                          <Selector
                            selector_name={Object.keys(selectorname)}
                            setoption={setSelectedAppType}
                          />
                        </div>
                      </div>
                      <FileDrop
                        Name={"App Image"}
                        activestatus={setappimgactive}
                        uploadedImage={AppImg}
                        setimg={setAppImg}
                        error={isAppImgEmpty}
                        onclick={() => setisAppImgEmpty(false)}
                      />
                      <FileDrop
                        Name={"Additional Media"}
                        multiple
                        activestatus={setaddmediaactive}
                        uploadedImage={AdditionalMediaImg}
                        setimg={setAdditionalMediaImg}
                        error={isAdditionalMEmpty}
                        onclick={() => setIsAdditionalMEmpty(false)}
                      />
                      <div className="addapp_pricing_wrapper">
                        <div className="addapp_label  addapp_pricing_sent">
                          Pricing
                          <label></label>
                        </div>

                        <Button
                          name="Set pricing of your app"
                          onClick={() => {
                            setPricingActive(true);
                            setIsPricingEmpty(false);
                          }}
                        />
                        <p className="pricing_error">
                          {isPricingEmpty && "please fill the required fields "}
                        </p>
                      </div>
                    </div>
                  </div>
                </Scroller>

                <div className="addapp_btn">
                  {console.log(AppName.length)}
                  <Button
                    disabled={AppName.length < 3}
                    name={"Submit"}
                    onClick={() => {
                      postData();
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Navbar>
      <AnimatedModal modalOpen={addmediaactive} className="preview_modal">
        {AdditionalMediaImg.length > 1 && (
          <div className="sss">
            <h4 className="preview_multipleimg_word">Preview</h4>
            <p className="preview_multipleimg_sentence">
              Below are the images you uploaded as
              <span style={{ color: "#285de4", marginLeft: "0.3vw" }}>
                Additional Media's
              </span>
            </p>

            <Slider {...CategorySliderSettings} ref={categorySliderRef}>
              {AdditionalMediaImg.map((item, i) => (
                <SliderCard
                  id={"slide_" + i}
                  videoid={setVideoId}
                  uploadedMedia={item}
                  className={"category_mediaimg_card_wrapper"}
                  MediaClass={"Category_appimg"}
                  slider_name={""}
                  imgupload={URL.createObjectURL(AdditionalMediaImg[i])}
                  videoplayer={URL.createObjectURL(AdditionalMediaImg[i])}
                />
              ))}
            </Slider>
            <div className="mediaImg_prev_multiple_btn">
              <Button
                name={"Go back"}
                onClick={() => setaddmediaactive(false)}
              />
            </div>
          </div>
        )}

        {AdditionalMediaImg.length === 1 && (
          <div className="sss">
            <h4 className="preview_multipleimg_word">Preview</h4>
            <p className="preview_multipleimg_sentence">
              Below is the image you uploaded as
              <span style={{ color: "#285de4", marginLeft: "0.3vw" }}>
                Additional Media
              </span>
            </p>

            <Slider {...CategorySliderSettings} ref={categorySliderRef}>
              {AdditionalMediaImg.map((item, i) => (
                <SliderCard
                  id={"slide_" + i}
                  videoid={setVideoId}
                  uploadedMedia={item}
                  className={"category_mediaimg_card_wrapper"}
                  MediaClass={"Category_appimg"}
                  slider_name={""}
                  imgupload={URL.createObjectURL(AdditionalMediaImg[i])}
                  videoplayer={URL.createObjectURL(AdditionalMediaImg[i])}
                />
              ))}
            </Slider>
            <div className="mediaImg_prev_multiple_btn">
              <Button
                name={"Go Back"}
                onClick={() => setaddmediaactive(false)}
              />
            </div>
          </div>
        )}
        <div
          className="close_otp_modal"
          onClick={() => {
            setaddmediaactive(false);
          }}
        >
          {closeIcon()}
        </div>
      </AnimatedModal>
      <AnimatedModal modalOpen={appimgactive} className="preview_modal">
        {AppImg.length === 1 && (
          <div className="sss">
            <h4 className="preview_word">Preview</h4>
            <p className="preview_sentence">
              Below is the image you uploaded as
              <span style={{ color: "#285de4", marginLeft: "0.3vw" }}>
                App Image Media
              </span>
            </p>
            {AppImg.map((item, i) => (
              <SliderCard
                className={"category_appimg_card_wrapper"}
                ImageClass={"Category_appimg"}
                appImgMedia={item}
                slider_name={""}
                appImgUpload={URL.createObjectURL(AppImg[i])}
                //  videoplayer={URL.createObjectURL(AppImg[i])}
              />
            ))}

            <img className="cardimg" src={card}></img>

            <h3 title={AppName} className="card_app_name">
              {AppName}
            </h3>
            <div title={Description} className="card_app_description">
              {Description}
            </div>
            <div className=" appImg_prev_btn">
              <Button name={"Go back"} onClick={() => setappimgactive(false)} />
            </div>
          </div>
        )}
        <div
          className="close_otp_modal"
          onClick={() => {
            setappimgactive(false);
          }}
        >
          {closeIcon()}
        </div>
      </AnimatedModal>
      <AnimatedModal modalOpen={pricingActive} className="preview_modal">
        <div className=" pricing_wrapper ">
          <div className="pricing_main_wrapper">
            <div className="pricing_subwrapper">
              <div className="pricing_table_subwrapper ">
                <h4 className="addapp_label setpricing_wrapper">
                  Set pricing of your App
                </h4>
                <table>
                  <tr>
                    <th style={{ borderTopLeftRadius: "6px" }}>#</th>
                    <th className="addapp_label">Type </th>
                    <th
                      className="addapp_label"
                      style={{ borderTopRightRadius: "6px" }}
                    >
                      price
                    </th>
                  </tr>
                  {Object.keys(dataValues).map((key, priceIdx) => {
                    return (
                      <tr>
                        <td>
                          <input
                            style={{ marginBottom: priceIdx === 1 && "1.65vw" }}
                            type={"checkbox"}
                            checked={selectorBox === priceIdx}
                            onChange={() => {
                              let _demo = { ...dataValues };
                              _demo.selectorone.monthly = "";
                              _demo.selectorone.quarterly = "";
                              _demo.selectorone.yearly = "";
                              _demo.selectorone.lifetime = "";
                              _demo.selectortwo.onetime = "";
                              setdataValues(_demo);
                              setSelectorBox(priceIdx);
                              {
                                Object.keys(
                                  dataValues[
                                    Object.keys(dataValues)[selectorBox]
                                  ]
                                ).map((element, idx) => {
                                  document
                                    .getElementById(element)
                                    .classList.remove("input__error");
                                });
                              }
                            }}
                          />
                        </td>
                        <td className="addapp_label">
                          {Object.keys(dataValues[key]).map((element, idx) => (
                            <div
                              style={{
                                marginTop:
                                  idx === 0
                                    ? "1.5vw"
                                    : idx === 1
                                    ? "2.5vw"
                                    : idx === 2
                                    ? "2.5vw"
                                    : idx === 3
                                    ? "2vw"
                                    : idx === 4
                                    ? "2vw"
                                    : "1vw",
                              }}
                              className="table_name"
                              id={"idx" + idx}
                            >
                              {element}
                            </div>
                          ))}
                        </td>
                        <td className="addapp_label">
                          {Object.keys(dataValues[key]).map((element, idx) => {
                            return (
                              <InputBox
                                id={element}
                                children={dollarIcon()}
                                disabled={selectorBox !== priceIdx}
                                value={
                                  dataValues[key][element] &&
                                  dataValues[key][element].indexOf(".") !==
                                    -1 &&
                                  dataValues[key][element].length -
                                    dataValues[key][element].indexOf(".") >
                                    2
                                    ? parseFloat(dataValues[key][element])
                                        .toFixed(2)
                                        .toString()
                                    : dataValues[key][element]
                                }
                                onChange={(e) => {
                                  if (!isNaN(e.target.value)) {
                                    if (
                                      e.target.value.indexOf(".") === -1 ||
                                      (e.target.value.includes(".") &&
                                        e.target.value.length -
                                          e.target.value.indexOf(".") <=
                                          3)
                                    ) {
                                      let _data = { ...dataValues };
                                      _data[key][element] = e.target.value;
                                      setdataValues(_data);
                                    }
                                  }
                                }}
                                error={errors["isPricingBoxEmpty"]}
                                onFocus={() => {
                                  document
                                    .getElementById(element)
                                    .classList.remove(
                                      "input__error",
                                      "error_shake"
                                      // "input__error"
                                    );
                                }}
                                helperText={errors["isPricingBoxEmpty"] && ""}
                              />
                            );
                          })}
                        </td>
                      </tr>
                    );
                  })}
                </table>
              </div>
            </div>
            <div className="reset_submit_wrapper">
              <div className="appImg_prev_btn">
                <Button
                  name={"Submit"}
                  onClick={() => {
                    pricingPostData();
                  }}
                />
              </div>
              <div className="appImg_prev_btn">
                <Button
                  name={"Reset"}
                  onClick={() => {
                    setdataValues({
                      selectorone: {
                        monthly: "",
                        quarterly: "",
                        yearly: "",
                        lifetime: "",
                      },
                      selectortwo: {
                        onetime: "",
                      },
                    });
                    {
                      Object.keys(
                        dataValues[Object.keys(dataValues)[selectorBox]]
                      ).map((element, idx) => {
                        document
                          .getElementById(element)
                          .classList.remove("input__error");
                      });
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div
          className="close_otp_modal"
          onClick={() => {
            setPricingActive(false);
          }}
        >
          {closeIcon()}
        </div>
      </AnimatedModal>
      <AnimatedModal
        modalOpen={ShowAlert}
        // modalOpen={true}
        handleClose={() => {
          setShowAlert(false);
        }}
        type={msg.type}
        errorHeader={msg.header}
        errorText={msg.errorText}
        // timeout={5000}
        alwayOpen={true}
      />
      {LoadingScreen && <Loading type={"transparent"} text={"Loading"} />}
    </div>
  );
}
